import React, { memo, useRef } from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { BsCheckCircle, BsInfoCircle, BsXCircle } from "react-icons/bs";
import styles from "./evaluationComponent.module.css";
import { TEvaluationResult } from "./evaluationComponent.types";

type TEvaluationComponentProps = {
  evaluation: TEvaluationResult;
};

const EvaluationComponent: React.FC<TEvaluationComponentProps> = ({ evaluation }: TEvaluationComponentProps) => {
  const ref = useRef(null);

  if (!evaluation.evaluate) {
    return <></>;
  }

  const popover = (
    <Popover id="evaluation-popover" className={styles.tooltipContainer}>
      <Popover.Content className={styles.detailsContainer}>
        <div className={styles.messageSection}>
          <div className={styles.messageContent}>
            {evaluation?.qtyDetails?.status ? (
              <BsCheckCircle className={`${styles.messageIcon} ${styles.iconSuccess}`} />
            ) : (
              <BsXCircle className={`${styles.messageIcon} ${styles.iconError}`} />
            )}
            <span className={evaluation?.qtyDetails?.status ? styles.successMessage : styles.errorMessage}>
              {evaluation?.qtyDetails?.message}
            </span>
          </div>
        </div>

        <div className={styles.messageSection}>
          <div className={styles.messageContent}>
            {evaluation?.totalDetails?.status ? (
              <BsCheckCircle className={`${styles.messageIcon} ${styles.iconSuccess}`} />
            ) : (
              <BsXCircle className={`${styles.messageIcon} ${styles.iconError}`} />
            )}
            <span className={evaluation.totalDetails?.status ? styles.successMessage : styles.errorMessage}>
              {evaluation?.totalDetails?.message}
            </span>
          </div>
        </div>
      </Popover.Content>
    </Popover>
  );

  const evaluationIcon =
    evaluation?.qtyDetails?.status && evaluation?.totalDetails?.status ? (
      <BsCheckCircle className={styles.iconSuccess} size={18} />
    ) : (
      <BsInfoCircle className={styles.iconError} size={18} />
    );

  return (
    <div ref={ref}>
      <OverlayTrigger container={ref.current} trigger={["hover", "focus"]} placement="right" overlay={popover}>
        {evaluationIcon}
      </OverlayTrigger>
    </div>
  );
};

export default memo(EvaluationComponent);
