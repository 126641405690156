import AmortizationSchedulePicker from "components/admin/pickers/reactHookFormPickers/amortizationSchedulePicker";
import CustomDataListPicker from "components/admin/pickers/reactHookFormPickers/customDataListPicker";
import { DatePickerField, InputField } from "components/forms/hookFormFields";
import React, { memo } from "react";
import { Col, Row } from "react-bootstrap";
import { useWatch } from "react-hook-form";
import style from "./amortization.module.css";

type TAmortizationScheduleProps = {
  isIntacctErp?: boolean;
  modelDataName: string;
  moduleName?: string;
};

const AmortizationSchedule = ({ modelDataName, moduleName, isIntacctErp }: TAmortizationScheduleProps) => {
  const amortizationScheduleName = useWatch({ name: `${modelDataName}.amortization_schedule_name` });

  return (
    <Row>
      <Col>
        <AmortizationSchedulePicker
          label="Amortization Template"
          name={`${modelDataName}.amortization_id`}
          instanceId={`${modelDataName}.amortization_schedule`}
          objectPropertyKey={`${modelDataName}.amortization`}
          modelDataName={moduleName}
        />
      </Col>

      {isIntacctErp ? (
        <Col>
          <InputField
            label="Amortization Schedule"
            name={`${modelDataName}.amortization_schedule_name`}
            type="text"
            id={`${modelDataName}.schedule_id`}
          />
        </Col>
      ) : amortizationScheduleName ? (
        <Col>
          <div className={style.schedule}>Amortization Schedule</div>
          <span className="form-control">{amortizationScheduleName}</span>
        </Col>
      ) : null}

      <Col>
        <DatePickerField
          id={`${modelDataName}.amortization_start_date`}
          name={`${modelDataName}.amortization_start_date`}
          label="Start Date"
        />
      </Col>

      {isIntacctErp ? (
        <Col>
          <CustomDataListPicker
            name={`${modelDataName}.prepaid_expense_class_external_id`}
            id={`${modelDataName}.prepaid_expense_class_external_id`}
            label="Prepaid Expense Class"
            model={`${modelDataName}.PrepaidExpenseClass`}
          />
        </Col>
      ) : (
        <Col>
          <DatePickerField
            id={`${modelDataName}.amortization_end_date`}
            name={`${modelDataName}.amortization_end_date`}
            label="End Date"
          />
        </Col>
      )}
    </Row>
  );
};

export default memo(AmortizationSchedule);
