import { useQuery } from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import PickerErrorBlock from "components/common/pickers/pickerErrorBlock";
import { Mandatory } from "components/forms/bootstrapFields";
import { restApiService } from "providers/restApi";
import React, { memo, useCallback, useMemo } from "react";
import { Form } from "react-bootstrap";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { OnChangeValue } from "react-select";
import commonService from "services/common/commonSvc";
import ReactSelect from "../wrapperReactSelect/reactSelect";
import { StaticWorkflowPickerTypes } from "./staticWorkflowPickerType";

const StaticWorkflowPicker = ({
  name,
  label,
  valuePropertyName = "workflow_name",
  labelPropertyName = "workflow_name_label",
  required = false,
  emptyOptionLabel = "-- Select --",
  getResponseData,
  validate,
  placeholder = "",
  labelClassName,
  modelDataName,
}: StaticWorkflowPickerTypes.TStaticWorkflowPickerProps) => {
  const { t } = useTranslation();
  const { setValue } = useFormContext();

  const {
    data: fetchedOptions,
    isFetching: isFetchingOptions,
    isError: isErrorOption,
  } = useQuery<StaticWorkflowPickerTypes.TStaticWorkflowPickerOption[]>({
    queryKey: [name, getResponseData, modelDataName],
    queryFn: async ({ signal }) => {
      const response: AxiosResponse<StaticWorkflowPickerTypes.TStaticWorkflowPickerOption[]> = await restApiService.get(
        "approval_workflows/static_workflow_names",
        { workflow_type: modelDataName },
        null,
        true,
        null,
        true,
        signal,
      );
      const data = getResponseData ? getResponseData(response) : response.data;
      return Array.isArray(data) ? data : [];
    },
  });

  const options = useMemo<StaticWorkflowPickerTypes.TStaticWorkflowPickerOption[]>(() => {
    let finalOptions = fetchedOptions || [];

    if (!required) {
      const emptyOption = {
        [valuePropertyName]: null,
        [labelPropertyName]: emptyOptionLabel,
      } as StaticWorkflowPickerTypes.TStaticWorkflowPickerOption;
      finalOptions = [emptyOption, ...finalOptions];
    }

    return finalOptions;
  }, [emptyOptionLabel, fetchedOptions, labelPropertyName, required, valuePropertyName]);

  const onChangeHandle = useCallback(
    (selected: OnChangeValue<StaticWorkflowPickerTypes.TStaticWorkflowPickerOption, false>) => {
      setValue(name, selected ? selected[valuePropertyName] : null);
    },
    [name, setValue, valuePropertyName],
  );

  const getSelectedValue = useCallback(
    (value: string) => {
      return options.find((option) => option[valuePropertyName] === value) || null;
    },
    [options, valuePropertyName],
  );

  const getOptionLabel = useCallback(
    (option: StaticWorkflowPickerTypes.TStaticWorkflowPickerOption) => option[labelPropertyName] || "",
    [labelPropertyName],
  );

  const getOptionValue = useCallback(
    (option: StaticWorkflowPickerTypes.TStaticWorkflowPickerOption) => option[valuePropertyName] || "",
    [valuePropertyName],
  );

  return (
    <Form.Group>
      {label && (
        <Form.Label className={"pickerLabel " + labelClassName}>
          {label} {required && <Mandatory required={required} />}
        </Form.Label>
      )}
      <Controller
        name={name}
        rules={{ required: required ? t("validations.required") : undefined, validate }}
        render={({ field: { value, onChange }, fieldState: { error } }) => (
          <>
            <ReactSelect
              value={getSelectedValue(value)}
              isLoading={isFetchingOptions}
              options={options}
              onChange={onChangeHandle}
              placeholder={placeholder}
              getOptionLabel={getOptionLabel}
              getOptionValue={getOptionValue}
              isClearable
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  ...commonService.getInvalidStyle(Boolean(error?.message)),
                }),
              }}
            />
            {error?.message && <PickerErrorBlock error={error.message} />}
            {isErrorOption && <PickerErrorBlock error={t("components.common.pickers.loadingOptionsError")} />}
          </>
        )}
      />
    </Form.Group>
  );
};

export default memo(StaticWorkflowPicker);
