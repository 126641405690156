import { ColDef, GridApi, HeaderValueGetterParams } from "ag-grid-community";
import useConfirmModal from "components/modals/confirmModal/useConfirmModalHook";
import PopoverRender from "components/popovers/popoverRender";
import TooltipRender from "components/toolTip/tooltipRender";
import _, { isPlainObject } from "lodash";
import { restApiService } from "providers/restApi";
import React, { memo } from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useTypedSelector } from "reducers";
import expenseService, { ExpenseConstants } from "services/admin/expenses/expenseSvc";
import ExpensesApis from "services/admin/expenses/expensesApis";
import { ExpensesTypes } from "services/admin/expenses/expensesType";
import { setAllowedFilters } from "services/common/gridService";
import { FieldFilterMapType } from "services/common/types/grid.type";
import { IUser } from "services/common/user/userTypes";
import { companyDateFormat } from "services/general/dateSvc";
import { formattedAmount } from "services/general/helpers";
import { CreateNotification, NotificationType } from "services/general/notifications";
import { translate } from "services/general/translation";
import gridService from "services/grid/gridSvc";
import styles from "../expense.module.css";
import UploadReceipt from "./uploadReceipt";

type CellRendererParamsType = {
  value: any;
  data: ExpensesTypes.ListItem;
};

export const FIELD_NAME_MAP: FieldFilterMapType = {
  id: {
    equals: "id",
  },
  number: {
    contains: "number",
  },
  "purchase_order.number": {
    equals: "purchase_order_number",
    blank: "purchase_order_number_has_data",
    notBlank: "purchase_order_number_has_data",
  },
  vendor_name: {
    contains: "vendor_name",
    equals: "exact_vendor_name",
    blank: "vendor_name_has_data",
    notBlank: "vendor_name_has_data",
  },
  status: {
    equals: "statuses",
    default: "statuses",
  },
  date: {
    inRange: "",
    lessThanOrEqual: "date_before",
    greaterThanOrEqual: "date_after",
  },
  total: {
    inRange: "",
    lessThanOrEqual: "total_less_than",
    greaterThanOrEqual: "total_greater_than",
  },
  "employee.name": {
    contains: "employee_name",
  },
  currency_code: {
    equals: "currency_iso_code",
  },
  submit_date: {
    inRange: "",
    lessThanOrEqual: "submit_date_before",
    greaterThanOrEqual: "submit_date_after",
  },
  "subsidiary.name": {
    contains: "subsidiary_name",
  },
  "policy.name": {
    contains: "policy_name",
  },
  project_name: {
    contains: "project_name",
  },
  "submitter.name": {
    contains: "submitter_name",
  },
  has_violations: {
    equals: "violation",
    default: "violation",
  },
  reimbursable: {
    equals: "reimbursable",
  },
  "expense_report.number": {
    contains: "expense_report_number",
    blank: "expense_report_has_data",
    notBlank: "expense_report_has_data",
  },
};

const Actions = memo(({ data, gridApi }: { data: ExpensesTypes.ListItem; gridApi?: GridApi }) => {
  const currentUser: IUser = useTypedSelector((state) => state.user);
  const { t } = useTranslation();
  const { createConfirmModal } = useConfirmModal();
  const searchAPIFilters = localStorage.getItem(ExpenseConstants.LIST_EXPENSE_ITEM_SEARCH_API_FILTERS);

  const onDeleteConfirm = async () => {
    try {
      if (data.id && gridApi) {
        const result = await ExpensesApis.deleteExpense(data.id);
        gridService.removeRowFromGrid(gridApi, String(data.id));
        CreateNotification(t("success"), t("admin.pages.expenseItem.successfulDelete"), NotificationType.success);
      }
    } catch (error) {}
  };

  const createDeleteConfirmation = () => {
    createConfirmModal({
      title: t("admin.pages.expenseItem.deleteDialogHeader"),
      body: t("admin.pages.expenseItem.confirmDeleteMessage"),
      callBackData: null,
      cancelCallBack: null,
      confirmButtonLabel: t("admin.pages.expenseItem.deleteButtonLabel"),
      saveCallBack: onDeleteConfirm,
    });
  };

  return (
    <>
      <a
        href={`/ap/expenses/${data.id}${searchAPIFilters && searchAPIFilters.length ? "?searchAPIFilters=" + searchAPIFilters : ""}`}
      >
        <button type="button" className="actionButtons view" data-toggle="tooltip" title="View"></button>
      </a>
      {expenseService.canEditOrDeleteExpenseItem(data, currentUser) && (
        <>
          {/* PMP Feature flag code that exposes icon to go to 'new' React edit item details page 
          <a href={`/ap/expense/${data.id}${searchAPIFilters && searchAPIFilters.length ? '?searchAPIFilters=' + searchAPIFilters : ''}`}>
            <button type="button" className="actionButtons editIcon" data-toggle="tooltip" title="Edit"></button>
          </a>
          */}
          <Link to={`/ap/expense/${data.id}`}>
            <button type="button" className="actionButtons editIcon " data-toggle="tooltip" title="Edit"></button>
          </Link>
          {!data.purchase_id && (
            <button
              type="button"
              className="actionButtons icon-delete mt-2"
              data-toggle="tooltip"
              title="Delete"
              onClick={createDeleteConfirmation}
            ></button>
          )}
        </>
      )}
    </>
  );
});

export const getExpenseItemHeaders = ({
  gridApi,
  currentUser,
}: {
  gridApi?: GridApi;
  currentUser: IUser;
}): { columnDefs: ColDef[]; defaultOrder: string[] } => {
  let columnDefs: ColDef[] = [
    {
      headerName: "Select",
      field: "select",
      filter: false,
      headerCheckboxSelection: true,
      // headerComponent: () => {
      //   if (gridApi && gridColumnApi) {
      //     return <SelectAll gridApi={gridApi} columnApi={gridColumnApi} ref={checkBoxRef} />;
      //   } else {
      //     return null;
      //   }
      // },
      headerValueGetter: function (params: HeaderValueGetterParams) {
        return "Select";
      },
      resizable: false,
      pinned: true,
      lockPinned: true,
      checkboxSelection: true,
      minWidth: 35,
      width: 35,
      maxWidth: 35,
    },
    {
      field: "id",
      headerName: "ID",
      sortable: true,
      hide: true,
      filter: "agTextColumnFilter",
      filterParams: {
        suppressAndOrCondition: true,
      },
      headerValueGetter: function () {
        return translate("ID");
      },
      minWidth: 100,
    },
    {
      field: "number",
      headerName: "Number",
      sortable: true,
      filter: "agTextColumnFilter",
      cellRendererParams: {
        viewLink: "expenseItems",
        authorization: {
          I: "read",
          a: "ExpenseItems",
        },
      },
      cellRenderer: "randomChildCellRouting",
      filterParams: {
        suppressAndOrCondition: true,
      },
      headerValueGetter: function () {
        return translate("expenseNo");
      },
      minWidth: 100,
    },
    {
      field: "purchase_order.number",
      headerName: "Purchase Order",
      hide: true,
      filter: "agTextColumnFilter",
      cellRendererParams: {
        redirectToAngular: true,
      },
      cellRenderer: "childCellRouting",
      filterParams: {
        suppressAndOrCondition: true,
      },
      headerValueGetter: function () {
        return translate("poNumber");
      },
      minWidth: 100,
    },
    {
      field: "purchase_order.open_balance",
      headerName: "poOpenBal",
      hide: true,
      filter: false,
      cellClass: "justify-content-end",
      cellRenderer: (params: any) =>
        !_.isNil(params.value) ? formattedAmount(params.value, params.data?.currency_code) : null,
      headerValueGetter: function () {
        return translate("poOpenBal");
      },
      minWidth: 115,
    },
    {
      field: "vendor_name",
      headerName: "Merchant",
      hide: false,
      filter: "agTextColumnFilter",
      filterParams: {
        suppressAndOrCondition: true,
      },
      headerValueGetter: function () {
        return translate("merchant");
      },
      minWidth: 100,
    },
    {
      field: "date",
      headerName: "Date",
      sortable: true,
      sort: "desc",
      filter: "agDateColumnFilter",
      filterParams: {
        suppressAndOrCondition: true,
      },
      cellRenderer: (params: any) => {
        return companyDateFormat(params.value, currentUser);
      },
      headerValueGetter: function () {
        return translate("date");
      },
      minWidth: 100,
    },
    {
      field: "total",
      headerName: "Total Amount",
      sortable: true,
      cellClass: "justify-content-end",
      filter: "agNumberColumnFilter",
      cellRenderer: (params: any) => formattedAmount(params.value, params.data?.currency_code, 2, true),
      filterParams: {
        suppressAndOrCondition: true,
      },
      headerValueGetter: function () {
        return translate("totalAmount");
      },
      minWidth: 110,
    },
    {
      field: "employee.name",
      headerName: "Employee",
      sortable: true,
      filter: "agTextColumnFilter",
      cellRenderer: (params: any) => {
        const name = `${params?.data?.employee?.first_name || ""} ${params?.data?.employee?.last_name || ""}`.trim();
        return isPlainObject(params.data.employee) ? (
          <a className="link" href={restApiService.makeAngularURLWithId("contacts/", params.data.employee.id)}>
            {name}
          </a>
        ) : null;
      },
      filterParams: {
        suppressAndOrCondition: true,
      },
      valueGetter: (params) =>
        `${params?.data?.employee?.first_name || ""} ${params?.data?.employee?.last_name || ""}`.trim(),
      headerValueGetter: function () {
        return translate("employee");
      },
      minWidth: 100,
      hide: false,
    },
    {
      field: "category_names",
      headerName: "Category",
      hide: false,
      filter: false,
      headerValueGetter: function () {
        return translate("category");
      },
      minWidth: 100,
    },
    {
      field: "status",
      headerName: "Status",
      sortable: true,
      filter: "agTextColumnFilter",
      filterParams: {
        suppressAndOrCondition: true,
      },
      cellRenderer: (params: any) => {
        switch (params.value) {
          case "PENDING":
            return <span>PENDING APPROVAL</span>;
          default:
            return <span>{params.value}</span>;
        }
      },
      floatingFilterComponent: "agCustomSelectFilter",
      floatingFilterComponentParams: {
        suppressFilterButton: true,
        options: [
          { label: "ALL", value: "" },
          { label: "PENDING APPROVAL", value: ["PENDING"] },
          { label: "REJECTED", value: ["REJECTED"] },
          { label: "APPROVED", value: ["APPROVED"] },
          { label: "NEW", value: ["NEW"] },
          { label: "PAID", value: ["PAID", "PAID (PROCESSING)"] },
          { label: "DRAFT", value: ["DRAFT"] },
        ],
      },
      headerClass: styles.agGridHeaderColor,
      headerComponent: (params: any) => (
        <div draggable className="d-flex align-items-center">
          {translate("status")}
          <PopoverRender
            popoverClassName={"status-tooltip"}
            placement="auto"
            title="Status Definitions"
            content={
              <Row>
                <Col sm="3" className={styles.statusTitle}>
                  New:
                </Col>
                <Col sm="9">Newly created, needs to be reviewed and added to expense report</Col>
                <Col sm="3" className={styles.statusTitle}>
                  Draft:
                </Col>
                <Col sm="9">
                  Expense has been edited and saved as a draft, needs to be reviewed and added to expense report
                </Col>
                <Col sm="3" className={styles.statusTitle}>
                  Pending Approval:
                </Col>
                <Col sm="9"> Submitted for and awaiting approval</Col>
                <Col sm="3" className={styles.statusTitle}>
                  Approved:
                </Col>
                <Col sm="9"> All approvals completed</Col>
                <Col sm="3" className={styles.statusTitle}>
                  Paid:
                </Col>
                <Col sm="9"> Fully processed and expense reimbursement paid to requestor</Col>
                <Col sm="3" className={styles.statusTitle}>
                  Rejected:
                </Col>
                <Col sm="9"> Rejected during approval, no action needed</Col>{" "}
              </Row>
            }
            className="icon icon-info-blue mt-3 mx-1"
          />
        </div>
      ),
      headerValueGetter: function () {
        return translate("status");
      },
      minWidth: 80,
    },
    {
      field: "has_violations",
      headerName: "violation",
      cellRenderer: (params: any) =>
        params.value ? (
          <>
            <Link to={`/ap/expense/${params.data.id}`}>
              <TooltipRender
                title={
                  `<ul>` +
                  params?.data?.violations_messages
                    ?.map((str: string, i: number) => `<li key={${i}}>${str}</li>`)
                    .join("") +
                  `</ul>`
                }
                className="icon-20 icon-violation-warning bs-20"
                containerClassName="expense-tooltip"
                dangerouslySetInnerHTML={true}
              />
            </Link>
          </>
        ) : (
          ""
        ),
      headerClass: styles.agGridHeaderColor,
      headerComponent: (params: any) => {
        return (
          <div draggable className="d-flex align-items-center">
            {params.displayName}
            <TooltipRender
              title="Expenses with violations are missing information required by company policy. For additional details on the violation reason, open the Expense Item Details or hover over the Violations icon(s) below."
              className="icon-20 icon-questionmark bs-20"
            />
          </div>
        );
      },
      cellClass: "justify-content-center",
      filter: "agTextColumnFilter",
      filterParams: {
        suppressAndOrCondition: true,
      },
      floatingFilterComponent: "agCustomSelectFilter",
      floatingFilterComponentParams: {
        suppressFilterButton: true,
        options: [
          { value: true, label: "Has Violations" },
          { value: false, label: "No Violations" },
        ],
      },
      headerValueGetter: function () {
        return translate("violations");
      },
      minWidth: 100,
    },
    {
      field: "receipt_required",
      headerName: "Receipt required",
      sortable: false,
      filter: false,
      cellRenderer: (params: CellRendererParamsType) => {
        return params?.value;
      },
      headerValueGetter: function () {
        return translate("receiptRequired");
      },
      minWidth: 135,
    },
    {
      field: "has_assets",
      headerName: "hasAssets",
      cellClass: "justify-content-center",
      sortable: false,
      cellRenderer: (params: CellRendererParamsType) => {
        return gridApi && <UploadReceipt expenseItem={params.data} gridApi={gridApi} />;
      },
      filter: "agTextColumnFilter",
      filterParams: {
        suppressAndOrCondition: true,
      },
      floatingFilterComponent: "agCustomSelectFilter",
      floatingFilterComponentParams: {
        suppressFilterButton: true,
        options: [
          { value: false, label: "No" },
          { value: true, label: "Yes" },
        ],
      },
      headerValueGetter: function () {
        return translate("receiptAttached");
      },
      minWidth: 135,
    },
    {
      field: "expense_report.number",
      headerName: "Expense Report",
      sortable: true,
      cellRendererParams: {
        redirectToAngular: true,
      },
      cellRenderer: "childCellRouting",
      filter: "agTextColumnFilter",
      filterParams: {
        suppressAndOrCondition: true,
      },
      headerValueGetter: function () {
        return translate("expenseReportNumber");
      },
      minWidth: 140,
    },
    {
      field: "policy.name",
      headerName: "Policy",
      sortable: true,
      filter: "agTextColumnFilter",
      filterParams: {
        suppressAndOrCondition: true,
      },
      headerValueGetter: function () {
        return translate("policy");
      },
      minWidth: 100,
    },
    {
      field: "reimbursable",
      headerName: "For Reimbursement?",
      headerComponent: (params: any) => (
        <div draggable className="d-flex align-items-center">
          <div className="ag-header-cell-label" role="presentation">
            <span className="ag-header-cell-text">{translate("reimbursable")}</span>
          </div>
          <PopoverRender
            popoverClassName={"status-tooltip"}
            placement="auto"
            content={
              <Row>
                <Col md="12" sm="12" xl="12">
                  Expenses marked for reimbursement will be paid directly to the requestor - these include any expenses
                  using personal credit cards or cash. Corporate card purchases are not eligible for reimbursement.
                </Col>
              </Row>
            }
            className="icon icon-info-blue mt-3 mx-1"
          />
        </div>
      ),
      cellRenderer: (params: any) => <>{params?.value ? "Yes" : "No"}</>,
      cellClass: "justify-content-center",
      filter: "agTextColumnFilter",
      filterParams: {
        suppressAndOrCondition: true,
      },
      floatingFilterComponent: "agCustomSelectFilter",
      floatingFilterComponentParams: {
        suppressFilterButton: true,
        options: [
          { value: true, label: "Yes" },
          { value: false, label: "No" },
        ],
      },
      headerValueGetter: function () {
        return translate("reimbursable");
      },
      minWidth: 170,
    },
    {
      field: "currency_code",
      headerName: "Currency",
      sortable: true,
      filter: "agTextColumnFilter",
      filterParams: {
        suppressAndOrCondition: true,
      },
      headerValueGetter: function () {
        return translate("currency_code");
      },
      minWidth: 120,
      hide: true,
    },
    {
      field: "submit_date",
      headerName: "Submit Date",
      hide: true,
      sortable: true,
      filter: "agDateColumnFilter",
      filterParams: {
        suppressAndOrCondition: true,
      },
      cellRenderer: (params: any) => {
        return companyDateFormat(params.value, currentUser);
      },
      headerValueGetter: function () {
        return translate("submitDate");
      },
      minWidth: 115,
    },
    {
      field: "item_type",
      headerName: "item_type",
      sortable: true,
      filter: "agTextColumnFilter",
      filterParams: {
        suppressAndOrCondition: true,
      },
      floatingFilterComponent: "agCustomSelectFilter",
      floatingFilterComponentParams: {
        suppressFilterButton: true,
        options: [
          { value: "SINGLE_EXPENSE", label: "SINGLE_EXPENSE" },
          { value: "MILEAGE", label: "MILEAGE" },
          { value: "PER_DIEM", label: "PER_DIEM" },
        ],
      },
      headerValueGetter: function () {
        return translate("itemType");
      },
      minWidth: 100,
      hide: true,
    },
    {
      field: "subsidiary.name",
      headerName: "Subsidiary",
      sortable: true,
      filter: "agTextColumnFilter",
      filterParams: {
        suppressAndOrCondition: true,
      },
      headerValueGetter: function () {
        return translate("subsidiary");
      },
      minWidth: 100,
      hide: true,
    },
    {
      field: "project_name",
      headerName: "Project",
      hide: true,
      filter: "agTextColumnFilter",
      filterParams: {
        suppressAndOrCondition: true,
      },
      headerValueGetter: function () {
        return translate("project");
      },
      minWidth: 100,
    },
    {
      field: "submitter.name",
      headerName: "Submitter",
      sortable: true,
      filter: "agTextColumnFilter",
      filterParams: {
        suppressAndOrCondition: true,
      },
      headerValueGetter: function () {
        return translate("submitter");
      },
      minWidth: 100,
      hide: true,
    },
    {
      field: "Actions",
      cellRenderer: (params: any) => gridApi && <Actions data={params?.data} gridApi={gridApi} />,
      sortable: false,
      resizable: false,
      filter: false,
      headerValueGetter: function () {
        return translate("actions");
      },
      minWidth: 120,
    },
  ];
  const param: any = {};
  setAllowedFilters(columnDefs, FIELD_NAME_MAP);
  const defaultOrder = columnDefs.map((col: any) => col.field);
  return {
    columnDefs: columnDefs,

    // sortBy(
    //   columnDefs,
    //   col => col?.headerValueGetter && typeof col?.headerValueGetter === "function" && col.headerValueGetter(param)
    // ),
    defaultOrder,
  };
};
