import LoadingBox from "components/common/loaders/loadingBox";
import _ from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Button, Col, Container, Modal, Row } from "react-bootstrap";
import { useHistory, useParams } from "react-router";
import { InjectedFormProps, reduxForm } from "redux-form";
import { extractFileFromURI } from "services/general/helpers";
import Styles from "../../../../pages/admin/purchaseOrders/changeOrders/purchaseOrders.module.css";
import { restApiService } from "../../../../providers/restApi";
import { useTypedSelector } from "../../../../reducers";
import {
  ChangeOrderObjectOptionsType,
  ChangeOrderOptionsType,
  FormComponentCustomPropsType,
} from "../../../../services/admin/purchaseOrders/changeOrders/purchaseOrders.types";
import { ChangeOrdersPOFormService } from "../../../../services/admin/purchaseOrders/changeOrders/purchaseOrdersForm";
import PurchaseOrdersApis from "../../../../services/admin/purchaseOrders/purchaseOrderApi";
import { CreateNotification, NotificationType } from "../../../../services/general/notifications";
import { getFormDifference } from "../../../../services/general/reduxFormSvc";
import TabNavigation from "../../../navigation/tabNavigation";

const { modalHeaderDatesMsg, modalHeaderDescription, modalHeaderTitle, bgButton } = Styles;

const purchaseOrdersForm = new ChangeOrdersPOFormService("create");

let CreateChangeOrder = ({
  children,
  initialize,
  handleSubmit,
}: InjectedFormProps<ChangeOrderOptionsType, FormComponentCustomPropsType> & FormComponentCustomPropsType) => {
  const { po_id } = useParams<{ po_id: string }>();
  const currentPurchaseOrderId = useTypedSelector((state) => purchaseOrdersForm.getCurrentPOId(state));
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const tabRoutes = useMemo(
    () => [
      {
        path: `/ap/change_orders/purchase_orders/${po_id}/add`,
        pageName: "Main",
        isActive: "",
      },
      {
        path: `/ap/change_orders/purchase_orders/${po_id}/add/po_items`,
        pageName: "PO Items",
        isActive: "",
      },
      {
        path: `/ap/change_orders/purchase_orders/${po_id}/add/expenses`,
        pageName: "Expenses",
        isActive: "",
      },
    ],
    [po_id],
  );

  const getPurchaseOrderDetails = useCallback(async (id) => {
    let purchaseOrderDetails: ChangeOrderObjectOptionsType | null = await PurchaseOrdersApis.getPODetails(id);
    if (purchaseOrderDetails) {
      purchaseOrderDetails = ChangeOrdersPOFormService.processComparisonData(
        purchaseOrderDetails,
        purchaseOrderDetails,
      );
      ChangeOrdersPOFormService.prepareObjectChanges(purchaseOrderDetails);
      purchaseOrderDetails.initialValues = _.cloneDeep(purchaseOrderDetails);
      initialize({
        file_uris: [],
        object_changes: purchaseOrderDetails,
        change_requestable_id: purchaseOrderDetails.id,
        change_requestable_type: "PurchaseOrder",
      });
    }
  }, []);

  const uploadAssets = useCallback(async (id, uris: { name: string; uri: string }[] | undefined) => {
    try {
      let formData = new FormData();
      if (_.isArray(uris) && uris.length) {
        for (let i = 0; i < uris.length; i++) {
          const url = uris[i].uri;
          const file = await extractFileFromURI(url, uris[i].name);
          formData.append(`change_request[assets_attributes][${i}][asset_file]`, file);
        }
        const res = await restApiService.patch(`change_requests/${id}`, null, formData);
        if (res.data) {
          CreateNotification("Attached", "Attachment Updated", NotificationType.success);
        }
      }
    } catch (error) {
      console.log(error);
    }
  }, []);

  const submit = useCallback(
    async (values: ChangeOrderOptionsType) => {
      setLoading(true);
      const file_uris = values.file_uris;
      const formValues = _.cloneDeep(values);
      const objectInitialValues = formValues.object_changes.initialValues;
      delete formValues.object_changes.initialValues;
      delete formValues.change_requestable; // unnecessary
      delete formValues.requestor; // unnecessary
      delete formValues.file_uris; // deleting from the copy, not the one above
      const difference = getFormDifference(
        formValues.object_changes,
        objectInitialValues,
        false,
      ) as ChangeOrderObjectOptionsType;
      ChangeOrdersPOFormService.filterPayload(difference);
      if (Object.keys(difference).length === 0) {
        CreateNotification("New Change Order", "No Changes Made.", NotificationType.danger);
        setLoading(false);
        return false;
      }
      formValues.object_changes = difference;
      const res = await restApiService.post("change_requests", null, { change_request: formValues });
      if (res && res.data) {
        await uploadAssets(res.data.id, file_uris);
        CreateNotification(
          "New Change Order",
          `Change Order - ${res.data.number} Submitted.`,
          NotificationType.success,
        );
        setTimeout(async () => {
          history.goBack();
        }, 1000);
      }
      setLoading(false);
    },
    [po_id],
  );

  useEffect(() => {
    if (po_id && currentPurchaseOrderId?.toString() !== po_id) {
      getPurchaseOrderDetails(po_id);
    }
  }, [po_id, currentPurchaseOrderId]);
  return (
    <Modal show={true} size="xl" centered>
      <Modal.Header className="pb-0">
        <Container fluid>
          <Row>
            <Col className={modalHeaderDatesMsg}>Edit start and end dates of this purchase order</Col>
          </Row>
          <Row className={modalHeaderTitle}>Request Change Order</Row>
          <Row className={modalHeaderDescription}>
            Change orders allow for editing multiple fields, including line items and expenses, as well as adjusting
            start and end dates.
          </Row>
          <Row>
            <TabNavigation navigationTab={tabRoutes} />
          </Row>
        </Container>
      </Modal.Header>
      <Modal.Body>
        <Container fluid>{children}</Container>
      </Modal.Body>
      <Modal.Footer>
        {loading ? (
          <Row>
            <Col>
              <LoadingBox isLoading={true} children={null} />
            </Col>
            <Col style={{ flex: 0 }} className="font-weight-bold px-0 pr-3">
              Submitting
            </Col>
          </Row>
        ) : (
          <>
            <Button
              variant="secondary"
              type="button"
              onClick={() => window.location.assign(restApiService.makeAngularURLWithId("purchase_orders/", po_id))}
            >
              Cancel
            </Button>
            <Button className={bgButton} type="button" onClick={handleSubmit(submit)}>
              Submit
            </Button>
          </>
        )}
      </Modal.Footer>
    </Modal>
  );
};

const Add = reduxForm<ChangeOrderOptionsType, FormComponentCustomPropsType>({
  touchOnChange: true,
  touchOnBlur: false,
  destroyOnUnmount: false,
  form: ChangeOrdersPOFormService.CREATE_CHANGE_ORDER_PO_FORM_NAME,
})(CreateChangeOrder);

export default Add;
