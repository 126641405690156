import { AxiosResponse } from "axios";
import { restApiService } from "providers/restApi";
import React, { useCallback, useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import InputDropdownFilter from "./inputDropdown";

const ContactsOptionsFilter = ({
  name,
  code,
  options,
  isRequired,
  isMulti,
  customFilters,
}: {
  name: string;
  code: string;
  options: { [key: string]: any }[];
  isRequired?: boolean;
  isMulti?: boolean;
  customFilters?: URLSearchParams;
}) => {
  const [contactOptions, setContactOptions] = useState<{ label: string; value: string }[]>([]);

  const loadContactsOptions = useCallback(async () => {
    try {
      const response: AxiosResponse<any> = await restApiService.get(
        `contacts${customFilters ? "?" + customFilters : ""}`,
      );
      const contacts: { label: string; value: string }[] = [];
      if (!isMulti) {
        contacts.push({ label: "ALL", value: "" });
      }
      response.data.forEach((contact: { name: string; id: string }) => {
        contacts.push({ label: contact.name, value: contact.id });
      });
      setContactOptions(contacts);
    } catch (error) {
      throw error;
    }
  }, [customFilters, isMulti]);

  useEffect(() => {
    loadContactsOptions();
  }, [loadContactsOptions]);

  const renderDropdown = () => {
    if (contactOptions.length > 1) {
      return (
        <InputDropdownFilter
          name={name}
          code={code}
          options={contactOptions}
          isRequired={isRequired}
          isMulti={isMulti}
        />
      );
    } else {
      return (
        <div>
          <h5>Loading Contacts Filter...</h5>
          <Spinner animation="border" variant="info"></Spinner>
        </div>
      );
    }
  };

  return <>{renderDropdown()}</>;
};

export default ContactsOptionsFilter;
