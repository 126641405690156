import { uniqBy } from "lodash";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import Dropzone from "react-dropzone";
import "./fileUploader.css";

type FileUploaderNonModalPropsType = {
  files?: { uri: string; name: string }[];
  onFilesUpdated: (filesAttributes: any) => Promise<void> | void;
  multiple?: boolean;
  accept?: string[];
  maxFiles?: number;
  maxSize?: number; // in bytes
  className?: string;
  containerClassName?: string;
};

type LocalFileAttributesType = { uri: string; name: string };

export const FileUploaderNonModal = ({
  files,
  onFilesUpdated,
  multiple,
  accept,
  maxFiles,
  maxSize,
  className,
  containerClassName,
}: FileUploaderNonModalPropsType) => {
  const [filesAttributes, setFilesAttributes] = useState<LocalFileAttributesType[]>([]); //file
  const filesAttributesRef = useRef<LocalFileAttributesType[]>([]);

  const setFiles = useCallback(
    (acceptedFiles: File[]) => {
      const newFileAttributes = acceptedFiles.map((f) => ({ uri: URL.createObjectURL(f as Blob), name: f.name }));
      filesAttributesRef.current = uniqBy([...filesAttributesRef.current, ...newFileAttributes], "name");
      if (maxFiles) {
        filesAttributesRef.current = filesAttributesRef.current.slice(-maxFiles);
      }
      onFilesUpdated(filesAttributesRef.current);
      setFilesAttributes([...filesAttributesRef.current]);
    },
    [maxFiles],
  );

  const removeFile = (file: LocalFileAttributesType) => {
    filesAttributesRef.current = filesAttributesRef.current.filter((item: any) => item.name !== file.name);
    onFilesUpdated(filesAttributesRef.current);
    setFilesAttributes([...filesAttributesRef.current]);
  };

  useEffect(() => {
    if (files?.length) {
      filesAttributesRef.current = files;
      setFilesAttributes([...files]);
    }
  }, []);

  return (
    <Container className={`px-0 file-uploader ${containerClassName ?? ""}`}>
      <Row className={`mx-0 py-3 border-dashed border-dark ${className ?? ""} dropZone`}>
        <Col className="px-0 d-flex justify-content-center align-items-center">
          <Dropzone onDrop={setFiles} maxFiles={maxFiles} maxSize={maxSize} accept={accept} multiple={multiple}>
            {({ getRootProps, getInputProps }) => (
              <Col className="p-0 h-100 d-flex flex-column justify-content-center" {...getRootProps()}>
                <Row>
                  <Col sm className="d-flex">
                    <p className="instructions">
                      Drag and drop file
                      <br />
                      or
                    </p>
                  </Col>
                </Row>
                <Row className="pt-2">
                  <Col sm className="d-flex justify-content-center">
                    <input {...getInputProps()} />
                    <Button className="btn py-2 px-2 browse-button" type="button">
                      Browse
                    </Button>
                  </Col>
                </Row>
                {accept && accept.length > 0 ? (
                  <Row className="pt-2">
                    <Col sm className="d-flex justify-content-center acceptedTypes">
                      Accepted file types: {accept.join(", ")}
                    </Col>
                  </Row>
                ) : null}
              </Col>
            )}
          </Dropzone>
        </Col>
      </Row>
      <Row className="mx-0">
        <Col className="px-0">
          {filesAttributes.length
            ? filesAttributes.map((file, index) => (
                <Row className="mt-2" key={index}>
                  <Col className="file-to-upload">
                    {file.name}
                    <Button variant="link" title="Remove file" onClick={() => removeFile(file)}>
                      <i className="icon icon-delete m-0" />
                    </Button>
                  </Col>
                </Row>
              ))
            : null}
        </Col>
      </Row>
    </Container>
  );
};
