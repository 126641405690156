import useGetValue from "components/admin/hooks/hookFormHooks/useGetValue";
import useIsMounted from "components/common/hooks/useIsMounted";
import PickerErrorBlock from "components/common/pickers/pickerErrorBlock";
import { Mandatory } from "components/forms/bootstrapFields";
import _ from "lodash";
import { restApiService } from "providers/restApi";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Form } from "react-bootstrap";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import Select, { SingleValue } from "react-select";
import { useTypedSelector } from "reducers";
import commonService from "services/common/commonSvc";
import { IUser } from "services/common/user/userTypes";
import { parseForSelect } from "services/general/helpers";
import { CreateNotification, NotificationType } from "services/general/notifications";
import { DepartmentPickerType } from "./departmentPickerTypes";

const SinglePicker = ({
  label,
  required,
  name,
  modelData,
  parentObj,
  params,
  isPoHeader,
  callBack,
  isRequestPo,
  menuPosition = "fixed",
  menuPlacement = "auto",
  containerClassName,
  disabled,
}: DepartmentPickerType.TDepartmentPickerProps) => {
  const [departments, setDepartments] = useState<DepartmentPickerType.TDepartmentsListOptions[]>([]);
  const currentUser: IUser = useTypedSelector((state) => state.user);
  const isMounted = useIsMounted();
  const [selected, setSelected] = useState<DepartmentPickerType.TDepartmentsListOptions | null>(null);
  // this ref is use to store old api params to avoid unnecessary api call
  const oldParams = useRef<any>(null);

  // This ref is to store previous department id
  //remember to use oldDepartmentId when using setSelected
  const oldDepartmentId = useRef<string | null>(null);

  const { control, getValues, setValue } = useFormContext();

  const isSubsidiaryHasDepartment = () => {
    const subsidiary = !_.isNil(parentObj) ? getValues(parentObj + "subsidiary") : {};
    return currentUser?.company?.global?.show_departments_by_subsidiary && subsidiary?.has_departments;
  };

  const [subsidiaryId] = useWatch({
    control,
    name: [
      ...["subsidiary_id"].map((parentDependencyName) => (!_.isNil(parentObj) ? parentObj + parentDependencyName : "")),
    ],
  });
  const departmentId = useGetValue(name);
  //we are fetching departmentId for mergeInactive (findSelected)
  const [locationId, businessUnitId, accountId, productItemId] = useWatch({
    control,
    name: [
      ...["location_id", "business_unit_id", "account_id", "product_item_id"].map((modelDependencyName) =>
        !_.isNil(modelData) ? modelData + modelDependencyName : "",
      ),
    ],
  });

  const findSelectedSingle = useCallback(
    async (options: DepartmentPickerType.TDepartmentsListOptions[], listChanged: boolean) => {
      if (!listChanged && oldDepartmentId.current === departmentId) {
        return;
      }

      if (departmentId) {
        oldDepartmentId.current = departmentId;
        const department = options.filter((option: any) => option.value === departmentId);
        const onlyShowMappedDepartment = !_.isNil(modelData) && getValues(modelData + "only_show_mapped_department");
        if (department.length === 0 && onlyShowMappedDepartment) {
          setValue(modelData + "only_show_mapped_department", false);
          oldDepartmentId.current = null;
          setValue(name, null);
          setSelected(null);
        } else if (department.length === 0 && !isSubsidiaryHasDepartment()) {
          // Fetch inactive departments from the API and enable them to be appear in the department dropdown.
          try {
            const result = await restApiService.get("departments/" + departmentId);
            const inactiveDepartment: any = {
              value: result.data.id,
              label: (
                <>
                  {result.data.name} - <small style={{ fontSize: "10px" }}>({result.data.status})</small>
                </>
              ),
            };
            setSelected(inactiveDepartment);
          } catch (error) {
            console.log(error);
            CreateNotification("Error", "Problem loading inactive department.", NotificationType.danger);
          }
        } else {
          setSelected(department[0]);
        }
      } else {
        oldDepartmentId.current = null;
        setSelected(null);
      }
    },
    [departmentId, getValues, isSubsidiaryHasDepartment, modelData, name, setValue],
  );

  const getParentObjParams = useCallback(
    (apiParams: any) => {
      const [parentSubsidiary] = getValues([
        ...["subsidiary"].map((parentDependencyName) =>
          parentObj ? parentObj + parentDependencyName : parentDependencyName,
        ),
      ]);
      if (currentUser?.company?.global?.show_departments_by_subsidiary && parentSubsidiary?.has_departments) {
        apiParams.subsidiary_id = parentSubsidiary?.id;
        setValue(modelData + "only_show_mapped_department", true);
      }
    },
    [currentUser?.company?.global?.show_departments_by_subsidiary, getValues, modelData, parentObj, setValue],
  );

  const getModelDataParams = (apiParams: any) => {
    const [location, isProductItemLinkToDepartment, businessUnit] = getValues([
      ...["location", "is_product_item_link_to_department", "business_unit"].map((modelDependencyName) =>
        modelData ? modelData + modelDependencyName : modelDependencyName,
      ),
    ]);
    if (location?.is_location_map_to_department && locationId) {
      apiParams.location_id = location?.id;
      setValue(modelData + "only_show_mapped_department", true);
    }

    if (isProductItemLinkToDepartment) {
      apiParams.product_item_id = productItemId;
      setValue(modelData + "only_show_mapped_department", true);
    }

    if (businessUnit?.has_departments) {
      apiParams.business_unit_id = businessUnitId;
      setValue(modelData + "only_show_mapped_department", true);
    }
  };

  const getDepartments = useCallback(async () => {
    let apiParams: any = { status: "ACTIVE" };
    if (
      ((isRequestPo && currentUser?.company?.po_request_data_restrict_by_subsidiary) || (isRequestPo && isPoHeader)) &&
      subsidiaryId > 0
    ) {
      apiParams.subsidiary_id = subsidiaryId;
    }

    if (isRequestPo && currentUser?.company?.po_request_auto_set_department) {
      apiParams.po_request = true;
    }

    // if (!_.isNil(modelData)) {
    //   getModelDataParams(apiParams);
    // }

    if (!_.isNil(parentObj)) {
      getParentObjParams(apiParams);
    }

    // 'has_account_structures' becomes true if account_structures is uploaded
    // In this case, department picker will override all previous parameters
    // and send has_account_structures's parameters to departments.lk API.
    // if (currentUser?.company?.has_account_structures) {
    //   apiParams = { status: "ACTIVE", account_structure: true };
    //   apiParams.account_structure = true;

    //   if (accountId && !_.isNil(modelData)) {
    //     apiParams.account_id = accountId;
    //     setValue(modelData + "only_show_mapped_department", true);
    //   }

    //   if (subsidiaryId && !_.isNil(parentObj)) {
    //     apiParams.subsidiary_id = subsidiaryId;
    //   }
    // }

    // if (params) {
    //   apiParams = { status: "ACTIVE", ...params };
    // }

    if (!_.isEqual(oldParams.current, apiParams)) {
      try {
        oldParams.current = apiParams;
        const result = await restApiService.get("departments.lk", apiParams, null, true, null, true);
        let list = parseForSelect(result.data);
        if (isMounted.current) {
          if (!required) {
            list = [
              {
                label: "-- Select Department --",
                value: "",
                id: "",
                status: undefined,
              },
              ...list,
            ];
          }
          findSelectedSingle(list, true);
          setDepartments(list);
        }
      } catch (error) {
        console.log("%cError in fetching department" + error, "color: red");
      }
    } else {
      oldParams.current = apiParams;
      findSelectedSingle(departments, false);
    }
  }, [
    currentUser?.company?.po_request_auto_set_department,
    currentUser?.company?.po_request_data_restrict_by_subsidiary,
    departments,
    findSelectedSingle,
    getParentObjParams,
    isMounted,
    isPoHeader,
    isRequestPo,
    parentObj,
    required,
    subsidiaryId,
  ]);

  const onChangeDepartment = (selected: SingleValue<DepartmentPickerType.TDepartmentsListOptions>, onChange: any) => {
    let selectedId = selected?.id || null;
    oldDepartmentId.current = selectedId;
    if (!_.isNil(modelData)) {
      setValue(modelData + "department", selected);
    }
    onChange(selectedId);
    setSelected(selected);
    if (callBack) {
      callBack(selected);
    }
  };

  useEffect(() => {
    getDepartments();
  }, [departmentId, subsidiaryId, locationId, businessUnitId, accountId, productItemId, getDepartments]);

  return (
    <Form.Group className={`${containerClassName ?? ""}`}>
      {label && (
        <Form.Label className="pickerLabel">
          {label} <Mandatory required={required} />
        </Form.Label>
      )}
      <Controller
        control={control}
        name={name}
        rules={{ required: required ? "This field is required" : false }}
        render={({ field: { value, onChange, ref }, fieldState: { error, invalid } }) => (
          <>
            <Select
              ref={ref}
              getOptionLabel={(option: any): any => {
                return (
                  <>
                    <span className="formField">{option.label}</span>
                    {option?.status === "INACTIVE" && <small style={{ fontSize: "10px" }}> -({option?.status})</small>}
                  </>
                );
              }}
              filterOption={(option: any, searchText) =>
                option?.data?.label?.toLowerCase().includes(searchText.toLowerCase())
              }
              isClearable={!required}
              getOptionValue={(option) => option?.id as string}
              options={departments}
              menuPlacement={menuPlacement}
              menuPosition={menuPosition}
              className="pickerSelectedOption"
              onChange={(value) => onChangeDepartment(value, onChange)}
              isDisabled={disabled}
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  ...commonService.getInvalidStyle(Boolean(error?.message)),
                }),
              }}
              value={value ? selected : null}
            />
            {error && invalid && <PickerErrorBlock error={error?.message || ""} />}
          </>
        )}
      />
    </Form.Group>
  );
};

export default SinglePicker;
