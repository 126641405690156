import React, { useEffect, useState } from "react";
import { Button, Col, Container, Nav, Row, Table } from "react-bootstrap";
import { BreadcrumbTrail } from "../../../../navigation/navigationLinks";
import { useParams } from "react-router";
import { restApiService } from "../../../../../providers/restApi";
import { Can } from "../../../../../services/authorization/authorization";
import { useTranslation } from "react-i18next";
import style from "../../purchaseOrders.module.css";
import useIsMounted from "../../../../common/hooks/useIsMounted";
import PurchaseOrdersApis from "../../../../../services/admin/purchaseOrders/purchaseOrderApi";
import { PurchaseOrderType } from "../../../../../services/admin/purchaseOrders/purchaseOrderType";
import { useTypedSelector } from "../../../../../reducers";
import { companyDateFormat, companyDateTimeFormat } from "../../../../../services/general/dateSvc";
import _ from "lodash";
import { CreateNotification, NotificationType } from "../../../../../services/general/notifications";
import { ApprovalType } from "../../../../../services/admin/approvals/approvalsType";
import ApprovalsApis from "../../../../../services/admin/approvals/approvalsApis";
import approvalsService from "../../../../../services/admin/approvals/approvalsSvc";
import { Link } from "react-router-dom";
import { PoChangeOrderType } from "../../../../../services/admin/changeOrders/poChangeOrderApprovalsType";
import useConfirmModal from "../../../../modals/confirmModal/useConfirmModalHook";
import RejectModal from "./rejectModal";
import ErrorBoundary from "../../../../common/errorBoundary/errorBoundary";
import ChangeOrderApis from "../../../../../services/admin/changeOrders/changeOrderApis";
import PoLineItemsTable from "../../../objectChanges/poLineItemsTable";
import ExpenseLineTable from "../../../objectChanges/expenseLineTable";
import AttachmentSection from "../../../../common/attachmentSection/attachmentSection";
import { IDType } from "../../../../../services/common/types/common.type";

type FileDetails = {
  path?: string;
  lastModified?: number;
  lastModifiedDate?: string;
  name?: string;
  size?: number;
  type?: string;
  id?: IDType;
  webkitRelativePath?: string;
};

const DetailsApprovalChangeOrders = () => {
  const { t } = useTranslation();
  const { id: currentActionableApprovalId } = useParams<{ id: string }>();
  const isMounted = useIsMounted();
  const currentUser = useTypedSelector((state) => state.user);
  const [assets, setAssets] = useState<FileDetails[]>([]);
  const [actionableApprovalDetails, setActionableApprovalDetails] = useState<ApprovalType.DetailsType>();
  const [poChangeOrderDetail, setPoChangeOrderDetail] = useState<PoChangeOrderType.DetailType>();
  const [purchaseOrderDetail, setPurchaseOrderDetail] = useState<PurchaseOrderType.DetailType>();
  const [poChangeOrderApprovalList, setPoChangeOrderApprovalList] = useState<PoChangeOrderType.ApprovalListType[]>([]);
  const previouseActionApprovable = approvalsService.getPreviousActionApprovable(
    poChangeOrderApprovalList,
    currentActionableApprovalId,
  );
  const nextActionApprovable = approvalsService.getNextActionApprovable(
    poChangeOrderApprovalList,
    currentActionableApprovalId,
  );

  const { createConfirmModal } = useConfirmModal();
  const [showRejectModel, setShowRejectModel] = useState(false);

  const getActionalbleApprovalDetails = async () => {
    try {
      const result = await ApprovalsApis.getActionApprovalDetails(currentActionableApprovalId);
      if (isMounted.current) {
        setActionableApprovalDetails(result);
      }
    } catch (error) {}
  };

  const getPoChangeOrderDetail = async () => {
    try {
      if (actionableApprovalDetails?.approvable_id) {
        const result = await ChangeOrderApis.getChangeOrderDetail<PoChangeOrderType.DetailType>(
          actionableApprovalDetails.approvable_id,
        );
        if (isMounted.current) {
          setPoChangeOrderDetail(result);
          setAssets(result.assets_attributes);
        }
      }
    } catch (error) {}
  };

  const getChangeRequestablePODetail = async () => {
    try {
      if (poChangeOrderDetail?.change_requestable_id) {
        const result = await PurchaseOrdersApis.getPurchaseOrder(poChangeOrderDetail.change_requestable_id);
        if (isMounted.current) {
          setPurchaseOrderDetail(result);
        }
      }
    } catch (error) {}
  };

  const getPoChangeOrderList = async () => {
    try {
      const result = await ApprovalsApis.getPoChangeOrderApprovalList();
      if (isMounted.current) {
        setPoChangeOrderApprovalList(result);
      }
    } catch (error) {}
  };

  useEffect(() => {
    getActionalbleApprovalDetails();
  }, [currentActionableApprovalId]);

  useEffect(() => {
    getPoChangeOrderDetail();
  }, [actionableApprovalDetails?.approvable_id]);

  useEffect(() => {
    getChangeRequestablePODetail();
  }, [poChangeOrderDetail?.change_requestable_id]);

  useEffect(() => {
    if (poChangeOrderDetail?.status === "OPEN" && poChangeOrderDetail?.change_requestable_id) {
      window.location.assign(
        restApiService.makeAngularURLWithId("purchase_orders/", poChangeOrderDetail?.change_requestable_id),
      );
    }
  }, [poChangeOrderDetail?.status, poChangeOrderDetail?.change_requestable_id]);

  useEffect(() => {
    getPoChangeOrderList();
  }, []);

  const isStartDateChanged = () => {
    return (
      (!purchaseOrderDetail?.start_date && poChangeOrderDetail?.object_changes?.start_date) ||
      (purchaseOrderDetail?.start_date &&
        poChangeOrderDetail?.object_changes?.start_date &&
        poChangeOrderDetail?.object_changes?.start_date !== purchaseOrderDetail?.start_date)
    );
  };

  const isEndDateChanged = () => {
    return (
      (!purchaseOrderDetail?.end_date && poChangeOrderDetail?.object_changes?.end_date) ||
      (purchaseOrderDetail?.end_date &&
        poChangeOrderDetail?.object_changes?.end_date &&
        poChangeOrderDetail?.object_changes?.end_date !== purchaseOrderDetail?.end_date)
    );
  };

  const isDescriptionChanged = () => {
    return (
      (!purchaseOrderDetail?.description && poChangeOrderDetail?.object_changes?.description) ||
      (purchaseOrderDetail?.description &&
        poChangeOrderDetail?.object_changes?.description &&
        poChangeOrderDetail?.object_changes?.description !== purchaseOrderDetail?.description)
    );
  };

  const claimApproval = async (approval: ApprovalType.ApprovalType) => {
    try {
      if (approval?.id) {
        await ApprovalsApis.claimApproval(approval?.id);
        CreateNotification("Claim", "Approval Claim", NotificationType.success);
        getPoChangeOrderDetail();
      }
    } catch (error) {}
  };

  const submit = async ({ approval, state }: { approval: ApprovalType.DetailsType; state: string }) => {
    await approvalsService.submit(approval, state, currentUser);
    CreateNotification("Approved", `Approved CO# ${poChangeOrderDetail?.number}`, NotificationType.success);
    setTimeout(() => window.location.reload(), 1000);
  };

  const onApprove = (approval: ApprovalType.DetailsType, state: string) => {
    createConfirmModal({
      title: "Confirm",
      body: `Are you sure you want to Approve ${poChangeOrderDetail?.number}?`,
      callBackData: { approval, state },
      saveCallBack: submit,
      cancelCallBack: null,
    });
  };

  const onReject = (approval: any, state: string) => {
    toggleShowReject();
  };
  const toggleShowReject = () => {
    setShowRejectModel((prev) => !prev);
  };

  const getStatusClass = function (status: string) {
    if (status === "DRAFT") {
      return "status_draft";
    } else if (status === "PENDING") {
      return "status_pending";
    } else if (status === "REJECTED" || status === "INACTIVE") {
      return "status_rejected";
    } else if (status) {
      return "status_approved";
    }
  };

  const onUploadAttachment = async (files: File) => {
    try {
      const formData = new FormData();
      if (_.isArray(files)) {
        files.forEach((file, index) => {
          formData.append(`change_request[assets_attributes][${index}][asset_file]`, file);
          formData.append(`change_request[assets_attributes][${index}][file_name]`, file.name);
        });
      }
      const res = await restApiService.patch(`change_requests/${poChangeOrderDetail?.id}`, null, formData);
      if (res.data) {
        setAssets(res.data.assets_attributes);
        CreateNotification(t("Attached"), t("Attachment Updated"), NotificationType.success);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const deleteAttachementConfirm = async (assetId: number | string) => {
    const patchData = { assets_attributes: { id: assetId, _destroy: 1 } };
    const response = await restApiService.patch(`change_requests/${poChangeOrderDetail?.id}`, null, {
      change_request: patchData,
    });
    CreateNotification("Deleted Attachment", "", NotificationType.info);
    // update local company state
    // setCompany(response.data);
  };

  const onDeleteAttachment = async (assetId: string | number) => {
    createConfirmModal({
      title: "Confirm",
      body: `Do you really want delete Attachment with ${assetId}`,
      callBackData: assetId,
      saveCallBack: deleteAttachementConfirm,
      cancelCallBack: null,
    });
  };

  return (
    <Container fluid>
      {showRejectModel && actionableApprovalDetails && poChangeOrderDetail && (
        <RejectModal
          showRejectModel={showRejectModel}
          closeShowReject={toggleShowReject}
          poChangeOrderDetail={poChangeOrderDetail}
          actionableApprovalDetails={actionableApprovalDetails}
        />
      )}
      <Row className="mt-2">
        <Col xs="6">
          {previouseActionApprovable && previouseActionApprovable?.actionable_approval?.id && (
            <Link
              to={`/ap/po_co_approvals/${previouseActionApprovable?.actionable_approval?.id}`}
              className={style.link}
            >
              <Nav.Item>
                <i className="icon icon-arrow-left-blue m-0"></i>
                {t("admin.pages.poCoApproval.prevChangeOrder")}
              </Nav.Item>
            </Link>
          )}
        </Col>
        <Col xs="6" className="text-right">
          {nextActionApprovable && nextActionApprovable?.actionable_approval?.id && (
            <Link to={`/ap/po_co_approvals/${nextActionApprovable?.actionable_approval?.id}`} className={style.link}>
              <Nav.Item>
                {t("admin.pages.poCoApproval.nextChangeOrder")}
                <i className="icon icon-arrow-right-blue m-0 float-right "></i>
              </Nav.Item>
            </Link>
          )}
        </Col>
      </Row>

      <Row className="mt-3">
        <Col xs="12">
          <BreadcrumbTrail to={"/ap/po_co_approvals"} pageName={"Change Orders"} title={poChangeOrderDetail?.number} />
        </Col>
      </Row>

      <Row>
        <Col xs="8" className="col-xs-8">
          <h2 id="po_change_order_for_approval-number">{poChangeOrderDetail?.number}</h2>
          {(purchaseOrderDetail?.po_type === "SINGLE VENDOR" || purchaseOrderDetail?.po_type === "CORPORATE CARD") && (
            <h4>
              <a
                href={restApiService.angularBaseURL() + "vendors/" + purchaseOrderDetail?.vendor?.id}
                className={style.link}
              >
                {purchaseOrderDetail?.vendor?.name}
              </a>
              {purchaseOrderDetail?.vendor?.status && (
                <span
                  className={`${style.vendorStatusText} mx-2 ${getStatusClass(purchaseOrderDetail?.vendor?.status)}`}
                  title="Vendor Status"
                >
                  {purchaseOrderDetail?.vendor?.status}
                </span>
              )}
              <span className={`${style.font16}`}>({purchaseOrderDetail?.po_type})</span>
            </h4>
          )}
          {purchaseOrderDetail?.po_type !== "SINGLE VENDOR" && purchaseOrderDetail?.po_type !== "CORPORATE CARD" && (
            <h4>{purchaseOrderDetail?.vendor?.name}</h4>
          )}
        </Col>
        <Col xs="4" className="section-description">
          <span className="float-right">
            <h3>{t("admin.pages.poCoApproval.requestor")}</h3>
            {/* TODO: expose change order requestor name here  */}
            <p id="po_co_for_approval-requestor-name">{poChangeOrderDetail?.requestor_id || "N/A"}</p>
          </span>
        </Col>
      </Row>

      <Row className="mt-2">
        <Col sm="6">
          {/* 
          // uncomment if required 
          <Button
            variant="secondary"
            id="po_co_for_approval_open_chat"
            ng-click="toggle_message_panel_general('purchase_orders', parentobj.currentPO.id);"
            className="mr-2"
          >
            <i className="icon icon-open-chat" aria-hidden="true"></i> Message
          </Button> */}

          {/* Commented temporary 
          <Button
            variant="secondary"
            className="mr-2"
            ng-click="poCommonSvc.file_download(purchase_order);"
            id="po_change_order_for_approval-export_pdf"
          >
            <i className="icon m-0 icon-export"></i> Download PDF
          </Button> */}

          {poChangeOrderDetail?.status !== "OPEN" && (
            <Can I="add" a="PurchaseOrders">
              <Link
                to={{
                  pathname: `/ap/change_orders/${poChangeOrderDetail?.id}/edit`,
                  state: { isFromDetailPage: false },
                }}
              >
                <Button>
                  <Nav.Item>
                    <i className={"icon icon-pencil " + style.icon14}></i>
                    {t("admin.pages.poCoApproval.editChangeOrder")}
                  </Nav.Item>
                </Button>
              </Link>
            </Can>
          )}
        </Col>
        <Col sm="6" className="d-flex justify-content-end">
          {actionableApprovalDetails &&
          actionableApprovalDetails.aasm_state !== "rejected" &&
          actionableApprovalDetails.aasm_state !== "approved" ? (
            <>
              <Button
                variant="secondary"
                onClick={() => onReject(actionableApprovalDetails, "rejected")}
                className="mr-2"
              >
                <i className={"icon icon-times " + style.icon14}></i> Reject
              </Button>
              <Button
                onClick={() => {
                  if (actionableApprovalDetails) onApprove(actionableApprovalDetails, "approved");
                }}
              >
                <i className={"icon icon-check " + style.icon14}></i> Approve
              </Button>
            </>
          ) : (
            poChangeOrderDetail && (
              <div className={poChangeOrderDetail.status === "REJECTED" ? style.rejectedStatus : style.approvalStatus}>
                <i
                  className={`icon ${poChangeOrderDetail.status === "REJECTED" ? "icon-approval-rejected" : "icon-approval-pending"} ${style.icon22}`}
                ></i>
                <div style={{ paddingTop: 3 }}>{poChangeOrderDetail.status}</div>
              </div>
            )
          )}
        </Col>
      </Row>

      <Row className="mt-4">
        <Col xs="12">
          <Table bordered responsive className={`text-left ${style.table} `}>
            <tbody>
              <tr>
                <th>{t("admin.pages.poCoApproval.vendor")}</th>
                <th>{t("admin.pages.poCoApproval.vendorId")}</th>
                <th>{t("admin.pages.poCoApproval.address")}</th>
                <th>{t("admin.pages.poCoApproval.date")}</th>
                <th>{t("admin.pages.poCoApproval.terms")}</th>
                <th>{t("admin.pages.poCoApproval.cardRequired")}</th>
              </tr>
              <tr>
                <td>
                  {(purchaseOrderDetail?.po_type === "SINGLE VENDOR" ||
                    purchaseOrderDetail?.po_type === "CORPORATE CARD") && (
                    <a
                      href={restApiService.angularBaseURL() + "vendors/" + purchaseOrderDetail?.vendor?.id}
                      className={style.link}
                    >
                      {purchaseOrderDetail?.vendor?.name}
                    </a>
                  )}
                  {purchaseOrderDetail?.po_type !== "SINGLE VENDOR" &&
                    purchaseOrderDetail?.po_type !== "CORPORATE CARD" && <>{purchaseOrderDetail?.vendor?.name}</>}
                </td>
                <td>{purchaseOrderDetail?.vendor?.id}</td>
                <td>
                  {purchaseOrderDetail?.vendor?.address1} {purchaseOrderDetail?.vendor?.address2}
                  {"  "}
                  {purchaseOrderDetail?.vendor?.address3}
                  {"  "}
                  {purchaseOrderDetail?.vendor?.city}
                  {"  "}
                  {purchaseOrderDetail?.vendor?.zipcode}
                  {"  "}
                  {purchaseOrderDetail?.vendor?.state}
                  {"  "}
                  {purchaseOrderDetail?.vendor?.country}
                </td>
                <td>{purchaseOrderDetail?.date}</td>
                <td>{purchaseOrderDetail?.term?.code}</td>
                <td>{purchaseOrderDetail?.require_virtual_card ? "Yes" : "No"}</td>
              </tr>
              <tr>
                <th>{t("admin.pages.poCoApproval.startDate")}</th>
                <th>{t("admin.pages.poCoApproval.endDate")}</th>
                {!currentUser?.company?.po_hide_department && <th>Department</th>}
                <th>Location</th>
                <th>Currency Code</th>
                <th>Subsidiary</th>
              </tr>
              <tr>
                <td className={`${isStartDateChanged() && style.updatedItem}`}>
                  {isStartDateChanged() && (
                    <>
                      <div className={style.previousValue}>
                        {companyDateFormat(purchaseOrderDetail?.start_date, currentUser) || "---"}
                      </div>
                      <div>{companyDateFormat(poChangeOrderDetail?.object_changes?.start_date, currentUser)}</div>
                    </>
                  )}
                  {!isStartDateChanged() && companyDateFormat(purchaseOrderDetail?.start_date, currentUser)}
                </td>

                <td className={`${isEndDateChanged() && style.updatedItem}`}>
                  {isEndDateChanged() && (
                    <>
                      <div className={style.previousValue}>
                        {companyDateFormat(purchaseOrderDetail?.end_date, currentUser) || "---"}
                      </div>
                      <div>{companyDateFormat(poChangeOrderDetail?.object_changes?.end_date, currentUser)}</div>
                    </>
                  )}
                  {!isEndDateChanged() && companyDateFormat(purchaseOrderDetail?.end_date, currentUser)}
                </td>
                {!currentUser?.company?.po_hide_department && <td>{purchaseOrderDetail?.department?.name}</td>}
                <td>{purchaseOrderDetail?.location?.name || "N/A"}</td>
                <td>{purchaseOrderDetail?.currency?.iso_code || "N/A"}</td>
                <td>{purchaseOrderDetail?.subsidiary?.name || "N/A"}</td>
              </tr>
            </tbody>
          </Table>
        </Col>
      </Row>

      <Row>
        <Col>Addresses</Col>
      </Row>

      <Row>
        <Col xs="12">
          <Table bordered responsive className={`text-left ${style.table} `}>
            <tbody>
              <tr>
                <th>{t("admin.pages.poCoApproval.shipTo")}</th>
                <th>{t("admin.pages.poCoApproval.startDate")}</th>
              </tr>
              <tr>
                <td>
                  <p className="address-content">{purchaseOrderDetail?.address?.label}</p>
                  <p className="address-content">
                    {purchaseOrderDetail?.address?.address1}, {purchaseOrderDetail?.address?.address2}{" "}
                    {purchaseOrderDetail?.address?.address3}
                  </p>
                  <p className="address-content">
                    {purchaseOrderDetail?.address?.city} {purchaseOrderDetail?.address?.state}{" "}
                    {purchaseOrderDetail?.address?.zipcode} {purchaseOrderDetail?.address?.country}
                  </p>
                </td>
                <td>
                  <p className="address-content">{purchaseOrderDetail?.billing_address?.label}</p>
                  <p className="address-content">
                    {purchaseOrderDetail?.billing_address?.address1}, {purchaseOrderDetail?.billing_address?.address2}{" "}
                    {purchaseOrderDetail?.billing_address?.address3}
                  </p>
                  <p className="address-content">
                    {purchaseOrderDetail?.billing_address?.city} {purchaseOrderDetail?.billing_address?.state}{" "}
                    {purchaseOrderDetail?.billing_address?.zipcode} {purchaseOrderDetail?.billing_address?.country}
                  </p>
                </td>
              </tr>
            </tbody>
          </Table>
        </Col>
      </Row>

      <ErrorBoundary>
        <br></br>
        <Row>
          <Col className="pb-2">{t("admin.pages.poCoApproval.poLineItems")}</Col>
        </Row>

        <Row>
          <Col xs="12">
            {poChangeOrderDetail?.object_changes?.po_items_attributes && purchaseOrderDetail?.po_items && (
              <PoLineItemsTable
                poChangeOrderItems={poChangeOrderDetail?.object_changes?.po_items_attributes}
                poItems={purchaseOrderDetail?.po_items}
              />
            )}
            <br></br>
          </Col>
        </Row>
      </ErrorBoundary>

      <Row>
        <Col className="pb-2">{t("admin.pages.poCoApproval.poExpenses")}</Col>
      </Row>

      <Row>
        <Col xs="12">
          {purchaseOrderDetail?.invoice_debit_accounts &&
            poChangeOrderDetail?.object_changes?.invoice_debit_accounts_attributes && (
              <ExpenseLineTable
                invoiceDebitAccounts={purchaseOrderDetail?.invoice_debit_accounts}
                changeOrderInvoiceDebitAccounts={poChangeOrderDetail?.object_changes?.invoice_debit_accounts_attributes}
              />
            )}
          <br></br>
        </Col>
      </Row>
      <br></br>
      <Row>
        <Col className="pb-2">{t("admin.pages.poCoApproval.description")}</Col>
      </Row>
      <Row>
        <Col>
          <div className={`${isDescriptionChanged() && style.updatedItem}`}>
            {isDescriptionChanged() && (
              <>
                <div className={style.previousValue}>{purchaseOrderDetail?.description}</div>
                <div>{poChangeOrderDetail?.object_changes?.description}</div>
              </>
            )}
            {!isDescriptionChanged() && purchaseOrderDetail?.description}
          </div>
        </Col>
      </Row>
      <br></br>
      {/* 
     // Uncomment the code if required 
      <Row>
        <Col>
          <TabNavigator
            activeTab={activeTab}
            handleTabChange={(tab: string) => setActiveTab(tab)}
            tabData={[
              { label: "Invoice", activeTab: "invoice" }
              // { label: "Budget", activeTab: "budget" }
            ]}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          {activeTab === "invoice" && purchaseOrderDetail?.id && (
            <InvoiceDataList
              isApproval
              hideAddButton
              collapsible
              filter={{ purchase_order_id: purchaseOrderDetail?.id }}
            />
          )}
        </Col>
      </Row> */}

      <br></br>
      <Row>
        <Col className="pb-2">{t("admin.pages.poCoApproval.approvalWorkflow")}</Col>
      </Row>
      <Row>
        <Col xs="12">
          <Table responsive bordered className="text-left">
            <tbody>
              {_.isArray(poChangeOrderDetail?.approvals) &&
                poChangeOrderDetail?.approvals?.map((approval) => {
                  return (
                    <tr key={approval.id}>
                      <td className={style.workflowText}>
                        {companyDateTimeFormat(
                          approval.approved_at || approval.updated_at || approval.created_at,
                          currentUser,
                        )}
                      </td>
                      <td className={style.workflowText}>{approval.name}</td>
                      <td className={style.workflowText}>
                        {approval.aasm_state === "approved" && (
                          <>
                            <i className={`icon icon-approval-approved ${style.icon22} `} />
                            <span>Approved</span>
                          </>
                        )}
                        {(approval.aasm_state === "viewed" || approval.aasm_state === "sent") && (
                          <>
                            <i className={"icon icon-approval-pending " + style.icon22}></i>
                            <span>Pending</span>
                          </>
                        )}
                        {approval.aasm_state === "rejected" && (
                          <>
                            <i className={"icon icon-approval-rejected " + style.icon22}></i>
                            <span>Rejected</span>
                          </>
                        )}
                      </td>
                      <td className={style.workflowText}>
                        {approvalsService.isAllowClaim(approval, currentUser) && (
                          <button
                            className="icon border-0 icon-approval-claimed"
                            onClick={() => claimApproval(approval)}
                            id="claim_approval"
                            data-toggle="tooltip"
                            title="Claim Approval"
                          />
                        )}
                        {approval.is_claimed && (
                          <button
                            className="icon border-0 icon-approval-claimed"
                            id="claimed_approval"
                            data-toggle="tooltip"
                            disabled={approval.is_claimed}
                            title="Approval Claimed"
                          />
                        )}
                      </td>
                    </tr>
                  );
                })}

              {_.isArray(poChangeOrderDetail?.approvals) &&
                poChangeOrderDetail?.approvals?.length &&
                poChangeOrderDetail.approvals.length < 1 && (
                  <tr>
                    <td colSpan={4}>No Approver</td>
                  </tr>
                )}
            </tbody>
          </Table>
        </Col>
      </Row>
      <Row>
        <Col>
          <AttachmentSection
            allowAdd
            allowDelete
            collapsible
            title={
              <>
                <i className="icon m-0 document" />
                {t("documents").toUpperCase()}
              </>
            }
            attachments={assets}
            onAttachmentDelete={onDeleteAttachment}
            onAttachmentUpload={onUploadAttachment}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default DetailsApprovalChangeOrders;
