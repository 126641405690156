import _ from "lodash";
import React, { useEffect, useMemo } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { connect, useDispatch, useSelector } from "react-redux";
import { Field, FieldArray, getFormValues, reduxForm } from "redux-form";
import { punchout_providers } from "../../../../lookups/integrationsLookups";
import { ADDRESS_FORM, onAddressUnmount } from "../../../../reducers/common/addressSectionReducer";
import { noWhiteSpaceOnly, required } from "../../../../services/validations/reduxFormValidation";
import CountryPicker from "../../../common/pickers/reduxPickers/countryPicker";
import StatePicker from "../../../common/pickers/reduxPickers/statePicker";
import { RenderCheckBox, RenderField, RenderSelect } from "../../../forms/bootstrapFields";

const PunchoutIds = ({ fields, meta }) => {
  const { t } = useTranslation();
  return (
    <>
      <Row>
        <Col className="d-flex align-items-center">
          <span className="mr-2"> Punchout Ids </span>
          <Button size="sm" onClick={() => fields.push({})}>
            + Add
          </Button>
        </Col>
      </Row>
      {fields.map((punchoutProgram, index) => {
        return (
          <Row key={index}>
            <Col>
              <Field
                id="punchout.provider_name"
                name={`${punchoutProgram}.provider_name`}
                label={t("components.admin.addressable.provider")}
                options={[{ label: "", value: "" }, ...punchout_providers]}
                component={RenderSelect}
                required
                validate={[required]}
              />
            </Col>
            <Col>
              <Field
                id="punchout.id"
                name={`${punchoutProgram}.id`}
                label={t("components.admin.addressable.id")}
                component={RenderField}
              />
            </Col>
          </Row>
        );
      })}
    </>
  );
};

let AddressForm = ({ handleSubmit, onHide }) => {
  const { form } = useSelector((state) => getFormValues(ADDRESS_FORM)(state));
  const addressableType = useSelector((state) => state.addressSectionRed.addressableType);
  const currentUser = useSelector((state) => state.user);
  const company = useSelector((state) => state.companyLandingSlice.companyDetail);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    return () => {
      dispatch(onAddressUnmount());
    };
  }, []);

  const options = useMemo(() => {
    let options = [
      { label: "Select Address Type", value: "" },
      { label: "Shipping", value: "Shipping" },
      { label: "Billing", value: "Billing" },
      { label: "Mailing", value: "Mailing" },
    ];
    let showHq = false;
    if (addressableType == "Company") {
      if (form?.id && form.address_type == "HQ") {
        showHq = true;
      } else if (
        !form?.id &&
        (!_.isPlainObject(company.address) ||
          (_.isPlainObject(company.address) && company.address.address_type != "HQ"))
      ) {
        showHq = true;
      }
    }

    if (showHq) {
      options.push({ label: "HQ", value: "HQ" });
    }
    return options;
  }, [addressableType, company?.addresss, form?.id]);

  return (
    <Form noValidate className="px-2" onSubmit={handleSubmit}>
      <Row>
        <Col sm="4">
          <Field
            name="form.address_type"
            type="text"
            label={t("components.admin.addressable.addressType")}
            id="address.address_type"
            component={RenderSelect}
            options={options}
            disabled={form?.id && form.address_type == "HQ"}
            autocomplete="off"
            required
            validate={[required, noWhiteSpaceOnly]}
          />
        </Col>
      </Row>
      <Row>
        {addressableType !== "Vendor" && (
          <Col sm="4">
            <Field
              type="text"
              label={t("components.admin.addressable.entityName")}
              name="form.entity_name"
              component={RenderField}
              id="entity_name"
            />
          </Col>
        )}
        <Col sm="4">
          <Field
            type="text"
            label={t("components.admin.addressable.attention")}
            name="form.attention"
            component={RenderField}
          />
        </Col>
        <Col md="4">
          <Field
            type="text"
            name="form.label"
            label={t("components.admin.addressable.label")}
            component={RenderField}
            required
            validate={[required, noWhiteSpaceOnly]}
          />
        </Col>
      </Row>
      <Row>
        <Col sm="4">
          <Field
            type="text"
            label={t("components.admin.addressable.addressLine1")}
            component={RenderField}
            name="form.address1"
            id="address_address1"
            required
            validate={[required, noWhiteSpaceOnly]}
          />
        </Col>
        <Col sm="4">
          <Field
            label={t("components.admin.addressable.addressLine2")}
            component={RenderField}
            type="text"
            name="form.address2"
            id="address_address2"
          />
        </Col>
        <Col sm="4">
          <Field
            type="text"
            label={t("components.admin.addressable.addressLine3")}
            component={RenderField}
            name="form.address3"
            id="address_address3"
          />
        </Col>
      </Row>
      <Row>
        <Col sm="4">
          <Field label={t("components.admin.addressable.country")} name="form.country" component={CountryPicker} />
        </Col>
        <Col sm="4">
          <Field
            label={t("components.admin.addressable.city")}
            name="form.city"
            type="text"
            component={RenderField}
            id="address_city"
          />
        </Col>
        <Col sm="4">
          <Field
            label={t("components.admin.addressable.state")}
            name="form.state"
            component={StatePicker}
            countryCode={form?.country}
          />
        </Col>
      </Row>
      <Row>
        <Col sm="4">
          <Field
            label={t("components.admin.addressable.zip")}
            type="text"
            name="form.zipcode"
            component={RenderField}
            id="address_zipcode"
          />
        </Col>
        <Col sm="4">
          <Field
            label={t("components.admin.addressable.email")}
            type="email"
            component={RenderField}
            name="form.email"
            id="address_email"
          />
        </Col>
      </Row>
      <Row>
        <Col sm="4">
          <Field
            label={t("components.admin.addressable.isDefault")}
            component={RenderCheckBox}
            type="checkbox"
            name="form.is_default"
            id="address_is_defualt"
          />
        </Col>
      </Row>

      {["Company", "Subsidiary"].includes(addressableType) && currentUser?.company?.has_punchout_integration && (
        <>
          <hr />
          <Row>
            <Col sm="5">
              <FieldArray name="form.punchout_ids" component={PunchoutIds} />
            </Col>
          </Row>
        </>
      )}

      <Row>
        <Col className="d-flex justify-content-end">
          <Button id="address_cancel_btn" variant="secondary" onClick={() => onHide()} className="mx-2">
            {t("cancel")}
          </Button>
          <Button type="submit" id="address_submit_btn">
            {t("submit")}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

AddressForm = reduxForm({
  form: ADDRESS_FORM, // a unique identifier for this form
  enableReinitialize: true,
  touchOnChange: false,
  touchOnBlur: false,
})(AddressForm);

AddressForm = connect((state, props) => ({
  initialValues: state.addressSectionRed.addressForm,
}))(AddressForm);

export default AddressForm;
