import _ from "lodash";
import { restApiService } from "providers/restApi";
import React, { useCallback, useMemo } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { AbstractListPickerTypes } from "../abstractListPicker/abstractListPickerTypes";
import AbstractListPicker from "../abstractListPicker/index2";
import { RebatePickerTypes } from "./RebatePickerType";

const RebatePicker = ({
  name,
  label,
  objectPropertyKey,
  modelData,
  required,
  callBack,
}: RebatePickerTypes.TRebatePickerProps) => {
  const { getValues, setValue } = useFormContext();
  const locationFieldName = useMemo(() => {
    let field = "location";
    if (_.isNil(modelData)) return "";
    return `${modelData}.${field}`;
  }, [modelData]);

  const location = useWatch({
    name: locationFieldName,
  });

  const resetRebate = useCallback(
    (currentId: number, rebates: RebatePickerTypes.TRebatePickerOptions) => {
      const rebate = Array.isArray(rebates) ? rebates.filter((rebate) => rebate?.id === currentId) : [];
      if (Array.isArray(rebate) && rebate.length < 1) {
        setValue(name, null);
        setValue(`${modelData}.rebate`, null);
        setValue(`${modelData}.rebate_amount`, null);
      }
    },
    [modelData, name, setValue],
  );
  const mergeInactive = useCallback(
    async (currentId, rebates) => {
      let inactiveOption = null;
      if (currentId) {
        const rebate = Array.isArray(rebates) ? rebates.filter((rebate) => rebate?.id === currentId) : [];
        const onlyShowMappedRebate = getValues(`${modelData}.only_show_mapped_rebate`);
        if (rebate.length === 0 && onlyShowMappedRebate) {
          resetRebate(currentId, rebates);

          setValue(`${modelData}.only_show_mapped_rebate`, false);
          inactiveOption = null;
        } else if (rebate.length === 0) {
          try {
            const response = await restApiService.get("rebates", currentId);
            inactiveOption = response.data;
          } catch (error) {
            console.log(error);
          }
        } else {
          inactiveOption = rebate[0];
        }
      }
      return { inactiveOption };
    },
    [getValues, modelData, resetRebate, setValue],
  );

  let params = useMemo(() => {
    let apiParams: Record<string, any> = {};
    apiParams.status = "ACTIVE";

    if (location && location.has_rebates) {
      apiParams.location_id = location.id;
      setValue(`${modelData}.only_show_mapped_rebate`, true);
    }
    return apiParams;
  }, [location, modelData, setValue]);

  const handleSelection = useCallback(
    (selected: AbstractListPickerTypes.TPickerValue<RebatePickerTypes.TRebatePickerOption>) => {
      if (callBack) {
        callBack(selected);
      }
    },
    [callBack],
  );

  const formatOptionLabel = useCallback((option) => {
    return (
      <>
        {!option.id && <span>-- Select --</span>}
        {option.id && (
          <span>
            <strong>Name: </strong>
            {option.name}
            {option.rate && (
              <span>
                <strong> Rate:</strong> {option.rate}%
              </span>
            )}
            {option.is_included && <span>(included)</span>}
          </span>
        )}
      </>
    );
  }, []);
  return (
    <AbstractListPicker<RebatePickerTypes.TRebatePickerOption>
      name={name}
      label={label}
      objectPropertyKey={objectPropertyKey}
      fetchUrl="rebates.lk"
      params={params}
      required={required}
      mergeInactive={mergeInactive}
      formatOptionLabel={formatOptionLabel}
      callBack={handleSelection}
    />
  );
};

export default RebatePicker;
