import React, { MouseEventHandler, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { WombatType } from "wombatifier/services/wombat.types";
import { WombatSvc } from "wombatifier/services/wombatSvc";

const DownloadTemplateLink = ({
  url,
  fileName,
  displayName,
  onClick,
}: {
  url?: string;
  fileName: string;
  displayName: string;
  onClick?: MouseEventHandler<HTMLAnchorElement>;
}) => {
  const { t } = useTranslation();

  return (
    <span>
      {t("admin.pages.bulkUploads.importTemplate")}&nbsp;
      <a
        target="_blank"
        href={url}
        download={fileName}
        className="link-primary text-decoration-underline text-info"
        rel="noreferrer"
        onClick={onClick}
      >
        {displayName}
      </a>
    </span>
  );
};

const CSVTemplateLink = ({
  columnNames,
  filename,
  displayName,
}: {
  columnNames: string[];
  filename: string;
  displayName: string;
}) => {
  const csvBlob = new Blob([columnNames.join(",")], { type: "text/csv" });
  const url = window.URL.createObjectURL(csvBlob);
  return (
    <DownloadTemplateLink
      url={url}
      fileName={filename}
      displayName={displayName}
      onClick={(e) => e.stopPropagation()}
    />
  );
};

export const TemplateLink = ({ currentWombat }: { currentWombat: WombatType }) => {
  const [wombatTemplate, setWombatTemplate] = useState<{ hasTemplate: boolean | null; path: string }>({
    hasTemplate: null,
    path: "",
  });

  useEffect(() => {
    setWombatTemplate({ hasTemplate: null, path: "" });

    if (!currentWombat) {
      return;
    }

    import(`assets/wombatTemplates/${currentWombat.name}.xlsx`)
      .then((file) => {
        setWombatTemplate({ hasTemplate: true, path: file.default });
      })
      .catch(() => {
        setWombatTemplate({ hasTemplate: false, path: "" });
      });
  }, [currentWombat?.name]);

  // Currently no way of getting customer files for Rosetta to dynamically generate
  if (currentWombat?.workflow_type === "file_integration_ingest_pmg_rosetta") {
    return <></>;
  }

  if (wombatTemplate.hasTemplate) {
    return (
      <DownloadTemplateLink
        url={wombatTemplate.path}
        fileName={`${currentWombat?.name}.xlsx`}
        displayName={currentWombat?.name}
      />
    );
  }

  if (wombatTemplate.hasTemplate === false && currentWombat?.config.wombat_fields.length) {
    return (
      <CSVTemplateLink
        columnNames={WombatSvc.flattenNestedValueMappedFroms(currentWombat.config.wombat_fields)}
        filename={`${currentWombat.source_type}_template.csv`}
        displayName={currentWombat.source_type}
      />
    );
  }

  return <></>;
};
