import PaymentsTabs from "components/admin/payments/paymentTabs";
import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { useSelector } from "react-redux";
import DataGrid from "../../../../components/datagrid/datagrid";
import prePaymentsHeader from "../../../../components/datagrid/girdHeaders/prePaymentsHeader";
import RestApi from "../../../../providers/restApi";

const PrePaymentsPage = (props) => {
  const [pre_payments, setPrePayments] = useState([]);
  const [gridStyle, setGridStyle] = useState({ width: "auto", height: "100%" });
  const [loading, setLoading] = useState(true);
  const user = useSelector((state) => state.user);

  useEffect(() => {
    getPrePayments();
  }, []);

  const getPrePayments = () => {
    new RestApi().get("payments/prepayment").then(
      (res) => {
        if (res && res.data) {
          setPrePayments(res.data);
          setLoading(false);
        }
      },
      (err) => {
        setLoading(false);
      },
    );
  };

  return (
    <Container fluid={true} className={"container-fluid h-100 pb-2"}>
      <PaymentsTabs />
      <div style={{ padding: "1%" }}>
        <DataGrid
          gridHeight="500px"
          gridStyle={gridStyle}
          rowData={pre_payments}
          columnDefs={prePaymentsHeader}
          dataType={"pre_payments"}
          //   dateFormat={this.state.dateFormat}
          autoHeight={true}
          loading={loading}
          {...props}
          // to add pagination
          pagination={true}
          cacheBlockSize={50}
          maxBlocksInCache={1}
          paginationPageSize={50}
        />
      </div>
    </Container>
  );
};
export default PrePaymentsPage;
