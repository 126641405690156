import _ from "lodash";
import { restApiService } from "providers/restApi";
import React, { useCallback, useMemo } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { useTypedSelector } from "reducers";
import { selectCurrentUser } from "reducers/userReducers";
import commonService from "services/common/commonSvc";
import { IUser } from "services/common/user/userTypes";
import { companyDateFormat } from "services/general/dateSvc";
import { formattedAmount } from "services/general/helpers";
import AbstractListPicker from "../abstractListPicker/index2";
import styles from "./budgetPicker.module.css";
import { BudgetPickerType } from "./budgetPickerTypes";

const SingleBudgetPicker = ({
  name,
  instanceId,
  label,
  required,
  disabled,
  modelDataName,
  parentObjName,
  containerClassName,
  objectPropertyKey,
}: BudgetPickerType.TBudgetPickerProps) => {
  const { getValues } = useFormContext();
  const currentUser: IUser = useTypedSelector(selectCurrentUser);

  const modelData = !_.isNil(modelDataName) ? (modelDataName === "" ? "" : modelDataName) : null;
  const parentData = !_.isNil(parentObjName) ? (parentObjName === "" ? "" : parentObjName) : null;

  const currencyCodeFieldName = useMemo((): string => {
    const field = "currency_code";
    if (_.isNil(parentData)) return "";
    return `${parentData}.${field}`;
  }, [parentData]);

  const vendorIdFieldName = useMemo((): string => {
    const field = "vendor_id";
    if (_.isNil(parentData)) return "";
    return `${parentData}.${field}`;
  }, [parentData]);

  const accountIdFieldName = useMemo((): string => {
    const field = "account_id";
    if (_.isNil(modelData)) return "";
    return `${modelData}.${field}`;
  }, [modelData]);

  const departmentIdFieldName = useMemo((): string => {
    const field = "department_id";
    if (_.isNil(modelData)) return "";
    return `${modelData}.${field}`;
  }, [modelData]);

  const [currencyCode, accountId, vendorId, departmentId] = useWatch({
    name: [currencyCodeFieldName, accountIdFieldName, vendorIdFieldName, departmentIdFieldName],
  });

  const allowBudgetFilter = useMemo(() => {
    return currentUser.company?.budget?.show_field_at === "line_level";
  }, [currentUser.company?.budget?.show_field_at]);

  const allowBudgetMultiCurrency = useMemo(() => {
    return currentUser.company?.budget?.allow_multi_currency_budget;
  }, [currentUser.company?.budget?.allow_multi_currency_budget]);

  const getModelDataParams = useCallback(
    (apiParams: Record<string, any>) => {
      if (accountId && allowBudgetFilter) {
        apiParams.account_id = accountId;
      }
    },
    [accountId, allowBudgetFilter],
  );

  const getParentObjParams = useCallback(
    (apiParams: Record<string, any>) => {
      if (currencyCode && !allowBudgetMultiCurrency) {
        apiParams.currency_code = currencyCode;
      }

      if (vendorId && allowBudgetMultiCurrency) {
        apiParams.vendor_id = vendorId;
      }

      if (departmentId) {
        apiParams.department_id = departmentId;
      }
    },
    [allowBudgetMultiCurrency, currencyCode, departmentId, vendorId],
  );

  const params = useMemo(() => {
    let apiParams: Record<string, any> = { active: true, items: 150 };

    if (!_.isNil(modelData)) {
      getModelDataParams(apiParams);
    }

    if (!_.isNil(parentData)) {
      getParentObjParams(apiParams);
    }
    return apiParams;
  }, [getModelDataParams, getParentObjParams, modelData, parentData]);

  const formatOptionLabel = useCallback(
    (option: BudgetPickerType.TBudgetsListOptions, context: any) => {
      return (
        <>
          {context?.context !== "menu" ? (
            <>
              <span className={styles.optionLabelTitle}>{option.label} </span>
              {option.label && (
                <span className={styles.optionLabelTitle}>
                  Balance:{" "}
                  {formattedAmount((option.budget_balance ?? 0).toString(), option?.currency?.iso_code, 2, true)}
                </span>
              )}
              <span className={styles.optionLabelTitle}>{option.name ? "Select/Search The List" : ""}</span>
            </>
          ) : (
            <>
              <span className={styles.optionLabelTitle}>{option.name ? "Select/Search The List" : ""}</span>
              {option.id !== "" && !option.name && (
                <div className={styles.options}>
                  <span className={styles.optionLabelTitle}>{option.label}</span>
                  <br />
                  <b>Code:</b> {option.label} <br />
                  <b>Amount: </b>
                  <span>{formattedAmount((option.amount ?? 0).toString(), option?.currency?.iso_code, 2, true)}</span>
                  <b> Balance: </b>
                  <span>
                    {formattedAmount((option.budget_balance ?? 0).toString(), option?.currency?.iso_code, 2, true)}
                  </span>
                  <br />
                  <b>Allocated: </b>
                  {formattedAmount((option.budget_allocated ?? 0).toString(), option?.currency?.iso_code, 2, true)} /
                  <b> Status:</b> {option.status}
                  <br />
                  <b>Start Date:</b> {companyDateFormat(option.start_date, currentUser)} /<b> End Date: </b>
                  {companyDateFormat(option.end_date, currentUser)}
                </div>
              )}
            </>
          )}
        </>
      );
    },
    [currentUser],
  );

  const mergeInactive = useCallback(
    async (currentId, budgets) => {
      let inactiveOption = null;

      if (currentId > 0) {
        const budget = Array.isArray(budgets) ? budgets.find((budget) => budget?.id === currentId) : [];

        let account = getValues(`${modelData}.account`);

        if (!budget && account?.has_budget_links) {
          inactiveOption = null;
        } else if (!budget) {
          try {
            const response = await restApiService.get("budgets/" + currentId);
            inactiveOption = response.data;
          } catch (error) {
            commonService.handleError(error);
          }
        } else {
          inactiveOption = budget;
        }
        return { inactiveOption };
      }

      return { inactiveOption };
    },
    [getValues, modelData],
  );

  return (
    <AbstractListPicker<BudgetPickerType.TBudgetsListOptions>
      name={name}
      label={label}
      params={params}
      required={required}
      disabled={disabled}
      mergeInactive={mergeInactive}
      fetchUrl="budgets.lk"
      formatOptionLabel={formatOptionLabel}
      //   callBack={handlePickerSelection}
      objectPropertyKey={!_.isNil(modelData) ? `${modelData}.budget` : ""}
      containerClassName={containerClassName}
      placeholder="Select/Search The List"
    />
  );
};

export default SingleBudgetPicker;
