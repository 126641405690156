import DateTimePicker from "components/admin/pickers/reduxFormPickers/dateTimePicker/dateTimePicker";
import { RenderCheckBox } from "components/forms/bootstrapFields";
import useConfirmModal from "components/modals/confirmModal/useConfirmModalHook";
import _ from "lodash";
import moment from "moment";
import React, { Fragment, useCallback, useEffect, useMemo } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useTypedSelector } from "reducers";
import { INTEGRATION_FORM_NAME } from "reducers/admin/integrationsReducer";
import { change, Field, FieldArray, FieldArrayFieldsProps, getFormValues } from "redux-form";
import IntegrationApis from "services/admin/integration/integrationApis";
import { IntegrationTypes } from "services/admin/integration/integrationTypes";
import { IUser } from "services/common/user/userTypes";
import { getCompanyDateFormat } from "services/general/dateSvc";
import { afterCurrentDateTime, beforeCurrentDateTime, required } from "services/validations/reduxFormValidation";
import styles from "../../integrations.module.css";

type JobsColumnType = {
  fields: FieldArrayFieldsProps<IntegrationTypes.IntegrationJobType>;
  integrationJob: { inactiveAllJobs: boolean; jobs: IntegrationTypes.IntegrationJobsType };
};

type SubJobsColumnType = {
  fields: FieldArrayFieldsProps<IntegrationTypes.SubJobType>;
  integrationJob: { inactiveAllJobs: boolean; jobs: IntegrationTypes.IntegrationJobsType };
  jobIndex: number;
  job: IntegrationTypes.IntegrationJobType;
};

type TLastrunDatePickerProps = {
  name: string;
  job?: any;
  isMasterJob?: boolean;
  minDate?: string;
  maxDate?: string;
};

const LastrunDatePicker = ({ name, job, isMasterJob, minDate, maxDate }: TLastrunDatePickerProps) => {
  const maxDateValue = useMemo(() => {
    if (isMasterJob) {
      return moment.utc().format("YYYY-MM-DDTHH:mm:ss");
    } else {
      return maxDate ? moment.parseZone(maxDate).format("YYYY-MM-DDTHH:mm:ss") : null;
    }
  }, [isMasterJob, maxDate]);

  const minDateValue = useMemo(() => {
    if (isMasterJob) {
      return moment.utc().subtract(2, "days").format("YYYY-MM-DDTHH:mm:ss");
    } else {
      return minDate ? moment.parseZone(minDate).format("YYYY-MM-DDTHH:mm:ss") : null;
    }
  }, [isMasterJob, minDate]);

  const maxDateValidation = useMemo(() => afterCurrentDateTime("utc", -2), []);
  const minDateValidation = useMemo(() => beforeCurrentDateTime("utc"), []);

  return (
    <Field
      name={name}
      component={DateTimePicker}
      className="justify-content-center"
      inUtc={true}
      minDate={minDateValue}
      maxDate={maxDateValue}
      validate={[required, maxDateValidation, minDateValidation]}
    />
  );
};

const SubJobsColumn = ({ fields, integrationJob, jobIndex, job }: SubJobsColumnType) => {
  const currentUser: IUser = useTypedSelector((state) => state.user);
  const dispatch = useDispatch();

  const handleSubJobActive = (obj: IntegrationTypes.SubJobType, value: boolean) => {
    obj.isActive = value;
    if (value && !job.isActive) {
      job.isActive = true;
      job.sub_jobs = job.sub_jobs.map((subJob) => (subJob.id === obj.id ? obj : subJob));
      dispatch(change(INTEGRATION_FORM_NAME, `jobsForm.jobs[${jobIndex}]`, job));
    }
    if (value && integrationJob?.inactiveAllJobs) {
      dispatch(change(INTEGRATION_FORM_NAME, `jobsForm.inactiveAllJobs`, false));
    }
  };
  return (
    <>
      {fields &&
        fields.length > 0 &&
        fields.map((subJob, index: number) => (
          <Row className={` ${index % 2 === 1 ? styles.oddRow : ""} my-1  `} key={index}>
            <Col xs="4" className={`my-1 pl-5 d-flex flex-row ${styles.subJobText}`}>
              <span className="pr-1">
                {String.fromCharCode(index + 97)}.{}
              </span>
              {fields.get(index).display_name}
            </Col>
            <Col xs="2" className={`my-1 ${styles.subJobText}`}>
              {fields.get(index).display_schedule}
            </Col>
            <Col xs="2" className={`my-1`}>
              <Field name={`${subJob}.isEdited`} component={RenderCheckBox} className="justify-content-center" />
            </Col>
            <Col xs="auto" className={`my-1 ms-3 ${styles.subJobText} flex-grow-1 ${styles.lastSyncDate}`}>
              {!fields.get(index).isEdited ? (
                fields.get(index).last_synced_at ? (
                  moment
                    .parseZone(fields.get(index).last_synced_at)
                    .format(getCompanyDateFormat(currentUser) + " h:mm A")
                ) : (
                  ""
                )
              ) : (
                <Field
                  name={`${subJob}.last_synced_at`}
                  component={DateTimePicker}
                  className="justify-content-center"
                  inUtc={true}
                />
              )}
            </Col>
            <Col xs="auto" className={styles.jobsActiveTabHeader + " text-center "}>
              <Field
                name={`${subJob}.isActive`}
                component={RenderCheckBox}
                onChange={(value: any) => handleSubJobActive(fields.get(index), value)}
                className="justify-content-center ml-1"
              />
            </Col>
          </Row>
        ))}
    </>
  );
};

const JobsColumn = ({ fields, integrationJob }: JobsColumnType) => {
  const dispatch = useDispatch();
  const currentUser: IUser = useTypedSelector((state) => state.user);

  const { createConfirmModal } = useConfirmModal();

  const handleAllSubJobChange = useCallback(
    ({ obj, index }: { obj: IntegrationTypes.IntegrationJobType; index: number }) => {
      if (_.isArray(obj?.sub_jobs) && obj.sub_jobs.length > 0) {
        obj.sub_jobs = obj.sub_jobs.map((subJob) => ({ ...subJob, isActive: obj.isActive }));
        dispatch(change(INTEGRATION_FORM_NAME, `jobsForm.jobs[${index}]`, obj));
      }
    },
    [dispatch],
  );

  const confirmSubJobsChange = useCallback(
    (obj, index) => {
      createConfirmModal({
        title: `Do you want to ${obj.isActive ? "active" : "inactive"} all Sub jobs for this Master Job?`,
        callBackData: { obj: obj, index: index },
        confirmButtonLabel: "Yes",
        cancelButtonLabel: "No",
        saveCallBack: handleAllSubJobChange,
      });
    },
    [createConfirmModal, handleAllSubJobChange],
  );

  const handleJobActive = (obj: IntegrationTypes.IntegrationJobType, value: any, index: number) => {
    obj.isActive = value;
    if (_.isArray(obj?.sub_jobs) && obj.sub_jobs.length > 0) {
      confirmSubJobsChange(obj, index);
    }
    if (value && integrationJob?.inactiveAllJobs) {
      dispatch(change(INTEGRATION_FORM_NAME, `jobsForm.inactiveAllJobs`, false));
    }
  };
  return (
    <div className="py-2">
      {fields &&
        fields.length > 0 &&
        fields.map((job, index: number) => (
          <Fragment key={index}>
            <Row className={`${styles.rowBorder} d-flex flex-row`}>
              <Col xs="4" className="my-1  d-flex flex-row">
                <span className="pr-1">{index + 1}. </span>
                {fields.get(index).display_name}
              </Col>
              <Col xs="2" className="my-1">
                {fields.get(index).display_schedule}
              </Col>
              <Col xs="2" className={`my-1`}>
                <Field name={`${job}.isEdited`} component={RenderCheckBox} className="justify-content-center" />
              </Col>
              <Col xs="auto" className={`my-1 flex-grow-1 ${styles.lastSyncDate}`}>
                {!fields.get(index).isEdited ? (
                  fields.get(index).last_synced_at ? (
                    moment
                      .parseZone(fields.get(index).last_synced_at)
                      .format(getCompanyDateFormat(currentUser) + " h:mm A")
                  ) : (
                    ""
                  )
                ) : (
                  <LastrunDatePicker name={`${job}.last_synced_at`} job={fields.get(index)} isMasterJob={true} />
                )}
              </Col>
              <Col xs="auto" className={styles.jobsActiveTabHeader + " text-center "}>
                <Field
                  name={`${job}.isActive`}
                  component={RenderCheckBox}
                  onChange={(value: any) => handleJobActive(fields.get(index), value, index)}
                  className="justify-content-center ml-1"
                />
              </Col>
            </Row>
            <FieldArray
              name={`${job}.sub_jobs`}
              component={SubJobsColumn}
              integrationJob={integrationJob}
              job={fields.get(index)}
              jobIndex={index}
            />
          </Fragment>
        ))}
    </div>
  );
};

const IntegrationJobs = () => {
  const integrationForm: any = useTypedSelector((state) => getFormValues(INTEGRATION_FORM_NAME)(state));
  const dispatch = useDispatch();

  const getIntegration = async () => {
    try {
      if (integrationForm.form.id) {
        const result = await IntegrationApis.getIntegrationJobs(integrationForm.form.id);
        dispatch(change(INTEGRATION_FORM_NAME, "jobsForm.jobs", result));
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleAllJobsInactive = (value: any) => {
    integrationForm.jobsForm.inactiveAllJobs = value;
    if (_.isArray(integrationForm?.jobsForm?.jobs) && integrationForm?.jobsForm?.jobs.length > 0 && value) {
      let jobs = integrationForm.jobsForm.jobs.map((job: IntegrationTypes.IntegrationJobType) => {
        let obj = job;
        obj.isActive = false;
        obj.sub_jobs = obj.sub_jobs.map((obj) => ({ ...obj, isActive: false }));
        return obj;
      });
      dispatch(change(INTEGRATION_FORM_NAME, "jobsForm.jobs", jobs));
    }
  };

  useEffect(() => {
    getIntegration();
    return () => {
      dispatch(change(INTEGRATION_FORM_NAME, "jobsForm.jobs", []));
    };
  }, []);
  return (
    <>
      {integrationForm?.jobsForm?.jobs &&
        _.isArray(integrationForm?.jobsForm?.jobs) &&
        integrationForm?.jobsForm?.jobs.length > 0 && (
          <>
            <hr className="mt-2 mb-1" />
            <Col lg="12" className="mt-3">
              <Row>
                <Col sm="12" className="d-flex">
                  <h5 className={"mr-2 " + styles.jobsTableTitle}>Scheduled Jobs</h5>
                </Col>
              </Row>
              <Container className={"mt-3 " + styles.jobsTable}>
                <Row className={styles.jobsTableHeader + " d-flex"}>
                  <Col xs="4" className="my-1 ">
                    Name
                  </Col>
                  <Col xs="2" className={`my-1 `}>
                    Schedule
                  </Col>
                  <Col xs="2" className={`my-1`}>
                    Edit Last Run Date
                  </Col>
                  <Col xs="auto" className={`my-1 flex-grow-1 ${styles.lastSyncDate}`}>
                    Last Run (UTC)
                  </Col>
                  <Col xs="auto" className={`my-1 ${styles.jobsActiveTabHeader}`}>
                    Active
                  </Col>
                </Row>
                <FieldArray name="jobsForm.jobs" component={JobsColumn} integrationJob={integrationForm?.jobsForm} />
              </Container>
            </Col>
          </>
        )}
    </>
  );
};

export default IntegrationJobs;
