import { AxiosResponse } from "axios";
import { restApiService } from "providers/restApi";
import { getListOptionsType } from "services/common/types/common.type";
import {
  ChangeOrderObjectOptionsType,
  ChangeOrderOptionsType,
} from "../purchaseOrders/changeOrders/purchaseOrders.types";
import { ChangeOrdersPOFormService } from "../purchaseOrders/changeOrders/purchaseOrdersForm";
import { PoChangeOrderType } from "./poChangeOrderApprovalsType";

class ChangeOrderApis {
  static async getChangeOrderDetail<T>(id: string | Number) {
    try {
      const response: AxiosResponse<T> = await restApiService.get("change_requests/" + id);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  static getChangeOrderPOMerged = async (id: number) => {
    // TODO:  try catch
    let mergedChangeOrderData: ChangeOrderOptionsType | null = null;
    const data: ChangeOrderOptionsType = await ChangeOrderApis.getChangeOrderDetail(id);
    if (data && data.change_requestable) {
      //TODO: should not have any of the business logic here
      const mergedValues: ChangeOrderObjectOptionsType = ChangeOrdersPOFormService.processComparisonData(
        data.change_requestable,
        data.object_changes,
      );
      ChangeOrdersPOFormService.prepareObjectChanges(mergedValues);
      const newInitialValues = { ...data, object_changes: mergedValues };
      newInitialValues.initialValues = { ...data, object_changes: data.change_requestable };
      mergedChangeOrderData = newInitialValues;
    }
    return mergedChangeOrderData;
  };

  static getList = async ({ filter, cache }: getListOptionsType = {}) => {
    try {
      const response: AxiosResponse<PoChangeOrderType.ListType> = await restApiService.get(
        "change_requests",
        filter,
        null,
        true,
        null,
        cache,
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  static delete = async (id: number) => {
    try {
      const response: AxiosResponse<PoChangeOrderType.DetailType> = await restApiService.delete(
        `change_requests/${id}`,
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };
}

export default ChangeOrderApis;
