import { restApiService } from "providers/restApi";
import React, { memo, useCallback, useMemo } from "react";
import AbstractListPicker from "../abstractListPicker/index2";
import { EmployeePickerTypes } from "./employeePickerTypes";

const SingleEmployeePicker = ({
  name,
  label,
  instanceId,
  required = false,
  modelDataName,
  objectPropertyKey,
}: EmployeePickerTypes.TEmployeePickerProps) => {
  //todo callback function : to updated modelObj.manager_id and pass callback function only for budget model

  const mergeInactive = useCallback(async (currentId, employees) => {
    let inactiveOption = null;
    if (currentId) {
      const employee = Array.isArray(employees) ? employees.filter((employee) => employee.id === currentId) : [];

      if (employee.length === 0) {
        try {
          const response = await restApiService.get(`contacts.lk/${currentId}`);
          inactiveOption = response.data;
        } catch (error) {
          console.error(error);
        }
      } else {
        inactiveOption = employee[0];
      }
    }
    return { inactiveOption };
  }, []);

  const params = useMemo(() => ({ contact_type: "COMPANY" }), []);

  return (
    <AbstractListPicker<EmployeePickerTypes.TEmployeePickerOption>
      name={name}
      label={label}
      required={required}
      instanceId={instanceId}
      params={params}
      objectPropertyKey={objectPropertyKey ?? (modelDataName && `${modelDataName}.employee`)}
      mergeInactive={mergeInactive}
      fetchUrl="contacts.lk"
    />
  );
};

export default memo(SingleEmployeePicker);
