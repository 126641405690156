import React, { memo, ReactNode, useEffect } from "react";
import { shallowEqual } from "react-redux";
import { useTypedSelector } from "reducers";
import { selectCurrentUser } from "reducers/userReducers";
import { WrappedFieldProps } from "redux-form";
import { CommonTypes } from "services/common/types/commonTypes";
import { IUser } from "services/common/user/userTypes";
import MultiDepartmentPicker from "./multiDepartmentPicker";
import SingleDepartmentPicker from "./singleDepartmentPicker";

export interface DepartmentPickerPropsTypes extends WrappedFieldProps, CommonTypes.TSelectMenu {
  modelData?: any;
  parentObjData?: any;
  className?: string;
  labelClassName?: string;
  label?: string;
  tooltip?: string;
  params?: Record<string, any>;
  required?: boolean;
  instanceId?: string;
  placeholder?: string;
  disabled?: boolean;
  onSelectCallback?: (value: string) => void;
  callBack?: (location: any) => void;
  originalValue?: ReactNode;
  isMulti?: boolean;
  isClearable?: boolean;
  showAsteriskWithoutLabel?: boolean;
  restrictOptionsByEmployeeDepartment?: boolean;
}

export type DepartmentsListOptionsType = {
  id?: string | number;
  label?: ReactNode;
  value?: string | number;
  has_categories?: boolean;
  has_budgets?: boolean;
  name?: string;
  status?: string;
};

const DepartmentPicker = ({
  input,
  params,
  instanceId,
  onSelectCallback,
  isMulti,
  parentObjData,
  modelData,
  callBack,
  ...rest // storing remain props in rest
}: DepartmentPickerPropsTypes) => {
  const currentUser: IUser = useTypedSelector(selectCurrentUser, shallowEqual);
  useEffect(() => {
    if (onSelectCallback) {
      onSelectCallback(input.value);
    }
  }, [input.value]);

  return (
    <>
      {isMulti ? (
        <MultiDepartmentPicker input={input} {...rest} instanceId={instanceId} />
      ) : (
        <SingleDepartmentPicker
          modelData={modelData}
          parentObjData={parentObjData}
          input={input}
          instanceId={instanceId}
          callBack={callBack}
          restrictOptionsByEmployeeDepartment={currentUser.company?.expense_item?.inherit_employee_department}
          {...rest}
        />
      )}
    </>
  );
};

export default memo(DepartmentPicker);
