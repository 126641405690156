import { useQuery } from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import PickerErrorBlock from "components/common/pickers/pickerErrorBlock";
import { Mandatory } from "components/forms/bootstrapFields";
import _ from "lodash";
import { restApiService } from "providers/restApi";
import React, { memo, useCallback, useEffect, useMemo } from "react";
import { Form } from "react-bootstrap";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import Select, { OnChangeValue } from "react-select";
import useCustomLabel from "services/admin/customLabels/useCustomLabel";
import { IAbstractListPickerProps } from "./abstractListPicker.types";

const AbstractListPicker = <T,>({
  id,
  name,
  objectName,
  params,
  fetchUrl,
  fetchInactiveURL,
  fetchFunctionAsync,
  valuePropertyName = "id",
  labelPropertyName = "name",
  label,
  sortProperty,
  required = false,
  disabled = false,
  multiple = false,
  clearable = true,
  fetchInactive = true,
  only_show_mapped = false,
  showInactiveStatus = false,
  placeholder = "",
  containerClassName = "",
  getResponseData,
  filterRecords,
  formatOptionLabel,
  callBackPropertyId,
  callBackFullObject,
  onMenuOpen,
  onMenuClose,
  menuPosition = "fixed",
  menuPlacement = "bottom",
}: IAbstractListPickerProps<T>) => {
  const { t } = useTranslation();
  const { control, setValue } = useFormContext();
  const currentId = useWatch({ name: name });
  const { getCustomLabel } = useCustomLabel();

  const getOptionValue = useCallback((option) => option?.[valuePropertyName], [valuePropertyName]);
  const getOptionLabel = useCallback((option) => option?.[labelPropertyName], [labelPropertyName]);

  const customLabel = useMemo(() => {
    return label ? getCustomLabel(label) : "";
  }, [getCustomLabel, label]);

  const memoFetchInactiveUrl = useMemo(() => {
    return fetchInactiveURL ? fetchInactiveURL(currentId) : `${fetchUrl}/${currentId}`;
  }, [currentId, fetchInactiveURL, fetchUrl]);

  const fetchData = useCallback(
    async (signal: AbortSignal | undefined) => {
      if (fetchFunctionAsync) {
        const data = await fetchFunctionAsync(params, signal);
        return Array.isArray(data) ? data : [];
      } else {
        const response: AxiosResponse<T> = await restApiService.get(fetchUrl, params, null, true, null, false, signal);
        const data = getResponseData ? getResponseData(response) : response.data;
        return Array.isArray(data) ? data : [];
      }
    },
    [fetchFunctionAsync, fetchUrl, getResponseData, params],
  );

  const {
    data: fetchedOptions,
    isFetching: isFetchingOptions,
    isError: isErrorOption,
  } = useQuery<T[]>({
    queryKey: [name, fetchUrl, params],
    queryFn: async ({ signal }) => {
      return await fetchData(signal);
    },
    enabled: params != null,
  });

  const shouldFetchInactive = useMemo(() => {
    return (
      fetchInactive &&
      !!currentId &&
      !isFetchingOptions &&
      !fetchedOptions?.some((opt) => getOptionValue(opt) === currentId)
    );
  }, [fetchInactive, currentId, isFetchingOptions, fetchedOptions, getOptionValue]);

  const {
    data: inactiveOption,
    isFetching: isFetchingInactiveOption,
    isError: isErrorInactiveOption,
  } = useQuery<T>({
    queryKey: [name, memoFetchInactiveUrl, currentId],
    queryFn: async ({ signal }) => {
      const response: AxiosResponse<T> = await restApiService.get(
        memoFetchInactiveUrl,
        null,
        null,
        true,
        null,
        false,
        signal,
      );
      return response.data;
    },
    enabled: shouldFetchInactive,
  });

  const options = useMemo<T[]>(() => {
    let finalOptions = fetchedOptions || [];
    if (filterRecords) {
      finalOptions = filterRecords(finalOptions);
    }
    if (inactiveOption) {
      finalOptions = [inactiveOption, ...finalOptions];
    }
    if (!required) {
      const emptyOption = {
        [valuePropertyName]: "",
        [labelPropertyName]: t("components.common.pickers.emptyOption", { label: customLabel || "" }),
      } as any;
      finalOptions = [emptyOption, ...finalOptions];
    }
    if (sortProperty) {
      finalOptions = _.sortBy(finalOptions, sortProperty);
    }
    return finalOptions;
  }, [
    customLabel,
    fetchedOptions,
    filterRecords,
    inactiveOption,
    labelPropertyName,
    required,
    sortProperty,
    t,
    valuePropertyName,
  ]);

  const isInactiveData = useCallback(
    (data: T) => {
      return (
        data &&
        !only_show_mapped &&
        showInactiveStatus &&
        inactiveOption &&
        getOptionValue(data) === getOptionValue(inactiveOption)
      );
    },
    [only_show_mapped, showInactiveStatus, inactiveOption, getOptionValue],
  );

  const getFormatOptionLabel = useCallback(
    (data) => {
      return formatOptionLabel ? (
        formatOptionLabel(data)
      ) : (
        <>
          {getOptionLabel(data)}
          {isInactiveData(data) && <small className="inactiveOption">({(data as any)?.status})</small>}
        </>
      );
    },
    [formatOptionLabel, getOptionLabel, isInactiveData],
  );

  const handleSelectChange = useCallback(
    (selected: OnChangeValue<T, boolean>, onChange: (value: any) => void) => {
      onChange(getOptionValue(selected));
      if (objectName) {
        setValue(objectName, selected);
      }
      if (callBackFullObject) {
        callBackFullObject(selected as T | T[] | null);
      }

      if (callBackPropertyId) {
        const ids =
          multiple && Array.isArray(selected) ? selected.map((item) => getOptionValue(item)) : getOptionValue(selected);
        callBackPropertyId(ids);
      }
    },
    [callBackFullObject, callBackPropertyId, getOptionValue, multiple, objectName, setValue],
  );

  const findSelectedOption = useCallback(
    (value: any): T | undefined => {
      return options.find((option) => getOptionValue(option) === value) || undefined;
    },
    [getOptionValue, options],
  );

  useEffect(() => {
    const inactiveOptionValue = inactiveOption ? getOptionValue(inactiveOption) : null;
    if (inactiveOption && !only_show_mapped && inactiveOptionValue !== currentId) {
      setValue(name, inactiveOptionValue);
      if (objectName) {
        setValue(objectName, inactiveOption);
      }
    }
    if (only_show_mapped && inactiveOptionValue === currentId) {
      setValue(name, null);
      if (objectName) {
        setValue(objectName, null);
      }
    }
  }, [currentId, getOptionValue, inactiveOption, name, objectName, only_show_mapped, setValue]);

  return (
    <Form.Group className={containerClassName}>
      {label && (
        <Form.Label className="pickerLabel">
          {customLabel} <Mandatory required={required} />
        </Form.Label>
      )}
      <Controller
        name={name}
        control={control}
        rules={required ? { required: t("validations.required") } : undefined}
        render={({ field: { value, onChange }, fieldState: { error, isTouched } }) => {
          return (
            <>
              <Select
                id={id}
                value={findSelectedOption(value)}
                isLoading={isFetchingOptions && isFetchingInactiveOption}
                options={options}
                isMulti={multiple}
                isClearable={clearable}
                getOptionLabel={getOptionLabel}
                getOptionValue={getOptionValue}
                onChange={(selected) => handleSelectChange(selected, onChange)}
                classNamePrefix={containerClassName}
                isDisabled={disabled}
                placeholder={placeholder}
                formatOptionLabel={getFormatOptionLabel}
                onMenuOpen={onMenuOpen}
                onMenuClose={onMenuClose}
                menuPosition={menuPosition}
                menuPlacement={menuPlacement}
              />
              {error?.message && <PickerErrorBlock error={error.message} />}
              {isErrorOption && <PickerErrorBlock error={t("components.common.pickers.loadingOptionsError")} />}
              {isErrorInactiveOption && (
                <PickerErrorBlock error={t("components.common.pickers.loadingInactiveOptionError")} />
              )}
            </>
          );
        }}
      />
    </Form.Group>
  );
};
export default memo(AbstractListPicker) as typeof AbstractListPicker;
