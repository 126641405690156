import React, { memo, useCallback, useMemo } from "react";
import { useTypedSelector } from "reducers";
import { IDType } from "services/common/types/common.type";
import AbstractListPicker from "../abstractListPicker/index2";

type TCustomDataListPickerProps = {
  id?: string;
  name: string;
  objectName?: string;
  model: string;
  required?: boolean;
  multiple?: boolean;
  activeOnly?: boolean;
  showInactiveStatus?: boolean;
  label?: string;
};

type TCustomDataListPickerOption = {
  name?: string;
  order?: number;
  status?: string;
  external_id?: string;
};

type TCustomDataListPickerPropsOption = {
  name?: string;
  order?: number;
  status?: string;
  external_id?: string;
  id: IDType;
};
const CustomDataListPicker = ({
  id,
  name,
  objectName,
  model,
  label,
  required = false,
  multiple = false,
  showInactiveStatus = false,
  activeOnly = true,
}: TCustomDataListPickerProps) => {
  const currentUser = useTypedSelector((state) => state.user);
  const companyId = currentUser?.company?.id;
  const params = { active: activeOnly, model: model };

  const fetchUrl = useMemo(() => {
    return `companies/${companyId}/custom_data_list`;
  }, [companyId]);

  const getFilterByExternalId = useCallback((options) => {
    return options.filter((option: any) => option?.external_id);
  }, []);

  const getResponseData = useCallback((response) => {
    return response?.data?.properties
      ? response?.data?.properties.data_list?.map((item: TCustomDataListPickerOption) => ({
          ...item,
          id: item.external_id ?? "",
        }))
      : [];
  }, []);

  return (
    <AbstractListPicker<TCustomDataListPickerPropsOption>
      name={name}
      label={label}
      fetchUrl={fetchUrl}
      required={required}
      params={params}
      filterRecords={getFilterByExternalId}
      showInactiveAsOption={showInactiveStatus}
      getResponseData={getResponseData}
    />
  );
};

export default memo(CustomDataListPicker);
