import { restApiService } from "providers/restApi";
import React, { useCallback, useMemo } from "react";
import { FormatOptionLabelMeta } from "react-select";
import { AbstractListPickerTypes } from "../abstractListPicker/abstractListPickerTypes";
import AbstractListPicker from "../abstractListPicker/index2";
import { WhTaxPickerTypes } from "./whTaxPickerType";

const WhTaxPicker = ({ name, label, objectPropertyKey, modelData, callBack }: WhTaxPickerTypes.TWhTaxPickerProps) => {
  const mergeInactive = useCallback(
    async (currentId, taxes) => {
      let inactiveOption = null;
      if (currentId) {
        const tax = Array.isArray(taxes) ? taxes.filter((tax) => tax.id === currentId) : [];
        if (tax.length === 0) {
          try {
            const response = restApiService.get("taxes", currentId);
            inactiveOption = response;
          } catch (error) {
            console.log(error);
          }
        } else {
          inactiveOption = tax[0];
        }
      }
      return { inactiveOption };
    },
    [modelData],
  );

  const handleSelection = useCallback(
    (selected: AbstractListPickerTypes.TPickerValue<WhTaxPickerTypes.TWhTaxPickerOption>) => {
      if (callBack) {
        callBack(selected);
      }
    },
    [callBack],
  );
  let params = useMemo(() => ({ TYPES: "WH_TAX", ACTIVE: "YES" }), []);
  const formatOptionLabel = useCallback(
    (
      tax: WhTaxPickerTypes.TWhTaxPickerOption,
      context?: FormatOptionLabelMeta<WhTaxPickerTypes.TWhTaxPickerOption>,
    ) => {
      return (
        <>
          {context?.context == "value" ? (
            <span>
              {tax?.code}
              {tax.rate && tax.rate > 0 && ` (${tax.rate}%)`}
              {tax?.name}
            </span>
          ) : (
            <span>
              {tax.code && `code: ${tax.code}`}
              {tax.rate && tax.rate > 0 && ` Rate: ${tax.rate}%`}
              {tax?.name} {/*tax.name handel if user select 1st option i.e. select*/}
            </span>
          )}
        </>
      );
    },
    [],
  );
  return (
    <>
      <AbstractListPicker<WhTaxPickerTypes.TWhTaxPickerOption>
        name={name}
        label={label}
        objectPropertyKey={objectPropertyKey}
        fetchUrl="taxes.lk"
        params={params}
        formatOptionLabel={formatOptionLabel}
        mergeInactive={mergeInactive}
        callBack={handleSelection}
      />
    </>
  );
};

export default WhTaxPicker;
