import React from "react";
import { Row, Col } from "react-bootstrap";
import TabNavigation from "../../navigation/tabNavigation";
import styles from "./expense.module.css";
import { RootState, useTypedSelector } from "reducers";
import { shallowEqual } from "react-redux";

//NOTE: add only those route which we want to show on tabNavigations
// href is used for angular
// path is used for react

export const expensesNavTabsRoutes = (countState: any) => {
  return [
    {
      pageName: "Dashboard",
      path: "/ap/expense_dashboard",
      authorization: {
        I: "list",
        a: "ExpenseItems",
      },
    },
    {
      pageName: "Expenses",
      path: "/ap/expenses",
      authorization: {
        I: "list",
        a: "ExpenseItems",
      },
      highlightLabel: countState?.unsubmitted_expense_item_count,
    },
    {
      path: "/ap/expenseItems/draft",
      pageName: "Draft",
      authorization: {
        I: "listdraft",
        a: "ExpenseItems",
      },
    },
    {
      path: "/ap/expense_reports",
      pageName: "Expense Reports",
      authorization: {
        I: "list",
        a: "ExpenseReports",
      },
      highlightLabel: countState?.unsubmitted_expense_report_count,
    },
    {
      pageName: "Pay Reimbursements",
      href: "pay_expenses",
      authorization: {
        I: "pay",
        a: "ExpenseReports",
      },
    },
    {
      pageName: "Reimbursement Status",
      href: "reimbursement_status",
      authorization: {
        permissionExcept: ["payExpenseReports"],
      },
    },
    {
      path: "",
      pageName: "Policies",
      href: "policies",
      authorization: {
        I: "list",
        a: "Policies",
      },
    },
    {
      path: "/ap/inbound_receipts",
      pageName: "Receipt",
      authorization: {
        I: "receipts",
        a: "_visible",
      },
    },
  ];
};

const NavTabs = ({ activePageName }: { activePageName: string }) => {
  const countState = useTypedSelector((state: RootState) => state.unsubmittedExpensesCount, shallowEqual);
  return (
    <>
      <Row className="mx-0">
        <Col className="px-0">
          <TabNavigation activePageName={activePageName} navigationTab={expensesNavTabsRoutes(countState)} />
        </Col>
        <Col className="px-0" style={{ flex: 0 }}>
          <a
            className={`d-flex text-nowrap ${styles.expenseReportHelp} text-decoration-none`}
            target="_blank"
            href="https://accrualify.zendesk.com/hc/en-us/sections/35781856038803-Create-Submit-Expense-Reports"
            rel="noreferrer"
          >
            <div className="mr-2">
              <i className="icon-expense_report-help"></i>
            </div>
            Expense Report Help
          </a>
        </Col>
      </Row>
    </>
  );
};

export default NavTabs;
