import i18next from "i18next";
import _ from "lodash";
import moment from "moment";
import { getMatchedNumber } from "services/general/helpers";

export const required = (value) =>
  value !== undefined && value !== null && value !== "" ? undefined : i18next.t("validations.required");

export const canNotBeZero = (value) => (value !== 0 && value !== "0" ? undefined : i18next.t("validations.required"));

export const isEmpty = (value) => (!_.isEmpty(value) ? undefined : i18next.t("validations.required"));

export const noWhiteSpaceOnly = (value) =>
  _.isString(value) && !value.trim() ? i18next.t("validations.noWhiteSpaceOnly") : undefined;

export const noWhiteSpaceAllowed = (value) => {
  const containsWhitespace = /\s/.test(value);
  return containsWhitespace ? i18next.t("validations.noWhiteSpacesAllowed") : undefined;
};

export const charOnly = (value) => {
  if (!value) {
    return undefined; // Allow empty input
  }

  const regex = /^[A-Za-z.]+(?:\s+[A-Za-z.]+)*$/; // Regular expression to match letters (both uppercase and lowercase) and dots
  if (!regex.test(value)) {
    return i18next.t("validations.charOnly");
  }

  return undefined;
};

export const isValidBirthday = (value) => {
  if (!value) {
    return undefined;
  }
  const splittedValue = value.split("/");
  const month = Number(splittedValue[0]);
  const day = Number(splittedValue[1]);
  if (!month || !day || month > 12 || month < 1 || day > new Date(0, month, 0).getDate() || day < 1) {
    return "Please enter valid birthday mm/dd";
  }
};

export const numberOnly = (value) => (!value || /^[0-9]+$/.test(value) ? undefined : "Please enter a valid number.");

export const maxLength = (max) => (value) =>
  value && value.length > max ? i18next.t("validations.maxLength", { max: max }) : undefined;

export const minLength = (min) => (value) =>
  value && value.length < min ? i18next.t("validations.minLength", { min: min }) : undefined;

export const length = (len) => {
  return (value) => (value && value.length !== len ? i18next.t("validations.equalLength", { len: len }) : undefined);
};
export const length9 = length(9);
export const length6 = length(6);

export const maxLength6 = maxLength(6);
export const maxLength15 = maxLength(15);

export const phoneNumberOnly = (value) =>
  !value || /^[0-9]+$/.test(value) ? undefined : "Please ensure the entered information adheres to this pattern [0-9]+";

export const number = (value) => (value && isNaN(Number(value)) ? i18next.t("validations.mustBeNumber") : undefined);

export const wholeNumber = (value) =>
  value && !Number.isInteger(Number(value)) ? i18next.t("validations.mustBeWholeNumber") : undefined;

export const beforeDate = (beforeRef) => (value) => {
  if (!beforeRef?.current || !value) {
    return undefined;
  }
  const targetDate = new Date(value);
  const beforeDate = new Date(beforeRef.current);
  return value && beforeRef.current && targetDate > beforeDate
    ? `${i18next.t("validations.beforeDate")} ${beforeDate.toLocaleDateString()}`
    : undefined;
};

export const afterDate = (afterRef) => (value) => {
  if (!afterRef?.current || !value) {
    return undefined;
  }
  const targetDate = new Date(value);
  const afterDate = new Date(afterRef.current);
  return value && afterRef.current && targetDate < afterDate
    ? `${i18next.t("validations.afterDate")} ${afterDate.toLocaleDateString()}`
    : undefined;
};

export const beforeCurrentDateTime = (tz, addDays) => (value) => {
  if (!value) {
    return undefined;
  }
  const targetDate = new Date(moment.parseZone(value).format("YYYY-MM-DDTHH:mm"));

  let beforeDate;
  if (tz) {
    switch (tz) {
      case "utc":
        beforeDate = new Date(
          addDays
            ? moment.utc().add(addDays, "days").format("YYYY-MM-DDTHH:mm")
            : moment.utc().format("YYYY-MM-DDTHH:mm:ss"),
        );
        break;

      default:
        beforeDate = new Date(
          addDays
            ? moment.tz(tz).add(addDays, "days").format("YYYY-MM-DDTHH:mm")
            : moment().tz(tz).format("YYYY-MM-DDTHH:mm"),
        );
        break;
    }
  } else {
    beforeDate = new Date(moment().addDays(addDays, "days").format("YYYY-MM-DDTHH:mm"));
  }

  return value && beforeDate && targetDate > beforeDate
    ? `${i18next.t("validations.beforeDate")} ${beforeDate.toLocaleDateString() + " " + beforeDate.toLocaleTimeString()}`
    : undefined;
};

export const afterCurrentDateTime = (tz, addDays) => (value) => {
  if (!value) {
    return undefined;
  }

  let afterDate;
  if (tz) {
    switch (tz) {
      case "utc":
        afterDate = new Date(
          addDays
            ? moment.utc().add(addDays, "days").format("YYYY-MM-DDTHH:mm")
            : moment.utc().format("YYYY-MM-DDTHH:mm:ss"),
        );
        break;

      default:
        afterDate = new Date(
          addDays
            ? moment.tz(tz).add(addDays, "days").format("YYYY-MM-DDTHH:mm")
            : moment().tz(tz).format("YYYY-MM-DDTHH:mm"),
        );
        break;
    }
  } else {
    afterDate = new Date(moment().addDays(addDays, "days").format("YYYY-MM-DDTHH:mm"));
  }

  const targetDate = new Date(moment.parseZone(value).format("YYYY-MM-DDTHH:mm"));

  return value && afterDate && targetDate < afterDate
    ? `${i18next.t("validations.afterDate")} ${afterDate.toLocaleDateString() + " " + afterDate.toLocaleTimeString()}`
    : undefined;
};

export const minValue = (min) => (value) =>
  value && value < min ? i18next.t("validations.minValue", { min: min }) : undefined;

export const minValueCleaned = (min) => (value) => {
  const numericalValue = getMatchedNumber(value);
  return value && numericalValue <= min ? i18next.t("validations.minValue", { min: min }) : undefined;
};

export const minValue18 = minValue(18);

export const maxValue = (max) => (value) =>
  value && value > max ? i18next.t("validations.maxValue", { max: max }) : undefined;

export const email = (value) =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value) ? i18next.t("validations.invalidEmail") : undefined;

export const password = (value) => {
  return value && !value.match(passwordPattern) ? i18next.t("validations.password") : undefined;
};

export const passwordPattern = "^(?=.*[A-Za-z])(?=.*\\d)(?=.*[@$!%*#?&])[A-Za-z\\d@$!%*#?&]{8,}$";

export const noNegativeNumber = (value) => {
  return value && Number(value) < 0 ? i18next.t("validations.noNegativeNumber") : undefined;
};

export const fileNameSafe = (value) => {
  if (!value) {
    return undefined; // Allow empty input
  }
  // Regular expression to match alphanumeric characters, underscores, and hyphens
  const alphanumericRegex = /^[a-zA-Z0-9_\- ]+$/;
  if (!alphanumericRegex.test(value)) {
    return i18next.t("validations.alphanumericForFilename");
  }
  return undefined;
};
