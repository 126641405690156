import CurrencySymbolRenderer from "components/admin/commonUsed/currencySymbolRenderer";
import AccountPicker from "components/admin/pickers/reactHookFormPickers/accountPicker";
import BusinessUnitPicker from "components/admin/pickers/reactHookFormPickers/businessUnitPicker";
import CategoryPicker from "components/admin/pickers/reactHookFormPickers/categoryPicker";
import DepartmentPicker from "components/admin/pickers/reactHookFormPickers/departmentPicker";
import LocationPicker from "components/admin/pickers/reactHookFormPickers/locationPicker";
import { InputField, TextAreaField } from "components/forms/hookFormFields";
import _ from "lodash";
import React, { useCallback, useMemo, useState } from "react";
import { Button, Table } from "react-bootstrap";
import { useFieldArray, useFormContext } from "react-hook-form";
import { BsCheck2, BsFiles, BsGripVertical, BsPencil, BsPlusLg, BsTrash3 } from "react-icons/bs";
import { useTypedSelector } from "reducers";
import adminCommonSvc from "services/admin/commonSvc";
import invoiceCommonSvc from "services/admin/invoices/invoiceCommonSvc";
import { InvoiceType } from "services/admin/invoices/invoiceType";
import { IUser } from "services/common/user/userTypes";
import styles from "../materialDataTable.module.css";
import { allowDrag, handleDragStart, handleDrop } from "./dragUtils";

type TInvoiceCreditLineSectionProps = {
  allowSubmitWithGLErrors: boolean;
};

const InvoiceCreditLineSection = ({ allowSubmitWithGLErrors }: TInvoiceCreditLineSectionProps) => {
  const { control, getValues, setValue, trigger } = useFormContext<any>();
  const currentUser: IUser = useTypedSelector((state) => state.user);
  const { fields, append, update, move } = useFieldArray<any>({
    control,
    name: "credit_entries_attributes",
    keyName: "_id" as "id",
  });

  const creditEntries = fields as unknown as InvoiceType.TInvoiceExpenseLineEntry[];
  const [draggingIndex, setDraggingIndex] = useState<number | null>(null);

  // Calculate the number of columns dynamically
  const dynamicColumnCount = useMemo(() => {
    let columnCount = 6; // Drag, Actions, Account, Percent, Memo, Amount (static columns)
    if (currentUser.company.has_departments && !currentUser.company.invoice?.expenses?.department?.is_hide)
      columnCount += 1; // Department
    if (currentUser.company.has_locations && !currentUser.company.invoice_account_hide_location) columnCount += 1; // Location
    if (currentUser.company.has_business_units && !currentUser.company.invoice_account_hide_business_unit)
      columnCount += 1; // Business Unit
    if (
      currentUser.company.has_categories &&
      (!currentUser.company.invoice || (currentUser.company.invoice && !currentUser.company.invoice.hide_categories))
    ) {
      columnCount += 1; // Category
    }
    return columnCount;
  }, [
    currentUser.company.has_business_units,
    currentUser.company.has_categories,
    currentUser.company.has_departments,
    currentUser.company.has_locations,
    currentUser.company.invoice,
    currentUser.company.invoice_account_hide_business_unit,
    currentUser.company.invoice_account_hide_location,
  ]);

  const allowEditRow = async (entry: InvoiceType.TInvoiceExpenseLineEntry, index: number) => {
    let isValidate = true;
    if (entry.allowEdit) {
      isValidate = await trigger(`credit_entries_attributes.${index}`);
    }
    if (isValidate) {
      const updatedItem = {
        ...getValues(`credit_entries_attributes.${index}`),
        id: entry.id,
        allowEdit: !entry.allowEdit,
      };
      updatedItem.amount = Number(updatedItem.amount);
      updatedItem.percent = Number(updatedItem.percent);

      setValue(`credit_entries_attributes.${index}`, updatedItem);
      update(index, updatedItem);
    }
  };

  const addDuplicateRow = (item: InvoiceType.TInvoiceExpenseLineEntry, index: number) => {
    const creditEntry: InvoiceType.TInvoiceExpenseLineEntry = { ...getValues(`credit_entries_attributes.${index}`) };
    delete creditEntry.id;
    creditEntry.isDuplicateItem = true;
    creditEntry.allowEdit = true;
    append(creditEntry);
  };

  const destroyExpense = (index: number) => {
    let updatedEntry = { ...getValues(`credit_entries_attributes[${index}]`), _destroy: 1 };
    update(index, updatedEntry);
  };

  const addNewRow = () => {
    let obj = { allowEdit: true };
    append(obj);
  };

  const manageAmountChange = (amount: number, index: number) => {
    let invAmount = Number(getValues(`amount`));
    let percent = Number(adminCommonSvc.roundUpAmount((Number(amount) / invAmount) * 100));

    setValue(`credit_entries_attributes.${index}.amount`, Number(amount));
    setValue(`credit_entries_attributes.${index}.percent`, percent);
  };

  const managePercentChange = (percent: number, index: number) => {
    let invAmount = Number(getValues(`amount`));
    let amount = Number(adminCommonSvc.roundUpAmount((invAmount * Number(percent)) / 100, null, currentUser));
    setValue(`credit_entries_attributes.${index}.amount`, amount);
    setValue(`credit_entries_attributes.${index}.percent`, Number(percent));
  };

  const isValidEntry = useCallback((entry: any) => _.isPlainObject(entry) && entry._destroy !== 1, []);
  const allowDragMemo = useMemo(
    () => allowDrag(creditEntries, (entry) => isValidEntry(entry)),
    [creditEntries, isValidEntry],
  );

  return (
    <div className={`${styles.mainDualDiv} mt-2`}>
      <span className="mb-2">
        <h5 className={styles.cardTitle}>Credit Entries</h5>
      </span>
      <div className={styles.mainTableWrapper}>
        <Table size="sm" className={`${styles.tableMaterial} ${styles.scrollableTable}`}>
          <thead>
            <tr>
              <th className={styles.dragHandleHeader}></th>
              <th>Actions</th>
              <th>Account</th>
              <th>Percent</th>
              <th>Amount</th>
              <th>Memo</th>

              {currentUser.company.has_departments && !currentUser.company.invoice?.expenses?.department?.is_hide && (
                <th>Department</th>
              )}
              {currentUser.company.has_locations && !currentUser.company.invoice_account_hide_location && (
                <th>Location</th>
              )}
              {currentUser.company.has_business_units && !currentUser.company.invoice_account_hide_business_unit && (
                <th>Business Unit</th>
              )}
              {currentUser.company.has_categories &&
                (!currentUser.company.invoice ||
                  (currentUser.company.invoice && !currentUser.company.invoice.hide_categories)) && <th>Category</th>}
            </tr>
          </thead>
          <tbody>
            {creditEntries.map((entry, index) => {
              const canDrag = allowDragMemo && !entry.allowEdit;
              const editIcon = entry.allowEdit ? (
                <BsCheck2
                  className={styles.operationIcon}
                  size={20}
                  onClick={() => {
                    allowEditRow(entry, index);
                  }}
                />
              ) : (
                <BsPencil
                  className={styles.operationIcon}
                  size={15}
                  onClick={() => {
                    allowEditRow(entry, index);
                  }}
                />
              );
              return (
                isValidEntry(entry) && (
                  <tr
                    key={entry._id ?? index}
                    draggable={canDrag}
                    className={styles.tableBody}
                    onDragStart={(e) => handleDragStart(e, index, setDraggingIndex)}
                    onDrop={(e) => handleDrop(e, move, index, draggingIndex, setDraggingIndex)}
                  >
                    <td className={canDrag ? styles.dragHandleHeader : ""}>
                      {canDrag && <BsGripVertical className={styles.operationIcon} size={16} />}
                    </td>
                    <td className={styles.iconsSection}>
                      {editIcon}
                      <BsFiles
                        className={styles.operationIcon}
                        size={16}
                        onClick={() => addDuplicateRow(entry, index)}
                      />

                      <BsTrash3 className={styles.operationIcon} size={16} onClick={() => destroyExpense(index)} />
                    </td>
                    <td>
                      {entry.allowEdit ? (
                        <AccountPicker
                          containerClassName={`${styles.largeInputDesc} ${styles.customInput}`}
                          name={`credit_entries_attributes.${index}.account_id`}
                          accountGroupName="INVOICE_CREDIT_GROUPS"
                          modelData={`credit_entries_attributes.${index}`}
                          required={invoiceCommonSvc.isFieldRequired(true, allowSubmitWithGLErrors)}
                          parentObj={""}
                        />
                      ) : (
                        `${entry.account_number ?? ""} - ${entry.account_name ?? ""}`
                      )}
                    </td>

                    <td>
                      {entry.allowEdit ? (
                        <InputField
                          containerClassName={`${styles.largeInput} ${styles.customInput}`}
                          name={`credit_entries_attributes.${index}.percent`}
                          type="number"
                          onChange={(e) => managePercentChange(e.target.value, index)}
                        />
                      ) : (
                        `${entry.percent ?? ""}`
                      )}
                    </td>

                    <td>
                      {entry.allowEdit ? (
                        <InputField
                          containerClassName={`${styles.largeInput} ${styles.customInput}`}
                          name={`credit_entries_attributes.${index}.amount`}
                          type="number"
                          onChange={(e) => manageAmountChange(e.target.value, index)}
                        />
                      ) : (
                        <CurrencySymbolRenderer name="currency_code" amount={Number(entry.amount) || 0} />
                      )}
                    </td>

                    <td>
                      {entry.allowEdit ? (
                        <TextAreaField
                          containerClassName={`${styles.largeInputDesc} ${styles.customInput}`}
                          name={`credit_entries_attributes.${index}.memo`}
                          required={invoiceCommonSvc.isFieldRequired(
                            currentUser?.company?.invoice?.expenses?.memo?.is_required,
                            allowSubmitWithGLErrors,
                          )}
                        />
                      ) : (
                        entry.memo
                      )}
                    </td>

                    {currentUser.company.has_departments &&
                      !currentUser.company.invoice?.expenses?.department?.is_hide && (
                        <td>
                          {" "}
                          {entry.allowEdit ? (
                            <DepartmentPicker
                              containerClassName={`${styles.largeInputDesc} ${styles.customInput}`}
                              name={`credit_entries_attributes.${index}.department_id`}
                              modelData={`credit_entries_attributes.${index}`}
                              // parentObj={""} //header
                              required={invoiceCommonSvc.isFieldRequired(
                                currentUser?.company?.invoice?.expenses?.department?.is_required, // || invoiceItem.is_department_required
                                allowSubmitWithGLErrors,
                              )}
                            />
                          ) : (
                            (entry.department?.name ?? entry.department_name)
                          )}
                        </td>
                      )}
                    {currentUser.company.has_locations && !currentUser.company.invoice_account_hide_location && (
                      <td>
                        {entry.allowEdit ? (
                          <LocationPicker
                            name={`credit_entries_attributes.${index}.location_id`}
                            modelDataName={`credit_entries_attributes.${index}`}
                            containerClassName={`${styles.projectMinWidth} ${styles.customInput}`}
                            required={invoiceCommonSvc.isFieldRequired(
                              currentUser?.company?.invoice_expense_location_required,
                              allowSubmitWithGLErrors,
                            )}
                          />
                        ) : (
                          (entry.location?.name ?? entry.location_name)
                        )}
                      </td>
                    )}
                    {currentUser.company.has_business_units &&
                      !currentUser.company.invoice_account_hide_business_unit && (
                        <td>
                          {entry.allowEdit ? (
                            <BusinessUnitPicker
                              name={`credit_entries_attributes.${index}.business_unit_id`}
                              modelDataName={`credit_entries_attributes.${index}`}
                              disabled={currentUser?.company?.readonly_business_unit_to_all}
                              containerClassName={`${styles.projectMinWidth} ${styles.customInput}`}
                              required={invoiceCommonSvc.isFieldRequired(
                                currentUser?.company?.invoice?.expenses?.business_unit?.is_required,
                                allowSubmitWithGLErrors,
                              )}
                            />
                          ) : (
                            (entry.business_unit?.name ?? entry.business_unit_name)
                          )}
                        </td>
                      )}

                    {currentUser.company.has_categories &&
                      (!currentUser.company.invoice ||
                        (currentUser.company.invoice && !currentUser.company.invoice.hide_categories)) && (
                        <td>
                          {entry.allowEdit ? (
                            <CategoryPicker
                              name={`credit_entries_attributes.${index}.category_id`}
                              modelData={`credit_entries_attributes.${index}`}
                              containerClassName={`${styles.projectMinWidth} ${styles.customInput}`}
                            />
                          ) : (
                            (entry.category?.name ?? entry.category_name)
                          )}
                        </td>
                      )}
                  </tr>
                )
              );
            })}
            {creditEntries.length < 1 && (
              <tr>
                <td colSpan={dynamicColumnCount}>
                  <span className={styles.noRecords}>Records not found!</span>
                </td>
              </tr>
            )}
          </tbody>
        </Table>
        <Button variant="light" className={styles.actionBtn} onClick={() => addNewRow()}>
          <BsPlusLg className={styles.actionIcon} />
          Add Credit Line
        </Button>
      </div>
      {/* Not allowing to add credit entry */}
    </div>
  );
};

export default InvoiceCreditLineSection;
