import { Receipt } from "components/admin/expensese/expenseItem/receiptListContext";
import _ from "lodash";
import { restApiService } from "providers/restApi";
import { CommonApis } from "services/admin/commonApis";
import adminCommonSvc from "services/admin/commonSvc";
import PolicyApis from "services/admin/policy/policyApis";
import policyCommonSvc from "services/admin/policy/policyCommonSvc";
import { PolicyTypes } from "services/admin/policy/policyTypes";
import PurchaseOrdersApis from "services/admin/purchaseOrders/purchaseOrderApi";
import commonService from "services/common/commonSvc";
import ContactApis from "services/common/contact/contactApis";
import { IAttachment, IDType } from "services/common/types/common.type";
import { CommonTypes } from "services/common/types/commonTypes";
import { IUser } from "services/common/user/userTypes";
import { isDefined } from "services/general/helpers";
import { CreateNotification, NotificationType } from "services/general/notifications";
import { ExpenseReportTypes } from "../expenseReport/expenseReportType";
import ExpensesApis from "../expensesApis";
import { ExpensesTypes } from "../expensesType";
import expenseService, { ExpenseConstants } from "../expenseSvc";

class ExpenseItemCommonSvc {
  MILEAGE_GOOGLE_MAP_VIEW_DIV_ID = "MILEAGE_GOOGLE_MAP_VIEW_DIV_ID";
  MILEAGE_GOOGLE_MAP_ATTACHMENT_NAME_PREFIX = "mileageGoogleMap_";

  showSubsidiary = (currentUser: IUser) => {
    return currentUser.company.expense?.show_subsidiary;
  };

  calculatePerDiemAmount(expenseItem: ExpensesTypes.ExpenseItemFormDataType, currentUser: IUser) {
    if (!expenseItem.per_diem) {
      expenseItem.per_diem = {};
    }

    if (expenseItem?.per_diem?.currency_code) {
      expenseItem.currency_code = expenseItem?.per_diem?.currency_code;
    }
    const unitPrice = expenseItem.per_diem.unit_price ? expenseItem.per_diem.unit_price : 0;
    expenseItem.amount =
      expenseItem?.qty && adminCommonSvc.roundUpAmount(parseFloat(unitPrice) * expenseItem?.qty, null, currentUser);
    return expenseItem;
  }

  moveAssetsAttributes = (expenseItem: ExpensesTypes.ExpenseItemFormDataType) => {
    if (Array.isArray(expenseItem.assets_attributes) && expenseItem.assets_attributes.length > 0) {
      // Move attachments to a separate array
      let attachments = expenseItem.assets_attributes;
      // Delete attachments from the source to prevent it from being submitted with the post request
      delete expenseItem.assets_attributes;
      return attachments;
    }
  };

  setDefaultDistanceUnit = ({
    currencyCode,
    index,
    currentUser,
    expenses,
    isEditLoading,
  }: {
    currencyCode: string;
    index: number;
    expenses: ExpensesTypes.ExpenseItemFormDataType[];
    currentUser: IUser;
    isEditLoading?: boolean;
  }) => {
    if (expenses) {
      expenses[index].default_mileage_value = _.find(expenses[index].policy?.mileage_rates, {
        currency_code: currencyCode,
      });
      if (!expenses[index].default_mileage_value) {
        expenses[index].set_mileage_rate_error = true;
        return expenses[index];
      } else {
        expenses[index].set_mileage_rate_error = false;
      }

      // don't update distance_unit on milage, when loading for edit
      if (!isEditLoading) {
        expenses[index].distance_unit = expenses[index].default_mileage_value?.default_unit;
      }
      this.calculateExpenseDistance(expenses[index], index, currentUser);

      return expenses[index];
    }
  };

  uploadAttachments = async ({ attachments, expenseId }: { attachments: IAttachment[]; expenseId: IDType }) => {
    try {
      const formData = new FormData();
      if (Array.isArray(attachments)) {
        attachments.forEach((attachment, index) => {
          attachment.asset_file &&
            formData.append(`expense_item[assets_attributes][${index}][asset_file]`, attachment.asset_file);
          attachment.asset_file_file_name &&
            formData.append(
              `expense_item[assets_attributes][${index}][asset_file_file_name]`,
              attachment?.asset_file_file_name,
            );
          attachment.url && formData.append(`expense_item[assets_attributes][${index}][url]`, attachment?.url);
          attachment.asset_expiring_url &&
            formData.append(
              `expense_item[assets_attributes][${index}][asset_expiring_url]`,
              attachment?.asset_expiring_url,
            );
          attachment.asset_expiring_url &&
            formData.append(
              `expense_item[assets_attributes][${index}][asset_expiring_url]`,
              attachment?.asset_expiring_url,
            );

          attachment.id && formData.append(`expense_item[assets_attributes][${index}][id]`, String(attachment?.id));

          attachment._destroy &&
            formData.append(`expense_item[assets_attributes][${index}][_destroy]`, String(attachment?._destroy));
        });
      }
      const expense = await ExpensesApis.patchAttachaments({ id: expenseId, formData });
      return expense;
    } catch (error) {
      throw error;
    }
  };

  disableReimbursable = (expenseItem: ExpensesTypes.ExpenseItemFormDataType) => {
    if (_.isPlainObject(expenseItem)) {
      return (
        !!(expenseItem.link_to_expense_report && expenseItem.link_to_expense_report !== "new") ||
        !!(expenseItem.expense_report || expenseItem.purchase)
      );
    }
    return false;
  };

  isAdminUser = (currentUser: IUser) => {
    let filteredArray = currentUser?.roles?.filter(function (role) {
      return (
        ["expense_admin", "admin", "admin_expensereport", "universal_admin", "application_admin"].indexOf(role.name) !==
        -1
      );
    });
    return filteredArray.length > 0;
  };

  showReimbursable = (currentUser: IUser) => {
    return currentUser.company.expense_item?.show_reimbursable && this.isAdminUser(currentUser);
  };

  isAllowToShowErPicker = (expenseItem: ExpensesTypes.ExpenseItemFormDataType, currentUser: IUser) => {
    // If company setting is turned on to hide the expense report dropdown
    if (currentUser.company.expense_item?.hide_new_expense_report) {
      return false;
    }
    return (
      expenseItem?.policy_id &&
      (!currentUser.company.expense || !currentUser.company.expense?.enforce_policy_violations)
    );
  };

  isTaxFieldsEnable(isTaxesEnabled: boolean, currentUser: IUser): boolean {
    return Boolean(!currentUser.company?.expense_item?.hide_tax_fields && isTaxesEnabled);
  }

  calculateExpenseDistance(expenseItem: ExpensesTypes.ExpenseItemFormDataType, index: number, currentUser: IUser) {
    if (expenseItem.policy && expenseItem.default_mileage_value) {
      let mileageRate;
      if (expenseItem.distance_unit === "mi") {
        mileageRate = expenseItem.default_mileage_value.rate_per_mi
          ? expenseItem.default_mileage_value.rate_per_mi
          : expenseItem.policy?.rate_per_mi
            ? expenseItem.policy.rate_per_mi
            : _.isPlainObject(expenseItem.policy?.properties)
              ? expenseItem.policy?.properties.rate_per_mi
              : 0;
      } else if (expenseItem.distance_unit === "km") {
        mileageRate = expenseItem.default_mileage_value.rate_per_km
          ? expenseItem.default_mileage_value.rate_per_km
          : expenseItem.policy?.rate_per_km
            ? expenseItem.policy.rate_per_km
            : _.isPlainObject(expenseItem.policy?.properties)
              ? expenseItem.policy?.properties.rate_per_km
              : 0;
      }
      let distance = expenseItem?.distance && Number(expenseItem?.distance);
      if (distance) {
        expenseItem.amount = adminCommonSvc.roundUpAmount(distance * parseFloat(mileageRate), null, currentUser);
      }
      return expenseItem;
    }
  }

  isCategoryExist(categoryId: IDType | undefined, categories: any[]) {
    if (categoryId) {
      let category = _.find(categories, { id: categoryId });
      return !!category;
    }
  }

  canConvertToSubsidiaryCurrency = (expenseItem: ExpensesTypes.ExpenseItemFormDataType | null | undefined) => {
    return expenseItem?.policy?.convert_to_subsidiary_currency && expenseItem?.employee?.subsidiary?.currency_code;
  };

  canConvertToEmployeeCurrency = (expenseItem: ExpensesTypes.ExpenseItemFormDataType | null | undefined) => {
    return expenseItem?.policy?.convert_to_employee_currency && expenseItem?.employee?.currency_code;
  };

  getCurrencyRatePromise = async (
    expenseItem: ExpensesTypes.ExpenseItemFormDataType,
    appCurrencies: CommonTypes.ListCurrencyCodeItem,
    currentUser: IUser,
  ) => {
    try {
      if (this.isConversionAvailable(expenseItem)) {
        const to_currency = this.assignCurrency(expenseItem, appCurrencies);
        const params = { to_currency: to_currency, from_currency: expenseItem.base_currency_code };
        const exchangeRate = await CommonApis.getExchangeRate(params);
        expenseItem.currency_exchange = exchangeRate;
        expenseItem.currency_rate = expenseItem.currency_exchange.rate;
        this.convertBaseAmounts(expenseItem, currentUser);
      }
    } catch (error) {
      commonService.handleError(error);
    }
  };

  calculateExchangeTax = (expenseItem: ExpensesTypes.ExpenseItemFormDataType, currentUser: IUser, erPolicy?: any) => {
    if (this.isConversionAvailable(expenseItem)) {
      this.checkExchangeRate(expenseItem);
      this.calculateBaseCurrencyTax(expenseItem, currentUser);
      expenseItem.tax =
        expenseItem.base_currency_tax &&
        adminCommonSvc.roundUpAmount(
          expenseItem.currency_exchange.rate * expenseItem.base_currency_tax,
          null,
          currentUser,
        );
      this.updateExpenseAmount(expenseItem, currentUser);
      this.calculateExchangeAmount(expenseItem, currentUser);
    }
  };

  checkExchangeRate = (expenseItem: ExpensesTypes.ExpenseItemFormDataType) => {
    expenseItem.currency_exchange = { rate: expenseItem.currency_rate };
  };

  calculateBaseCurrencyTax(expenseItem: ExpensesTypes.ExpenseItemFormDataType, currentUser: IUser) {
    if (
      this.isConversionAvailable(expenseItem) &&
      expenseItem.base_currency_total &&
      _.isPlainObject(expenseItem.tax_code) &&
      expenseItem.tax_code.id
    ) {
      expenseItem.base_currency_tax = adminCommonSvc.roundUpAmount(
        (expenseItem.base_currency_total * expenseItem.tax_code.rate) / (100 + expenseItem.tax_code.rate),
        null,
        currentUser,
      );
    }
  }

  calculateExchangeAmount(expenseItem: ExpensesTypes.ExpenseItemFormDataType, currentUser: IUser) {
    if (expenseItem.base_currency_total !== null) {
      let tax =
        expenseItem?.base_currency_tax && expenseItem?.base_currency_tax > 0 ? expenseItem.base_currency_tax : 0;
      let total = expenseItem.base_currency_total ? expenseItem.base_currency_total : 0;
      expenseItem.base_currency_amount = adminCommonSvc.roundUpAmount(total - tax, null, currentUser);
    }
  }

  calculateExchangeTotal(expenseItem: ExpensesTypes.ExpenseItemFormDataType, currentUser: IUser) {
    this.checkExchangeRate(expenseItem);
    if (_.isPlainObject(expenseItem.currency_exchange) && expenseItem.base_currency_total) {
      expenseItem.total = adminCommonSvc.roundUpAmount(
        expenseItem.currency_exchange.rate * expenseItem.base_currency_total,
        null,
        currentUser,
      );
      this.calculateBaseCurrencyTax(expenseItem, currentUser);
      this.calculateExchangeAmount(expenseItem, currentUser);
      this.calculateExpenseTax(expenseItem, currentUser);
      this.updateExpenseAmount(expenseItem, currentUser);
    }
  }

  calculateExpenseTax = (expenseItem: ExpensesTypes.ExpenseItemFormDataType, currentUser: IUser) => {
    if (this.isConversionAvailable(expenseItem)) {
      this.calculateExchangeTax(expenseItem, currentUser);
    } else {
      if (expenseItem.total && _.isPlainObject(expenseItem.tax_code)) {
        expenseItem.tax = adminCommonSvc.roundUpAmount(
          (expenseItem.total * expenseItem.tax_code.rate) / (100 + expenseItem.tax_code.rate),
          null,
          currentUser,
        );
      } else if (
        expenseItem.base_currency_tax &&
        _.isPlainObject(expenseItem.currency_exchange) &&
        !_.isPlainObject(expenseItem.tax_code)
      ) {
        expenseItem.tax = adminCommonSvc.roundUpAmount(
          expenseItem.currency_exchange.rate * expenseItem.base_currency_tax,
          null,
          currentUser,
        );
      }
    }
  };

  updateExpenseAmount = (expenseItem: ExpensesTypes.ExpenseItemFormDataType, currentUser: IUser) => {
    const tax = expenseItem.tax && expenseItem.tax > 0 ? expenseItem.tax : 0;
    const total = expenseItem.total ? expenseItem.total : 0;
    expenseItem.amount = adminCommonSvc.roundUpAmount(total - tax, null, currentUser);
  };

  convertBaseAmounts = (expenseItem: ExpensesTypes.ExpenseItemFormDataType, currentUser: IUser) => {
    this.calculateExchangeTotal(expenseItem, currentUser);
    this.calculateExpenseTax(expenseItem, currentUser);
    this.updateExpenseAmount(expenseItem, currentUser);
  };

  isAllowedConversion = (policy: PolicyTypes.Details): boolean => {
    return Boolean(
      policy.convert_to_base_currency || policy.convert_to_subsidiary_currency || policy.convert_to_employee_currency,
    );
  };

  isConversionAvailable = (expenseItem: ExpensesTypes.ExpenseItemFormDataType): boolean => {
    return (
      _.isObject(expenseItem.policy) &&
      (expenseItem.item_type === "SINGLE_EXPENSE" ||
        (_.isObject(expenseItem.selected_type) && expenseItem.selected_type.value === "SINGLE_EXPENSE")) &&
      (this.isAllowedConversion(expenseItem.policy) ||
        (_.isObject(expenseItem.policy.properties) && this.isAllowedConversion(expenseItem.policy.properties)))
    );
  };

  isConversionActive = (expenseItem: ExpensesTypes.ExpenseItemFormDataType): boolean => {
    return Boolean(
      expenseItem &&
        expenseItem.base_currency_code &&
        expenseItem.currency_code &&
        this.isConversionAvailable(expenseItem) &&
        expenseItem.base_currency_code !== expenseItem.currency_code,
    );
  };

  assignCurrency = (
    expenseItem: ExpensesTypes.ExpenseItemFormDataType,
    companyCurrencies: CommonTypes.ListCurrencyCodeItem,
  ): string | undefined => {
    let globalCurrencyCodes = companyCurrencies;
    // // Obtain a list of all currencies that are available/have policy access(by all/specific/exclude currencies)
    let currencyCodes: CommonTypes.ListCurrencyCodeItem = policyCommonSvc.getPolicyCurrencyCodes(
      expenseItem.policy,
      globalCurrencyCodes,
    );
    // // choose the appropriate currency code based on policy settings
    let assignedCurrency = expenseItem.currency_code; // default currency is assigned on backend. Usually employee currency.
    if (expenseItem?.policy?.convert_to_subsidiary_currency) {
      // convert to subsidiary currency, or if no sub currency is found, use policy currency
      if (expenseItem?.employee?.subsidiary?.currency_code) {
        assignedCurrency = expenseItem?.employee?.subsidiary.currency_code;
      } else {
        assignedCurrency = expenseItem.policy.currency_code;
      }
    } else if (expenseItem?.policy?.convert_to_employee_currency) {
      // convert to employee currency, or if no employee currency is found, use subsidiary currency.
      // if neither are found, use policy currency
      if (expenseItem?.employee?.currency_code) {
        assignedCurrency = expenseItem.employee.currency_code;
      } else if (expenseItem?.employee?.subsidiary?.currency_code) {
        assignedCurrency = expenseItem.employee.subsidiary.currency_code;
      } else {
        assignedCurrency = expenseItem.policy.currency_code;
      }
    } else if (expenseItem?.policy?.convert_to_base_currency) {
      assignedCurrency = expenseItem.policy.currency_code;
    }

    // Filter the list of currencies based on whether they include the user's currency/subsidiary currency.
    let filteredCurrency = currencyCodes.filter((currency) => currency.value === expenseItem.currency_code);
    if (filteredCurrency.length === 0) {
      return expenseItem?.policy?.currency_code;
    } else {
      return assignedCurrency;
    }
  };

  isConversionalExpenseItem = (expenseItem: ExpensesTypes.ExpenseItemFormDataType) => {
    return (
      this.isConversionAvailable(expenseItem) &&
      expenseItem.base_currency_code &&
      expenseItem.base_currency_code !== expenseItem.currency_code
    );
  };

  //if base currency and converted currency is same
  // base currency details should be null
  defaultNilBaseCurrencyDetail = ({
    expenseItem,
    companyCurrencies,
  }: {
    expenseItem: ExpensesTypes.ExpenseItemFormDataType;
    companyCurrencies: CommonTypes.ListCurrencyCodeItem;
  }) => {
    let baseCurrencyCode = isDefined(expenseItem.currency) ? expenseItem.currency.iso_code : null;
    let toCurrency = this.assignCurrency(expenseItem, companyCurrencies);

    if (this.isConversionAvailable(expenseItem) && (!baseCurrencyCode || baseCurrencyCode === toCurrency)) {
      expenseItem.base_currency_code = null;
      expenseItem.base_currency_total = null;
      expenseItem.base_currency_amount = null;
      expenseItem.base_currency_tax = null;
      expenseItem.currency_rate = null;
    }
  };

  getCurrencyRate = async (
    baseCurrencyCode: string,
    index: number,
    expenses: ExpensesTypes.ExpenseItemFormDataType[],
    companyCurrencies: CommonTypes.ListCurrencyCodeItem,
    currentUser: IUser,
  ) => {
    try {
      if (expenses && expenseItemCommonSvc.isConversionAvailable(expenses[index])) {
        if (expenses[index]?.currency) {
          baseCurrencyCode = expenses[index].currency.iso_code;
        }
        const toCurrency = expenseItemCommonSvc.assignCurrency(expenses[index], companyCurrencies);
        if (baseCurrencyCode !== toCurrency) {
          const obj = { to_currency: toCurrency, from_currency: baseCurrencyCode };
          const response = await restApiService.get("company/exchange_rate", obj);
          if (response) {
            expenses[index].currency_exchange = response.data;
            expenses[index].currency_rate = expenses[index].currency_exchange.rate;
            expenseItemCommonSvc.convertBaseAmounts(expenses[index], currentUser);
            expenses[index].base_currency_code = baseCurrencyCode;
            expenses[index].currency_code = toCurrency;
            expenses[index].show_policy_conversion_message = true;
          }
        } else {
          expenses[index].show_policy_conversion_message = false;
          this.defaultNilBaseCurrencyDetail({ expenseItem: expenses[index], companyCurrencies });
        }
      }
      return expenses[index];
    } catch (error) {
      commonService.handleError(error);
    }
  };

  getPo = async (poID: string, index: number, expenses: ExpensesTypes.ExpenseItemFormDataType[]) => {
    try {
      const purchaseOrder = await PurchaseOrdersApis.getPurchaseOrder(poID);
      if (expenses) {
        expenses[index].purchase_order_id = purchaseOrder.id;
        expenses[index].currency_code = purchaseOrder?.currency?.iso_code;
        CreateNotification("Alert", "Currency is inherited from Purchase order", NotificationType.info);
        expenses[index].is_expense_call_from_po_detail_page = true;
      }
      return expenses[index];
    } catch (error) {
      commonService.handleError(error);
    }
  };

  mergePolicyResponse = async (
    policy: ExpenseReportTypes.DetailsPolicy,
    index: number,
    expenses: ExpensesTypes.ExpenseItemFormDataType[],
    appCurrencies: CommonTypes.ListCurrencyCodeItem,
    currentUser: IUser,
    params: any,
  ) => {
    try {
      // reset the policy conversion flag when changing policies.
      expenses[index].show_policy_conversion_message = false;
      if (policy.id && expenses) {
        const result = await PolicyApis.getPolicy({ policyId: policy.id, cache: true });
        const policyResponse = result;
        expenses[index].policy = policyResponse;
        expenses[index].policy_id = policyResponse.id;
        expenses[index].per_diem = null;
        expenses[index].per_diem_id = "";
        const itemTypes = [expenseService.expenseItemTypes[0]];
        expenses[index].hide_item_type = false;
        expenses[index].selected_type = expenseService.expenseItemTypes[0];
        expenses[index].item_type = expenses[index]?.selected_type?.value;
        expenses[index].distance_unit = expenses[index]?.policy?.default_mileage_unit;
        expenses[index].currency_code = expenseItemCommonSvc.assignCurrency(expenses[index], appCurrencies);
        if (expenseItemCommonSvc.isConversionAvailable(expenses[index])) {
          expenses[index].base_currency_code = expenseItemCommonSvc.assignCurrency(expenses[index], appCurrencies);
          if (expenses[index]?.base_currency_code) {
            await this.getCurrencyRate(
              expenses[index].base_currency_code!,
              index,
              expenses,
              appCurrencies,
              currentUser,
            );
          }
        }

        expenseService.expenseItemTypes.forEach((expenseItemType) => {
          if (expenseItemType.value === "MILEAGE" && expenses[index]?.policy?.distance_enabled) {
            itemTypes.push(expenseItemType);
          } else if (expenseItemType.value === "PER_DIEM" && expenses[index]?.policy?.per_diems_enabled) {
            itemTypes.push(expenseItemType);
          }
          expenses[index].itemTypes = itemTypes;
        });
        // expenseItemCommonSvc.refreshExpenseReportPicker($scope.expenses[index]);
        // expenseItemCommonSvc.refreshTaxCodePicker($scope.expenses[index]);
        if (params?.poID) {
          await this.getPo(params.poID, index, expenses);
        }
        return expenses[index];
      }
      return expenses[index];
    } catch (error) {
      commonService.handleError(error);
    }
  };

  // sets expenses[index].employee for the purposes of currency conversion and convenience
  employeePickedCallback = async (
    contactID: IDType,
    index: number,
    expenses: ExpensesTypes.ExpenseItemFormDataType[],
    appCurrencies: CommonTypes.ListCurrencyCodeItem,
    currentUser: IUser,
    params?: any,
  ) => {
    try {
      if (contactID && expenses) {
        const response = await ContactApis.getContact(Number(contactID));
        expenses[index].employee = response;
        expenses[index].employee_id = expenses[index]?.employee?.id;
        if (
          currentUser.company.expense_item?.inherit_employee_department &&
          expenses[index].employee?.department_id &&
          currentUser.company.expense_item.show_department
        ) {
          expenses[index].department_id = expenses[index].employee?.department_id;
        }
        // because updating the employee can result in a blank employee subsidiary,
        // we run mergePolicyResponse and get_currency_rate to ensure the policy's
        // currency conversion features are up to date with the new employee.
        if (expenses[index].policy) {
          //policy, index, expenses, companyCurrencies, currentUser:IUser, params: any
          await this.mergePolicyResponse(expenses[index].policy!, index, expenses, appCurrencies, currentUser, params);
        }

        this.inheritDefaultValueFromEmployee({
          modelData: expenses[index],
          expenseItem: expenses[index],
        });

        return expenses[index];
      }
    } catch (error) {
      commonService.handleError(error);
    }
  };

  // header level or line level fields are inheriting from current expense item employee
  inheritDefaultValueFromEmployee = ({
    modelData,
    expenseItem,
  }: {
    modelData: ExpensesTypes.ExpenseItemFormDataType | ExpensesTypes.ExpenseDebitAccount;
    expenseItem: ExpensesTypes.ExpenseItemFormDataType;
  }) => {
    modelData.department = expenseItem.employee?.department;
    modelData.department_id = expenseItem.employee?.department?.id ?? expenseItem.employee?.department_id;
    modelData.business_unit = expenseItem.employee?.business_unit;
    modelData.business_unit_id = expenseItem?.employee?.business_unit?.id ?? expenseItem.employee?.business_unit_id;
    modelData.location = expenseItem.employee?.location;
    modelData.location_id = expenseItem.employee?.location?.id ?? expenseItem.employee?.location_id;
  };

  isViolationsEmpty = (expense: ExpensesTypes.ExpenseItemFormDataType) => {
    if (_.isPlainObject(expense) && expense.id && expense.policy_violations) {
      return Object.keys(expense.policy_violations).length === 0;
    } else {
      // if policy violations is not present show send true
      return true;
    }
  };

  /*
      this function will return all not destroyed attributes
    */
  getValidAttributes = <T extends { _destroy?: number }>(attributes: T[]): T[] => {
    const validAttributes = Array.isArray(attributes) && attributes.filter((attribute) => !(attribute._destroy === 1));
    if (Array.isArray(validAttributes)) {
      return validAttributes;
    } else {
      return [];
    }
  };

  getSumOfAmount = <T extends { _destroy?: number; amount?: number | string }>(attributes: T[]): number => {
    const validAttribute = this.getValidAttributes(attributes);
    let sumOfAmount = validAttribute.reduce((a: number, c) => {
      return Number(a) + (c?.amount ? Number(c.amount) : 0);
    }, 0);
    return sumOfAmount;
  };

  getSumOfBaseCurrencyAmount = <T extends { _destroy?: number; base_currency_amount?: number | string }>(
    attributes: T[],
  ): number => {
    const validAttribute = this.getValidAttributes(attributes);
    let sumOfBaseCurrencyAmount = validAttribute.reduce((a: number, c) => {
      return Number(a) + (c?.base_currency_amount ? Number(c.base_currency_amount) : 0);
    }, 0);
    return sumOfBaseCurrencyAmount;
  };

  updatePercentageOnExpenseReportDebitAccounts = ({
    expenseReportDebitAccounts,
    expenseItem,
  }: {
    expenseReportDebitAccounts: ExpensesTypes.ExpenseDebitAccounts;
    expenseItem: ExpensesTypes.Details;
  }) => {
    const isConversionActive = this.isConversionActive(expenseItem);
    const updateExpenseReportDebitAccounts = expenseReportDebitAccounts.map((expenseReportDebitAccountItem) => {
      let percentage = 0;

      if (isConversionActive) {
        percentage = commonService.calculatePercentageFromAmount({
          amount: Number(expenseReportDebitAccountItem.base_currency_amount),
          total: Number(expenseItem.base_currency_total),
        });
      } else {
        percentage = commonService.calculatePercentageFromAmount({
          amount: Number(expenseReportDebitAccountItem.amount),
          total: Number(expenseItem.total),
        });
      }

      return {
        ...expenseReportDebitAccountItem,
        percentage,
        percent: percentage,
      };
    });
    return updateExpenseReportDebitAccounts;
  };

  isSplitEnable = (currentUser: IUser) => {
    return currentUser.company.expense_item?.enable_split_expenses;
  };

  destroyAllExpenseReportDebitAccountAttr = (expenseItem: ExpensesTypes.ExpenseItemFormDataType) => {
    expenseItem.expense_report_debit_accounts_attributes = expenseItem.expense_report_debit_accounts_attributes?.map(
      (debitAccount) => {
        return {
          ...debitAccount,
          _destroy: 1,
        };
      },
    );
    return expenseItem;
  };

  isExpenseItemForm = ({ modules, formName }: { modules: string | string[]; formName: string }) => {
    return modules === "ExpenseItem" && formName === ExpenseConstants.EXPENSE_ITEM_FORM;
  };

  getConvertedAmount = ({
    expenseReportDebitAccountsAttributes,
    index,
    amount,
    currentUser,
    total,
    baseCurrencyTotal,
  }: {
    expenseReportDebitAccountsAttributes: ExpensesTypes.ExpenseDebitAccounts;
    index: number;
    amount: number;
    currentUser: IUser;
    total: number;
    baseCurrencyTotal: number;
  }) => {
    // update amount and base_currency_amount in expense_report_debit_accounts_attributes
    if (expenseReportDebitAccountsAttributes[index].amount) {
      expenseReportDebitAccountsAttributes[index].amount = amount;
    }
    if (expenseReportDebitAccountsAttributes[index].amount) {
      expenseReportDebitAccountsAttributes[index].base_currency_amount = amount;
    }

    let sumOfAmount =
      expenseReportDebitAccountsAttributes && expenseItemCommonSvc.getSumOfAmount(expenseReportDebitAccountsAttributes);
    sumOfAmount = adminCommonSvc.roundUpAmount(Number(sumOfAmount), null, currentUser);

    const targetCurrencyRemainingAmount = Number(total) - Number(sumOfAmount);

    let sumOfBaseCurrencyAmount =
      expenseReportDebitAccountsAttributes &&
      expenseItemCommonSvc.getSumOfBaseCurrencyAmount(expenseReportDebitAccountsAttributes);
    sumOfBaseCurrencyAmount = adminCommonSvc.roundUpAmount(Number(sumOfBaseCurrencyAmount), null, currentUser);

    const isBaseCurrencyAmountTotalMatched = Number(sumOfBaseCurrencyAmount) === Number(baseCurrencyTotal);
    const isAmountTotalMatched = Number(sumOfAmount) === Number(total);

    // logic handle variance in conversion
    if (
      isBaseCurrencyAmountTotalMatched &&
      !isAmountTotalMatched &&
      targetCurrencyRemainingAmount > 0 &&
      targetCurrencyRemainingAmount < 1
    ) {
      if (expenseReportDebitAccountsAttributes[index].amount) {
        expenseReportDebitAccountsAttributes[index].amount =
          Number(expenseReportDebitAccountsAttributes[index].amount) - targetCurrencyRemainingAmount;
      }
    }
    return expenseReportDebitAccountsAttributes[index].amount;
  };

  inheritAllocationFromExpenseItemHeader = ({
    expenseItem,
  }: {
    expenseItem: ExpensesTypes.ExpenseItemFormDataType;
  }) => {
    const expenseDebitAccount: ExpensesTypes.ExpenseDebitAccount = {};
    // --- TODO: remove once backend fix
    let account = null;
    if (Array.isArray(expenseItem?.category?.accounts)) {
      account = expenseItem?.category?.accounts.find((acc, index) => index === 0);
      //  disable type script next line
      //  @ts-ignore
    } else if (_.isPlainObject(expenseItem?.category?.account as any)) {
      //  @ts-ignore
      account = expenseItem?.category?.account;
    }
    const accountID = account ? account.id : null;
    expenseDebitAccount.account = account;
    expenseDebitAccount.account_id = expenseItem?.account_id || accountID;
    // ---

    expenseDebitAccount.amount = Number(expenseItem.total || 0);
    expenseDebitAccount.base_currency_amount = Number(expenseItem.base_currency_total || 0);
    expenseDebitAccount.percentage = 100;
    expenseDebitAccount.percent = 100;

    expenseDebitAccount.policy_id = expenseItem?.policy_id;
    expenseDebitAccount.policy_id = expenseItem?.policy_id;
    expenseDebitAccount.policy = expenseItem?.policy;
    expenseDebitAccount.subsidiary = expenseItem?.subsidiary;
    expenseDebitAccount.subsidiary_id = expenseItem?.subsidiary_id;
    expenseDebitAccount.category_id = expenseItem?.category_id;
    expenseDebitAccount.category = expenseItem?.category;
    expenseDebitAccount.category_name = expenseItem?.category_name;
    expenseDebitAccount.project_id = expenseItem?.project_id;
    expenseDebitAccount.project = expenseItem?.project;
    expenseDebitAccount.project_name = expenseItem?.project_name;
    expenseDebitAccount.department_id = expenseItem?.department_id;
    expenseDebitAccount.department = expenseItem?.department;
    expenseDebitAccount.department_name = expenseItem?.department_name;
    expenseDebitAccount.business_unit_id = expenseItem?.business_unit_id;
    expenseDebitAccount.business_unit_name = expenseItem?.business_unit_name;
    expenseDebitAccount.business_unit = expenseItem?.business_unit;
    expenseDebitAccount.location_id = expenseItem?.location_id;
    expenseDebitAccount.location = expenseItem?.location;
    expenseDebitAccount.location_name = expenseItem?.location_name;
    expenseDebitAccount.custom_fields = expenseItem?.custom_fields;
    expenseDebitAccount.metadata_template_id = expenseItem?.metadata_template_id;
    expenseDebitAccount.metadata = expenseItem?.metadata;
    expenseDebitAccount.metadata_links_attributes =
      Array.isArray(expenseItem?.metadata_links_attributes) &&
      expenseItem?.metadata_links_attributes.map((metadataLink) => {
        return { ...metadataLink, id: null };
      });
    expenseDebitAccount.metadata_links = expenseDebitAccount?.metadata_links_attributes;
    expenseDebitAccount.metadataForm = expenseItem?.metadataForm;

    return expenseDebitAccount;
  };

  copyFirstExpenseReportDebitAccountsToHeader = ({
    expenseItem,
  }: {
    expenseItem: ExpensesTypes.ExpenseItemFormDataType;
  }) => {
    if (expenseItem?.expense_report_debit_accounts?.length === 1) {
      const debitLineLikeObject = this.extractExpenseReportAccountLikeObject({
        modelData: expenseItem?.expense_report_debit_accounts[0],
      });

      // remove not required fields form header line
      delete debitLineLikeObject.base_currency_amount;
      delete debitLineLikeObject.base_currency_total;
      delete debitLineLikeObject.amount;
      delete debitLineLikeObject.total;
      delete debitLineLikeObject.percent;
      delete debitLineLikeObject.subsidiary;
      delete debitLineLikeObject.subsidiary_id;
      delete debitLineLikeObject.policy;
      delete debitLineLikeObject.policy_id;

      // no need set metadata_links_attributes and metadataForm as selector.tsx will do it
      delete debitLineLikeObject.metadataForm;
      delete debitLineLikeObject.metadata_links_attributes;

      if (debitLineLikeObject) {
        expenseItem = { ...expenseItem, ...debitLineLikeObject };
      }
    }

    return expenseItem;
  };

  // from modelData this fn extract expense_report_debit_accounts like(not same) object
  // return debit line like object
  extractExpenseReportAccountLikeObject = <T>({
    modelData,
  }: {
    modelData: T & ExpensesTypes.ExpenseReportDebitAccountLikeObject;
  }) => {
    const debitLineLikeObject: ExpensesTypes.ExpenseReportDebitAccountLikeObject = {
      total: Number(modelData.total ?? 0),
      amount: Number(modelData.amount),
      base_currency_amount: Number(modelData.base_currency_amount ?? 0),
      base_currency_total: Number(modelData.base_currency_total ?? 0),
      policy_id: modelData?.policy_id,
      policy: modelData?.policy,
      subsidiary: modelData?.subsidiary,
      subsidiary_id: modelData?.subsidiary_id,
      account_id: modelData.account_id,
      account: modelData.account,
      category_id: modelData.category_id,
      category: modelData.category,
      category_name: modelData.category_name,
      project_id: modelData.project_id,
      project: modelData.project,
      project_name: modelData.project_name,
      department_id: modelData.department_id,
      department: modelData.department,
      department_name: modelData.department_name,
      business_unit_id: modelData.business_unit_id,
      business_unit: modelData.business_unit,
      business_unit_name: modelData.business_unit_name,
      location_id: modelData.location_id,
      location: modelData.location,
      location_name: modelData.location_name,
      custom_fields: modelData.custom_fields,
      metadata_template_id: modelData.metadata_template_id,
      metadata_links_attributes: modelData.metadata_links_attributes,
      metadata_links: modelData.metadata_links,
      metadata: modelData.metadata,
      metadataForm: modelData.metadataForm,
    };

    return debitLineLikeObject;
  };

  moveExpenseItemHeaderToFirstExpenseReportDebitAccount = ({
    expenseItem,
    submitting,
  }: {
    expenseItem: ExpensesTypes.ExpenseItemFormDataType;
    submitting?: boolean;
  }) => {
    if (expenseItem?.expense_report_debit_accounts?.length === 1) {
      expenseItem.expense_report_debit_accounts_attributes = expenseItem?.expense_report_debit_accounts;

      const debitLineLikeObject = this.extractExpenseReportAccountLikeObject({ modelData: expenseItem });
      // delete the attributes which are not required
      delete debitLineLikeObject.base_currency_amount;
      delete debitLineLikeObject.amount;

      if (expenseItem?.expense_report_debit_accounts_attributes[0]) {
        expenseItem.expense_report_debit_accounts_attributes[0] = {
          ...expenseItem?.expense_report_debit_accounts_attributes[0],
          ...debitLineLikeObject,
        };

        expenseItem.expense_report_debit_accounts_attributes[0].amount = Number(debitLineLikeObject.total);
        expenseItem.expense_report_debit_accounts_attributes[0].base_currency_amount = Number(
          debitLineLikeObject.base_currency_total,
        );

        // remove values from expense item
        if (submitting) {
          this.removeCopyFieldsValueFromHeader({ expenseItem });
        }
      }
    }
    return expenseItem;
  };

  // Making sure not passing any copied value from expense report debit account on header,
  // Using undefine will no passed in payload
  removeCopyFieldsValueFromHeader = ({ expenseItem }: { expenseItem: ExpensesTypes.ExpenseItemFormDataType }) => {
    const fieldsToClear = [
      "account_id",
      "account",
      "category_id",
      "category",
      "category_name",
      "project_id",
      "project",
      "project_name",
      "department_id",
      "department",
      "department_name",
      "business_unit_id",
      "business_unit",
      "business_unit_name",
      "location_id",
      "location",
      "location_name",
      "custom_fields",
      "metadata_template_id",
      "metadata_links_attributes",
      "metadata_links",
      "metadataForm",
    ];

    fieldsToClear.forEach((field) => {
      expenseItem[field] = undefined;
    });
    return expenseItem;
  };

  prepareExpenseReportDebitAccountsForSplitCodingForm = ({
    modalDebitAccountsAttributes,
    expenseItem,
  }: {
    modalDebitAccountsAttributes: ExpensesTypes.ExpenseDebitAccounts;
    expenseItem: ExpensesTypes.ExpenseItemFormDataType;
  }) => {
    const isCurrencyConversionActive = this.isConversionActive(expenseItem);
    let updatedModalDebitAccountsAttributes: ExpensesTypes.ExpenseDebitAccounts;
    updatedModalDebitAccountsAttributes = modalDebitAccountsAttributes?.map((debitAccountItem) => {
      let splitAmount = isCurrencyConversionActive ? debitAccountItem.base_currency_amount : debitAccountItem.amount;
      const applicableTotal = isCurrencyConversionActive ? expenseItem.base_currency_total : expenseItem.total;
      const percent = isDefined(debitAccountItem.percentage)
        ? debitAccountItem.percentage
        : commonService.calculatePercentageFromAmount({ amount: Number(splitAmount), total: Number(applicableTotal) });

      return {
        ...debitAccountItem,
        metadata_links: debitAccountItem.metadata_links_attributes
          ? debitAccountItem.metadata_links_attributes
          : debitAccountItem.metadata_links,
        metadata_links_attributes: debitAccountItem.metadata_links_attributes
          ? debitAccountItem.metadata_links_attributes
          : debitAccountItem.metadata_links,
        subsidiary_id: expenseItem.subsidiary_id,
        subsidiary: expenseItem.subsidiary,
        policy_id: expenseItem.policy_id,
        policy: expenseItem.policy,
        percentage: percent,
        percent,
        employee: expenseItem?.employee,
      };
    });

    if (
      Array.isArray(updatedModalDebitAccountsAttributes) &&
      updatedModalDebitAccountsAttributes.length &&
      updatedModalDebitAccountsAttributes?.length > 0 &&
      updatedModalDebitAccountsAttributes[0]?.id
    ) {
      // sort in ascending order
      updatedModalDebitAccountsAttributes = _.sortBy(updatedModalDebitAccountsAttributes, ["id"], ["asc"]);
    }
    return updatedModalDebitAccountsAttributes;
  };

  // this function check if the expense item received form backend is using split coding or not
  // return true if yes or false if no
  isSplitExpenseItem = ({
    expenseItem,
    currentUser,
  }: {
    expenseItem: ExpensesTypes.ExpenseItemFormDataType;
    currentUser: IUser;
  }) => {
    return (
      expenseItem?.item_type === "SINGLE_EXPENSE" &&
      Array.isArray(expenseItem.expense_report_debit_accounts) &&
      expenseItem.expense_report_debit_accounts.length > 1 &&
      this.isSplitEnable(currentUser)
    );
  };

  isOnlyOneSplitExpenseItem = ({
    expenseItemType,
    expenseReportDebitAccounts,
  }: {
    expenseItemType: string | undefined;
    expenseReportDebitAccounts: ExpensesTypes.ExpenseDebitAccounts | undefined | null;
  }) => {
    // find valid expense report debit account attribute
    const validExpenseReportDebitAccountAttributes = expenseReportDebitAccounts
      ? expenseItemCommonSvc.getValidAttributes(expenseReportDebitAccounts)
      : [];

    return (
      expenseItemType === "SINGLE_EXPENSE" &&
      Array.isArray(validExpenseReportDebitAccountAttributes) &&
      validExpenseReportDebitAccountAttributes.length === 1 &&
      validExpenseReportDebitAccountAttributes[0].id
    );
  };

  wasOnlyOneSplitExpenseItem = ({
    expenseItem,
  }: {
    expenseItem: ExpensesTypes.ExpenseItemFormDataType | undefined;
  }) => {
    return (
      expenseItem?.item_type === "SINGLE_EXPENSE" &&
      Array.isArray(expenseItem.expense_report_debit_accounts) &&
      expenseItem.expense_report_debit_accounts.length === 1 &&
      expenseItem.expense_report_debit_accounts[0].id
    );
  };

  isPurchaseLinked = ({ expenseItem }: { expenseItem: ExpensesTypes.ExpenseItemFormDataType }) => {
    return Boolean(expenseItem.purchase?.id);
  };

  // Call the API to attach receipts to an expense item: Append all receipts
  // stored in the expenseItem (when editing an expense item),
  // and any new ones that may be passed in (when adding an expense item)
  attachReceipts = async ({
    expenseItem,
    receipts,
  }: {
    expenseItem: ExpensesTypes.ExpenseItemFormDataType;
    receipts?: Receipt[];
  }) => {
    // Using sets to avoid any duplication of ID's.
    let selectedReceiptIds: Set<number> = new Set();

    if (expenseItem?.id) {
      if (Array.isArray(receipts) && receipts.length > 0) {
        receipts.forEach((receipt) => selectedReceiptIds.add(Number(receipt.id)));
      }

      if (Array.isArray(expenseItem.receipts)) {
        expenseItem.receipts
          .filter((receipt) => receipt.selected)
          .forEach((receipt) => selectedReceiptIds.add(Number(receipt.id)));
      }

      // Store the count of newly attached receipts for user notification on success; the next step "adds"
      // already attached receipts so we need to know how many are going to be newly added.
      const newReceiptCount = selectedReceiptIds.size;

      // Add any existing linked documents to the Set for the PATCH that is about to occur, otherwise they will be lost.
      if (expenseItem.assets && Array.isArray(expenseItem.assets)) {
        expenseItem.assets.forEach((asset) => {
          if (asset.linked_document_id) {
            selectedReceiptIds.add(asset.linked_document_id);
          }
        });
      }

      await ExpensesApis.addExpenseItemDocuments({
        id: expenseItem.id.toString(),
        document_ids: Array.from(selectedReceiptIds),
      });

      return newReceiptCount;
    }
  };

  // for support of mobile map
  waypointsToGeolocation = (waypoints: ExpensesTypes.TMapRouteWaypoints[] | undefined) => {
    if (!waypoints) return [];
    const geolocations: any[] = [];

    waypoints.forEach((waypoint, index) => {
      if (index === waypoints.length - 1) return;
      const [source, destination] = waypoints.slice(index, index + 2);
      if (index === 0) {
        const geolocation = {
          source: {
            type: "source",
            number: 0,
            latitude: source.lat,
            longitude: source.lng,
            place_id: source.place_id,
            formatted_address: source.address,
          },
          destination: {
            type: "destination",
            number: 0,
            latitude: destination.lat,
            longitude: destination.lng,
            place_id: destination.place_id,
            formatted_address: destination.address,
          },
        };
        geolocations.push(geolocation);
      } else {
        const geolocation = {
          source: {
            type: "destination",
            number: index - 1,
            latitude: source.lat,
            longitude: source.lng,
            place_id: source.place_id,
            formatted_address: source.address,
          },
          destination: {
            type: "destination",
            number: index,
            latitude: destination.lat,
            longitude: destination.lng,
            place_id: destination.place_id,
            formatted_address: destination.address,
          },
        };
        geolocations.push(geolocation);
      }
    });

    return geolocations;
  };
}

const expenseItemCommonSvc = new ExpenseItemCommonSvc();
export default expenseItemCommonSvc;
