import { InputField } from "components/forms/hookFormFields";
import React, { useEffect, useMemo } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { useTypedSelector } from "reducers";
import adminCommonSvc from "services/admin/commonSvc";
import useCustomLabel from "services/admin/customLabels/useCustomLabel";
import PoCommonSvc from "services/admin/purchaseOrders/poCommonSvc";
import { IUser } from "services/common/user/userTypes";

const RequestPoAmount = () => {
  const currentUser: IUser = useTypedSelector((state) => state.user);
  const poCommonSvc = PoCommonSvc.getInstance(currentUser);
  const { setValue } = useFormContext<any>();
  const { getCustomLabel } = useCustomLabel();

  const poItems = useWatch({ name: `purchase_order.po_items` });
  const poExpenses = useWatch({ name: `purchase_order.invoice_debit_accounts` });

  const itemsTotal = useMemo(() => {
    if (!poCommonSvc.isHideExpenseAndItemLines()) {
      let itemsTotal = adminCommonSvc.roundUpAmount(
        Number(poCommonSvc.calculateItemsTotal(poItems)),
        null,
        currentUser,
      );

      let expensesTotal = adminCommonSvc.roundUpAmount(
        Number(poCommonSvc.calculateExpensesTotal(poExpenses)),
        null,
        currentUser,
      );

      return Number(itemsTotal + expensesTotal);
    }
    return 0;
  }, [currentUser, poCommonSvc, poExpenses, poItems]);

  useEffect(() => {
    setValue(`purchase_order.amount`, itemsTotal);
  }, [itemsTotal, poExpenses, setValue]);

  return (
    <InputField
      id="po_request_amount"
      name="purchase_order.amount"
      label={getCustomLabel("admin.pages.purchaseOrder.amount")}
      type="number"
      disabled={!currentUser.company.po_request?.hide_expense_and_item_lines}
      required
    />
  );
};

export default RequestPoAmount;
