import { any } from "prop-types";
import React from "react";
import { Card } from "react-bootstrap";
import "./panel.style.css";

/**
 * @deprecated This Panel component is deprecated and should not be used.
 * If you need a Panel for admin or vendor portal, go for components/common/panel/panel.tsx
 */
const Panel = (props) => {
  var panelContent = { heading: "", bodyContent: any, iconClass: any };
  if ("panelContent" in props) {
    panelContent.heading = props.panelContent.heading;
    panelContent.bodyContent = props.panelContent.bodyContent;
    panelContent.iconClass = props.panelContent.iconClass;
  }
  return (
    <Card className={"h-100 panelContainer " + props.cardClasses} style={props.cardStyle}>
      {panelContent.heading ? (
        <Card.Header className={"cardHeader"} style={props.cardHeaderStyle}>
          <h3 style={props.headingStyle} className={"panel-heading align-self-center mb-0"}>
            {props.panelContent.iconClass && (
              <i className={"icon " + props.panelContent.iconClass} style={props.iconStyle}></i>
            )}
            {props.panelContent.heading} <span style={props.subHeadingStyle}>{props.panelContent.subHeading}</span>
          </h3>
          {props.icon ? (
            <div
              className={"ml-auto"}
              onClick={() => {
                if (props.closeModel) {
                  props.closeModel();
                }
              }}
            >
              {props.icon}
            </div>
          ) : null}
          {props.headerComponent ? props.headerComponent : null}
        </Card.Header>
      ) : null}
      <Card.Body className={"h-100"} style={props.cardBodyStyle}>
        {/*<Card.Title>Special title treatment</Card.Title>*/}
        {/*<Card.Text> */}
        {/* {panelContent.bodyContent} */}
        {props.children}
        {/*</Card.Text>*/}
        {/*<Button variant="primary">Go somewhere</Button>*/}
      </Card.Body>
    </Card>
  );
};
export default Panel;
