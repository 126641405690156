import { AxiosResponse } from "axios";
import { TActivity } from "services/common/types/chat.types";
import { restApiService } from "../../../providers/restApi";
import { IDType, getListOptionsType } from "../../common/types/common.type";
import { InvoiceType } from "./invoiceType";

class InvoiceApis {
  static async getNewInvoice() {
    const response: AxiosResponse<InvoiceType.InvoiceDetailType> = await restApiService.get("invoices/new");
    return response.data;
  }

  static async getInvoiceList({ filter, cache }: getListOptionsType = {}) {
    const response: AxiosResponse<{ meta: InvoiceType.ListMetaDataType; data: InvoiceType.ListType[] }> =
      await restApiService.get("invoices", filter, null, true, null, cache);
    return response.data;
  }

  static async getInvoice(id: IDType) {
    const response: AxiosResponse<InvoiceType.InvoiceDetailType> = await restApiService.get("invoices/" + id);
    return response.data;
  }

  static async deleteInvoice(id: number) {
    const response: AxiosResponse<InvoiceType.InvoiceDetailType> = await restApiService.delete("invoices/" + id);
    return response.data;
  }

  static async addInvoice(invoicePayload: InvoiceType.AddPayloadType) {
    const response: AxiosResponse<InvoiceType.InvoiceDetailType> = await restApiService.post(
      "invoices",
      null,
      invoicePayload,
    );
    return response.data;
  }

  static async getInvoiceItems(id: string) {
    const response: AxiosResponse<InvoiceType.InvoiceDetailType> = await restApiService.get(
      "invoices/" + id + "/invoice_items",
    );
    return response.data;
  }

  static async uploadDocuments(documentPayload: any, invoiceId: IDType) {
    const response: AxiosResponse<InvoiceType.InvoiceDetailType> = await restApiService.patch(
      "invoices/" + invoiceId,
      null,
      documentPayload,
    );
    return response.data;
  }

  static async editInvoice(id: string, invoicePayload: InvoiceType.EditPayloadType) {
    const response: AxiosResponse<InvoiceType.InvoiceDetailType> = await restApiService.patch(
      "invoices/" + id,
      null,
      invoicePayload,
    );
    return response.data;
  }

  static async getNextInvoiceIdAsync(id: string, params: string) {
    const response: AxiosResponse = await restApiService.get("invoices/" + id + "/next?" + params);
    return response.data.invoice.id;
  }

  static async getPrevInvoiceIdAsync(id: string, params: string) {
    const response: AxiosResponse = await restApiService.get("invoices/" + id + "/previous?" + params);
    return response.data.invoice.id;
  }

  static async attachFilesToInvoice(invoiceId: string, files: File[]) {
    if (files.length === 0) {
      return;
    }

    const formData = new FormData();

    files.forEach((file, index) => {
      formData.append(`invoice[assets_attributes[${index}]asset_file]`, file);
      formData.append(`invoice[assets_attributes[${index}]file_name]`, file.name);
      formData.append(`invoice[avoid_default_account_entries]`, "true");
    });

    const response: AxiosResponse<InvoiceType.InvoiceDetailType> = await restApiService.patch(
      "invoices/" + invoiceId,
      null,
      formData,
    );
    return response.data;
  }

  static async getActivitiesAsync(id: number, params: any, signal: AbortSignal | undefined) {
    const response: AxiosResponse<TActivity[]> = await restApiService.get(
      "invoices/" + id + "/activities",
      params,
      null,
      true,
      null,
      false,
      signal,
    );
    return response.data;
  }

  static async postActivitiesAsync(payload: TActivity) {
    const response: AxiosResponse<TActivity> = await restApiService.post("activities", null, { activity: payload });
    return response.data;
  }
}

export default InvoiceApis;
