import { AxiosResponse } from "axios";
import SubsidiaryPicker from "components/admin/pickers/reactHookFormPickers/subsidiaryPicker";
import VendorPicker from "components/admin/pickers/reactHookFormPickers/vendorPicker";
import { VendorPickerType } from "components/admin/pickers/reactHookFormPickers/vendorPicker/vendorPickerTypes";
import { InputField, Select } from "components/forms/hookFormFields";
import _ from "lodash";
import { restApiService } from "providers/restApi";
import React, { useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { useTypedSelector } from "reducers";
import useCustomLabel from "services/admin/customLabels/useCustomLabel";
import { InvoiceType } from "services/admin/invoices/invoiceType";
import { useInvoiceCommonSvc } from "services/admin/invoices/useInvoiceCommonSvc";
import { IUser } from "services/common/user/userTypes";
import { useInvoiceDetailsContext } from "./invoiceDetailsContext";
import styles from "./invoiceHeaderLineSection.module.css";
import InvoiceDetailsAmount from "./subSections/InvoiceDetailsAmount";
import { useUiStateContext } from "./uiStateContext";

const InvoiceMainHeader = () => {
  const { getValues, setValue } = useFormContext<InvoiceType.InvoiceDetailType>();
  const { invoiceDetails, isFetchingInvoiceDetails } = useInvoiceDetailsContext();
  const { isOperationRunning } = useUiStateContext();
  const invoiceCommonHookSvc = useInvoiceCommonSvc();
  const { getCustomLabel } = useCustomLabel();
  const currentUser: IUser = useTypedSelector((state) => state.user);

  const shouldBlur = isOperationRunning || isFetchingInvoiceDetails || !invoiceDetails;
  const invoiceFormClass = shouldBlur ? styles.formBlur : "";

  const invoice = getValues();

  const statusOptions = useMemo(
    () => [
      { label: "New", value: "NEW" },
      { label: "Pending", value: "PENDING" },
      { label: "Open", value: "OPEN" },
    ],
    [],
  );

  const handleVendorChange = async (selectedVendor: VendorPickerType.TVendorOptions) => {
    try {
      const response: AxiosResponse = await restApiService.get("vendors/" + selectedVendor.id);
      const responseId = response?.data?.id;
      if (responseId) {
        const vendor = response.data;

        if (vendor?.term) {
          setValue("term", vendor.term);
          setValue("term_id", vendor.term?.id);
        }

        if (vendor?.vendor_id) {
          let updatedVendor = {
            ...selectedVendor,
            vendor_id: vendor.vendor_id,
            po_not_required: vendor.po_not_required,
            is_1099_eligible: vendor.is_1099_eligible,
            has_vendor_locations: vendor.has_vendor_locations,
          };
          setValue("vendor", updatedVendor);
          setValue("vendorVendorId", updatedVendor.vendor_id);
          setValue("vendor.vendor_id", updatedVendor.vendor_id);
        }

        const vendorsSubsidiary = _.find(vendor.subsidiaries, { id: invoice.subsidiary_id });
        if (!vendorsSubsidiary) {
          setValue("subsidiary", vendor.subsidiary);
          setValue("subsidiary_id", vendor.subsidiary?.id);
        }

        invoiceCommonHookSvc.updateDueDate();
      }
    } catch (error) {
      console.log("error: ", error);
    }
  };

  return (
    <div className={`${invoiceFormClass} ${styles.gridContainer}`}>
      {currentUser.company.has_subsidiaries && (
        <div className={styles.gridItem}>
          <SubsidiaryPicker name="subsidiary_id" required label={"admin.pages.invoice.subsidiary"} />
        </div>
      )}
      <div className={styles.gridItem}>
        <VendorPicker
          name="vendor"
          required
          label={getCustomLabel("admin.pages.invoice.vendor")}
          excludeStatuses="INACTIVE"
          callBack={(selectedVendor) => handleVendorChange(selectedVendor)}
        />
      </div>
      <div className={styles.gridItem}>
        <Select
          id="invoice_status"
          name="status"
          label={getCustomLabel("admin.pages.invoice.status")}
          options={statusOptions}
        />
      </div>
      <div className={styles.gridItem}>
        <InputField id="invoice_number" name="number" label={getCustomLabel("admin.pages.invoice.number")} required />
      </div>
      <div className={styles.gridItem}>
        <InvoiceDetailsAmount />
      </div>
    </div>
  );
};

export default InvoiceMainHeader;
