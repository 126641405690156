import { useQuery } from "@tanstack/react-query";
import AttachmentCarousel from "components/common/attachmentCarousel/attachmentCarousel";
import { PolicyViolationSummary } from "components/common/policyViolationSummary/policyViolationSummary";
import useConfirmModal from "components/modals/confirmModal/useConfirmModalHook";
import CustomModal from "components/modals/customModal";
import _ from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Button, Col, Container, Row, Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import { useTypedSelector } from "reducers";
import { selectAppCurrecyCode } from "reducers/common/appCommonStatesSlice";
import { selectCurrentUser } from "reducers/userReducers";
import { arrayPush, change } from "redux-form";
import adminCommonSvc from "services/admin/commonSvc";
import expenseItemCommonSvc from "services/admin/expenses/expenseItems/expenseItemCommonSvc";
import ExpensesApis, { useGetCategories } from "services/admin/expenses/expensesApis";
import { ExpensesTypes } from "services/admin/expenses/expensesType";
import expenseService from "services/admin/expenses/expenseSvc";
import { Can } from "services/authorization/authorization";
import commonService from "services/common/commonSvc";
import { IAttachment } from "services/common/types/common.type";
import { IUser } from "services/common/user/userTypes";
import ExpenseAllocations from "./expenseAllocations";
import style from "./expenseItem.module.css";
import { ExpenseItemFormContext } from "./expenseItemFormSectionContext";
import ExpenseReceiptList from "./expenseReceiptList";
import { formExpenseItemFormData } from "./formExpenseItem";
import MileageForm from "./mileageForm";
import PerDiemForm from "./perDiemForm";
import PolicyAndItemTypeSelection from "./policyAndItemTypeSelection";
import { ReceiptsProvider } from "./receiptListContext";
import SingleExpenseForm from "./singleExpenseForm";

type EditExpenseItemFormSectionType = {
  form: string;
  sectionPrefix?: string;
  index: number;
};

const EditExpenseItemFormSection = ({ form, sectionPrefix, index }: EditExpenseItemFormSectionType) => {
  const { t } = useTranslation();
  const translate = (key: string) => t(`admin.pages.expenseItemDetails.${key}`);

  const { id: expenseItemId } = useParams<{ id: string }>();
  const { createConfirmModal } = useConfirmModal();

  const dispatch = useDispatch();
  const currentUser: IUser = useTypedSelector(selectCurrentUser);
  const appCurrencies = useTypedSelector(selectAppCurrecyCode);
  const expenseFormData = useTypedSelector(formExpenseItemFormData(form));

  const [showReceiptList, setShowReceiptList] = useState(false);

  const currentExpenseItemFormLocationString = useMemo(() => sectionPrefix ?? "", [sectionPrefix]); //<- referring to current expenseItem
  const expenses = useMemo(() => expenseFormData?.expenses, [expenseFormData]);
  const expenseItem = useMemo(() => expenses?.[index], [expenses, index]);

  function onDeleteConfirm(selectedAttachment: IAttachment): void {
    const updatedAssetsAttributes =
      Array.isArray(expenseItem?.assets_attributes) &&
      expenseItem?.assets_attributes?.map((assetsAttributes) => {
        if (assetsAttributes.id && assetsAttributes.id === selectedAttachment.id) {
          // if files are already uploaded
          assetsAttributes._destroy = 1;
        } else if (assetsAttributes.url && selectedAttachment.url && assetsAttributes.url === selectedAttachment.url) {
          // if files are on local
          assetsAttributes._destroy = 1;
        }
        return assetsAttributes;
      });

    if (updatedAssetsAttributes) {
      dispatch(change(form, currentExpenseItemFormLocationString + ".assets_attributes", updatedAssetsAttributes));
    }
  }

  function onAttachmentUpload(files: File[]): void {
    if (Array.isArray(files) && files.length > 0) {
      files.forEach((file) => {
        const assetFile = {
          asset_file: file,
          asset_file_file_name: file.name,
          url: URL.createObjectURL(file),
          asset_expiring_url: URL.createObjectURL(file),
        };
        dispatch(arrayPush(form, currentExpenseItemFormLocationString + ".assets_attributes", assetFile));
        return assetFile;
      });
    }
  }

  const { data: categories } = useGetCategories();

  const { data: expenseData, refetch } = useQuery({
    queryKey: ["expense", expenseItemId],
    queryFn: ({ signal }) => ExpensesApis.getExpense(expenseItemId, signal),
    enabled: !!expenseItemId,
    cacheTime: 0,
  });

  const checkIfCategoryExist = useCallback(() => {
    if (
      expenseItem?.category_id &&
      categories &&
      !expenseItemCommonSvc.isCategoryExist(expenseItem.category_id, categories)
    ) {
      dispatch(change(form, currentExpenseItemFormLocationString + ".category_id", null));
    }
  }, [categories, currentExpenseItemFormLocationString, dispatch, expenseItem?.category_id, form]);

  // NOTE: don't trigger this function more than once,
  // as metadata is building internal state on metadata_template_id change.
  // Rendering this function will remove internal state,
  // and as metadata_template_id is not changed, it will not trigger to build a new state again.
  const getExpenseItem = useCallback(
    async (expItem) => {
      try {
        if (expItem) {
          if (expItem.total) {
            expItem.total = adminCommonSvc.roundUpAmount(expItem.total, null, currentUser);
          }

          //instead of using show file function assigning assets to assets_attributes
          expItem.assets_attributes = expItem.assets;
          //we must loop over our options for expense types to find the matching one
          expenseService.expenseItemTypes.forEach((value) => {
            if (expItem?.item_type === value.value) {
              expItem.itemTypes = [value];
              expItem.selected_type = value;
            }
          });

          if (expItem.base_currency_code) {
            expItem = await expenseItemCommonSvc.getCurrencyRate(
              expItem.base_currency_code,
              index,
              [expItem],
              appCurrencies,
              currentUser,
            );
          }

          if (expItem.item_type === "MILEAGE" && expItem?.currency_code) {
            expItem = expenseItemCommonSvc.setDefaultDistanceUnit({
              currencyCode: expItem?.currency_code,
              index,
              expenses: [expItem],
              currentUser,
              isEditLoading: true,
            });
          }

          // check if using split expense when split is more than one
          if (
            expenseItemCommonSvc.isSplitExpenseItem({ expenseItem: expItem, currentUser }) &&
            Array.isArray(expItem.expense_report_debit_accounts)
          ) {
            expItem.expense_report_debit_accounts_attributes =
              expenseItemCommonSvc.updatePercentageOnExpenseReportDebitAccounts({
                expenseReportDebitAccounts: expItem.expense_report_debit_accounts,
                expenseItem: expItem,
              });
            expItem.using_split = true;
          } else if (
            expenseItemCommonSvc.isOnlyOneSplitExpenseItem({
              expenseItemType: expItem?.item_type,
              expenseReportDebitAccounts: expItem?.expense_report_debit_accounts,
            })
          ) {
            // bring expense_report_debit_accounts[0] line data to header
            // at time of submit put back to expense_report_debit_accounts_attributes
            expItem = expenseItemCommonSvc.copyFirstExpenseReportDebitAccountsToHeader({ expenseItem: expItem });
          }

          if (currentUser.company.expense_item?.enable_split_expenses) {
            // it will valid by default will update to false as user action on split and total
            expItem.is_split_100_percent = true;
          }

          expItem.metadata_links_attributes = expItem.metadata_links;

          if (_.isPlainObject(expItem)) {
            dispatch(change(form, currentExpenseItemFormLocationString, { ...expItem, is_loaded: true }));
          }
        }
      } catch (error) {
        console.log(error);
        commonService.handleError(error);
      }
    },
    [currentExpenseItemFormLocationString, currentUser, dispatch, form, index],
  );

  const onDeleteAttachment = (attachment: IAttachment) => {
    createConfirmModal({
      title: translate("notification.deleteConfirmationDialog.title"),
      body: translate("notification.deleteConfirmationDialog.body"),
      confirmButtonLabel: translate("notification.deleteConfirmationDialog.deleteButton"),
      callBackData: null,
      cancelCallBack: null,
      saveCallBack: () => onDeleteConfirm(attachment),
    });
  };

  const showReceiptListDialog = () => {
    setShowReceiptList(true);
  };

  const receiptAttachedCallback = () => {
    // expenseItem.receipts may have been updated by the dialog; save it's state to the store.
    dispatch(change(form, currentExpenseItemFormLocationString, { ...expenseItem }));
  };

  useEffect(() => {
    getExpenseItem(expenseData);
  }, [getExpenseItem, expenseData]);

  useEffect(() => {
    checkIfCategoryExist();
  }, [categories, checkIfCategoryExist]);

  const expenseItemFormContextValue = useMemo(
    () => ({ index, formName: form, sectionPrefix: currentExpenseItemFormLocationString }),
    [currentExpenseItemFormLocationString, form, index],
  );

  if (!expenseItem?.is_loaded) {
    return (
      <Container fluid>
        <Row>
          <Col>
            <div className="w-100 h-100 d-flex justify-content-center align-items-center">
              <Spinner animation="border" variant="info" />
            </div>
          </Col>
        </Row>
      </Container>
    );
  }

  return (
    <ReceiptsProvider>
      <ExpenseItemFormContext.Provider value={expenseItemFormContextValue}>
        {expenseItem && _.isArray(expenseItem.violations_messages) && expenseItem.violations_messages.length > 0 && (
          <Row className="mb-3">
            <Col>
              <PolicyViolationSummary expenseDetails={expenseItem as ExpensesTypes.Details}></PolicyViolationSummary>
            </Col>
          </Row>
        )}
        <Row>
          <Col md="12">
            <Row>
              <Can I={"editattachment"} a={"ExpenseItems"}>
                <Col md="4">
                  <div className={style.expenseAttachmentParent}>
                    <AttachmentCarousel
                      attachments={expenseItem?.assets_attributes || []}
                      onDeleteAttachmentCallback={onDeleteAttachment}
                      onUploadRequestedCallback={onAttachmentUpload}
                    />
                  </div>
                  {/* AC-17238: hiding "attach receipt" functionality via feature flag goes here.  */}
                  <Button
                    className={style.attachFromReceiptQueueButton}
                    variant="secondary"
                    onClick={() => showReceiptListDialog()}
                  >
                    {translate("attach")}
                  </Button>
                </Col>
              </Can>
              <Col md="8">
                <PolicyAndItemTypeSelection />
                {expenseItem?.selected_type?.value === "SINGLE_EXPENSE" && <SingleExpenseForm />}
                {expenseItem?.selected_type?.value === "MILEAGE" && <MileageForm />}
                {expenseItem?.selected_type?.value === "PER_DIEM" && <PerDiemForm />}
              </Col>
            </Row>
            <ExpenseAllocations />
          </Col>
        </Row>
      </ExpenseItemFormContext.Provider>
      {expenseItem && (
        <CustomModal
          size="lg"
          show={showReceiptList}
          onHide={() => setShowReceiptList(false)}
          header={translate("attachReceiptDialogHeader")}
          body={
            <ExpenseReceiptList
              expenseItem={expenseItem}
              setShowReceiptList={setShowReceiptList}
              receiptAttachedCallback={receiptAttachedCallback}
            />
          }
        />
      )}
    </ReceiptsProvider>
  );
};

export default EditExpenseItemFormSection;
