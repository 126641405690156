/*eslint-disable eqeqeq*/
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-balham.css";
import { AgGridReact } from "ag-grid-react";
import React, { Component } from "react";
// import 'ag-grid-community/styles/ag-theme-fresh.css';
import "ag-grid-enterprise";
import { calculateGridHeight } from "../../services/grid/gridHeight";
import CustomStatToolPanel from "../common/dataGrid/customStatToolPanel";
import ChildCellRouting from "./childCellRouting.jsx";
import ChildMessageRenderer from "./childMessageRenderer.jsx";
import ContactArrayRenderer from "./contactArrayRenderer";
import CustomPinnedRowRenderer from "./customPinnedRowRenderer.jsx";
import "./datagrid.style.css";
import FloatingFilterComponent from "./floatingFilterComponent.jsx";
import MyFooterValueGetter from "./MyFooterValueGetter.jsx";
import RandomChildCellRouting from "./randomCellChildRouting";

import moment from "moment";
import { connect } from "react-redux";
import * as actionType from "../../actions/actionTypes";
import CustomDropdown from "./customDropdown";

const ColoredLine = ({ color }) => (
  <hr
    style={{
      color: "#76777A",
      height: 0.5,
      marginBottom: 34,
      marginTop: "2rem",
    }}
  />
);
class DataGrid extends Component {
  constructor(props) {
    super(props);
    this.state = {
      temporaryGridHeight: "500px",
      showFloatFilter: true,
      icons: {
        //deprecated in new ag-grid
        // checkboxUnchecked: function (params) {
        //   return '<span><input class="abc123" type="checkbox"/></span>';
        // },
        // checkboxUnchecked:"A"
      },

      statusBar: {
        statusPanels: [
          {
            statusPanel: "agTotalAndFilteredRowCountComponent",
            align: "left",
          },
          {
            statusPanel: "agTotalRowCountComponent",
            align: "center",
          },
          { statusPanel: "agFilteredRowCountComponent" },
          { statusPanel: "agSelectedRowCountComponent" },
          { statusPanel: "agAggregationComponent" },
        ],
      },
      sideBar: {
        toolPanels: [
          {
            id: "customStats",
            labelDefault: "Actions",
            labelKey: "Tools",
            iconKey: "columns",
            toolPanel: "customStatsToolPanel",
            width: 200,
          },
          {
            id: "columns",
            labelDefault: "Columns",
            labelKey: "columns",
            iconKey: "columns",
            toolPanel: "agColumnsToolPanel",
            toolPanelParams: {
              suppressRowGroups: true,
              suppressValues: true,
              suppressPivotMode: true,
            },
          },
        ],
        defaultToolPanel: "",
      },
      style: {
        width: "100%",
      },
      nonEditableColumn: { editable: false },

      defaultColDef: {
        resizable: true,
        sortable: true,
        // suppressSizeToFit:true,
        floatingFilter: true,
        menuTabs: ["filterMenuTab", "generalMenuTab", { defaultItems: "filterMenuTab" }],
        name: props.dataType,
        cellRendererParams: props,

        cellStyle: { textAlign: "center" },
        // floatingFilterComponentParams:{
        //     suppressFilterButton:true,
        // }
      },
      headerHeight: 40,
      rowData: [
        {
          make: "Toyota",
          model: "Celica",
          price: 35000,
        },
      ],
      floatingFilterComponentParams: {
        suppressFilterButton: true,
        color: "red",
      },

      components: {
        childMessageRenderer: ChildMessageRenderer,
        customStatsToolPanel: CustomStatToolPanel,
        footerValueGetter: MyFooterValueGetter,
        customPinnedRowRenderer: CustomPinnedRowRenderer,
        childCellRouting: ChildCellRouting,
        floatingFilterComponent: FloatingFilterComponent,
        randomChildCellRouting: RandomChildCellRouting,
        ContactArrayRenderer: ContactArrayRenderer,
        // 'agDateInput': CustomDateComponentagDateInput,
        // 'khan': 'CustomPinnedRowRenderer',
        pinnedBottomRowData: [],
      },
    };
    this.generatePinnedBottomData = this.generatePinnedBottomData.bind(this);
    this.calculatePinnedBottomData = this.calculatePinnedBottomData.bind(this);
    this.saveState.bind(this);
  }

  componentDidUpdate() {
    if (this.gridApi) {
      let pinnedBottomData = this.generatePinnedBottomData();
      if (pinnedBottomData) {
        this.gridApi.setGridOption("pinnedBottomRowData", [pinnedBottomData]);
      }

      //if the loading prop has updated check if it is false and if the rowData is empty to show the no rows overlay
      if (this.props.loading != null && !this.props.loading && (!this.props.rowData || this.props.rowData.length < 1)) {
        this.gridApi.showNoRowsOverlay();
      }
    }
  }

  componentDidMount() {
    this.setState({ temporaryGridHeight: calculateGridHeight(this.gridElement.getBoundingClientRect().y) + "px" });
    window.addEventListener("beforeunload", this.saveState);
  }

  componentWillUnmount() {
    window.removeEventListener("beforeunload", this.saveState);
    this.saveState();
  }

  onGridReady = (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    let getState = JSON.parse(localStorage.getItem(this.props.dataType));
    if (getState) {
      this.gridApi.applyColumnState(getState[this.props.dataType].sortState);
      this.gridApi.applyColumnState(getState[this.props.dataType].colState);
      this.gridApi.setColumnGroupState(getState[this.props.dataType].groupState);
      this.gridApi.setFilterModel(getState[this.props.dataType].filterState);
    }

    // this.gridColumnApi.gridOptions.rowHeight = 40;
    this.gridApi.autoSizeColumns();
    // this.gridApi.sizeColumnsToFit();
    // this.gridColumnApi.sizeColumnsToFit();
    let pinnedBottomData = this.generatePinnedBottomData();
    this.gridApi.setGridOption("pinnedBottomRowData", [pinnedBottomData]);

    //if the loading props was passed in show the loading overlay
    if (this.props.loading != null && this.props.loading) {
      this.gridApi.showLoadingOverlay();
    }
  };

  generatePinnedBottomData() {
    if (!this.gridApi) {
      return;
    }
    // generate a row-data with null values
    let result = {};

    this.gridApi.getAllGridColumns().forEach((item) => {
      result[item.colId] = null;
    });
    return this.calculatePinnedBottomData(result);
  }

  calculatePinnedBottomData(target) {
    //list of columns for aggregation
    let columnsWithAggregation = ["amount", "accrual_balance", "invoice_balance"];
    // let element = 'amount'
    columnsWithAggregation.forEach((element) => {
      this.gridApi.forEachNodeAfterFilter((rowNode) => {
        if (rowNode.data[element]) target[element] += Number(rowNode.data[element].toFixed(2));
      });

      // TODO: find solution to show string
      if (target[element]) {
        target[element] = Number(target[element].toFixed(2));
        //  target[element] = `Total: ${target[element].toFixed(2)}`;
      } else if (!target[element]) {
        target[element] = 0;
        // `Total: ${0}`;
      }
    });
    return target;
  }

  render() {
    return (
      <div>
        <div style={{ width: "100%", height: "100%" }}>
          {this.props.dataType === "invoices" ? (
            <div>
              {/* <Button size="sm" className="gridButton blue" onClick={this.onCreateInvoice} disabled>
                Create Invoice
              </Button> */}
              <button size="sm" className="gridButton btn btn-primary" onClick={this.onSubmitInvoice}>
                Submit Invoice
              </button>
            </div>
          ) : null}

          <CustomDropdown
            gridHeaderButtons={this.props.gridHeaderButtons}
            addRow={this.props.addRow}
            onQuickFilterSelect={this.onQuickFilterSelect}
            onShowFloatfilter={this.onShowFloatfilter}
            quickFIlter={this.state.quickFIlter}
            {...this.props}
          />
        </div>
        <ColoredLine color="#76777A" style={{ marginTop: 24 }} />
        <div
          className="ag-theme-fresh animated fadeIn"
          ref={(gridElement) => {
            this.gridElement = gridElement;
          }}
          style={{
            width: this.props.gridStyle.width,
            height: this.getGridHeight(),
          }}
        >
          <AgGridReact
            columnDefs={this.props.columnDefs}
            rowData={this.props.rowData}
            rowSelection="multiple"
            suppressRowClickSelection={"true"}
            sideBar={this.state.sideBar}
            onGridReady={this.onGridReady}
            onFilterChanged={this.onExcelExport}
            onSortChanged={this.onExcelExport}
            defaultColDef={this.state.defaultColDef}
            components={this.state.components}
            headerHeight={this.state.headerHeight}
            rowHeight={40}
            statusBar={this.state.statusBar}
            icons={this.state.icons}
            // isExternalFilterPresent="true"
            floatingFiltersHeight="40"
            getRowStyle={function (params) {
              if (params.node.rowIndex == params.api.rowModel.rowsToDisplay.length - 1) {
                return { borderBottom: "1px solid #B2B2B2 !important" };
              }
            }}
            floatingFilterComponentParams={this.state.floatingFilterComponentParams}
            {...this.props}
            // dateComponentParams={}
            // dateComponent={function (params) {
            //     console.log(params);
            // }}

            // groupIncludeTotalFooter={true}
            //  groupRowAggNodes={this.groupRowAggNodes}
            // getMainMenuItems={this.getMainMenuItems}
          ></AgGridReact>
        </div>
      </div>
    );
  }

  // TODO:  Remove from here add to invoice list page
  onCreateInvoice = () => {
    this.props.history.push("ar/create_invoice");
  };
  onSubmitInvoice = () => {
    this.props.history.push("ar/submit_invoice");
  };

  getGridHeight() {
    if (this.props.autoHeight) {
      if (!this.gridElement) {
        return this.state.temporaryGridHeight;
      }
      return calculateGridHeight(this.gridElement.getBoundingClientRect().y) + "px";
    } else {
      return this.props.gridStyle.height;
    }
  }
  saveState = () => {
    // TODO: test it
    let savedState = {};
    let fd = {
      colState: this?.gridColumnApi?.getColumnState(),
      groupState: this?.gridColumnApi?.getColumnGroupState(),
      sortState: this?.gridColumnApi?.getColumnState(),
      filterState: this?.gridApi?.getFilterModel(),
    };
    savedState[this.props.dataType] = fd;
    localStorage.setItem(this.props.dataType, JSON.stringify(savedState));
  };

  // important
  groupRowAggNodes = (nodes) => {
    var result = {
      gold: 0,
      silver: 0,
      bronze: 0,
      goldPie: 0,
      silverPie: 0,
      bronzePie: 0,
    };

    nodes.map((node) => {
      if (typeof node.data.amount === "number") {
        result.gold += node.data.amount;
      }

      return <MyFooterValueGetter amount={result} />;
    });
    return nodes;
  };
  onBtExport = () => {
    let params = {
      skipHeader: false,
      columnGroups: true,
      skipFooters: false,
      skipGroups: false,
      skipPinnedTop: true,
      skipPinnedBottom: true,
      allColumns: false,
      onlySelected: false,
      suppressQuotes: false,
      // fileName: document.querySelector("#fileName").value,
      fileName: "dataExport",
      // columnSeparator: document.querySelector("#columnSeparator").value
    };
    this.gridApi.exportDataAsCsv(params);
  };

  onAllExport = () => {
    var params = {
      skipHeader: false,
      columnGroups: true,
      skipFooters: false,
      skipGroups: false,
      skipPinnedTop: true,
      skipPinnedBottom: true,
      allColumns: true,
      onlySelected: false,
      suppressQuotes: false,
      // fileName: document.querySelector("#fileName").value,
      fileName: "dataExport",
      // columnSeparator: document.querySelector("#columnSeparator").value
    };
    this.gridApi.exportDataAsCsv(params);
  };
  onExcelExport = () => {
    if (this.gridApi) {
      // var params = {
      //   skipHeader: false,
      //   columnGroups: true,
      //   skipFooters: false,
      //   skipGroups: false,
      //   skipPinnedTop: true,
      //   skipPinnedBottom: true,
      //   allColumns: false,
      //   onlySelected: false,
      //   suppressQuotes: false,
      //   // fileName: document.querySelector("#fileName").value,
      //   fileName: 'ahtesham',
      //   // columnSeparator: document.querySelector("#columnSeparator").value
      //   exportMode: 'xlsx'
      // };
      // this.gridApi.exportDataAsExcel(params);
      let pinnedBottomData = this.generatePinnedBottomData();
      this.gridApi.setGridOption("pinnedBottomRowData", [pinnedBottomData]);
    }
  };

  onButtonClick = (e) => {
    const selectedNodes = this.gridApi.getSelectedNodes();
    const selectedData = selectedNodes.map((node) => node.data);
    const selectedDataStringPresentation = selectedData.map((node) => node.make + " " + node.model).join(", ");
    alert(`Selected nodes: ${selectedDataStringPresentation}`);
  };
  getMainMenuItems = (params) => {};

  ////////////////////////////////////////DropDown methods Start////////////////////////////////////////////////////////////

  onShowFloatfilter = (event) => {
    // this.setState({
    //     showFloatFilter:!this.state.showFloatFilter
    // })
    this.setState(
      {
        defaultColDef: { floatingFilter: !this.state.defaultColDef.floatingFilter },
        showFloatFilter: !this.state.showFloatFilter,
      },
      () => {
        this.gridApi.refreshHeader();
      },
    );
  };

  onQuickFilterSelect = (event) => {
    this.setState({ quickFIlter: event });
    let filterBy = event;

    switch (filterBy) {
      case "clear":
        return this.clearFilter();
      case "noQuick":
        return this.resetFilter();
      case "reset":
        return this.resetFilter();
      case "noRequestor":
        return this.noRequestor();
      case "noCustomer":
        return this.noCustomer();
      case "month":
        return this.month();
      case "year":
        return this.year();
      case "quarter":
        return this.quarter();
      case "lastQuarter":
        return this.lastQuarter();
      case "topOpenPOSort":
        return this.topOpenPOSort();
      case "negativePOSort":
        return this.negativePOSort();
      case "noPO":
        return this.noPO();
      case "all":
      case "draft":
      case "pending":
      case "approved":
      case "open":
      case "closed":
      case "rejected":
      case "active":
        return this.statusFilter();

      default:
        return true;
    }
  };
  clearFilter() {
    this.gridApi.setFilterModel(null);
    this.gridApi.onFilterChanged();
  }

  resetFilter() {
    this.gridApi.setFilterModel(null);
    this.gridApi.deselectAll();
    this.gridColumnApi.resetColumnState();
    this.saveState();
  }

  noRequestor() {
    var filterInstance = this.gridApi.getFilterInstance("requestor.name");
    filterInstance.setModel({
      type: "No data Specified",
      // filter: ''
    });
    this.gridApi.onFilterChanged();
  }

  noCustomer() {
    var filterInstance = this.gridApi.getFilterInstance("purchaser.name");

    filterInstance.setModel({
      type: "No data Specified",
      // filter: ''
    });
    this.gridApi.onFilterChanged();
  }

  month() {
    var today = new Date();
    var firstDay = new Date(today.getFullYear(), today.getMonth(), 1);
    var lastDay = new Date(today.getFullYear(), today.getMonth() + 1, 0);
    var filterInstance =
      this.props.dataType == "invoice_request"
        ? this.gridApi.getFilterInstance("sent_date")
        : this.props.dataType == "payments"
          ? this.gridApi.getFilterInstance("payment_date")
          : this.gridApi.getFilterInstance("date");
    filterInstance.setModel({
      filterType: "date",
      type: "inRange",
      // filter: '',
      dateFrom: moment(firstDay).format("YYYY-MM-DD"), //   '2017-11-01',
      dateTo: moment(lastDay).format("YYYY-MM-DD"),
    });
    this.gridApi.onFilterChanged();
  }

  year() {
    var today = new Date();
    var firstDay = new Date(today.getFullYear(), 0, 1);
    var lastDay = new Date(today.getFullYear(), 12, 0);

    var filterInstance =
      this.props.dataType == "invoice_request"
        ? this.gridApi.getFilterInstance("sent_date")
        : this.props.dataType == "payments"
          ? this.gridApi.getFilterInstance("payment_date")
          : this.gridApi.getFilterInstance("date");
    filterInstance.setModel({
      filterType: "date",
      type: "inRange",
      // filter: '',
      dateFrom: moment(firstDay).format("YYYY-MM-DD"), //   '2017-11-01',
      dateTo: moment(lastDay).format("YYYY-MM-DD"),
    });
    this.gridApi.onFilterChanged();
    // console.log(this.gridApi.onFilterChanged())
  }

  quarter() {
    var today = new Date();
    var quarter = Math.floor((today.getMonth() + 3) / 3);
    var firstDay = moment().quarter(quarter).startOf("quarter")._d;
    var lastDay = moment().quarter(quarter).endOf("quarter")._d;

    var filterInstance =
      this.props.dataType == "invoice_request"
        ? this.gridApi.getFilterInstance("sent_date")
        : this.props.dataType == "payments"
          ? this.gridApi.getFilterInstance("payment_date")
          : this.gridApi.getFilterInstance("date");
    filterInstance.setModel({
      filterType: "date",
      type: "inRange",
      // filter: '',
      dateFrom: moment(firstDay).format("YYYY-MM-DD"), //   '2017-11-01',
      dateTo: moment(lastDay).format("YYYY-MM-DD"),
    });
    this.gridApi.onFilterChanged();
  }

  lastQuarter() {
    var today = new Date();
    var quarter = Math.floor((today.getMonth() + 3) / 3);
    var last_quarter;

    if (quarter === 1) {
      last_quarter = 4;
    } else {
      last_quarter = quarter - 1;
    }

    var firstDay = moment().quarter(last_quarter).startOf("quarter")._d;
    var lastDay = moment().quarter(last_quarter).endOf("quarter")._d;

    var filterInstance =
      this.props.dataType == "invoice_request"
        ? this.gridApi.getFilterInstance("sent_date")
        : this.props.dataType == "payments"
          ? this.gridApi.getFilterInstance("payment_date")
          : this.gridApi.getFilterInstance("date");
    filterInstance.setModel({
      filterType: "date",
      type: "inRange",
      // filter: '',
      dateFrom: moment(firstDay).format("YYYY-MM-DD"), //   '2017-11-01',
      dateTo: moment(lastDay).format("YYYY-MM-DD"),
    });
    this.gridApi.onFilterChanged();
  }

  topOpenPOSort() {
    var filterInstance = this.gridApi.getFilterInstance("open_balance");

    filterInstance.setModel({
      type: "greaterThan",
      filter: "0",
    });
    this.gridApi.onFilterChanged();
  }

  negativePOSort() {
    var filterInstance = this.gridApi.getFilterInstance("open_balance");

    filterInstance.setModel({
      type: "lessThan",
      filter: "0",
    });
    this.gridApi.onFilterChanged();
  }

  noPO() {
    var filterInstance = this.gridApi.getFilterInstance("purchase_order.number");
    filterInstance.setModel({
      type: "blanks",
      // filter: ''
    });
    this.gridApi.onFilterChanged();
  }
  statusFilter() {}
  ///////////////////////////////////////////////////////Dropdown method ends//////////////////////////////////////////////////////////////////
}

const mapStateToProps = (state, ownProps) => {
  return {
    floatingFilter: state.floatingFilter,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addFloat: (floatingFilter) => {
      dispatch({ type: actionType.FLOATFILTER, payload: this.onQuickFilterSelect() });
    },
    // createContact: contact => dispatch(contactAction.createContact(contact))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DataGrid);
