import PickerErrorBlock from "components/common/pickers/pickerErrorBlock";
import { Mandatory } from "components/forms/bootstrapFields";
import moment from "moment";
import React, { ReactNode, useCallback, useMemo, useRef } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import ReactDatePicker from "react-datepicker";
import { WrappedFieldProps } from "redux-form";
import style from "../datePicker/datePicker.module.css";

interface DatePickerPropsType extends WrappedFieldProps {
  className?: string;
  label?: ReactNode;
  tooltip?: ReactNode;
  required?: boolean;
  disabled?: boolean;
  minDate?: string;
  maxDate?: string;
  id?: string;
  inUtc?: boolean;
}

const DateTimePicker = ({
  className,
  input,
  id = "react-date-time-picker",
  label,
  tooltip,
  meta: { touched, error, warning },
  required,
  disabled,
  maxDate,
  minDate,
  inUtc,
}: DatePickerPropsType) => {
  const datePickerRef = useRef<any>();

  const getSelectDate = useCallback((): Date | null => {
    return input.value ? new Date(moment.parseZone(input.value).format("YYYY-MM-DDTHH:mm:ss")) : null;
  }, [input.value]);

  const onDateChange = useCallback((value: Date | null) => {
    input.onChange(moment.parseZone(value).format("YYYY-MM-DDTHH:mm:ss"));
  }, []);

  // Function to set the date to today
  const setToday = () => {
    const dateStrFormated = inUtc
      ? moment().utc().format("YYYY-MM-DDTHH:mm:ss")
      : moment().format("YYYY-MM-DDTHH:mm:ss");
    datePickerRef?.current?.setOpen(false);
    input.onChange(dateStrFormated);
  };

  const clearDate = () => {
    input.onChange(null);
    datePickerRef?.current?.setOpen(false);
  };

  const isTodayDisabled = useMemo(() => {
    let today = moment();
    if (!maxDate && minDate) {
      return today.isBefore(minDate, "day");
    } else if (!minDate && maxDate) {
      return today.isAfter(maxDate, "day");
    } else if (minDate && maxDate) {
      if (today.isSameOrAfter(minDate, "day") && today.isSameOrBefore(maxDate, "day")) {
        return false;
      } else {
        return true;
      }
    }
  }, [minDate, maxDate]);

  return (
    <Form.Group>
      {label && (
        <Form.Label>
          {label}
          <Mandatory required={required} />
          {tooltip}
        </Form.Label>
      )}
      <Row className="m-0 p-0 d-flex flex-row flex-nowrap">
        <ReactDatePicker
          name={input.name}
          ref={datePickerRef}
          onChange={onDateChange}
          selected={getSelectDate()}
          placeholderText={"MM/dd/yyyy h:mm aa"}
          timeInputLabel="Time:"
          dateFormat="MM/dd/yyyy h:mm aa"
          className={`${touched && error ? "requiredField" : "w-100"}  icon-calender`}
          disabled={disabled}
          minDate={minDate ? new Date(minDate) : null}
          maxDate={maxDate ? new Date(maxDate) : null}
          // The 'popperProps: positionFixed' configuration ensures that the DatePicker calendar popper is displayer above its parent,
          // preventing any cut-off appearance. It ensuring the full visibility of the calendar
          popperProps={{ positionFixed: true }}
          id={id}
          showTimeInput={true}
        >
          <div>
            <Row>
              <Col className="d-flex justify-content-center align-items-center">
                <div className={`mb-2 ${style.buttonGrp}`}>
                  <Button variant="secondary" className={style.btnSm} onClick={setToday} disabled={isTodayDisabled}>
                    Today
                  </Button>
                  <Button className={style.btnSm} onClick={clearDate}>
                    Clear
                  </Button>
                </div>
              </Col>
            </Row>
          </div>
        </ReactDatePicker>
        {!label && required && (
          <div className="ml-2">
            <Mandatory required={required}></Mandatory>
          </div>
        )}
      </Row>
      {touched && error && <PickerErrorBlock error={error} />}
    </Form.Group>
  );
};

export default DateTimePicker;
