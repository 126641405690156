import React, { memo } from "react";
import { BudgetPickerType } from "./budgetPickerTypes";
import SingleBudgetPicker from "./singlePicker";

const BudgetPicker = (props: BudgetPickerType.TBudgetPickerProps) => {
  return (
    <>
      {props.isMulti ? (
        // <MultiBudgetPicker/>         // TODO: Implement Multi Budget Picker
        <></>
      ) : (
        <SingleBudgetPicker {...props} />
      )}
    </>
  );
};

export default memo(BudgetPicker);
