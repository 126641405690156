import _ from "lodash";
import React, { Fragment, memo, useCallback, useMemo } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { useTypedSelector } from "reducers";
import { IUser } from "services/common/user/userTypes";
import AccountsTabEntriesPanel from "./manageAccountsTabEntries";
type TAccountTabEntries = {
  debitEntries: string;
  creditEntries: string;
  modelDataName: string;
  modelObject: string;
  debitGroupName?: string;
  creditGroupName?: string;
  hideCreditAccounts?: boolean;
  isPoRequest?: boolean;
  modelName?: string;
  noVendor: boolean;
  readonly: boolean;
  disabled: boolean;
};

const AccountTabEntries = ({
  debitEntries,
  creditEntries,
  modelDataName,
  modelObject,
  debitGroupName,
  creditGroupName,
  hideCreditAccounts,
  isPoRequest = false,
  modelName,
  noVendor,
  readonly,
  disabled,
}: TAccountTabEntries) => {
  const currentUser: IUser = useTypedSelector((state) => state.user);
  const { control } = useFormContext();
  const [paymentType] = useWatch({ control, name: [`${modelDataName}.paymentType`] });
  const accountEntryLists = useMemo(() => {
    let accountEntryLists = [];
    accountEntryLists.push({
      label: "Debit Accounts",
      label_name: "add_debit_account",
      accountEntries: debitEntries,
      accountGroupName: debitGroupName,
    });
    accountEntryLists.push({
      label: "Credit Accounts",
      label_name: "add_credit_account",
      accountGroupName: creditGroupName,
      accountEntries: creditEntries,
      hide: hideCreditAccounts ? true : false,
    });
    return accountEntryLists;
  }, [creditEntries, creditGroupName, debitEntries, debitGroupName, hideCreditAccounts]);

  const hideDebitAccount = useCallback(
    (accountLabel) => {
      return (
        currentUser?.company?.credit_memo?.hide_debit_account &&
        accountLabel === "Debit Accounts" &&
        paymentType === "CREDIT"
      );
    },
    [paymentType, currentUser],
  );

  const showAccountEntry = useCallback(
    (label: string, accountEntry: any, hide?: boolean) => {
      if (!_.isPlainObject(accountEntry) || accountEntry._destroy == 1) {
        return false;
      } else if (modelDataName == "purchase_order" && label == "Credit Accounts") {
        return false;
      } else if (
        accountEntry &&
        (accountEntry.product_item_id ||
          accountEntry.subtotal_template_id ||
          accountEntry.is_subtotal_template ||
          accountEntry.is_header_level_tax)
      ) {
        return false;
      } else if (hide) {
        return false;
      } else if (hideDebitAccount(label)) {
        return false;
      }
      return true;
    },
    [hideDebitAccount, modelDataName],
  );

  return (
    <>
      {accountEntryLists.map((accountEntryList) => (
        <Fragment key={accountEntryList.label_name}>
          {showAccountEntry(accountEntryList.label, {}, accountEntryList.hide) && (
            <AccountsTabEntriesPanel
              accountEntryList={accountEntryList}
              showAccountEntry={showAccountEntry}
              hideDebitAccount={hideDebitAccount}
              modelDataName={modelDataName}
              modelObject={modelObject}
              isPoRequest={isPoRequest}
              // modelName={modelName}
              paymentType={paymentType}
              noVendor={noVendor}
              readonly={readonly}
              disabled={disabled}
            />
          )}
        </Fragment>
      ))}
    </>
  );
};

export default memo(AccountTabEntries);
