import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Container, Form, InputGroup, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { companyDateFormat } from "services/general/dateSvc";
import { ExpensesTypes } from "services/admin/expenses/expensesType";
import { Receipt, useReceipts } from "./receiptListContext";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "reducers";
import CustomModal from "components/modals/customModal";
import ExpensesApis from "services/admin/expenses/expensesApis";
import Panel from "components/common/panel/panel";
import ReceiptsApis from "services/admin/expenses/receipts/receiptsApis";
import styles from "./expenseItem.module.css";
interface ExpenseReceiptListProps {
  expenseItem: ExpensesTypes.ExpenseItemFormDataType | undefined;
  setShowReceiptList: (show: boolean) => void;
  receiptAttachedCallback?: () => void | undefined;
}

const ExpenseReceiptList: React.FC<ExpenseReceiptListProps> = ({
  expenseItem,
  setShowReceiptList,
  receiptAttachedCallback,
}) => {
  const { receipts, addRawReceipts, removeAllReceipts, flipCheckedStatus, restoreOriginalReceipts } = useReceipts();
  const [visibleFilteredItems, setVisibleFilteredItems] = useState<{ [key: number]: boolean }>({});
  const [enableDoneButton, setEnableDoneButton] = useState<boolean>(false);
  const [filterCompanyNameText, setFilterCompanyNameText] = useState<string>("");
  const [showZoomedImageDialog, setShowZoomedImageDialog] = useState(false);
  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const [zoomDialogTitle, setZoomDialogTitle] = useState<string>("");
  const currentUser = useTypedSelector((state) => state.user);
  const clearFilterList = useRef<{ [key: string]: boolean }[]>([]);

  const { t } = useTranslation();
  const t2 = (key: string, options: any = undefined) => {
    return t(`components.admin.expenseReceiptList.${key}`, options);
  };

  const getReceipts = async () => {
    if (receipts.length > 0) {
      removeAllReceipts();
    }

    const BASE_PARAMS = {
      document_type: "RECEIPT",
      status: ["PENDING_OCR", "PENDING_MATCH", "ACTION_REQUIRED"],
      documentable_type: "Contact",
      items: 100,
    };

    var result = await ReceiptsApis.getReceiptList({ filter: BASE_PARAMS });

    addRawReceipts(result.data);

    clearFilterList.current = result.data.map((receipt) => ({ [String(receipt.id)]: true }));
    setVisibleFilteredItems(Object.assign({}, ...clearFilterList.current));
  };

  const handleCheckboxChange = (id: string) => {
    flipCheckedStatus(id);
  };

  const updateExpenseItemNotes = async () => {
    if (expenseItem && expenseItem.id) {
      // All newly attached receipts need their **notes** to be appended to the parent expense item's **description**, separated by the pipe character
      let receiptNotes: string[] = [];

      if (expenseItem.description && expenseItem.description.length > 0) {
        receiptNotes.push(expenseItem.description);
      }

      receipts
        .filter((receipt) => receipt.selected)
        .forEach((receipt) => {
          if (typeof receipt.note === "string" && (receipt.note as string).length > 0) {
            receiptNotes.push(receipt.note);
          }
        });

      // On existing multiline descriptions, replace newlines with pipes.  Sometimes they sneak in.
      expenseItem.description = receiptNotes.join(" | ").replace(/\n/g, " | ");

      await ExpensesApis.updateExpenseItem({
        id: expenseItem.id.toString(),
        payload: { expense_item: expenseItem },
      });
    }
  };

  const handleFilterKeyPress = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilterCompanyNameText(event.target.value);
    const merchantNameFilterValue = event.target.value.trim().toLowerCase();

    if (merchantNameFilterValue.length === 0) {
      setVisibleFilteredItems(Object.assign({}, ...clearFilterList.current));
    } else {
      const filteredReceipts = receipts.map((receipt) => {
        const merchantName = receipt.merchantName.toLowerCase();
        return {
          [String(receipt.id)]: merchantName.includes(merchantNameFilterValue) || receipt.selected, // Receipts that are checked should always be visible
        };
      });
      setVisibleFilteredItems(Object.assign({}, ...filteredReceipts));
    }
  };

  const displayImageZoomed = (receipt: Receipt) => {
    setZoomDialogTitle(receipt.merchantName);
    setSelectedImage(receipt.image.imageUrl);
    setShowZoomedImageDialog(true);
  };

  const hideImageZoomed = () => {
    setShowZoomedImageDialog(false);
    setSelectedImage(null);
  };

  const onCancel = () => {
    setShowReceiptList(false);
    restoreOriginalReceipts();
  };

  const onSave = () => {
    if (expenseItem) {
      expenseItem.receipts = receipts.filter((receipt) => receipt.selected);

      updateExpenseItemNotes();

      if (receiptAttachedCallback) {
        receiptAttachedCallback();
      }
    }

    setShowReceiptList(false);
  };

  useEffect(() => {
    const count = receipts.filter((receipt) => receipt.selected).length;
    setEnableDoneButton(count > 0);
  }, [receipts]);

  useEffect(() => {
    getReceipts();
  }, []);

  const listContent = () => {
    return (
      <>
        <Col>
          <Row>{t2("headerLine")}</Row>
        </Col>
        <InputGroup>
          <Form.Control
            type="text"
            placeholder={t2("search")}
            value={filterCompanyNameText}
            className={styles.searchBar}
            onChange={handleFilterKeyPress}
          />
        </InputGroup>
        <Col>
          <Row className={styles.expenseReceiptListListContainer}>
            {receipts.map((receipt, index) => (
              <Container key={receipt.id}>
                {receipt.id !== undefined && visibleFilteredItems[Number(receipt.id)] ? (
                  <Panel
                    wrapperClass={styles.receiptRowContainerPanel}
                    cardBodyClass={styles.receiptRowContainerPanelBody}
                  >
                    <Row>
                      <Col xs="auto" className="pl-4 pr-2 d-flex align-items-center">
                        <Form.Check
                          type="checkbox"
                          id={`checkbox-${receipt.id}`}
                          checked={receipt.selected}
                          onChange={() => handleCheckboxChange(receipt.id)}
                        />
                      </Col>
                      <Col xs="2" className="pr-0 pl-0 d-flex align-items-center">
                        <object
                          className={styles.expenseReceiptListThumbnail}
                          key={receipt.image.fileName}
                          data={receipt.image.imageUrl}
                          type="image/png"
                          aria-label="image"
                          onClick={(event) => {
                            displayImageZoomed(receipt);
                          }}
                        />
                      </Col>
                      <Col xs="9" className="pl-0">
                        <Row className={`${styles.expenseReceiptListTopRow} pl-0`}>
                          <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip id={`tooltip-${receipt.id}`}>{receipt.merchantName}</Tooltip>}
                          >
                            <Col xs="6">{receipt.merchantName}</Col>
                          </OverlayTrigger>
                          <Col xs="3">{companyDateFormat(receipt.date, currentUser)}</Col>
                          <Col xs="3">{receipt.amount}</Col>
                        </Row>
                        <Row className={styles.expenseReceiptListSecondRow}>
                          <Col>
                            {t2("note")}
                            {receipt.note}
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Panel>
                ) : (
                  <></>
                )}
              </Container>
            ))}
          </Row>
        </Col>
        <Row className={`justify-content-end ${styles.expenseReceiptListFooter}`}>
          <Col xs="auto">
            <Button variant="secondary" onClick={onCancel}>
              {t("cancel")}
            </Button>
          </Col>
          <Col xs="auto">
            <Button variant="primary" onClick={onSave} disabled={!enableDoneButton}>
              {t("done")}
            </Button>
          </Col>
        </Row>
      </>
    );
  };

  return (
    <div>
      {listContent()}
      <CustomModal
        size="lg"
        show={showZoomedImageDialog}
        onHide={hideImageZoomed}
        header={zoomDialogTitle}
        headerTitleClass={styles.zoomedHeader}
        body={<>{selectedImage && <img src={selectedImage} alt="Full Size Object" className={styles.zoomedImage} />}</>}
      ></CustomModal>
    </div>
  );
};

export default ExpenseReceiptList;
