import React, { useCallback } from "react";
import { countries } from "../../../../app.svc.Lookup";
import AbstractListPicker from "../abstractListPicker/AbstractOptionsPicker";
import { AbstractListPickerTypes } from "../abstractListPicker/abstractOptionsPickerTypes";
import { CountryPickerTypes } from "./countryPickerTypes";

type TCountryPickerProps = {
  label: string;
  required?: boolean;
  disabled?: boolean;
  name: string;
  objectPropertyKey?: string;
  callBack?: (selectedCountry: CountryPickerTypes.TCountryOption | null | undefined) => void;
};

const CountryPicker = ({
  label,
  required = false,
  disabled = false,
  callBack,
  name,
  objectPropertyKey,
}: TCountryPickerProps) => {
  const handlePickerSelection = useCallback(
    (selected: AbstractListPickerTypes.TPickerValue<CountryPickerTypes.TCountryOption>) => {
      if (callBack) {
        callBack(selected);
      }
    },
    [callBack],
  );

  return (
    <AbstractListPicker<CountryPickerTypes.TCountryOption>
      name={name}
      options={countries}
      label={label}
      required={required}
      disabled={disabled}
      callBack={handlePickerSelection}
      valuePropertyName="value"
      labelPropertyName="label"
      placeholder="Select a country"
      objectPropertyKey={objectPropertyKey}
    />
  );
};

export default CountryPicker;
