import _ from "lodash";
import React, { useEffect } from "react";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { change, SubmissionError } from "redux-form";
import RestApi from "../../../../providers/restApi";
import {
  closeIntegrationModal,
  INTEGRATION_FORM_NAME,
  setCompany,
  setIntegrationForm,
  setIsIntegrationModalOpen,
} from "../../../../reducers/admin/integrationsReducer";
import { CreateNotification, NotificationType } from "../../../../services/general/notifications";
import { createCompleteError } from "../../../../services/general/reduxFormSvc";
import { checkSFTPValidation } from "../../../../services/integrationCommonSvc/integrationCommonSvc";
import IntegrationModalForm from "./integrationModalForm";

const restApiService = new RestApi();
const AddIntegration = ({ onSaveCallBack }) => {
  const dispatch = useDispatch();
  const company = useSelector((state) => state.integrationsRed.company);
  const { t } = useTranslation();
  const isIntegrationModalOpen = useSelector((state) => state.integrationsRed.isIntegrationModalOpen);

  useEffect(() => {
    dispatch(setIsIntegrationModalOpen(true));
    dispatch(setIntegrationForm({ form: {}, company: company }));
  }, [dispatch]);

  // only using with submit callback
  const closeModal = () => {
    dispatch(closeIntegrationModal());
    onSaveCallBack();
  };

  const submit = async (integrationFormData) => {
    try {
      const requestData = {
        payoneer_programs_attributes: integrationFormData.company.payoneer_programs,
      };

      let res;
      if (
        integrationFormData.form?.application_type === "VIRTUAL_CARD" &&
        typeof integrationFormData.form.sync_virtual_card === "undefined"
      ) {
        integrationFormData.form.sync_virtual_card = true;
      }
      if (integrationFormData.form && !integrationFormData.form.id) {
        // to creatre new integration settings
        res = await restApiService.post("integration_settings", null, {
          integration_setting: { ...integrationFormData.form },
        });
      }

      if (integrationFormData.form && integrationFormData.form.id) {
        // when check ftp then only we use these
        // to update currently created integration settings
        res = await restApiService.patch("integration_settings/" + integrationFormData.form.id, null, {
          integration_setting: { ...integrationFormData.form },
        });
      }

      // also update payonner_programs_attributes
      if (integrationFormData.form.application_type === "PAYONEER") {
        const companyResponse = await restApiService.patch("company", null, {
          company: requestData,
        });
        dispatch(setCompany(companyResponse.data));
      }

      if (res) {
        CreateNotification("Saved", "Credentials Saved ", "success");
        if (res.data) {
          // adding new data with id in form,so again clicking on submit will not genereate new integration
          dispatch(change(INTEGRATION_FORM_NAME, "form", res.data));

          if (
            res.data &&
            res.data.id &&
            integrationFormData.formTempState &&
            integrationFormData.formTempState.check_to_test_ftp
          ) {
            try {
              const sftpValidationRes = await checkSFTPValidation(res.data, dispatch);
              // validation success full close modal
              closeModal();
            } catch (error) {
              // if got error not closing modal let user see what is error
              CreateNotification(t("error"), error.message, NotificationType.danger);
            }
          } else {
            closeModal();
          }
        }
      } else {
        closeModal();
      }
    } catch (error) {
      const { response } = error;
      if (response.status === 422) {
        if (_.isPlainObject(response.data)) {
          const completeErrorObj = createCompleteError(response.data);
          throw new SubmissionError({
            form: completeErrorObj,
          });
        }
      }
    }
  };

  return (
    <>
      {isIntegrationModalOpen && (
        <Modal size="xl" show={isIntegrationModalOpen} onHide={() => dispatch(closeIntegrationModal())}>
          <IntegrationModalForm onSubmit={submit} onHide={() => dispatch(closeIntegrationModal())} />
        </Modal>
      )}
    </>
  );
};

export default AddIntegration;
