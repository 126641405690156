import { UseFieldArrayMove } from "react-hook-form";

export const allowDrag = (entries: any[], isValidEntry: (entry: any, index?: number) => boolean): boolean => {
  const validCount = entries.reduce((count, entry, index) => (isValidEntry(entry, index) ? count + 1 : count), 0);
  return validCount > 1;
};

export const handleDragStart = (
  e: React.DragEvent<HTMLDivElement>,
  index: number,
  setDraggingIndex: React.Dispatch<React.SetStateAction<number | null>>,
) => {
  setDraggingIndex(index);
};

export const handleDrop = (
  e: React.DragEvent<HTMLTableRowElement>,
  move: UseFieldArrayMove,
  index: number,
  draggingIndex: number | null,
  setDraggingIndex: React.Dispatch<React.SetStateAction<number | null>>,
) => {
  e.preventDefault();
  if (draggingIndex !== null) {
    move(draggingIndex, index);
    setDraggingIndex(null);
  }
};
