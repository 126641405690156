import React, { ReactNode, useCallback } from "react";
import MultiPoPicker from "./multiPicker";
import { restApiService } from "providers/restApi";
import { WrappedFieldProps } from "redux-form";
import { AxiosResponse } from "axios";
import invoiceCommonSvc from "services/admin/invoices/invoiceCommonSvc";
import { useTypedSelector } from "reducers";
import { IUser } from "services/common/user/userTypes";
import SinglePOPicker from "./singlePicker";
import { IDType } from "services/common/types/common.type";

export interface PurchaseOrderPickerPropsType extends WrappedFieldProps {
  isMulti?: boolean;
  label?: string;
  labelClassName?: string;
  tooltip?: string;
  params?: Record<string, any>;
  required?: boolean;
  inputId?: string;
  placeholder?: string;
  disabled?: boolean;
  originalValue?: ReactNode;
  callBack?: (po: PurchaseOrderOptionType | null) => void;
  deleteCallBack?: (po: PurchaseOrderOptionType) => void;
  loadOptions?: any;
  options?: any;
  isPoRequired?: boolean;
  modelData: any;
  instanceId?: string;
  contactId?: IDType;
  useParentCurrencyCode?: boolean;
  searchPo?: boolean;
  isClearable?: boolean;
}

type ParamsType = {
  by_open: boolean;
  record_limit: number;
  number: string;
  vendor?: string;
  subsidiary_id?: string;
  currency_code?: string;
};

type PurchaseOrderListType = {
  id: number;
  number: string;
  status: string;
  amount: number;
  po_type: string | null;
  date: string;
  description: string;
  open_balance: number;
  vendor_name: string;
  vendor_id: number;
  start_date: string | null;
  end_date: string | null;
  requestor_id: number;
  subsidiary_id: number | null;
  allow_to_receipt: boolean;
  currency: {
    symbol: string;
    iso_code: string;
    precision: number;
  };
};

export type PurchaseOrderOptionType = {
  value: number;
  label: string;
} & PurchaseOrderListType;

const parseForSelect = (purchaseOrders: PurchaseOrderListType[]) => {
  return purchaseOrders.map((purchaseOrder) => ({
    value: purchaseOrder.id,
    label: purchaseOrder.number,
    ...purchaseOrder,
  }));
};

const PurchaseOrderPicker = ({ isMulti, modelData, ...rest }: PurchaseOrderPickerPropsType) => {
  const currentUser: IUser = useTypedSelector((state) => state.user);
  const getPurchaseOrders = async (getParams: any) => {
    const result: AxiosResponse<PurchaseOrderListType[]> = await restApiService.get(
      "purchase_orders.lk",
      getParams,
      null,
      true,
      null,
      true,
    );
    let parseOptions = parseForSelect(result.data);
    return parseOptions;
  };
  const loadOptionsForMulti = useCallback(
    async (inputValue: string, callback: (options: any) => void) => {
      let getParams: ParamsType = { by_open: true, record_limit: 50, number: inputValue };
      if (modelData) {
        getParams.vendor = modelData.is_not_single_vendor_po ? -1 : modelData.vendor_id;
        if (!currentUser.company.invoice?.do_not_filter_po_links_by_subsidiary && modelData.subsidiary_id) {
          getParams.subsidiary_id = modelData.subsidiary_id;
        }
        if (modelData.currency_code && !invoiceCommonSvc.allowMultiCurrencyPO(currentUser)) {
          getParams.currency_code = modelData.currency_code;
        }
      }

      if (getParams.number) {
        return await getPurchaseOrders(getParams);
      }
    },
    [modelData],
  );

  if (isMulti)
    return (
      <>
        <MultiPoPicker modelData={modelData} loadOptions={loadOptionsForMulti} {...rest} />
      </>
    );
  else
    return (
      <>
        <SinglePOPicker modelData={modelData} {...rest} />
      </>
    );
};

export default PurchaseOrderPicker;
