import BusinessUnitPicker from "components/admin/pickers/reactHookFormPickers/businessUnitPicker";
import DepartmentPicker from "components/admin/pickers/reactHookFormPickers/departmentPicker";
import GrantPicker from "components/admin/pickers/reactHookFormPickers/grantPicker";
import LocationPicker from "components/admin/pickers/reactHookFormPickers/locationPicker";
import SecondaryCategoryPicker from "components/admin/pickers/reactHookFormPickers/secondaryCategoryPicker";
import AmortizationSchedule from "components/AmortizationSchedule/AmortizationSchedule";
import Panel from "components/common/panel/panel";
import { InputField, RenderInputGroupField, TextAreaField } from "components/forms/hookFormFields";
import _, { cloneDeep } from "lodash";
import React, { memo, useCallback, useMemo } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { useFormContext, useWatch } from "react-hook-form";
import { useTypedSelector } from "reducers";
import { selectCurrentUser } from "reducers/userReducers";
import { useCategoryService } from "services/admin/category/useCategorySvc";
import adminCommonSvc from "services/admin/commonSvc";
import invoiceCommonSvc from "services/admin/invoices/invoiceCommonSvc";
import { IDType } from "services/common/types/common.type";
import { IUser } from "services/common/user/userTypes";
import { roundUpAmount } from "services/vp/services/roundUpAmount";
import useAdminCompanyCurrencyCode from "../hooks/useAdminCompanyCurrencyCode";
import { AbstractListPickerTypes } from "../pickers/reactHookFormPickers/abstractListPicker/abstractListPickerTypes";
import AccountPicker from "../pickers/reactHookFormPickers/accountPicker";
import BudgetPicker from "../pickers/reactHookFormPickers/budgetPicker";
import CategoryPicker from "../pickers/reactHookFormPickers/categoryPicker";
import { CategoryPickerTypes } from "../pickers/reactHookFormPickers/categoryPicker/categoryPickerType";
import EmployeePicker from "../pickers/reactHookFormPickers/employeePicker";
import EventCodePicker from "../pickers/reactHookFormPickers/eventCodePicker";
import ForSubsidiaryPicker from "../pickers/reactHookFormPickers/forSubsidiaryPicker";
import InterCompanyPicker from "../pickers/reactHookFormPickers/interCompanyPickers";
import ProjectPicker from "../pickers/reactHookFormPickers/projectPicker";
import { ProjectPickerTypes } from "../pickers/reactHookFormPickers/projectPicker/projectPickerTypes";
import RebatePicker from "../pickers/reactHookFormPickers/rebatePicker";
import SponsorshipPicker from "../pickers/reactHookFormPickers/sponsorshipPicker";
import TaxCodePicker from "../pickers/reactHookFormPickers/taxCodePicker/index2";
import { TaxCodePickerTypes } from "../pickers/reactHookFormPickers/taxCodePicker/taxCodePickerTypes";
import WhTaxPicker from "../pickers/reactHookFormPickers/whTaxPicker";

type TMetadata = {
  id?: IDType;
  metadata_configuration_id?: number;
  metadata_field_id?: IDType;
  name: string;
  value: string;
};
type BudgetItemLink = {
  id?: number;
};
const AccountEntry = ({
  index,
  accountType,
  accountEntries,
  modelDataName,
  commonFieldsCondition,
  isPoRequest,
  subsidiaryId,
  noVendor,
  accountGroupName,
  paymentType,
  readonly,
  disabled,
}: {
  index: number;
  accountType: string;
  accountEntries: any;
  modelDataName: string;
  commonFieldsCondition: Record<string, any>;
  isPoRequest: boolean;
  subsidiaryId: IDType;
  noVendor: boolean;
  accountGroupName: string;
  paymentType: string;
  readonly: boolean;
  disabled: boolean;
}) => {
  const { getValues, setValue } = useFormContext();
  const currentUser: IUser = useTypedSelector(selectCurrentUser);
  const { mapCategoryAccount } = useCategoryService();
  const { companyCurrencies } = useAdminCompanyCurrencyCode();
  const [
    isHideProject,
    accountEntryIsProjectRequired,
    isLocationRequired,
    isDepartmentRequired,
    isGrantRequired,
    isDepartmentReadOnly,
    isLocationReadOnly,
  ] = useWatch({
    name: [
      `${accountEntries}.${index}.is_hide_project`,
      `${accountEntries}.${index}.is_project_required`,
      `${accountEntries}.${index}.is_location_required`,
      `${accountEntries}.${index}.is_department_required`,
      `${accountEntries}.${index}.is_grant_required`,
      `${accountEntries}.${index}.is_department_read_only`,
      `${accountEntries}.${index}.is_location_read_only`,
    ],
  });

  const currencyCode = useWatch({ name: `${modelDataName}.currency_code` });
  const isRequsetPo = useWatch({ name: `${modelDataName}.is_request_PO` });
  const modelDataStatus = useWatch({ name: `${modelDataName}.status` });

  const accountEntryAccountId = useWatch({ name: `${accountEntries}.${index}.account_id` });
  const watchedDestroy = useWatch({ name: `${accountEntries}.${index}._destroy` });
  const projectId = useWatch({ name: `${accountEntries}.${index}.project_id` });

  const isDepartmentDisabled = useMemo(() => {
    const objDepartment = {
      is_department_read_only: isDepartmentReadOnly,
      project_id: projectId,
    };
    return adminCommonSvc.isDepartmentDisabled(objDepartment);
  }, [isDepartmentReadOnly, projectId]);

  const isLocationDisabled = useMemo(() => {
    const objLocation = {
      is_location_read_only: isLocationReadOnly,
      project_id: projectId,
    };
    return adminCommonSvc.isDepartmentDisabled(objLocation);
  }, [isLocationReadOnly, projectId]);

  const handleCategoryCallback = useCallback(
    (category?: CategoryPickerTypes.TCategoryPickerOption | null) => {
      mapCategoryAccount(category, `${accountEntries}.${index}`);
    },
    [accountEntries, index],
  );

  const mapAccountRequiredFields = useCallback((account: any) => {
    // TODO: add prper types here
    const accountEntry: any = getValues(`${accountEntries}.${index}`);

    invoiceCommonSvc.mapAccountRequiredFields(
      account,
      currentUser,
      accountEntry,
      paymentType == "CREDIT" ? "credit_memo" : modelDataName,
    );
    setValue(`${accountEntries}.${index}`, accountEntry);
  }, []);

  const calculateRebate = useCallback(
    (account: any) => {
      let accountEntry: any = getValues(`${accountEntries}.${index}`);
      accountEntry = invoiceCommonSvc.calculateExpenseLineRebate(accountEntry, currentUser);
      invoiceCommonSvc.updateSubAmountWithRebate(accountEntry);
      setValue(`${accountEntries}.${index}`, accountEntry);
      calculateExpenseTax(accountEntry.tax, true);
    },
    [accountEntries, currentUser, getValues, index],
  );

  const handlePercentChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const percent = Number(e.target.value);
      setValue(`${accountEntries}.${index}.percent`, percent);
      const amount = getValues(`${modelDataName}.amount`);
      setValue(`${accountEntries}.${index}.amount`, adminCommonSvc.roundUpAmount((amount * Number(percent)) / 100, 4));
    },
    [accountEntries, getValues, index, modelDataName, setValue],
  );

  const calculateExpenseLineAmount = useCallback((accountEntry) => {
    const modelData: any = getValues(modelDataName);
    let tax = parseFloat(accountEntry.tax);
    let subAmount = parseFloat(accountEntry.sub_amount);
    accountEntry.tax = !isNaN(tax) ? tax : 0;
    accountEntry.sub_amount = !isNaN(subAmount) ? subAmount : 0;

    if (modelData.is_account_used_tax) {
      accountEntry.amount = adminCommonSvc.roundUpAmount(accountEntry.sub_amount, null, currentUser);
    } else {
      invoiceCommonSvc.makeWhTaxNegative(accountEntry, currentUser);
      accountEntry.amount = adminCommonSvc.roundUpAmount(
        parseFloat(accountEntry.tax) + parseFloat(accountEntry.sub_amount),
        null,
        currentUser,
      );
      if (accountEntry.wh_tax_amount) {
        accountEntry.amount = accountEntry.amount + accountEntry.wh_tax_amount;
      }
    }

    if (accountEntry.rebate_amount) {
      accountEntry.amount = accountEntry.amount + accountEntry.rebate_amount;
    }

    setValue(`${accountEntries}.${index}`, accountEntry);

    // if (invoiceCommonSvc.isActiveHeaderTax(modelData, currentUser)) {
    //   if (commonFieldsCondition.isInvoice) {
    //     invoiceCommonSvc.calculateHeaderLevelTax(modelData);
    //   } else if (scope.is_credit_memo()) {
    //     invoiceCommonSvc.calculate_header_level_credit_memo_tax(scope.modelData);
    //   }
    // }
  }, []);

  const calculateExpenseTax = useCallback(
    (
      taxCode?: AbstractListPickerTypes.TPickerValue<TaxCodePickerTypes.TTaxCodeOption>,
      isRebateCalculated?: boolean,
    ) => {
      const modelData: any = getValues(modelDataName);
      let accountObj = getValues(`${accountEntries}.${index}`);
      if (!invoiceCommonSvc.isActiveHeaderTax(modelData, currentUser)) {
        let amount =
          _.isPlainObject(accountObj.tax_code) && accountObj.tax_code?.rate && accountObj.tax_code.rate > 0
            ? (accountObj.sub_amount * accountObj.tax_code.rate) / 100
            : 0;
        accountObj.tax = isNaN(amount) ? null : adminCommonSvc.roundUpAmount(amount, null, currentUser);
      }
      if (!isRebateCalculated) {
        accountObj = invoiceCommonSvc.calculateExpenseLineRebate(accountObj, currentUser);
      }
      calculateExpenseLineAmount(accountObj);
    },
    [accountEntries, calculateExpenseLineAmount, currentUser, getValues, index, modelDataName],
  );

  const handleTaxChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const taxAmount = e.target.value ? Number(e.target.value) : null;
      setValue(`${accountEntries}.${index}.tax`, taxAmount);
      const accountEntry = getValues(`${accountEntries}.${index}`);
      calculateExpenseLineAmount(accountEntry);
    },
    [accountEntries, calculateExpenseLineAmount, getValues, index, setValue],
  );

  const manageSubAmount = useCallback(
    (value: any) => {
      setValue(`${accountEntries}.${index}.actual_sub_amount`, value);
      calculateExpenseTax();
    },
    [accountEntries, calculateExpenseTax, index, setValue],
  );

  const handleAmountChange = useCallback((value: any) => {
    const headerAmount = getValues(`${modelDataName}.amount`);
    setValue(`${accountEntries}.${index}.percent`, roundUpAmount(value / headerAmount) * 100);
  }, []);

  const showLocation = useMemo(() => {
    if (isPoRequest && currentUser?.company?.po_request_hide_expense_line_location) {
      return false;
    } else if (
      modelDataName === "purchase_order" &&
      currentUser?.company?.purchase_order?.expenses?.location?.is_hide
    ) {
      return false;
    }
    return true;
  }, [
    currentUser?.company?.po_request_hide_expense_line_location,
    currentUser?.company?.purchase_order?.expenses?.location?.is_hide,
    isPoRequest,
    modelDataName,
  ]);

  const isDepartmentRequiredOnPoRequestExpenseLine = useMemo(() => {
    return currentUser?.company?.po_request?.expenses?.department?.is_required;
  }, [currentUser?.company?.po_request?.expenses?.department?.is_required]);

  const isDepartmentOnAdminPoExpenseLine = useMemo(() => {
    return currentUser?.company?.purchase_order?.expenses?.department?.is_required;
  }, [currentUser?.company?.purchase_order?.expenses?.department?.is_required]);

  const checkIfDepartmentIsRequired = useMemo(() => {
    return (
      (modelDataName === "purchase_order" &&
        modelDataStatus !== "DRAFT" &&
        ((isRequsetPo && isDepartmentRequiredOnPoRequestExpenseLine) ||
          (!isRequsetPo && isDepartmentOnAdminPoExpenseLine))) ||
      currentUser.company.has_account_structures
    );
  }, [
    isDepartmentOnAdminPoExpenseLine,
    isDepartmentRequiredOnPoRequestExpenseLine,
    currentUser.company.has_account_structures,
    isRequsetPo,
    modelDataName,
    modelDataStatus,
  ]);

  const isDepartmentFieldRequired = useMemo(() => {
    if (isDepartmentRequired) {
      return true;
    } else if (modelDataName === "purchase_order") {
      return checkIfDepartmentIsRequired;
    }
    //todo add condition for vacrd
    //todo add condition for credit memo
    return false;
  }, [checkIfDepartmentIsRequired, isDepartmentRequired, modelDataName]);

  const isLocationRequiredOnExpenseLevel = useMemo(() => {
    if (isLocationRequired) {
      return true;
    } else if (modelDataName === "purchase_order" && currentUser?.company?.po_expense_location_required) {
      return true;
    }
    //todo add condition for credit accounts
    //todo add condition for vcard
    return false;
  }, [currentUser?.company?.po_expense_location_required, isLocationRequired, modelDataName]);

  const showDepartment = useMemo(() => {
    if (isPoRequest && currentUser?.company?.po_request_hide_expense_line_department) {
      return false;
    } else if (
      modelDataName === "purchase_order" &&
      !isPoRequest &&
      currentUser?.company?.purchase_order?.expenses?.department?.is_hide
    ) {
      return false;
    }
    return true;
  }, [
    currentUser?.company?.po_request_hide_expense_line_department,
    currentUser?.company?.purchase_order?.expenses?.department?.is_hide,
    isPoRequest,
    modelDataName,
  ]);

  const isMemoRequired = useMemo(() => {
    return modelDataName === "purchase_order" && currentUser.company.purchase_order?.expenses?.memo?.is_required;
  }, [currentUser.company.purchase_order, modelDataName]);

  const showPoExpenseLineMemo = useMemo(() => {
    return isPoRequest && !currentUser.company.po_request_hide_expense_line_memo;
  }, [currentUser.company.po_request_hide_expense_line_memo, isPoRequest]);

  const showAmortization = useMemo(() => {
    if (
      (paymentType === "CREDIT" && accountType === "Debit Accounts") ||
      modelDataName === "purchase_order" ||
      currentUser.company.amortization_scheduled === false
    ) {
      return false;
    }

    return true;
  }, [paymentType, accountType, modelDataName, currentUser.company.amortization_scheduled]);

  const isIntacctErp = useMemo(() => {
    return currentUser.company?.integration?.system_name === "Intacct";
  }, [currentUser.company?.integration]);

  const showBusinessUnit = useMemo(() => {
    if (currentUser?.company?.has_business_units) {
      return modelDataName === "purchase_order" &&
        currentUser?.company?.purchase_order?.expenses?.business_unit?.is_hide
        ? false
        : true;
    }
    return true;
  }, [
    currentUser?.company?.has_business_units,
    currentUser?.company?.purchase_order?.expenses?.business_unit?.is_hide,
    modelDataName,
  ]);

  const isBusinessUnitRequired = useMemo(() => {
    return (
      (modelDataName === "purchase_order" &&
        currentUser?.company?.purchase_order?.expenses?.business_unit?.is_required) ||
      currentUser?.company?.virtual_card?.require_business_unit
    );
  }, [
    currentUser.company?.purchase_order?.expenses?.business_unit?.is_required,
    currentUser.company?.virtual_card?.require_business_unit,
    modelDataName,
  ]);

  const showProject = useMemo(() => {
    if (modelDataName === "payment" && accountType === "Debit Accounts") {
      return false;
    } else if (modelDataName === "purchase_order" && currentUser.company.purchase_order?.expenses?.project?.is_hide) {
      return false;
    }
    return true;
  }, [currentUser.company.purchase_order?.expenses?.project?.is_hide, accountType, modelDataName]);

  const showGrant = useMemo(() => {
    if (modelDataName === "payment" && paymentType === "CREDIT") {
      return accountType === "Credit Accounts" ? true : false;
    } else if (modelDataName === "payment") {
      return false;
    }
    return true;
  }, [accountType, modelDataName, paymentType]);

  const showEmployeeField = useMemo(() => {
    if (modelDataName === "purchase_order" && currentUser.company?.purchase_order?.show_employee_items_and_expenses) {
      return true;
    }
    //todo  condition  for credits
    return false;
  }, [currentUser?.company?.purchase_order?.show_employee_items_and_expenses, modelDataName]);

  const isProjectRequired = useMemo(() => {
    const company = currentUser.company;
    // TODO: add prper types here
    const modelData: any = getValues(modelDataName);
    return (
      accountEntryIsProjectRequired ||
      company?.virtual_card?.require_project ||
      (modelDataName === "purchase_order" && company.purchase_order?.expenses?.project?.is_required) ||
      (modelDataName === "payment" &&
        modelData.payment_type === "CREDIT" &&
        company.credit_memo?.expenses?.project?.is_required)
    );
  }, [accountEntryIsProjectRequired, currentUser.company, getValues, modelDataName]);

  const inheritLinkedDataByProject = useCallback(
    (project: AbstractListPickerTypes.TPickerValue<ProjectPickerTypes.TProjectPickerOption>) => {
      const lineObject = getValues(`${accountEntries}.${index}`);
      if (project && lineObject && _.isPlainObject(project) && _.isPlainObject(lineObject)) {
        //to check project has mapping of accounts or not
        if (project.has_accounts || lineObject?.project?.has_accounts) {
          lineObject.project_id = project.id;
          lineObject.project = project;
          lineObject.only_show_mapped_account = true;

          setValue(`${accountEntries}.${index}`, lineObject);
        }
      }
    },
    [accountEntries, getValues, index, setValue],
  );

  const getTotalDropDown = useMemo(() => {
    var total = 0;
    total += currentUser.company.has_locations ? 1 : 0;
    total += currentUser.company.has_departments ? 1 : 0;
    total += currentUser.company.has_business_units ? 1 : 0;
    total += currentUser.company.has_projects ? 1 : 0;
    total += currentUser.company.has_categories ? 1 : 0;
    total += currentUser.company.has_grants ? 1 : 0;
    return total;
  }, [
    currentUser.company.has_business_units,
    currentUser.company.has_categories,
    currentUser.company.has_departments,
    currentUser.company.has_grants,
    currentUser.company.has_locations,
    currentUser.company.has_projects,
  ]);

  const getDropdownColumnClass = useMemo(() => {
    let cls = "col-md-3";
    let c = getTotalDropDown;
    if (c === 4) {
      cls = "col-md-3";
    } else if (c === 3 || c === 5) {
      cls = "col-md-4";
    } else if (c === 2) {
      cls = "col-md-6";
    } else if (c === 1) {
      cls = "col-md-12";
    }
    return cls;
  }, [getTotalDropDown]);
  const removeMetadata = (metadata: TMetadata[]) => {
    const cloneMetadata = cloneDeep(metadata);
    cloneMetadata.forEach((feild: TMetadata) => {
      delete feild?.id;
    });
    return cloneMetadata;
  };

  const duplicateAccountEntry = (index: number) => {
    let accountArray = getValues(`${accountEntries}`);
    let newAccountObj = cloneDeep(getValues(`${accountEntries}.${index}`));
    newAccountObj.id = undefined;
    newAccountObj.is_expense_received = undefined;
    newAccountObj.is_matched = undefined;
    newAccountObj.rebate_link = undefined;

    if (newAccountObj.metadata_links_attributes) {
      newAccountObj.metadata_links_attributes = removeMetadata(newAccountObj.metadata_links_attributes);
    } else if (newAccountObj.metadata_links) {
      newAccountObj.metadata_links_attributes = removeMetadata(newAccountObj.metadata_links);
    }
    // TODO-Rhutik: Test this feature after budget picker implementation
    if (_.isArray(newAccountObj?.budget_item_links) && newAccountObj?.budget_item_links.length > 0) {
      newAccountObj.budget_item_links.forEach((link: BudgetItemLink) => (link.id ? delete link.id : null));
    }
    let updatedAccountEntries = accountArray;
    updatedAccountEntries.push(newAccountObj);
    setValue(`${accountEntries}`, updatedAccountEntries);
  };

  const deleteAccountEntry = (index: number) => {
    setValue(`${accountEntries}.${index}._destroy`, 1);
  };
  const isPaymentDebit = useMemo(() => {
    const modelData = getValues(modelDataName);
    return modelData?.payment_type === "DEBIT";
  }, [getValues, modelDataName]);

  if (watchedDestroy == 1) {
    return null;
  } else {
    return (
      <Row>
        <Col md="12">
          <Panel
            header={
              <Row>
                <Col sm={11}>{accountType}</Col>
                <Col sm={1} className="px-pl-0">
                  <Button
                    onClick={() => duplicateAccountEntry(index)}
                    className="bg-transparent border-0 m-0 p-0 justify-content-end"
                  >
                    <i className="icon duplicate m-0" />
                  </Button>
                  <Button
                    onClick={() => deleteAccountEntry(index)}
                    className="bg-transparent border-0 m-0 p-0 px-pl-15"
                  >
                    <i className="icon icon-delete m-0" />
                  </Button>
                </Col>
              </Row>
            }
          >
            {/* ALl pickers will come here */}
            <Container fluid>
              <Row>
                {/* //TODO-SANKET : ADD VENDOR BY NAME */}
                {/* {    <div className="mb-3">
          <VendorPicker
            name={`${accountEntries}.${index}.vendor_name`}
            label={"Vendor"}
            excludeStatuses="INACTIVE"
            callBack={(selectedVendor) => handleVendorChange(selectedVendor)}
          />
        </div>} */}

                {commonFieldsCondition.showCategoryAtFirst && (
                  <Col sm={noVendor || commonFieldsCondition.enableTax ? 3 : 6}>
                    <CategoryPicker
                      name={`${accountEntries}.${index}.category_id`}
                      instanceId={`${accountEntries}.${index}.category`}
                      required={currentUser?.company?.po_request?.expenses?.category?.is_required}
                      label="Category"
                      modelData={`${accountEntries}.${index}`}
                      parentObj={modelDataName}
                      callBack={handleCategoryCallback}
                      disabled={disabled}
                    />
                  </Col>
                )}
                {!commonFieldsCondition.showCategoryAtFirst && (
                  <Col sm={noVendor || commonFieldsCondition.enableTax ? 2 : 6}>
                    <AccountPicker
                      name={`${accountEntries}.${index}.account_id`}
                      required
                      label="Account"
                      modelData={`${accountEntries}.${index}`}
                      parentObj={modelDataName}
                      accountGroupName={accountGroupName}
                      callBack={mapAccountRequiredFields}
                    />
                  </Col>
                )}
                {commonFieldsCondition.showPercentField && !commonFieldsCondition.enableTaxAndRebate && (
                  <Col sm="1">
                    <InputField
                      name={`${accountEntries}.${index}.percent`}
                      type="number"
                      label="Percent"
                      readOnly={readonly}
                      disabled={
                        disabled
                        //TODO-SANKET-VCARD vCardCommonSvc.permit_edits_to_card_purchase_accounts
                        // || !permitted_to_edit_purchases()
                      }
                      onChange={handlePercentChange}
                    />
                  </Col>
                )}
                {commonFieldsCondition.enableTax && (
                  <Col sm="2">
                    <TaxCodePicker
                      instanceId={`${accountEntries}.${index}.tax_code_picker`}
                      name={`${accountEntries}.${index}.tax_id`}
                      label="Tax Code"
                      objectPropertyKey={`${accountEntries}.${index}.tax_code`} // make sure that taxcode id should store in tax_id and object should store on tax_code
                      modelData={`${accountEntries}.${index}`}
                      parentObj={modelDataName}
                      subsidiaryId={subsidiaryId}
                      disabled={
                        disabled
                        //TODO-SANKET-VCARD vCardCommonSvc.permit_edits_to_card_purchase_accounts
                        // || !permitted_to_edit_purchases()
                      }
                      //todod-sanket-make field required using new function
                      // required={}
                      callBack={calculateExpenseTax}
                    />
                  </Col>
                )}
                {commonFieldsCondition.enableTax && commonFieldsCondition.showTaxAmount && (
                  <Col sm="1">
                    <InputField
                      name={`${accountEntries}.${index}.tax`}
                      type="number"
                      label="Tax"
                      readOnly={!currentUser.company.enable_to_enter_tax_amount}
                      onChange={handleTaxChange}
                    />
                  </Col>
                )}
                {commonFieldsCondition.enableTaxAndRebate && (
                  <Col sm={"2"}>
                    <RenderInputGroupField
                      name={`${accountEntries}.${index}.sub_amount`}
                      type="number"
                      label="Sub Amount"
                      required={accountEntryAccountId ? true : false}
                      inputGroupText={adminCommonSvc.getSymbolFromIsoCode(currencyCode, companyCurrencies)}
                      onChangeCallback={manageSubAmount}
                    />
                  </Col>
                )}
                <Col sm="2">
                  <RenderInputGroupField
                    label={"Amount"}
                    name={`${accountEntries}.${index}.amount`}
                    type="number"
                    disabled={
                      disabled || commonFieldsCondition.enableTaxAndRebate
                      //TODO-SANKET-VCARD vCardCommonSvc.permit_edits_to_card_purchase_accounts
                      // || !permitted_to_edit_purchases()
                    }
                    onChangeCallback={handleAmountChange}
                    inputGroupText={adminCommonSvc.getSymbolFromIsoCode(currencyCode, companyCurrencies)}
                  />
                </Col>
              </Row>
              <Row>
                {currentUser?.company?.has_wh_taxes && commonFieldsCondition.isTaxEnableToVendorCredit && (
                  <Col sm="3">
                    <WhTaxPicker
                      instanceId={`${accountEntries}.${index}.wh_tax_picker`}
                      name={`${accountEntries}.${index}.wh_tax_id`}
                      label="WH Tax Code"
                      objectPropertyKey={`${accountEntries}.${index}.wh_tax_code`}
                      modelData={`${accountEntries}.${index}`}
                      //  callBack={calculateWhTax}
                    />
                  </Col>
                )}
                {commonFieldsCondition.showRebate && (
                  <Col sm="3">
                    <RebatePicker
                      instanceId={`${accountEntries}.${index}`}
                      name={`${accountEntries}.${index}.rebate_id`}
                      label="Rebate"
                      objectPropertyKey={`${accountEntries}.${index}.rebate`}
                      modelData={`${accountEntries}.${index}`}
                      callBack={calculateRebate}
                    />
                  </Col>
                )}
              </Row>
              <Row>
                {commonFieldsCondition.showCategoryAtFirst && (
                  <Col className={getDropdownColumnClass}>
                    <AccountPicker
                      name={`${accountEntries}.${index}.account_id`}
                      required
                      label="Account"
                      modelData={`${accountEntries}.${index}`}
                      parentObj={modelDataName}
                      accountGroupName={accountGroupName}
                      callBack={mapAccountRequiredFields}
                    />
                  </Col>
                )}

                {currentUser.company.has_departments && showDepartment && (
                  <Col className={getDropdownColumnClass}>
                    <DepartmentPicker
                      name={`${accountEntries}.${index}.department_id`}
                      label="Department"
                      instanceId={`account_entry_department_${index}`}
                      isRequestPo={isPoRequest}
                      disabled={isDepartmentDisabled}
                      required={isDepartmentFieldRequired}
                      modelData={`${accountEntries}.${index}`}
                      //todo create & add mapping function for callback
                    />
                  </Col>
                )}

                {currentUser.company.has_locations && showLocation && (
                  <Col className={getDropdownColumnClass}>
                    <LocationPicker
                      name={`${accountEntries}.${index}.location_id`}
                      label="Location"
                      modelDataName={`${accountEntries}.${index}`}
                      instanceId={`account_entry_location_${index}`}
                      required={isLocationRequiredOnExpenseLevel}
                      disabled={isLocationDisabled}
                      //todo create & add mapping function for callback
                    />
                  </Col>
                )}

                {showBusinessUnit && (
                  <Col className={getDropdownColumnClass}>
                    <BusinessUnitPicker
                      name={`${accountEntries}.${index}.business_unit_id`}
                      label="Business Unit"
                      modelDataName={`${accountEntries}.${index}`}
                      instanceId={`account_entry_bu_${index}`}
                      parentObj={modelDataName}
                      disabled={currentUser.company.readonly_business_unit_to_all}
                      required={isBusinessUnitRequired}
                    />
                  </Col>
                )}

                {currentUser.company.has_inter_companies && (
                  <Col sm="3">
                    <InterCompanyPicker
                      label="Inter Company"
                      name={`${accountEntries}.${index}.inter_company`}
                      modelData={`${accountEntries}.${index}`}
                      parentObj={modelDataName}
                    />
                  </Col>
                )}

                {currentUser.company.has_budgets && currentUser.company?.budget?.show_field_at === "line_level" && (
                  <BudgetPicker
                    instanceId={`account_entry_budgetId_${index}`}
                    name={`${accountEntries}.${index}.budget_id`}
                    label="Budget"
                    modelDataName={`${accountEntries}.${index}`}
                    parentObjName={modelDataName}
                    // Enable the 'isMulti' option if the company configuration allows users to select multiple Budget Lines through the "Allow users to select multiple Budget Lines" setting.
                    isMulti={!!currentUser.company?.budget?.allow_to_select_budget_lines}
                  />
                )}

                {showProject && currentUser.company.has_projects && !isHideProject && (
                  <Col className={getDropdownColumnClass}>
                    <ProjectPicker
                      instanceId={`${accountEntries}.${index}.project`}
                      label="Project"
                      name={`${accountEntries}.${index}.project_id`}
                      required={isProjectRequired}
                      modelData={`${accountEntries}.${index}`}
                      isPoRequest={isPoRequest}
                      parentObj={modelDataName}
                      callBack={inheritLinkedDataByProject}
                      // disabled={} TODO: add disabled code here
                    />
                  </Col>
                )}

                {currentUser.company.has_grants && showGrant && (
                  <Col className={getDropdownColumnClass}>
                    <GrantPicker
                      name={`${accountEntries}.${index}.grant_id`}
                      instanceId={`account_entry_grant_id_${index}`}
                      modelDataName={`${accountEntries}.${index}`}
                      required={isGrantRequired}
                      label="Grant"
                    />
                  </Col>
                )}

                {currentUser.company.has_event_codes && (
                  <Col className={getDropdownColumnClass}>
                    <EventCodePicker
                      instanceId={`account_entry_event_code_id_${index}`}
                      label="Event Code"
                      name={`${accountEntries}.${index}.event_code_id`}
                    />
                  </Col>
                )}
                {currentUser.company.has_projects && currentUser.company.allow_for_project && !isPaymentDebit && (
                  <>
                    <Col className={getDropdownColumnClass}>
                      <ProjectPicker
                        instanceId={`${accountEntries}.${index}.for_project`}
                        label="For Project"
                        name={`${accountEntries}.${index}.for_project_id`}
                        modelData={`${accountEntries}.${index}`}
                        placeholder="-- For Project --"
                        isForProject
                        // disabled={} TODO: add disabled code here
                      />
                    </Col>
                  </>
                )}

                {currentUser.company.has_sponsorships && (
                  <Col sm="3">
                    <SponsorshipPicker
                      label="Sponsorship"
                      name={`${accountEntries}.${index}.sponsorship_id`}
                      instanceId={`${accountEntries}.${index}.sponsorship`}
                    />
                  </Col>
                )}

                {currentUser.company.has_business_units && currentUser.company.allow_for_business_unit && (
                  <Col className={getDropdownColumnClass}>
                    <BusinessUnitPicker
                      name={`${accountEntries}.${index}.for_business_unit_id`}
                      label="For Business Unit"
                      instanceId={`account_entry_for_business_unit_id_${index}`}
                    />
                  </Col>
                )}

                {currentUser.company.allow_for_location && !isPaymentDebit && (
                  <Col className={getDropdownColumnClass}>
                    <LocationPicker
                      name={`${accountEntries}.${index}.for_location_id`}
                      instanceId={`account_entry_for_location_id_${index}`}
                      label="For Location"
                    />
                  </Col>
                )}

                {currentUser.company.has_for_subsidiaries && (
                  <Col className={getDropdownColumnClass}>
                    <ForSubsidiaryPicker
                      name={`${accountEntries}.${index}.for_subsidiary_id`}
                      instanceId={`account_entry_for_subsidiary_id_${index}`}
                      label="For Subsidiary"
                    />
                  </Col>
                )}

                {currentUser.company.has_secondary_categories && (
                  <Col className={getDropdownColumnClass}>
                    <SecondaryCategoryPicker
                      name={`${accountEntries}.${index}.secondary_category_id`}
                      label={"Secondary Category"}
                      instanceId={`account_entry_secondary_category_id_${index}`}
                    />
                  </Col>
                )}

                {showEmployeeField && (
                  <Col className={getDropdownColumnClass}>
                    <EmployeePicker
                      name={`${accountEntries}.${index}.employee_id`}
                      instanceId={`${accountEntries}.${index}.employee`}
                      label="Employee"
                      modelDataName={`${accountEntries}.${index}`}
                    />
                  </Col>
                )}

                {showAmortization && (
                  <AmortizationSchedule
                    modelDataName={`${accountEntries}.${index}`}
                    isIntacctErp={isIntacctErp}
                    moduleName={modelDataName}
                  />
                )}

                {showPoExpenseLineMemo && (
                  <Col sm="12">
                    <TextAreaField
                      name={`${accountEntries}.${index}.memo`}
                      label="Memo"
                      disabled={disabled}
                      required={isMemoRequired}
                      rows={2}
                    />
                  </Col>
                )}
              </Row>
              <Row />
            </Container>
            {/* -------------------------- */}
          </Panel>
        </Col>
      </Row>
    );
  }
};

export default memo(AccountEntry);
