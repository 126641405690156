import AccountPicker from "components/admin/pickers/reactHookFormPickers/accountPicker";
import BudgetPicker from "components/admin/pickers/reactHookFormPickers/budgetPicker";
import BusinessUnitPicker from "components/admin/pickers/reactHookFormPickers/businessUnitPicker";
import CountryPicker from "components/admin/pickers/reactHookFormPickers/countryPicker";
import DepartmentPicker from "components/admin/pickers/reactHookFormPickers/departmentPicker";
import EmployeePicker from "components/admin/pickers/reactHookFormPickers/employeePicker";
import EventCodePicker from "components/admin/pickers/reactHookFormPickers/eventCodePicker";
import ForSubsidiaryPicker from "components/admin/pickers/reactHookFormPickers/forSubsidiaryPicker";
import GrantPicker from "components/admin/pickers/reactHookFormPickers/grantPicker";
import InterCompanyPicker from "components/admin/pickers/reactHookFormPickers/interCompanyPickers";
import LocationPicker from "components/admin/pickers/reactHookFormPickers/locationPicker";
import ProductItemPicker from "components/admin/pickers/reactHookFormPickers/productItemPicker";
import { ProductItemPickerType } from "components/admin/pickers/reactHookFormPickers/productItemPicker/productItemPickerTypes";
import ProjectPicker from "components/admin/pickers/reactHookFormPickers/projectPicker";
import RebatePicker from "components/admin/pickers/reactHookFormPickers/rebatePicker";
import SecondaryCategoryPicker from "components/admin/pickers/reactHookFormPickers/secondaryCategoryPicker";
import TaxCodePicker from "components/admin/pickers/reactHookFormPickers/taxCodePicker/index2";
import TaxSchedulePicker from "components/admin/pickers/reactHookFormPickers/taxSchedule";
import UnitPicker from "components/admin/pickers/reactHookFormPickers/unitPicker";
import WarehousePicker from "components/admin/pickers/reactHookFormPickers/warehousePicker/warehousePicker";
import { WarehousePickerTypes } from "components/admin/pickers/reactHookFormPickers/warehousePicker/warehousePickerTypes";
import { item_types } from "components/app.svc.Lookup";
import Panel from "components/common/panel/panel";
import { InputField, Select } from "components/forms/hookFormFields";
import _, { cloneDeep } from "lodash";
import React, { memo, useCallback, useEffect, useMemo } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { useFormContext, useWatch } from "react-hook-form";
import { useTypedSelector } from "reducers";
import { selectCurrentUser } from "reducers/userReducers";
import adminCommonSvc from "services/admin/commonSvc";
import invoiceCommonSvc from "services/admin/invoices/invoiceCommonSvc";
import PoCommonSvc from "services/admin/purchaseOrders/poCommonSvc";
import { PORequestFormTypes } from "services/admin/purchaseOrders/poRequestFormTypes";
import { PurchaseOrderType } from "services/admin/purchaseOrders/purchaseOrderType";
import { IUser } from "services/common/user/userTypes";
import styles from "../../purchaseOrders.module.css";

type TPOLineItem = {
  index: number;
  lineKey: string;
  noVendor: boolean;
  append: any;
};

const POLineItem = ({ index, lineKey, noVendor, append }: TPOLineItem) => {
  const { control, getValues, setValue } = useFormContext<PORequestFormTypes.TForm>();
  const currentUser: IUser = useTypedSelector(selectCurrentUser);
  const poCommonSvc = PoCommonSvc.getInstance(currentUser);
  const [poSubsidiaryId, isRequestPO] = useWatch({
    control: control,
    name: [`purchase_order.subsidiary_id`, "purchase_order.is_request_PO"],
  });

  // all watch related to po item
  const [itemDestroy, isRequestNewItem, poItemIsPunchout, isDepartmentRequired] = useWatch({
    control,
    name: [
      `purchase_order.po_items.${index}._destroy`,
      `purchase_order.po_items.${index}.is_request_new_item`,
      `purchase_order.po_items.${index}.is_punchout_item`,
      `purchase_order.po_items.${index}.is_department_required`,
    ],
  });

  const [tax, rebateAmount, lineAmount, projectId, isDepartmentReadOnly] = useWatch({
    name: [
      `purchase_order.po_items.${index}.tax`,
      `purchase_order.po_items.${index}.rebate_amount`,
      `purchase_order.po_items.${index}.amount`,
      `purchase_order.po_items.${index}.project_id`,
      `purchase_order.po_items.${index}.is_department_read_only`,
    ],
  });

  // use to pass condition for project exists
  const isProjectExist = useMemo(() => {
    return currentUser.company.has_projects && !currentUser.company.po_request_hide_item_line_project;
  }, [currentUser.company.has_projects, currentUser.company.po_request_hide_item_line_project]);

  const poItemIsProjectHide = useWatch({ name: `purchase_order.po_items.${index}.is_hide_project` });
  const poItemIsProjectRequired = useWatch({ name: `purchase_order.po_items.${index}.is_project_required` });

  const isProjectRequired = useMemo(() => {
    return poItemIsProjectRequired || currentUser?.company.purchase_order?.items?.project?.is_required;
  }, [currentUser?.company.purchase_order?.items?.project?.is_required, poItemIsProjectRequired]);

  const showDepartment = useMemo(() => {
    return currentUser.company.has_departments && !currentUser?.company.po_request_disabled_itemline_department;
  }, [currentUser.company.has_departments, currentUser.company.po_request_disabled_itemline_department]);

  const poStatus = useWatch({ name: "purchase_order.status" });
  const isDraftStatus = useMemo(() => {
    return poStatus === "DRAFT";
  }, [poStatus]);

  const defaultInit = useCallback(
    (poItemObj: PurchaseOrderType.PoItemType) => {
      const purchaseOrder = getValues("purchase_order");
      poItemObj.country = purchaseOrder?.vendor?.country
        ? purchaseOrder.vendor.country
        : currentUser.company.address
          ? currentUser.company.address.country
          : "";
      poItemObj.currency_code = purchaseOrder.currency_code;
    },
    [currentUser.company.address, getValues],
  );

  const calculateAmount = useCallback(
    (poItem: PurchaseOrderType.PoItemType) => {
      poItem.unit_price = poItem.unit_price ? poItem.unit_price : 0;
      let amt = poItem.qty && poItem.qty > 0 ? poItem.unit_price * poItem.qty : poItem.unit_price;
      return adminCommonSvc.roundUpAmount(Number(amt), null, currentUser);
    },
    [currentUser],
  );

  const allowToCreateNewItem = useCallback(
    (poItemObj: any) => {
      if (poItemObj.product_item_id === -1) {
        defaultInit(poItemObj);
        poItemObj.is_request_new_item = true;
      }
    },
    [defaultInit],
  );

  const isBusinessUnitRequired = useMemo(() => {
    return currentUser.company?.purchase_order?.items?.business_unit?.is_required && !isDraftStatus;
  }, [currentUser.company?.purchase_order?.items?.business_unit?.is_required, isDraftStatus]);

  const setItemPrice = useCallback(
    (poItemObj) => {
      allowToCreateNewItem(poItemObj);
      if (poItemObj.is_request_new_item) {
        poItemObj.item_type = "Non-Inventory";
      }

      if (poItemObj.product_item_id !== -1 && poItemObj?.product_item?.id) {
        poItemObj.unit_price = poItemObj.product_item.price;
        poItemObj.memo = poItemObj.product_item.description;
        poItemObj.qty = 1;
        poItemObj.product_item_id = poItemObj.product_item.id;
        poItemObj.description = "";
        poItemObj.amount = calculateAmount(poItemObj); // TODO: add amount calculation

        if (!poItemObj.product_item.taxable) {
          poItemObj.tax_id = null;
          poItemObj.tax_code = undefined;
          poItemObj.tax = null;
        }

        if (poItemObj.amount && poItemObj.tax_code?.rate) {
          poItemObj.tax = adminCommonSvc.roundUpAmount(
            Number((poItemObj.amount / 100) * poItemObj.tax_code.rate),
            null,
            currentUser,
          );
        } else {
          poItemObj.tax = null;
        }

        poItemObj.enable_account_dir = true;

        if (
          Array.isArray(poItemObj.product_item.invoice_debit_accounts) &&
          poItemObj.product_item.invoice_debit_accounts["0"]
        ) {
          poItemObj.account_id = poItemObj.product_item.invoice_debit_accounts[0].account_id;
        }
      }
    },
    [allowToCreateNewItem, calculateAmount, currentUser],
  );

  const locationMapping = useCallback(
    (selectedLocation) => {
      if (selectedLocation) {
        setValue(
          `purchase_order.po_items.${index}.is_location_map_to_project`,
          selectedLocation.is_location_map_to_project,
        );
        // TODO: Implement departmentLocationMapping.is_department_mapped_with_location(location, po_item);
      }
    },
    [index, setValue],
  );

  const isDepartmentFieldDisabled = useMemo(() => {
    return false;

    // TODO: Working here, check why useWatch is not working for isDepartmentReadOnly
  }, []);

  const selectProductItem = useCallback(
    (selected: ProductItemPickerType.TProductItemOption | undefined | null) => {
      const punchoutId = getValues("purchase_order.punchout_id");
      if (punchoutId) {
        setValue(`purchase_order.po_items.${index}.product_item_id`, selected?.id ?? null);
      } else {
        const poItem: PurchaseOrderType.PoItemType = getValues(`purchase_order.po_items.${index}`);
        if (selected) {
          invoiceCommonSvc.setProductItemMapping(selected, poItem, currentUser);
        }
        setItemPrice(poItem);

        const purchaseOrder = getValues("purchase_order");
        poCommonSvc.getVendorPartNum({ item: poItem, modalData: purchaseOrder });
        setValue(`purchase_order.po_items.${index}`, poItem);
      }
    },
    [currentUser, getValues, index, poCommonSvc, setItemPrice, setValue],
  );

  const productItemId = useWatch({ name: `purchase_order.po_items.${index}.product_item.id` });
  const productItemTaxable = useWatch({ name: `purchase_order.po_items.${index}.product_item.taxable` });
  const poItemAmount = useWatch({ name: `purchase_order.po_items.${index}.amount` });

  const isTaxDisabled = useMemo(() => {
    if (productItemId && !productItemTaxable) {
      return true;
    } else if (!(poItemAmount > 0)) {
      return true;
    }
    return false;
  }, [poItemAmount, productItemId, productItemTaxable]);

  const calculateTax = useCallback(() => {
    let poItemObj = getValues(`purchase_order.po_items.${index}`);
    poItemObj.qty = poItemObj.qty ? adminCommonSvc.roundUpAmount(poItemObj.qty, 5, currentUser) : 0;
    poItemObj.amount = calculateAmount(poItemObj);

    if (poItemObj.amount && poItemObj.tax_code?.rate) {
      poItemObj.tax = adminCommonSvc.roundUpAmount(
        Number((poItemObj.amount / 100) * poItemObj.tax_code.rate),
        null,
        currentUser,
      );
    } else if (!poItemObj.amount && !poItemObj.tax_code) {
      poItemObj.tax_code = undefined;
      poItemObj.tax_id = null;
      poItemObj.tax = null;
    }
    poItemObj = invoiceCommonSvc.calculateItemLineRebate(poItemObj, currentUser);
    setValue(`purchase_order.po_items.${index}`, poItemObj);
  }, [calculateAmount, currentUser, getValues, index, setValue]);

  const mapUnitCalculation = useCallback(
    (unit: any) => {
      let poItem = getValues(`purchase_order.po_items.${index}`);
      if (poItem.unit_id && _.isPlainObject(unit) && unit.id !== poItem.unit_id && poItem.unit_price) {
        let unit_price = (poItem.unit_price * unit.conversion_rate) / poItem.unit.conversion_rate;
        if (unit_price) {
          poItem.unit_price = adminCommonSvc.roundUpAmount(
            unit_price,
            adminCommonSvc.unitPriceDecimalLimit(currentUser),
            currentUser,
          );
          // need to update poItem as calculateTax function is again fetching from formState
          setValue(`purchase_order.po_items.${index}`, poItem);
          calculateTax();
        }
      }
      // after tax got calculated need to fetch new value so use getValues again
      poItem = getValues(`purchase_order.po_items.${index}`);
      poItem.unit_id = unit.id;
      poItem.unit = unit;
      setValue(`purchase_order.po_items.${index}`, poItem);
    },
    [calculateTax, currentUser, getValues, index, setValue],
  );

  const manageUnitPrice = useCallback(
    (e: any) => {
      let unitPrice = Number(e.target.value);
      unitPrice = adminCommonSvc.roundUpAmount(
        unitPrice,
        adminCommonSvc.unitPriceDecimalLimit(currentUser),
        currentUser,
      );
      setValue(`purchase_order.po_items.${index}.unit_price`, unitPrice);
      calculateTax();
    },
    [calculateTax, currentUser, index, setValue],
  );

  const lineTotal = useCallback(() => {
    let lineTotal = adminCommonSvc.roundUpAmount(
      Number(tax) + Number(lineAmount) + Number(rebateAmount),
      null,
      currentUser,
    );
    setValue(`purchase_order.po_items.${index}.total`, lineTotal);
    return lineTotal;
  }, [currentUser, index, lineAmount, rebateAmount, setValue, tax]);

  const onChangeQty = useCallback(
    (e) => {
      const qty = e.target.value ? Number(e.target.value) : null;
      setValue(`purchase_order.po_items.${index}.qty`, qty);
      calculateTax();
    },
    [calculateTax, index, setValue],
  );

  const poItemWarehouseRequired = useWatch({ name: `purchase_order.po_items.${index}.is_warehouse_required` });
  const locationMappingWithWarehouse = useCallback(
    (selected: WarehousePickerTypes.TWarehousePickerOption | null | undefined) => {
      if (selected?.location_id && currentUser.company.has_locations) {
        setValue(`purchase_order.po_items.${index}.location_id`, selected.location_id);
      }
    },
    [setValue, currentUser, index],
  );

  const calculateUnitPrice = useCallback(
    (e) => {
      const amount = e.target.value ? adminCommonSvc.roundUpAmount(Number(e.target.value), null, currentUser) : 0;
      // calculate amount and unit price for line on amount change
      const qty = getValues(`purchase_order.po_items.${index}.qty`);
      const unitPrice = qty ? amount / qty : amount;
      const roundedUnitPrice = adminCommonSvc.roundUpAmount(
        unitPrice,
        adminCommonSvc.unitPriceDecimalLimit(currentUser),
        currentUser,
      );
      setValue(`purchase_order.po_items.${index}.amount`, amount);
      setValue(`purchase_order.po_items.${index}.unit_price`, roundedUnitPrice);
      calculateTax();
    },
    [calculateTax, currentUser, getValues, index, setValue],
  );

  const isDepartmentFieldRequired = useMemo(() => {
    if (isDepartmentRequired) {
      return true;
    } else if (isRequestPO && currentUser?.company?.po_request_department_required_line_item_level) {
      return true;
    } else {
      return false;
    }
  }, [currentUser?.company?.po_request_department_required_line_item_level, isDepartmentRequired, isRequestPO]);

  const isAccountRequired = useMemo(() => {
    const isGLAccountRequired = !!currentUser.company.po_request_gl_account_required; // Ensure null or undefined becomes false
    return isRequestPO && isGLAccountRequired && !isDraftStatus;
  }, [currentUser.company.po_request_gl_account_required, isDraftStatus, isRequestPO]);

  const mapAccountRequiredFields = useCallback(
    (account: any) => {
      const accountEntry: any = getValues(`purchase_order.po_items.${index}`);
      invoiceCommonSvc.mapAccountRequiredFields(account, currentUser, accountEntry, "purchase_order");
      setValue(`purchase_order.po_items.${index}`, accountEntry);
    },
    [currentUser, getValues, index, setValue],
  );

  const copyPOLineItem = useCallback(() => {
    let newItem = cloneDeep(getValues(`purchase_order.po_items.${index}`));
    delete newItem.id;
    delete newItem.external_id;
    delete newItem.linked_txn_external_line_id;
    delete newItem.linked_txn_external_id;
    delete newItem.inherit_po_item_id;
    delete newItem.rebate_link;
    newItem.view_meta = { edit: true };
    newItem.metadata_links = null;
    poCommonSvc.removeIdFromBudgetItemLinks(newItem);
    append(newItem);
  }, [append, getValues, index, poCommonSvc]);

  const deleteItem = () => {
    setValue(`purchase_order.po_items.${index}._destroy`, 1);
  };
  useEffect(() => {
    lineTotal();
  }, [lineTotal]);

  if (itemDestroy === 1) return null;
  return (
    <Row key={lineKey}>
      <Col>
        <Panel
          header={
            <Row>
              <Col sm={11}>ITEM {index + 1}</Col>
              <Col sm={1} className={`px-pl-0 ${styles.alignRight}`}>
                <Button
                  onClick={() => copyPOLineItem()}
                  className="bg-transparent border-0 m-0 p-0 justify-content-end"
                >
                  <i className={`icon duplicate m-0 ${styles.iconSize}`} />
                </Button>
                <Button onClick={() => deleteItem()} className="bg-transparent border-0 m-0 p-0 px-pl-15">
                  <i className={`icon icon-delete m-0 ${styles.iconSize}`} />
                </Button>
              </Col>
            </Row>
          }
        >
          {!isRequestNewItem && (
            <Row>
              <Col sm="3">
                <ProductItemPicker
                  label={"Item Name"}
                  name={`purchase_order.po_items.${index}.product_item_id`}
                  modelName="purchase_order_line"
                  modelData={`purchase_order.po_items.${index}`}
                  parentObj={"purchase_order"}
                  callBack={selectProductItem}
                  required={!currentUser?.company?.po_request_hide_po_items}
                  isRequestItem
                />
              </Col>
              <Col sm="1">
                <InputField
                  label="Qty"
                  type="number"
                  name={`purchase_order.po_items.${index}.qty`}
                  onChange={onChangeQty}
                />
              </Col>
              {currentUser.company.has_units && (
                <Col sm="2">
                  <UnitPicker
                    label="Unit"
                    name={`purchase_order.po_items.${index}.unit_id`}
                    modelData={`purchase_order.po_items.${index}`}
                    callBack={mapUnitCalculation}
                  />
                </Col>
              )}
              <Col sm="2">
                <InputField
                  label="Unit Price"
                  name={`purchase_order.po_items.${index}.unit_price`}
                  disabled={currentUser.company.purchase_order?.items?.disable_unit_price}
                  type="number"
                  placeholder={"Unit Price"}
                  onChange={manageUnitPrice}
                />
              </Col>
              {!currentUser.company.po_item_line_hide_tax && (
                <Col sm={!currentUser.company.allow_edit_po_item_subtotal ? "1" : "2"}>
                  <InputField
                    label="Tax"
                    id={`purchase_order.po_items.${index}.tax_id`}
                    name={`purchase_order.po_items.${index}.tax`}
                    readOnly={!currentUser.company.enable_to_enter_tax_amount}
                    type="number"
                    placeholder={"Tax Amt"}
                  />
                </Col>
              )}
              <Col sm={"2"}>
                <InputField
                  label="Total"
                  id={`purchase_order.po_items.${index}.total`}
                  name={`purchase_order.po_items.${index}.total`}
                  disabled
                  type="number"
                  placeholder={"Total"}
                />
              </Col>
              {/* {
                TODO: vendor picker
                } */}
              {/* accountpicker  */}
              <Col sm={3}>
                <AccountPicker
                  name={`purchase_order.po_items.${index}.account_id`}
                  label="GL Accounts"
                  modelData={`purchase_order.po_items.${index}`}
                  parentObj="purchase_order"
                  required={isAccountRequired}
                  disabled={!currentUser.company?.enable_to_change_po_item_line_expense_account}
                  callBack={mapAccountRequiredFields}
                />
              </Col>
              {currentUser.company.has_taxes && !currentUser.company.po_item_line_hide_tax && (
                <Col sm="3">
                  <TaxCodePicker
                    instanceId={`purchase_order.po_items.${index}.tax_code_picker`}
                    name={`purchase_order.po_items.${index}.tax_id`}
                    label="Tax Code"
                    objectPropertyKey={`purchase_order.po_items.${index}.tax_code`} // make sure that taxcode id should store in tax_id and object should store on tax_code
                    modelData={`purchase_order.po_items.${index}`}
                    parentObj="purchase_order"
                    subsidiaryId={poSubsidiaryId}
                    disabled={isTaxDisabled}
                    callBack={calculateTax}
                  />
                </Col>
              )}
              {/* rebatePicke */}
              {currentUser.company?.has_rebates && currentUser.company?.global?.allow_rebate_at_items_line && (
                <Col sm="3">
                  <RebatePicker
                    instanceId={`purchase_order.po_items.${index}`}
                    name={`purchase_order.po_items.${index}.rebate_id`}
                    label="Rebate"
                    objectPropertyKey={`purchase_order.po_items.${index}.rebate`}
                    modelData={`purchase_order.po_items.${index}`}
                    callBack={calculateTax}
                  />
                </Col>
              )}
              {showDepartment && (
                <Col sm="4">
                  <DepartmentPicker
                    name={`purchase_order.po_items.${index}.department_id`}
                    label="Department"
                    required={isDepartmentFieldRequired}
                    // disabled={isDepartmentFieldDisabled}
                    instanceId={`purchase_order_line_department_${index}`}
                    modelData={`purchase_order.po_items.${index}`}
                    parentObj="purchase_order"
                  />
                </Col>
              )}
              {currentUser.company.has_warehouses && (
                <Col sm="4">
                  <WarehousePicker
                    name={`purchase_order.po_items.${index}.warehouse_id`}
                    label={"Warehouse"}
                    instanceId={`purchase_order.po_items.${index}.warehouse`}
                    modelData={`purchase_order.po_items.${index}`}
                    objectPropertyKey={`purchase_order.po_items.${index}.warehouse`}
                    callBack={locationMappingWithWarehouse}
                    required={poItemWarehouseRequired}
                  />
                </Col>
              )}
              {currentUser.company.has_locations && !currentUser.company.po_request_disabled_itemline_location && (
                <Col sm="4">
                  <LocationPicker
                    instanceId={`purchase_order_line_location_id_${index}`}
                    name={`purchase_order.po_items.${index}.location_id`}
                    label="Location"
                    modelData={`purchase_order.po_items.${index}`}
                    modelDataName={`purchase_order.po_items.${index}`}
                    callBack={locationMapping}
                    required={currentUser.company.po_item_location_required}
                  />
                </Col>
              )}

              {currentUser.company.purchase_order?.show_employee_items_and_expenses && (
                <Col sm="4">
                  <EmployeePicker
                    name={`purchase_order.po_items.${index}.employee_id`}
                    modelDataName={`purchase_order.po_items.${index}`}
                    label="Employee"
                    instanceId={`purchase_order.po_items.${index}.project`}
                  />
                </Col>
              )}

              {currentUser.company.has_tax_schedules && (
                <Col sm="4">
                  <TaxSchedulePicker
                    label={"Tax Schedule"}
                    name={`purchase_order.po_items.${index}.tax_schedule_id`}
                    required={!currentUser.company.product_item_optional_tax_schedule && !isDraftStatus}
                    modelData={`purchase_order.po_items.${index}`}
                  />
                </Col>
              )}
              {currentUser.company.has_business_units &&
                !currentUser.company?.purchase_order?.items?.business_unit?.is_hide && (
                  <Col sm="4">
                    <BusinessUnitPicker
                      instanceId={`purchase_order_line_business_unit_.${index}`}
                      name={`purchase_order.po_items.${index}.business_unit_id`}
                      label="Business Unit"
                      parentObj="purchase_order"
                      modelDataName={`purchase_order.po_items.${index}`}
                      required={isBusinessUnitRequired}
                      disabled={currentUser.company.readonly_business_unit_to_all}
                    />
                  </Col>
                )}
              {currentUser.company.has_budgets && currentUser.company?.budget?.show_field_at === "line_level" && (
                <BudgetPicker
                  instanceId={`po_item_budgetId_${index}`}
                  name={`purchase_order.po_items.${index}.budget_id`}
                  label="Budget"
                  modelDataName={`purchase_order.po_items.${index}`}
                  parentObjName="purchase_order"
                  // Enable the 'isMulti' option if the company configuration allows users to select multiple Budget Lines through the "Allow users to select multiple Budget Lines" setting.
                  isMulti={!!currentUser.company?.budget?.allow_to_select_budget_lines}
                />
              )}
              {currentUser.company.has_inter_companies && (
                <Col>
                  <InterCompanyPicker
                    label={"Inter Company"}
                    name={`purchase_order.po_items.${index}.inter_company`}
                    modelData={`purchase_order.po_items.${index}`}
                    parentObj={"purchase_order"}
                  />
                </Col>
              )}
              {currentUser.company.has_grants && (
                <Col sm="4">
                  <GrantPicker
                    name={`purchase_order.po_items.${index}.grant_id`}
                    label={"Grant"}
                    instanceId={`po_item_grantId_${index}`}
                    modelDataName={`purchase_order.po_items.${index}`}
                  />
                </Col>
              )}
              {currentUser.company.has_event_codes && (
                <Col sm="4">
                  <EventCodePicker
                    name={`purchase_order.po_items.${index}.event_code`}
                    label={"Event Code"}
                    instanceId={`po_item_eventCode_${index}`}
                  />
                </Col>
              )}
              {/* <>Budget Picker</> */}
              {isProjectExist && !poItemIsProjectHide && (
                <Col sm="4">
                  <ProjectPicker
                    name={`purchase_order.po_items.${index}.project_id`}
                    instanceId={`purchase_order.po_items.${index}.project`}
                    label={"Project"}
                    modelData={`purchase_order.po_items.${index}`}
                    required={isProjectRequired}
                    isPoRequest
                  />
                </Col>
              )}

              {currentUser.company.has_for_subsidiaries && (
                <Col sm="4">
                  <ForSubsidiaryPicker
                    name={`purchase_order.po_items.${index}.for_subsidiary_id`}
                    label="For Subsidiary"
                    instanceId={`purchase_order.po_items.${index}.for_subsidiary`}
                  />
                </Col>
              )}

              {currentUser.company.has_secondary_categories && (
                <Col sm="4">
                  <SecondaryCategoryPicker
                    name={`purchase_order.po_items.${index}.secondary_category_id`}
                    label={"Secondary Category"}
                    instanceId={`po_item_secondaryCategory_${index}`}
                    modelDataName={`purchase_order.po_items.${index}`}
                  />
                </Col>
              )}
              {currentUser.company.has_projects && currentUser.company.allow_for_project && (
                <Col sm="4">
                  <ProjectPicker
                    label={"For Project"}
                    name={`purchase_order.po_items.${index}.for_project_id`}
                    instanceId={`purchase_order.po_items.${index}.for_project`}
                    placeholder="-- For Project --"
                    modelData={`purchase_order.po_items.${index}`}
                    objectPropertyKey={`purchase_order.po_items.${index}.for_project`}
                    isForProject
                  />
                </Col>
              )}
            </Row>
          )}
          {/* NOT NEW ENDS */}

          {/* REQUEST NEW ITEM STARTS */}
          {isRequestNewItem && (
            <fieldset className={styles.newItemFormFieldSet}>
              <legend>New Item Details</legend>
              {/* TODO: check what is use of get  get_subsidiaries_list  */}
              <Container fluid>
                <Row>
                  <Col md="4" className="pr-0">
                    <InputField
                      label={"Item Name"}
                      name={`purchase_order.po_items.${index}.name`}
                      type="text"
                      maxLength={60}
                      placeholder="Item Name"
                      required
                      id={`po_item_itemName_${index}`}
                      inputClassName={styles.pxP1}
                    />
                  </Col>

                  {currentUser.company.has_taxes && !currentUser.company.po_item_line_hide_tax && (
                    <Col sm={currentUser.company.allow_edit_po_item_subtotal ? 2 : 3} className="pr-0">
                      <TaxCodePicker
                        name={`purchase_order.po_items.${index}.tax_id`}
                        label="Tax Code"
                        objectPropertyKey={`purchase_order.po_items.${index}.tax_code`} // make sure that taxcode id should store in tax_id and object should store on tax_code
                        modelData={`purchase_order.po_items.${index}`}
                        parentObj="purchase_order"
                        subsidiaryId={poSubsidiaryId}
                        callBack={calculateTax}
                      />
                    </Col>
                  )}

                  <Col sm={currentUser.company.po_item_line_hide_tax ? 2 : 1} className="pr-0">
                    <InputField
                      label={"Qty"}
                      name={`purchase_order.po_items.${index}.qty`}
                      onChange={onChangeQty}
                      type="number"
                      placeholder="Qty"
                      required
                      id={`po_item_itemQty_${index}`}
                      disabled={poItemIsPunchout}
                      inputClassName={styles.pxP1}
                    />
                  </Col>

                  <Col sm={currentUser.company.po_item_line_hide_tax ? 2 : 1} className="pr-0">
                    <InputField
                      label="Unit Price"
                      id={`purchase_order_item_unit_price_${index}`}
                      name={`purchase_order.po_items.${index}.unit_price`}
                      disabled={currentUser.company.purchase_order?.items?.disable_unit_price}
                      type="number"
                      placeholder={"Unit Price"}
                      onChange={manageUnitPrice}
                      maxLength={16}
                      inputClassName={styles.pxP1}
                    />
                  </Col>

                  {!currentUser.company.po_item_line_hide_tax && (
                    <Col sm={currentUser.company.allow_edit_po_item_subtotal ? "1" : "2"} className="pr-0">
                      <InputField
                        label="Tax"
                        id={`purchase_order.po_items.${index}.tax_id`}
                        name={`purchase_order.po_items.${index}.tax`}
                        readOnly={!currentUser.company.enable_to_enter_tax_amount}
                        type="number"
                        placeholder={"Tax Amt"}
                        inputClassName={styles.pxP1}
                      />
                    </Col>
                  )}

                  {currentUser.company.allow_edit_po_item_subtotal && (
                    <Col sm="1" className="pr-0">
                      <InputField
                        label={"Subtotal"}
                        id={`po_item_new_product_item_subtotal_${index}`}
                        name={`purchase_order.po_items.${index}.amount`}
                        onChange={calculateUnitPrice}
                        type="number"
                        inputClassName={styles.pxP1}
                      />
                    </Col>
                  )}

                  <Col sm={"2"}>
                    <InputField
                      label="Total"
                      id={`purchase_order.po_items.${index}.total`}
                      name={`purchase_order.po_items.${index}.total`}
                      disabled
                      type="number"
                      placeholder={"Total"}
                      inputClassName={styles.pxP1}
                    />
                  </Col>
                </Row>

                <Row>
                  {!currentUser.company.po_request_hide_display_name_new_item && (
                    <Col sm="4" className="pr-0">
                      <InputField
                        label="Item Display Name"
                        id={`purchase_order.po_items.${index}.item_display_name`}
                        name={`purchase_order.po_items.${index}.item_display_name`}
                        type="text"
                        inputClassName={styles.pxP1}
                        maxLength={60}
                      />
                    </Col>
                  )}
                  {!currentUser.company.po_request_hide_item_type_new_item && (
                    <Col sm="4" className="pr-0">
                      <Select
                        label="Item Type"
                        id={`purchase_order.po_items.${index}.item_type`}
                        name={`purchase_order.po_items.${index}.item_type`}
                        options={item_types.map((option) => ({ value: option.value, label: option.name }))}
                        required
                      />
                    </Col>
                  )}
                  {!currentUser.company.po_request_hide_expense_account_new_item && (
                    <Col sm="4" className="pr-0">
                      <AccountPicker
                        label={"Assets/Expense Account"}
                        name={`purchase_order.po_items.${index}.account_id`}
                        modelData={`purchase_order.po_items.${index}`}
                        required
                      />
                    </Col>
                  )}
                </Row>

                <Row>
                  {currentUser.company.has_locations && (
                    <Col sm="4">
                      <LocationPicker
                        label="Location"
                        name={`purchase_order.po_items.${index}.location_id`}
                        required={currentUser.company.po_item_location_required}
                        modelData={`purchase_order.po_items.${index}`}
                        parentObj="purchase_order"
                      />
                    </Col>
                  )}

                  {currentUser.company.has_departments && (
                    <Col sm="4">
                      <DepartmentPicker
                        label="Department"
                        instanceId={`purchase_order_new_product_department_${index}`}
                        name={`purchase_order.po_items.${index}.department_id`}
                        required={isDepartmentFieldRequired}
                        modelData={`purchase_order.po_items.${index}`}
                        parentObj="purchase_order"
                      />
                    </Col>
                  )}

                  {currentUser.company.has_business_units && (
                    <Col sm="4">
                      <BusinessUnitPicker
                        label="Business Unit"
                        instanceId={`purchase_order_new_product_business_unit_${index}`}
                        name={`purchase_order.po_items.${index}.business_unit_id`}
                        required={currentUser.company.purchase_order?.items?.business_unit.is_required}
                        parentObj="purchase_order"
                        modelData={`purchase_order.po_items.${index}`}
                        // bySubsidiary={currentUser.company.po_request_data_restrict_by_subsidiary}
                      />
                    </Col>
                  )}
                  <Col sm="4">
                    <CountryPicker label="Country" name={`purchase_order.po_items.${index}.country`} />
                  </Col>
                </Row>
              </Container>
            </fieldset>
          )}
        </Panel>
      </Col>
    </Row>
  );
};

export default memo(POLineItem);
