import CurrencyCodePicker from "components/common/pickers/reduxFormPickers/currencyCodePicker";
import _ from "lodash";
import React from "react";
import { Button, Col, Row } from "react-bootstrap";
import { Field } from "redux-form";
import { required } from "../../services/validations/reduxFormValidation";
import DepartmentPicker from "../admin/pickers/reduxFormPickers/departmentPicker";
import MetadataConfigurationPicker from "../admin/pickers/reduxFormPickers/metadataConfigurationPicker/metadataConfigurationPicker";
import ProjectPicker from "../admin/pickers/reduxFormPickers/projectPicker";
//import ProjectPicker from "../admin/pickers/reduxFormPickers/projectPicker/projectPicker";
import QuestionPicker from "../admin/pickers/reduxFormPickers/questionPicker";
import TriggerPicker from "../admin/pickers/reduxFormPickers/triggerPicker";
import VendorClassification from "../admin/pickers/reduxFormPickers/vendorClassification/vendorClassification";
import { RenderField, RenderFieldNumber, RenderSelect, RenderTextArea } from "../forms/bootstrapFields";
import AccountPicker from "../pickers/reduxFormPicker/accountPicker";
import BusinessUnitPicker from "../pickers/reduxFormPicker/businessUnitPicker";
import LocationPicker from "../pickers/reduxFormPicker/locationPicker";
import MultipleContactPicker from "../pickers/reduxFormPicker/multipleContactPicker";
import SubsidiaryPicker from "../pickers/reduxFormPicker/subsidiaryPicker";
import VendorPicker from "../pickers/reduxFormPicker/vendorPicker";
import { ReactComponent as Icadd } from "./../../assets/workflow/ic-add.svg";
import { ReactComponent as IcBulkAction } from "./../../assets/workflow/ic-bulk-actions.svg";
import { ReactComponent as DeleteIcon } from "./../../assets/workflow/ic-delete.svg";
import useAdminCompanyCurrencyCode from "./../../components/admin/hooks/useAdminCompanyCurrencyCode";
import Style from "./workFlow.module.css";

export const RenderTrigger = ({ triggerNames, approvalType, fields, meta: { error, submitFailed }, workFlowStep }) => {
  const { companyCurrencies } = useAdminCompanyCurrencyCode();
  return (
    <Col md={12}>
      <div className={`${Style.triggerContainer}`}>
        <div className={Style.triggerHeader}>
          <div>
            <IcBulkAction />
            {"  "} Triggers
          </div>
          <div>
            <Button onClick={() => fields.push({ key: "DEFAULT", value: null, operator: null })}>
              <Icadd />
              {"  "} Trigger
            </Button>
          </div>
        </div>
        <div className={Style.triggerBody}>
          {!fields.length && (
            <Row>
              <Col md={12}>There are no triggers.</Col>
            </Row>
          )}
          {fields.map((trigger, index) => {
            return (
              <Row key={index} className="my-2">
                <Col md={3} className=" w-100 ">
                  <Field
                    name={`${trigger}.key`}
                    triggerNames={triggerNames}
                    className={Style.Custom__Select}
                    component={TriggerPicker}
                  />
                </Col>
                <Col md={3}>
                  {[
                    "ACCOUNT",
                    "DEPARTMENT",
                    "LOCATION",
                    "SUBSIDIARY",
                    "PROJECT",
                    "VENDOR",
                    "BUSINESS_UNIT",
                    "SUBMITTER",
                    "LINE_DEPARTMENT",
                    "LINE_LOCATION",
                    "LINE_BUSINESS_UNIT",
                    "LINE_PROJECT",
                    "VENDOR_CLASSIFICATION",
                    "CUSTOM_FIELD",
                  ].includes(fields.get(index).key) && (
                    <Field
                      name={`${trigger}.operator`}
                      component={RenderSelect}
                      validate={[required]}
                      className={Style.Custom__Select}
                      options={[
                        { label: "Select", value: "" },
                        { label: "EQUAL", value: "equal" },
                        { label: "NOT EQUAL", value: "not_equal" },
                      ]}
                      required
                    />
                  )}
                  {["AMOUNT"].includes(fields.get(index).key) && (
                    <Field
                      name={`${trigger}.currency_code`}
                      component={CurrencyCodePicker}
                      validate={[required]}
                      className={Style.Custom__Select}
                      currencyCodeOptions={_.isArray(companyCurrencies) ? companyCurrencies : []}
                      required
                    />
                  )}
                  {["AMOUNT"].includes(fields.get(index).key) && (
                    <Field
                      name={`${trigger}.operator`}
                      component={RenderSelect}
                      validate={[required]}
                      className={Style.Custom__Select}
                      options={[
                        { label: "Select", value: "" },
                        { label: "GREATER THAN OR EQUAL TO", value: "greater_than_or_equal_to" },
                        { label: "LESS THAN OR EQUAL TO", value: "less_than_or_equal_to" },
                      ]}
                      required
                    />
                  )}
                  {["INVOICE_PO"].includes(fields.get(index).key) && (
                    <Field
                      name={`${trigger}.value`}
                      component={RenderSelect}
                      validate={[required]}
                      className={Style.Custom__Select}
                      required
                      options={[
                        { label: "Select", value: "" },
                        { label: "YES", value: true },
                        { label: "NO", value: false },
                      ]}
                    />
                  )}
                  {["CUSTOM_QUESTION"].includes(fields.get(index).key) && (
                    <Field
                      name={`${trigger}.value`}
                      component={RenderField}
                      validate={[required]}
                      className={Style.Custom__Select}
                      required
                    />
                  )}
                  {fields.get(index).key === "CUSTOM_TRIGGER" && (
                    <Field
                      name={`${trigger}.description`}
                      className={Style.Custom__Select}
                      component={RenderTextArea}
                      isMulti={false}
                      placeholder={"Custom Trigger Description"}
                    />
                  )}
                  {fields.get(index).key === "CUSTOM_FIELD" && (
                    <Field
                      name={`${trigger}.custom_field_level`}
                      className={Style.Custom__Select}
                      component={RenderSelect}
                      validate={[required]}
                      options={[
                        { label: "Select", value: "" },
                        { label: "HEADER LEVEL", value: "header_level" },
                        { label: "LINE LEVEL", value: "line_level" },
                      ]}
                      required
                    />
                  )}
                </Col>
                <Col md={5}>
                  {fields.get(index).key === "AMOUNT" && (
                    <Field
                      name={`${trigger}.value`}
                      type="number"
                      min="0"
                      component={RenderFieldNumber}
                      validate={[required]}
                      required={true}
                    />
                  )}

                  {fields.get(index).key === "CUSTOM_QUESTION" && (
                    <Field
                      name={`${trigger}.operator`}
                      className={Style.Custom__Select}
                      component={QuestionPicker}
                      validate={[required]}
                      isMulti={false}
                      approvalType={approvalType}
                    />
                  )}

                  {fields.get(index).key === "SUBSIDIARY" && (
                    <Field
                      name={`${trigger}.value`}
                      className={Style.Custom__Select}
                      component={SubsidiaryPicker}
                      validate={[required]}
                      isMulti={true}
                      required
                    />
                  )}

                  {fields.get(index).key === "DEPARTMENT" && (
                    <Field
                      name={`${trigger}.value`}
                      className={Style.Custom__Select}
                      component={DepartmentPicker}
                      validate={[required]}
                      isMulti={true}
                      required
                    />
                  )}

                  {fields.get(index).key === "LOCATION" && (
                    <Field
                      name={`${trigger}.value`}
                      className={Style.Custom__Select}
                      validate={[required]}
                      component={LocationPicker}
                      isMulti={true}
                      required
                    />
                  )}

                  {fields.get(index).key === "VENDOR" && (
                    <Field
                      name={`${trigger}.value`}
                      className={Style.Custom__Select}
                      component={VendorPicker}
                      validate={[required]}
                      isMulti={true}
                      required={true}
                    />
                  )}

                  {fields.get(index).key === "ACCOUNT" && (
                    <Field
                      name={`${trigger}.value`}
                      className={Style.Custom__Select}
                      component={AccountPicker}
                      isClearable={true}
                      validate={[required]}
                      isMulti={true}
                    />
                  )}

                  {fields.get(index).key === "BUSINESS_UNIT" && (
                    <Field
                      name={`${trigger}.value`}
                      className={Style.Custom__Select}
                      component={BusinessUnitPicker}
                      validate={[required]}
                      isMulti={true}
                    />
                  )}

                  {fields.get(index).key === "SUBMITTER" && (
                    <Field
                      name={`${trigger}.value`}
                      className={Style.Custom__Select}
                      component={MultipleContactPicker}
                      validate={[required]}
                      isMulti={true}
                    />
                  )}

                  {fields.get(index).key === "PROJECT" && (
                    <Field
                      name={`${trigger}.value`}
                      className={Style.Custom__Select}
                      component={ProjectPicker}
                      validate={[required]}
                      isMulti={true}
                      hideRequiredStar={true}
                      required
                    />
                  )}

                  {fields.get(index).key === "LINE_PROJECT" && (
                    <Field
                      name={`${trigger}.value`}
                      className={Style.Custom__Select}
                      component={ProjectPicker}
                      validate={[required]}
                      isMulti={true}
                      hideRequiredStar={true}
                      required
                    />
                  )}

                  {fields.get(index).key === "LINE_DEPARTMENT" && (
                    <Field
                      name={`${trigger}.value`}
                      className={Style.Custom__Select}
                      component={DepartmentPicker}
                      validate={[required]}
                      isMulti={true}
                      required
                    />
                  )}

                  {fields.get(index).key === "LINE_LOCATION" && (
                    <Field
                      name={`${trigger}.value`}
                      className={Style.Custom__Select}
                      validate={[required]}
                      component={LocationPicker}
                      isMulti={true}
                      required
                    />
                  )}

                  {fields.get(index).key === "LINE_BUSINESS_UNIT" && (
                    <Field
                      name={`${trigger}.value`}
                      className={Style.Custom__Select}
                      component={BusinessUnitPicker}
                      validate={[required]}
                      isMulti={true}
                    />
                  )}

                  {fields.get(index).key === "VENDOR_CLASSIFICATION" && (
                    <Field
                      name={`${trigger}.value`}
                      className={Style.Custom__Select}
                      component={VendorClassification}
                      validate={[required]}
                      isMulti={true}
                    />
                  )}

                  {fields.get(index).key === "CUSTOM_FIELD" && (
                    <div>
                      <Row>
                        <Col md={6}>
                          <Field
                            name={`${trigger}.metadata_configuration_id`}
                            className={Style.Custom__Select}
                            component={MetadataConfigurationPicker}
                            validate={[required]}
                            required
                            placeholder={"Custom Trigger Description"}
                          />
                        </Col>
                        <Col md={6}>
                          <Field
                            name={`${trigger}.value`}
                            component={RenderField}
                            validate={[required]}
                            className={Style.Custom__Select}
                            required
                            placeholder={"Custom Field Value"}
                          />
                        </Col>
                      </Row>
                    </div>
                  )}

                  {["CUSTOM_TRIGGER"].includes(fields.get(index).key) &&
                    workFlowStep?.action_code?.[fields.get(index).value] && (
                      <Row style={{ paddingTop: 10, paddingLeft: 20 }}>
                        <h5 style={{ color: "#1C9B02" }}>Custom Trigger Implemented</h5>
                      </Row>
                    )}
                  {["CUSTOM_TRIGGER"].includes(fields.get(index).key) &&
                    !workFlowStep?.action_code?.[fields.get(index).value] && (
                      <Row style={{ paddingTop: 10, paddingLeft: 20 }}>
                        <h5>Custom Trigger Not Implemented</h5>
                      </Row>
                    )}
                </Col>
                <Col md={1} className="p-2">
                  <DeleteIcon
                    onClick={() => {
                      fields.remove(index);
                    }}
                    style={{ cursor: "pointer" }}
                  />
                </Col>
              </Row>
            );
          })}
        </div>
      </div>
    </Col>
  );
};
