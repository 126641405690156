import React from "react";
import { Dropdown, DropdownButton } from "react-bootstrap";
import { BsThreeDots } from "react-icons/bs";
import { useLocation } from "react-router";
import { useTypedSelector } from "reducers";
import { DocumentTypes } from "services/common/documents/documentTypes";
import { CreateNotification, NotificationType } from "services/general/notifications";

export const BulkUploadFilesRowAction = ({ data }: { data: DocumentTypes.Details }) => {
  const currentUser = useTypedSelector((state) => state.user);
  const location = useLocation();
  const assets = data.assets || [];
  let useExtensionSuffix = false;
  if (assets.length > 1) {
    useExtensionSuffix = true;
  }
  return (
    <DropdownButton alignRight title={<BsThreeDots />}>
      {currentUser?.company?.can_view_file_details ? (
        <Dropdown.Item
          onClick={() => {
            window.open(`${location.pathname}/file_details/${data.id}`);
          }}
        >
          View
        </Dropdown.Item>
      ) : null}
      {assets.map((asset) => {
        const extension = asset.asset_file_file_name.split(".").pop()?.toUpperCase();
        if (extension === "JSON" && !currentUser.is_root) {
          return null;
        }
        return (
          <Dropdown.Item
            key={extension}
            onClick={() => {
              const link = document.createElement("a");
              link.href = asset.asset_expiring_url;
              link.download = asset.asset_file_file_name;
              link.target = "_blank";
              document.body.appendChild(link);
              fetch(asset.asset_expiring_url, { method: "HEAD" })
                .then((res) => {
                  if (res.ok) {
                    link.click();
                  } else {
                    CreateNotification("Error", "File not found", NotificationType.danger);
                  }
                })
                .catch((e) => {
                  CreateNotification("Error", "Error checking file: " + e.message, NotificationType.danger);
                });
              document.body.removeChild(link);
            }}
          >
            Download {useExtensionSuffix ? (extension ?? "") : ""}
          </Dropdown.Item>
        );
      })}
    </DropdownButton>
  );
};
