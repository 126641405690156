import ErrorBoundary from "components/common/errorBoundary/errorBoundary";
import useIsMounted from "components/common/hooks/useIsMounted";
import _ from "lodash";
import { restApiService } from "providers/restApi";
import React, { useCallback, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "reducers";
import PoCommonSvc from "services/admin/purchaseOrders/poCommonSvc";
import { PORequestFormTypes } from "services/admin/purchaseOrders/poRequestFormTypes";
import PurchaseOrdersApis from "services/admin/purchaseOrders/purchaseOrderApi";
import { PurchaseOrderType } from "services/admin/purchaseOrders/purchaseOrderType";
import { IUser } from "services/common/user/userTypes";
import { CreateNotification, NotificationType } from "services/general/notifications";
import FormRequestPO from "./form";

const RequestPO = () => {
  const { t } = useTranslation();
  const isMounted = useIsMounted();
  const currentUser: IUser = useTypedSelector((state) => state.user);
  const poCommonSvc = PoCommonSvc.getInstance(currentUser);
  const [poData, setPoData] = useState<any>(null);

  const methods = useForm<PORequestFormTypes.TForm>({
    defaultValues: {
      purchase_order: {},
    },
  });

  const { reset } = methods;

  const changeCurrency = (purchaseOrder: any) => {
    if (purchaseOrder.is_currency_code_edit_allow) {
      purchaseOrder.currency_code = purchaseOrder.subsidiary.currency_code;
    }
  };

  const fetchNewPo = useCallback(async () => {
    if (isMounted.current) {
      const purchaseOrder = await PurchaseOrdersApis.getNewPurchaseOrder();

      purchaseOrder.po_items = [];
      purchaseOrder.assets_attributes = [];
      purchaseOrder.po_type = "SINGLE VENDOR";
      // TODO: Implement is punchout feature: isPunchout
      // TODO: Implement inherit_project_level_data feature
      // TODO: Implement copyPurchaseOrder(); feature

      // TODO: Create Type
      let itemObj: {
        location_id?: string | number;
        department_id?: string | number;
        business_unit_id?: string | number;
      } = {};

      if (!currentUser.company.po_request_hide_po_items && !poCommonSvc.hideExpenseAndItemLines()) {
        if (purchaseOrder.location_id && currentUser.company.po_inherit_header_location_to_item_line) {
          itemObj.location_id = purchaseOrder.location_id;
        }

        if (
          _.isPlainObject(purchaseOrder.department) ||
          purchaseOrder.department_id ||
          !currentUser.company.po_request_disabled_itemline_department
        ) {
          itemObj.department_id = _.isPlainObject(purchaseOrder.department)
            ? purchaseOrder.department
            : purchaseOrder.department_id;

          if (purchaseOrder?.department?.business_unit_id && purchaseOrder?.department?.business_unit_id > 0) {
            itemObj.business_unit_id = purchaseOrder?.department?.business_unit_id;
          }

          // TODO: Implement poCommonSvc.inheritProjectLevelData(itemObj, purchaseOrder);
        }
      }

      if (purchaseOrder.subsidiary) {
        purchaseOrder.subsidiary_id = purchaseOrder.subsidiary.id;
        changeCurrency(purchaseOrder); // TODO: test this code
        // poCommonSvc.changeSubsidiary();  // TODO: Implement this function
      }

      if (purchaseOrder.department) {
        purchaseOrder.department_id = purchaseOrder.department.id;
      }

      if (purchaseOrder.address) {
        purchaseOrder.address_id = purchaseOrder.address.id;
      }
      purchaseOrder.is_request_PO = true;
      //poCommonSvc.updateReceiveByDate(purchaseOrder); // TODO: Implement Receive by date calculation feature

      //TODO: Implement this feature
      //if any punchout id is in the GET parameters fetch the line items from it
      // if (isPunchout) {
      //   get_punchout_items($state.params.punchoutId)
      // }

      if (isMounted.current) {
        setPoData(purchaseOrder);
      }
    }
  }, [isMounted]);

  useEffect(() => {
    fetchNewPo();
  }, [fetchNewPo]);

  useEffect(() => {
    if (poData) {
      reset({
        purchase_order: poData,
        savedPO: poData,
      });
    }
  }, [poData, reset]);

  const checkItemsAccounts = (poItems: any) => {
    let isAllowToSubmit = true;
    poItems.forEach((item: any) => {
      if (
        item &&
        item.is_request_new_item &&
        item.account_id &&
        item.cogs_account_id &&
        item.account_id === item.cogs_account_id
      ) {
        CreateNotification("Error", `Assets/Expense Account and COGS Account can not be same`, NotificationType.danger);
        isAllowToSubmit = false;
      }
    });
    return isAllowToSubmit;
  };

  const validateW9 = useCallback(
    (purchaseOrder: any) => {
      if (
        currentUser.company.po_request_w9_form_required &&
        purchaseOrder.vendor_attributes?.assets_attributes &&
        Array.isArray(purchaseOrder.vendor_attributes?.assets_attributes) &&
        purchaseOrder.vendor_attributes.assets_attributes.length < 1
      ) {
        CreateNotification("Warning", `W9 Form is required`, NotificationType.warning);
        return false;
      }
      return true;
    },
    [currentUser.company.po_request_w9_form_required],
  );

  const attachmentRequired = useCallback(
    (purchaseOrder: any) => {
      // TODO: Theck this logic purchaseOrder.punchout_id
      // if (purchaseOrder.punchout_id) {
      //   return true;
      // }

      if (
        purchaseOrder.status !== "DRAFT" &&
        currentUser.company.po_request_is_attachment_required &&
        purchaseOrder.assets_attributes.length < 1
      ) {
        return false;
      }
      return true;
    },
    [currentUser.company.po_request_is_attachment_required],
  );

  const attachmentRequiredOnMinimumAmount = useCallback(
    (purchaseOrder: any) => {
      // TODO: Theck this logic purchaseOrder.punchout_id
      // if (purchaseOrder.punchout_id) {
      //   return true;
      // }

      if (
        purchaseOrder.status !== "DRAFT" &&
        Number(currentUser.company.po_request_attachment_required_on_min_amount) > 0 &&
        purchaseOrder.amount >= Number(currentUser.company.po_request_attachment_required_on_min_amount) &&
        purchaseOrder.assets_attributes.length < 1
      ) {
        return false;
      }
      return true;
    },
    [currentUser.company.po_request_attachment_required_on_min_amount],
  );

  const isStartAndEndDateSame = (purchaseOrder: any) => {
    if (purchaseOrder.start_date && purchaseOrder.end_date) {
      // let start_date = new Date(obj.start_date);
      // let end_date = new Date(obj.end_date);
      // return start_date >= end_date;
    } else {
      return false;
    }
  };

  const success = (response: PurchaseOrderType.DetailType) => {
    if (_.isPlainObject(response)) {
      CreateNotification("Added", `PO Number ${response?.number} added`, NotificationType.success);
      setTimeout(() => {
        window.location.href = restApiService.angularBaseURL() + "/purchase_orders/" + response.id;
      }, 2000);
    }
  };

  const createPo = useCallback(
    async (purchaseOrder: any) => {
      if (Array.isArray(purchaseOrder.budget_item_links)) {
        purchaseOrder.budget_item_links_attributes = purchaseOrder.budget_item_links;
      }

      if (Array.isArray(purchaseOrder.po_items) && purchaseOrder.po_items.length > 0) {
        purchaseOrder.po_items.forEach((item: any) => {
          poCommonSvc.removeIdFromBudgetItemLinks(item);
        });
      }

      //update billing address field
      if (purchaseOrder.billing_address) {
        purchaseOrder.billing_address = poCommonSvc.updateAddressFields(purchaseOrder.billing_address);
      }

      try {
        const response = await PurchaseOrdersApis.addPoRequest({
          purchase_order: purchaseOrder,
        });

        if (
          !purchaseOrder.assets_attributes ||
          (Array.isArray(purchaseOrder.assets_attributes) && purchaseOrder.assets_attributes.length < 1)
        ) {
          success(response);
          return;
        }

        if (
          response?.id &&
          Array.isArray(purchaseOrder.assets_attributes) &&
          purchaseOrder.assets_attributes.length > 0
        ) {
          await PurchaseOrdersApis.uploadPOAttachment(purchaseOrder.assets_attributes, response?.id);
          success(response);
        }
      } catch (error) {
        CreateNotification("Error", `Purchase order not able to Submit. please try again.`, NotificationType.danger);
        console.log("error: ", error);
      }
    },
    [poCommonSvc],
  );

  const onSubmit = useCallback(
    (formValues) => {
      let purchaseOrder = formValues.purchase_order;

      if (purchaseOrder.require_virtual_card && purchaseOrder.currency_code !== "USD") {
        CreateNotification(
          "Invalid Currency",
          `Virtual cards can only be required on USD currency PO's`,
          NotificationType.success,
        );
        return;
      }
      /*TODO: Implement checkBudgetVariance 
    if (!checkBudgetVariance()) {
      return false;
    }*/

      // poCommonSvc.assignNewItem(purchaseOrder);
      // poCommonSvc.update_fields_before_submit($scope.purchase_order); //to inherit the header data to line level

      poCommonSvc.updateFieldsBeforeSubmit(purchaseOrder);
      if (!checkItemsAccounts(purchaseOrder.po_items)) {
        return false;
      }

      if (!validateW9(purchaseOrder)) {
        return false;
      }
      /* TODO: Fix and uncomment
    if (!no_vendor && !new_vendor && purchaseOrder.vendor && (!purchaseOrder.vendor.name || !purchaseOrder.vendor_id)) {
      return false;
    }*/

      if (!attachmentRequired(purchaseOrder)) {
        return false;
      }

      if (!attachmentRequiredOnMinimumAmount(purchaseOrder)) {
        return false;
      }

      if (isStartAndEndDateSame(purchaseOrder)) {
        return false;
      }

      if (!(purchaseOrder.amount > 0) && Array.isArray(purchaseOrder.po_items)) {
        CreateNotification("Not Allowed", `Purchase Order total amount can not be zero`, NotificationType.warning);
        return false;
      }

      purchaseOrder.po_items.forEach((item: any) => {
        if (!item.vendor) {
          return;
        }
        item.vendor_name = item.vendor.name || item.vendor;
      });
      /* TODO: Implement this feature
      if (newVendor) {
        poCommonSvc.create_vendor()
      }else{
        createPo()
      }*/

      createPo(purchaseOrder); // TODO: Remove this once the poCommonSvc.create_vendor() function is fully implemented.
    },
    [attachmentRequired, attachmentRequiredOnMinimumAmount, createPo, validateW9],
  );

  return (
    <FormProvider {...methods}>
      <ErrorBoundary>
        <FormRequestPO onSubmit={onSubmit} />
      </ErrorBoundary>
    </FormProvider>
  );
};

export default RequestPO;
