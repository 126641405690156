import { WombatType } from "wombatifier/services/wombat.types";
import { AssetType } from "../types/common.type";

export const RECEIPT_QUEUE_STATUSES: string[] = ["PENDING_OCR", "PENDING_MATCH", "ACTION_REQUIRED", "MATCHED"];

export type ReceiptQueueStatusType = typeof RECEIPT_QUEUE_STATUSES;

export const FILE_INTEGRATION_ERROR_STATUSES = [
  "PARSING_FAILED",
  "PRE_PROCESSING_FAILED",
  "MAPPING_FAILED",
  "VALIDATING_FAILED",
  "PROCESSING_FAILED",
  "COMPLETED_WITH_ERRORS",
];

export const FILE_INTEGRATION_STATUSES: string[] = [
  "NEW",
  "PARSING",
  "PARSING_FAILED",
  "PRE_PROCESSING",
  "PRE_PROCESSING_FAILED",
  "MAPPING",
  "MAPPING_FAILED",
  "VALIDATING",
  "VALIDATING_FAILED",
  "PROCESSING",
  "PROCESSING_FAILED",
  "COMPLETED",
  "COMPLETED_WITH_ERRORS",
];

export type FileIntegrationStatusType = typeof FILE_INTEGRATION_STATUSES;

export type DocumentStatusType = ReceiptQueueStatusType[number] | FileIntegrationStatusType[number];

export namespace DocumentTypes {
  export type ListItem = {};

  export type Details = {
    company_id?: number | string;
    contact_id?: number | string;
    created_at?: Date;
    document_reference?: string;
    document_type?: string;
    documentable_id?: number;
    documentable_type?: string;
    form_data?: Record<string, any>;
    id: number | string;
    name?: string;
    page_data?: string;
    sign_reference?: string;
    status?: DocumentStatusType;
    updated_at?: Date;
    assets: AssetType[];
    wombat?: WombatType;
  };

  export type AddResponse = Details;

  export type Add = {
    document: {
      name?: string;
      documentable_id?: number | string;
      documentable_type?: string;
      document_type?: string;
      page_data?: string;
      document_reference?: string;
      sign_reference?: string;
      signature_value?: string;
      status?: DocumentStatusType;
      form_data?: Record<string, any>;
    };
  };

  export type Edit = {};
}
