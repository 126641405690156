import ConsolidateErrorModal from "components/admin/consolidateError/consolidateErrorModal";
import { ConsolidateErrorModalTypes } from "components/admin/consolidateError/consolidateErrorModalTypes";
import ErrorBoundary from "components/common/errorBoundary/errorBoundary";
import useConfirmModal from "components/modals/confirmModal/useConfirmModalHook";
import { restApiService } from "providers/restApi";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Button, ButtonGroup, Card, Col, Container, Dropdown, Row, Tab, Tabs } from "react-bootstrap";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { BsArrowLeft, BsChatRightText, BsChevronLeft, BsChevronRight } from "react-icons/bs";
import { useHistory, useLocation } from "react-router";
import { useTypedSelector } from "reducers";
import adminCommonSvc from "services/admin/commonSvc";
import useCustomLabel from "services/admin/customLabels/useCustomLabel";
import { createCreditMemoAsync } from "services/admin/invoices/creditMemo";
import InvoiceApis from "services/admin/invoices/invoiceApis";
import invoiceCommonSvc from "services/admin/invoices/invoiceCommonSvc";
import { InvoiceType } from "services/admin/invoices/invoiceType";
import commonService from "services/common/commonSvc";
import { IUser } from "services/common/user/userTypes";
import { getStringFormattedAmount } from "services/general/helpers";
import { CreateNotification, NotificationType } from "services/general/notifications";
import ApprovalSection from "./approvalSection";
import ChatWrapper from "./chat/chatWrapper";
import FileUploadSection from "./fileUploadSection";
import { useInvoiceDetailsContext } from "./invoiceDetailsContext";
import styles from "./invoiceDetailsManager.module.css";
import { handleError, stopEventPropagation } from "./invoiceDetailsService";
import InvoiceDetailsItemLineSection from "./invoiceDetailstemLineSection";
import InvoiceHeaderLineSection from "./InvoiceHeaderLineSection";
import InvoiceMainHeader from "./InvoiceMainHeader";
import InvoiceCreditLineSection from "./subSections/creditLineSection";
import InvoiceDebitLineSection from "./subSections/debitLineSection";
import { useUiStateContext } from "./uiStateContext";

type TInvoiceDetailsManagerProps = {
  invoiceId: number;
  portal: boolean;
};

const InvoiceDetailsManager: React.FC<TInvoiceDetailsManagerProps> = ({ invoiceId, portal }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const currentUser: IUser = useTypedSelector((state) => state.user);
  const { createConfirmModal } = useConfirmModal();
  const { invoiceDetails, refetchInvoiceDetails, isFetchingInvoiceDetails, goToUrl, prevInvoiceId, nextInvoiceId } =
    useInvoiceDetailsContext();
  const { isExpanded, setIsOperationRunning, isOperationRunning } = useUiStateContext();
  const decimal = adminCommonSvc.getDefaultDecimal(currentUser);
  const [showConsolidateErrorModal, setShowConsolidateErrorModal] = useState<boolean>(false);
  const [consolidateErrorContainers, setConsolidateErrorContainers] = useState<
    ConsolidateErrorModalTypes.TConsolidateErrorContainer[] | null
  >(null);
  const { isFetchedCustomLabels } = useCustomLabel();
  const [isChatOpen, setIsChatOpen] = useState<boolean>(false);
  const openChat = () => setIsChatOpen(true);
  const closeChat = () => setIsChatOpen(false);

  const parsedFormValues = useMemo(() => {
    return invoiceCommonSvc.parseInvoiceForUploadQueue(invoiceDetails?.invoice);
  }, [invoiceDetails]);

  const allowSubmitWithGLErrors = useMemo(() => {
    return invoiceCommonSvc.canSubmitWithoutErrors(currentUser, parsedFormValues);
  }, [currentUser, parsedFormValues]);

  const invoiceLoadedRef = useRef(false);

  // run once on initial invoice load to check if current invoice details
  // has already triggered anything

  useEffect(() => {
    const runInitialProactiveApprovalsTriggers = async () => {
      if (!invoiceLoadedRef.current && invoiceDetails?.invoice) {
        await invoiceCommonSvc.triggerProactiveApprovals(invoiceDetails.invoice);
        invoiceLoadedRef.current = true;
        refetchInvoiceDetails();
      }
    };

    runInitialProactiveApprovalsTriggers();
  }, [invoiceDetails, refetchInvoiceDetails]);

  const methods = useForm<InvoiceType.InvoiceDetailType>({
    values: parsedFormValues,
  });

  const {
    handleSubmit,
    getValues,
    trigger,
    formState: { isSubmitting, isLoading },
  } = methods;

  const goToOriginalCaller = () => {
    if (portal) {
      history.push(goToUrl);
    } else {
      window.location.href = goToUrl;
    }
  };

  const handleGoToOriginUrl = (timeout: number) => {
    if (timeout > 0) {
      setTimeout(() => {
        goToOriginalCaller();
      }, timeout);
    } else {
      goToOriginalCaller();
    }
  };

  const handleChangeId = (newId: number | undefined) => {
    if (!newId) {
      return;
    }
    const { search, pathname } = location;
    const pathParts = pathname.split("/");
    const index = pathParts.indexOf(invoiceId.toString());

    if (index !== -1) {
      pathParts[index] = newId.toString();
    }
    const newPath = pathParts.join("/");
    history.push(`${newPath}${search}`);
  };

  const showPrevButton = !!(prevInvoiceId && prevInvoiceId !== invoiceId);
  const showNextButton = !!(nextInvoiceId && nextInvoiceId !== invoiceId);

  const onPrevClick = async (event: any) => {
    stopEventPropagation(event);
    handleChangeId(prevInvoiceId);
  };

  const onNextClick = async (event: any) => {
    stopEventPropagation(event);
    handleChangeId(nextInvoiceId);
  };

  const assignAccountTransactionAttributes = (formValues: InvoiceType.InvoiceDetailType) => {
    if (
      (formValues?.debit_entries_attributes && formValues?.debit_entries_attributes?.length > 0) ||
      (formValues?.credit_entries_attributes && formValues?.credit_entries_attributes.length > 0)
    ) {
      invoiceCommonSvc.assignInvoiceAmountToCredit(formValues, currentUser);
      formValues.account_transaction_attributes = {};
      formValues.account_transaction_attributes.date = formValues.date;
      formValues.account_transaction_attributes.id = formValues?.account_transaction
        ? formValues?.account_transaction?.id
        : undefined;
      formValues.account_transaction_attributes.amount = formValues.amount;
      formValues.account_transaction_attributes.debit_entries_attributes = formValues.debit_entries_attributes;
      formValues.account_transaction_attributes.credit_entries_attributes = formValues.credit_entries_attributes;
    } else {
      formValues.account_transaction_attributes = undefined;
    }
  };

  const onSubmit = async (formValues: InvoiceType.InvoiceDetailType) => {
    //   if (invoice.requestor || invoice.requestor && invoice.requestor.id > 0)) {
    //   invoice.requestor_id = '';
    // }
    if (formValues.id) {
      formValues.status = "PENDING";
      //TODO-Temp assign debit entry and credit entry
      if (
        (formValues?.debit_entries_attributes && formValues?.debit_entries_attributes?.length > 0) ||
        (formValues?.credit_entries_attributes && formValues?.credit_entries_attributes.length > 0)
      ) {
        invoiceCommonSvc.assignInvoiceAmountToCredit(formValues, currentUser);

        formValues.account_transaction_attributes = {};
        formValues.account_transaction_attributes.date = formValues.date;
        formValues.account_transaction_attributes.id = formValues?.account_transaction
          ? formValues?.account_transaction?.id
          : undefined;
        formValues.account_transaction_attributes.amount = formValues.amount;
        formValues.account_transaction_attributes.debit_entries_attributes = formValues.debit_entries_attributes;
        formValues.account_transaction_attributes.credit_entries_attributes = formValues.credit_entries_attributes;
      } else {
        formValues.account_transaction_attributes = undefined;
      }

      try {
        // According to the requirement, invoices should initially be submitted with a PENDING status, similar to the invoice inbox process.
        const params =
          formValues.approvals && formValues.approvals.length > 0
            ? { invoice: formValues, process_proactive_approvals: true }
            : { invoice: formValues };
        const response = await restApiService.post(`invoices/${formValues.id}/submit_for_approval`, null, params);
        if (response?.data?.id) {
          CreateNotification("Updated", `Invoice successfully updated`, NotificationType.success);
          if (response?.data?.status_message) {
            CreateNotification("Sync", response.status_message, NotificationType.info);
          }
        }
      } catch (error) {
        CreateNotification("Error", `Invoice not able to Submit. please try again.`, NotificationType.danger);
        console.log("error: ", error);
      } finally {
        if (showNextButton) {
          onNextClick(new Event("SubmitAndNext"));
        } else {
          handleGoToOriginUrl(0);
        }
      }
    } else {
      CreateNotification("Error", `No Id Found, Please upload document.`, NotificationType.danger);
    }
  };

  const onConvertToCreditMemoConfirm = async () => {
    if (!invoiceDetails?.invoice?.id) {
      return;
    }

    try {
      setIsOperationRunning(true);

      const creditMemo = await createCreditMemoAsync(invoiceDetails.invoice, currentUser);

      CreateNotification(
        t("admin.pages.details.converted"),
        t("admin.pages.details.convertToCreditMemoSuccess", {
          invNumber: creditMemo.payment_number,
        }),
        NotificationType.success,
      );

      if (showNextButton) {
        onNextClick(new Event("ConvertedAndNext"));
      } else {
        handleGoToOriginUrl(2000);
      }
    } catch (error: any) {
      let errorMessage = "";
      if (error.response?.data) {
        for (const [key, value] of Object.entries(error.response.data)) {
          const formattedKey = key === "payment_number" ? "Credit Memo #" : `${key}:`;
          errorMessage += `${formattedKey} ${value} `;
        }
        CreateNotification("Error", errorMessage, NotificationType.danger);
      } else {
        CreateNotification("Error", "Payment was not created.", NotificationType.danger);
      }
    } finally {
      setIsOperationRunning(false);
    }
  };

  const onConvertToCreditMemoInvoiceClick = (event: any) => {
    stopEventPropagation(event);
    if (!invoiceDetails?.invoice) {
      return;
    }
    createConfirmModal({
      title: t("admin.pages.details.convert"),
      body: t("admin.pages.details.convertToCreditMemo", { invNumber: invoiceDetails.invoice.number }),
      callBackData: null,
      cancelCallBack: null,
      saveCallBack: onConvertToCreditMemoConfirm,
    });
  };

  const getConfirmationMessage = (formValue: InvoiceType.InvoiceDetailType, isSubmit: boolean) => {
    const currencyCode = formValue.currency_code;
    const amount = formValue.amount;
    const originalCurrencyCode = formValue.original_currency_code;
    const originalAmount = formValue.original_amount;
    const msgKey = isSubmit
      ? "admin.pages.details.originalAmountNotChanged"
      : "admin.pages.details.originalAmountNotChangedSave";

    const originalAmountNotChanged = invoiceDetails ? (
      <>{t(msgKey, { invoiceNumber: invoiceDetails.invoice.number })}</>
    ) : (
      <></>
    );

    const originalAmountChanged = invoiceDetails ? (
      <>
        {t("admin.pages.details.originalAmountChanged", {
          to: getStringFormattedAmount(String(amount), currencyCode, decimal),
          from: getStringFormattedAmount(String(originalAmount), originalCurrencyCode, decimal),
        })}
      </>
    ) : (
      <></>
    );

    return amount === originalAmount ? originalAmountNotChanged : originalAmountChanged;
  };

  const onSubmitInvoiceClick = (formValue: any) => {
    if (!invoiceDetails?.invoice) {
      return;
    }
    createConfirmModal({
      title: t("confirm"),
      body: getConfirmationMessage(formValue, true),
      callBackData: null,
      cancelCallBack: null,
      saveCallBack: () => onSubmit(formValue),
    });
  };

  const onDeleteConfirm = async () => {
    if (!invoiceDetails?.invoice?.id) {
      return;
    }

    try {
      setIsOperationRunning(true);
      await InvoiceApis.deleteInvoice(invoiceDetails.invoice.id);
      CreateNotification(
        t("admin.pages.details.deleted"),
        t("admin.pages.details.confirm", { invNumber: invoiceDetails.invoice.number }),
        NotificationType.success,
      );
    } finally {
      setIsOperationRunning(false);
      handleGoToOriginUrl(2000);
    }
  };

  const onDeleteInvoiceClick = (event: any) => {
    stopEventPropagation(event);
    if (!invoiceDetails?.invoice) {
      return;
    }
    createConfirmModal({
      title: t("admin.pages.details.delete"),
      body: t("admin.pages.details.deleteConfirmBody", { invNumber: invoiceDetails.invoice.number }),
      callBackData: null,
      cancelCallBack: null,
      saveCallBack: onDeleteConfirm,
    });
  };

  const onSaveInvoiceClick = (event: any, goNext: boolean) => {
    stopEventPropagation(event);
    if (!invoiceDetails?.invoice) {
      return;
    }

    const formValues = getValues();
    trigger().then((isValid) => {
      if (isValid) {
        createConfirmModal({
          title: t("admin.pages.details.save"),
          body: getConfirmationMessage(formValues, false),
          callBackData: null,
          cancelCallBack: null,
          saveCallBack: () => onSaveInvoiceConfirm(event, goNext),
        });
      }
    });
  };

  const onSaveInvoiceConfirm = async (event: any, goNext: boolean) => {
    if (!invoiceDetails?.invoice) {
      return;
    }

    try {
      setIsOperationRunning(true);
      await saveInvoiceAsync(true);
    } finally {
      setIsOperationRunning(false);
      if (goNext) {
        onNextClick(event);
      } else {
        refetchInvoiceDetails();
      }
    }
  };

  const saveInvoiceAsync = async (notify: boolean = true, skipWorkflowTriggerApi?: boolean) => {
    if (!invoiceDetails?.invoice?.id) {
      return;
    }
    try {
      const formValues = getValues();
      assignAccountTransactionAttributes(formValues);
      const editApiResult = await InvoiceApis.editInvoice(invoiceDetails.invoice.id.toString(), {
        invoice: formValues,
      });
      // skipWorkflowTriggerApi used for if a save on the invoice doesn't edit any relevant
      // invoice form info that a workflow trigger would care about - saves api call resources
      if (editApiResult && !skipWorkflowTriggerApi) {
        await invoiceCommonSvc.triggerProactiveApprovals(editApiResult);
      }
      if (notify) {
        CreateNotification(
          t("admin.pages.details.saved"),
          t("admin.pages.details.confirm", { invNumber: invoiceDetails.invoice.number }),
          NotificationType.success,
        );
      }
    } catch (error) {
      console.log("error: ", error);
      handleError(error, t);
    }
  };

  const btnsDisabled = useMemo(() => {
    return isLoading || isSubmitting || isFetchingInvoiceDetails || isOperationRunning || !invoiceDetails?.invoice;
  }, [isFetchingInvoiceDetails, isLoading, isOperationRunning, isSubmitting, invoiceDetails]);

  const getErrorsContainers = useCallback((formStateErrors: any) => {
    return commonService.getReactHookFormConsolidateErrors({
      formErrors: formStateErrors,
      containers: [
        {
          container_name: "Main",
          container_id: "main",
          errors: [],
          isMain: true,
        },
        {
          container_name: "Line Items",
          container_id: "line_items",
          errors: [],
        },
        {
          container_name: "Debit Entries",
          container_id: "debit_entries",
          errors: [],
        },
      ],
      fieldContainerMap: {
        invoice_items_attributes: "line_items",
        debit_entries_attributes: "debit_entries",
      },
      nameLabelMapOverride: {
        date: "Invoice Date",
      },
    });
  }, []);

  const consolidateErrors = (errors: any) => {
    if (Object.keys(errors).length > 0) {
      setShowConsolidateErrorModal(true);
      setConsolidateErrorContainers(getErrorsContainers(errors));
    }
  };

  const onConsolidateErrorModalClose = () => {
    setShowConsolidateErrorModal(false);
    setConsolidateErrorContainers(null);
  };

  return (
    <>
      {showConsolidateErrorModal && consolidateErrorContainers && (
        <ConsolidateErrorModal
          errorContainers={consolidateErrorContainers}
          modalTitle="Your invoice could not be submitted"
          modalTitleMessage="Review the following list of incorrect or missing information and their respective screens:"
          showConsolidateErrorModal={showConsolidateErrorModal}
          cancel={() => onConsolidateErrorModalClose()}
        />
      )}
      <Container fluid>
        {/*
          Used 'FormProvider' because...
          1. It provides access to the methods and state of the form.
          2. It allows you to manage form state and share it across components without manually passing props down the component tree.
          3. Simplifies prop drilling and provides a centralized place for managing form state.
      */}
        <ErrorBoundary>
          <FormProvider {...methods}>
            <form id="invoice_details_form" noValidate onSubmit={handleSubmit(onSubmitInvoiceClick, consolidateErrors)}>
              {isChatOpen && (
                <ChatWrapper
                  title={parsedFormValues?.number}
                  invoiceId={invoiceId}
                  user={currentUser}
                  onClose={closeChat}
                  wrapped
                />
              )}
              <Row className={`${styles.stickyRow} mt-3 mb-3 p-0 b-0`}>
                <Col md="4" className="d-flex justify-content-start align-items-end">
                  <Button
                    variant="secondary"
                    className={`${styles.secondaryButton} ${styles.backToInboxBtn} mr-1`}
                    title={t("admin.pages.details.inbox")}
                    id="invoice_inbox_btn"
                    disabled={btnsDisabled}
                    onClick={() => handleGoToOriginUrl(0)}
                  >
                    <BsArrowLeft className={styles.backToInboxIcon} />
                    {t("admin.pages.details.inbox")}
                  </Button>
                  {showPrevButton && (
                    <Button
                      variant="secondary"
                      className={`${styles.secondaryButton} ${styles.regularBtn} mr-1`}
                      id="invoice_prev_btn"
                      disabled={btnsDisabled || !showPrevButton}
                      onClick={onPrevClick}
                      title={t("admin.pages.details.prevInvoice")}
                    >
                      <BsChevronLeft className={styles.prevIcon} />
                      {t("admin.pages.details.prevInvoice")}
                    </Button>
                  )}
                  {showNextButton && (
                    <Button
                      variant="secondary"
                      className={`${styles.secondaryButton} ${styles.regularBtn}`}
                      id="invoice_next_btn"
                      disabled={btnsDisabled || !showNextButton}
                      onClick={onNextClick}
                      title={t("admin.pages.details.nextInvoice")}
                    >
                      {t("admin.pages.details.nextInvoice")}
                      <BsChevronRight className={styles.nextIcon} />
                    </Button>
                  )}
                </Col>
                <Col md="3" className="d-flex justify-content-start pt-2">
                  {parsedFormValues?.invoice_filing_duration && (
                    <span>
                      {`${t("admin.pages.details.submissionTime")}: ${parsedFormValues?.invoice_filing_duration}`}
                    </span>
                  )}
                </Col>
                <Col md="5" className="d-flex justify-content-end align-items-start">
                  <Button
                    variant="secondary"
                    className={`${styles.secondaryButton} ${styles.regularBtn} mr-1`}
                    id="invoice_message_btn"
                    onClick={openChat}
                    title={t("admin.pages.details.message")}
                  >
                    <BsChatRightText className={styles.chatIcon} size={18} />
                    {t("admin.pages.details.message")}
                  </Button>
                  <Button
                    variant="secondary"
                    className={`${styles.secondaryButton} ${styles.cmBtn}`}
                    id="invoice_convert_btn"
                    disabled={btnsDisabled}
                    onClick={onConvertToCreditMemoInvoiceClick}
                    title={t("admin.pages.details.convertToCm")}
                  >
                    {t("admin.pages.details.convertToCm")}
                  </Button>
                  <Button
                    variant="secondary"
                    className={`${styles.secondaryButton} ${styles.regularBtn} ml-1`}
                    id="invoice_delete_btn"
                    disabled={btnsDisabled}
                    onClick={onDeleteInvoiceClick}
                    title={t("admin.pages.details.delete")}
                  >
                    {t("admin.pages.details.delete")}
                  </Button>
                  <ButtonGroup className="ml-1">
                    <Button
                      variant="secondary"
                      disabled={btnsDisabled}
                      className={`${styles.secondaryButton} ${styles.regularBtn}`}
                      onClick={(e) => onSaveInvoiceClick(e, false)}
                      title={t("admin.pages.details.save")}
                    >
                      {t("admin.pages.details.save")}
                    </Button>
                    {showNextButton && (
                      <Dropdown as={ButtonGroup}>
                        <Dropdown.Toggle
                          split
                          variant="secondary"
                          id="dropdown-split-basic"
                          disabled={btnsDisabled}
                          className={`${styles.secondaryButton} ${styles.dropdownToggle}`}
                        />
                        <Dropdown.Menu>
                          <Dropdown.Item
                            as="button"
                            onClick={(e) => onSaveInvoiceClick(e, true)}
                            className={styles.primaryDropdownText}
                          >
                            {t("admin.pages.invoice.saveAndNext")}
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    )}
                  </ButtonGroup>
                  <Button
                    disabled={btnsDisabled}
                    variant="primary"
                    className={`${styles.primaryButton} ${styles.regularBtn} ml-1`}
                    type="submit"
                    title="Submit"
                  >
                    {t("admin.pages.invoice.submit")}
                  </Button>
                </Col>
              </Row>
              <Row className={styles.row}>
                <div className={`${styles.fixedCol} ${isExpanded ? styles.expanded : styles.collapsed}`}>
                  <FileUploadSection saveAsyncCallback={saveInvoiceAsync} />
                </div>
                {isFetchedCustomLabels && (
                  <div className={styles.variableCol}>
                    <Card className={styles.mainCard}>
                      <Card.Body>
                        <InvoiceMainHeader />
                        <Tabs defaultActiveKey="Header" className={`${styles.customTabsBorder} customTab mt-3`}>
                          <Tab eventKey="Header" title={"Invoice Header"} className="mt-4">
                            <InvoiceHeaderLineSection />
                          </Tab>
                          {currentUser.company.allow_invoice_items && (
                            <Tab eventKey="LineItems" title={"Line Items"} className="mt-4">
                              <InvoiceDetailsItemLineSection
                                saveAsyncCallback={saveInvoiceAsync}
                                allowSubmitWithGLErrors={allowSubmitWithGLErrors}
                              />
                            </Tab>
                          )}
                          {(!currentUser?.company?.invoice?.hide_debit_account ||
                            !currentUser.company.invoice_hide_credit_account) && (
                            <Tab eventKey="GLAccounts" title={"GL Accounts"} className="mt-4">
                              {!currentUser?.company?.invoice?.hide_debit_account && (
                                <InvoiceDebitLineSection allowSubmitWithGLErrors={allowSubmitWithGLErrors} />
                              )}
                              {!currentUser?.company?.invoice_hide_credit_account && (
                                <InvoiceCreditLineSection allowSubmitWithGLErrors={allowSubmitWithGLErrors} />
                              )}
                            </Tab>
                          )}
                        </Tabs>
                      </Card.Body>
                    </Card>
                  </div>
                )}
              </Row>
              <Row className={styles.row}>
                <div className={styles.variableCol}>
                  {invoiceDetails && <ApprovalSection saveAsyncCallback={saveInvoiceAsync} />}
                </div>
              </Row>
            </form>
          </FormProvider>
        </ErrorBoundary>
      </Container>
    </>
  );
};

export default InvoiceDetailsManager;
