import {
  ColumnApi,
  GridApi,
  GridReadyEvent,
  IServerSideGetRowsParams,
  PaginationChangedEvent,
} from "ag-grid-community";
import Activities from "components/admin/activities/activities";
import ActivityLog from "components/admin/activityLog/activityLog";
import AccessDeniedPanel from "components/common/accessDeniedPanel/accessDeniedPanel";
import ServerSideDataGrid from "components/common/dataGrid/serverSideDataGrid/serverSideDataGrid";
import useShowFilterState from "components/common/hooks/useShowFilterState";
import Breadcrumb from "components/common/navigations/breadcrumb";
import Panel from "components/common/panel/panel";
import ToggleFilterButton from "components/datagrid/buttons/toggleFilterButton";
import GridFilterDropdown from "components/datagrid/gridFilterDropdown";
import PageTitle from "components/page/pageTitle";
import TooltipRender from "components/toolTip/tooltipRender";
import { restApiService } from "providers/restApi";
import { default as React, FC, useCallback, useEffect, useRef, useState } from "react";
import { Col, Container, Dropdown, DropdownButton, Row, Spinner } from "react-bootstrap";
import { BsCheckCircle, BsCircle, BsFilter } from "react-icons/bs";
import { useParams } from "react-router-dom";
import { useTypedSelector } from "reducers";
import DocumentApis from "services/common/documents/documentApis";
import { DocumentTypes } from "services/common/documents/documentTypes";
import { getPaginationData, getParams, processSortModel, saveDefaultOrder } from "services/common/gridService";
import { TabNavigation } from "wombatifier/components/navigation/tabNavigator";
import { DocumentRowResultResultTypes } from "wombatifier/services/documentRowResult.types";
import { DocumentRowResultApis } from "wombatifier/services/documentRowResultApis";
import "./bulkUploadFileDetails.css";
import { FIELD_NAME_MAP, fileItemsHeaders } from "./bulkUploadRowResultHeaders";

const GRID_STORAGE_NAME = "FileItems";
const FIELD_SORT_MAP = {
  row_number: "row_number",
  record_identifier: "entity_number",
  message: "message",
};

interface FileItem {
  record_identifier: string;
  row_number: number;
  message: string;
  action?: string;
}

interface BulkUploadFileDetailsProps {
  TabNavigator?: ({ activePageName }: { activePageName: string }) => JSX.Element;
}

const BulkUploadFileDetails: FC<BulkUploadFileDetailsProps> = ({ TabNavigator }) => {
  const { document_id } = useParams<{ document_id: string }>();
  const [gridApi, setGridApi] = useState<GridApi>();
  const [gridColumnApi, setGridColumnApi] = useState<ColumnApi>();
  const [fileMeta, setFileMeta] = useState<DocumentTypes.Details | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const { showFilters, updateShowFilters } = useShowFilterState("listWombatFilter", true);
  const angularBaseURL = restApiService.angularBaseURL();
  const baseListURL = "/ap/bulk_uploads/";
  const filterForExceptionsRef = useRef<boolean>(false);
  const [exceptionFilterApplied, setExceptionFilterApplied] = useState<boolean>(false);
  const [entityCounts, setEntityCounts] = useState<{
    [key in DocumentRowResultResultTypes]?: { [key: string]: number };
  }>({});
  const currentUser = useTypedSelector((state) => state.user);
  const navigationTab = [
    {
      path: `${baseListURL}/file_details/${document_id}`,
      pageName: "File Info",
      isActive: true,
    },
  ];

  const [paginationData, setPaginationData] = useState({
    firstRow: 0,
    lastRow: 0,
    totalRows: 0,
    totalPages: 0,
  });

  // Every time the grid needs new rows (such as first load or scrolling) this function will fire
  // We make the API call and then call the success function on the object the grid passed in
  const getRows = useCallback(async (params: IServerSideGetRowsParams) => {
    try {
      let filterParams = getParams(params, FIELD_NAME_MAP);
      if (filterForExceptionsRef.current) {
        filterParams.has_errors = true;
      }
      const baseSort = processSortModel(params.request.sortModel, FIELD_SORT_MAP);
      filterParams["sort"] = baseSort;
      const res = await DocumentRowResultApis.list({ filter: { ...filterParams, document_id: document_id } });
      if (res.data) {
        let mappedData = mapApiResponseToDataGridFormat(res.data);
        params.success({
          rowData: mappedData,
          rowCount: res.meta.count,
        });
      }
    } catch (error) {
      params.fail();
    }
  }, []);

  const handleEntityCounts = useCallback(async () => {
    const vendorCountsCreated = await DocumentRowResultApis.counts({
      document_id: document_id,
      filter: { entity_type: "Vendor", result_type: "created" },
    });
    const invoiceCountsCreated = await DocumentRowResultApis.counts({
      document_id: document_id,
      filter: { entity_type: "Invoice", result_type: "created" },
    });
    setEntityCounts({ created: { ...vendorCountsCreated, ...invoiceCountsCreated } });
  }, []);

  const mapApiResponseToDataGridFormat = useCallback((apiResponse: any[]): FileItem[] => {
    apiResponse.forEach((item) => {
      if (item.entity_type === "Invoice" || item.entity_type === "Vendor") {
        let link = null;
        let message = item.message;
        if (item.entity_id) {
          link = angularBaseURL;
          if (item.entity_type === "Vendor") {
            link += "vendors/";
            if (item.message === "Created" || item.message === "Updated") {
              message = item.entity_type + " " + item.message;
            }
          } else if (item.entity_type === "Invoice") {
            link += "invoices/";
            if (item.message === "Created") {
              message = item.entity_type + " " + item.message;
            }
          }
          link += item.entity_id;
        }
        item.action = link;
        item.message = message;
      }
    });
    return apiResponse;
  }, []);

  const getFileMeta = useCallback(async () => {
    try {
      const data = await DocumentApis.getOne(document_id);
      setFileMeta(data);
    } catch (error) {
      console.error("Error fetching file metadata:", error);
    }
  }, []);

  const fetchData = useCallback(async () => {
    setLoading(true);
    try {
      await getFileMeta();
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  }, []);

  const updateActionFilterRef = useCallback(
    (value: boolean) => {
      filterForExceptionsRef.current = value;
      setExceptionFilterApplied(value);
      gridApi?.refreshServerSide();
    },
    [filterForExceptionsRef, setExceptionFilterApplied, gridApi],
  );

  useEffect(() => {
    fetchData();
  }, [document_id]);

  const gridHeaders = fileItemsHeaders();

  useEffect(() => {
    saveDefaultOrder(GRID_STORAGE_NAME, gridHeaders.defaultOrder);
    handleEntityCounts();
  }, []);

  const gridReady = (params: GridReadyEvent) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
    //tell the grid that we are using a custom data source
    params.api.setGridOption("serverSideDatasource", { getRows });
  };

  const onPaginationChange = (params: PaginationChangedEvent) => {
    const paginationData = getPaginationData(params.api);
    setPaginationData(paginationData);
  };

  return (
    <>
      <Container fluid={true} className="pt-4 pb-0">
        <Row>
          <Col className="py-0">
            {TabNavigator ? (
              <TabNavigator activePageName="File Uploads" />
            ) : (
              <Breadcrumb breadcrumbItems={[{ name: "File Uploads", link: baseListURL }]} trailLabel={"File Details"} />
            )}
          </Col>
        </Row>
      </Container>

      <Container fluid={true} className={"pb-4 pt-0 file-details"}>
        {currentUser?.company?.can_view_file_details ? (
          <>
            {loading ? (
              <Spinner size="sm" className="mt-30" animation="border" variant="info" />
            ) : (
              <>
                <Row>
                  <Col>
                    <PageTitle title={fileMeta?.name} status={fileMeta?.status} />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <hr className={`full-border`} />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    {/* Please remove corresponding CSS rule to avoid pointer-events: none and cursor: default if this page gets more TabNavigation tabs in the future */}
                    <TabNavigation activePageName={"File Info"} routes={navigationTab} />
                    <ul className="list-unstyled">
                      <li>
                        <strong>Total records: </strong>
                        {fileMeta?.form_data?.object_creation_results?.total_count ?? "N/A"}
                      </li>
                      <li>
                        <strong>Total vendors created: </strong>
                        {entityCounts?.created?.Vendor ?? "N/A"}
                      </li>
                      <li>
                        <strong>Records processed successfully: </strong>
                        {fileMeta?.form_data?.object_creation_results?.total_count != null &&
                        fileMeta?.form_data?.object_creation_results?.error_count != null
                          ? fileMeta.form_data.object_creation_results.total_count -
                            fileMeta.form_data.object_creation_results.error_count
                          : "N/A"}
                      </li>
                      <li>
                        <strong>Records processed with exceptions (errors): </strong>
                        {fileMeta?.form_data?.object_creation_results?.error_count ?? "N/A"}
                      </li>
                      <li>
                        <strong>Source Document ID:</strong>{" "}
                        {fileMeta?.assets?.[0] ? (
                          <a href={fileMeta.assets[0].asset_expiring_url}>{document_id}</a>
                        ) : (
                          document_id
                        )}
                      </li>
                    </ul>
                  </Col>
                </Row>
                <Row className="grid-row">
                  <Col>
                    <Panel
                      header={
                        <div className="d-flex justify-content-between">
                          <div className="align-self-center">
                            <i className="icon icon-documents-blue-alt" /> File Items{" "}
                            <span className="d-inline-block">
                              <TooltipRender
                                title="This file was uploaded with the exceptions (errors) listed below. Use the Action menu to view each exception for details."
                                className="icon icon-info-blue"
                                placement="top"
                              />
                            </span>
                          </div>
                          <DropdownButton
                            id="sort-dropdown"
                            title={
                              <span>
                                <BsFilter />
                                Sort
                              </span>
                            }
                          >
                            <Dropdown.Item
                              onClick={() => {
                                updateActionFilterRef(true);
                              }}
                            >
                              {exceptionFilterApplied ? <BsCheckCircle /> : <BsCircle />} Show records with exceptions
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={() => {
                                updateActionFilterRef(false);
                              }}
                            >
                              {!exceptionFilterApplied ? <BsCheckCircle /> : <BsCircle />} Show all records
                            </Dropdown.Item>
                          </DropdownButton>
                        </div>
                      }
                    >
                      <Row className="file-details-grid-filter">
                        <Col className="px-0">
                          <GridFilterDropdown
                            gridApi={gridApi}
                            gridColumnApi={gridColumnApi}
                            gridStorageName={GRID_STORAGE_NAME}
                          />
                        </Col>
                        <Col className="px-0" style={{ flex: 0 }}>
                          <ToggleFilterButton
                            clickCallback={() => {
                              updateShowFilters(!showFilters);
                            }}
                          />
                        </Col>
                      </Row>
                      <Row className="file-details-datagrid">
                        <ServerSideDataGrid
                          columnDefs={gridHeaders.columnDefs}
                          defaultColDef={{
                            sortable: true,
                            filter: true,
                            floatingFilter: showFilters,
                          }}
                          gridReady={gridReady}
                          gridStorageName={GRID_STORAGE_NAME}
                          domLayout="normal"
                          onPaginationChanged={onPaginationChange}
                          paginationSize={25}
                          paginationOptions={[25, 50, 100]}
                          pagination
                        />
                      </Row>
                    </Panel>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <ActivityLog modelMethod="documents" modelActionId={`${document_id}`} />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Activities modelName="Document" modelId={document_id} />
                  </Col>
                </Row>
              </>
            )}
          </>
        ) : (
          <AccessDeniedPanel />
        )}
      </Container>
    </>
  );
};
export default BulkUploadFileDetails;
