import React, { memo } from "react";
import { Button, Navbar } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { BsXLg } from "react-icons/bs";
import { IUser } from "services/common/user/userTypes";
import Chat from "./chat";
import styles from "./chatWrapper.module.css";

type TChatWrapperProps = {
  title?: string;
  invoiceId: number;
  user: IUser;
  onClose: () => void;
  wrapped?: boolean;
};

const ChatWrapper: React.FC<TChatWrapperProps> = ({ title, invoiceId, user, wrapped = false, onClose }) => {
  const { t } = useTranslation();
  const message = title ? `${title} ${t("admin.pages.invoice.chat.title")}` : "";
  return (
    <div className={styles.chatWrapper}>
      <Navbar className={styles.chatHeader}>
        <Navbar.Brand className={styles.title}>{message}</Navbar.Brand>
        <Button
          variant="link"
          onClick={onClose}
          aria-label={t("admin.pages.invoice.chat.close")}
          className={styles.closeButton}
        >
          <BsXLg color="#fff" size={20} />
        </Button>
      </Navbar>
      <Chat invoiceId={invoiceId} user={user} wrapped={wrapped} />
    </div>
  );
};

export default memo(ChatWrapper);
