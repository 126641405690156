import AccountsTabEntries from "components/admin/accountsTabEntries";
import useGetUrLQuery from "components/admin/hooks/useGetUrlQuery";
import { RenderSwitch } from "components/forms/hookFormFields";
import React, { useCallback, useMemo } from "react";
import { Col, Row } from "react-bootstrap";
import { useFormContext, useWatch } from "react-hook-form";
import { useParams } from "react-router";
import { useTypedSelector } from "reducers";
import PoCommonSvc from "services/admin/purchaseOrders/poCommonSvc";
import { IUser } from "services/common/user/userTypes";
import styles from "../../purchaseOrders.module.css";

const ManagePoExpenses = () => {
  const currentUser: IUser = useTypedSelector((state) => state.user);
  const { getValues, setValue } = useFormContext();
  const poCommonSvc = PoCommonSvc.getInstance(currentUser);
  const [id, isPoCopy] = useWatch({ name: ["purchase_order.id", "purchase_order.is_copy"] });
  let query = useGetUrLQuery();
  const punchOutId = query.get("punchoutId");
  const { purchaseOrderID } = useParams<{ purchaseOrderID?: string }>();

  const showExpenses = useMemo(() => {
    //TODO-SANKET DO THIS FOR PO REUQEST EDIT
    // if (currentUser.company?.po_request?.hide_expense_and_item_lines) {
    //   return true;
    // }

    if (purchaseOrderID) {
      return (
        (currentUser.company.po_request_allow_expenses || punchOutId) &&
        !poCommonSvc.is_hide_po_request_lines() &&
        isPoCopy
      );
    } else {
      return (currentUser.company.po_request_allow_expenses || punchOutId) && !poCommonSvc.is_hide_po_request_lines();
    }
  }, [currentUser?.company?.po_request_allow_expenses, isPoCopy, poCommonSvc, punchOutId, purchaseOrderID]);

  const closedAllPoExpense = useCallback((closedAllExpense) => {
    const invoiceDebitAccounts = getValues("purchase_order.invoice_debit_accounts");
    if (Array.isArray(invoiceDebitAccounts) && invoiceDebitAccounts.length > 0) {
      let newInvoiceDebitAccounts = invoiceDebitAccounts.map((expense) => ({
        ...expense,
        is_closed: closedAllExpense,
      }));
      setValue("purchase_order.invoice_debit_accounts", newInvoiceDebitAccounts);
    }
  }, []);

  return (
    <>
      {showExpenses && (
        <Row>
          <Col md="12" className="d-flex flex-row justify-content-between">
            <h3 className={styles.poItemsTitle}>PO Expenses</h3>
            {id && (
              <div className={`d-flex ${styles.closedAllLineHeader}`}>
                <label className={`mr-2 mt-1 ${styles.closedAllLineLabel}`}>Close All Line</label>
                <RenderSwitch
                  name="closed_all_po_expense"
                  id="closed_all_po_expense"
                  className="switch"
                  callBack={closedAllPoExpense}
                />
              </div>
            )}
          </Col>
          <Col md="12">
            <AccountsTabEntries
              modelDataName="purchase_order"
              modelObject="savedPO"
              debitEntries="purchase_order.invoice_debit_accounts"
              creditEntries="purchase_order.invoice_credit_accounts"
              debitGroupName="PO_DEBIT_GROUPS"
              isPoRequest
              readonly
              noVendor={false}
              disabled={false}
            />
          </Col>
        </Row>
      )}
    </>
  );
};

export default ManagePoExpenses;
