export const system_names = [
  { label: "Accrualify Integration", value: "Accrualify Integration" },
  {
    label: "Accrualify Internal Service",
    value: "Accrualify Internal Service",
  },
  { label: "Acumatica", value: "Acumatica" },
  { label: "Blackbaud", value: "Blackbaud" },
  { label: "Cambridge", value: "Cambridge" },
  { label: "Comdata Payments", value: "Comdata Payments" },
  { label: "Comdata Virtual", value: "Comdata Virtual" },
  { label: "Comdata Web", value: "Comdata Web" },
  { label: "Expensify", value: "Expensify" },
  { label: "Forte", value: "Forte" },
  { label: "Intacct", value: "Intacct" },
  { label: "LOB Check", value: "LOB Check" },
  { label: "Netsuite", value: "Netsuite" },
  { label: "Corpay", value: "Corpay" },
  { label: "Punchout", value: "Punchout" },
  { label: "QuickBooks Enterprise", value: "Quickbooks Enterprise" },
  { label: "QuickBooks", value: "Quickbook" },
  { label: "SAP Cloud", value: "SAP Cloud" },
  { label: "SAP On Premise", value: "SAP On Premise" },
  { label: "VISA_S", value: "Stripe" },
  { label: "Spectrum", value: "Spectrum" },
  { label: "SVB ACH", value: "SVB ACH" },
  { label: "SVB Master Card", value: "SVB Master Card" },
  { label: "Transferwise", value: "Transferwise" },
  { label: "US Bank", value: "US Bank" },
  { label: "VISA", value: "VISA" },
  { label: "Xero", value: "Xero" },
  { label: "PostGrid Check", value: "PostGrid Check" },
  { label: "Sage300C", value: "Sage300C" },
  { label: "Deltek Vantagepoint", value: "Deltek Vantagepoint" },
];

export const erp_names = [
  { label: "AMB", value: "amb" },
  { label: "Blackbaud V7", value: "blackbaudv7" },
  { label: "Britebai", value: "britebai" },
  { label: "Caselle", value: "caselle" },
  { label: "Colleague", value: "colleague" },
  { label: "Deltek", value: "deltek" },
  { label: "Great Plains", value: "greatplains" },
  { label: "Great Plains (Harcum College)", value: "greatplains_harcumcollege" },
  { label: "Great Plains (Western Seminary)", value: "greatplains_westernseminary" },
  { label: "MarkSystems", value: "marksystems" },
  { label: "MatrixCare", value: "matrixcare" },
  { label: "Meditech", value: "meditech" },
  { label: "Netsuite", value: "netsuite" },
  { label: "Point Click Care", value: "pointclickcare" },
  { label: "Quick Books Desktop", value: "quickbooksdesktop" },
  { label: "Sage50", value: "sage50" },
  { label: "Sage100", value: "sage100" },
  { label: "Sage500", value: "sage500" },
  { label: "Sage Abila", value: "sageabila" },
  { label: "Senior Systems", value: "seniorsystems" },
  { label: "Traverse", value: "traverse" },
  { label: "Veracross", value: "veracross" },
  { label: "Wolfepak", value: "wolfepak" },
];

export const status = [
  { value: "ACTIVE", label: "ACTIVE" },
  { value: "INACTIVE", label: "INACTIVE" },
  { value: "PENDING", label: "PENDING" },
];

export const application_types = [
  {
    value: "AI",
    label: "INTEGRATION",
    system_names: [
      "Netsuite",
      "Quickbook",
      "Quickbooks Enterprise",
      "Intacct",
      "Spectrum",
      "SAP Cloud",
      "SAP On Premise",
      "Xero",
      "Sage300C",
    ],
  },
  {
    value: "AI2",
    label: "INTEGRATION v2",
    system_names: [
      "Netsuite",
      "Quickbook",
      "Quickbooks Enterprise",
      "Intacct",
      "Spectrum",
      "SAP Cloud",
      "SAP On Premise",
      "Xero",
      "Sage300C",
      "Deltek Vantagepoint",
      "Acumatica",
      "Blackbaud",
    ],
  },
  {
    value: "AUTHENTICATION",
    label: "AUTHENTICATION",
    system_names: [
      "Netsuite",
      "Quickbook",
      "Xero",
      "Blackbaud",
      "Intacct",
      "Quickbooks Enterprise",
      "Spectrum",
      "Sage300C",
      "Acumatica",
      "Deltek Vantagepoint",
    ],
  },
  { value: "BOA_PAYMENT", label: "XML EDI PAYMENT" },
  { value: "SEPA_PAYMENT", label: "SEPA PAYMENT" },
  { value: "AUS_EFT_PAYMENT", label: "AUS PAYMENT" },
  { value: "NZ_EFT_PAYMENT", label: "NZ PAYMENT" },
  {
    value: "CAMBRIDGE",
    label: "CAMBRIDGE",
    system_names: ["Cambridge"],
  },
  {
    value: "CC_PAYMENT",
    label: "CREDIT CARD PAYMENT",
    system_names: ["Comdata Payments"],
  },
  {
    value: "CHECK_PAYMENT",
    label: "CHECK PAYMENT",
    system_names: ["LOB Check", "US Bank", "PostGrid Check"],
  },
  { value: "CHECK_PRINT", label: "CHECK_PRINT" },
  { value: "CAN_EFT_PAYMENT", label: "CANADIAN EFT PAYMENT" },
  { value: "ERP_EXTRACT", label: "ERP EXTRACT" },
  {
    value: "EXPENSIFY_AUTHENTICATION",
    label: " EXPENSIFY AUTHENTICATION",
    system_names: ["Expensify"],
  },
  { value: "FTP", label: "FTP", system_names: ["US Bank"] },
  {
    value: "INVOICE_AUTOMATION",
    label: "INVOICE_AUTOMATION",
    system_names: ["INVOICE_AUTOMATION EMAIL"],
  },
  { value: "NACHA_PAYMENT", label: "NACHA PAYMENT" },
  {
    value: "CORPAY",
    label: "CORPAY",
    system_names: ["Corpay"],
  },
  {
    value: "PAYMENT",
    label: "ACH PAYMENT",
    system_names: ["SVB ACH", "Forte", "US Bank"],
  },
  { value: "PAYONEER", label: "PAYONEER" },
  { value: "PAYONEER_PAYMENT", label: "PAYONEER_PAYMENT" },
  {
    value: "PAYONEER_BRANDED_PAYMENT",
    label: "PAYONEER_BRANDED_PAYMENT",
  },
  { value: "PUNCHOUT", label: "PUNCHOUT", system_names: ["Punchout"] },
  {
    value: "TRANSFERWISE_PAYMENT",
    label: "TRANSFERWISE PAYMENT",
    system_names: ["Transferwise"],
  },
  {
    value: "TRANSFERWISE_QUOTE",
    label: "TRANSFERWISE QUOTE",
    system_names: ["Transferwise"],
  },
  {
    value: "VIRTUAL_CARD",
    label: "VIRTUAL CARD",
    system_names: ["SVB Master Card", "Stripe", "US Bank", "VISA", "Comdata Virtual", "Comdata Web"],
  },
  {
    value: "FILE_INTEGRATION",
    label: "FILE INTEGRATION",
    system_names: ["GREAT_PLAINS"],
  },
  {
    value: "FILE_INTEGRATION_INGEST",
    label: "FILE INTEGRATION_INGEST",
    system_names: [],
  },
  {
    value: "FILE_INTEGRATION_OUTBOUND",
    label: "FILE_INTEGRATION_OUTBOUND",
    system_names: [],
  },
];

export const punchout_providers = [
  { value: "Amazon", label: "Amazon" },
  { value: "Benco", label: "Benco" },
  { value: "BioLegend", label: "BioLegend" },
  { value: "Fisher Scientific", label: "Fisher Scientific" },
  { value: "Henry Schein", label: "Henry Schein" },
  { value: "Millipore Sigma", label: "Sigma Aldrich, Inc." },
  { value: "Miltenyi Biotec", label: "Miltenyi Biotec, Inc." },
  { value: "Office Depot", label: "Office Depot" },
  { value: "Rainin", label: "Mettler-Toledo Rainin, LLC" },
  { value: "Staples", label: "Staples" },
  { value: "Stem Cell", label: "Stemcell Technologies" },
  { value: "Takara Bio", label: "Takara Bio" },
  { value: "Thermo Fisher", label: "Life Technologies Corporation" },
  { value: "Avantor", label: "VWR International" },
  { value: "Zageno", label: "Zageno" },
];

export const authentication_methods = [
  {
    value: "CE_TOKEN_BASED",
    label: "CE_TOKEN_BASED",
    system_names: ["Accrualify Integration", "Accrualify Internal Service"],
    App_types: ["AUTHENTICATION"],
  },

  {
    value: "EXPENSIFY_TOKEN_BASED",
    label: "EXPENSIFY_TOKEN_BASED",
    system_names: ["Accrualify Integration", "Accrualify Internal Service", "Expensify"],
    App_types: ["EXPENSIFY_AUTHENTICATION", "AUTHENTICATION"],
  },
  {
    value: "QB_TOKEN_BASED",
    label: "QB_TOKEN_BASED",
    system_names: ["Accrualify Integration", "Accrualify Internal Service", "Quickbook"],
    App_types: ["AUTHENTICATION"],
  },
  {
    value: "XERO_TOKEN_BASED",
    label: "XERO_TOKEN_BASED",
    system_names: ["Accrualify Integration", "Accrualify Internal Service", "Xero"],
    App_types: ["AUTHENTICATION"],
  },
  {
    value: "BLACK_BAUD_TOKEN_BASED",
    label: "BLACK_BAUD_TOKEN_BASED",
    system_names: ["Accrualify Integration", "Accrualify Internal Service", "Blackbaud"],
    App_types: ["AUTHENTICATION"],
  },
  {
    value: "TOKEN_BASED",
    label: "TOKEN_BASED",
    system_names: [
      "Cambridge",
      "Intacct",
      "Netsuite",
      "Quickbooks Enterprise",
      "Quickbook",
      "SAP Cloud",
      "SAP On Premise",
      "Spectrum",
      "Xero",
      "Accrualify Integration",
      "Accrualify Internal Service",
      "Punchout",
      "Sage300C",
    ],
    App_types: [
      "CAMBRIDGE",
      "AI",
      "AI2",
      "BOA_PAYMENT",
      "SEPA_PAYMENT",
      "CAMBRIDGE",
      "CC_PAYMENT",
      "PUNCHOUT",
      "FTP",
      "NACHA_PAYMENT",
      "CHECK_PAYMENT",
      "CHECK_PRINT",
      "CAN_EFT_PAYMENT",
      "AUS_EFT_PAYMENT",
      "NZ_EFT_PAYMENT",
      "PAYMENT",
    ],
  },
  {
    value: "CORPAY_TOKEN_BASED",
    label: "CORPAY_TOKEN_BASED",
    system_names: ["Corpay"],
    App_types: ["CORPAY"],
  },
  {
    value: "TW_TOKEN_BASED",
    label: "TW_TOKEN_BASED",
    system_names: ["Accrualify Integration", "Accrualify Internal Service", "Transferwise"],
    App_types: ["TRANSFERWISE_QUOTE", "AUTHENTICATION"],
  },
  {
    value: "CREDENTIAL",
    label: "CREDENTIAL",
    system_names: [
      "Transferwise",
      "SVB Master Card",
      "SVB ACH",
      "LOB Check",
      "Forte",
      "Quickbook",
      "Quickbooks Enterprise",
      "SAP On Premise",
      "Intacct",
      "Netsuite",
      "SAP Cloud",
      "Xero",
      "US Bank",
      "Cambridge",
      "Intacct",
      "Corpay",
      "Stripe",
      "Spectrum",
      "VISA",
      "Accrualify Integration",
      "Accrualify Internal Service",
      "Punchout",
      "PostGrid Check",
      "Sage300C",
      "Deltek Vantagepoint",
      "Acumatica",
      "Blackbaud",
    ],
    App_types: [
      "TRANSFERWISE_PAYMENT",
      "VIRTUAL_CARD",
      "PAYMENT",
      "CHECK_PAYMENT",
      "AI",
      "CAMBRIDGE",
      "AI2",
      "CORPAY",
      "FTP",
      "PAYONEER",
      "PAYONEER_PAYMENT",
      "PAYONEER_BRANDED_PAYMENT",
      "BOA_PAYMENT",
      "SEPA_PAYMENT",
      "CAMBRIDGE",
      "CC_PAYMENT",
      "PUNCHOUT",
      "CHECK_PRINT",
      "NACHA_PAYMENT",
      "CHECK_PAYMENT",
      "CAN_EFT_PAYMENT",
      "AUS_EFT_PAYMENT",
      "NZ_EFT_PAYMENT",
      "PAYMENT",
      "AUTHENTICATION",
    ],
  },
  {
    value: "None",
    label: "None",
    system_names: [
      "Punchout",
      "Cambridge",
      "Intacct",
      "Netsuite",
      "Corpay",
      "Quickbooks Enterprise",
      "Quickbook",
      "SAP Cloud",
      "SAP On Premise",
      "Spectrum",
      "Xero",
      "Accrualify Integration",
      "Accrualify Internal Service",
      "Comdata Payments",
      "Comdata Virtual",
      "Comdata Web",
      "Sage300C",
    ],
    App_types: [
      "INVOICE_AUTOMATION",
      "PUNCHOUT",
      "CAMBRIDGE",
      "AI",
      "AI2",
      "CORPAY",
      "BOA_PAYMENT",
      "SEPA_PAYMENT",
      "CAMBRIDGE",
      "CHECK_PRINT",
      "CC_PAYMENT",
      "NACHA_PAYMENT",
      "FTP",
      "CHECK_PAYMENT",
      "CHECK_PRINT",
      "CAN_EFT_PAYMENT",
      "AUS_EFT_PAYMENT",
      "NZ_EFT_PAYMENT",
      "PAYMENT",
      "VIRTUAL_CARD",
    ],
  },
];

export const Manual_authentication_systems = [
  "Acumatica",
  "Intacct",
  "Netsuite",
  "Quickbooks Enterprise",
  "Spectrum",
  "Sage300C",
  "Deltek Vantagepoint",
];
