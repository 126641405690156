import i18n from "i18next";
import { change } from "redux-form";
import DepartmentPicker from "../../../../../../components/admin/pickers/reduxFormPickers/departmentPicker";
import { RenderCheckBox, RenderFieldNumber } from "../../../../../../components/forms/bootstrapFields";
import AddressPicker from "../../../../../../components/pickers/reduxFormPicker/addressPicker";
import BusinessUnitPicker from "../../../../../../components/pickers/reduxFormPicker/businessUnitPicker";
import LocationPicker from "../../../../../../components/pickers/reduxFormPicker/locationPicker";
import TeamsPicker from "../../../../../../components/pickers/reduxFormPicker/teamPicker";
import { CreateNotification, NotificationType } from "../../../../../../services/general/notifications";
import { maxValue, minValue, required, wholeNumber } from "../../../../../../services/validations/reduxFormValidation";
import { CompanyDefaultsTypes } from "../../settingsTypes";
import PORequestAttachment from "./component/poRequestAttachment";

export const poSettingsHeaderLevel: CompanyDefaultsTypes.CommonSettingFieldType[] = [
  {
    name: "company_default.default_location",
    tooltip: {
      show: true,
      title: "Display location on Purchase Order form as default.",
      placement: "top",
    },
    label: "Default PO Location",
    type: "react-select",
    component: LocationPicker,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 1,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.po_default_duration_in_months",
    tooltip: {
      show: true,
      title: "PO end date will be calculated by using entered month from start date.",
      placement: "top",
    },
    label: "Default Purchase Order Duration:",
    type: "number",
    component: RenderFieldNumber,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 1,
    validation: [minValue(0), maxValue(1000)],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.purchase_order.default_department",
    tooltip: {
      show: true,
      title: "Display department on Purchase Order form as default.",
      placement: "top",
    },
    label: "Default PO Department",
    type: "react-select",
    component: DepartmentPicker,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 2,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.default_billing_address_id",
    tooltip: {
      show: true,
      title: "Display Default Billing Address",
      placement: "top",
    },
    label: "Default Billing Address",
    type: "react-select",
    component: AddressPicker,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 2,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.purchase_order.default_business_unit",
    tooltip: {
      show: true,
      title: "Display Business on Purchase Order form as default.",
      placement: "top",
    },
    label: "Default PO Business Unit",
    type: "react-select",
    component: BusinessUnitPicker,
    isShow: (isShowObj) => {
      const { company_default } = isShowObj;
      return company_default.id;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 3,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.po_hide_department",
    tooltip: {
      show: true,
      title: "Department field at the header level will be hidden from admin PO forms",
      placement: "top",
    },
    label: "Hide header level department",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {
      const { dispatch, formName } = onChangeObj;
      dispatch(change(formName, "company_default.purchase_order.department.is_required", false));
    },
    col: 1,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.purchase_order.department.is_required",
    tooltip: {
      show: true,
      title: "Department will be required field at header level on admin PO forms",
      placement: "top",
    },
    label: "Department Required",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      const { company_default } = isShowObj;
      return !company_default.po_hide_department;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 1,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.po_workflow_name_required",
    tooltip: {
      show: true,
      title: "Workflow Name is required",
      placement: "top",
    },
    label: "Workflow Name Required",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 1,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.purchase_order_hide_add_button",
    tooltip: {
      show: true,
      title: "Add PO button will be hidden from purchase order",
      placement: "top",
    },
    label: "Hide Add PO button",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 1,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.purchase_order.hide_categories",
    tooltip: {
      show: true,
      title: "Hide categories from Purchase Order",
      placement: "top",
    },
    label: "Hide Categories",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 1,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.purchase_order.business_unit.is_hide",
    tooltip: {
      show: true,
      title: "Hide the Business Unit field from the Admin Purchase Order and Request PO forms.",
      placement: "top",
    },
    label: "Hide Business unit",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 1,
    validation: [],
    require: false,
    class: "",
  },
  {
    name: "company_default.purchase_order.location.is_hide",
    tooltip: {
      show: true,
      title: "Hide location field from admin Purchase Order form",
      placement: "top",
    },
    label: "Hide Location",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 1,
    validation: [],
    require: false,
    class: "",
  },
  {
    name: "company_default.allow_vcard_for_po",
    tooltip: {
      show: true,
      title: "Corporate Card field will show on PO form.",
      placement: "top",
    },
    label: "Allow Corporate Card for PO",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 1,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.purchase_order.project.is_show",
    tooltip: {
      show: true,
      title: "It will allow to show Project field on admin PO form and request PO form at header level.",
      placement: "top",
    },
    label: "Show header level project",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 2,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.purchase_order.show_po_required_vendors",
    tooltip: {
      show: true,
      title: "It will allow to show only PO Required vendor on All PO forms.",
      placement: "top",
    },
    label: "Show only PO Required Vendors",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 2,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.purchase_order.show_eligible_1099_on_lines",
    tooltip: {
      show: true,
      title: "1099 checkbox will be visible on line level.",
      placement: "top",
    },
    label: "Show 1099 on PO line level",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 2,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.po_request_is_attachment_required",
    tooltip: {
      show: true,
      title: "Attachments will be required field on PO request form and admin PO form.",
      placement: "top",
    },
    label: "Attachments required",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {
      const { dispatch, formName } = onChangeObj;
      dispatch(change(formName, "company_default.po_request_attachment_required_on_min_amount", ""));
    },
    col: 2,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.po_request_attachment_required_on_min_amount",
    tooltip: {
      show: true,
      title: "Attachments will be required field which will trigger on the entered amount.",
      placement: "top",
    },
    label: "Attachment Required On Amount",
    type: "number",
    component: PORequestAttachment,
    isShow: (isShowObj) => {
      const { company_default } = isShowObj;
      return !company_default.po_request_is_attachment_required;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 2,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.purchase_order.po_approval_completion_team",
    tooltip: {
      show: true,
      title:
        "Notify all members of the selected team if a PO approval is APPROVED or REJECTED. This will result in all team members receiving a deluge of emails, so use with caution.",
      placement: "top",
    },
    label: "Notify team of PO Approval Completion",
    type: "react-select",
    component: TeamsPicker,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 3,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.allow_po_sub_type",
    tooltip: {
      show: true,
      title: "PO subtype will be visible if PO type is Single Vendor ",
      placement: "top",
    },
    label: "Allow PO Subtype",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 2,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.purchase_order.show_point_of_contact",
    tooltip: {
      show: true,
      title: "Internal Point of Contact field will show on PO form.",
      placement: "top",
    },
    label: "Allow Point of Contact",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {
      const { formName, dispatch } = onChangeObj;
      dispatch(change(formName, "company_default.purchase_order.po_print.hide_point_of_contact", false));
    },
    col: 3,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.purchase_order.notify_low_balance",
    tooltip: {
      show: true,
      title: "Notify admin/requestor on low PO balance below %",
      placement: "top",
    },
    label: " Notify low PO balance",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {
      const { formName, dispatch, company_default } = onChangeObj;
      if (company_default?.purchase_order?.notify_low_balance) {
        dispatch(change(formName, "company_default.purchase_order.notify_low_balance_percentage", null));
      }
    },
    col: 3,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.purchase_order.notify_low_balance_percentage",
    tooltip: {
      show: true,
      title: "Low PO balance percentage",
      placement: "top",
    },
    type: "number",
    label: "Low balance percentage",
    component: RenderFieldNumber,
    isShow: (isShowObj) => {
      const { company_default } = isShowObj;
      return company_default.purchase_order?.notify_low_balance;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 3,
    customProps: {
      min: 0,
      max: 100,
    },
    validation: [required, wholeNumber, minValue(0), maxValue(100)],
    require: true,
    class: "",
  },
  {
    name: "company_default.purchase_order.inherit_department_business_unit_from_requestor",
    tooltip: {
      show: true,
      title:
        "The selected Requestor's Business Unit and Department will be inherited only at the header level of the Request PO and Admin PO forms, this inheritance will not apply to the line item level.",
      placement: "top",
    },
    type: "checkbox",
    label: "Inherit Business Unit and Department from Requestor",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 3,
    validation: [],
    require: undefined,
    class: "",
  },
];

export const poSettingsItemLine: CompanyDefaultsTypes.CommonSettingFieldType[] = [
  {
    name: "company_default.po_item_line_hide_tax",
    tooltip: {
      show: true,
      title: "Tax code will be hidden from PO line level.",
      placement: "top",
    },
    label: "Hide tax from PO item line level",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 1,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.allow_edit_po_item_subtotal",
    tooltip: {
      show: true,
      title:
        "Allow to edit PO item sub amount. Once we changed subtotal it will calculate unit price according to qty.",
      placement: "top",
    },
    label: "Allow edit PO item sub amount",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 2,
    validation: [],
    require: false,
    class: "",
  },
  {
    name: "company_default.po_request_disabled_itemline_department",
    tooltip: {
      show: true,
      title: "Item line level department field will be hidden from Request as well as Admin PO form",
      placement: "top",
    },
    label: "Hide item line department",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 1,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.po_request_hide_item_line_project",
    tooltip: {
      show: true,
      title: "Item line level project field will be hidden from Request as well as Admin PO form",
      placement: "top",
    },
    label: "Hide item line project",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {
      const { formName, dispatch } = onChangeObj;
      dispatch(change(formName, "company_default.purchase_order.items.project.is_required", false));
    },
    col: 1,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.purchase_order.items.project.is_required",
    tooltip: {
      show: true,
      title: "Item level project will be required field at Request PO as well as Admin PO form",
      placement: "top",
    },
    label: "Item level project required",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      const { company_default } = isShowObj;
      return !company_default?.po_request_hide_item_line_project;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 1,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.purchase_order.items.business_unit.is_hide",
    tooltip: {
      show: true,
      title: "Item line level business unit field will be hidden from 'PO Request' form and Admin 'Add PO' form",
      placement: "top",
    },
    label: "Hide item line business unit",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {
      const { formName, dispatch } = onChangeObj;
      dispatch(change(formName, "company_default.purchase_order.items.business_unit.is_required", false));
    },
    col: 1,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.purchase_order.items.business_unit.is_required",
    tooltip: {
      show: true,
      title: "Business unit will be required field at Request PO as well as Admin PO on item line level",
      placement: "top",
    },
    label: "Item level business unit required",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      const { company_default } = isShowObj;
      return !company_default.purchase_order?.items?.business_unit?.is_hide;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 1,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.po_request_disabled_itemline_location",
    tooltip: {
      show: true,
      title: "Hide location field from 'PO Request' form and Admin 'Add PO' item line level form once selected",
      placement: "top",
    },
    label: "Hide item line Location",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 1,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.po_item_location_required",
    tooltip: {
      show: true,
      title: "Item level location will be required field at the PO Form",
      placement: "top",
    },
    label: "Item level location required",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      const { company_default } = isShowObj;
      return !company_default.po_request_disabled_itemline_location;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 1,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.purchase_order.items.receive_by_date.is_show",
    tooltip: {
      show: true,
      title: "Receive by field will show on PO print and PO item line level.",
      placement: "top",
    },
    label: "Show Receive by date",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {
      const { formName, dispatch, company_default } = onChangeObj;
      if (company_default.purchase_order?.items?.receive_by_date) {
        dispatch(change(formName, "company_default.purchase_order.items.receive_by_date.is_required", false));
      }
    },
    col: 1,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.purchase_order.items.receive_by_date.is_required",
    tooltip: {
      show: true,
      title: "Item line level business unit field will be hidden from 'PO Request' form and Admin 'Add PO' form",
      placement: "top",
    },
    label: "Receive by date Required",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      const { company_default } = isShowObj;
      return company_default.purchase_order?.items?.receive_by_date?.is_show;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 1,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.purchase_order.items.disable_unit_price",
    tooltip: {
      show: true,
      title: "User is not allowed to change or edit price of item while creating or approving or editing PO.",
      placement: "top",
    },
    label: "Disable unit price field",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 1,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.po_request_hide_po_items",
    tooltip: {
      show: true,
      title: "PO items section will be hidden from PO request form and PO admin form.",
      placement: "top",
    },
    label: "Hide PO items from purchase order form",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {
      const { company_default, dispatch, formName } = onChangeObj;
      if (company_default.po_request_hide_po_items && !company_default.po_request_allow_expenses) {
        CreateNotification(
          "Warning",
          "PO Require items or expense or both. Please select one of them.",
          NotificationType.warning,
        );
        dispatch(change(formName, "company_default.po_request_hide_po_items", false));
      }
    },
    col: 2,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.po_inherit_header_location_to_item_line",
    tooltip: {
      show: true,
      title:
        "PO header level location will be inherited at the item level. This applies to the 'PO Request' and Admin 'Add PO' forms if selected",
      placement: "top",
    },
    label: "Inherit PO header level location to item level",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 2,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.purchase_order.items.inherit_header_project",
    tooltip: {
      show: true,
      title: "Inherit header level project on admin PO form and request PO form item line level.",
      placement: "top",
    },
    label: "Inherit PO header level project on item level",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 2,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.purchase_order.items.inherit_header_department",
    tooltip: {
      show: true,
      title:
        "PO header level department will be inherited at the item level. This applies to the 'PO Request' and Admin 'Add PO' forms if selected",
      placement: "top",
    },
    label: "Inherit PO header level department to item level",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 2,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.po_items_show_service_start_end_date",
    tooltip: {
      show: true,
      title: "Allow to show service start and end date on PO item line level once selected.",
      placement: "top",
    },
    label: "Show service start and end date on PO line item level",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 2,
    validation: [],
    require: undefined,
    class: "",
  },
  //  {
  //    name: "company_default.purchase_order.items.show_budget",
  //    tooltip: {
  //      show: true,
  //      title:
  //        "Allow to show budget field on PO item line level once selected",
  //      placement: "top",
  //    },
  //    label: "Show budget on PO item line",
  //    type: "checkbox",
  //    component: RenderCheckBox,
  //    isShow: (isShowObj) => {
  //     return true
  //    },
  //    onChangeCallBack: (onChangeObj) => {},
  //    col: 2,
  //    validation: [],
  //    require: undefined,
  //    class: "",
  //  },
  {
    name: "company_default.purchase_order.show_employee_items_and_expenses",
    tooltip: {
      show: true,
      title: "Allow to show employee field on PO item and expense line level once selected.",
      placement: "top",
    },
    label: "Show employee on PO line level",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 2,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.purchase_order.items.memo.is_required",
    tooltip: {
      show: true,
      title: "Item line memo will be required for Admin PO and Request PO form if setting turned On",
      placement: "top",
    },
    label: "Item line memo required",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 2,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.purchase_order.items.disable_product_items_filter_by_vendor",
    tooltip: {
      show: true,
      title: "PO line items will be filtered by vendor",
      placement: "top",
    },
    label: "Filter PO line items by vendor",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    customProps: { isInverted: true },
    onChangeCallBack: (onChangeObj) => {},
    col: 2,
    validation: [],
    require: undefined,
    class: "",
  },
];

export const poSettingsExpenseLine: CompanyDefaultsTypes.CommonSettingFieldType[] = [
  {
    name: "company_default.enable_tax_to_po_expenses",
    tooltip: {
      show: true,
      title: "Enable Tax to PO Expenses.",
      placement: "top",
    },
    label: "Enable Tax to PO Expenses",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 1,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.purchase_order.expenses.project.is_hide",
    tooltip: {
      show: true,
      title: "Expense line level project field will be hidden from Request PO as well as Admin PO form",
      placement: "top",
    },
    label: "Hide project",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {
      const { formName, dispatch } = onChangeObj;
      dispatch(change(formName, "company_default.purchase_order.expenses.project.is_required", false));
    },
    col: 1,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.purchase_order.expenses.project.is_required",
    tooltip: {
      show: true,
      title: "Expense line level project will be required friels on Request PO as well as Admin PO form",
      placement: "top",
    },
    label: "Expense line level project required",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      const { company_default } = isShowObj;
      return !company_default?.purchase_order?.expenses?.project?.is_hide;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 1,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.purchase_order.expenses.business_unit.is_hide",
    tooltip: {
      show: true,
      title: "Expense line level business unit field will be hidden from Request PO as well as Admin PO form.",
      placement: "top",
    },
    label: "Hide expense line business unit",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {
      const { formName, dispatch } = onChangeObj;
      dispatch(change(formName, "company_default.purchase_order.expenses.business_unit.is_required", false));
    },
    col: 1,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.purchase_order.expenses.business_unit.is_required",
    tooltip: {
      show: true,
      title: "Business unit will be required field at Request PO as well as Admin PO on expense line level",
      placement: "top",
    },
    label: "Required Business unit",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      const { company_default } = isShowObj;
      return !company_default.purchase_order?.expenses?.business_unit?.is_hide;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 1,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.purchase_order.expenses.department.is_hide",
    tooltip: {
      show: true,
      title: "Expense line level department field will be hidden from  Admin PO form",
      placement: "top",
    },
    label: "Hide department",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {
      const { formName, dispatch } = onChangeObj;
      dispatch(change(formName, "company_default.purchase_order.expenses.department.is_required", false));
    },
    col: 1,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.purchase_order.expenses.department.is_required",
    tooltip: {
      show: true,
      title: "Department will be required field at PO expense line level.",
      placement: "top",
    },
    label: "Make department required",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      const { company_default } = isShowObj;
      return !company_default.purchase_order?.expenses?.department?.is_hide;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 1,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.purchase_order.expenses.location.is_hide",
    tooltip: {
      show: true,
      title: "Expense line level location field will be hidden from  Admin PO form",
      placement: "top",
    },
    label: "Hide location",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {
      const { dispatch, formName } = onChangeObj;
      dispatch(change(formName, "company_default.po_expense_location_required", false));
    },
    col: 1,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.po_expense_location_required",
    tooltip: {
      show: true,
      title: "Expense level location will be required field in the PO Form",
      placement: "top",
    },
    label: "Required location",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      const { company_default } = isShowObj;
      return !company_default.purchase_order?.expenses?.location?.is_hide;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 1,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.po_inherit_header_location_to_expense_line",
    tooltip: {
      show: true,
      title:
        "PO header level location will be inherited at the expenses level. This applies to the 'PO Request' and Admin 'Add PO' forms if selected.",
      placement: "top",
    },
    label: "Inherit PO header level location to expenses level",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 2,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.purchase_order.expenses.inherit_header_project",
    tooltip: {
      show: true,
      title: "Inherit header level project on admin PO form and request PO form expense line level.",
      placement: "top",
    },
    label: "Inherit PO header level project on expense level",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 2,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.purchase_order.expenses.inherit_header_department",
    tooltip: {
      show: true,
      title:
        "PO header level department will be inherited at expenses level. This applies to the 'PO Request' and Admin 'Add PO' forms if selected",
      placement: "top",
    },
    label: "Inherit PO header level department to expenses level",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 2,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.purchase_order.expenses.show_start_end_date",
    tooltip: {
      show: true,
      title: "Allow to show service start and end date on PO Expense level once selected.",
      placement: "top",
    },
    label: "Show service start and end date",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 2,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.purchase_order.expenses.memo.is_required",
    tooltip: {
      show: true,
      title: "Expense line memo will be required for Admin PO and Request PO form if setting is turned on.",
      placement: "top",
    },
    label: "Expense line memo required",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 2,
    validation: [],
    require: undefined,
    class: "",
  },
];

export const poSettingsPrint: CompanyDefaultsTypes.CommonSettingFieldType[] = [
  {
    name: "company_default.print_workflow",
    tooltip: {
      show: true,
      title: "Workflow will be printed on PO",
      placement: "top",
    },
    label: "Print Workflow",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 1,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.purchase_order.po_print.show_water_mark",
    tooltip: {
      show: true,
      title: "It will print the DRAFT watermark when the PO is in Pending or Draft status.",
      placement: "top",
    },
    label: "Print PO with watermark",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 1,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.po_print_hide_po_items",
    tooltip: {
      show: true,
      title: "PO items name will be hidden from PO print once selected.",
      placement: "top",
    },
    label: "Hide PO item name from PO print",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 1,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.purchase_order.po_print.hide_company_profile",
    tooltip: {
      show: true,
      title: "Company profile detail will be hidden from PO print once selected.",
      placement: "top",
    },
    label: "Hide company profile info in the PO print",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 1,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.po_print_hide_items",
    tooltip: {
      show: true,
      title: "PO items section will be hidden from PO print.",
      placement: "top",
    },
    label: "Hide PO items section from PO print",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 1,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.purchase_order.po_print.hide_description",
    tooltip: {
      show: true,
      title: "PO header level description will be hidden from PO print once selected.",
      placement: "top",
    },
    label: "Hide PO description from PO print.",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 1,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.purchase_order.po_print.show_item_line_project",
    tooltip: {
      show: true,
      title: "Project will be shown on PO item line level",
      placement: "top",
    },
    label: "Show project on po item line",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 1,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.show_date_signature_on_po_print",
    tooltip: {
      show: true,
      title: "Show Date and Signature on PO print",
      placement: "top",
    },
    label: "Date and Signature on PO print",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 1,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.purchase_order.po_print.hide_requestor",
    tooltip: {
      show: true,
      title: "Purchase Order Requestor will be hidden from PO print",
      placement: "top",
    },
    label: "Hide Requestor",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 2,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.purchase_order.po_print.hide_tax",
    tooltip: {
      show: true,
      title: "Item and expense line tax will be hidden from PO print",
      placement: "top",
    },
    label: "Hide Taxes",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 2,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.purchase_order.po_print.hide_shipping_address_email",
    tooltip: {
      show: true,
      title: "Shipping address email will be hidden from PO print once selected.",
      placement: "top",
    },
    label: "Hide Shipping address email from PO print.",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 2,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.po_print_hide_expenses",
    tooltip: {
      show: true,
      title: "PO Expense section will be hidden from PO print.",
      placement: "top",
    },
    label: "Hide PO Expense section from PO print",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {
      const { dispatch, formName } = onChangeObj;

      dispatch(change(formName, "company_default.purchase_order.expenses.account.hide_in_print_po", false));
    },
    col: 2,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.purchase_order.expenses.account.hide_in_print_po",
    tooltip: {
      show: true,
      title: "Account name from expense section will be hidden from PO print once selected.",
      placement: "top",
    },
    label: "Hide account name from PO print expense section",
    type: "checkbox",
    component: RenderCheckBox,

    isShow: (isShowObj) => {
      const { company_default } = isShowObj;
      return !company_default.po_print_hide_expenses;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 2,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.po_print_hide_department_subsidiary",
    tooltip: {
      show: true,
      title: "Department and subsidiary will be hidden from purchase order print.",
      placement: "top",
    },
    label: "Hide department and subsidiary from purchase order print",
    type: "checkbox",
    component: RenderCheckBox,

    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 2,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.po_request_hide_shipping_address_from_po_print",
    tooltip: {
      show: true,
      title: "Shipping address will be hidden from PO print",
      placement: "top",
    },
    label: "Hide shipping address from PO print ",
    type: "checkbox",
    component: RenderCheckBox,

    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 2,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.purchase_order.hide_send_po_button",
    tooltip: {
      show: true,
      title:
        "Check this box to always hide the 'Send PO' button on the PO Details page. By default, the button is only visible if the PO is in OPEN status.",
      placement: "top",
    },
    label: 'Hide "Send PO" button from PO Details page',
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 2,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.purchase_order.po_print.hide_point_of_contact",
    tooltip: {
      show: true,
      title: "Point of contact will be hidden from PO print once selected.",
      placement: "top",
    },
    label: "Hide Point of contact from PO print.",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      const { company_default } = isShowObj;
      return !company_default.purchase_order?.show_point_of_contact;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 2,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.use_subsidiary_address",
    tooltip: {
      show: true,
      title: "Subsidiary address will replace parent company address on PO print page header",
      placement: "top",
    },
    label: "Use Subsidiary Address",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 2,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.purchase_order.po_print.hide_company_logo",
    tooltip: {
      show: true,
      title: "Company logo will be hidden at PO print",
      placement: "top",
    },
    label: "Hide Company Logo",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 3,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.purchase_order.po_print.hide_status",
    tooltip: {
      show: true,
      title: "Enable this setting to hide the status from PO print PDF.",
      placement: "top",
    },
    label: "Hide Status from PO print. ",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 3,
    validation: [],
    require: false,
    class: "",
  },
  {
    name: "company_default.purchase_order.po_print.show_line_business_unit",
    tooltip: {
      show: true,
      title:
        "Enable this setting to include the Business Unit on PO item lines and expense lines in the PO print view.",
      placement: "top",
    },
    label: "Show line business unit",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 3,
    validation: [],
    require: false,
    class: "",
  },
  {
    name: "company_default.purchase_order.po_print.allow_line_level_location_address",
    tooltip: {
      show: true,
      title: "Enable this setting to print line item shipping address",
      placement: "top",
    },
    label: "Print Line Item Shipping Address",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 3,
    validation: [],
    require: false,
    class: "",
  },
];

export const poSettingsApproval: CompanyDefaultsTypes.CommonSettingFieldType[] = [
  {
    name: "company_default.po_approval_show_gl_impact",
    tooltip: {
      show: true,
      title: "GL impact section will show up in PO approval page",
      placement: "top",
    },
    label: "Show PO Approval GL Impact Section",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 1,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.po_approval_show_expense",
    tooltip: {
      show: true,
      title: "PO expense section will show up in PO approval page",
      placement: "top",
    },
    label: "Show PO Approval Expense Section",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 1,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.po_approval_notify_submitter",
    tooltip: {
      show: true,
      title: "Notify PO Submitter of PO Approval if PO is submitted on behalf of the specified requestor",
      placement: "top",
    },
    label: "Notify PO submitter of PO Approval",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 2,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.purchase_order.hold_po_requestor_invoice_approval_emails",
    tooltip: {
      show: true,
      title:
        "Withhold the 'invoice approval' email if it's being sent because the recipient requested the invoice's PO.",
      placement: "top",
    },
    label: "Withhold invoice approval notification for PO requestors",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 2,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.purchase_order.disable_rejection_for_po_approvals",
    tooltip: {
      show: true,
      title: i18n.t("companySettings.poSettings.disablePoApprovalRejectionTooltip"),
      placement: "top",
    },
    label: i18n.t("companySettings.poSettings.disablePoApprovalRejection"),
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 1,
    validation: [],
    require: undefined,
    class: "",
  },
];
