import { angularBaseURL } from "providers/restApi";
import React, { memo, useMemo } from "react";
import { Badge } from "react-bootstrap";
import styles from "./invoiceReceiptMapping.module.css";

type TInvoiceReceiptMappingProps = {
  invoiceItems: any[];
  receipts: any[];
};

const goToReceipt = (receiptNumber: string) => {
  window.open(`${angularBaseURL}receipt/${receiptNumber}`, "_blank");
};

const InvoiceReceiptMapping = ({ invoiceItems, receipts }: TInvoiceReceiptMappingProps) => {
  const receiptLookup = useMemo(() => {
    const lookup: any = {};

    receipts.forEach((receipt) => {
      const receiptNumber = receipt.number;
      receipt.receipt_items.forEach((item: any) => {
        const productName = item.product_name;
        if (productName) {
          if (!lookup[productName]) {
            lookup[productName] = [];
          }
          lookup[productName].push({
            receiptNumber,
            id: receipt.id,
          });
        }
      });
    });

    return lookup;
  }, [receipts]);

  const invoiceToReceiptsMap = useMemo(() => {
    const map = new Map();

    invoiceItems.forEach((invoiceItem) => {
      const productName = invoiceItem.product_name;
      if (!productName) return;

      const matchedReceipts = receiptLookup[productName];
      if (matchedReceipts && matchedReceipts.length > 0) {
        map.set(invoiceItem.id, matchedReceipts);
      } else {
        map.set(invoiceItem.id, []);
      }
    });

    return map;
  }, [invoiceItems, receiptLookup]);

  return (
    <span className={styles.evenSpacingContainer}>
      {Array.from(invoiceToReceiptsMap.entries()).map(([invoiceItemId, receiptData]) =>
        receiptData.length > 0
          ? receiptData.map((r: any, idx: number) => (
              <Badge pill className={styles.receiptLink} variant="primary" onClick={() => goToReceipt(r.id)}>
                {r.receiptNumber}
              </Badge>
            ))
          : null,
      )}
    </span>
  );
};

export default memo(InvoiceReceiptMapping);
