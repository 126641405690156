import React from "react";
import DateFilter from "../reportFilters/date";
import DropdownFilter from "../reportFilters/dropdown";
import ScheduledDateRange from "../reportFilters/scheduledDateRange";
import VendorSearchDropdown from "../reportFilters/vendorSearchDropdown";

const CreditMemoDetailedListing = ({ scheduled }: { scheduled?: boolean }) => {
  const credit_memo_status_options = [
    { value: "", label: "ALL" },
    { value: "PENDING", label: "PENDING" },
    { value: "AVAILABLE", label: "AVAILABLE" },
    { value: "APPLIED", label: "APPLIED" },
    { value: "PROCESSING", label: "PROCESSING" },
    { value: "FAILED", label: "FAILED" },
    { value: "VOID", label: "VOID" },
    { value: "REJECTED", label: "REJECTED" },
  ];
  const renderDateFilter = () => {
    if (scheduled) {
      return <ScheduledDateRange name={"Schedule Date Range"} code={"schedule_date_range"} options={[]} isRequired />;
    } else {
      return (
        <>
          <DateFilter name={"Start Date"} code={"start_date"} options={[]} isRequired />
          <DateFilter name={"End Date"} code={"end_date"} options={[]} isRequired />
        </>
      );
    }
  };

  return (
    <>
      <div>{renderDateFilter()}</div>
      <VendorSearchDropdown name={"Vendor"} code={"vendor_id"} />
      <DropdownFilter name={"Credit Memo Status"} code={"status"} options={credit_memo_status_options} />
    </>
  );
};

export default CreditMemoDetailedListing;
