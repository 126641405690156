import { ColDef } from "ag-grid-community";
import PopoverRender from "components/popovers/popoverRender";
import { sortBy } from "lodash";
import React from "react";
import { Col, Row } from "react-bootstrap";
import { FILE_INTEGRATION_STATUSES } from "services/common/documents/documentTypes";
import { setAllowedFilters } from "services/common/gridService";
import { FieldFilterMapType } from "services/common/types/grid.type";
import { companyDateFormat } from "services/general/dateSvc";
import { translate } from "services/general/translation";
import styles from "wombatifier/components/bulk_upload/bulkUpload.module.css";
import { BulkUploadFilesRowAction } from "wombatifier/components/bulk_upload/bulkUploadFilesRowAction";
export const FIELD_NAME_MAP: FieldFilterMapType = {
  name: {
    contains: "name_contains",
    equals: "name",
  },
  created_at: {
    inRange: "",
    lessThanOrEqual: "created_at_before",
    greaterThanOrEqual: "created_at_after",
  },
  wombat_name: {
    contains: "wombat_name_contains",
    equals: "wombat_name",
  },
  id: {
    contains: "id_contains",
    equals: "id",
  },
  status: {
    // values: 'status' // only uncomment and change if need to use alternative attribute
  },
};
const StatusPill = (params: any) => {
  const label = (params.data.status || "").replace(/\_/g, " ");
  if ((label || "").includes("ERROR")) {
    return <span className={`rounded-pill px-2 py-1 my-1 ${styles.errorStatusPill}`}>{label}</span>;
  }
  return label;
};

export const getBulkUploadsHeaders = ({
  currentUser,
}: {
  currentUser: any;
}): { columnDefs: ColDef[]; defaultOrder: string[] } => {
  let columnDefs = [
    {
      field: "name",
      sortable: true,
      flex: 3,
      filter: "agTextColumnFilter",
      cellClass: "routingCellButtonImportant",
      filterParams: {
        suppressAndOrCondition: true,
      },
      onCellClicked: (event: any) => {
        if (event?.data?.assets?.length > 0) {
          window.open(event.data.assets[0].asset_expiring_url, "_blank");
        }
      },
      headerValueGetter: function () {
        return translate("file_name");
      },
    },
    {
      field: "status",
      headerName: "Status",
      flex: 2,
      cellRenderer: !currentUser?.company?.can_view_file_details ? "agGroupCellRenderer" : StatusPill,
      cellRendererParams: {
        innerRenderer: StatusPill,
      },
      filter: "agSetColumnFilter",
      filterParams: {
        values: FILE_INTEGRATION_STATUSES,
        suppressSelectionCount: true,
      },
      headerValueGetter: function () {
        return translate("status");
      },
      headerComponent: (params: any) => {
        return (
          <>
            {params.displayName}
            <PopoverRender
              popoverClassName={"status-tooltip wide-popover"}
              placement="top"
              title="Status Definitions"
              content={
                <Row>
                  <Col sm="5" className={styles.statusTitle}>
                    Completed:
                  </Col>
                  <Col sm="7">File successfully uploaded</Col>
                  <Col sm="5" className={styles.statusTitle}>
                    Pending Processing:
                  </Col>
                  <Col sm="7">Upload has not been completed</Col>
                  <Col sm="5" className={styles.statusTitle}>
                    Completed with Errors:
                  </Col>
                  <Col sm="7">File uploaded with errors</Col>{" "}
                </Row>
              }
              className="icon icon-info-blue mt-3 mx-1"
            />
          </>
        );
      },
    },
    {
      field: "wombat_name",
      headerName: "Document Type",
      flex: 2,
      sortable: true,
      filter: "agTextColumnFilter",
      filterParams: {
        suppressAndOrCondition: true,
      },
      headerValueGetter: function () {
        return translate("file_type");
      },
      valueGetter: (params: { data: { wombat_name: any; document_type: any } }) => {
        return params.data.wombat_name || params.data.document_type;
      },
      cellRenderer: (params: { data: { wombat_name: any; document_type: any } }) => {
        return params.data.wombat_name ? (
          <span title={params.data.document_type}>{params.data.wombat_name}</span>
        ) : (
          params.data.document_type
        );
      },
    },
    {
      field: "created_at",
      headerName: "Date",
      flex: 1,
      sortable: true,
      filter: "agDateColumnFilter",
      filterParams: {
        suppressAndOrCondition: true,
      },
      cellRenderer: (params: any) => {
        return companyDateFormat(params.value, currentUser);
      },
      headerValueGetter: function () {
        return translate("upload_date");
      },
      minWidth: 120,
    },
    {
      field: "id",
      headerName: "ID",
      flex: 1,
      sortable: true,
      filter: "agTextColumnFilter",
      filterParams: {
        suppressAndOrCondition: true,
      },
      headerValueGetter: function () {
        return translate("file_id");
      },
    },
    {
      field: "ZActions",
      filter: false,
      cellClass: "actions-button-cell",
      cellRenderer: (params: any) => {
        return <BulkUploadFilesRowAction data={params.data} />;
      },
      width: 70,
      sortable: false,
      resizable: false,
      headerValueGetter: function () {
        return translate("actions");
      },
    },
  ];
  setAllowedFilters(columnDefs, FIELD_NAME_MAP);
  const defaultOrder = columnDefs.map((col: any) => col.field);
  return { columnDefs: sortBy(columnDefs, (col) => col.headerValueGetter()), defaultOrder };
};
