import { restApiService } from "providers/restApi";
import React, { useMemo } from "react";
import { Nav } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { Can } from "services/authorization/authorization";
import "wombatifier/components/navigation/navigation.style.css";

interface TabNavigationPropsType {
  routes: TabNavigationRouteType[];
  activePageName: string;
}

interface TabNavigationRouteType {
  isActive?: boolean;
  href?: string;
  path?: string;
  pageName: string;
  authorization?: {
    I?: string;
    a?: string;
    permissions?: string[];
  };
  hide?: boolean;
}

export const TabNavigation = ({ routes, activePageName }: TabNavigationPropsType) => {
  return (
    <div className={"tabNavigationContainer"}>
      <ul className="nav nav-tabs border-0 tabNavigation">
        {routes.map((tab) => {
          return (
            !tab.hide &&
            (tab.authorization ? (
              <Can
                key={tab.pageName}
                I={tab.authorization.I}
                a={tab.authorization.a}
                permissions={tab.authorization.permissions}
              >
                <ListTabs key={tab.pageName} route={tab} activePageName={activePageName} />
              </Can>
            ) : (
              <ListTabs key={tab.pageName} route={tab} activePageName={activePageName} />
            ))
          );
        })}
      </ul>
    </div>
  );
};

const ListTabs = ({ route, activePageName }: { route: TabNavigationRouteType; activePageName: string }) => {
  const isTabActive = useMemo(() => {
    let active = route.isActive ? route.isActive : false;
    if (activePageName && route.pageName === activePageName) {
      active = true;
    }
    return active;
  }, [activePageName]);

  return (
    <li key={route.pageName} className={"nav-item mx-4"}>
      {route.path ? (
        <NavLink
          key={route.pageName}
          exact
          to={route.path}
          className={`routeContainer ${isTabActive ? "active" : ""}`}
          replace={true}
          data-toggle="tooltip"
        >
          <Nav.Item>{route.pageName}</Nav.Item>
        </NavLink>
      ) : (
        <a key={route.pageName} className="routeContainer" href={restApiService.angularBaseURL() + route.href}>
          {route.pageName}
        </a>
      )}
    </li>
  );
};
