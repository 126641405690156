import BudgetPicker from "components/admin/pickers/reduxFormPickers/budgetPicker";
import CustomFieldPicker from "components/admin/pickers/reduxFormPickers/customFieldPicker";
import RebatePicker from "components/admin/pickers/reduxFormPickers/rebatePicker";
import ErrorBoundary from "components/common/errorBoundary/errorBoundary";
import Panel from "components/common/panel/panel";
import { RenderCheckBox, RenderFieldNumber, RenderTextArea } from "components/forms/bootstrapFields";
import ProductItemPicker from "components/pickers/reduxFormPicker/productItemPicker";
import _ from "lodash";
import React, { ReactNode, useMemo, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useTypedSelector } from "reducers";
import { Field, FieldArrayFieldsProps, change, touch } from "redux-form";
import adminCommonSvc from "services/admin/commonSvc";
import { CustomLabelSvc } from "services/admin/customLabels/customLabelsSvc";
import invoiceCommonSvc from "services/admin/invoices/invoiceCommonSvc";
import { InvoiceType } from "services/admin/invoices/invoiceType";
import { IDType } from "services/common/types/common.type";
import { IUser } from "services/common/user/userTypes";
import { formattedAmount, isDefined } from "services/general/helpers";
import { isEmpty, required } from "services/validations/reduxFormValidation";
import { roundUpAmount } from "services/vp/services/roundUpAmount";
import { MetadataFieldSelector } from "wombatifier/components/metadata_field/selector";
import AccountPicker, { AccountObjType } from "../../../pickers/reduxFormPickers/accountPicker";
import AmortizationSchedule from "../../../pickers/reduxFormPickers/amortizationSchedule";
import BusinessUnitPicker from "../../../pickers/reduxFormPickers/businessUnitPicker";
import ContactPicker from "../../../pickers/reduxFormPickers/contactPicker";
import CustomDataListPicker from "../../../pickers/reduxFormPickers/customDataListPicker";
import DepartmentPicker from "../../../pickers/reduxFormPickers/departmentPicker";
import EventCodePicker from "../../../pickers/reduxFormPickers/eventCodePicker";
import ForSubsidiaryPicker from "../../../pickers/reduxFormPickers/forSubsidiaryPicker";
import GrantPicker from "../../../pickers/reduxFormPickers/grantPicker";
import InterCompanyPicker from "../../../pickers/reduxFormPickers/interCompanyPicker";
import LocationPicker from "../../../pickers/reduxFormPickers/locationPicker";
import ProjectPicker, { ProjectOptionsType } from "../../../pickers/reduxFormPickers/projectPicker/projectPicker";
import SecondaryCategoryPicker from "../../../pickers/reduxFormPickers/secondaryCategoryPicker";
import TaxCodePicker from "../../../pickers/reduxFormPickers/taxCodePicker";
import UnitPicker from "../../../pickers/reduxFormPickers/unitPicker";
import WareHousePicker from "../../../pickers/reduxFormPickers/warehousePicker";
import WhTaxPicker from "../../../pickers/reduxFormPickers/whTaxPicker";
import styles from "../invoices.module.css";

export type ManageInvoiceItemsType = {
  actual_unit_price?: number;
  rebate_link?: {
    rebate?: {
      is_included?: boolean;
    };
  };
  rebate_id?: null | IDType;
  rebate_amount?: number | null;
  rebate?: any;
  amortization_schedule_name?: ReactNode;
  product_item?: any;
  tax_id?: number;
  unit_id?: number;
  qty?: number;
  unit_price?: number;
  amount?: number;
  tax?: number;
  business_unit_id?: number;
  department_id?: number;
  location_id?: number;
  budget_id?: number;
  account_id?: number;
  is_warehouse_required?: boolean;
  is_grant_required?: boolean;
  _destroy?: string | number;
  eligible_1099?: any;
  amortization_start_date?: string;
  amortization_end_date?: string;
  is_hide_project?: boolean;
  is_location_required?: boolean;
  is_department_required?: boolean;
};

type ManageInvoiceItemsPropsType = {
  fields: FieldArrayFieldsProps<InvoiceType.ManageInvoiceItemsType>;
  invoice: InvoiceType.InvoiceDetailType;
  formName: string;
  showAdditionalCodingSection?: boolean;
  customLabels?: { [key: string]: string[] };
  allowSubmitWithGLErrors?: boolean;
};

const showTaxAmount = (invoice: InvoiceType.InvoiceDetailType, currentUser: IUser) => {
  return (
    !invoiceCommonSvc.isActiveHeaderTax(invoice, currentUser) &&
    (currentUser?.company?.has_taxes || currentUser?.company?.enable_to_enter_tax_amount)
  );
};

const updateInvoiceDebitEntriesIfDestroy = (
  item: any,
  invoice: InvoiceType.InvoiceDetailType,
  currentUser: IUser,
  removeSubtotalTemplates: boolean,
) => {
  let obj = { isDebitEntriesChanged: false, isSubtotalTemplatechanged: false };
  if (item._destroy === 1) {
    invoice?.debit_entries_attributes?.forEach((val: any, index: number) => {
      if (
        (item.id > 0 && val.item_line_id === item.id) ||
        (removeSubtotalTemplates && (val.is_subtotal_template || val.subtotal_template_id) && val._destroy != 1)
      ) {
        val._destroy = 1;
        obj.isDebitEntriesChanged = true;
        if (removeSubtotalTemplates && (val.is_subtotal_template || val.subtotal_template_id)) {
          obj.isSubtotalTemplatechanged = true;
        }
      }
    });

    if (invoiceCommonSvc.isActiveHeaderTax(invoice, currentUser)) {
      invoiceCommonSvc.calculateHeaderLevelTax(invoice);
    }
  }
  return obj;
};

const isSubtotalTaxExits = (invoice: InvoiceType.InvoiceDetailType) => {
  let isExist = false;
  if (_.isArray(invoice?.debit_entries_attributes) && invoice.debit_entries_attributes.length > 0) {
    invoice.debit_entries_attributes.forEach((entry) => {
      if (entry._destroy !== 1 && entry.is_tax) {
        isExist = true;
        if (invoice?.itemsObj) {
          invoice.itemsObj.subTotalTax = entry.amount;
        }
      }
    });
  }
  return isExist;
};

const isSubtotalUsedTaxExist = (invoice: InvoiceType.InvoiceDetailType) => {
  let isExist = false;

  if (_.isArray(invoice?.tax_debit_entries_attributes) && invoice.tax_debit_entries_attributes.length > 0) {
    invoice.tax_debit_entries_attributes.forEach((entry: any) => {
      if (entry._destroy !== 1) {
        isExist = true;
        if (invoice?.itemsObj) {
          invoice.itemsObj.subTotalTax = entry.amount;
        }
      }
    });
  }
  return isExist;
};

const isTaxDisabled = (invoiceItem: any, invoice: InvoiceType.InvoiceDetailType, currentUser: IUser) => {
  const { product_item, amount } = invoiceItem;
  let isDisable = false;
  if (product_item?.id && !product_item.taxable) {
    isDisable = true;
  }

  if (!(amount > 0)) {
    isDisable = true;
  }

  if (isSubtotalTaxExits(invoice) || isSubtotalUsedTaxExist(invoice)) {
    isDisable = true;
  }

  if (
    invoiceCommonSvc.isActiveHeaderTax(invoice, currentUser) &&
    invoiceCommonSvc.allowDisableTaxByAmountsAre(invoice.amounts_are)
  ) {
    isDisable = true;
  }
  return isDisable;
};

const initialiseItem = (invoiceItem: any, invoice: InvoiceType.InvoiceDetailType) => {
  if (invoiceItem?.id > 0 && !(invoiceItem?.product_item_id > 0) && invoiceItem?.name) {
    invoiceItem.product_item = { display_name: invoiceItem.name };
  } else if (!invoice?.id && !(invoiceItem?.product_item_id > 0) && invoiceItem?.name) {
    invoiceItem.product_item = { display_name: invoiceItem.name };
  } else if (invoiceItem?.id > 0 && invoiceItem?.product_item_id && invoiceItem?.name) {
    if (invoiceItem?.product_item && invoiceItem?.name) {
      invoiceItem.product_item = {
        ...invoiceItem.product_item,
        name: invoiceItem.name,
        display_name: invoiceItem.name,
      };
    }
  }
};

const clearFreetext = (input: any, currentUser: IUser) => {
  if (invoiceCommonSvc.disabledFreetextItem(currentUser) && !input.value?.product_item) {
    input.value = { product_item: "" };
  }
};

const ManageInvoiceItems = ({
  fields,
  invoice,
  formName,
  showAdditionalCodingSection,
  customLabels,
  allowSubmitWithGLErrors,
}: ManageInvoiceItemsPropsType) => {
  const currentUser: IUser = useTypedSelector((state) => state.user);
  const [hideItems, setHideItems] = useState<number[]>([]);
  const dispatch = useDispatch();
  const defaultDecimal = adminCommonSvc.getDefaultDecimal(currentUser);
  const unitPriceDecimalLimit = adminCommonSvc.unitPriceDecimalLimit(currentUser);
  const { t } = useTranslation();

  const deleteInvoiceItem = (item: any, index: number) => {
    item._destroy = 1;
    if (_.isArray(invoice?.invoice_items_attributes)) {
      invoice.invoice_items_attributes[index] = item;
    }
    const removeSubtotalTemplates = invoiceCommonSvc.checkToRemoveSubtotalTemplates(invoice);
    const { isDebitEntriesChanged, isSubtotalTemplatechanged } = updateInvoiceDebitEntriesIfDestroy(
      item,
      invoice,
      currentUser,
      removeSubtotalTemplates,
    );
    setHideItems([...hideItems, index]);
    if (isDebitEntriesChanged) {
      dispatch(change("invoiceForm", "debit_entries_attributes", invoice?.debit_entries_attributes));
      if (isSubtotalTemplatechanged) {
        invoiceCommonSvc.getInvoiceTotalAmount(invoice, currentUser);
      }
    }
    dispatch(change("InvoiceForm", `invoice_items_attributes[${index}]`, item));
  };

  const duplicateInvoiceItem = (item: any) => {
    const invoiceItem = invoiceCommonSvc.getDuplicateRow(item, "item");
    adminCommonSvc.removeIdFromBudgetItemLinks(invoiceItem);
    fields.push(invoiceItem);
  };

  const updateItemDebitAccountAmount = (invoiceItem: any) => {
    if (invoiceItem.id > 0 && invoiceItem.account_id) {
      let itemTotal = invoiceCommonSvc.getItemTotal(invoice, invoiceItem, currentUser);
      invoice?.debit_entries_attributes?.forEach((debitEntry) => {
        if (debitEntry.item_line_id == invoiceItem.id) {
          debitEntry.amount = itemTotal;
          invoiceItem.total = debitEntry.amount;
          debitEntry.account_id = invoiceItem.account_id;
          debitEntry.product_item_id = invoiceItem.product_item_id;
        }
      });
    }
  };

  const calculationItemLineTax = (invoiceItem: any, index?: number, isRebateCalculated: boolean = false) => {
    if (!invoiceCommonSvc.isActiveHeaderTax(invoice, currentUser) && invoiceItem?.amount && invoiceItem?.tax_code) {
      invoiceItem.tax = roundUpAmount((invoiceItem.amount / 100) * invoiceItem?.tax_code?.rate);
    } else if (!invoiceItem.amount && !invoiceItem.tax_code) {
      invoiceItem.tax_code = undefined;
      invoiceItem.tax_id = null;
      invoiceItem.tax = null;
    }

    if (!isRebateCalculated) {
      invoiceItem = invoiceCommonSvc.calculateItemLineRebate(invoiceItem, currentUser);
    }

    invoiceItem = invoiceCommonSvc.calculateWhTax(invoiceItem, currentUser);
    updateItemDebitAccountAmount(invoiceItem);
    invoiceCommonSvc.updateSubtotalUsetax(invoice, currentUser);

    if (invoiceCommonSvc.isActiveHeaderTax(invoice, currentUser)) {
      invoiceCommonSvc.calculateHeaderLevelTax(invoice);
    }
    dispatch(change("InvoiceForm", `invoice_items_attributes[${index}]`, invoiceItem));
  };

  const updateItemLineQty = (invoiceItem: any, value: string, index: number) => {
    invoiceItem.qty = roundUpAmount(parseFloat(value), 5);
    dispatch(change("InvoiceForm", `invoice_items_attributes[${index}]`, invoiceItem));
    calculateTax(invoiceItem, index);
  };

  const updateItemLineUnitPrice = (invoiceItem: any, value: string, index: number) => {
    invoiceItem.qty = invoiceItem.qty ? parseFloat(invoiceItem.qty) : 0;
    invoiceItem.tax = invoiceItem.tax ? parseFloat(invoiceItem.tax) : 0;
    invoiceItem.unit_price = roundUpAmount(parseFloat(value), adminCommonSvc.unitPriceDecimalLimit(currentUser));
    dispatch(change("InvoiceForm", `invoice_items_attributes[${index}]`, invoiceItem));
    calculateTax(fields.get(index), index);
  };

  const calculateTax = (invoiceItem: any, index: number, isRebateCalculated: boolean = false) => {
    invoiceItem.qty = parseFloat(invoiceItem.qty);
    invoiceItem.qty = roundUpAmount(invoiceItem.qty, 5);
    invoiceItem.amount = invoiceCommonSvc.calculateAmount(invoiceItem);
    calculationItemLineTax(invoiceItem, index, isRebateCalculated);
  };

  const assignFreeTextItem = (invoiceItem: any, index: number) => {
    if (currentUser?.company?.enable_freetext_item_sync && invoice?.freetext_product_account) {
      const { account_id, account_linkable_id } = invoice.freetext_product_account;
      invoiceItem.account_id = account_id;
      invoiceItem.product_item_id = account_linkable_id;
      calculateTax(invoiceItem, index);
    } else {
      dispatch(change("InvoiceForm", `invoice_items_attributes[${index}]`, invoiceItem));
      dispatch(touch("InvoiceForm", `invoice_items_attributes[${index}].product_item`));
    }
  };

  const selectProductItem = (productItem: any, invoiceItem: any, index: number) => {
    invoiceCommonSvc.setProductItemMapping(productItem, invoiceItem, currentUser);
    if (productItem?.id) {
      invoiceItem.unit_price = productItem?.price;
      invoiceItem.description = productItem?.description;
      invoiceItem.qty = 1;
      invoiceItem.product_item = productItem;
      invoiceItem.product_item_id = productItem.id;
      dispatch(change("InvoiceForm", `invoice_items_attributes[${index}].product_item`, invoiceItem.product_item));
      invoiceItem.name = "";
      invoiceItem.amount = invoiceCommonSvc.calculateAmount(invoiceItem);
      invoiceItem.description = productItem?.description;

      if (_.isArray(productItem?.invoice_debit_accounts) && productItem?.invoice_debit_accounts[0]) {
        invoiceItem.account_id = productItem?.invoice_debit_accounts[0]?.account_id;
      }

      if (!productItem?.taxable) {
        invoiceItem.tax_id = null;
        invoiceItem.tax_code = undefined;
        invoiceItem.tax = null;
      }

      if (invoiceItem?.amount && invoiceItem?.tax_code) {
        invoiceItem.tax = (invoiceItem.amount / 100) * invoiceItem.tax_code.rate;
      } else {
        invoiceItem.tax = null;
      }
      calculateTax(invoiceItem, index);
      dispatch(change("InvoiceForm", `invoice_items_attributes[${index}]`, invoiceItem));
      if (_.isArray(invoice?.invoice_items_attributes)) {
        invoice.invoice_items_attributes[index] = invoiceItem;
        const discountAmount = adminCommonSvc.calculateDiscountAmt(invoice, currentUser);
        dispatch(change("InvoiceForm", "amount_disc", discountAmount));
      }
    }
  };

  const setItemName = (invoiceItem: any, index: number, value: string) => {
    invoiceItem.product_item_id = null;
    invoiceItem.account_id = null;
    invoiceItem.name = "";
    if (!invoiceCommonSvc.disabledFreetextItem(currentUser)) {
      invoiceItem.name = value;
      invoiceItem.product_item = value.length > 0 ? { display_name: value } : {};
      assignFreeTextItem(invoiceItem, index);
    }
  };

  const calculateItemTax = (whTaxCode: any, invoiceItem: any, index: number) => {
    invoiceItem.wh_tax_code = whTaxCode;
    invoiceItem.wh_tax_id = whTaxCode.id;
    calculateTax(invoiceItem, index);
  };

  const calculateTaxUpdate = (taxCode: any, invoiceItem: any, index: number) => {
    invoiceItem.tax_code = taxCode;
    invoiceItem.tax_id = taxCode?.id;
    calculateTax(invoiceItem, index);
  };

  const updateItemLineTax = (invoiceItem: any, itemLinetTax: number, index: number) => {
    invoiceItem.tax = itemLinetTax;
  };

  const changeProject = (invoiceItem: any, project: ProjectOptionsType, index: number) => {
    let projectId;
    if (_.isPlainObject(project)) {
      projectId = project.project_id;
      invoiceItem.project_id = project.project_id;
      invoiceItem.project_name = project.label;
      invoiceItem.project = project;
    } else {
      projectId = null;
    }
    dispatch(change("InvoiceForm", `invoice_items_attributes[${index}].project_id`, projectId));
  };

  const calculateUnitPrice = (invoiceItem: any, subAmount: number, index: number) => {
    invoiceItem.amount = roundUpAmount(subAmount);
    let amount = roundUpAmount(subAmount);
    let unitPrice = invoiceItem.qty ? amount / parseFloat(invoiceItem.qty) : amount;
    invoiceItem.unit_price = roundUpAmount(unitPrice, unitPriceDecimalLimit);
    calculationItemLineTax(invoiceItem, index);
  };

  const mapUnitCalculation = (invoiceItem: any, unit: any, index: number) => {
    if (invoiceItem.unit_id && unit?.id !== invoiceItem.unit_id && invoiceItem.unit_price) {
      let unitPrice = (invoiceItem.unit_price * unit.conversion_rate) / invoiceItem.unit.conversion_rate;
      if (unitPrice) {
        invoiceItem.unit_price = roundUpAmount(unitPrice, unitPriceDecimalLimit);
        calculateTax(invoiceItem, index);
      }
    }
    invoiceItem.unit_id = unit.id;
    invoiceItem.unit = unit;
  };

  const addRow = (fields: FieldArrayFieldsProps<InvoiceType.ManageInvoiceItemsType>) => {
    if (isDefined(invoice)) {
      invoice.submitWithAccounts = true;
    }

    let obj = {};
    invoiceCommonSvc.inheritHeaderToItemLine(invoice, obj, currentUser);
    invoiceCommonSvc.assign1099Eligible(invoice, obj, currentUser);
    fields?.push(obj);
  };

  const changeForProject = (invoiceItem: any, project: ProjectOptionsType, index: number) => {
    invoiceItem.for_project_id = project.project_id;
    invoiceItem.for_project_name = project.label;
    invoiceItem.for_project = project;
    dispatch(change("InvoiceForm", `invoice_items_attributes[${index}].for_project_id`, project.project_id));
  };

  const calculateRebate = (itemObj: InvoiceType.ManageInvoiceItemsType, index: number): any => {
    itemObj = invoiceCommonSvc.calculateItemLineRebate(itemObj, currentUser);
    invoiceCommonSvc.updateUnitPriceWithRebate(itemObj, currentUser);
    calculateTax(itemObj, index, true); //true - it means calculate_tax calling from calculate_rebate
  };

  const updateRebateAmount = function (invItemObj: InvoiceType.ManageInvoiceItemsType, index: number): any {
    invoiceCommonSvc.updateUnitPriceWithRebate(invItemObj, currentUser);
    updateItemDebitAccountAmount(invItemObj);
  };

  const updateInvoiceItemAccount = (
    account: AccountObjType,
    invoiceItem: InvoiceType.ManageInvoiceItemsType,
    index: number,
  ) => {
    if (invoiceItem && account) {
      invoiceItem.account_id = account.id as number;
    }
    invoiceCommonSvc.mapAccountRequiredFields(account, currentUser, invoiceItem, "invoice_Items");
    dispatch(change(formName, `invoice_items_attributes[${index}]`, invoiceItem));
  };

  const invoiceItemsTotals = useMemo(() => invoiceCommonSvc.getInvoiceItemsTotals(invoice), [invoice]);

  return (
    <>
      <Row className="invoiceItemLine">
        <Col>
          {fields &&
            fields.length > 0 &&
            fields.map((item: any, index) => {
              const invoiceItem = fields.get(index);
              if (!hideItems.includes(index) && fields.get(index)._destroy !== 1) {
                return (
                  <Panel
                    key={index}
                    cardBodyClass={styles.invoiceLineCardBody}
                    wrapperClass={styles.wrapperClass}
                    header={
                      <Row>
                        <Col sm={11} className={styles.panelTitle}>
                          ITEM {index + 1}{" "}
                        </Col>
                        <Col sm={1} className="px-pl-0">
                          <Button
                            onClick={() => duplicateInvoiceItem(fields.get(index))}
                            className="bg-transparent border-0 m-0 p-0 justify-content-end"
                          >
                            <i className="icon duplicate m-0" />
                          </Button>
                          <Button
                            onClick={() => deleteInvoiceItem(fields.get(index), index)}
                            className="bg-transparent border-0 m-0 p-0 px-pl-15"
                          >
                            <i className="icon icon-delete m-0" />
                          </Button>
                        </Col>
                      </Row>
                    }
                  >
                    <Row>
                      <Col sm={3}>
                        <Field
                          key={index}
                          instanceId={`invoice-item-${index}-product-item`}
                          name={`${item}.product_item`}
                          label={CustomLabelSvc.setCustomLabel(
                            customLabels,
                            t("admin.pages.invoice.invoiceItems.itemName"),
                          )}
                          placeholder="Search / Enter New Item"
                          component={ProductItemPicker}
                          apiParams={{
                            subsidiary_id: adminCommonSvc.showItemsBySubsidiary(invoice, currentUser)
                              ? invoice?.subsidiary_id
                              : null,
                          }}
                          callBackString={(value: any) => setItemName(fields.get(index), index, value)}
                          onBlurCallback={(input: any) => clearFreetext(input, currentUser)}
                          onInitialCallback={() => initialiseItem(fields.get(index), invoice)}
                          onChangeCallback={(productItem: any) =>
                            selectProductItem(productItem, fields.get(index), index)
                          }
                          disabledFreeText={invoiceCommonSvc.disabledFreetextItem(currentUser)}
                          required={true}
                          validate={[required, isEmpty]}
                        />
                      </Col>
                      {currentUser?.company?.has_taxes && !invoice.is_used_tax && (
                        <Col sm={2} className="p-0">
                          <Field
                            instanceId={`invoice-item-${index}-tax`}
                            name={`${item}.tax_id`}
                            label={CustomLabelSvc.setCustomLabel(
                              customLabels,
                              t("admin.pages.invoice.invoiceItems.taxCode"),
                            )}
                            component={TaxCodePicker}
                            isClearableDisable={true}
                            parentObj={invoice}
                            modelData={fields.get(index)}
                            subsidiary_id={invoice?.subsidiary_id}
                            callBack={(taxCode: any) => calculateTaxUpdate(taxCode, fields.get(index), index)}
                            disabled={isTaxDisabled(fields.get(index), invoice, currentUser)}
                            required={invoiceCommonSvc.isInvoiceLineLevelTaxRequired(invoice, currentUser)}
                            validate={
                              invoiceCommonSvc.isInvoiceLineLevelTaxRequired(invoice, currentUser) ? [required] : []
                            }
                          />
                        </Col>
                      )}
                      {currentUser?.company?.has_taxes && invoice.is_used_tax && (
                        <Col sm={2} className="p-0">
                          <Field
                            instanceId={`invoice-item-${index}-tax`}
                            name={`${item}.tax_id`}
                            label={CustomLabelSvc.setCustomLabel(
                              customLabels,
                              t("admin.pages.invoice.invoiceItems.taxCode"),
                            )}
                            component={TaxCodePicker}
                            isClearableDisable={true}
                            modelData={fields.get(index)}
                            isUseTax={invoice.is_used_tax ?? null}
                            callBack={(taxCode: any) => calculateTaxUpdate(taxCode, fields.get(index), index)}
                            disabled={isTaxDisabled(fields.get(index), invoice, currentUser)}
                            required={invoiceCommonSvc.isInvoiceLineLevelTaxRequired(invoice, currentUser)}
                            validate={
                              invoiceCommonSvc.isInvoiceLineLevelTaxRequired(invoice, currentUser) ? [required] : []
                            }
                            parentObj={invoice}
                          />
                        </Col>
                      )}
                      {currentUser?.company?.has_units && (
                        <Col sm={1} className="pr-0">
                          <Field
                            instanceId={`invoice-item-${index}-unit`}
                            name={`${item}.unit_id`}
                            label={CustomLabelSvc.setCustomLabel(
                              customLabels,
                              t("admin.pages.invoice.invoiceItems.unit"),
                            )}
                            component={UnitPicker}
                            modelData={fields.get(index)}
                            required={false}
                            callBack={(unit: any) => mapUnitCalculation(fields.get(index), unit, index)}
                          />
                        </Col>
                      )}
                      <Col sm={1} className="pr-0">
                        <Field
                          id={`invoice-item-${index}-unit`}
                          name={`${item}.qty`}
                          type="number"
                          label={CustomLabelSvc.setCustomLabel(customLabels, t("admin.pages.invoice.invoiceItems.qty"))}
                          component={RenderFieldNumber}
                          onChange={(e: any) => updateItemLineQty(fields.get(index), e.target.value, index)}
                          normalize={(value: any) => value && roundUpAmount(parseFloat(value), 5)}
                        />
                      </Col>
                      <Col className="pr-0">
                        <Field
                          id={`invoice-item-${index}-unit-price`}
                          name={`${item}.unit_price`}
                          type="number"
                          label={CustomLabelSvc.setCustomLabel(
                            customLabels,
                            t("admin.pages.invoice.invoiceItems.unitPrice"),
                          )}
                          component={RenderFieldNumber}
                          onChange={(e: any) => updateItemLineUnitPrice(fields.get(index), e.target.value, index)}
                          normalize={(value: any) =>
                            value && roundUpAmount(parseFloat(value), adminCommonSvc.unitPriceDecimalLimit(currentUser))
                          }
                        />
                      </Col>
                      {currentUser?.company?.allow_edit_invoice_item_subtotal && (
                        <Col className="pr-0">
                          <Field
                            id={`invoice_item_subtotal_${index}`}
                            name={`${item}.amount`}
                            type="number"
                            label={CustomLabelSvc.setCustomLabel(
                              customLabels,
                              t("admin.pages.invoice.invoiceItems.subTotal"),
                            )}
                            component={RenderFieldNumber}
                            onChange={(e: any) => calculateUnitPrice(fields.get(index), e.target.value, index)}
                          />
                        </Col>
                      )}
                      {showTaxAmount(invoice, currentUser) && (
                        <Col className="pr-0">
                          <Field
                            id={`invoice_item_tax_${index}`}
                            name={`${item}.tax`}
                            type="number"
                            label={CustomLabelSvc.setCustomLabel(
                              customLabels,
                              t("admin.pages.invoice.invoiceItems.tax"),
                            )}
                            component={RenderFieldNumber}
                            disabled={!currentUser.company.enable_to_enter_tax_amount}
                            onChange={(e: any) => updateItemLineTax(fields.get(index), e.target.value, index)}
                          />
                        </Col>
                      )}
                      <Col>
                        <Field
                          id={`invoice_cost_amount_${index}`}
                          name={`${item}.total`}
                          label={CustomLabelSvc.setCustomLabel(
                            customLabels,
                            t("admin.pages.invoice.invoiceItems.total"),
                          )}
                          type="number"
                          disabled
                          value={invoiceCommonSvc.getItemTotal(invoice, fields.get(index), currentUser)}
                          component={RenderFieldNumber}
                          onChange={(e: any) => updateItemDebitAccountAmount(fields.get(index))}
                        />
                      </Col>
                    </Row>
                    <Row>
                      {(fields.get(index)?.account_id || !currentUser.company.disabled_freetext_item) && (
                        <Col sm={4}>
                          <Field
                            instanceId={`invoice-item-${index}-account`}
                            component={AccountPicker}
                            name={`${item}.account_id`}
                            label={CustomLabelSvc.setCustomLabel(
                              customLabels,
                              t("admin.pages.invoice.invoiceItems.account"),
                            )}
                            modelData={fields.get(index)}
                            disabled={currentUser.company.disabled_freetext_item || fields.get(index).product_item_id}
                            required={invoiceCommonSvc.isFieldRequired(true, allowSubmitWithGLErrors)}
                            callBack={(account: AccountObjType) =>
                              updateInvoiceItemAccount(account, fields.get(index), index)
                            }
                            validate={invoiceCommonSvc.isFieldRequired(true, allowSubmitWithGLErrors) ? [required] : []}
                          />
                        </Col>
                      )}

                      {currentUser?.company?.has_wh_taxes && (
                        <Col sm={4}>
                          <Field
                            instanceId={`invoice-item-${index}-wh-tax`}
                            component={WhTaxPicker}
                            name={`${item}.wh_tax_id`}
                            label={CustomLabelSvc.setCustomLabel(
                              customLabels,
                              t("admin.pages.invoice.invoiceItems.whTaxCode"),
                            )}
                            callBack={(whTaxCode: any) => calculateItemTax(whTaxCode, fields.get(index), index)}
                          />
                        </Col>
                      )}

                      {currentUser?.company?.has_wh_taxes && (
                        <Col sm={4}>
                          <Field
                            instanceId={`invoice-item-${index}-wh-tax-amount`}
                            name={`${item}.wh_tax_amount`}
                            type="number"
                            label={CustomLabelSvc.setCustomLabel(
                              customLabels,
                              t("admin.pages.invoice.invoiceItems.whTaxAmount"),
                            )}
                            component={RenderFieldNumber}
                            disabled={!currentUser?.company?.global?.enable_to_enter_wh_tax_amount}
                            onChange={(e: any) => updateItemDebitAccountAmount(fields.get(index))}
                          />
                        </Col>
                      )}

                      {invoiceCommonSvc.allowRebateItemsLine(currentUser) && (
                        <Col sm={4}>
                          <Field
                            instanceId={`invoice-item-${index}-rebate`}
                            name={`${item}.rebate_id`}
                            modelData={fields.get(index)}
                            formName={formName}
                            modelDataFormField={`${item}`}
                            type="text"
                            label={CustomLabelSvc.setCustomLabel(
                              customLabels,
                              t("admin.pages.invoice.invoiceItems.rebate"),
                            )}
                            component={RebatePicker}
                            callBack={(obj: any) => calculateRebate(obj.b, index)}
                          />
                        </Col>
                      )}

                      {invoiceCommonSvc.allowRebateItemsLine(currentUser) && (
                        <Col sm={4}>
                          <Field
                            id={`invoice-item-${index}-rebate-amount`}
                            name={`${item}.rebate_amount`}
                            type="number"
                            label={CustomLabelSvc.setCustomLabel(
                              customLabels,
                              t("admin.pages.invoice.invoiceItems.rebateAmount"),
                            )}
                            component={RenderFieldNumber}
                            placeholder="Rebate Amount"
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                              const invoiceItem = fields.get(index);
                              invoiceItem.rebate_amount = parseFloat(e.target.value);
                              updateRebateAmount(invoiceItem, index);
                            }}
                          />
                        </Col>
                      )}
                    </Row>
                    <Row>
                      {currentUser?.company?.has_departments &&
                        !currentUser?.company?.invoice?.items?.department?.is_hide && (
                          <Col sm={4}>
                            <Field
                              instanceId={`invoice-item-${index}-department`}
                              component={DepartmentPicker}
                              name={`${item}.department_id`}
                              label={CustomLabelSvc.setCustomLabel(
                                customLabels,
                                t("admin.pages.invoice.invoiceItems.department"),
                              )}
                              disabled={adminCommonSvc.isDepartmentDisabled(fields.get(index))}
                              modelData={fields.get(index)}
                              parentObjData={invoice}
                              required={invoiceCommonSvc.isFieldRequired(
                                currentUser?.company?.invoice?.items?.department?.is_required ||
                                  invoiceItem.is_department_required,
                                allowSubmitWithGLErrors,
                              )}
                              validate={
                                invoiceCommonSvc.isFieldRequired(
                                  currentUser?.company?.invoice?.items?.department?.is_required ||
                                    invoiceItem.is_department_required,
                                  allowSubmitWithGLErrors,
                                )
                                  ? [required]
                                  : null
                              }
                              menuPosition="fixed"
                            />
                          </Col>
                        )}
                      {currentUser?.company?.has_warehouses && (
                        <Col sm={4}>
                          <Field
                            instanceId={`invoice-item-${index}-warehouse`}
                            name={`${item}.warehouse_id`}
                            label={CustomLabelSvc.setCustomLabel(
                              customLabels,
                              t("admin.pages.invoice.invoiceItems.warehouse"),
                            )}
                            placeholder="Select..."
                            component={WareHousePicker}
                            required={invoiceCommonSvc.isFieldRequired(
                              currentUser?.company?.invoice?.items?.warehouse?.is_required ||
                                fields.get(index).is_warehouse_required,
                              allowSubmitWithGLErrors,
                            )}
                            validate={
                              invoiceCommonSvc.isFieldRequired(
                                currentUser?.company?.invoice?.items?.warehouse?.is_required ||
                                  fields.get(index).is_warehouse_required,
                                allowSubmitWithGLErrors,
                              )
                                ? [required]
                                : []
                            }
                            floating={true}
                            // call-back="location_mapping_with_warehouse(warehouse, invoice_item)"
                          />
                        </Col>
                      )}

                      {currentUser?.company?.has_locations && !currentUser?.company?.invoice_item_hide_location && (
                        <Col sm={4}>
                          <Field
                            instanceId={`invoice-item-${index}-location`}
                            name={`${item}.location_id`}
                            label={CustomLabelSvc.setCustomLabel(
                              customLabels,
                              t("admin.pages.invoice.invoiceItems.location"),
                            )}
                            component={LocationPicker}
                            modelData={fields.get(index)}
                            parentObjData={invoice}
                            disabled={adminCommonSvc.isLocationDisabled(fields.get(index))}
                            callBack={(location: any) => dispatch(change(formName, `${item}.location`, location))}
                            required={invoiceCommonSvc.isFieldRequired(
                              invoiceItem.is_location_required || currentUser?.company?.invoice_item_location_required,
                              allowSubmitWithGLErrors,
                            )}
                            validate={
                              invoiceCommonSvc.isFieldRequired(
                                invoiceItem.is_location_required ||
                                  currentUser?.company?.invoice_item_location_required,
                                allowSubmitWithGLErrors,
                              )
                                ? [required]
                                : []
                            }
                          />
                        </Col>
                      )}

                      {currentUser?.company?.has_business_units &&
                        !currentUser?.company?.invoice_item_hide_business_unit && (
                          <Col sm={4}>
                            <Field
                              instanceId={`invoice-item-${index}-business-unit`}
                              name={`${item}.business_unit_id`}
                              label={CustomLabelSvc.setCustomLabel(
                                customLabels,
                                t("admin.pages.invoice.invoiceItems.businessUnit"),
                              )}
                              component={BusinessUnitPicker}
                              parentObjData={invoice}
                              modelData={fields.get(index)}
                              bySubsidiary={currentUser?.company?.invoice?.show_business_unit_by_subsidiary}
                              callBack={(businessUnit: any) =>
                                dispatch(change(formName, `${item}.business_unit`, businessUnit))
                              }
                              disabled={currentUser?.company?.readonly_business_unit_to_all}
                              required={invoiceCommonSvc.isFieldRequired(
                                currentUser?.company?.invoice?.items?.business_unit?.is_required,
                                allowSubmitWithGLErrors,
                              )}
                              validate={
                                invoiceCommonSvc.isFieldRequired(
                                  currentUser?.company?.invoice?.items?.business_unit?.is_required,
                                  allowSubmitWithGLErrors,
                                )
                                  ? [required]
                                  : []
                              }
                            />
                          </Col>
                        )}
                      {currentUser?.company?.has_inter_companies && (
                        <Col sm={4}>
                          <Field
                            instanceId={`invoice-item-${index}-inter-company`}
                            name={`${item}.inter_company_id`}
                            label={CustomLabelSvc.setCustomLabel(
                              customLabels,
                              t("admin.pages.invoice.invoiceItems.interCompany"),
                            )}
                            component={InterCompanyPicker}
                            params={{ account_id: `${item}.account_id`, subsidiary_id: invoice?.subsidiary_id }}
                            // TODO: Implement only_show_mapped_inter_company functionality
                          />
                        </Col>
                      )}
                      {adminCommonSvc.isBudgetVisibleAtLineLevel(currentUser) &&
                        !currentUser?.company?.budget?.allow_to_select_budget_lines && (
                          <Col sm={4}>
                            <Field
                              instanceId={`invoice-item-${index}-budget`}
                              name={`${item}.budget_id`}
                              component={BudgetPicker}
                              placeholder="Select..."
                              label={CustomLabelSvc.setCustomLabel(
                                customLabels,
                                t("admin.pages.invoice.invoiceItems.budget"),
                              )}
                              parentObjData={invoice}
                              modelData={fields.get(index)}
                            />
                          </Col>
                        )}

                      {adminCommonSvc.isBudgetVisibleAtLineLevel(currentUser) &&
                        currentUser?.company?.budget?.allow_to_select_budget_lines && (
                          <Col sm={4}>
                            <Field
                              instanceId={`invoice-item-${index}-budget-item`}
                              name={`${item}.budget_item_links`}
                              component={BudgetPicker}
                              placeholder="Select..."
                              isMulti={true}
                              label={CustomLabelSvc.setCustomLabel(
                                customLabels,
                                t("admin.pages.invoice.invoiceItems.budget"),
                                t("admin.pages.invoice.invoiceItems.budgets"),
                              )}
                              parentObjData={invoice}
                              modelData={fields.get(index)}
                              callBack={(selectedBudgets: any) =>
                                dispatch(change(formName, `${item}.budget_item_links_attributes`, selectedBudgets))
                              }
                            />
                          </Col>
                        )}

                      {currentUser?.company?.has_grants && (
                        <Col sm={4}>
                          <Field
                            instanceId={`invoice-item-${index}-grant`}
                            name={`${item}.grant_id`}
                            label={CustomLabelSvc.setCustomLabel(
                              customLabels,
                              t("admin.pages.invoice.invoiceItems.grant"),
                            )}
                            placeholder="Select..."
                            component={GrantPicker}
                            floating={true}
                            required={fields.get(index).is_grant_required}
                            validate={fields.get(index).is_grant_required ? [required] : []}
                          />
                        </Col>
                      )}

                      {currentUser?.company?.has_event_codes && (
                        <Col sm={4}>
                          <Field
                            instanceId={`invoice-item-${index}-event-code`}
                            name={`${item}.event_code_id`}
                            label={CustomLabelSvc.setCustomLabel(
                              customLabels,
                              t("admin.pages.invoice.invoiceItems.eventCode"),
                            )}
                            placeholder="Select..."
                            component={EventCodePicker}
                            floating={true}
                          />
                        </Col>
                      )}

                      {currentUser?.company?.has_secondary_categories && (
                        <Col sm={4}>
                          <Field
                            instanceId={`invoice-item-${index}-secondary-category`}
                            name={`${item}.secondary_category_id`}
                            label={CustomLabelSvc.setCustomLabel(
                              customLabels,
                              t("admin.pages.invoice.invoiceItems.secondaryCategory"),
                            )}
                            component={SecondaryCategoryPicker}
                            placeholder="Select..."
                            floating={true}
                          />
                        </Col>
                      )}

                      {currentUser?.company?.has_business_units && currentUser?.company?.allow_for_business_unit && (
                        <Col sm={4}>
                          <Field
                            instanceId={`invoice-item-${index}-for-business-unit`}
                            name={`${item}.for_business_unit_id`}
                            label={CustomLabelSvc.setCustomLabel(
                              customLabels,
                              t("admin.pages.invoice.invoiceItems.forBusinessUnit"),
                            )}
                            placeholder="Select..."
                            component={BusinessUnitPicker}
                            floating={true}
                          />
                        </Col>
                      )}

                      {currentUser?.company?.has_locations && currentUser?.company?.allow_for_location && (
                        <Col sm={4}>
                          <Field
                            instanceId={`invoice-item-${index}-for-location`}
                            name={`${item}.for_location_id`}
                            label={CustomLabelSvc.setCustomLabel(
                              customLabels,
                              t("admin.pages.invoice.invoiceItems.forLocation"),
                            )}
                            modelData={fields.get(index)}
                            parentObjData={invoice}
                            placeholder="Select..."
                            component={LocationPicker}
                          />
                        </Col>
                      )}

                      {currentUser?.company?.has_for_subsidiaries && (
                        <Col sm={4}>
                          <Field
                            instanceId={`invoice-item-${index}-for-subsidiary`}
                            name={`${item}.for_subsidiary_id`}
                            label={CustomLabelSvc.setCustomLabel(
                              customLabels,
                              t("admin.pages.invoice.invoiceItems.forSubsidiary"),
                            )}
                            placeholder="Select..."
                            component={ForSubsidiaryPicker}
                          />
                        </Col>
                      )}

                      {currentUser?.company?.has_territories && (
                        <Col sm="4">
                          <Field
                            instanceId={`invoice-item-${index}-territory`}
                            name={`${item}.territories_external_id`}
                            component={CustomDataListPicker}
                            label={CustomLabelSvc.setCustomLabel(
                              customLabels,
                              t("admin.pages.invoice.invoiceItems.territories"),
                            )}
                            modelName={"Territories"}
                            floating={true}
                          />
                        </Col>
                      )}

                      {currentUser?.company?.has_customers && (
                        <Col sm="4">
                          <Field
                            instanceId={`invoice-item-${index}-customer-external`}
                            name={`${item}.customer_external_id`}
                            component={CustomDataListPicker}
                            label={CustomLabelSvc.setCustomLabel(
                              customLabels,
                              t("admin.pages.invoice.invoiceItems.customer"),
                            )}
                            modelName={"Customer"}
                            floating={true}
                          />
                        </Col>
                      )}

                      {currentUser?.company?.has_custom_employee && (
                        <Col sm="4">
                          <Field
                            instanceId={`invoice-item-${index}-employee-external`}
                            name={`${item}.custom_employee_external_id`}
                            component={CustomDataListPicker}
                            label={CustomLabelSvc.setCustomLabel(
                              customLabels,
                              t("admin.pages.invoice.invoiceItems.employee"),
                            )}
                            modelName={"CustomEmployee"}
                            floating={true}
                          />
                        </Col>
                      )}

                      {currentUser?.company?.has_custom_types && (
                        <Col sm="4">
                          <Field
                            instanceId={`invoice-item-${index}-custom-type-external`}
                            name={`${item}.custom_type_external_id`}
                            component={CustomDataListPicker}
                            label={CustomLabelSvc.setCustomLabel(
                              customLabels,
                              t("admin.pages.invoice.invoiceItems.type"),
                            )}
                            modelName={"CustomType"}
                            floating={true}
                          />
                        </Col>
                      )}

                      {currentUser?.company?.has_company_events && (
                        <Col sm="4">
                          <Field
                            instanceId={`invoice-item-${index}-company-event-external-id`}
                            name={`${item}.company_event_external_id`}
                            component={CustomDataListPicker}
                            label={CustomLabelSvc.setCustomLabel(
                              customLabels,
                              t("admin.pages.invoice.invoiceItems.companyEvent"),
                            )}
                            modelName={"CompanyEvent"}
                            floating={true}
                          />
                        </Col>
                      )}

                      {currentUser?.company?.has_vineyard_codes && (
                        <Col sm="4">
                          <Field
                            instanceId={`invoice-item-${index}-vineyard-code`}
                            name={`${item}.vineyard_code_external_id`}
                            component={CustomDataListPicker}
                            label={CustomLabelSvc.setCustomLabel(
                              customLabels,
                              t("admin.pages.invoice.invoiceItems.vineyardCode"),
                            )}
                            modelName={"VineyardCode"}
                            floating={true}
                          />
                        </Col>
                      )}

                      {currentUser?.company?.show_employee_field_invoice_item_expense && (
                        <Col sm="4" className={styles.vendorFormField}>
                          <Field
                            instanceId={`invoice-item-${index}-employee`}
                            name={`${item}.employee_id`}
                            component={ContactPicker}
                            label={CustomLabelSvc.setCustomLabel(
                              customLabels,
                              t("admin.pages.invoice.invoiceItems.employee"),
                            )}
                            floating={true}
                          />
                        </Col>
                      )}

                      {/* <Col sm={4}>
                        <Field
                          name={`${item}.vendor_part_number`}
                          type="text"
                          label="Vendor Part Number"
                          disabled={true}
                          // TODO: onChange="update_item_debit_account_amount(invoice_item)"
                        />
                      </Col> */}
                      {currentUser?.company?.has_projects && !invoiceItem.is_hide_project && (
                        <Col sm={4}>
                          <Field
                            instanceId={`invoice-item-${index}-project`}
                            name={`${item}.project`}
                            label={CustomLabelSvc.setCustomLabel(
                              customLabels,
                              t("admin.pages.invoice.invoiceItems.project"),
                            )}
                            component={ProjectPicker}
                            callBack={(selected: ProjectOptionsType) =>
                              changeProject(fields.get(index), selected, index)
                            }
                            required={invoiceCommonSvc.isFieldRequired(
                              invoiceCommonSvc.isProjectRequired(fields.get(index), currentUser, true),
                              allowSubmitWithGLErrors,
                            )}
                            validate={
                              invoiceCommonSvc.isFieldRequired(
                                invoiceCommonSvc.isProjectRequired(fields.get(index), currentUser, true),
                                allowSubmitWithGLErrors,
                              )
                                ? [required]
                                : null
                            }
                            menuPosition="fixed"
                          />
                        </Col>
                      )}

                      {currentUser?.company?.has_projects && currentUser?.company?.allow_for_project && (
                        <Col sm={4}>
                          <Field
                            instanceId={`invoice-item-${index}-for-project`}
                            name={`${item}.for_project`}
                            label={CustomLabelSvc.setCustomLabel(
                              customLabels,
                              t("admin.pages.invoice.invoiceItems.forProject"),
                            )}
                            callBack={(selected: ProjectOptionsType) =>
                              changeForProject(fields.get(index), selected, index)
                            }
                            component={ProjectPicker}
                            menuPosition="fixed"
                          />
                        </Col>
                      )}

                      {currentUser?.company?.amortization_scheduled && (
                        <Col sm={12}>
                          <AmortizationSchedule
                            id={`invoice-item-${index}-amortization-schedule`}
                            amortizationId={`${item}.amortization_id`}
                            startDate={fields.get(index).amortization_start_date}
                            endDate={fields.get(index).amortization_end_date}
                            amortizationStartDate={`${item}.amortization_start_date`}
                            amortizationEndDate={`${item}.amortization_end_date`}
                            amortizationScheduleName={
                              fields.get(index).amortization_schedule_name !== null
                                ? fields.get(index).amortization_schedule_name
                                : null
                            }
                          />
                        </Col>
                      )}

                      {invoiceCommonSvc.show1099Eligible(invoice, currentUser) && (
                        <Col sm={12}>
                          <Field
                            id={`invoice_item_is_1099_eligible_${index}`}
                            name="eligible_1099"
                            component={RenderCheckBox}
                            defaultValue={fields.get(index).eligible_1099}
                            onChange={(value: any) => (fields.get(index).eligible_1099 = value)}
                            type="checkbox"
                            label={CustomLabelSvc.setCustomLabel(
                              customLabels,
                              t("admin.pages.invoice.invoiceItems.1099Eligible"),
                            )}
                          />
                        </Col>
                      )}

                      <Col sm={12}>
                        <Field
                          name={`${item}.description`}
                          label={CustomLabelSvc.setCustomLabel(
                            customLabels,
                            t("admin.pages.invoice.invoiceItems.description"),
                          )}
                          rows={2}
                          component={RenderTextArea}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col md="12">
                        <CustomFieldPicker
                          formFieldName={`${item}.custom_fields`}
                          formName={formName}
                          parentObjData={invoice}
                          modelData={fields.get(index)}
                          modelDataFieldName={`${item}`}
                          modelName="InvoiceItem"
                        />
                      </Col>

                      {showAdditionalCodingSection && (
                        <ErrorBoundary>
                          <MetadataFieldSelector
                            formName={formName}
                            formSlice={`${item}`}
                            modules="InvoiceItem"
                            allowSubmitWithGLErrors={allowSubmitWithGLErrors}
                          />
                        </ErrorBoundary>
                      )}
                    </Row>
                  </Panel>
                );
              }
            })}
        </Col>
      </Row>
      <Row className="px-mt-15 px-mb-25">
        <Col sm={10}>
          <Button variant="btn btn-primary" onClick={() => addRow(fields)}>
            <i className="btn-icon icon-add-btn"></i> Add Items
          </Button>
        </Col>

        {invoiceCommonSvc.isItemExist(invoice?.invoice_items_attributes).length > 0 && (
          <Col sm={2} className={styles.subtotalAmountsAlignment}>
            <Row>
              <Col sm={12} className="px-mb-10">
                <span>
                  Subtotal:{" "}
                  {formattedAmount(
                    (invoiceItemsTotals.invoiceSubTotal || 0).toString(),
                    invoice?.currency_code,
                    defaultDecimal,
                    true,
                  )}
                </span>
              </Col>
              {invoiceItemsTotals.invoiceSubTotal > 0 && invoiceItemsTotals.invoiceTaxesTotal !== 0 && (
                <Col sm={12} className="px-mb-10">
                  <span>
                    Tax:{" "}
                    {formattedAmount(
                      (invoiceItemsTotals.invoiceTaxesTotal || "").toString(),
                      invoice?.currency_code,
                      defaultDecimal,
                      true,
                    )}
                  </span>
                </Col>
              )}
              {currentUser?.company?.has_wh_taxes &&
                invoiceItemsTotals.invoiceWHTaxesTotal !== 0 &&
                !invoice.is_used_tax && (
                  <Col sm={12}>
                    <span>
                      WH Tax:{" "}
                      {formattedAmount(
                        invoiceItemsTotals.invoiceWHTaxesTotal.toString(),
                        invoice?.currency_code,
                        defaultDecimal,
                        true,
                      )}
                    </span>
                  </Col>
                )}
              {invoiceCommonSvc.allowRebateItemsLine(currentUser) && Boolean(invoiceItemsTotals.invoiceRebateTotal) && (
                <Col sm={12}>
                  <span>
                    Rebate:{" "}
                    {formattedAmount((invoiceItemsTotals.invoiceRebateTotal || "").toString(), invoice?.currency_code)}
                  </span>
                </Col>
              )}{" "}
            </Row>
          </Col>
        )}
      </Row>
    </>
  );
};

export default ManageInvoiceItems;
