import useShowSelectMenu from "components/admin/hooks/useShowSelectMenu";
import _ from "lodash";
import React, { ReactNode, useCallback, useEffect, useRef, useState } from "react";
import { Form } from "react-bootstrap";
import { components, MultiValue, SingleValue } from "react-select";
import AsyncSelect from "react-select/async";
import { WrappedFieldProps } from "redux-form";
import { CommonTypes } from "services/common/types/commonTypes";
import { isDefined } from "services/general/helpers";
import { restApiService } from "../../../../../providers/restApi";
import { Mandatory } from "../../../../forms/bootstrapFields";
import Styles from "../override.module.css";
import PickerErrorBlock from "../pickerErrorBlock";

export interface ProjectPickerPropsType extends WrappedFieldProps, CommonTypes.TSelectMenu {
  label?: string;
  filter?: any;
  required?: boolean;
  tooltip?: ReactNode;
  placeholder?: string;
  isMulti?: boolean;
  returnObject?: boolean;
  className?: string;
  isClearable?: boolean;
  menuPosition?: any;
  instanceId?: string;
  error?: string;
  callBack?: (selected: SingleValue<void | ProjectOptionsType>) => void;
  modelData?: any;
  isForProject?: boolean;
  instaceId?: string;
  showAsteriskWithoutLabel?: boolean;
}

export type ProjectOptionsType = {
  id?: number;
  name?: string;
  status?: string;
  location_id?: null;
  department_id?: null;
  customer_external_id?: null;
  has_accounts?: boolean;
  project_type_id?: null;
  project_id: number;
  value: number;
  label: ReactNode;
  destroy?: 1;
};

const CustomMultiValue = (props: any) => {
  const { data } = props;
  if (data && data._destroy === 1) {
    return null; // Exclude options with _destroy: 1 from being rendered as selected
  }
  return <components.MultiValue {...props} />;
};

const ProjectPicker = ({
  label,
  input,
  meta: { touched, error: error2 },
  filter = { LIMIT: 100, status: "ACTIVE" },
  className,
  required,
  placeholder,
  tooltip,
  isMulti,
  isClearable,
  menuPosition,
  instanceId,
  error,
  callBack,
  modelData,
  isForProject,
  instaceId,
  showAsteriskWithoutLabel,
  parentDivId,
  parentDivVariance,
}: ProjectPickerPropsType) => {
  const [selectedOptions, setSelectedOptions] = useState(input.value);
  const parseForProjectOptions = (projects: any) => {
    const parsedData = projects.map((project: any) => {
      return {
        ...project,
        project_id: project.id,
        value: project.id,
        label: project.name,
      };
    });
    return parsedData;
  };

  const getProjectOptions = async (inputValue: string) => {
    const result = await restApiService.get(
      "projects.lk",
      { PROJECT_NAME: inputValue, ...filter },
      null,
      true,
      null,
      true,
    );
    const value = parseForProjectOptions(result?.data?.data);
    return value;
  };

  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const selectRef = useRef<HTMLDivElement | null>(null);
  useShowSelectMenu({
    setIsMenuOpen,
    selectRef,
    parentDivId,
    variance: parentDivVariance,
  });

  const loadOptions = async (inputValue: string, callback: any) => {
    const options = await getProjectOptions(inputValue);
    callback(options);
  };

  const onChangeSingle = (selectedOption: SingleValue<void | ProjectOptionsType>) => {
    input.onChange(selectedOption);
    if (callBack) {
      callBack(selectedOption);
    }
  };

  const onChangeMultiple = (selectedOptions: MultiValue<ProjectOptionsType>) => {
    setSelectedOptions(selectedOptions);
    input.onChange(selectedOptions);
  };

  // custom logic to handle _destroyed
  const handleOptionRemove = (data: any) => {
    if (_.isArray(selectedOptions)) {
      const newValues = selectedOptions.map((val: any) => {
        if (val.value === data.value) {
          val._destroy = 1;
        }
        return val;
      });
      input.onChange(newValues);
      setSelectedOptions(newValues);
    }
  };

  // const onChangeProject =(selectedOption:any)=>{
  //   onChangeSingle(selectedOption);
  // }

  useEffect(() => {
    if (typeof input.value === "object" && !input.value.label) {
      input.value.label = input.value.name;
    }

    setSelectedOptions(input.value);
  }, [input.value]);

  //this is to handle case when we dont get for_project object but there is for_project_id available
  const initProject = () => {
    if (input.value || selectedOptions) {
      return false;
    }

    if (_.isPlainObject(modelData) && !modelData.for_project && modelData.for_project_id && isForProject) {
      let obj: any = {};
      obj.id = obj.value = obj.project_id = modelData.for_project_id;
      obj.name = obj.label = modelData.for_project_name;
      if (!isMulti) {
        onChangeSingle(obj);
      }
    }
  };

  const checkIsClearable = useCallback(() => {
    if (required) {
      return false;
    } else if (isDefined(isClearable)) {
      return isClearable;
    } else if (_.isUndefined(isClearable)) {
      return false;
    }
  }, [isClearable, required]);

  useEffect(() => {
    initProject();
  }, [modelData?.for_project_id]);

  if (isMulti) {
    return (
      <Form.Group className={className}>
        {label && (
          <Form.Label>
            {label ?? null}
            <Mandatory required={required} />
            {tooltip ?? null}
          </Form.Label>
        )}
        <AsyncSelect
          {...input}
          value={selectedOptions}
          required={required}
          loadOptions={(inputValue, callback): any => loadOptions(inputValue, callback)}
          onChange={(selectedOption) => {
            onChangeMultiple(selectedOption);
          }}
          onBlur={() => {
            input.onBlur(input.value);
          }}
          isMulti
          components={{
            MultiValue: CustomMultiValue,
            MultiValueRemove: ({ innerProps, data }) => (
              <span {...innerProps} onClick={() => handleOptionRemove(data)}>
                {"  "}&times;{"  "}
              </span>
            ),
          }}
          defaultOptions
          cacheOptions={true}
          placeholder={placeholder ?? ""}
          isClearable={checkIsClearable()}
          menuPosition={menuPosition ? menuPosition : null}
          instanceId={instanceId ? instanceId : "multi-project-picker"}
        />
        {error && <div className="error">{error}</div>}
      </Form.Group>
    );
  }

  if (!isMulti) {
    return (
      <Form.Group className={`${Styles.select} ` + className} ref={selectRef}>
        {label && (
          <Form.Label>
            {label ?? null}
            <Mandatory required={required} />
            {tooltip ?? null}
          </Form.Label>
        )}
        {!label && showAsteriskWithoutLabel && required && (
          <div className="d-flex justify-content-end">
            <Mandatory required={required} />
          </div>
        )}
        <AsyncSelect
          {...input}
          required={false}
          loadOptions={(inputValue, callback): any => loadOptions(inputValue, callback)}
          onChange={(selectedOption) => {
            onChangeSingle(selectedOption);
          }}
          onBlur={() => {
            input.onBlur(input.value);
          }}
          defaultOptions
          cacheOptions
          placeholder={placeholder ?? ""}
          isClearable={checkIsClearable()}
          menuPosition={menuPosition ? menuPosition : null}
          menuIsOpen={isMenuOpen}
          onMenuOpen={() => setIsMenuOpen(true)}
          onMenuClose={() => setIsMenuOpen(false)}
          menuPlacement="auto"
          instanceId={instanceId ? instanceId : "single-project-picker"}
        />
        {(error || error2) && touched && <PickerErrorBlock error={error || error2} />}
      </Form.Group>
    );
  }
  return <></>;
};

export default ProjectPicker;
