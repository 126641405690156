import CollapsibleViewerCard from "components/common/unifiedViewer/collapsibleViewerCard";
import DocumentViewer from "components/common/unifiedViewer/documentViewer";
import PdfPreviewViewer from "components/common/unifiedViewer/pdfPreviewViewer";
import PdfToolbar from "components/common/unifiedViewer/PdfToolbar";
import useConfirmModal from "components/modals/confirmModal/useConfirmModalHook";
import { debounce } from "lodash";
import { PDFDocument } from "pdf-lib";
import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import { BsFileEarmarkPlus, BsFileEarmarkX, BsList, BsSignIntersectionY } from "react-icons/bs";
import InvoiceApis from "services/admin/invoices/invoiceApis";
import { CreateNotification, NotificationType } from "services/general/notifications";
import { useFileRenderingContext } from "./fileRenderingContext";
import styles from "./fileUploadSection.module.css";
import { useInvoiceDetailsContext } from "./invoiceDetailsContext";
import {
  createExtractedFileAsync,
  deleteAndAttachFileToFormDataAsync,
  deleteAssetAsync,
  downloadFile,
  getNewPdfFromSelectedPages,
  handleError,
  printFileFromBlob,
  stopEventPropagation,
} from "./invoiceDetailsService";
import { useUiStateContext } from "./uiStateContext";

const initialSize = 1;
const zeroXyPoint = { x: 0, y: 0 };

type TFileUploadSectionProps = {
  saveAsyncCallback: (notify: boolean) => Promise<void>;
};

const FileUploadSection: React.FC<TFileUploadSectionProps> = ({ saveAsyncCallback }) => {
  const { t } = useTranslation();
  const [showThumbnails, setShowThumbnails] = useState(false);
  const {
    invoiceDetails,
    isFetchingInvoiceDetails,
    refetchInvoiceDetails,
    assets,
    isSingleAsset,
    selectedAsset,
    setSelectedAsset,
    isSelectedAssetPdf,
  } = useInvoiceDetailsContext();
  const {
    pageNumber,
    setPageNumber,
    numPages,
    setNumPages,
    fileToRenderMemo,
    invalidateCurrentInvoiceFileCache,
    pdfDocumentToRender,
    resetFileContext,
  } = useFileRenderingContext();
  const { checkboxValues, setIsOperationRunning, setCheckboxValues, isOperationRunning, isExpanded, handleToggle } =
    useUiStateContext();
  const { createConfirmModal } = useConfirmModal();
  const [dragStart, setDragStart] = useState(zeroXyPoint);
  const [rotation, setRotation] = useState(0);
  const [dragOffset, setDragOffset] = useState(zeroXyPoint);
  const [scale, setScale] = useState(initialSize);
  const [boxWidth, setBoxWidth] = useState(0);
  const boxRef = useRef<HTMLDivElement>(null);

  const debouncedUpdateWidth = useMemo(
    () =>
      debounce(() => {
        if (boxRef.current && isExpanded) {
          const width = boxRef.current.clientWidth;
          if (width > 0) {
            setBoxWidth(width);
            setScale(1);
          }
        }
      }, 500),
    [isExpanded],
  );

  useEffect(() => {
    const element = boxRef.current;
    if (!element) return;
    const initialWidth = element.clientWidth;
    if (initialWidth > 0) {
      setBoxWidth(initialWidth);
    }
    const observer = new ResizeObserver(() => {
      debouncedUpdateWidth();
    });
    observer.observe(element);
    return () => {
      observer.disconnect();
      debouncedUpdateWidth.cancel();
    };
  }, [debouncedUpdateWidth, fileToRenderMemo]);

  const refreshInvoiceDetailsAsync = useCallback(
    async (id: number | undefined): Promise<void> => {
      if (!id) return;

      resetFileContext();
      invalidateCurrentInvoiceFileCache();
      refetchInvoiceDetails();
    },
    [resetFileContext, invalidateCurrentInvoiceFileCache, refetchInvoiceDetails],
  );

  const attachFilesAsync = async (files: File[]) => {
    if (!invoiceDetails?.invoice || files.length === 0) return;

    try {
      setIsOperationRunning(true);
      await InvoiceApis.attachFilesToInvoice(invoiceDetails?.invoice.id?.toString()!, files);

      CreateNotification(
        t("admin.pages.details.updated"),
        t("admin.pages.details.attachFileSuccess", { invNumber: invoiceDetails.invoice.number }),
        NotificationType.success,
      );
    } catch (error) {
      handleError(error, t, "Errorattaching files");
    } finally {
      setIsOperationRunning(false);
      await refreshInvoiceDetailsAsync(invoiceDetails.invoice.id);
    }
  };

  const printGenericFile = useCallback(
    async (event?: React.MouseEvent) => {
      stopEventPropagation(event);

      try {
        if (!fileToRenderMemo) return;

        printFileFromBlob(fileToRenderMemo, t);
      } catch (err) {
        CreateNotification(t("error"), "Error printing file", NotificationType.danger);
      }
    },
    [fileToRenderMemo, t],
  );

  const downloadGenericFile = useCallback(
    async (event?: React.MouseEvent) => {
      stopEventPropagation(event);

      try {
        if (!fileToRenderMemo) return;

        downloadFile(fileToRenderMemo, t);
      } catch (err) {
        CreateNotification(t("error"), "Error downloading file", NotificationType.danger);
      }
    },
    [fileToRenderMemo, t],
  );

  const downloadPdf = useCallback(
    async (event?: React.MouseEvent) => {
      stopEventPropagation(event);

      try {
        if (!fileToRenderMemo) return;

        downloadFile(fileToRenderMemo, t);
      } catch (err) {
        console.error(err);
        CreateNotification(t("error"), "Error downloading PDF", NotificationType.danger);
      }
    },
    [fileToRenderMemo, t],
  );

  const mappedCheckboxes = Array.from(checkboxValues.entries())
    .filter(([_, should]) => should)
    .map(([pageNumber]) => pageNumber);

  const isAnyCheckboxChecked =
    checkboxValues && Array.from(checkboxValues.values()).some((val) => val) && isSelectedAssetPdf;

  const printPdf = useCallback(
    async (event?: React.MouseEvent) => {
      stopEventPropagation(event);

      if (!invoiceDetails || !selectedAsset || !pdfDocumentToRender) {
        return;
      }
      if (mappedCheckboxes && mappedCheckboxes.length === 0) {
        printFileFromBlob(fileToRenderMemo, t);
      } else {
        const { newPdfFromSelectedPages } = await getNewPdfFromSelectedPages(mappedCheckboxes, pdfDocumentToRender);
        const blob = new Blob([newPdfFromSelectedPages], { type: "application/pdf" });
        printFileFromBlob(blob, t);
      }
    },
    [invoiceDetails, selectedAsset, pdfDocumentToRender, mappedCheckboxes, fileToRenderMemo, t],
  );

  const addPdfFileAsync = async (fileToAdd: File) => {
    if (!fileToAdd || !invoiceDetails || !selectedAsset) return;

    setIsOperationRunning(true);

    try {
      if (!pdfDocumentToRender) return;

      await saveAsyncCallback(false);

      const pdfBytes = await fileToAdd.arrayBuffer();
      const pdfDocToAdd = await PDFDocument.load(pdfBytes);
      const numPagesToAdd = pdfDocToAdd.getPageCount();
      const pageNumbers = Array.from({ length: numPagesToAdd }, (_, index) => index);
      const copiedPages = await pdfDocumentToRender.copyPages(pdfDocToAdd, pageNumbers);

      for (const page of copiedPages) {
        pdfDocumentToRender.addPage(page);
      }

      const modifiedPdfBytes = await pdfDocumentToRender.save();

      await deleteAndAttachFileToFormDataAsync(invoiceDetails.invoice, modifiedPdfBytes, selectedAsset, t, false);

      CreateNotification(
        t("admin.pages.details.saved"),
        t("admin.pages.details.confirm", { invNumber: invoiceDetails.invoice.number }),
        NotificationType.success,
      );
    } catch (error) {
      handleError(error, t, "Error modifying PDF file");
    } finally {
      setIsOperationRunning(false);
      await refreshInvoiceDetailsAsync(invoiceDetails.invoice.id);
    }
  };

  const deletePdfPageAsync = async () => {
    if (!isAnyCheckboxChecked || !invoiceDetails || !selectedAsset || !invoiceDetails.invoice) return;

    setIsOperationRunning(true);

    try {
      if (!pdfDocumentToRender) return;

      await saveAsyncCallback(false);

      const pages = [...mappedCheckboxes].sort((a, b) => b - a);

      if (pages.length === numPages) {
        CreateNotification(t("warning"), "Cannot delete all invoices.", NotificationType.warning);
        return;
      }

      pages.forEach((pageNumber) => {
        pdfDocumentToRender.removePage(pageNumber - 1);
      });

      const modifiedPdfBytes = await pdfDocumentToRender.save();

      await deleteAndAttachFileToFormDataAsync(invoiceDetails.invoice, modifiedPdfBytes, selectedAsset, t);
    } catch (error) {
      handleError(error, t, "Error modifying PDF file");
    } finally {
      setIsOperationRunning(false);
      await refreshInvoiceDetailsAsync(invoiceDetails.invoice.id);
    }
  };

  const splitPdfPageAsync = async () => {
    if (!isAnyCheckboxChecked || !invoiceDetails || !selectedAsset || !invoiceDetails.invoice) return;

    setIsOperationRunning(true);
    try {
      if (!pdfDocumentToRender) return;

      await saveAsyncCallback(false);

      const { newPdfFromSelectedPages, pages } = await getNewPdfFromSelectedPages(
        mappedCheckboxes,
        pdfDocumentToRender,
      );
      const filename = `split_${selectedAsset.asset_file_file_name}`;
      await createExtractedFileAsync(newPdfFromSelectedPages, filename, t);

      const remainingPdf = await PDFDocument.create();
      const totalPages = pdfDocumentToRender.getPageCount();

      for (let i = 0; i < totalPages; i++) {
        if (!pages.includes(i + 1)) {
          const [page] = await remainingPdf.copyPages(pdfDocumentToRender, [i]);
          remainingPdf.addPage(page);
        }
      }

      const remainingPdfBytes = await remainingPdf.save();
      selectedAsset.asset_file_file_name = "original_" + selectedAsset.asset_file_file_name;

      await deleteAndAttachFileToFormDataAsync(invoiceDetails.invoice, remainingPdfBytes, selectedAsset, t);
    } catch (error) {
      handleError(error, t, "Error modifying PDF file");
    } finally {
      setIsOperationRunning(false);
      await refreshInvoiceDetailsAsync(invoiceDetails.invoice.id);
    }
  };

  const onHandleDeletePdfPageClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    stopEventPropagation(event);

    if (!isAnyCheckboxChecked) {
      return;
    }

    createConfirmModal({
      title: "Confirm Delete",
      body: "Are you sure you want to delete the selected page(s)?",
      saveCallBack: deletePdfPageAsync,
      cancelCallBack: () => {},
      confirmButtonLabel: "Delete",
      cancelButtonLabel: "Cancel",
    });
  };

  const onHandleSplitPdfPageClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    stopEventPropagation(event);

    if (!isAnyCheckboxChecked) {
      return;
    }

    createConfirmModal({
      title: "Confirm Split Page",
      body: "Are you sure you want to split the selected page(s)?",
      saveCallBack: splitPdfPageAsync,
      cancelCallBack: () => {},
      confirmButtonLabel: "Split",
      cancelButtonLabel: "Cancel",
    });
  };

  const deleteSelectedAssetAsync = useCallback(async () => {
    if (!selectedAsset || !invoiceDetails?.invoice) return;

    setIsOperationRunning(true);

    try {
      await deleteAssetAsync(invoiceDetails?.invoice.id?.toString()!, selectedAsset, t);
    } catch (error) {
      handleError(error, t, "Error deleting file");
    } finally {
      setIsOperationRunning(false);
      await refreshInvoiceDetailsAsync(invoiceDetails.invoice.id);
    }
  }, [selectedAsset, invoiceDetails, setIsOperationRunning, t, refreshInvoiceDetailsAsync]);

  const onHandleDeleteSelectedAssetClick = useCallback(
    (event?: React.MouseEvent<HTMLButtonElement>) => {
      stopEventPropagation(event);

      if (!selectedAsset) return;

      createConfirmModal({
        title: "Confirm Delete",
        body: `Are you sure you want to delete this file: ${selectedAsset?.asset_file_file_name}?`,
        saveCallBack: deleteSelectedAssetAsync,
        cancelCallBack: () => {},
        confirmButtonLabel: "Delete",
        cancelButtonLabel: "Cancel",
      });
    },
    [selectedAsset, createConfirmModal, deleteSelectedAssetAsync],
  );

  const deletePdf = useCallback(
    (event?: React.MouseEvent<HTMLButtonElement>) => onHandleDeleteSelectedAssetClick(event),
    [onHandleDeleteSelectedAssetClick],
  );

  const panelLabel = (() => {
    if (!selectedAsset) return "";

    if (!selectedAsset.asset_file_file_name) return invoiceDetails?.label;

    return <span title={selectedAsset.asset_file_file_name}>{invoiceDetails?.label}</span>;
  })();

  // *********** Dropzone add files to pdf ***********
  const dropzoneRef = useRef<HTMLInputElement | null>(null);
  const { getRootProps, getInputProps, open } = useDropzone({
    noClick: true,
    noKeyboard: true,
    noDragEventsBubbling: true,
    noDrag: true,
    maxFiles: 1,
    accept: [".pdf"],
    onDrop: (acceptedFiles) => {
      if (acceptedFiles.length === 0) return;

      const file = acceptedFiles[0];
      addPdfFileAsync(file);
    },
  });

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    stopEventPropagation(event);
    open();
  };

  // *********** Dropzone Attach files to invoice ***********
  const dropzoneRefAttachFiles = useRef<HTMLInputElement | null>(null);
  const {
    getRootProps: getRootPropsAttachFiles,
    getInputProps: getInputPropsAttachFiles,
    open: openAttachFiles,
  } = useDropzone({
    noClick: true,
    noKeyboard: true,
    noDragEventsBubbling: true,
    noDrag: true,
    maxFiles: 10,
    onDrop: (acceptedFiles) => {
      if (acceptedFiles.length === 0) return;
      attachFilesAsync(acceptedFiles);
    },
  });

  const handleThumbnailsVisibility = (event: React.MouseEvent<HTMLButtonElement>, value: boolean) => {
    stopEventPropagation(event);
    setShowThumbnails(value);
  };

  const handleShowThumbnails = (event: React.MouseEvent<HTMLButtonElement>) => handleThumbnailsVisibility(event, true);
  const handleHideThumbnails = (event: React.MouseEvent<HTMLButtonElement>) => handleThumbnailsVisibility(event, false);
  const minimizePdfThumbnailsIcons =
    panelLabel && !isOperationRunning && !isFetchingInvoiceDetails && isExpanded ? (
      <>
        {!showThumbnails && isSelectedAssetPdf && (
          <button
            key={"showThumbnails"}
            className={`${styles.secToolbarButton}`}
            onClick={handleShowThumbnails}
            aria-label={"Show Thumbnails"}
            role="listitem"
            title={"Show Thumbnails"}
            type="button"
          >
            <BsList size={20} />
          </button>
        )}
        {showThumbnails && isSelectedAssetPdf && (
          <button
            key={"hideThumbnails"}
            className={`${styles.secToolbarButton}`}
            onClick={handleHideThumbnails}
            aria-label={"Hide Thumbnails"}
            role="listitem"
            title={"Hide Thumbnails"}
            type="button"
          >
            <BsList size={20} />
          </button>
        )}
      </>
    ) : (
      <></>
    );

  const disableSplitAndDeleteBtns = !isAnyCheckboxChecked || !showThumbnails || !isSelectedAssetPdf;
  const disableAddBtn = isOperationRunning || isFetchingInvoiceDetails || !showThumbnails || !isSelectedAssetPdf;

  const splitPdfPageIcon = (
    <button
      className={`${styles.secToolbarButton}`}
      onClick={onHandleSplitPdfPageClick}
      aria-label={t("components.common.filePreview.toolbar.splitPage")}
      disabled={disableSplitAndDeleteBtns}
      role="listitem"
      title={t("components.common.filePreview.toolbar.splitPage")}
      type="button"
    >
      <BsSignIntersectionY size={20} />
    </button>
  );

  const addPdfPageIcon = (
    <button
      key="addPdfPageIcon"
      className={`${styles.secToolbarButton}`}
      onClick={handleOpen}
      aria-label={t("components.common.filePreview.toolbar.addPage")}
      disabled={disableAddBtn}
      role="listitem"
      title={t("components.common.filePreview.toolbar.addPage")}
      type="button"
    >
      <BsFileEarmarkPlus size={20} />
    </button>
  );

  const deletePdfPageIcon = (
    <button
      key="deletePdfPageIcon"
      className={`${styles.secToolbarButton}`}
      onClick={onHandleDeletePdfPageClick}
      aria-label={t("components.common.filePreview.toolbar.deletePage")}
      disabled={disableSplitAndDeleteBtns}
      role="listitem"
      title={t("components.common.filePreview.toolbar.deletePage")}
      type="button"
    >
      <BsFileEarmarkX size={20} />
    </button>
  );

  useEffect(() => {
    if (isSelectedAssetPdf) {
      setPageNumber(1);
      setShowThumbnails(false);
    }
  }, [fileToRenderMemo, isSelectedAssetPdf, setPageNumber]);

  return (
    <>
      <div {...getRootProps({ style: { display: "none" } })} ref={dropzoneRef}>
        <input {...getInputProps()} />
      </div>
      <div {...getRootPropsAttachFiles({ style: { display: "none" } })} ref={dropzoneRefAttachFiles}>
        <input {...getInputPropsAttachFiles()} />
      </div>
      <CollapsibleViewerCard
        isSingleAsset={isSingleAsset}
        assets={assets}
        selectedAsset={selectedAsset}
        onFileThumbnailClick={setSelectedAsset}
        onAttachFilesClick={openAttachFiles}
        onDeleteFileClick={isSelectedAssetPdf ? deletePdf : onHandleDeleteSelectedAssetClick}
        onDownloadFileClick={isSelectedAssetPdf ? downloadPdf : downloadGenericFile}
        onPrintFileClick={isSelectedAssetPdf ? printPdf : printGenericFile}
        onToggleCard={handleToggle}
        isExpanded={isExpanded}
        isOperationRunning={isOperationRunning}
      >
        {isSelectedAssetPdf && (
          <PdfToolbar
            collapseIcon={minimizePdfThumbnailsIcons}
            scale={scale}
            setScale={setScale}
            setPageNumber={setPageNumber}
            setDragStart={setDragStart}
            setDragOffset={setDragOffset}
            setRotation={setRotation}
            pageNumber={pageNumber}
            numPages={numPages}
            pageActions={[addPdfPageIcon, splitPdfPageIcon, deletePdfPageIcon]}
          />
        )}
        {isSelectedAssetPdf && (
          <div className={styles.pdfDocument} ref={boxRef}>
            {fileToRenderMemo && isSelectedAssetPdf && (
              <PdfPreviewViewer
                file={fileToRenderMemo}
                numPages={numPages}
                pageNumber={pageNumber}
                setPageNumber={setPageNumber}
                isSubmitting={isOperationRunning || isFetchingInvoiceDetails}
                setDragStart={setDragStart}
                dragStart={dragStart}
                checkboxValues={checkboxValues}
                setCheckboxValues={setCheckboxValues}
                thumbnailVisible={showThumbnails}
                rotation={rotation}
                scale={scale}
                setDragOffset={setDragOffset}
                dragOffset={dragOffset}
                setNumPages={setNumPages}
                width={boxWidth}
              />
            )}
          </div>
        )}
        {!isSelectedAssetPdf && fileToRenderMemo && (
          <div className={styles.otherDocument}>
            <DocumentViewer file={fileToRenderMemo} />
          </div>
        )}
      </CollapsibleViewerCard>
    </>
  );
};

export default memo(FileUploadSection);
