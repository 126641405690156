import { Receipt, ReceiptsContext } from "components/admin/expensese/expenseItem/receiptListContext";
import useConfirmModal from "components/modals/confirmModal/useConfirmModalHook";
import CustomModal from "components/modals/customModal";
import _ from "lodash";
import React, { useContext, useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import ReceiptsApis from "services/admin/expenses/receipts/receiptsApis";
import { CreateNotification, NotificationType } from "services/general/notifications";
import defaultAttachment from "../../../assets/default_attachment.png";
import { IAttachment } from "../../../services/common/types/common.type";
import FileUploader from "../fileUploader/fileUploader";
import useIsMounted from "../hooks/useIsMounted";
import style from "./attachmentCarousel.module.css";
import AttachmentCarouselFileUploader from "./attachmentCarouselFileUploader";
import AttachmentCarouselPreviewComponent, { getFileType } from "./attachmentCarouselPreviewComponent";

export type attachmentCarouselPropsTypes = {
  attachments: IAttachment[];
  onCloseAttachmentCallback?: () => void;
  onAttachmentChangeCallback?: (selectedAttachment: IAttachment) => void;
  onDeleteAttachmentCallback?: (selectedAttachment: IAttachment) => void;
  onUploadRequestedCallback?: (files: File[]) => void;
  useModal?: boolean;
  previewAccessor?: boolean;
};

const AttachmentCarousel = ({
  attachments,
  onAttachmentChangeCallback,
  onCloseAttachmentCallback,
  onDeleteAttachmentCallback,
  onUploadRequestedCallback,
  previewAccessor,
}: attachmentCarouselPropsTypes) => {
  interface FailedImagesState {
    [key: number]: boolean;
  }

  const { t } = useTranslation();
  const t2 = (key: string, obj?: any) => t(`admin.pages.expenseItemDetails.${key}`, obj);

  // here will change only selectAttachmentIndex other changes will reflect automatically
  const [storedAttachments, setStoredAttachments] = useState<IAttachment[]>([]);
  const validAttachments = storedAttachments.filter((attachment) => attachment._destroy !== 1);
  const haveAttachments = validAttachments.length > 0 ? true : false;
  const [selectAttachmentIndex, setSelectAttachmentIndex] = useState<number>(0);
  const selectedAttachment = validAttachments[selectAttachmentIndex] || undefined;
  const [currentAttachmentFileName, setCurrentAttachmentFileName] = useState<string>("");

  // Access the context directly: ReceiptContext will not exist in cases where an
  // attachment carousel is used outside of a <ReceiptsProvider> component.
  const receiptsContext = useContext(ReceiptsContext);
  const receipts = React.useMemo(() => receiptsContext?.receipts || [], [receiptsContext]);
  const removeReceipt = receiptsContext?.removeReceipt || (() => {});

  const isMounted = useIsMounted();
  const { createConfirmModal } = useConfirmModal();

  const assetUrl =
    _.isPlainObject(selectedAttachment) &&
    (selectedAttachment.url ? selectedAttachment.url : selectedAttachment.asset_expiring_url);
  const assetName =
    _.isPlainObject(selectedAttachment) &&
    (selectedAttachment.filename ? selectedAttachment.filename : selectedAttachment.asset_file_file_name);

  const isPrevDisabled = selectAttachmentIndex === 0;
  const isNextDisable = _.isArray(validAttachments) && selectAttachmentIndex === validAttachments.length - 1;
  const [showModal, setShowModal] = useState<boolean>(false);
  const [failedToLoadImageIndex, setFailedToLoadImageIndex] = useState<FailedImagesState>({});

  const assetUrlAtIndex = (index: number) => {
    if (index < 0 || index >= validAttachments.length) {
      return "";
    }
    const attachment = validAttachments[index];
    return attachment.url ? attachment.url : attachment.asset_expiring_url;
  };

  const onClickAttachment = (index: number) => {
    setSelectAttachmentIndex((prev) => index);
  };

  const onClickNext = () => {
    const thumbnail = document.getElementById(`thumbnail_image_${selectAttachmentIndex + 1}`);
    if (thumbnail) {
      thumbnail.scrollIntoView();
    }
    setSelectAttachmentIndex((prev) => prev + 1);
  };

  const onClickPrev = () => {
    const thumbnail = document.getElementById(`thumbnail_image_${selectAttachmentIndex - 1}`);
    if (thumbnail) {
      thumbnail.scrollIntoView();
    }
    setSelectAttachmentIndex((prev) => prev - 1);
  };

  const addAttachmentsAndReceiptsToCarousel = React.useCallback(() => {
    const receiptAttachments = receipts
      .filter((receipt: Receipt) => receipt.selected)
      .map((receipt: Receipt) => ({
        id: receipt.id,
        is_receipt: true,
        asset_expiring_url: receipt.image.imageUrl,
        asset_expiring_thumbnail_url: receipt.image.imageUrl,
        asset_file_file_name: receipt.image.fileName,
      }));

    const newAttachments = [...attachments, ...receiptAttachments];

    // Avoid unnecessary state updates
    if (!_.isEqual(newAttachments, storedAttachments)) {
      setStoredAttachments(newAttachments);
    }
  }, [attachments, receipts, storedAttachments]);

  useEffect(() => {
    const attachmentFileName = (selectedAttachment?.filename ?? selectedAttachment?.asset_file_file_name) || "";
    setCurrentAttachmentFileName(attachmentFileName);

    if (onAttachmentChangeCallback && selectedAttachment) {
      onAttachmentChangeCallback(selectedAttachment);
    }
  }, [selectedAttachment]);

  useEffect(() => {
    if (isMounted.current) {
      addAttachmentsAndReceiptsToCarousel();
    }
  }, []);

  useEffect(() => {
    addAttachmentsAndReceiptsToCarousel();
  }, [addAttachmentsAndReceiptsToCarousel]);

  const toggleExpandAttachmentModal = () => {
    setShowModal((prevIsVisible) => !prevIsVisible);
  };

  const hideModal = () => {
    setShowModal(false);
  };

  const iconAttachment = (index: number, name: string) => {
    return (
      <Col className={style.attachmentContainer} onClick={() => onClickAttachment(index)}>
        <Row className="justify-content-center align-items-center">
          <img alt={name} className={style.attachmentIcon} src={defaultAttachment}></img>
        </Row>
        <Row className="justify-content-center align-items-center" style={{ padding: "0 4px" }}>
          {t(`serialAttachments.attachmentType.${name}`)}
        </Row>
      </Col>
    );
  };

  const handleError = (index: number) => {
    setFailedToLoadImageIndex((prev) => ({
      ...prev,
      [index]: true,
    }));
  };

  const thumbnailScroller = () => (
    <div className={style.carouselContainer}>
      <table>
        <tbody>
          <tr>
            {validAttachments.map((attachment: IAttachment, index: number) => (
              <td
                className={style.thumbnailCell}
                key={attachment.id ?? index}
                title={attachment.filename ?? attachment.asset_file_file_name}
              >
                {getFileType(attachment.filename || attachment.asset_file_file_name || "") === "image" &&
                  !failedToLoadImageIndex[index] && (
                    <img
                      alt={attachment.filename ?? attachment.asset_file_file_name}
                      src={assetUrlAtIndex(index)}
                      className={style.carouselImage}
                      onError={() => handleError(index)}
                      onClick={() => onClickAttachment(index)}
                    ></img>
                  )}
                {failedToLoadImageIndex[index] && (
                  <div className={style.thumbnailLoadError} onClick={() => onClickAttachment(index)}>
                    {t("serialAttachments.notFound")}
                  </div>
                )}
                {getFileType(attachment.filename || attachment.asset_file_file_name || "").match(
                  /\b(pdf|word|excel|unsupported)\b/,
                ) && (
                  <div style={{ whiteSpace: "nowrap" }}>
                    {iconAttachment(index, getFileType(attachment.filename || attachment.asset_file_file_name || ""))}
                  </div>
                )}
              </td>
            ))}
          </tr>
        </tbody>
      </table>
    </div>
  );

  const uploadFiles = (files: File[]) => {
    if (onUploadRequestedCallback) {
      onUploadRequestedCallback(files);
      setSelectAttachmentIndex(validAttachments.length);
    }
  };

  const uploadButton = () => {
    return onUploadRequestedCallback ? (
      <Row className={`${style.fileUploaderContainer} mb-4 mt-2`} title={t("components.common.attachmentSections.add")}>
        <FileUploader
          customButton={
            <div className={style.sidebarIcon}>
              <div className="icon upload-receipt-icon-light-blue" style={{ marginLeft: "1px" }}></div>
            </div>
          }
          showUploadBtn
          uploadAttachments={(files: File[]) => uploadFiles(files)}
          multiple={false}
        />
      </Row>
    ) : (
      <></>
    );
  };

  const onReceiptDeleteConfirm = async (attachment: IAttachment) => {
    if (attachment.id) {
      try {
        await ReceiptsApis.deleteReceipt(attachment.id);
        CreateNotification(t("success"), t("admin.pages.receipts.successfulDelete"), NotificationType.success);
        removeReceipt(attachment.id.toString());
      } catch (error) {
        throw error;
      }
    }
  };

  const deleteFile = (selectedAttachment: IAttachment) => {
    if (selectedAttachment?.is_receipt) {
      createConfirmModal({
        title: t("admin.pages.expenseItemDetails.notification.deleteConfirmationDialog.title"),
        body: t("admin.pages.expenseItemDetails.notification.deleteConfirmationDialog.body"),
        confirmButtonLabel: t("admin.pages.expenseItemDetails.notification.deleteConfirmationDialog.deleteButton"),
        callBackData: null,
        cancelCallBack: null,
        saveCallBack: () => onReceiptDeleteConfirm(selectedAttachment),
      });
    } else if (onDeleteAttachmentCallback) {
      onDeleteAttachmentCallback(selectedAttachment);
    }
  };

  return (
    <Container className="h-100">
      {!haveAttachments && (
        <Row className="h-100">
          <div className="d-flex align-items-center justify-content-center w-100">
            <AttachmentCarouselFileUploader
              uploadAttachments={(files: File[]) => uploadFiles(files)}
            ></AttachmentCarouselFileUploader>
          </div>
        </Row>
      )}
      {haveAttachments && (
        <Col>
          <Row>
            <Col className="my-1">
              <button
                disabled={isPrevDisabled}
                type="button"
                className={`btn primaryLinkColor ${isPrevDisabled ? "not-allowed" : ""}`}
                onClick={() => onClickPrev()}
              >
                <i className="icon m-0 icon-arrow-left-blue" />
                <div style={{ paddingTop: "3px" }}>{t("serialAttachments.previous")}</div>
              </button>
            </Col>
            <Col className="my-1">
              <button
                disabled={isNextDisable}
                type="button"
                className={`btn bg-transparent float-right primaryLinkColor ${isNextDisable ? "not-allowed" : ""}`}
                onClick={() => onClickNext()}
              >
                <i className="icon m-0 icon-arrow-right-blue float-right " />
                <div style={{ paddingTop: "1px", float: "right" }}>{t("serialAttachments.next")}</div>
              </button>
            </Col>
          </Row>
          <Row>
            <Col sm="1">
              <Row>
                <Col>{uploadButton()}</Col>
              </Row>
              <Row title={t("components.common.attachmentSections.zoom")} className="mb-3">
                <div
                  className={style.sidebarIcon}
                  onClick={toggleExpandAttachmentModal}
                  title={t("components.common.attachmentSections.zoom")}
                >
                  <div className="icon icon-zoom"></div>
                </div>
              </Row>
              {onDeleteAttachmentCallback && (
                <Row>
                  <div
                    className={style.sidebarIcon}
                    onClick={() => deleteFile(selectedAttachment)}
                    title={t("components.common.attachmentSections.remove")}
                  >
                    <div className="icon delete"></div>
                  </div>
                </Row>
              )}
            </Col>
            <Col sm="11">
              <Row style={{ flex: "1" }}>
                {assetName && (
                  <AttachmentCarouselPreviewComponent
                    fileName={assetName}
                    filePath={assetUrl || ""}
                    renderFullSize={false}
                    displayLoadingError={failedToLoadImageIndex[selectAttachmentIndex]}
                  />
                )}
              </Row>
              <Row className={style.attachmentCounterContainer}>
                {selectAttachmentIndex + 1} {t("serialAttachments.of")} {validAttachments.length}{" "}
                {t("serialAttachments.attachments")}
              </Row>
              {selectedAttachment && (
                <Row
                  className={style.attachmentCounterContainer}
                  title={`${t("serialAttachments.current")} ${currentAttachmentFileName}`}
                >
                  {t("serialAttachments.current")} {currentAttachmentFileName}
                </Row>
              )}
            </Col>
          </Row>
          <Row>{thumbnailScroller()}</Row>
        </Col>
      )}

      <CustomModal
        show={showModal}
        header={t("preview")}
        onHide={toggleExpandAttachmentModal}
        modalClass={"modal-dialog-full-size"}
        modalBodyClass={style.modalBody}
        body={
          <Container fluid style={{ height: "100%" }}>
            <Row style={{ height: "100%" }}>
              <Col className="d-flex flex-column" style={{ height: "100%" }}>
                <Row className="flex-grow-1" style={{ height: "100%" }}>
                  <AttachmentCarouselPreviewComponent
                    fileName={assetName || ""}
                    filePath={assetUrl || ""}
                    renderFullSize={true}
                    displayLoadingError={failedToLoadImageIndex[selectAttachmentIndex]}
                  ></AttachmentCarouselPreviewComponent>
                </Row>
                <Row
                  className={style.attachmentCounterContainer}
                  title={`${t("serialAttachments.current")} ${currentAttachmentFileName}`}
                >
                  {t("serialAttachments.current")} {currentAttachmentFileName}
                </Row>
                <Row>{thumbnailScroller()}</Row>
              </Col>
            </Row>
          </Container>
        }
        footer={
          <Row className="justify-content-end">
            <button className="btn btn-secondary mr-2" onClick={hideModal}>
              {t("cancel")}
            </button>
          </Row>
        }
      ></CustomModal>
    </Container>
  );
};

export default AttachmentCarousel;
