import AccountPicker from "components/admin/pickers/reduxFormPickers/accountPicker";
import i18n from "i18next";
import _ from "lodash";
import { change } from "redux-form";
import { CreateNotification, NotificationType } from "services/general/notifications";
import DepartmentPicker from "../../../../../../components/admin/pickers/reduxFormPickers/departmentPicker";
import ProductItemPickerNew from "../../../../../../components/admin/pickers/reduxFormPickers/productItemPickeer";
import { RenderCheckBox, RenderSelect } from "../../../../../../components/forms/bootstrapFields";
import { renderDatePicker as RenderDatePicker } from "../../../../../../components/forms/fields";
import ProductItemPicker from "../../../../../../components/pickers/reduxFormPicker/productItemPicker";
import { required } from "../../../../../../services/validations/reduxFormValidation";
import styles from "../../companySettingTab.module.css";
import { CompanyDefaultsTypes } from "../../settingsTypes";

export const InvoiceSettingsHeaderLevel: CompanyDefaultsTypes.CommonSettingFieldType[] = [
  /* ------------------------------ col 1---------------------------------------- */
  {
    name: "company_default.invoice.hide_business_unit",
    tooltip: {
      show: true,
      title: "Business Unit field at the header level will be hidden from invoices Invoice department is required",
      placement: "top",
    },
    label: "Hide header level business unit",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 1,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.invoice.is_required_location",
    tooltip: {
      show: true,
      title: "Header level location will be required field at the Invoice Form",
      placement: "top",
    },
    label: "Location Required",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 1,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.invoice.is_attachment_required",
    tooltip: {
      show: true,
      title: "Attachments will be required on invoice forms",
      placement: "top",
    },
    label: "Attachments required",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 1,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.invoice_workflow_name_required",
    tooltip: {
      show: true,
      title: "Workflow Name is required",
      placement: "top",
    },
    label: " Workflow Name Required",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 1,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.payment_run_required",
    tooltip: {
      show: true,
      title: "Payments will be auto-approved once the payment run is approved",
      placement: "top",
    },
    label: "Payment Run",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 1,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.pay_on_past_due",
    tooltip: {
      show: true,
      title: "Invoice is available to pay after due date",
      placement: "top",
    },
    label: "Pay After Due Date",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 1,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.invoice.hide_categories",
    tooltip: {
      show: true,
      title: "Hide categories from every Invoices forms",
      placement: "top",
    },
    label: " Hide Categories",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 1,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.invoice_requestor_required",
    tooltip: {
      show: true,
      title: "Company requestor will be added as a required field in the invoice form",
      placement: "top",
    },
    label: "Company Requestor Required",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 1,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.invoice.show_business_unit_by_subsidiary",
    tooltip: {
      show: true,
      title: "Business unit load as per subsidiary selected on header level and line level",
      placement: "top",
    },
    label: "Show business unit by subsidiary",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 1,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.invoice_hide_multiple_vendor_checkbox",
    tooltip: {
      show: true,
      title: "Multiple vendor/expense corp card will be hidden from invoices inbox section",
      placement: "top",
    },
    label: "Hide Multiple vendor/expense corp card",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 1,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.invoice_default_status",
    tooltip: {
      show: false,
    },
    label: "Default Invoice Status:",
    type: "drop-down",
    component: RenderSelect,
    options: [
      { label: "Select", value: "" },
      {
        label: "NEW",
        value: "NEW",
      },
      {
        label: "PENDING",
        value: "PENDING",
      },
      {
        label: "OPEN",
        value: "OPEN",
      },
    ],
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 1,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.invoice_stop_auto_number_from_frontend",
    tooltip: {
      show: true,
      title: "Invoice number will be blank while generating invoice",
      placement: "top",
    },
    label: "Do not show invoice number ",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 1,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.invoice.draft.stop_to_inherit_subsidiary_from_vendor",
    tooltip: {
      show: true,
      title: "Vendor's subsidiary will not inherit on invoice inbox and draft section, once selected.",
      placement: "top",
    },
    label: "Do not inherit vendor subsidiary",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 1,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.pay_with_po_associated",
    tooltip: {
      show: true,
      title: "Invoice is available to pay only if it has PO associated",
      placement: "top",
    },
    label: "Pay with PO Associated ",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 1,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.pay_with_sufficient_po_balance",
    tooltip: {
      show: true,
      title: "Invoice is available to pay only if it has sufficirent PO balance",
      placement: "top",
    },
    label: "Pay with Sufficient PO Balance",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 1,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.pay_with_subsidiary_associated",
    tooltip: {
      show: true,
      title: "Invoice is available to pay only if it has subsidiary associated",
      placement: "top",
    },
    label: "Pay with Subsidiary Associated ",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 1,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.pay_with_receipt_associated",
    tooltip: {
      show: true,
      title: "Invoice is available to pay only if company has three way matching setting and PO has receipt associated",
      placement: "top",
    },
    label: "Pay with Receipt Associated",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 1,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.invoice_show_vendor_id",
    tooltip: {
      show: true,
      title: "Allow invoice to show vendor ID on invoice inbox section",
      placement: "top",
    },
    label: "Show vendor ID on inbox section",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 1,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.invoice_use_tax_subtotal_hide_location",
    tooltip: {
      show: true,
      title:
        "Location will be hidden from use tax on subtotal section of invoices and will be inherit from first item line",
      placement: "top",
    },
    label: "Hide location from use tax subtotal",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 1,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.invoice_use_tax_subtotal_hide_project",
    tooltip: {
      show: true,
      title:
        "Project will be hidden from use tax on subtotal section of invoices and will be inherit from first item line",
      placement: "top",
    },
    label: "Hide project from use tax subtotal",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 1,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.invoice_use_tax_subtotal_hide_department",
    tooltip: {
      show: true,
      title:
        "Department will be hidden from use tax on subtotal section of invoices and will be inherited from first item line",
      placement: "top",
    },
    label: "Hide department use tax subtotal ",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 1,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.invoice.hide_vendor_address",
    tooltip: {
      show: true,
      title: "Vendor address will be hidden from invoice form",
      placement: "top",
    },
    label: "Hide vendor address",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 1,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.invoice.is_required_description",
    tooltip: {
      show: true,
      title: "Header level description will be mandatory field at the Invoice Form once selected.",
      placement: "top",
    },
    label: "Make description as mandatory",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 1,
    validation: [],
    require: false,
    class: "",
  },
  {
    name: "company_default.invoice.add_audit_log_to_invoice_pdf",
    tooltip: {
      show: true,
      title: "Audit log details will be added at the end of approved invoice PDFs.",
      placement: "top",
    },
    label: "Add Audit Log to Invoice PDF",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 1,
    validation: [],
    require: undefined,
    class: "",
  },
  /* ------------------- col 1 ends  ---------------------------------------- */
  /* ------------------- col 2 start -----------------------------------------*/
  {
    name: "company_default.invoice.vp_default_invoice_status",
    tooltip: {
      show: false,
    },
    label: "Default Invoice status to Vendor Portal",
    type: "drop-down",
    component: RenderSelect,
    options: [
      { label: "Select", value: "" },
      {
        label: "NEW",
        value: "NEW",
      },
      {
        label: "DRAFT",
        value: "DRAFT",
      },
    ],
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 2,
    validation: [],
    require: undefined,
    class: "",
  },

  {
    name: "company_default.invoice_hide_discount",
    tooltip: {
      show: true,
      title: "Discount amount and Discount expire date will be hidden from invoice form",
      placement: "top",
    },
    label: "Hide discount details",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 2,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.invoice_hide_service_start_date",
    tooltip: {
      show: true,
      title: "Service start date will be hidden from invoice form",
      placement: "top",
    },
    label: "Hide service start date",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 2,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.invoice_hide_service_end_date",
    tooltip: {
      show: true,
      title: "Service end date will be hidden from invoice form",
      placement: "top",
    },
    label: "Hide service end date",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 2,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.invoice_hide_location",
    tooltip: {
      show: true,
      title: "Location field will be hidden from invoice form",
      placement: "top",
    },
    label: "Hide header level location",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {
      const { dispatch, formName } = onChangeObj;
      dispatch(change(formName, "company_default.invoice.items.inherit_header_location", false));
      dispatch(change(formName, "company_default.invoice.expenses.inherit_header_location", false));
    },
    col: 2,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.invoice_hide_company_requestor",
    tooltip: {
      show: true,
      title: "Requestor field will be hidden from invoices",
      placement: "top",
    },
    label: "Hide requestor field ",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 2,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.invoice_hide_force_sync_with_erp",
    tooltip: {
      show: true,
      title: "Force Sync with ERP flag will be hidden from invoices inbox section",
      placement: "top",
    },
    label: "Hide Force Sync with ERP flag",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 2,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.invoice_hide_kept_on_hold",
    tooltip: {
      show: true,
      title: "Kept on hold flag will be hidden from invoices inbox section",
      placement: "top",
    },
    label: "Hide Kept on hold flag",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 2,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.invoice_hide_flag_not_to_push",
    tooltip: {
      show: true,
      title: "Flag not to push will be hidden from invoices inbox section",
      placement: "top",
    },
    label: "Hide Flag not to push",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 2,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.invoice_hide_reference_number",
    tooltip: {
      show: true,
      title: "Reference number field will be hidden from invoices inbox section",
      placement: "top",
    },
    label: "Hide reference number field",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 2,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.invoice.is_required_reference_number",
    tooltip: {
      show: true,
      title: "Reference number will be required field at invoice form",
      placement: "top",
    },
    label: "Reference number required ",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 2,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.invoice.pay_invoices.hide_submit_all_data",
    tooltip: {
      show: true,
      title: "Submit all data button will be hidden from Pay Invoices",
      placement: "top",
    },
    label: "Hide submit all data button",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 2,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.invoice_service_start_date_required",
    tooltip: {
      show: true,
      title: "Service start date will be required fields at header level for admin portal and vendor portal",
      placement: "top",
    },
    label: "Make service start date required at header level",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 2,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.invoice_service_end_date_required",
    tooltip: {
      show: true,
      title: "Service end date will be required fields at header level for admin portal and vendor portal",
      placement: "top",
    },
    label: "Make service end date required at header level",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 2,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.allow_update_debit_credit_entry_in_paid_invoice",
    tooltip: {
      show: true,
      title:
        "Allow to update Debit and Credit Account details in PAID status but Invoice amount should not be changed.",
      placement: "top",
    },
    label: "Allow to update Debit and Credit Account detail in PAID status",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 2,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.invoice.show_eligible_1099_on_lines",
    tooltip: {
      show: true,
      title: "1099 checkbox will be visible on line level.",
      placement: "top",
    },
    label: "Show 1099 on line level",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 2,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.invoice.is_tax_code_required_on_line_level",
    tooltip: {
      show: true,
      title: "If enable, the tax code field will become a mandatory requirement for invoice line level.",
      placement: "top",
    },
    label: "Tax Code Required on Line-level",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 2,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.invoice.hide_paid_outside_btn_from_detail_page",
    tooltip: {
      show: true,
      title: "Enabling this option will hide the 'Paid Outside' button on the invoice detail page.",
      placement: "top",
    },
    label: "Hide Paid Outside Button",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 2,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.invoice.allow_multi_currency_po",
    tooltip: {
      show: true,
      title: "Enabling this option will allow to link multi currency po's of the vendor.",
      placement: "top",
    },
    label: "Allow multi currency po's",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 2,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.invoice.do_not_filter_po_links_by_subsidiary",
    tooltip: {
      show: true,
      title: i18n.t("companySettings.invoiceSettings.doNotFilterPOLinksBySubsidiaryTooltip"),
      placement: "top",
    },
    label: i18n.t("companySettings.invoiceSettings.doNotFilterPOLinksBySubsidiary"),
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 2,
    validation: [],
    require: undefined,
    class: "",
  },
  /* ------------------- col 2 ends -----------------------------------------*/
  /*-------------------- col 3 ends -----------------------------------------*/
  {
    name: "company_default.enable_used_tax",
    tooltip: {
      show: true,
      title: "Allow Used Tax, It will applicable for Invoice item line level as well as subtotal level.",
      placement: "top",
    },
    label: "Allow Use Tax",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 3,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.used_tax_credit_account_id",
    tooltip: {
      show: false,
    },
    label: "Use Tax Credit Account:",
    type: "react-select",
    component: AccountPicker,
    isShow: (isShowObj) => {
      const { company_default } = isShowObj;
      return company_default.enable_used_tax;
    },
    onChangeCallBack: (onChangeObj, e) => {},
    col: 3,
    validation: [required],
    require: true,
    class: "",
    isClearable: true,
  },
  {
    name: "company.allow_invoice_items",
    tooltip: {
      show: true,
      title: "if enabled, it will allow to add line items ",
      placement: "top",
    },
    label: "Allow Invoice Line Items",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      const { company_default } = isShowObj;
      return !company_default.invoice?.auto_pull_po_items_to_expense_lines;
    },
    onChangeCallBack: (onChangeObj, e) => {
      const { dispatch, formName } = onChangeObj;
      dispatch(change(formName, "company_default.invoice.auto_pull_po_items_to_expense_lines", false));
      dispatch(change(formName, "company_default.invoice.hide_debit_account", false));
      dispatch(change(formName, "company_default.invoice.default_product_item_id", null));
    },
    col: 3,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.invoice.hide_debit_account",
    tooltip: {
      show: true,
      title: "If enable, it will hide Invoice Debit Accounts from all the section",
      placement: "top",
    },
    label: "Hide Debit Account",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      const { company } = isShowObj;
      return company.allow_invoice_items;
    },
    onChangeCallBack: (onChangeObj) => {
      const { dispatch, formName } = onChangeObj;
      dispatch(change(formName, "company_default.invoice.default_product_item_id", null));
    },
    col: 3,
    validation: [],
    require: false,
    class: "",
  },
  {
    name: "company_default.invoice.default_product_item_id",
    tooltip: {
      show: true,
      title: "Default Item will be used while creating Invoice Item by OCR",
      placement: "top",
    },
    label: "Default Invoice Item",
    type: "react-select",
    component: ProductItemPickerNew,
    isShow: (isShowObj) => {
      const { company_default } = isShowObj;
      return company_default.invoice?.hide_debit_account;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 3,
    customProps: { hideCustomStyles: true },
    validation: [required],
    require: true,
    class: "",
  },
  {
    name: "company_default.invoice.inherit_only_received_lines",
    tooltip: {
      show: true,
      title: "Invoice will inherit only received (but not billed) line items and expenses, once selected",
      placement: "top",
    },
    label: "Inherit only received PO lines",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      const { company_default } = isShowObj;
      return company_default.three_way_matching;
    },
    onChangeCallBack: (onChangeObj, e) => {},
    col: 3,
    validation: [],
    require: false,
    class: "",
  },
  {
    name: "company_default.invoice_hide_department",
    tooltip: {
      show: true,
      title: "Department field at the header level will be hidden from invoices ",
      placement: "top",
    },
    label: "Hide header level department ",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj, e) => {
      const { dispatch, formName } = onChangeObj;
      dispatch(change(formName, "company_default.invoice_department_required", false));
      dispatch(change(formName, "company_default.invoice.inherit_department_from_requestor", false));
      dispatch(change(formName, "company_default.invoice.items.inherit_header_department", false));
      dispatch(change(formName, "company_default.invoice.expenses.inherit_header_department", false));
    },
    col: 3,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.invoice_department_required",
    tooltip: {
      show: true,
      title: "Invoice department is required",
      placement: "top",
    },
    label: "Department Required ",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      const { company_default } = isShowObj;
      return !company_default.invoice_hide_department;
    },
    onChangeCallBack: (onChangeObj, e) => {},
    col: 3,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.invoice.inherit_department_from_requestor",
    tooltip: {
      show: true,
      title: "Department will be inherited from requestor ",
      placement: "top",
    },
    label: "Inherit department from requestor",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      const { company_default } = isShowObj;
      return !company_default.invoice_hide_department;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 3,
    validation: [],
    require: false,
    class: "",
  },
  {
    name: "company_default.invoice_show_prior_period",
    tooltip: {
      show: true,
      title: "To show Prior Period date field on invoice header level.",
      placement: "top",
    },
    label: "Show Prior Period",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj, e) => {
      const { dispatch, formName } = onChangeObj;
      dispatch(change(formName, "company_default.invoice.set_prior_period_range", null));
      dispatch(change(formName, "company_default.draft_invoice.hide_prior_period", false)); // <- reset hide prior period setting from draft invoice
    },
    col: 3,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.invoice.is_required_prior_period",
    tooltip: {
      show: true,
      title: "Prior Period date will be required field at invoice form.",
      placement: "top",
    },
    label: "Prior Period Required",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      const { company_default } = isShowObj;
      return company_default.invoice_show_prior_period;
    },
    onChangeCallBack: (onChangeObj, e) => {},
    col: 3,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.invoice.set_prior_period_range",
    tooltip: {
      show: true,
      title:
        "Please ensure that dates selected for the prior period falls within the range specified here. Any dates outside of this range will be disabled and unable to be used on invoices.",
      placement: "top",
    },
    label: "Establish the date range for the prior period",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      const { company_default } = isShowObj;
      return company_default.invoice_show_prior_period;
    },
    onChangeCallBack: (onChangeObj, e) => {
      const { dispatch, formName } = onChangeObj;
      dispatch(change(formName, "company_default.invoice.prior_period_range_start_date", null));
      dispatch(change(formName, "company_default.invoice.prior_period_range_end_date", null));
    },
    col: 3,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.invoice.prior_period_range_start_date",
    tooltip: {
      show: false,
    },
    type: "checkbox",
    label: "Start Date",
    component: RenderDatePicker,
    isShow: (isShowObj) => {
      const { company_default } = isShowObj;
      return (
        company_default.invoice_show_prior_period &&
        _.isPlainObject(company_default.invoice) &&
        company_default.invoice.set_prior_period_range
      );
    },
    onChangeCallBack: (onChangeObj, e) => {},
    col: 3,
    validation: [],
    require: undefined,
    class: `${styles.priorPeriodStartDate}`,
  },
  {
    name: "company_default.invoice.prior_period_range_end_date",
    tooltip: {
      show: false,
    },
    type: "checkbox",
    label: "End Date",
    component: RenderDatePicker,
    isShow: (isShowObj) => {
      const { company_default } = isShowObj;
      return (
        company_default.invoice_show_prior_period &&
        _.isPlainObject(company_default.invoice) &&
        company_default.invoice.set_prior_period_range
      );
    },
    onChangeCallBack: (onChangeObj, e) => {},
    col: 3,
    validation: [],
    require: undefined,
    class: `${styles.priorPeriodEndDate}`,
  },
  {
    name: "company_default.invoice.show_posted_date",
    tooltip: {
      show: true,
      title: "Posted date will be show as an required field at the Invoice Form",
      placement: "top",
    },
    label: "Show Posted Date and make it a required field",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 3,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.invoice.posted_date_default",
    tooltip: {
      show: false,
    },
    label: "Posted Date Default",
    type: "drop-down",
    component: RenderSelect,
    options: [
      { label: "None", value: "" },
      {
        label: "Invoice Due Date",
        value: "DUE_DATE",
      },
      {
        label: "Invoice Date",
        value: "INVOICE_DATE",
      },
    ],
    isShow: (isShowObj) => {
      return isShowObj?.company_default?.invoice?.show_posted_date;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 3,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.invoice.show_customer_account_number",
    tooltip: {
      show: true,
      title: "Customer Account Number will be show at the Invoice Form",
      placement: "top",
    },
    label: "Show Customer Account Number",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 3,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.invoice_hide_credit_account",
    tooltip: {
      show: true,
      title:
        "Hide Credit account and it will inherit credit account from vendor or subsidiary or company setting and other data will be inherit from first item line or account line.",
      placement: "top",
    },
    label: "Hide credit account ",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj, e) => {
      const { formName, dispatch } = onChangeObj;
      dispatch(change(formName, "company_default.inherit_default_credit_detail_to_invoice_credit_account", false));
      dispatch(change(formName, "company_default.invoice.inherit_credit_line_details_from_header", false));
      dispatch(change(formName, "company_default.invoice.inherit_credit_account_details_with_priority", false));
    },
    col: 3,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.inherit_default_credit_detail_to_invoice_credit_account",
    tooltip: {
      show: true,
      title: "Inherit default credit account details from company to invoice credit account.",
      placement: "top",
    },
    label: "Inherit company level credit account details ",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      const { company_default } = isShowObj;
      return (
        company_default.invoice_hide_credit_account &&
        !company_default?.invoice?.inherit_credit_line_details_from_header &&
        !company_default?.invoice?.inherit_credit_account_details_with_priority
      );
    },
    onChangeCallBack: (onChangeObj, e) => {},
    col: 3,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.invoice.inherit_credit_account_details_with_priority",
    tooltip: {
      show: true,
      title: "Inherit default credit account details from vendor or subsidiary or company to invoice credit account.",
      placement: "top",
    },
    label: "Inherit Credit Account details with priority",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      const { company_default } = isShowObj;
      return (
        company_default?.invoice_hide_credit_account &&
        !company_default?.invoice?.inherit_credit_line_details_from_header &&
        !company_default?.inherit_default_credit_detail_to_invoice_credit_account
      );
    },
    onChangeCallBack: (onChangeObj, e) => {},
    col: 3,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.invoice.inherit_credit_line_details_from_header",
    tooltip: {
      show: true,
      title: "Inherit credit account details from header",
      placement: "top",
    },
    label: "Inherit Credit Account fields from header",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      const { company_default } = isShowObj;
      return (
        company_default.invoice_hide_credit_account &&
        !company_default?.inherit_default_credit_detail_to_invoice_credit_account &&
        !company_default?.invoice?.inherit_credit_account_details_with_priority
      );
    },
    onChangeCallBack: (onChangeObj, e) => {},
    col: 3,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.invoice_posting_period_required",
    tooltip: {
      show: true,
      title: "Posting period will be required field at invoice form",
      placement: "top",
    },
    label: "Posting period required",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      const { current_user } = isShowObj;
      return current_user.company.has_posting_period;
    },
    onChangeCallBack: (onChangeObj, e) => {},
    col: 3,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.invoice.readonly_posting_period",
    tooltip: {
      show: true,
      title: "Posting period will be readonly field at invoice form",
      placement: "top",
    },
    label: "Posting period readonly",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      const { current_user } = isShowObj;
      return current_user.company.has_posting_period;
    },
    onChangeCallBack: (onChangeObj, e) => {},
    col: 3,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.invoice_auto_submit_from_po",
    tooltip: {
      show: true,
      title: "Allow invoice to auto submit from PO list page and PO detail page",
      placement: "top",
    },
    label: "Auto submit invoice from PO",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj, e) => {
      const { company_default, company, dispatch, formName } = onChangeObj;
      if (
        company_default.invoice_auto_submit_from_po &&
        (!_.isArray(company.invoice_credit_accounts) ||
          !_.isObject(company.invoice_credit_accounts[0]) ||
          !(company.invoice_credit_accounts[0] as any).account_id)
      ) {
        dispatch(change(formName, "company_default.invoice_auto_submit_from_po", false));
        CreateNotification(
          "Error",
          "Please add Invoice credit account in company setting using 'Edit Accounts' tab.",
          NotificationType.danger,
        );
      }
    },
    col: 3,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.po_auto_pull_partial",
    tooltip: {
      show: true,
      title: "Invoice will partial pull (50%) the PO items from OCR.",
      placement: "top",
    },
    label: "Partial Pull PO Item (AI-Automation) ",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      const { company_default } = isShowObj;
      return !company_default.invoice?.auto_pull_po_items_to_expense_lines;
    },
    onChangeCallBack: (onChangeObj, e) => {
      const { company_default, company, dispatch, formName } = onChangeObj;
      if (
        company_default.invoice_auto_submit_from_po &&
        (!_.isArray(company.invoice_credit_accounts) ||
          !_.isObject(company.invoice_credit_accounts[0]) ||
          !(company.invoice_credit_accounts[0] as any).account_id)
      ) {
        dispatch(change(formName, "company_default.invoice_auto_submit_from_po", false));
        CreateNotification(
          "Error",
          "Please add Invoice credit account in company setting using 'Edit Accounts' tab.",
          NotificationType.danger,
        );
      }
    },
    col: 3,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.enable_default_department_for_invoice_requestor",
    tooltip: {
      show: true,
      title: "Set default department for requested invoices by the invoice requestor",
      placement: "top",
    },
    label: "Set Default Department for Invoice Requestor",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj, e) => {},
    col: 3,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.default_department_for_invoice_requestor",
    tooltip: {
      show: false,
    },
    label: "Default Invoice Request Department",
    type: "react-select",
    component: DepartmentPicker,
    isShow: (isShowObj) => {
      const { company_default } = isShowObj;
      return company_default.enable_default_department_for_invoice_requestor;
    },
    onChangeCallBack: (onChangeObj, e) => {},
    col: 3,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.lock_invoice_requestor_department",
    tooltip: {
      show: false,
    },
    label: "Lock Invoice Request Department",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      const { company_default } = isShowObj;
      return company_default.enable_default_department_for_invoice_requestor;
    },
    onChangeCallBack: (onChangeObj, e) => {},
    col: 3,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.auto_approve_if_two_way_matched",
    tooltip: {
      show: true,
      title: "Auto approve invoices that are two-way matched",
      placement: "top",
    },
    label: "Auto Approve if Two-Way Matched",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj, e) => {},
    col: 3,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.auto_approve_if_advanced_two_way_matched",
    tooltip: {
      show: true,
      title: "Auto approve invoices that are advanced two-way matched",
      placement: "top",
    },
    label: "Auto Approve if Advanced Two-Way Matched",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj, e) => {},
    col: 3,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.auto_approve_if_three_way_matched",
    tooltip: {
      show: true,
      title: "Auto approve invoices that are three-way matched",
      placement: "top",
    },
    label: "Auto Approve if Three-Way Matched",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj, e) => {
      const { dispatch, formName } = onChangeObj;
      dispatch(change(formName, "company_default.invoice.auto_submit_if_three_way_matched", false));
    },
    col: 3,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.invoice.auto_submit_if_three_way_matched",
    tooltip: {
      show: true,
      title: "Auto submit invoices that are three-way matched",
      placement: "top",
    },
    label: "Auto Submit if Three-Way Matched",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return Boolean(isShowObj?.company_default?.auto_approve_if_three_way_matched);
    },
    onChangeCallBack: (onChangeObj, e) => {},
    col: 3,
    validation: [],
    require: false,
    class: "",
  },

  {
    name: "company_default.invoice.inherit_default_term_by_ocr",
    tooltip: {
      show: true,
      title:
        "Enabling this option will make term field in Invoice Inbox and Draft readonly & will inherit default term by OCR",
      placement: "top",
    },
    label: "Inherit default term from vendor",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 3,
    validation: [],
    require: false,
    class: "",
  },
  {
    name: "company_default.invoice.enable_invoice_lines_edit_mode",
    tooltip: {
      show: true,
      title:
        "Enabling this option allows you to edit invoice lines directly from the invoice inbox, draft, and approval sections.",
      placement: "top",
    },
    label: "Enable editing of invoice lines in inbox, draft and for approval sections.",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 3,
    validation: [],
    require: false,
    class: "",
  },
  {
    name: "company_default.invoice.hide_gl_recommendations_from_legacy_invoices",
    tooltip: {
      show: true,
      title:
        "Enabling this option will hide GL coding recommendations in the Invoice Inbox that are based on similar invoices that have been submitted before.",
      placement: "top",
    },
    label: "Hide GL recommendations from previous invoices",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 3,
    validation: [],
    require: false,
    class: "",
  },
  {
    name: "company_default.invoice.hide_gl_recommendations_from_approvers",
    tooltip: {
      show: true,
      title: i18n.t("companySettings.invoiceSettings.hideGLRecommendationsFromApproversTooltip"),
      placement: "top",
    },
    label: i18n.t("companySettings.invoiceSettings.hideGLRecommendationsFromApprovers"),
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 3,
    validation: [],
    require: undefined,
    class: "",
  },
];

export const InvoiceSettingsItemLine: CompanyDefaultsTypes.CommonSettingFieldType[] = [
  /* ------------------------ col 1 start ----------------------------- */
  {
    name: "company_default.invoice_item_hide_business_unit",
    tooltip: {
      show: true,
      title: "Business unit will be hidden from line items section of invoices",
      placement: "top",
    },
    label: "Hide business unit from line items ",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj, e) => {},
    col: 1,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.allow_update_items_in_paid_invoice",
    tooltip: {
      show: true,
      title: "Allow to update Invoice items in PAID status but Invoice amount should not be changed.",
      placement: "top",
    },
    label: "Allow update items in PAID status ",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj, e) => {},
    col: 1,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.allow_edit_invoice_item_subtotal",
    tooltip: {
      show: true,
      title:
        "Allow Invoice Item sub amount to be edited. When subtotal is updated, unit price will be re-calculated according to qty.",
      placement: "top",
    },
    label: "Allow edit Invoice item Sub Amount",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj, e) => {},
    col: 2,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.invoice.items.department.is_hide",
    tooltip: {
      show: true,
      title: "Department will be hidden from invoice item line level",
      placement: "top",
    },
    label: "Hide department from item line level",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj, e) => {},
    col: 1,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.show_employee_field_invoice_item_expense",
    tooltip: {
      show: true,
      title: "Allow invoice to show employee field on invoice line item level and invoice expense level.",
      placement: "top",
    },
    label: "Show employee field on invoice line level ",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj, e) => {},
    col: 1,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.invoice_item_hide_location",
    tooltip: {
      show: true,
      title: "Location will be hidden from line items section of invoices",
      placement: "top",
    },
    label: "Hide location from line items",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj, e) => {},
    col: 1,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.inherit_po_department_to_invoice_itemline",
    tooltip: {
      show: true,
      title:
        "Once invoices are linked to the PO, PO header level department, location and project will be inherited at Invoice item level if selected",
      placement: "top",
    },
    label: "PO header Department, Location and Project inherit",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj, e) => {},
    col: 1,
    validation: [],
    require: false,
    class: "",
  },
  {
    name: "company_default.invoice.items.warehouse.is_required",
    tooltip: {
      show: true,
      title: "Warehouse will be required field at item line level",
      placement: "top",
    },
    label: "Make warehouse required",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj, e) => {},
    col: 1,
    validation: [],
    require: false,
    class: "",
  },
  /* ------------------------ col 1 ends ----------------------------- */
  /* ------------------------ col 2 start ----------------------------- */
  {
    name: "company_default.invoice_item_location_required",
    tooltip: {
      show: true,
      title: "Item level location will be required field at invoice form",
      placement: "top",
    },
    label: "Item level location required",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj, e) => {},
    col: 2,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.invoice.items.business_unit.is_required",
    tooltip: {
      show: true,
      title: "Business unit will be required field at item line level",
      placement: "top",
    },
    label: "Make business unit required",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj, e) => {},
    col: 2,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.invoice.items.department.is_required",
    tooltip: {
      show: true,
      title: "Department will be required fields at item line level",
      placement: "top",
    },
    label: "Make department required",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj, e) => {},
    col: 2,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.invoice.items.project.is_required",
    tooltip: {
      show: true,
      title: "Project will be required fields at item line level",
      placement: "top",
    },
    label: "Make project required",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj, e) => {},
    col: 2,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.invoice.items.inherit_header_location",
    tooltip: {
      show: true,
      title: "Invoice header level location will be inherited at the item level.",
      placement: "top",
    },
    label: "Inherit Invoice header level location to item line level",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      const { company_default } = isShowObj;
      return !company_default.invoice_hide_location;
    },
    onChangeCallBack: (onChangeObj, e) => {},
    col: 2,
    validation: [],
    require: false,
    class: "",
  },
  /* ------------------------ col 2 ends ----------------------------- */
  /* ------------------------ col 3 starts ----------------------------- */
  {
    name: "company_default.enable_freetext_item_sync",
    tooltip: {
      show: false,
    },
    label: "Enable Invoice FreeText Item for Sync",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj, e) => {},
    col: 3,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.freetext_product",
    tooltip: {
      show: false,
    },
    label: "",
    type: "react-select",
    component: ProductItemPicker,
    isShow: (isShowObj) => {
      const { company_default } = isShowObj;
      return company_default.enable_freetext_item_sync;
    },
    onChangeCallBack: (onChangeObj, e) => {
      const { dispatch, formName } = onChangeObj;
      dispatch(change(formName, "company_default.freetext_item_external_id", e.external_id));
    },
    col: 3,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.auto_pull_po_line_item",
    tooltip: {
      show: true,
      title: "Invoice will auto inherit PO items regarless of item quantity",
      placement: "top",
    },
    label: "Auto Pull PO Line Item ",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      const { company_default } = isShowObj;
      return !company_default.invoice?.auto_pull_po_items_to_expense_lines;
    },
    onChangeCallBack: (onChangeObj, e) => {},
    col: 3,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.advance_auto_pull_po_line_item",
    tooltip: {
      show: true,
      title: "Invoice will auto inherit PO items only if item quantity is greater than 0",
      placement: "top",
    },
    label: "Advance Auto Pull PO Line Item",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      const { company_default } = isShowObj;
      return !company_default.invoice?.auto_pull_po_items_to_expense_lines;
    },
    onChangeCallBack: (onChangeObj, e) => {},
    col: 3,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.auto_matching_po_line_item",
    tooltip: {
      show: true,
      title: "Invoice will auto match PO items",
      placement: "top",
    },
    label: "Auto Match PO Line Item ",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      const { company_default } = isShowObj;
      return !company_default.invoice?.auto_pull_po_items_to_expense_lines;
    },
    onChangeCallBack: (onChangeObj, e) => {},
    col: 3,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.invoice.items.inherit_header_department",
    tooltip: {
      show: true,
      title: "Invoice header level Department will be inherited at the item level.",
      placement: "top",
    },
    label: "Inherit Invoice header level Department to item line level",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      const { company_default } = isShowObj;
      return !company_default.invoice_hide_department;
    },
    onChangeCallBack: (onChangeObj, e) => {},
    col: 3,
    validation: [],
    require: false,
    class: "",
  },
  /* ------------------------ col 3 ends ----------------------------- */
];

export const InvoiceSettingsExpenseLine: CompanyDefaultsTypes.CommonSettingFieldType[] = [
  /* ---------------------- col 1 start ------------------------ */
  {
    name: "company_default.invoice.submit_without_gl_coding",
    tooltip: {
      show: true,
      title: "Send invoices for approval without complete coding. Approvers will need the edit invoice permission",
      placement: "top",
    },
    label: "Submit for Approval without GL Coding",
    type: "checkbox",
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj, e) => {},
    col: 1,
    validation: [],
    require: undefined,
    class: "",
    component: RenderCheckBox,
  },
  {
    name: "company_default.invoice.expenses.department.is_hide",
    tooltip: {
      show: true,
      title: "Department will be hidden from invoice expense line level",
      placement: "top",
    },
    label: "Hide department from expense line level",
    type: "checkbox",
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj, e) => {},
    col: 1,
    validation: [],
    require: undefined,
    class: "",
    component: RenderCheckBox,
  },
  {
    name: "company_default.is_tax_to_invoice_expenses_line",
    tooltip: {
      show: true,
      title: "Allowing tax for Invoice expenses line once Selected",
      placement: "top",
    },
    label: "Enable Tax to Invoice Expenses",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj, e) => {},
    col: 1,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.invoice_expense_location_required",
    tooltip: {
      show: true,
      title: "Expense level location will be required field at invoice form",
      placement: "top",
    },
    label: "Location required for debit accounts",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj, e) => {},
    col: 1,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.invoice.expenses.business_unit.is_required",
    tooltip: {
      show: true,
      title: "Business unit will be required field at expense line level",
      placement: "top",
    },
    label: "Make business unit required",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj, e) => {},
    col: 1,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.invoice.expenses.project.is_required",
    tooltip: {
      show: true,
      title: "Project will be required field at expenses line level",
      placement: "top",
    },
    label: "Make project required",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj, e) => {},
    col: 1,
    validation: [],
    require: undefined,
    class: "",
  },

  /* ---------------------- col 1 ends ------------------------ */
  /* ---------------------- col 2 start ------------------------ */
  {
    name: "company_default.invoice.expenses.department.is_required",
    tooltip: {
      show: true,
      title: "Department will be required fields at expense line level",
      placement: "top",
    },
    label: "Department required for debit accounts",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj, e) => {},
    col: 2,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.invoice.expenses.memo.is_required",
    tooltip: {
      show: true,
      title: "Memo will be required field at expense line level",
      placement: "top",
    },
    label: "Memo required",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj, e) => {},
    col: 2,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.inherit_po_department_to_invoice_expense",
    tooltip: {
      show: true,
      title: "Allow user to inherit PO header level department to invoice expenses line level",
      placement: "top",
    },
    label: "Inherit PO header level department",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj, e) => {},
    col: 2,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.invoice.expenses.show_item_list",
    tooltip: {
      show: true,
      title: "Show Items list on expense line.",
      placement: "top",
    },
    label: "Show Items list on expense line.",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj, e) => {},
    col: 2,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.invoice.expenses.inherit_header_location",
    tooltip: {
      show: true,
      title: "Invoice header level location will be inherited at the expense line level.",
      placement: "top",
    },
    label: "Inherit Invoice header level location to expense line level",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      const { company_default } = isShowObj;
      return !company_default.invoice_hide_location;
    },
    onChangeCallBack: (onChangeObj, e) => {},
    col: 2,
    validation: [],
    require: false,
    class: "",
  },
  /* ---------------------- col 2 ends ------------------------ */
  /* ---------------------- col 3 start ------------------------ */
  {
    name: "company_default.invoice.auto_pull_po_expenses_line",
    tooltip: {
      show: true,
      title: "Invoice will auto inherit PO expenses.",
      placement: "top",
    },
    label: "Auto Pull PO Expense line",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj, e) => {},
    col: 3,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.invoice.auto_pull_po_items_to_expense_lines",
    tooltip: {
      show: true,
      title: "It will allow to pull PO Items as an expenses line to Invoice level.",
      placement: "top",
    },
    label: "Auto Pull PO Items to Invoice Expenses",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      const { company } = isShowObj;
      return !company.allow_invoice_items;
    },
    onChangeCallBack: (onChangeObj, e) => {},
    col: 3,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.invoice_account_hide_business_unit",
    tooltip: {
      show: true,
      title: "Business unit will be hidden from both debit accounts and credit accounts in invoices",
      placement: "top",
    },
    label: "Hide business unit from accounts ",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 3,
    validation: [],
    require: false,
    class: "",
  },
  {
    name: "company_default.invoice_account_hide_location",
    tooltip: {
      show: true,
      title: "Location will be hidden from both debit accounts and credit accounts in invoices",
      placement: "top",
    },
    label: "Hide location from accounts ",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 3,
    validation: [],
    require: false,
    class: "",
  },
  {
    name: "company_default.invoice.expenses.inherit_header_department",
    tooltip: {
      show: true,
      title: "Invoice header level Department will be inherited at the expense line level.",
      placement: "top",
    },
    label: "Inherit Invoice header level Department to expense line level",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      const { company_default } = isShowObj;
      return !company_default.invoice_hide_department;
    },
    onChangeCallBack: (onChangeObj, e) => {},
    col: 3,
    validation: [],
    require: false,
    class: "",
  },
  {
    name: "company_default.invoice.expenses.category.is_required",
    tooltip: {
      show: true,
      title: "Category will be required field at expense line level",
      placement: "top",
    },
    label: "Make Category required",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj, e) => {},
    col: 3,
    validation: [],
    require: false,
    class: "",
  },
  /* ---------------------- col 3 ends ------------------------ */
];
export const InvoiceSettingApproval: CompanyDefaultsTypes.CommonSettingFieldType[] = [
  {
    name: "company_default.invoice.disable_rejection_for_invoice_approvals",
    tooltip: {
      show: true,
      title: i18n.t("companySettings.invoiceSettings.disableInvoiceApprovalRejectionTooltip"),
      placement: "top",
    },
    label: i18n.t("companySettings.invoiceSettings.disableInvoiceApprovalRejection"),
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 1,
    validation: [],
    require: undefined,
    class: "",
  },
];
