import React from "react";
import AccountCodesFilter from "../reportFilters/accountCodesDropdown";
import ContactsOptionsFilter from "../reportFilters/contactsDropdown";
import DateFilter from "../reportFilters/date";
import InputFilter from "../reportFilters/input";

const VirtualCardListingSummary = ({}) => {
  const expenseUsersFilter = new URLSearchParams({ by_active: "true" });
  return (
    <div>
      <DateFilter name={"Start Date"} code={"start_date_after"} options={[]} isRequired />
      <DateFilter name={"End Date"} code={"end_date_before"} options={[]} isRequired />
      <AccountCodesFilter
        isMulti={true}
        name={"Account Codes"}
        code={"card_program_comdata_account_ids"}
        customFilters={expenseUsersFilter}
        options={[]}
      />
      <ContactsOptionsFilter isMulti={true} name={"Customer"} code={"contact_id"} options={[]} />
      <InputFilter name={"Vendor Number"} code={"vc_vendor_number"} options={[]} />
      <InputFilter name={"Vendor Name"} code={"vendor_name"} options={[]} />
      <InputFilter name={"Minimum Card Amount"} code={"min_card_amount"} options={[]} />
      <InputFilter name={"Maximum Card Amount"} code={"max_card_amount"} options={[]} />
    </div>
  );
};

export default VirtualCardListingSummary;
