import _ from "lodash";
import { IDType } from "services/common/types/common.type";
import { IUser } from "services/common/user/userTypes";
import invoiceCommonSvc from "../invoices/invoiceCommonSvc";
import { PORequestFormTypes } from "./poRequestFormTypes";
import { PurchaseOrderType } from "./purchaseOrderType";

/**
 * The Singleton class defines an getInstance, that lets clients access
 * the unique singleton instance.
 */
class PoCommonSvc {
  static svcInstance: PoCommonSvc;
  private currentUser: IUser | null = null;

  /**
   * The Singleton's constructor should always be private to prevent direct
   * construction calls with the `new` operator.
   */
  private constructor(currentUser: IUser) {
    this.currentUser = currentUser;
  }

  /**
   * The static getInstance that controls access to the singleton instance.
   *
   * This implementation allows you to extend the Singleton class while
   * keeping just one instance of each subclass around.
   */
  public static getInstance(currentUser: IUser): PoCommonSvc {
    // if this.currentUser is not equal to currentUser from store  create new instance of Svc else cached
    // || !Object.is(currentUser, PoCommonSvc?.svcInstance?.currentUser || {}) this condition make sure that poCommonSvc remain updated
    if (!PoCommonSvc.svcInstance || !Object.is(currentUser, PoCommonSvc?.svcInstance?.currentUser)) {
      PoCommonSvc.svcInstance = new PoCommonSvc(currentUser);
    }

    return PoCommonSvc.svcInstance;
  }

  /************************COMMON BUSINESS LOGICS START **************************/

  isHidePoRequestLines = (): boolean => {
    return Boolean(this.currentUser?.company.po_request?.hide_expense_and_item_lines);
  };

  poRequestHidePOItems = (): boolean => {
    return Boolean(this.currentUser?.company.po_request_hide_po_items);
  };

  is_hide_po_request_lines = (): boolean => Boolean(this.currentUser?.company?.po_request?.hide_expense_and_item_lines);

  hideExpenseAndItemLines = (): boolean => {
    return Boolean(this.currentUser?.company?.po_request?.hide_expense_and_item_lines);
  };

  inheritVendorSubsidiary = (response: any, subsidiaryId: IDType) => {
    let isSubsidiarySelected =
      Array.isArray(response.subsidiaries) &&
      response.subsidiaries.find((subsidiary: any) => subsidiary.id === subsidiaryId);

    if (isSubsidiarySelected && this.currentUser?.company?.po_request_allow_vendor_on_subsidiary) {
      return;
    } else if (response.subsidiary) {
      return response.subsidiary;
    }
  };

  removeIdFromBudgetItemLinks = (item: any) => {
    if (Array.isArray(item.budget_item_links) && item.budget_item_links.length > 0) {
      item.budget_item_links.forEach((link: any) => {
        if (link.id) {
          delete link.id;
        }
      });
    }
  };

  inheritProjectLevelData = (
    lineData: PurchaseOrderType.LineDataType,
    modelData: PurchaseOrderType.LineDataType,
    line: string,
  ) => {
    if (this.inheritPOHeaderProjectToExpenseLine() && _.isPlainObject(modelData?.project) && line == "expense") {
      lineData.project_id = modelData?.project?.id;
      lineData.project = modelData.project;

      if (modelData?.project?.location_id) {
        lineData.location_id = modelData.project.location_id;
      }

      if (modelData?.project?.department_id && !this.currentUser?.company?.po_request_hide_expense_line_department) {
        lineData.department_id = modelData.project.department_id;
        lineData.department = modelData.project.department;

        if (modelData.project?.department?.business_unit_id) {
          lineData.business_unit_id = modelData.project.department.business_unit_id;
        }
      }
    }
  };
  inheritPoHeaderLocationToItemLine = (): boolean => {
    return Boolean(this.currentUser?.company?.po_inherit_header_location_to_item_line);
  };
  isHideItemLineLocation = (): boolean => {
    return Boolean(this.currentUser?.company?.po_request_disabled_itemline_location);
  };
  inheritPoHeaderDepartmentToItemLine = (): boolean => {
    let poSetting = this.currentUser?.company?.purchase_order;
    return Boolean(poSetting?.items?.inherit_header_department);
  };
  isHideItemLineDepartment = (): boolean => {
    return Boolean(this.currentUser?.company?.po_request_disabled_itemline_department);
  };
  isHideExpenseAndItemLines = (): boolean => {
    return Boolean(this.currentUser?.company?.po_request?.hide_expense_and_item_lines);
  };

  inheritHeaderToItemLine = (purchaseOrder: PORequestFormTypes.TPurchaseOrder) => {
    if (
      this.currentUser &&
      purchaseOrder?.po_items &&
      ((this.inheritPoHeaderLocationToItemLine() && this.isHideItemLineLocation()) ||
        (this.inheritPoHeaderDepartmentToItemLine() && this.isHideItemLineDepartment()) ||
        invoiceCommonSvc.allowRebateItemsLine(this.currentUser))
    ) {
      purchaseOrder.po_items.forEach((poItem) => {
        if (this.currentUser) {
          if (this.inheritPoHeaderLocationToItemLine() && this.isHideItemLineLocation()) {
            poItem.location_id = purchaseOrder.location_id;
          }

          if (this.inheritPoHeaderDepartmentToItemLine() && this.isHideItemLineDepartment()) {
            poItem.department_id = purchaseOrder.department_id;
          }
          invoiceCommonSvc.updateRebateLink(poItem, this.currentUser);
        }
      });
    }
  };
  inheritPoHeaderLocationToExpenseLine = () => {
    return Boolean(this.currentUser?.company?.po_inherit_header_location_to_expense_line);
  };
  isHideLocationAtAdminExpenseLine = () => {
    let poSetting = this.currentUser?.company?.purchase_order;
    return Boolean(poSetting?.expenses?.location.is_hide);
  };
  isHideLocationAtPoRequestExpenseLine = () => {
    return Boolean(this.currentUser?.company?.po_request_hide_expense_line_location);
  };
  inheritPoHeaderDepartmentToExpenseLine = () => {
    let poSetting = this.currentUser?.company?.purchase_order;
    return Boolean(poSetting?.expenses?.inherit_header_department);
  };
  isHideDepartmentAtAdminExpenseLine = () => {
    let poSetting = this.currentUser?.company?.purchase_order;
    return Boolean(poSetting?.expenses?.department?.is_hide);
  };
  isHideDepartmentAtPoRequestExpenseLine = () => {
    return this.currentUser?.company?.po_request_hide_expense_line_department;
  };

  inheritHeaderToExpenseLine = (purchaseOrder: PORequestFormTypes.TPurchaseOrder) => {
    if (
      this.currentUser &&
      Array.isArray(purchaseOrder?.invoice_debit_accounts) &&
      ((this.inheritPoHeaderLocationToExpenseLine() &&
        ((this.isHideLocationAtAdminExpenseLine() && !purchaseOrder.is_request_PO) ||
          (this.isHideLocationAtPoRequestExpenseLine() && purchaseOrder.is_request_PO))) ||
        (this.inheritPoHeaderDepartmentToExpenseLine() &&
          ((this.isHideDepartmentAtAdminExpenseLine() && !purchaseOrder.is_request_PO) ||
            (this.isHideDepartmentAtPoRequestExpenseLine() && purchaseOrder.is_request_PO))) ||
        invoiceCommonSvc.allowRebateExpensesLine(this.currentUser))
    ) {
      purchaseOrder?.invoice_debit_accounts.forEach((expenseLine) => {
        if (this.currentUser) {
          if (
            this.inheritPoHeaderLocationToExpenseLine() &&
            ((this.isHideLocationAtAdminExpenseLine() && !purchaseOrder.is_request_PO) ||
              (this.isHideLocationAtPoRequestExpenseLine() && purchaseOrder.is_request_PO))
          ) {
            expenseLine.location_id = purchaseOrder.location_id;
          }

          if (
            this.inheritPoHeaderDepartmentToExpenseLine() &&
            ((this.isHideDepartmentAtAdminExpenseLine() && !purchaseOrder.is_request_PO) ||
              (this.isHideDepartmentAtPoRequestExpenseLine() && purchaseOrder.is_request_PO))
          ) {
            expenseLine.department_id = purchaseOrder.department_id;
          }
          invoiceCommonSvc.updateRebateLink(expenseLine, this.currentUser);
        }
      });
    }
  };

  inheritDefaultExpenseAccount = (purchaseOrder: PORequestFormTypes.TPurchaseOrder) => {
    let poRequest = this.currentUser?.company?.po_request;
    if (purchaseOrder.is_request_PO && this.hideExpenseAndItemLines() && poRequest?.default_expense_line_account_id) {
      let expenseObj = Array.isArray(purchaseOrder.invoice_debit_accounts)
        ? purchaseOrder.invoice_debit_accounts[0]
        : {};

      expenseObj.amount = purchaseOrder.amount;
      expenseObj.account_id = poRequest.default_expense_line_account_id;
      purchaseOrder.invoice_debit_accounts = [];
      purchaseOrder.invoice_debit_accounts.push(expenseObj);
    }
  };

  updateFieldsBeforeSubmit = (purchaseOrder: PORequestFormTypes.TPurchaseOrder) => {
    this.inheritHeaderToItemLine(purchaseOrder);
    this.inheritHeaderToExpenseLine(purchaseOrder);
    this.inheritDefaultExpenseAccount(purchaseOrder);
  };
  isAttachmentRequiredBasedOnAmount = (amount: number, currentUser: IUser) => {
    const minAmount = parseFloat(currentUser?.company?.po_request_attachment_required_on_min_amount);
    return amount && !isNaN(minAmount) && amount >= minAmount;
  };

  updateAddressFields = (address: any) => {
    if (!address.entity_name && address["properties"] && address["properties"]["entity_name"]) {
      address.entity_name = address["properties"]["entity_name"];
    }
    return address;
  };

  // TODO: add types
  setItemSubtype = (po: any, poItems: any, params: Record<string, any>) => {
    if (this.currentUser?.company.po_request_allow_inventory_item_for_inventory_type && po.sub_type === "Inventory") {
      params.sub_type = po.sub_type;
    } else {
      params.sub_type = null;
    }
  };

  // TODO: mohit Add purchaseOrder type
  getVendorPartNum = <
    T extends {
      product_item?: {
        properties?: {
          vendor_codes?: any;
        };
        vendor_codes?: any;
      };
      vendor_part_number?: any;
    },
  >({
    item,
    modalData,
  }: {
    item: T | null | undefined;
    modalData: any;
  }) => {
    if (item && item.product_item && modalData && modalData.vendor) {
      let vendorCodes =
        item.product_item.properties && item.product_item.properties.vendor_codes
          ? item.product_item.properties.vendor_codes
          : item.product_item.vendor_codes;
      const obj =
        Array.isArray(vendorCodes) &&
        vendorCodes.find((vendorCode) => vendorCode.external_id === modalData?.vendor?.external_id);
      item.vendor_part_number = obj ? obj.code : null;
    }
  };

  inheritPOHeaderProjectToExpenseLine = () => {
    return this.currentUser?.company.purchase_order?.expenses?.inherit_header_project;
  };

  inheritPOHeaderProjectToItemLine = () => {
    return this.currentUser?.company.purchase_order?.items?.inherit_header_project;
  };

  isItemsExist = (poItems: PurchaseOrderType.PoItemType[]) => {
    return poItems?.filter((item) => item._destroy !== 1) || [];
  };

  isExpensesExist = (poExpenses: PurchaseOrderType.InvoiceDebitAccountsType[]) => {
    return poExpenses?.filter((expense) => expense._destroy !== 1) || [];
  };

  calculateItemsTotal = (poItems: PurchaseOrderType.PoItemType[]) => {
    const items = this.isItemsExist(poItems);
    return items.reduce((total, item) => total + (item.total || 0), 0);
  };

  calculateExpensesTotal = (poExpenses: PurchaseOrderType.InvoiceDebitAccountsType[]) => {
    const expenses = this.isExpensesExist(poExpenses);
    return expenses.reduce((amount, expense) => amount + (expense.amount || 0), 0);
  };
}

export default PoCommonSvc;
