import { combineReducers, createStore } from "redux";
import apiProviders from "./apiProviderReducers";
import creditMemoReducer from "./creditMemoReducer";
import editDraftExpenseItemRed from "./editDraftExpenseItemReducer";
import floatingFilter from "./floatingFilterReducer";
import paymentMethodRed from "./paymentMethodReducer";
import paymentValidationsReducers from "./paymentValidationsReducers";
import storeValues from "./storeValueReducers";
import submitInvoicesReducers from "./submitInvoicesReducers";
import taxFormsReducer from "./taxFormsReducer";
import userReducer from "./userReducers";
// import { reducer as formReducer } from 'redux-form'
import { TypedUseSelectorHook, useSelector as useReduxSelector } from "react-redux";
import { reducer as formReducer } from "redux-form";
import addContactForm from "./addContactsReducer";
import approvalCountReducder from "./admin/approvalCountReducer";
import calendarEventerReducer from "./admin/calendarEventerReducer";
import companyAccountsReducer from "./admin/companyAccountsReducer";
import companyDefaultReducer from "./admin/companyDefaultReducer";
import companyPaymentMethodFormReducer from "./admin/companyPaymentMethodFormReducer";
import companyProfileFormReducer from "./admin/companyProfileFormReducer";
import companyLandingSlice from "./admin/companySlice";
import emailReducer from "./admin/emailReducer";
import expensesCountReducer from "./admin/expenseCountReducer";
import integrationsReducer from "./admin/integrationsReducer";
import manageAccountFormReducer from "./admin/manageAccountFormReducer";
import { messagePanelStatesReducers } from "./admin/messageSlice";
import receiptReducer from "./admin/receiptsReducer";
import samlConfigurationReducer from "./admin/samlConfigurationReducer";
import userProfileReducer from "./admin/userProfileReducer";
import approvalSettingsReducer from "./approvalSettingsReducers";
import addressSectionReducer from "./common/addressSectionReducer";
import { appCommonStatesReducers } from "./common/appCommonStatesSlice";
import confirmModalReducer from "./confirmModalReducer";
import diversityTrackingForm from "./dynamicForms/diversityTrackingFormReducer";
import invoiceReducer from "./invoiceReducer";
import onboardingReducer from "./onboardingReducer";
import paymentReducer from "./paymentReducer";
import bulkUploadSlice from "./slices/bulkUploadSlice";
import metadataConfigurationSlice from "./slices/metadataConfigurationSlice";
import metadataHierarchySlice from "./slices/metadataHierarchySlice";
import metadataTemplateSlice from "./slices/metadataTemplateSlice";
import payReimbursementSlice from "./slices/payReimbursementSlice";
import vpCompanyProfileReducer from "./vp/companyProfileReducer";
import vendorContactFormReducer from "./vp/vendorContactFormReducer";
import vendorSetupGuideReducer from "./vp/vendorSetUpGuideReducer";

const rootReducer = combineReducers({
  storeValues: storeValues,
  confirmModalReducer: confirmModalReducer,
  user: userReducer,
  approvalCount: approvalCountReducder,
  unsubmittedExpensesCount: expensesCountReducer,
  apiProviders: apiProviders,
  floatingFilter: floatingFilter,
  paymentMethodRed: paymentMethodRed,
  editDraftExpenseItemRed: editDraftExpenseItemRed,
  taxFormsReducer: taxFormsReducer,
  paymentValidationsReducers: paymentValidationsReducers,
  submitInvoicesReducers: submitInvoicesReducers,
  payment: paymentReducer,
  invoice: invoiceReducer,
  addContactForm: addContactForm,
  creditMemo: creditMemoReducer,
  form: formReducer,
  onboarding: onboardingReducer,
  approvalSettingsReducer: approvalSettingsReducer,
  diversityTrackingForm: diversityTrackingForm,
  companyDefaultRed: companyDefaultReducer,
  integrationsRed: integrationsReducer,
  companyPaymentMethodFormRed: companyPaymentMethodFormReducer,
  addressSectionRed: addressSectionReducer,
  calendarEventerRed: calendarEventerReducer,
  companyAccountsRed: companyAccountsReducer,
  manageAccountFormRed: manageAccountFormReducer,
  companyProfileFormRed: companyProfileFormReducer,
  samlConfigurationRed: samlConfigurationReducer,
  vendorContactFormRed: vendorContactFormReducer,
  emailRed: emailReducer,
  vendorSetupGuideRed: vendorSetupGuideReducer,
  userProfileRed: userProfileReducer,
  vpCompanyProfileReducer: vpCompanyProfileReducer,
  receiptRed: receiptReducer,
  bulkUploadForm: bulkUploadSlice,
  companyLandingSlice: companyLandingSlice,
  metadataConfigurationForm: metadataConfigurationSlice,
  metadataHierarchyForm: metadataHierarchySlice,
  metadataTemplateForm: metadataTemplateSlice,
  appCommonStates: appCommonStatesReducers,
  adminMessagePanelRed: messagePanelStatesReducers,
  payExpenseReviewForm: payReimbursementSlice,
});

export default rootReducer;

// don't export store, used only for creating types
const store = createStore(rootReducer);
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useTypedSelector: TypedUseSelectorHook<RootState> = useReduxSelector;
