import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { FaSpinner } from "react-icons/fa6";
import { Document, Page, pdfjs } from "react-pdf";
import CheckedThumbnail from "./checkedThumbnail";
import styles from "./pdfPreviewViewer.module.css";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

const options = {
  cMapUrl: `//unpkg.com/pdfjs-dist@${pdfjs.version}/cmaps/`,
  cMapPacked: true,
};

type PdfPreviewViewerProps = {
  file: File | undefined;
  numPages: number | undefined;
  setNumPages: React.Dispatch<React.SetStateAction<number | undefined>>;
  pageNumber: number;
  setPageNumber: (pageNumber: number) => void;
  width: number;
  isSubmitting: boolean;
  checkboxValues: Map<number, boolean>;
  setCheckboxValues: React.Dispatch<React.SetStateAction<Map<number, boolean>>>;
  thumbnailsPerPage?: number;
  thumbnailVisible: boolean;
  scale: number;
  rotation: number;
  setDragStart: React.Dispatch<React.SetStateAction<any>>;
  setDragOffset: React.Dispatch<React.SetStateAction<any>>;
  dragStart: any;
  dragOffset: any;
};

const usePDFNavigation = (numPages: number | null, thumbnailsPerPage: number) => {
  const [currentPage, setCurrentPage] = useState(1);

  const goToPrevious = useCallback(() => {
    setCurrentPage((current) => Math.max(current - 1, 1));
  }, []);

  const goToNext = useCallback(() => {
    setCurrentPage((current) => Math.min(current + 1, Math.max(1, numPages || 0)));
  }, [numPages]);

  const canGoPrevious = currentPage > 1;
  const canGoNext = numPages !== null && currentPage + thumbnailsPerPage - 1 < numPages;

  return { currentPage, goToPrevious, goToNext, canGoPrevious, canGoNext, setCurrentPage };
};

const PdfPreviewViewer: React.FC<PdfPreviewViewerProps> = ({
  file,
  pageNumber,
  setPageNumber,
  isSubmitting,
  setCheckboxValues,
  checkboxValues,
  thumbnailVisible,
  thumbnailsPerPage = 4,
  scale,
  rotation,
  setDragStart,
  dragStart,
  dragOffset,
  setDragOffset,
  numPages,
  setNumPages,
  width,
}) => {
  const dragRef = useRef<HTMLDivElement>(null);
  const [isDragging, setIsDragging] = useState(false);

  const spinner = (
    <div className={styles.spinnerContainer}>
      <FaSpinner className={styles.spinner} />
    </div>
  );

  const handleCheckboxClick = useCallback(
    (idx: number, checked: boolean) => {
      setCheckboxValues((prev) => new Map(prev.set(idx, checked)));
    },
    [setCheckboxValues],
  );

  const { currentPage, setCurrentPage } = usePDFNavigation(numPages ?? 0, thumbnailsPerPage);

  const thumbnailRange = useMemo(() => {
    return [...Array(thumbnailsPerPage)]
      .map((_, index) => currentPage + index)
      .filter((page) => page <= (numPages || 0));
  }, [currentPage, thumbnailsPerPage, numPages]);

  const handleDocumentLoadSuccess = useCallback(
    ({ numPages }: { numPages: number }) => {
      setNumPages(numPages);
    },
    [setNumPages],
  );

  const handleMouseDown = useCallback(
    (event: React.MouseEvent) => {
      setIsDragging(true);
      setDragStart({ x: event.clientX - dragOffset.x, y: event.clientY - dragOffset.y });
    },
    [dragOffset.x, dragOffset.y, setDragStart],
  );

  const handleMouseMove = useCallback(
    (event: React.MouseEvent) => {
      if (isDragging) {
        setDragOffset({ x: event.clientX - dragStart.x, y: event.clientY - dragStart.y });
      }
    },
    [isDragging, setDragOffset, dragStart.x, dragStart.y],
  );

  const handleMouseUp = useCallback(() => {
    setIsDragging(false);
  }, []);

  useEffect(() => {
    setPageNumber(1);
  }, [file, setPageNumber]);

  useEffect(() => {
    const currentPageIndex = Math.floor((pageNumber - 1) / thumbnailsPerPage) * thumbnailsPerPage + 1;
    setCurrentPage(currentPageIndex);
  }, [pageNumber, setCurrentPage, thumbnailsPerPage]);

  const componentMainClassName = isSubmitting ? `${styles.pdfViewer} ${styles.blurred}` : styles.pdfViewer;

  useEffect(() => {
    if (!thumbnailVisible) {
      setCheckboxValues(new Map());
    }
  }, [setCheckboxValues, thumbnailVisible]);

  return file ? (
    <Document
      loading={spinner}
      file={file}
      onLoadSuccess={handleDocumentLoadSuccess}
      options={options}
      className={`${componentMainClassName}`}
      error={<></>}
      noData={<></>}
    >
      {thumbnailVisible && (
        <div className={styles.navigatorSticky}>
          <div className={styles.navigatorContainer}>
            <div className={styles.thumbnailsContainer}>
              {thumbnailRange.map((page, index) =>
                page <= (numPages || 0) ? (
                  <CheckedThumbnail
                    key={`${page}_${index}`}
                    numPages={numPages || 0}
                    pageNumber={page}
                    onClickCheckBox={handleCheckboxClick}
                    checked={!!checkboxValues.get(page)}
                    selectedPage={pageNumber}
                    onPageSelect={setPageNumber}
                    width={width / thumbnailsPerPage - 20}
                  />
                ) : null,
              )}
            </div>
          </div>
        </div>
      )}
      <div
        className={`${styles.documentContainer} ${styles.document}`}
        ref={dragRef}
        style={{ transform: `translate(${dragOffset.x}px, ${dragOffset.y}px)` }}
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUp}
        onMouseLeave={handleMouseUp}
      >
        <Page
          scale={scale}
          pageNumber={pageNumber}
          renderTextLayer={false}
          renderAnnotationLayer={false}
          rotate={rotation}
          width={width - 70}
        />
      </div>
    </Document>
  ) : (
    <></>
  );
};

export default memo(PdfPreviewViewer);
