import _ from "lodash";
import { useCallback } from "react";
import { useFormContext } from "react-hook-form";
import { useTypedSelector } from "reducers";
import { selectCurrentUser } from "reducers/userReducers";
import { IUser } from "services/common/user/userTypes";
import { isDefined } from "services/general/helpers";
import adminCommonSvc from "../commonSvc";
import { InvoiceType } from "./invoiceType";

export const useInvoiceCommonSvc = () => {
  const currentUser: IUser = useTypedSelector(selectCurrentUser);
  const { getValues, setValue } = useFormContext<InvoiceType.InvoiceDetailType>();

  const getDefaultDecimal = useCallback(() => {
    return currentUser.company.has_any_integration ? 2 : 4;
  }, [currentUser]);

  const roundUpAmount = useCallback(
    (val: number, decimal: number | null = null) => {
      let precision = getDefaultDecimal();
      let d = decimal && decimal > 0 ? decimal : precision;
      return parseFloat(val.toFixed(d));
    },
    [getDefaultDecimal],
  );

  const updateDiscountAmount = useCallback(() => {
    const invoice = getValues();
    if (!currentUser.company.invoice_hide_discount && invoice) {
      const discAmount = adminCommonSvc.calculateDiscountAmt(invoice, currentUser);
      setValue("amount_disc", discAmount);
    }
  }, [currentUser, getValues, setValue]);

  const updateDiscountDate = useCallback(() => {
    const invoice = getValues();
    if (!currentUser.company.invoice_hide_discount && invoice) {
      const discDate = adminCommonSvc.calculateDiscDate(invoice);
      setValue("amount_disc_date", discDate);
    }
  }, [currentUser, getValues, setValue]);

  const updateDueDate = useCallback(() => {
    const invoice = getValues();
    if (invoice) {
      const dueDate = adminCommonSvc.calculateDueDate(invoice);
      setValue("due_date", dueDate);
    }
    updateDiscountDate();
    updateDiscountAmount();
  }, [getValues, setValue, updateDiscountAmount, updateDiscountDate]);

  const isActiveHeaderTax = () => {
    const invoice = getValues();
    const applyTaxOnLineAndStoreHeaderLevel = currentUser.company?.global?.apply_tax_on_line_and_store_header_level;
    const allowLineTax = !invoice?.subsidiary?.allow_line_tax;

    if (_.isPlainObject(invoice)) {
      return applyTaxOnLineAndStoreHeaderLevel && allowLineTax;
    } else {
      return applyTaxOnLineAndStoreHeaderLevel;
    }
  };

  const getItemTotal = useCallback(
    (invItem: InvoiceType.TInvoiceItem) => {
      const isUsedTax = getValues("is_used_tax");

      if (!_.isPlainObject(invItem)) {
        return 0; // Default value for non-objects
      }

      let amt = 0;
      let amount = invItem.amount || 0;
      let tax = invItem.tax || 0;
      let rebateAmount = invItem.rebate_amount || 0;
      let whTaxAmount = invItem.wh_tax_amount || 0;

      if (isUsedTax) {
        amt = Number(amount);
      } else {
        amt = Number(amount) + Number(tax) + Number(whTaxAmount) + Number(rebateAmount);
      }
      let total = roundUpAmount(Number(amt)); // TODO: add this here adminCommonSvc.unitPriceDecimalLimit(currentUser)
      return total;
    },
    [getValues, roundUpAmount],
  );

  const getItemsTotal = useCallback(() => {
    let invoiceItemsAttributes = getValues("invoice_items_attributes") as InvoiceType.TInvoiceItem[];
    let total = 0;
    if (_.isArray(invoiceItemsAttributes) && invoiceItemsAttributes.length > 0) {
      invoiceItemsAttributes.forEach((item) => {
        if (item._destroy !== 1) {
          total = total + getItemTotal(item);
        }
      });
    }
    return total;
  }, [getItemTotal, getValues]);

  const isItemDebitLine = (debitEntry: any) => {
    return _.isPlainObject(debitEntry) && (debitEntry.item_line_id || debitEntry.product_item_id);
  };

  const getAccountsTotal = useCallback(() => {
    let accountTotal = 0;
    const debitEntries = getValues("debit_entries_attributes");

    Array.isArray(debitEntries) &&
      debitEntries?.forEach((entry, key) => {
        if (Number(entry.amount) && entry._destroy !== 1 && !isItemDebitLine(entry)) {
          accountTotal += Number(entry.amount);
        }
      });

    return accountTotal;
  }, [getValues]);

  const isItemsExist = () => {
    const invoice = getValues();
    return invoice.invoice_items_attributes?.filter((item) => item._destroy !== 1) || [];
  };

  const isExpenseLineExist = () => {
    const invoice = getValues();
    return invoice.debit_entries_attributes?.filter((entry) => entry._destroy !== 1) || [];
  };

  const calculateItemsTotal = () => {
    const items = isItemsExist();
    let itemsTotal = items.reduce((total, item) => total + (item.total || 0), 0);

    return itemsTotal;
  };

  const updateCreditEntries = () => {
    let creditEntries = getValues("credit_entries_attributes") || [];
    const totalAmount = getValues("amount") || 0;

    if (_.isArray(creditEntries) && creditEntries.length > 0) {
      const updatedCreditEntries = creditEntries.map((creditEntry) => {
        if (isDefined(totalAmount) && totalAmount! >= 0) {
          let percent = Number(creditEntry.percent ? creditEntry.percent : 100);
          let amount = Number(adminCommonSvc.roundUpAmount((totalAmount * Number(percent)) / 100, null, currentUser));
          return {
            ...creditEntry,
            percent: percent,
            amount: amount || totalAmount,
          };
        }
        return creditEntry;
      });

      setValue("credit_entries_attributes", updatedCreditEntries);
    }
  };

  const calculateDiscountAmt = () => {
    const invoice = getValues();
    const discAmount = adminCommonSvc.calculateDiscountAmt(invoice, currentUser);
    invoice.amount_disc = discAmount;
    setValue("amount_disc", discAmount);
  };

  const getInvoiceTotalAmount = () => {
    let amount = calculateItemsTotal();
    amount = amount + getAccountsTotal();
    amount = roundUpAmount(amount);
    return amount;
  };

  const showTaxOnDebitLine = () => {
    // verify this logic is working or not
    return (
      currentUser.company.is_tax_to_invoice_expenses_line &&
      !isActiveHeaderTax() &&
      (currentUser.company.has_taxes || currentUser.company.enable_to_enter_tax_amount)
    );
  };

  const disabledFreetextItem = () => {
    return currentUser.company.disabled_freetext_item === true;
  };

  const allowRebateExpenseLine = () => {
    return currentUser.company.has_rebates && currentUser.company.global?.allow_rebate_at_expenses_line;
  };

  const enabledExpenseTaxOrRebate = () => {
    return currentUser.company.has_taxes && currentUser.company.is_tax_to_invoice_expenses_line;
    // return currentUser.company.has_taxes && currentUser.company.is_tax_to_invoice_expenses_line || allowRebateExpenseLine(); // TODO: Enable this line when the rebate dependency for expense lines is added.
  };

  const invoiceCommonSvc = {
    getAccountsTotal,
    isItemDebitLine,
    updateDueDate,
    updateDiscountDate,
    updateDiscountAmount,
    showTaxOnDebitLine,
    getItemTotal,
    getDefaultDecimal,
    getItemsTotal,
    disabledFreetextItem,
    roundUpAmount,
    enabledExpenseTaxOrRebate,
    isExpenseLineExist,
    updateCreditEntries,
    calculateDiscountAmt,
    getInvoiceTotalAmount,
    calculateItemsTotal,
    isItemsExist,
  };

  return invoiceCommonSvc;
};
