import CustomModal from "components/modals/customModal";
import React, { useCallback } from "react";
import { useDispatch } from "react-redux";
import { change } from "redux-form";
import adminCommonSvc from "services/admin/commonSvc";
import expenseItemCommonSvc from "services/admin/expenses/expenseItems/expenseItemCommonSvc";
import { ExpensesTypes } from "services/admin/expenses/expensesType";
import { IAttachment } from "services/common/types/common.type";
import { useExpenseItemFormContext } from "../expenseItemFormSectionContext";
import { useFormValue } from "../formExpenseItem";
import style from "./../expenseItem.module.css";
import MapRouteForm from "./mapRouteForm";

type AddRouteMapModalProps = {
  show: boolean;
  handleClose: () => void;
};

const AddRouteMapModal = ({ show, handleClose }: AddRouteMapModalProps) => {
  const ctx = useExpenseItemFormContext();
  const expenseItem = useFormValue<ExpensesTypes.ExpenseItemFormDataType>(ctx?.formName, ctx?.sectionPrefix);
  const mapRoute = expenseItem?.map_route;
  const defaultMapRoute = (mapRoute && { map_route: mapRoute }) ?? { map_route: {} };
  const dispatch = useDispatch();
  const isAdd = !mapRoute; // if mileage expense item mapRoute is having id it's in edit mode.

  const removePreviousMapAttachment = useCallback((assetAttributes: IAttachment[]): IAttachment[] => {
    return assetAttributes.map((asset) => {
      if (
        !asset._destroy &&
        (asset.name?.includes(expenseItemCommonSvc.MILEAGE_GOOGLE_MAP_ATTACHMENT_NAME_PREFIX) ||
          asset.asset_file_file_name?.includes(expenseItemCommonSvc.MILEAGE_GOOGLE_MAP_ATTACHMENT_NAME_PREFIX))
      ) {
        return { ...asset, _destroy: 1 };
      }
      return asset;
    });
  }, []);

  const handleRouteMapFormSubmit = async (mapDataRouteData: ExpensesTypes.TMapRouteFormData) => {
    let mapRouteWaypointsAttributes: ExpensesTypes.TMapRouteWaypoints[] = [];

    // merging destroyed_map_route_waypoints  and map_route_waypoints attribute
    if (Array.isArray(mapDataRouteData?.map_route?.map_route_waypoints)) {
      mapRouteWaypointsAttributes = [...mapRouteWaypointsAttributes, ...mapDataRouteData.map_route.map_route_waypoints];
    }
    if (Array.isArray(mapDataRouteData?.map_route?.destroyed_map_route_waypoints)) {
      mapRouteWaypointsAttributes = [
        ...mapRouteWaypointsAttributes,
        ...mapDataRouteData.map_route.destroyed_map_route_waypoints,
      ];
    }

    const geolocations = expenseItemCommonSvc.waypointsToGeolocation(mapDataRouteData?.map_route?.map_route_waypoints);
    const updatedMapRoute: ExpensesTypes.TMapRouteFormData = {
      map_route: {
        ...mapDataRouteData.map_route,
        map_route_waypoints_attributes: mapRouteWaypointsAttributes,
      },
    };

    const screenShot = await adminCommonSvc.takeScreenshotFile({
      divId: expenseItemCommonSvc.MILEAGE_GOOGLE_MAP_VIEW_DIV_ID,
      namePrefix: expenseItemCommonSvc.MILEAGE_GOOGLE_MAP_ATTACHMENT_NAME_PREFIX,
    });

    if (screenShot) {
      // screenshot will be always there
      const assetAttributes = removePreviousMapAttachment(expenseItem?.assets_attributes ?? []);
      const assetFile: IAttachment = {
        asset_file: screenShot,
        asset_file_file_name: screenShot.name,
        url: URL.createObjectURL(screenShot),
        asset_expiring_url: URL.createObjectURL(screenShot),
      };

      const updatedExpenseItem: ExpensesTypes.ExpenseItemFormDataType = {
        ...expenseItem,
        geolocations: geolocations,
        assets_attributes: [...assetAttributes, assetFile],
        map_route: updatedMapRoute.map_route,
        map_route_attributes: updatedMapRoute.map_route,
        distance: mapDataRouteData.map_route.distance,
        distance_unit: mapDataRouteData.map_route.units,
      };

      if (ctx) {
        dispatch(change(ctx?.formName, ctx?.sectionPrefix, updatedExpenseItem));
      }
      handleClose();
    }
  };

  return (
    <CustomModal
      show={show}
      onHide={handleClose}
      header={isAdd ? "Add Route Map" : "Edit Route Map"}
      size={"lg"}
      headerClass={style.headerClass}
      modalBodyClass={style.modalBody}
      contentClassName={style.mapModalContent}
      body={<MapRouteForm defaultValues={defaultMapRoute} onSubmit={handleRouteMapFormSubmit} onCancel={handleClose} />}
    />
  );
};

export default AddRouteMapModal;
