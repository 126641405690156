import React from "react";
import AccountCodesFilter from "../reportFilters/accountCodesDropdown";
import ContactsOptionsFilter from "../reportFilters/contactsDropdown";
import DateFilter from "../reportFilters/date";

const VirtualCardReconciliationCards = ({}) => {
  const expenseUsersFilter = new URLSearchParams({ by_active: "true" });
  return (
    <div>
      <AccountCodesFilter
        isMulti={true}
        name={"Account Codes"}
        code={"card_program_comdata_account_ids"}
        customFilters={expenseUsersFilter}
        options={[]}
      />
      <ContactsOptionsFilter isMulti={true} name={"Customer"} code={"contact_id"} options={[]} />
      <DateFilter name={"Start Date"} code={"start_date_after"} options={[]} isRequired />
      <DateFilter name={"End Date"} code={"end_date_before"} options={[]} isRequired />
    </div>
  );
};

export default VirtualCardReconciliationCards;
