import useShowSelectMenu from "components/admin/hooks/useShowSelectMenu";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Form } from "react-bootstrap";
import Select from "react-select";
import { Mandatory } from "wombatifier/components/pickers/mandatory";

type WombatSelectPropsType = {
  className?: string;
  options: SelectOptionType[];
  sourceOptions?: SelectOptionType[];
  label?: string;
  labelClassName?: string;
  placeholder?: string;
  isRequired?: boolean;
  isDisabled?: boolean;
  tooltip?: string;
  defaultValue?: any;
  searchText?: string;
  onSearchTextChange?: (value: string) => void;
  onChangeCallback?: (value: any, sourceOptions: SelectOptionType[]) => void;
  onBlurCallback?: (value: any) => void;
  floating?: boolean;
  input?: any;
  meta: { touched: boolean; error: string; warning: string };
  indicator?: boolean;
  isClearable?: boolean;
  isSearchable?: boolean;
  wrapSelected?: boolean;
  style?: any;
  inline?: boolean;
  menuAutoFixed?: boolean;
  parentDivId?: string;
  parentDivVariance?: {
    left?: number;
    right?: number;
    top?: number;
    bottom?: number;
  };
  initialValue?: any;
};

interface SelectOptionType {
  label: string;
  value: any;
}

export const WombatSelect = ({
  className = "",
  options,
  sourceOptions = [],
  label = "",
  labelClassName = "",
  placeholder = "Select one",
  isRequired = false,
  isDisabled = false,
  tooltip,
  defaultValue,
  floating = false,
  onChangeCallback,
  onBlurCallback,
  input,
  style,
  meta: { touched, error },
  indicator = true,
  isClearable = false,
  isSearchable = false,
  wrapSelected = false,
  menuAutoFixed = false,
  inline,
  parentDivId,
  parentDivVariance,
  onSearchTextChange,
  searchText,
  initialValue,
}: WombatSelectPropsType) => {
  const [selectedOption, setSelectedOption] = useState<SelectOptionType | undefined>(undefined);

  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const selectRef = useRef<HTMLDivElement | null>(null);
  useShowSelectMenu({
    setIsMenuOpen,
    selectRef,
    parentDivId,
    variance: parentDivVariance,
  });

  const handleOnChange = useCallback(
    (option: SelectOptionType) => {
      setSelectedOption(option ? option : options.find((option) => !option.value));
      input?.onChange(option?.value ?? null);
      onChangeCallback && onChangeCallback(option?.value, options);
    },
    [input, onChangeCallback, options],
  );

  useEffect(() => {
    if (input?.value) {
      if (initialValue && initialValue.value === input.value) {
        setSelectedOption(initialValue);
      } else {
        sourceOptions.length && setSelectedOption(sourceOptions.find((option) => input.value === option.value));
        !sourceOptions.length && setSelectedOption(options.find((option) => input.value === option.value));
      }
    } else {
      setSelectedOption({ label: "Select One", value: null });
    }
  }, [input?.value, options.length]);

  return (
    <Form.Group className={`${className}`} ref={selectRef}>
      {label && !inline && (
        <Form.Label className={labelClassName}>
          <div className={style?.alignNameIcon}>
            {style?.additionalCodingIcon && (
              <i className={`icon icon-vendors ${style?.additionalCodingIcon}` || ""}></i>
            )}
            <span className={style?.vendorDetails || ""}>{label}</span>
            <Mandatory required={isRequired} />
          </div>
          {tooltip ?? ""}
        </Form.Label>
      )}
      {label && inline && (
        <Form.Label className={labelClassName}>
          {label}
          <Mandatory required={isRequired} />
          {tooltip ?? ""}
        </Form.Label>
      )}

      <Select
        {...(input ? input : {})}
        required={isRequired}
        placeholder={placeholder}
        value={selectedOption}
        onBlur={onBlurCallback ? onBlurCallback : () => {}}
        onChange={handleOnChange}
        {...(menuAutoFixed ? { menuPlacement: "auto", menuPosition: "fixed" } : {})}
        menuIsOpen={isMenuOpen}
        inputValue={searchText}
        onInputChange={onSearchTextChange}
        onMenuOpen={() => setIsMenuOpen(true)}
        onMenuClose={() => setIsMenuOpen(false)}
        components={{ ...(!indicator ? { DropdownIndicator: () => null, IndicatorSeparator: () => null } : {}) }}
        options={options}
        {...(defaultValue ? { defaultValue } : {})}
        isClearable={isClearable}
        isSearchable={isSearchable}
        classNamePrefix="select"
        isDisabled={isDisabled}
        styles={{
          control: (baseStyles) => ({
            ...baseStyles,
          }),
          singleValue: (baseStyles) => ({
            ...baseStyles,
            ...(wrapSelected ? { textWrap: "wrap" } : {}),
          }),
          ...(floating ? { menuPortal: (base) => ({ ...base, zIndex: 9999 }) } : {}),
        }}
        {...(floating ? { menuPortalTarget: document.body } : {})}
      />
      {touched && error && (
        <Form.Control.Feedback className="d-inline-block" type="invalid">
          {error}
        </Form.Control.Feedback>
      )}
    </Form.Group>
  );
};
