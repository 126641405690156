import { APIProvider, Map, MapCameraChangedEvent, useMap, useMapsLibrary } from "@vis.gl/react-google-maps";
import { Checkbox, Select } from "components/forms/hookFormFields";
import chosenEnv from "config";
import React, { ChangeEvent, memo, useCallback, useEffect, useMemo } from "react";
import { Button, Col, Form, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { FormProvider, useFieldArray, useForm, useFormContext, useWatch } from "react-hook-form";
import { IoMdAddCircle } from "react-icons/io";
import { MdDelete, MdPlace } from "react-icons/md";
import adminCommonSvc from "services/admin/commonSvc";
import expenseItemCommonSvc from "services/admin/expenses/expenseItems/expenseItemCommonSvc";
import { ExpensesTypes } from "services/admin/expenses/expensesType";
import commonService from "services/common/commonSvc";
import style from "./../expenseItem.module.css";
import Directions from "./directions";
import PlaceAutocomplete from "./placeAutocomplete";
import PoiMarkers from "./poiMarkers";

//keeping out side of component so that don't rerender
const env: any = chosenEnv;

type TWayPointProps = {
  index: number;
};

const WayPoint = ({ index }: TWayPointProps) => {
  const { setValue, getValues } = useFormContext();
  const mapsGeo = useMapsLibrary("geocoding");
  const [isDestroy] = useWatch({ name: [`map_route.map_route_waypoints.${index}._destroy`] });

  const onPlaceSelectCallback = useCallback(
    (placeDetails: google.maps.places.PlaceResult | null, selectedOption: { label: string; value: string } | null) => {
      const place = getValues(`map_route.map_route_waypoints.${index}`) ?? {};
      setValue(`map_route.map_route_waypoints.${index}`, {
        ...place,
        address: selectedOption?.label,
        lat: placeDetails?.geometry?.location?.lat() ?? null,
        lng: placeDetails?.geometry?.location?.lng() ?? null,
        place_id: placeDetails?.place_id,
      });
    },
    [getValues, index, setValue],
  );

  const handleUserCurrentLocation = useCallback(async () => {
    // add check if geolocation is service is enable for domain if not ask for it
    if (!("geolocation" in navigator)) {
      window.alert("Geolocation service is not supported by browser");
      return;
    }
    const getPermission = await navigator.permissions.query({ name: "geolocation" });
    if (getPermission.state === "denied") {
      window.alert("Please allow location access");
      return;
    }

    if (navigator.geolocation && mapsGeo) {
      navigator.geolocation.getCurrentPosition((position) => {
        // use geocode api to get formatted address from position
        const lat = position.coords.latitude;
        const lng = position.coords.longitude;
        // use mapsGeo
        const geocoder = new mapsGeo.Geocoder();
        const location = { location: { lat, lng } };

        geocoder.geocode(
          location,
          (results: google.maps.GeocoderResult[] | null, status: google.maps.GeocoderStatus) => {
            if (status === "OK" && results) {
              const currentLocation = {
                address: results[0].formatted_address ?? "",
                lat: results[0].geometry.location.lat(),
                lng: results[0].geometry.location.lng(),
                place_id: results[0].place_id,
              };
              setValue(`map_route.map_route_waypoints.${index}`, currentLocation);
            }
          },
        );
      });
    }
  }, [index, mapsGeo, setValue]);

  if (isDestroy === 1) {
    return null;
  }

  return (
    <Form.Group key={index} controlId={`destination${String.fromCharCode(65 + index)}`}>
      <Form.Label className={style.fw500}>Destination {String.fromCharCode(65 + index)}</Form.Label>
      <PlaceAutocomplete
        name={`map_route.map_route_waypoints.${index}`}
        onPlaceSelectCallback={onPlaceSelectCallback}
      />
      <Button
        variant="link"
        className={"mt-2 p-0 text-decoration-none " + style.placeLink}
        onClick={handleUserCurrentLocation}
      >
        <MdPlace className={"errorTxtColor " + style.placeIcon} /> Use Current Location
      </Button>
    </Form.Group>
  );
};

const WayPoints = () => {
  const { control, setValue, getValues } = useFormContext<ExpensesTypes.TMapRouteFormData>();

  const { fields, append, remove } = useFieldArray({
    name: "map_route.map_route_waypoints", // unique name for your Field Array
    control: control,
    keyName: "_id",
  });

  const validFields = expenseItemCommonSvc.getValidAttributes(fields);
  const disableAddDestination = validFields.length >= 5;
  const showDeleteButton = useMemo(() => {
    return validFields.length > 2;
  }, [validFields.length]);

  const addWayPoints = useCallback(() => {
    append({});
  }, [append]);

  const deleteRoute = useCallback(() => {
    const mapRouteWaypoints = getValues("map_route.map_route_waypoints");
    const destroyedRouteWaypoints = getValues("map_route.destroyed_map_route_waypoints") ?? [];
    if (Array.isArray(mapRouteWaypoints)) {
      const lastIndex = mapRouteWaypoints.length - 1;
      const lastMapRouteWaypoint = { ...getValues(`map_route.map_route_waypoints.${lastIndex}`) };
      lastMapRouteWaypoint._destroy = 1;
      setValue("map_route.destroyed_map_route_waypoints", [...destroyedRouteWaypoints, lastMapRouteWaypoint]);
      remove(lastIndex);
    }
  }, [getValues, remove, setValue]);

  // always keep two destination initialized;
  useEffect(() => {
    const mapRouteWaypoints = getValues("map_route.map_route_waypoints");
    if (!Array.isArray(mapRouteWaypoints) || (Array.isArray(mapRouteWaypoints) && mapRouteWaypoints.length === 0)) {
      setValue("map_route.map_route_waypoints", [{}, {}]);
      //any change to the array structure (like adding or removing fields) causes the component to re-render
    }
  }, [getValues, setValue]);

  const entering = useCallback(
    (e: any) =>
      commonService.customEntering(e, {
        width: "236px",
        maxWidth: "236px",
        padding: "16px",
        left: "200px",
        top: "50px",
      }),
    [],
  );

  return (
    <>
      <Row>
        <Col>
          {fields.map((field, index) => (
            <WayPoint key={field._id} index={index} />
          ))}
        </Col>
      </Row>

      <Row>
        <Col>
          {!disableAddDestination && (
            <Button
              disabled={disableAddDestination}
              className={style.addDestinationButton + " " + style.fw500}
              onClick={addWayPoints}
            >
              <IoMdAddCircle size={35} /> Add Destination
            </Button>
          )}

          {disableAddDestination && (
            <OverlayTrigger
              placement={"top"}
              onEntering={entering}
              overlay={
                <Tooltip className={style.expenseBarToolTip} id="button-tooltip-3">
                  Up to five destinations allowed.
                </Tooltip>
              }
            >
              <Button
                disabled={disableAddDestination}
                className={style.addDestinationButton + " " + style.fw500}
                onClick={addWayPoints}
              >
                <IoMdAddCircle size={35} /> Add Destination
              </Button>
            </OverlayTrigger>
          )}
        </Col>
        <Col>
          {showDeleteButton && (
            <Button className={style.deleteRoute + " " + style.fw500} onClick={deleteRoute}>
              <MdDelete color="#be1549" size={27} /> Remove Last
            </Button>
          )}
        </Col>
      </Row>
    </>
  );
};

type TFooterProps = {
  onCancel: () => void;
};
const Footer = ({ onCancel }: TFooterProps) => {
  const { control } = useFormContext<ExpensesTypes.TMapRouteFormData>();
  const wayPoints = useWatch({ name: "map_route.map_route_waypoints", control }) ?? [];
  const [distance, invalidDestinationPresent] =
    useWatch({ name: ["map_route.distance", "map_route.invalid_destination_present"], control }) ?? 0;

  // make sure not enabling save button until all the distention are filled; and distance is not zero
  // also if one the selected is invalid as path
  const enableSave =
    wayPoints.every((wayPoint) => wayPoint.lat && wayPoint.lng) && distance !== 0 && !invalidDestinationPresent;

  return (
    <div>
      <div className="d-flex align-items-center justify-content-between">
        <div className="mt-3">
          {invalidDestinationPresent && (
            <div className={style.errorMessage}>Mileage could not be calculated from selected destination(s)</div>
          )}
          <div>
            <p className={style.shortestDistanceText}>Calculation is based on the shortest route.</p>
          </div>
        </div>
        <div className="d-flex justify-content-end mt-3">
          <Button variant="secondary" onClick={onCancel} className="mr-2">
            Cancel
          </Button>
          <Button
            form="google-map-form"
            className={style.mapSaveButton}
            variant="primary"
            disabled={!enableSave}
            type="submit"
          >
            Save
          </Button>
        </div>
      </div>
    </div>
  );
};

const DistanceAndRoundTrip = memo(() => {
  const { setValue, getValues, control } = useFormContext();
  const [totalDistance, roundTrip, units] = useWatch({
    name: ["map_route.distance", "map_route.round_trip", "map_route.units"],
    control: control,
  });
  const oneWayDistance = roundTrip ? adminCommonSvc.roundUpNumber({ val: (totalDistance ?? 0) / 2 }) : totalDistance;

  const formattedDistance = (totalDistance: string | number) => {
    return Number(totalDistance) % 1 === 0 ? totalDistance : Number(totalDistance).toFixed(2);
  };

  const onChangeRoundTrip = useCallback(
    (e?: ChangeEvent<HTMLInputElement>) => {
      if (e?.target.checked) {
        const distance = getValues("map_route.distance") ?? 0;
        setValue("map_route.distance", distance * 2);
        setValue("map_route.round_trip", e?.target.checked);
      } else {
        const distance = getValues("map_route.distance") ?? 0;
        setValue("map_route.distance", distance / 2);
        setValue("map_route.round_trip", e?.target.checked);
      }
    },
    [getValues, setValue],
  );

  const onChangeUnitSystem = useCallback(
    (e?: ChangeEvent<HTMLInputElement>) => {
      // 1 mile = 1.609344 km
      if (e?.target?.value) {
        const distance = getValues("map_route.distance") ?? 0;
        if (e?.target.value === ExpensesTypes.MILAGE_UNITS.MILE) {
          setValue("map_route.units", e?.target.value);
          setValue("map_route.distance", adminCommonSvc.roundUpNumber({ val: distance / 1.609344 }));
        } else if (e?.target.value === ExpensesTypes.MILAGE_UNITS.KILOMETER) {
          setValue("map_route.units", e?.target.value);
          setValue("map_route.distance", adminCommonSvc.roundUpNumber({ val: distance * 1.609344 }));
        }
      }
    },
    [getValues, setValue],
  );

  return (
    <>
      <Row>
        <Col xs="6" sm="6" md="6">
          <div className={"largeFont " + style.distanceText}>Distance </div>
        </Col>
        <Col xs="6" md="6" sm="6" className="d-flex justify-content-end">
          <div className="d-flex align-items-center">
            <span className={"mr-3 largeFont primaryLinkColor " + style.distanceDigit}>
              {formattedDistance(totalDistance ?? 0)}
            </span>
          </div>
          <div>
            <Select
              id={"unit"}
              name="map_route.units"
              options={[
                { label: "mile", value: ExpensesTypes.MILAGE_UNITS.MILE },
                { label: "km", value: ExpensesTypes.MILAGE_UNITS.KILOMETER },
              ]}
              onChange={onChangeUnitSystem}
              selectClassName={style.unitSelect}
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group controlId="roundTrip">
            <Checkbox id="round_trip" label={"Round Trip"} name="map_route.round_trip" onChange={onChangeRoundTrip} />
          </Form.Group>
        </Col>

        {roundTrip && (
          <Col xs="8" sm="8" md="8" className={style.oneWayText + " d-flex justify-content-end "}>
            <div>
              ({oneWayDistance} {units === ExpensesTypes.MILAGE_UNITS.MILE ? <>mi</> : <>km</>} each way)
            </div>
          </Col>
        )}
      </Row>
    </>
  );
});

const MapView = () => {
  const { getValues, setValue } = useFormContext<ExpensesTypes.TMapRouteFormData>();
  const map = useMap();

  useEffect(() => {
    if (!map) return;

    const handleMapZoomChange = (ev: MapCameraChangedEvent) => {
      const wayPoints = getValues("map_route.map_route_waypoints") ?? [];
      const waypointsWithCoords = wayPoints.filter((waypoint) => waypoint?.lat && waypoint?.lng);
      const currentZoom = map.getZoom();
      const scheduleZoom16 = getValues("map_route.schedule_zoom_16");

      // for all other zoom change is handled by google map
      if (scheduleZoom16 && currentZoom !== 16 && waypointsWithCoords.length === 1) {
        map?.setZoom(16);
        setValue("map_route.schedule_zoom_16", false);
      }
    };

    map?.addListener("zoom_changed", handleMapZoomChange);
  }, [getValues, map, setValue]);

  return (
    <div
      id={expenseItemCommonSvc.MILEAGE_GOOGLE_MAP_VIEW_DIV_ID}
      style={{
        width: "346px",
        height: "381px",
      }}
    >
      <Map
        defaultCenter={{ lat: 37.5669373, lng: -122.326562 }}
        defaultZoom={9}
        mapId="MILAGE_MAP"
        fullscreenControl={false}
        clickableIcons={false}
        zoomControl={true}
        streetViewControl={false}
        mapTypeControl={false}
      >
        <PoiMarkers />
        <Directions />
      </Map>
    </div>
  );
};

type TMapRouteFormProps = {
  defaultValues: ExpensesTypes.TMapRouteFormData;
  onSubmit: (arg: ExpensesTypes.TMapRouteFormData) => void;
  onCancel: () => void;
};
const MapRouteForm = ({ defaultValues, onSubmit, onCancel }: TMapRouteFormProps) => {
  const methods = useForm<ExpensesTypes.TMapRouteFormData>({
    defaultValues,
  });

  // don't propogate  to main form.
  const stopPropagate = useCallback((callback: () => void) => {
    return (e: { stopPropagation: () => void; preventDefault: () => void }) => {
      e.stopPropagation();
      e.preventDefault();
      callback();
    };
  }, []);

  return (
    <FormProvider {...methods}>
      <Form id={"google-map-form"} onSubmit={stopPropagate(methods.handleSubmit(onSubmit))}>
        <APIProvider apiKey={env.googleMapAccessKey}>
          <Row className="mt-0">
            <Col sm={12} lg={6} className={style.leftContainer}>
              <DistanceAndRoundTrip />

              <Row>
                <Col>
                  <WayPoints />
                </Col>
              </Row>
            </Col>

            {/* Right Column: Map and Buttons */}
            <Col sm={12} lg={6} className={"d-flex justify-content-center " + style.rightContainer}>
              {/* Placeholder for map */}
              <MapView />
            </Col>
          </Row>

          <Footer onCancel={onCancel} />
        </APIProvider>
      </Form>
    </FormProvider>
  );
};

export default MapRouteForm;
