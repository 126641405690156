import { InputSelect } from "components/admin/pickers/inputs/inputSelect";
import React, { useCallback, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { change } from "redux-form";
import { ReportSvc } from "services/admin/reports/reportSvc";

type InputOptionType = { value: string; label: string };

const InputDropdownFilter = ({
  name,
  code,
  options,
  isRequired,
  isMulti = false,
}: {
  name: string;
  code: string;
  options: { [key: string]: any }[];
  isRequired?: boolean;
  isMulti?: boolean;
}) => {
  const dispatch = useDispatch();
  const [optionChosen, setOptionChosen] = useState<InputOptionType | InputOptionType[] | null>();

  const onOptionChosen = useCallback(
    (input) => {
      dispatch(
        change(
          ReportSvc.formName,
          `properties.filters.${code}`,
          isMulti ? input?.map((v: InputOptionType) => v.value) : input?.value,
        ),
      );
      setOptionChosen(input);
    },
    [code, dispatch, isMulti],
  );

  return (
    <Row className="mx-0">
      <Col className="px-0">
        <InputSelect
          options={options}
          label={name}
          labelClassName={`font-weight-bold`}
          placeholder={isMulti ? "Select" : "Select one"}
          isRequired={isRequired}
          onChange={onOptionChosen}
          value={optionChosen}
          isMulti={isMulti}
        />
      </Col>
    </Row>
  );
};

export default InputDropdownFilter;
