import { AxiosResponse } from "axios";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Form } from "react-bootstrap";
import { MenuPosition } from "react-select";
import AsyncSelect from "react-select/async";
import { WrappedFieldProps } from "redux-form";
import { restApiService } from "../../../../../providers/restApi";
import {
  findSelectedMultiple,
  findSelectedSingle,
  onBlur,
  onBlurSingle,
  onChange,
  onChangeSingle,
  parseForSelect,
} from "../../../../../services/general/helpers";
import {
  PickerComparisonField,
  pickerComparisonFieldStyle,
} from "../../../../common/pickers/reduxFormPickers/select/helpers";
import { Mandatory } from "../../../../forms/bootstrapFields";
import Styles from "../override.module.css";

interface ProjectPickerPropsType extends WrappedFieldProps {
  className?: string;
  labelClassName?: string;
  label?: string;
  isMulti: boolean;
  tooltip?: string;
  required: boolean;
  menuPosition?: MenuPosition;
  inputId?: string;
  placeholder?: string;
  disabled?: boolean;
  transparent?: boolean;
  originalValue?: any;
  hideRequiredStar?: boolean;
}

interface ProjectOptionsType {
  id: number;
  name: string | null;
  customer_external_id: string | null;
  department_id: number | null;
  location_id: number | null;
}

const ProjectPicker = ({
  className,
  labelClassName,
  label,
  input,
  meta: { touched, error },
  isMulti,
  tooltip,
  required,
  menuPosition,
  inputId,
  placeholder = "search/select",
  disabled,
  transparent,
  originalValue,
  hideRequiredStar,
}: ProjectPickerPropsType) => {
  const [projects, setProjects] = useState<ProjectOptionsType[]>([]);
  const [originalLabel, setOriginalLabel] = useState(null);

  const fetchProjects = useCallback(async (name: string) => {
    try {
      const result: AxiosResponse<any> = await restApiService.get(
        "projects.lk",
        { status: "ACTIVE", PROJECT_NAME: name },
        null,
        true,
        null,
        true,
      );
      if (result && result.data) {
        let newProjects: any = result.data.data;
        //if we have saved projects but they arent in the current request, fetch them
        if (input && input.value) {
          let missingProjects = isMulti ? input.value : [input.value];
          for (let x = 0; x < missingProjects.length; x++) {
            //only fetch projects not in this request and not already saved
            if (
              !newProjects.find((p: any) => p.id === missingProjects[x]) &&
              !projects.find((p: any) => p.value === missingProjects[x])
            ) {
              newProjects.push(await fetchSingleProject(missingProjects[x]));
            }
          }
        }

        //we save the projects we fetch for display/loading
        mergeProjects(parseForSelect(newProjects));
        return parseForSelect(newProjects);
      }
    } catch (error) {
      console.error("Error fetching projects:", error);
    }
    return [];
  }, []);

  //fetches a single project and merges it to projects, helpful when the saved project id is not currently in memory
  const fetchSingleProject = async (id: string) => {
    try {
      const result: AxiosResponse<any> = await restApiService.get(
        "projects.lk",
        { status: "ACTIVE", id },
        null,
        true,
        null,
        true,
      );
      if (result && result.data && result.data.data && result.data.data.length > 0) {
        return result.data.data[0];
      } else {
        return null;
      }
    } catch (error) {
      console.error("Error fetching projects:", error);
    }
    return [];
  };

  const mergeProjects = (newProjects: any[]) => {
    setProjects((prevProjects) => {
      const existingIds = new Set(prevProjects.map((project) => project.id));
      const uniqueNewProjects = newProjects.filter((project) => !existingIds.has(project.id));
      return [...prevProjects, ...uniqueNewProjects];
    });
  };

  const loadOptions = async (inputValue: string) => {
    return await fetchProjects(inputValue);
  };

  const getCustomConfig = useMemo(() => {
    let componentConfig: { [key: string]: any } = {};
    if (originalValue !== undefined) {
      componentConfig.Input = (props: any) => <PickerComparisonField {...props} originalValue={originalLabel} />;
    }
    if (transparent) {
      componentConfig.DropdownIndicator = () => null;
    }
    return componentConfig;
  }, [!!originalValue, originalLabel, transparent]);

  useEffect(() => {
    if (originalValue && projects.length > 0) {
      setOriginalLabel(findSelectedSingle({ value: originalValue }, projects)?.label);
    }
  }, [projects, originalValue]);

  return (
    <Form.Group className={Styles.select}>
      {label && (
        <Form.Label className={labelClassName}>
          {label ?? ""}
          <Mandatory required={required} />
          {tooltip ?? ""}
        </Form.Label>
      )}
      {!label && required && !hideRequiredStar && (
        <div className="d-flex justify-content-end">
          <Mandatory required={required} />
        </div>
      )}
      <AsyncSelect
        {...input}
        required={required}
        components={getCustomConfig}
        value={isMulti ? findSelectedMultiple(input, projects) : findSelectedSingle(input, projects)}
        placeholder={placeholder}
        onChange={isMulti ? (value) => onChange(input, value) : (value) => onChangeSingle(input, value)}
        onBlur={isMulti ? () => onBlur(input, input.value) : () => onBlurSingle(input, input.value)}
        loadOptions={loadOptions}
        defaultOptions
        isMulti={isMulti ? true : false}
        isClearable
        className={className}
        classNamePrefix="select"
        menuPosition={menuPosition}
        isDisabled={disabled}
        inputId={inputId ? inputId : "project-selector"}
        menuPortalTarget={document.querySelector("body")}
        styles={{
          control: (baseStyles, state) => ({
            ...baseStyles,
            ...(originalValue !== undefined ? pickerComparisonFieldStyle : {}),
            ...(transparent ? { backgroundColor: "transparent", border: "none" } : {}),
          }),
        }}
      />
      {error && touched && <div className="pickerError">{error}</div>}
    </Form.Group>
  );
};

export default ProjectPicker;
