import { useQuery } from "@tanstack/react-query";
import ContactPicker, { TContactOption } from "components/admin/pickers/reactHookFormPickers/contactPicker";
import { ContactTypeEnum } from "components/admin/pickers/reactHookFormPickers/contactPicker/contactPicker.types";
import moment from "moment";
import React, { KeyboardEvent, memo, useCallback, useMemo, useState } from "react";
import { Form, OverlayTrigger, Popover } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { BsQuestionCircle } from "react-icons/bs";
import { MdPerson } from "react-icons/md";
import InvoiceApis from "services/admin/invoices/invoiceApis";
import { TActivity } from "services/common/types/chat.types";
import { IUser } from "services/common/user/userTypes";
import { handleError } from "../invoiceDetailsService";
import styles from "./chat.module.css";

const DATE_FORMAT = "YYYY-MM-DD HH:mm:ss";
const QUERY_MESSAGES_KEY = "invoiceMessagesQuery";
const ACTIVITY_TYPE = "COMMENT";
const ACTIVITABLE_TYPE = "Invoice";

type TChatProps = {
  wrapped?: boolean;
  invoiceId: number;
  user: IUser;
};

type TChatMessage = {
  handle: string;
  handleAvatar: string | null;
  description: string;
  notifyContacts: string;
  createdAt: Date;
  isInternal: boolean;
};

const INTERNAL_VALUE = "internal";

const Chat: React.FC<TChatProps> = ({ invoiceId, user, wrapped = false }) => {
  const { t } = useTranslation();
  const { data: activities, refetch } = useQuery(
    [QUERY_MESSAGES_KEY, invoiceId],
    ({ signal }) => InvoiceApis.getActivitiesAsync(invoiceId, {}, signal),
    { enabled: !!invoiceId },
  );

  const chatMessages: TChatMessage[] = useMemo(() => {
    return (
      activities
        ?.filter((activity: any) => activity.activity_type === ACTIVITY_TYPE)
        .map((activity: any) => ({
          handle: activity.handle,
          handleAvatar: activity.handle_avatar || null,
          description: activity.description,
          notifyContacts: activity.notify_contacts?.map((c: any) => c.name).join(", ") || "",
          createdAt: activity.created_at,
          isInternal: !activity.public,
        })) || []
    );
  }, [activities]);

  const internalMessages = useMemo(() => chatMessages.filter((msg) => msg.isInternal), [chatMessages]);
  const vendorMessages = useMemo(() => chatMessages.filter((msg) => !msg.isInternal), [chatMessages]);
  const [selectedContacts, setSelectedContacts] = useState<TContactOption[]>([]);
  const [currentMessage, setCurrentMessage] = useState("");
  const [isInternalMessage, setIsInternalMessage] = useState(true);
  const [isMessageSending, setIsMessageSending] = useState(false);
  const userName = user.name;

  const popover = useMemo(
    () => (
      <Popover id="vendor-chat-popover">
        <Popover.Title as="h3">{t("admin.pages.invoice.chat.vendorMsgHeader")}</Popover.Title>
        <Popover.Content>{t("admin.pages.invoice.chat.vendorMsgBody")}</Popover.Content>
      </Popover>
    ),
    [t],
  );

  const handleTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsInternalMessage(event.target.value === INTERNAL_VALUE);
  };

  const canSendMessage = useMemo(
    () => !isMessageSending && currentMessage.trim().length > 0 && selectedContacts.length > 0,
    [isMessageSending, currentMessage, selectedContacts],
  );
  const renderedMessages = useMemo(() => {
    const messagesToDisplay = isInternalMessage ? internalMessages : vendorMessages;
    return messagesToDisplay.map((msg, index) => {
      const isMine = userName === msg.handle;
      const dateStyle = isMine ? styles.mineDate : styles.theirsDate;
      const displayedHandle = isMine ? t("admin.pages.invoice.chat.mineBubbleLabel") : msg.handle;
      return (
        <React.Fragment key={`${index}_${invoiceId}`}>
          <div className={`${styles.messageContainer} ${isMine ? styles.mine : styles.theirs} mt-3`}>
            {isMine ? (
              <>
                <div className={`${styles.messageBubble} ${isMine ? styles.mineBubble : styles.theirsBubble}`}>
                  <div className={styles.senderName}>{displayedHandle}</div>
                  <div className={styles.messageText}>{msg.description}</div>
                </div>
                {msg.handleAvatar ? (
                  <img
                    loading="lazy"
                    src={msg.handleAvatar}
                    alt={msg.handle}
                    className={`${styles.avatar} ${styles.avatarRight}`}
                  />
                ) : (
                  <div className={`${styles.avatarIcon} ${styles.avatarRight}`}>
                    <MdPerson size={32} />
                  </div>
                )}
              </>
            ) : (
              <>
                {msg.handleAvatar ? (
                  <img src={msg.handleAvatar} alt={msg.handle} className={styles.avatar} />
                ) : (
                  <div className={`${styles.avatarIcon} ${styles.avatarLeft}`}>
                    <MdPerson size={32} />
                  </div>
                )}
                <div className={`${styles.messageBubble} ${isMine ? styles.mineBubble : styles.theirsBubble}`}>
                  <div className={styles.senderName}>{displayedHandle}</div>
                  <div className={styles.messageText}>{msg.description}</div>
                </div>
              </>
            )}
          </div>
          <div className={dateStyle}>{moment(msg.createdAt).format(DATE_FORMAT)}</div>
          {isMine && (
            <div className={styles.messageNotified}>
              {t("admin.pages.invoice.chat.notifiedUsers")}: {msg.notifyContacts}
            </div>
          )}
        </React.Fragment>
      );
    });
  }, [isInternalMessage, internalMessages, vendorMessages, userName, invoiceId, t]);

  const chatSelectionMemo = useMemo(() => {
    return (
      <Form>
        <div className={styles.typeColumn}>
          <Form.Check
            type="radio"
            label={t("admin.pages.invoice.chat.internal")}
            name="typeSelection"
            value={INTERNAL_VALUE}
            checked={isInternalMessage}
            onChange={handleTypeChange}
            id="internal-radio"
          />
          <Form.Check
            type="radio"
            label={t("admin.pages.invoice.chat.vendor")}
            name="typeSelection"
            value="vendor"
            checked={!isInternalMessage}
            onChange={handleTypeChange}
            id="vendor-radio"
          />
          <div className={styles.vendorPopover}>
            <OverlayTrigger trigger={["hover", "focus"]} placement="left" overlay={popover}>
              <BsQuestionCircle className={styles.questionIcon} />
            </OverlayTrigger>
          </div>
        </div>
      </Form>
    );
  }, [isInternalMessage, popover, t]);

  const handlePostMessage = useCallback(
    async (message: TActivity) => {
      try {
        await InvoiceApis.postActivitiesAsync(message);
        setCurrentMessage("");
        refetch();
      } catch (error) {
        handleError(error, t, t("admin.pages.invoice.chat.errors.sending"));
      }
    },
    [refetch, t],
  );

  const handleContactSelection = useCallback((selected: TContactOption | TContactOption[] | null) => {
    if (Array.isArray(selected)) {
      setSelectedContacts(selected);
    } else if (selected) {
      setSelectedContacts([selected]);
    } else {
      setSelectedContacts([]);
    }
  }, []);

  const handleSendClick = useCallback(async () => {
    if (!selectedContacts.length || !currentMessage.trim()) return;
    setIsMessageSending(true);
    try {
      const newMessage: TActivity = {
        notify_contacts: selectedContacts.map((contact, index) => ({
          id: Date.now() + index,
          name: contact.name ?? "",
          email: contact.email ?? "",
          external_id: null,
          department_id: null,
          business_unit_id: null,
        })),
        public: !isInternalMessage,
        description: currentMessage,
        activity_type: ACTIVITY_TYPE,
        activitable_id: invoiceId,
        activitable_type: ACTIVITABLE_TYPE,
        created_at: new Date(),
        handle: userName,
      };
      await handlePostMessage(newMessage);
    } catch (error) {
      handleError(error, t, t("admin.pages.invoice.chat.errors.sending"));
    } finally {
      setIsMessageSending(false);
    }
  }, [currentMessage, handlePostMessage, invoiceId, isInternalMessage, selectedContacts, t, userName]);

  const handleKeyDown = useCallback(
    (e: KeyboardEvent<HTMLInputElement>) => {
      if (e.key === "Enter") {
        handleSendClick();
      }
    },
    [handleSendClick],
  );

  return (
    <div className={styles.chatContainer}>
      {wrapped && <div className={styles.wrappedChatType}>{chatSelectionMemo}</div>}
      <div className={styles.messageList}>{renderedMessages}</div>
      <div className={styles.optionsContainer}>
        <div className={styles.selectColumn}>
          <ContactPicker
            name="userContact"
            placeholder={t("admin.pages.invoice.chat.notifyUsers")}
            showEmail
            multiple
            activeOnly
            contactType={ContactTypeEnum.COMPANY}
            callBackFullObject={handleContactSelection}
          />
        </div>
        {!wrapped && chatSelectionMemo}
      </div>
      <div className={styles.inputContainer}>
        <input
          className={styles.inputField}
          placeholder={t("admin.pages.invoice.chat.type")}
          value={currentMessage}
          onChange={(e) => setCurrentMessage(e.target.value)}
          onKeyDown={handleKeyDown}
        />
        <button type="button" disabled={!canSendMessage} className={styles.sendButton} onClick={handleSendClick}>
          {isMessageSending ? t("admin.pages.invoice.chat.sending") : t("admin.pages.invoice.chat.send")}
        </button>
      </div>
    </div>
  );
};

export default memo(Chat);
