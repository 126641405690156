import useAdminCompanyCurrencyCode from "components/admin/hooks/useAdminCompanyCurrencyCode";
import ContactPicker from "components/admin/pickers/reduxFormPickers/contactPicker";
import PolicyPicker from "components/admin/pickers/reduxFormPickers/policyPicker";
import PurchaseOrderPicker from "components/admin/pickers/reduxFormPickers/purchaseOrderPicker";
import SubsidiaryPicker from "components/admin/pickers/reduxFormPickers/subsidiaryPicker";
import _ from "lodash";
import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useTypedSelector } from "reducers";
import { selectCurrentUser } from "reducers/userReducers";
import { change, Field } from "redux-form";
import expenseItemCommonSvc from "services/admin/expenses/expenseItems/expenseItemCommonSvc";
import { useGetCategories } from "services/admin/expenses/expensesApis";
import { ExpensesTypes } from "services/admin/expenses/expensesType";
import { PolicyTypes } from "services/admin/policy/policyTypes";
import { ContactTypes } from "services/common/contact/contactTypes";
import { IUser } from "services/common/user/userTypes";
import { required } from "services/validations/reduxFormValidation";
import ItemTypePicker from "../components/itemTypePicker";
import { useExpenseItemFormContext } from "./expenseItemFormSectionContext";
import { formExpenseItemFormData } from "./formExpenseItem";
import MapRouteButton from "./mapRoute/mapRouteButton";

const PolicyAndItemTypeSelection = () => {
  const dispatch = useDispatch();

  const { companyCurrencies } = useAdminCompanyCurrencyCode();
  const ctx = useExpenseItemFormContext();
  const index = ctx?.index;
  const currentUser: IUser = useTypedSelector(selectCurrentUser);
  const expenseFormData = useTypedSelector(formExpenseItemFormData(ctx?.formName ?? ""));
  const expenses = expenseFormData?.expenses;
  const expenseItem = expenses && ctx && expenses[ctx.index];

  const { data: categories } = useGetCategories();

  const updateExpenseItem = (updateExpenseItem: ExpensesTypes.ExpenseItemFormDataType) => {
    if (ctx) {
      dispatch(change(ctx.formName, ctx.sectionPrefix, updateExpenseItem));
    }
  };

  const destroyAllExpenseReportDebitAccountsAttr = (expenseItem: ExpensesTypes.ExpenseItemFormDataType) => {
    if (expenseItem.using_split && expenseItemCommonSvc.isSplitEnable(currentUser)) {
      let updatedExpenseItem = expenseItemCommonSvc.destroyAllExpenseReportDebitAccountAttr(expenseItem);
      //as destroy all expense ReportDebit Account to show allocations templates
      updatedExpenseItem.using_split = false;
      return updatedExpenseItem;
    } else {
      return expenseItem;
    }
  };

  const employeePickerCallback = async (contact: ContactTypes.ContactLKItem) => {
    if (expenses && ctx) {
      const expenseItem = await expenseItemCommonSvc.employeePickedCallback(
        contact.id,
        ctx?.index,
        expenses,
        companyCurrencies,
        currentUser,
        ctx.params,
      );
      expenseItem && destroyAllExpenseReportDebitAccountsAttr(expenseItem);
      dispatch(change(ctx.formName, ctx.sectionPrefix, expenseItem));
    }
  };

  const policyDisabled = function (expenseItem: ExpensesTypes.ExpenseItemFormDataType) {
    return expenseItem?.expense_report_id;
  };

  const mergePolicyResponse = async (policy?: PolicyTypes.PolicyLKItem) => {
    if (expenses && ctx && policy?.id) {
      const expenseItem = await expenseItemCommonSvc.mergePolicyResponse(
        policy,
        ctx.index,
        expenses,
        companyCurrencies,
        currentUser,
        ctx.params,
      );
      expenseItem && destroyAllExpenseReportDebitAccountsAttr(expenseItem);
      dispatch(change(ctx.formName, ctx.sectionPrefix, expenseItem));
    }
  };

  const getDefaultCategory = (itemType: string, index: number) => {
    if (expenses) {
      if (
        itemType === "MILEAGE" &&
        expenseItemCommonSvc.isCategoryExist(expenses[index]?.policy?.distance_default_category_id, categories ?? [])
      ) {
        expenses[index].category_id = expenses[index]?.policy?.distance_default_category_id;
      } else if (
        itemType === "PER_DIEM" &&
        expenseItemCommonSvc.isCategoryExist(expenses[index].policy?.per_diem_default_category_id, categories ?? [])
      ) {
        expenses[index].category_id = expenses[index]?.policy?.per_diem_default_category_id;
      } else {
        expenses[index].category_id = undefined;
      }
    }
  };

  const getItemTypeForm: any = (item: ExpensesTypes.SelectedTypeItem) => {
    if (!_.isUndefined(index) && index >= 0 && expenses && ctx) {
      if (item.value !== "SINGLE_EXPENSE") {
        // for only add form as edit form have disabled expense_item type drop down
        // we are currently using split and updated expense_item type to either
        if (expenseItemCommonSvc.isSplitEnable(currentUser)) {
          expenses[index].using_split = false;
          expenses[index] = destroyAllExpenseReportDebitAccountsAttr(expenses[index]);
        }
        expenses[index].vendor_name = "";
      }

      if (item.value !== "PER_DIEM" && expenses[index]?.per_diem_id) {
        expenses[index].per_diem_id = "";
        expenses[index].per_diem = null;
      }

      expenses[index].item_type = item.value;
      expenses[index].selected_type = item;
      expenses[index].current_tab = item.value.toLowerCase();
      if (expenses[index]?.item_type) {
        getDefaultCategory(expenses[index].item_type!, index);
      }
      if (expenses[index].item_type === "MILEAGE" && expenses[index]?.currency_code) {
        expenseItemCommonSvc.setDefaultDistanceUnit({
          currencyCode: expenses[index]?.currency_code!,
          index,
          expenses,
          currentUser,
        });
      }
      dispatch(change(ctx.formName, ctx.sectionPrefix, expenses[index]));
    }
  };

  const destroyItem = function (item: ExpensesTypes.ExpenseItemFormDataType) {
    if (expenses && ctx) {
      expenses.splice(expenses.indexOf(item), 1);
      dispatch(change(ctx.formName, "expenses", expenses));
    }
  };

  const loadPoPicker = () => {
    if (ctx?.params?.poID && expenses) {
      return _.isPlainObject(expenses[ctx.index]) && expenses[ctx.index].is_expense_call_from_po_detail_page;
    } else {
      return false;
    }
  };

  const onChangeSubsidiary = (subsidiary: any) => {
    let updatedExpenseItem = { ...expenseItem };
    updatedExpenseItem.subsidiary = subsidiary;
    updatedExpenseItem.subsidiary_id = subsidiary.id;
    destroyAllExpenseReportDebitAccountsAttr(updatedExpenseItem);
    updateExpenseItem(updatedExpenseItem);
  };

  const onPoChange = (purchaseOrder: Record<string, any> | null) => {
    let updatedExpenseItem = { ...expenseItem };
    if (purchaseOrder) {
      updatedExpenseItem.purchase_order = purchaseOrder;
      updatedExpenseItem.purchase_order_id = purchaseOrder.id;
    } else {
      updatedExpenseItem.purchase_order = null;
      updatedExpenseItem.purchase_order_id = null;
    }
    updateExpenseItem(updatedExpenseItem);
  };

  return (
    <Row>
      <Col md="12">
        <Row>
          <Col lg="6">
            <Field
              name="employee_id"
              component={ContactPicker}
              label="Employee"
              callBackObj={employeePickerCallback}
              isExpenseForm
              disabled={ctx?.expenseReport}
              validate={[required]}
              required
            />
          </Col>

          {currentUser.company.has_subsidiaries &&
            currentUser.company.expense?.show_subsidiary &&
            !expenseItem?.hide_subsidiary && (
              <Col lg="6">
                <Field
                  name="subsidiary_id"
                  component={(props: any) => (
                    <SubsidiaryPicker
                      {...props}
                      label="Subsidiary"
                      isExpenseForm
                      disabled={ctx?.expenseReport}
                      modelData={expenseItem}
                      modelName="expense_item"
                      callBack={onChangeSubsidiary}
                      validate={[required]}
                      required
                      formName={ctx?.formName}
                      formLocationStr={ctx?.sectionPrefix}
                    />
                  )}
                  validate={[required]}
                />
              </Col>
            )}

          {!expenseItem?.hide_subsidiary && (
            <Col lg="6">
              <Field
                name="policy_id"
                component={PolicyPicker}
                label="Policy"
                modelData={expenseItem}
                modelName="expense"
                disabled={(expenseItem && policyDisabled(expenseItem)) || ctx?.expenseReport}
                callBackObj={mergePolicyResponse}
                required
                validate={[required]}
              />
              {expenseItem?.policy?.convert_to_base_currency && expenseItem?.show_policy_conversion_message && (
                <span className="policy_currency_warning">
                  This policy uses {expenseItem?.policy.currency_code} your amount will be converted.
                </span>
              )}
              {expenseItemCommonSvc.canConvertToEmployeeCurrency(expenseItem) && (
                <span className="policy_currency_warning">
                  This policy will convert currency to the employee's currency, {expenseItem?.employee?.currency_code}.
                </span>
              )}
              {expenseItemCommonSvc.canConvertToSubsidiaryCurrency(expenseItem) && (
                <span className="policy_currency_warning">
                  This policy will convert currency to the employee's subsidiary currency,{" "}
                  {expenseItem?.employee?.subsidiary?.currency_code}.
                </span>
              )}
              {expenseItem?.policy?.convert_to_subsidiary_currency && !expenseItem?.employee?.subsidiary && (
                <span className="policy_currency_warning">
                  {" "}
                  This policy would convert currency to the employee's subsidiary currency, but since the selected
                  employee is not linked to a subsidiary, the policy's functional currency{" "}
                  {expenseItem?.policy.currency_code} will be used to convert the amount instead.{" "}
                </span>
              )}
              {expenseItem?.policy?.convert_to_employee_currency &&
                !expenseItem?.employee?.currency_code &&
                expenseItem?.employee?.subsidiary && (
                  <span className="policy_currency_warning">
                    This policy would convert currency to the employee's currency, but since the selected employee has
                    not chosen a currency code, the employee's subsidiary currency{" "}
                    {expenseItem?.employee?.subsidiary?.currency_code} will be used to convert the amount instead.
                  </span>
                )}
              {expenseItem?.policy?.convert_to_employee_currency &&
                !expenseItem?.employee?.currency_code &&
                !expenseItem?.employee?.subsidiary && (
                  <span className="policy_currency_warning">
                    This policy would convert currency to the employee's currency, but since the selected employee has
                    not chosen a currency code and is not associated with a subsidiary, the policy's functional currency{" "}
                    {expenseItem?.policy?.currency_code} will be used to convert the amount instead.
                  </span>
                )}
            </Col>
          )}

          {/* for item_type not milage don't need row */}
          {!expenseItem?.hide_item_type && expenseItem?.item_type !== "MILEAGE" && (
            <Col md={!expenseItem?.id && expenses?.length && expenses?.length > 1 ? "5" : "6"}>
              <Field
                label="Expense Item Type"
                name="selected_type"
                component={ItemTypePicker}
                callBackObj={getItemTypeForm}
                validate={[required]}
                options={expenseItem?.itemTypes}
                required
                disabled={expenseItem?.id || !expenseItem?.policy}
              />
            </Col>
          )}

          {!expenseItem?.id && expenses?.length && expenses?.length > 1 && (
            <Col md="1" className="d-flex align-items-center justify-content-center">
              <Form.Group>
                <button
                  type="button"
                  className="icon icon-delete btn-lg-delete mt-4 border-none"
                  onClick={() => expenseItem && destroyItem(expenseItem)}
                ></button>
              </Form.Group>
            </Col>
          )}
        </Row>

        {/* for item_type milage using new row to show dropdown and map button side by side */}
        {!expenseItem?.hide_item_type && expenseItem?.item_type === "MILEAGE" && (
          <Row>
            <Col md={"6"}>
              <Field
                label="Expense Item Type"
                name="selected_type"
                component={ItemTypePicker}
                callBackObj={getItemTypeForm}
                validate={[required]}
                options={expenseItem?.itemTypes}
                required
                disabled={expenseItem?.id || !expenseItem?.policy}
              />
            </Col>
            <Col md={"6"}>
              <MapRouteButton />
            </Col>
          </Row>
        )}

        {expenseItem?.policy_id && (
          <Row>
            {(currentUser.company.expense_item?.show_po_number || loadPoPicker()) && (
              <Col md="6" lg="6" ng-if="">
                <Field
                  label="PO Number"
                  name="purchase_order_id"
                  contactId={expenseItem.employee_id}
                  component={PurchaseOrderPicker}
                  modelData={expenseItem}
                  callBack={onPoChange}
                  isClearable
                />
              </Col>
            )}
          </Row>
        )}
      </Col>
    </Row>
  );
};

export default PolicyAndItemTypeSelection;
