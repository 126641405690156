import React, { useEffect, useMemo, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { fetchCompanyDetail } from "reducers/admin/companySlice";
import { saveDefaultOrder } from "services/common/gridService";
import RestApi from "../../../providers/restApi";
import {
  loadAddresses,
  removeDefaultFromAddress,
  setAddressableTypeId,
  setAddresses,
  setDefaultAddress,
} from "../../../reducers/common/addressSectionReducer";
import { CreateNotification, NotificationType } from "../../../services/general/notifications";
import ClientDataGrid from "../../common/dataGrid/clientDataGrid/clientDataGrid";
import ErrorBoundary from "../../common/errorBoundary/errorBoundary";
import Panel from "../../common/panel/panel";
import useConfirmModal from "../../modals/confirmModal/useConfirmModalHook";
import addressesHeader from "../dataGrid/dataGridHeaders/addressesHeaders";
import AddAddress from "./address/add";
import EditAddress from "./address/edit";

const restApiService = new RestApi();

const Addresses = ({ addressableId, addressableType, allowEdit, allowDelete }) => {
  const addresses = useSelector((state) => state.addressSectionRed.addresses);
  const gridStorageName = `ai.grid.${addressableType}`;
  const [isAddressFormOpen, setIsAddressFormOpen] = useState(false);
  const dispatch = useDispatch();
  const [editAddressId, setEditAddressId] = useState(null);
  const { t } = useTranslation();
  const { createConfirmModal } = useConfirmModal();
  const currentUser = useSelector((state) => state.user);

  useEffect(() => {
    dispatch(loadAddresses(addressableType, addressableId));
    dispatch(setAddressableTypeId({ addressableType, addressableId }));
  }, []);

  const onEditAddress = (addressId) => {
    setEditAddressId(addressId);
    setIsAddressFormOpen(true);
  };

  const onAddAddress = () => {
    setIsAddressFormOpen(true);
  };

  const deleteConfirmCallBack = async (address) => {
    try {
      await restApiService.delete("addresses/" + address.id);
      dispatch(setAddresses(addresses.filter((addr) => addr.id !== address.id)));
      CreateNotification(
        t("success"),
        t("components.admin.addressable.removeSuccess", { id: address.id }),
        NotificationType.success,
      );
      dispatch(loadAddresses(addressableType, addressableId));
      if (addressableType == "Company" && address?.address_type == "HQ") {
        dispatch(fetchCompanyDetail());
      }
    } catch (error) {
      console.log(error);
    }
  };

  const deleteAddress = (address) => {
    createConfirmModal({
      title: t("components.admin.addressable.delete"),
      body: t("components.admin.addressable.deleteConfirm", {
        id: address.id,
      }),
      callBackData: address,
      saveCallBack: deleteConfirmCallBack,
    });
  };

  const onSetDefaultAddressConfirm = (address) => {
    dispatch(setDefaultAddress(address));
  };

  const onSetAddressDefault = (address) => {
    createConfirmModal({
      title: t("components.admin.addressable.setDefault"),
      body: t("components.admin.addressable.setDefaultConfirmationMsg", {
        id: address.id,
      }),
      callBackData: address,
      saveCallBack: onSetDefaultAddressConfirm,
    });
  };

  const removeAddressDefaultConfirm = (address) => {
    dispatch(removeDefaultFromAddress(address));
  };

  const onRemoveAddressDefault = (address) => {
    createConfirmModal({
      title: t("components.admin.addressable.removeDefault"),
      body: t("components.admin.addressable.confirmRemoveMsg", {
        id: address.id,
      }),
      callBackData: address,
      saveCallBack: removeAddressDefaultConfirm,
    });
  };

  const cellRendererParams = {
    makePrimaryCallback: onSetAddressDefault,
    removePrimaryCallback: onRemoveAddressDefault,
    editCallback: onEditAddress,
    deleteCallback: deleteAddress,
    allowEdit,
    allowDelete,
    currentUser,
  };

  const gridHeaders = useMemo(() => {
    return addressesHeader(cellRendererParams);
  }, []);

  const closeCallback = () => {
    setEditAddressId(null);
  };

  useEffect(() => {
    saveDefaultOrder(gridStorageName, gridHeaders.defaultOrder);
  }, []);

  return (
    <ErrorBoundary>
      {isAddressFormOpen && !editAddressId && (
        <AddAddress
          addModalOpen={isAddressFormOpen}
          setAddModalOpen={setIsAddressFormOpen}
          closeCallback={closeCallback}
        />
      )}
      {isAddressFormOpen && editAddressId && (
        <EditAddress
          editModalOpen={isAddressFormOpen}
          setEditModalOpen={setIsAddressFormOpen}
          addressId={editAddressId}
          closeCallback={closeCallback}
        />
      )}

      <Row>
        <Col lg="12">
          <Panel
            header={
              <>
                <div className="d-flex justify-content-between">
                  <div className="align-self-center">
                    <i className="icon icon-location font-weight-bold" />
                    {t("components.admin.addressable.addresses")}
                  </div>
                  <Button variant="secondary" onClick={() => onAddAddress()}>
                    <i className="icon icon-plus-black"></i>
                    {t("components.admin.addressable.addAddress")}
                  </Button>
                </div>
              </>
            }
          >
            <Row>
              <Col>
                <ClientDataGrid
                  rowData={addresses}
                  defaultColDef={{
                    filter: "agTextColumnFilter",
                    floatingFilter: true,
                  }}
                  columnDefs={gridHeaders.columnDefs}
                  dataType={"addresses"}
                  domLayout="autoHeight"
                  pagination={true}
                  cacheBlockSize={50}
                  maxBlocksInCache={1}
                  paginationPageSize={50}
                  gridStorageName={gridStorageName}
                />
              </Col>
            </Row>
          </Panel>
        </Col>
      </Row>
    </ErrorBoundary>
  );
};

export default Addresses;
