import { AxiosResponse } from "axios";
import { restApiService } from "providers/restApi";
import { getListOptionsType } from "services/common/types/common.type";
import { DocumentRowResultType } from "./documentRowResult.types";

export class DocumentRowResultApis {
  static ENDPOINT = "document_row_results";
  static async list({ filter, cache }: getListOptionsType = {}) {
    try {
      const response: AxiosResponse<{ data: DocumentRowResultType[]; meta: { [key: string]: any } }> =
        await restApiService.get(this.ENDPOINT, filter, null, true, null, cache);
      return response.data; // paginated endpoint
    } catch (error) {
      throw error;
    }
  }

  static async counts({ document_id, filter, cache }: { document_id: number | string } & getListOptionsType) {
    try {
      const response: AxiosResponse<{ [key: string]: number }> = await restApiService.get(
        `${this.ENDPOINT}/counts`,
        { ...filter, document_id },
        null,
        true,
        null,
        cache,
      );
      return response.data; // paginated endpoint
    } catch (error) {
      throw error;
    }
  }

  static async get(id: string) {
    try {
      const response: AxiosResponse<DocumentRowResultType> = await restApiService.get(`${this.ENDPOINT}/${id}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
}
