import { useQuery } from "@tanstack/react-query";
import CurrencySymbolRenderer from "components/admin/commonUsed/currencySymbolRenderer";
import useConfirmModal from "components/modals/confirmModal/useConfirmModalHook";
import { angularBaseURL } from "providers/restApi";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Badge, Table } from "react-bootstrap";
import { useFieldArray, UseFieldArrayMove, useFormContext, useWatch } from "react-hook-form";
import { BsLink45Deg, BsPlusLg } from "react-icons/bs";
import { useTypedSelector } from "reducers";
import { CommonApis } from "services/admin/commonApis";
import { InvoiceType } from "services/admin/invoices/invoiceType";
import { useInvoiceCommonSvc } from "services/admin/invoices/useInvoiceCommonSvc";
import commonService from "services/common/commonSvc";
import { IUser } from "services/common/user/userTypes";
import { CreateNotification, NotificationType } from "services/general/notifications";
import { useInvoiceDetailsContext } from "../invoiceDetailsContext";
import { getReceiptsAsync, linkPoItemsAsync, unlinkPoItemsAsync } from "../invoiceDetailsService";
import { TMatchingData } from "../invoices.type";
import styles from "../materialDataTable.module.css";
import InvoiceItemLine from "./invoiceItemLine";

type TInvoiceItemLineSectionProps = {
  saveAsyncCallback: (notify: boolean, skipWorkflowTriggerApi?: boolean) => Promise<void>;
  allowSubmitWithGLErrors: boolean;
};

const emptyColumnValue = "--";

const goToInvoice = (invNumber: string) => {
  window.open(`${angularBaseURL}invoices/${invNumber}`, "_blank");
};

const InvoiceItemLineSection = ({ saveAsyncCallback, allowSubmitWithGLErrors }: TInvoiceItemLineSectionProps) => {
  const { control } = useFormContext<any>();
  const currentUser: IUser = useTypedSelector((state) => state.user);
  const { invoiceDetails, refetchInvoiceDetails, poItems } = useInvoiceDetailsContext();
  const [recommendedAccounts, setRecommendedAccounts] = useState<InvoiceType.recommendedAccounts>();
  const [isShowLinkRadios, setIsShowLinkRadios] = useState(false);
  const [selectedItem, setSelectedItem] = useState<any>(null);
  const { createConfirmModal } = useConfirmModal();
  const [draggingIndex, setDraggingIndex] = useState<number | null>(null);

  const matchingData: TMatchingData = useMemo(() => {
    return {
      evaluate: !currentUser.company.has_two_way_matching || currentUser.company.has_advance_two_way_matching,
      showPoMatching: currentUser.company.has_advance_two_way_matching,
      showReceiptMatching:
        currentUser.company.has_advance_two_way_matching && currentUser.company.has_three_way_matching,
    };
  }, [currentUser.company]);

  const totalsSpan = 3;
  const poColumnsCount = 10; //PO line#,	PO#,	unit,	qty,	unit price,	tax,	total, Qty Received	Qty Billed, Qty to Bill
  const receiptsColumnsCount = 2;

  const openLinkActions = useCallback((item: any) => {
    setIsShowLinkRadios(true);
    setSelectedItem(item);
  }, []);

  const closeLinkActions = useCallback((item: any) => {
    setIsShowLinkRadios(false);
    setSelectedItem(null);
  }, []);

  const { fields, append, update, move } = useFieldArray<InvoiceType.TInvoiceItem>({
    control,
    name: "invoice_items_attributes",
    keyName: "_id" as "id",
  });

  const invoiceItemsSorted = fields as unknown as InvoiceType.TInvoiceItem[];
  const canDrag = useMemo(() => invoiceItemsSorted?.length > 1, [invoiceItemsSorted]);
  const invoiceCommonSvc = useInvoiceCommonSvc();
  const vendorId = useWatch({ name: "vendor_id" });
  const currentPoItems = useWatch({ name: "invoice_purchase_order_links_attributes" });

  const currentPoIdsMemo = useMemo(() => {
    return currentPoItems?.filter((item: any) => item._destroy !== 1).map((item: any) => item.purchase_order_id);
  }, [currentPoItems]);

  const { data: receiptsData } = useQuery({
    queryKey: ["getPurchaseOrders", currentPoIdsMemo],
    queryFn: async ({ signal }) => await getReceiptsAsync(currentPoIdsMemo, signal),
    enabled: !!currentPoIdsMemo && currentPoIdsMemo.length > 0,
  });

  const getPoItemIndexKey = useCallback((poItem: any) => {
    return `${poItem.serial_number}_${poItem.po_number}`;
  }, []);

  const getFlatPoItemIndexKey = useCallback((poItem: any) => {
    return `${poItem.serialNumber}_${poItem.poNumber}`;
  }, []);

  const linkedIndexes = useMemo(() => {
    return invoiceItemsSorted
      .filter((item: any) => item.invoice_item_po_item_link_id)
      .map((item: any) => getPoItemIndexKey(item.po_item));
  }, [getPoItemIndexKey, invoiceItemsSorted]);

  const isItemLinked = useCallback(
    (poItem: any) => {
      const key = getFlatPoItemIndexKey(poItem);
      return linkedIndexes.includes(key);
    },
    [getFlatPoItemIndexKey, linkedIndexes],
  );

  const poItemsMemo = useMemo(() => {
    const flattened: any[] = [];
    const poItemIndexMap: { [key: number]: number } = {};

    poItems?.forEach((po: any) => {
      po.po_items.forEach((poItem: any) => {
        flattened.push({
          id: poItem.id,
          poNumber: po.number,
          poDate: po.date,
          description: poItem.product_name,
          department: poItem.department_name,
          businessUnit: poItem.business_unit_name,
          location: poItem.location_name,
          unitPrice: poItem.unit_price,
          qty: poItem.qty,
          qtyReceived: poItem.receipt_item_qty,
          amount: poItem.amount,
          tax: poItem.tax,
          total: poItem.total,
          serialNumber: poItem.serial_number,
        });
      });
    });

    const sortedItems = [...flattened].sort((a, b) => b.serialNumber - a.serialNumber);

    sortedItems.forEach((item, index) => {
      poItemIndexMap[item.id] = index + 1;
    });

    return sortedItems;
  }, [poItems]);

  const unlinkPoItem = useCallback(
    async (linkId: number) => {
      try {
        saveAsyncCallback(false, true);
        await unlinkPoItemsAsync(invoiceDetails?.invoice.id!, linkId);
        CreateNotification("Success", "Items unlinked successfully.", NotificationType.success);
        closeLinkActions(null);
        refetchInvoiceDetails();
      } catch (e) {
        CreateNotification("Error", "Error unlinking items.", NotificationType.danger);
      }
    },
    [closeLinkActions, invoiceDetails?.invoice.id, refetchInvoiceDetails, saveAsyncCallback],
  );

  const onUnlinkClick = useCallback(
    (id: number) => {
      createConfirmModal({
        title: "Confirm",
        body: "Are you sure, do you want to unlink?",
        callBackData: null,
        cancelCallBack: null,
        saveCallBack: () => unlinkPoItem(id),
      });
    },
    [createConfirmModal, unlinkPoItem],
  );

  const handleDropCallback = useCallback(
    (
      e: React.DragEvent<HTMLTableRowElement>,
      move: UseFieldArrayMove,
      index: number,
      draggingIndex: number | null,
      setDraggingIndex: React.Dispatch<React.SetStateAction<number | null>>,
    ) => {
      e.preventDefault();
      if (draggingIndex === null) return;
      const draggingFromItem = invoiceItemsSorted[draggingIndex];
      const draggingToItem = invoiceItemsSorted[index];

      if (!draggingFromItem || !draggingToItem) return;

      const clonedFromItem = { ...draggingFromItem };
      const clonedToItem = { ...draggingToItem };

      clonedFromItem.table_order = index;
      clonedToItem.table_order = draggingIndex;

      update(draggingIndex, clonedToItem);
      update(index, clonedFromItem);
    },
    [invoiceItemsSorted, update],
  );

  const { memoizedInvoiceItems } = useMemo(() => {
    const memoizedInvoiceItems = invoiceItemsSorted.map((item, index) => {
      return (
        <InvoiceItemLine
          receiptsData={receiptsData}
          key={`${item.id}_${index}`}
          item={item}
          selectedItem={selectedItem}
          index={index}
          update={update}
          append={append}
          linkPoItemCallback={openLinkActions}
          unlinkPoItemCallback={onUnlinkClick}
          showLinkActions={isShowLinkRadios}
          cancelLinkPoItemCallback={closeLinkActions}
          allowSubmitWithGLErrors={allowSubmitWithGLErrors}
          matchingData={matchingData}
          move={move}
          draggingIndex={draggingIndex}
          setDraggingIndex={setDraggingIndex}
          canDrag={canDrag}
          handleDropCallback={handleDropCallback}
        />
      );
    });

    return { memoizedInvoiceItems };
  }, [
    invoiceItemsSorted,
    receiptsData,
    selectedItem,
    update,
    append,
    openLinkActions,
    onUnlinkClick,
    isShowLinkRadios,
    closeLinkActions,
    allowSubmitWithGLErrors,
    matchingData,
    move,
    draggingIndex,
    canDrag,
  ]);

  // Calculate the number of columns dynamically
  const { dynamicColumnCount, poTotalSpan, invoiceTotalSpan, poBorder } = useMemo(() => {
    let columnCount = 8; // static columns
    let poTotalSpan = 9;
    let invoiceTotalSpan = 2;
    let poBorder = "";

    if (currentUser.company.has_taxes) {
      columnCount += 2; // Tax Code, Tax
      poTotalSpan += 1; // Tax
      invoiceTotalSpan += 1; // Tax
    }
    if (currentUser.company.has_units) {
      columnCount += 1; // Unit
      invoiceTotalSpan += 1; // Unit
    }
    if (currentUser.company.allow_edit_invoice_item_subtotal) {
      columnCount += 1; // Sub Amount
      invoiceTotalSpan += 1; // Sub Amount
    }
    if (currentUser.company.has_departments && !currentUser.company.invoice?.items?.department?.is_hide)
      columnCount += 1; // Department
    if (currentUser.company.has_locations && !currentUser.company.invoice_item_hide_location) columnCount += 1; // Location
    if (currentUser.company.has_business_units && !currentUser.company.invoice_item_hide_business_unit)
      columnCount += 1; // Business Unit
    if (currentUser.company.has_projects) {
      columnCount += 1; // Project
    }
    if (matchingData.showReceiptMatching) {
      columnCount += receiptsColumnsCount;
      poTotalSpan += receiptsColumnsCount;
    }
    if (matchingData.showPoMatching) columnCount += poColumnsCount;

    if (matchingData.showPoMatching && !matchingData.showReceiptMatching) {
      poBorder = styles.rightBorder;
    }
    return { dynamicColumnCount: columnCount, poTotalSpan, invoiceTotalSpan, poBorder };
  }, [
    currentUser.company.allow_edit_invoice_item_subtotal,
    currentUser.company.has_business_units,
    currentUser.company.has_departments,
    currentUser.company.has_locations,
    currentUser.company.has_projects,
    currentUser.company.has_taxes,
    currentUser.company.has_units,
    currentUser.company.invoice?.items?.department?.is_hide,
    currentUser.company.invoice_item_hide_business_unit,
    currentUser.company.invoice_item_hide_location,
    matchingData.showPoMatching,
    matchingData.showReceiptMatching,
  ]);

  const addNewRow = () => {
    let obj = { allowEdit: true, total: 0 };
    append(obj);
  };

  const removeAmortizationSchedule = (data: InvoiceType.TInvoiceItem) => {
    delete data.amortization;
    delete data.amortization_id;
    delete data.amortization;
    delete data.amortization_schedule_name;
    delete data.amortization_start_date;
    delete data.amortization_end_date;
  };

  const getRecommendedAccounts = useCallback(async () => {
    try {
      const response = await CommonApis.getVendorRecommendedAccounts(vendorId);
      setRecommendedAccounts(response);
    } catch (error) {
      commonService.handleError(error);
    }
  }, [vendorId]);

  useEffect(() => {
    if (vendorId) {
      getRecommendedAccounts();
    }
  }, [getRecommendedAccounts, vendorId]);

  const copyRecommendedAccounts = (invoiceItem: InvoiceType.TInvoiceItem) => {
    if (invoiceItem) {
      delete invoiceItem.id;
      removeAmortizationSchedule(invoiceItem);
      const newInvoiceItem = { ...invoiceItem };
      append(newInvoiceItem);
    }
  };

  const linkPoItem = useCallback(
    async (item: any) => {
      try {
        saveAsyncCallback(false, true);
        await linkPoItemsAsync(invoiceDetails?.invoice!, selectedItem, item?.id).then((res) => {
          const linked = res.linked;
          const result = res.result;
          if (!linked) return;

          if (result) CreateNotification("Success", "Items linked successfully.", NotificationType.success);
          closeLinkActions(null);
          refetchInvoiceDetails();
        });
      } catch (e) {
        CreateNotification("Error", "Error linking items.", NotificationType.danger);
      }
    },
    [closeLinkActions, invoiceDetails?.invoice, selectedItem, refetchInvoiceDetails, saveAsyncCallback],
  );

  const total = invoiceCommonSvc.getItemsTotal();

  return (
    <div className={styles.mainDiv}>
      {(recommendedAccounts?.invoice_items?.length ?? 0) > 0 && (
        <>
          <span className="mt-1 mb-2">
            <h6>Recommended Line Items</h6>
          </span>
          <div className={styles.mainTableWrapper}>
            <Table size="sm" className={`${styles.tableMaterial} ${styles.scrollableTable}`}>
              <thead>
                <tr>
                  <th>Actions</th>
                  <th>Source</th>
                  <th>Item</th>
                  <th>Unit Price</th>
                  <th>Account</th>
                  <th>Business Unit</th>
                  <th>Department</th>
                  <th>Location</th>
                  <th>Description</th>
                </tr>
              </thead>
              <tbody>
                {recommendedAccounts?.invoice_items?.map((invoiceItem, index) => {
                  return (
                    <tr key={`${invoiceItem.id}_${index}`}>
                      <td>
                        <span
                          role="button"
                          className={styles.copyLink}
                          onClick={() => copyRecommendedAccounts(invoiceItem)}
                        >
                          Add
                        </span>
                      </td>
                      <td>
                        <Badge
                          pill
                          className={styles.receiptLink}
                          variant="primary"
                          onClick={() => goToInvoice(`${invoiceItem.invoice?.id}`)}
                        >
                          {invoiceItem?.invoice?.number ?? emptyColumnValue}
                        </Badge>
                      </td>
                      <td>{invoiceItem.product_item?.name}</td>
                      <td>
                        <CurrencySymbolRenderer name="currency_code" amount={invoiceItem.unit_price ?? 0} />
                      </td>
                      <td>
                        {invoiceItem?.account
                          ? `${invoiceItem.account.number} - ${invoiceItem.account.name}`
                          : emptyColumnValue}
                      </td>
                      <td>{invoiceItem.business_unit?.name ?? emptyColumnValue}</td>
                      <td>{invoiceItem.department?.name ?? emptyColumnValue}</td>
                      <td>{invoiceItem.location?.name ?? emptyColumnValue}</td>
                      <td>{invoiceItem.description ?? emptyColumnValue}</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
        </>
      )}
      <div className={styles.mainTableWrapper}>
        <Table size="sm" className={`${styles.tableMaterial} ${styles.scrollableTable}`}>
          <thead>
            <tr>
              <th></th> {/* Drag handle column */}
              <th></th> {/* Row result evaluation column */}
              <th></th>
              {/* Purchase Order Information header */}
              {matchingData.showPoMatching && (
                <th colSpan={poColumnsCount} className={`${styles.poHeader} ${poBorder}`}>
                  Purchase Order
                </th>
              )}
              {/* Receipts header */}
              {matchingData.showReceiptMatching && (
                <th colSpan={2} className={styles.receiptsHeader}>
                  Receipts
                </th>
              )}
              {/* Invoice Information header */}
              <th className={styles.invoiceHeader} colSpan={14}>
                Invoice
              </th>
            </tr>
          </thead>
          <tr>
            <th></th>
            <th></th>
            <th></th>
            {/* 2 way Purchase Order Information columns */}
            {matchingData.showPoMatching && (
              <>
                <th className={styles.poColumnLeftHeader}>#</th>
                <th className={styles.poColumnHeader}>PO#</th>
                <th className={styles.poColumnHeader}>Item</th>
                <th className={styles.poColumnHeader}>Unit Price</th>
                <th className={styles.poColumnHeader}>Qty</th>
                <th className={styles.poColumnHeader}>Amount</th>
                {currentUser.company.has_taxes && <th className={styles.poColumnHeader}>Tax</th>}
                <th className={styles.poColumnHeader}>Total</th>
                <th className={styles.poColumnHeader}>Qty Billed</th>
                <th className={`${styles.poColumnHeader} ${poBorder}`}>Qty to Bill</th>
              </>
            )}
            {/* 3 way Receipts columns */}
            {matchingData.showReceiptMatching && (
              <>
                <th className={styles.receiptsColumnHeader}>Qty Received</th>
                <th className={styles.receiptsColumnRightHeader}>Receipt#</th>
              </>
            )}
            {/* Invoice Information columns */}
            <th className={styles.invoiceColumnLeftHeader}>Unit Price</th>
            <th>Qty</th>
            {currentUser.company.has_units && <th>Unit</th>}
            {currentUser.company.has_taxes && <th>Tax</th>}
            {currentUser.company.allow_edit_invoice_item_subtotal && <th>Sub Amount</th>}
            <th>Total</th>
            <th>Item</th>
            <th>Description</th>
            {currentUser.company.has_taxes && <th>Tax Code</th>}
            {/* Other info*/}
            <th>Account</th>
            <th>Department</th>
            <th>Location</th>
            <th>Business Unit</th>
            <th>Project</th>
          </tr>
          <tbody>
            {memoizedInvoiceItems}
            {invoiceItemsSorted.length < 1 && (
              <tr>
                <td colSpan={dynamicColumnCount}>
                  <span className={styles.noRecords}>Records not found!</span>
                </td>
              </tr>
            )}
          </tbody>
          {invoiceItemsSorted.length > 0 && (
            <tfoot>
              {matchingData.showPoMatching && (
                <tr>
                  <td colSpan={totalsSpan}></td>
                  {matchingData.showPoMatching && <td colSpan={poTotalSpan} className={styles.poFooter}></td>}
                  <td>Totals:</td>
                  <td colSpan={invoiceTotalSpan} className={styles.footerColumnRight}>
                    <CurrencySymbolRenderer name="currency_code" amount={total} />
                  </td>
                  <td colSpan={dynamicColumnCount - invoiceTotalSpan}></td>
                </tr>
              )}
              {!matchingData.showPoMatching && (
                <tr>
                  <td colSpan={dynamicColumnCount - 1}>Total</td>
                  <td>
                    <span>
                      <CurrencySymbolRenderer name="currency_code" amount={total} />
                    </span>
                  </td>
                </tr>
              )}
            </tfoot>
          )}
        </Table>
      </div>
      <span>
        <button className={styles.actionBtn} onClick={() => addNewRow()} type="button">
          <BsPlusLg className={styles.actionIcon} />
          Add Line Item
        </button>
      </span>
      {matchingData.showPoMatching && (
        <>
          <h5 className={`${styles.cardTitle} mt-4 mb-2`}>Outstanding PO Line Items</h5>
          <div className="overflow-auto custom-overflow">
            <Table className={styles.tableMaterial}>
              <thead>
                <tr>
                  <th>#</th>
                  <th>PO #</th>
                  <th>PO Issuance Date</th>
                  <th>Item Name</th>
                  <th>Department</th>
                  <th>Business Unit</th>
                  <th>Location</th>
                  <th>Unit Price</th>
                  <th>PO Qty</th>
                  <th>Qty Received</th>
                  <th>Amount</th>
                  <th>Tax</th>
                  <th>Total</th>
                </tr>
              </thead>
              <tbody>
                {poItemsMemo ? (
                  poItemsMemo?.map((item) => {
                    const isLinked = isItemLinked(item);
                    return (
                      <tr key={"poItems_" + getFlatPoItemIndexKey(item)}>
                        <td>
                          {isShowLinkRadios && !isLinked && (
                            <BsLink45Deg className={styles.operationIcon} size={18} onClick={() => linkPoItem(item)} />
                          )}
                          {isLinked && (
                            <Badge pill className={styles.badgeWithoutLink}>
                              {item.serialNumber}
                            </Badge>
                          )}
                          {!isLinked && item.serialNumber}
                        </td>
                        <td>{item.poNumber}</td>
                        <td>{item.poDate}</td>
                        <td>{item.description}</td>
                        <td>{item.department}</td>
                        <td>{item.businessUnit || emptyColumnValue}</td>
                        <td>{item.location || emptyColumnValue}</td>
                        <td>
                          <CurrencySymbolRenderer name="currency_code" amount={item.unitPrice} />
                        </td>
                        <td>{item.qty}</td>
                        <td>{item.qtyReceived}</td>
                        <td>
                          <CurrencySymbolRenderer name="currency_code" amount={item.amount} />
                        </td>
                        <td>
                          <CurrencySymbolRenderer name="currency_code" amount={item.tax} />
                        </td>
                        <td>
                          <CurrencySymbolRenderer name="currency_code" amount={item.total} />
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan={13} className={styles.noRecords}>
                      No records found.
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </div>
        </>
      )}
    </div>
  );
};

export default InvoiceItemLineSection;
