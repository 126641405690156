import useGetValue from "components/admin/hooks/hookFormHooks/useGetValue";
import PickerErrorBlock from "components/common/pickers/pickerErrorBlock";
import { Mandatory } from "components/forms/bootstrapFields";
import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import { Form } from "react-bootstrap";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { OnChangeValue } from "react-select";
import commonService from "services/common/commonSvc";
import ReactSelect from "../wrapperReactSelect/reactSelect";
import { AbstractListPickerTypes } from "./abstractOptionsPickerTypes";

const AbstractListPicker = <T extends { [key: string]: any }>({
  name,
  options,
  objectPropertyKey,
  valuePropertyName = "value",
  labelPropertyName = "label",
  label,
  required = false,
  disabled = false,
  onMenuOpen,
  onMenuClose,
  menuPosition = "fixed",
  menuPlacement = "bottom",
  placeholder = "Select an option",
  emptyOptionLabel = "-- Select --",
  validate,
  callBack,
}: AbstractListPickerTypes.IAbstractListPickerProps<T>) => {
  const { t } = useTranslation();
  const { setValue, getValues } = useFormContext();
  const [selected, setSelected] = useState<AbstractListPickerTypes.TPickerValue<T>>(null);

  const currentValue = useGetValue(name);

  const setPickerValue = useCallback(
    (selected: AbstractListPickerTypes.TPickerValue<T>) => {
      setSelected(selected ?? null);
      setValue(name, selected ? selected[valuePropertyName] : null);
      if (objectPropertyKey) setValue(objectPropertyKey, selected);
      if (callBack) {
        callBack(selected);
      }
    },
    [name, setValue, callBack, valuePropertyName],
  );

  const sortedOptions = useMemo(() => {
    let finalOptions = [...options];
    if (!required) {
      const emptyOption = {
        [valuePropertyName]: null,
        [labelPropertyName]: emptyOptionLabel,
      } as T;
      finalOptions = [emptyOption, ...finalOptions];
    }

    return finalOptions;
  }, [options, required, valuePropertyName, labelPropertyName, emptyOptionLabel]);

  const getOptionLabel = useCallback((option: T) => option[labelPropertyName], [labelPropertyName]);
  const getOptionValue = useCallback((option: T) => option?.[valuePropertyName] || "", [valuePropertyName]);

  const handleSelectChange = (selected: OnChangeValue<T, false>) => {
    setPickerValue(selected);
  };

  const isSelectedIsStale = useMemo(() => {
    let selectedIsStale = false;
    if ((selected && !currentValue) || (!selected && currentValue)) {
      selectedIsStale = true;
    } else if (selected && currentValue && currentValue !== selected.value) {
      selectedIsStale = true;
    }
    return selectedIsStale;
  }, [currentValue, selected]);

  const setDefaultValue = useCallback(async () => {
    if (isSelectedIsStale) {
      const selectedFound = options.find((option: any) => option[valuePropertyName] === currentValue);
      setPickerValue(selectedFound);
    }
  }, [currentValue, options, valuePropertyName, setPickerValue, isSelectedIsStale]);

  useEffect(() => {
    setDefaultValue();
  }, [name, options, valuePropertyName, setDefaultValue]);

  return (
    <Form.Group>
      {label && (
        <Form.Label>
          {label} {required && <Mandatory required={required} />}
        </Form.Label>
      )}

      <Controller
        name={name}
        rules={{ required: required ? t("validations.required") : undefined, validate }}
        render={({ field: { value, onChange }, fieldState: { error } }) => (
          <>
            <ReactSelect
              value={selected}
              options={sortedOptions}
              isClearable
              isDisabled={disabled}
              onChange={handleSelectChange}
              placeholder={placeholder}
              getOptionLabel={getOptionLabel}
              getOptionValue={getOptionValue}
              menuPosition={menuPosition}
              menuPlacement={menuPlacement}
              onMenuOpen={onMenuOpen}
              onMenuClose={onMenuClose}
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  ...commonService.getInvalidStyle(Boolean(error?.message)),
                }),
              }}
            />

            <PickerErrorBlock error={error?.message || ""} />
          </>
        )}
      />
    </Form.Group>
  );
};

export default memo(AbstractListPicker) as typeof AbstractListPicker;
