import { ColDef } from "ag-grid-community";
import { sortBy } from "lodash";
import React from "react";
import { setAllowedFilters } from "services/common/gridService";
import { FieldFilterMapType } from "services/common/types/grid.type";
import { translate } from "services/general/translation";

export const FIELD_NAME_MAP: FieldFilterMapType = {
  row_number: {
    inRange: "",
    greaterThanOrEqual: "row_number_greater",
    lessThanOrEqual: "row_number_lesser",
    equals: "row_number",
  },
  entity_number: {
    contains: "entity_number_contains",
    equals: "entity_number",
  },
  message: {
    contains: "message_contains",
    equals: "message",
  },
};

export const fileItemsHeaders = (): { columnDefs: ColDef[]; defaultOrder: string[] } => {
  const columnDefs = [
    {
      field: "row_number",
      filter: "agNumberColumnFilter",
      filterParams: {
        suppressAndOrCondition: true,
      },
      headerName: "Row #",
      sortable: true,
      flex: 1,
      headerValueGetter: function () {
        return translate("row_number");
      },
    },
    // {
    //   field: "result_type",
    //   filter: "agTextColumnFilter",
    //   headerName: "Result Type",
    //   sortable: true,
    //   flex: 1,
    //   headerValueGetter: function () {
    //     return translate("result_type");
    //   },
    // },
    {
      field: "entity_number",
      filter: "agTextColumnFilter",
      headerName: "Record Identifier",
      sortable: true,
      flex: 2,
      headerValueGetter: function () {
        return translate("record_identifier");
      },
    },
    {
      field: "message",
      filter: "agTextColumnFilter",
      headerName: "Alerts",
      sortable: true,
      flex: 6,
      headerValueGetter: function () {
        return translate("exceptions");
      },
    },
    {
      field: "ZActions",
      headerName: "Actions",
      flex: 1,
      sortable: false,
      filter: false,
      cellRenderer: (params: any) => {
        return params.data?.action ? (
          <a
            target="_blank"
            href={params.data.action}
            rel="noopener noreferrer"
            className="actionButtons icon-view mb-1"
            title="View created record"
          />
        ) : null;
      },
      headerValueGetter: function () {
        return translate("actions");
      },
    },
  ];
  const defaultOrder = columnDefs.map((col: any) => col.field);
  setAllowedFilters(columnDefs, FIELD_NAME_MAP);
  return {
    columnDefs: sortBy(columnDefs, (col) => typeof col?.headerValueGetter === "function" && col.headerValueGetter()),
    defaultOrder,
  };
};
