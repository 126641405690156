import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import companyApis from "services/common/company/companyApis";
import { CompanyDetailType } from "services/common/company/companyTypes";

export const fetchCompanyDetail = createAsyncThunk("companyDetail", async () => {
  try {
    const response = await companyApis.getCompanyDetail();
    return response;
  } catch (err) {
    console.log(err);
  }
});

const initialState: { companyDetail?: CompanyDetailType } = {};

const companyLandingSlice = createSlice({
  name: "companyLanding",
  initialState,
  reducers: {
    reset: () => initialState,
    setCompany: (state, action: PayloadAction<CompanyDetailType>) => {
      state.companyDetail = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCompanyDetail.fulfilled, (state, action) => {
      state.companyDetail = action.payload;
    });
  },
});

export const { reset, setCompany } = companyLandingSlice.actions;

export default companyLandingSlice.reducer;
