import React from "react";
import InvoiceItemLineSection from "./subSections/itemLineSection";

type TInvoiceDetailsItemLineSectionProps = {
  saveAsyncCallback: (notify: boolean, skipWorkflowTriggerApi?: boolean) => Promise<void>;
  allowSubmitWithGLErrors: boolean;
};

const InvoiceDetailsItemLineSection = ({
  saveAsyncCallback,
  allowSubmitWithGLErrors,
}: TInvoiceDetailsItemLineSectionProps) => {
  return (
    <InvoiceItemLineSection saveAsyncCallback={saveAsyncCallback} allowSubmitWithGLErrors={allowSubmitWithGLErrors} />
  );
};

export default InvoiceDetailsItemLineSection;
