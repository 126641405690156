import { GridApi } from "ag-grid-community";
import { FileUploaderNonModal } from "components/common/fileUploader/fileUploaderNonModal";
import useIsMounted from "components/common/hooks/useIsMounted";
import { RenderSelect } from "components/forms/bootstrapFields";
import { sortBy } from "lodash";
import RestApi from "providers/restApi";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Col, Container, Form, Modal, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { connect, shallowEqual, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { useTypedSelector } from "reducers";
import { change, Field, getFormValues, InjectedFormProps, reduxForm } from "redux-form";
import { capitalizeEachWord, extractFileFromURI } from "services/general/helpers";
import { CreateNotification, NotificationType } from "services/general/notifications";
import { FileType, WombatDestinationTypes, WombatType } from "wombatifier/services/wombat.types";
import { WombatApis } from "wombatifier/services/wombatApis";
import { Loadable } from "../general/loadable";
import "./bulkUploadModal.css";
import { TemplateLink } from "./bulkUploadTemplateLink";

interface FormData {
  wombat_id: string;
  uploadedFile: File | null;
}

const FORM_NAME = "bulkUploadForm";

interface ModalActionsType {
  open?: () => void;
  close?: () => void;
}
interface BulkUploadFilesModalPropsType {
  modalActionsRef: React.RefObject<ModalActionsType>;
  gridApiRef: React.RefObject<GridApi | undefined>;
  destinationType?: WombatDestinationTypes;
}

type BulkUploadInjectedPropsType = InjectedFormProps<FormData, BulkUploadFilesModalPropsType> &
  BulkUploadFilesModalPropsType;

const BulkUploadFilesModal = ({
  modalActionsRef,
  gridApiRef,
  destinationType,
  handleSubmit,
}: BulkUploadInjectedPropsType) => {
  const isMounted = useIsMounted();
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [documentTypeOptions, setDocumentTypeOptions] = useState<{ [key: string]: string }[]>([]);
  const [isShowDefaultWombatChecked, setIsShowDefaultWombatChecked] = useState(false);
  const form: any = useTypedSelector((state) => getFormValues(FORM_NAME)(state), shallowEqual);
  const currentUser = useTypedSelector((state) => state.user, shallowEqual);
  const wombatsHashRef = useRef<{ [key: string]: WombatType }>({});
  const currentWombat: WombatType | undefined = wombatsHashRef.current[form?.wombat_id];

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const removeAttachment = () => dispatch(change(FORM_NAME, "uploadedFile", null));
  const uploadAttachments = async (files: FileType[]) => {
    if (files.length > 0) {
      const file_attributes = files[0];
      const file = await extractFileFromURI(file_attributes.uri, file_attributes.name);
      dispatch(change(FORM_NAME, "uploadedFile", file));
    } else {
      removeAttachment();
    }
  };

  const { isLoading, setIsLoading, Loader } = Loadable();

  const getDocumentTypeOptions = useCallback(
    async (showDefaultWombats: boolean) => {
      let wombats: WombatType[] = [];
      try {
        const wombatResults = await WombatApis.inbounds({
          filter: {},
          cache: false,
        });
        if (wombatResults) {
          wombats = wombatResults;
        }
      } catch (err) {
        CreateNotification(
          t("admin.pages.bulkUploads.errorFileTypeFetch"),
          t("admin.pages.bulkUploads.errorCausedBy", { error: err }),
          NotificationType.danger,
        );
      }
      wombatsHashRef.current = wombats.reduce(
        (hash, wombat) => {
          hash[wombat.id.toString()] = wombat;
          return hash;
        },
        {} as { [key: string]: WombatType },
      );

      const optionList: { [key: string]: string }[] = [];
      wombats.forEach((wombat) => {
        const isGlobal = !wombat.company_id;
        let label: string;
        if (isGlobal && showDefaultWombats) {
          label = `STANDARD ${capitalizeEachWord(wombat.destination_type)}`;
          optionList.push({ value: wombat.id.toString(), label: label });
        } else if (!isGlobal) {
          label = wombat.name;
          optionList.push({ value: wombat.id.toString(), label: label });
        }
      });

      const options = [{ value: "", label: "Select file type" }, ...sortBy(optionList, (item) => item.label)];
      return options;
    },
    [t],
  );

  const handleShowDefaultWombatsCheckboxChange = useCallback(() => {
    setIsShowDefaultWombatChecked((prev) => !prev);
  }, []);

  const onSubmit = useCallback(
    async (values: FormData) => {
      setIsLoading(true);
      if (!values.uploadedFile) {
        setIsLoading(false);
        return;
      }

      const restApiService = new RestApi();
      try {
        const wombat = wombatsHashRef.current[values.wombat_id];
        const newDocument = await restApiService.post("/documents", null, {
          document: {
            name: values.uploadedFile.name,
            documentable_type: "Company",
            documentable_id: currentUser.company.id,
            document_type: wombat.source_type,
            status: "NEW",
            wombat_id: wombat.id,
          },
        });

        if (newDocument) {
          const formData = new FormData();
          formData.append("document[assets_attributes[0]asset_file]", values.uploadedFile);
          formData.append("document[assets_attributes[0]file_name]", values.uploadedFile.name);

          const uploadRes = await restApiService.formWithImage("documents/" + newDocument.data.id, formData, "patch");
          if (uploadRes.data) {
            CreateNotification(
              t("admin.pages.bulkUploads.saved"),
              t("admin.pages.bulkUploads.savedMsg"),
              NotificationType.success,
            );
          }
          setIsLoading(false);
          setModalVisible(false);
          removeAttachment();
        } else {
          setIsLoading(false);
          removeAttachment();
        }
        gridApiRef.current?.refreshServerSide();
      } catch (error) {
        setIsLoading(false);
        CreateNotification(
          t("admin.pages.bulkUploads.error"),
          t("admin.pages.bulkUploads.errorMsg", { error }),
          NotificationType.danger,
        );
      }
    },
    [currentUser.company.id],
  );

  const handleDocumentTypeOptionsAfterMount = useCallback(async (includeDefault: boolean) => {
    const options = await getDocumentTypeOptions(includeDefault);
    setDocumentTypeOptions(options);
  }, []);

  const handleDocumentTypeOptionsOnMount = useCallback(async () => {
    const options = await getDocumentTypeOptions(true);
    // If the destination_type is in the query params (e.g. ?destination_type=user) and the documentTypeOptions are loaded
    // then select the first option that matches the destination_type and it's a standard templates (company_id is null)
    // also check the "Show Default Templates" checkbox to show the standard templates in the options
    // and remove the destination_type from the query params to avoid selecting the same option again
    if (destinationType && options.length) {
      let option: WombatType | undefined;
      if (currentUser?.company?.is_paymerang || currentUser?.company?.has_osmos) {
        option = Object.values(wombatsHashRef.current).find(
          (option) => option.destination_type === destinationType && option.company_id,
        );
        setIsShowDefaultWombatChecked(false);
      }
      if (!option) {
        option = Object.values(wombatsHashRef.current).find(
          (option) => option.destination_type === destinationType && option.company_id,
        );
        setIsShowDefaultWombatChecked(false);
      }
      if (!option) {
        option = Object.values(wombatsHashRef.current).find(
          (option) => option.destination_type === destinationType && !option.company_id,
        );
        setIsShowDefaultWombatChecked(true);
      }
      dispatch(change(FORM_NAME, "wombat_id", `${option?.id}`));
    }
  }, [dispatch]);

  useEffect(() => {
    if (isMounted) {
      handleDocumentTypeOptionsAfterMount(isShowDefaultWombatChecked);
    }
  }, [isShowDefaultWombatChecked, getDocumentTypeOptions]);

  useEffect(() => {
    handleDocumentTypeOptionsOnMount();
  }, []);

  useEffect(() => {
    if (modalActionsRef.current) {
      modalActionsRef.current.open = () => setModalVisible(true);
      modalActionsRef.current.close = () => setModalVisible(false);
    }
  }, []);

  return (
    <Modal show={modalVisible} contentClassName="file-upload-modal shadow">
      <Modal.Header className="py-2 px-4">
        <Row className="m-0 d-flex w-100 justify-content-between align-items-center">
          <Col className="modal-title">Upload File</Col>
          <Col className="px-0" style={{ flex: 0 }}>
            <button
              className="modal-close"
              title="Close"
              onClick={() => {
                setModalVisible(false);
                removeAttachment();
              }}
            >
              &times;
            </button>{" "}
          </Col>
        </Row>
      </Modal.Header>
      <Modal.Body className="py-2 px-4">
        <Container className="p-0 m-0">
          <Row className="m-0 pt-2">
            <Col className="px-0 d-flex align-items-center">
              <label className="form-label">
                <input
                  type="checkbox"
                  checked={isShowDefaultWombatChecked}
                  onChange={handleShowDefaultWombatsCheckboxChange}
                />
                {t("admin.pages.bulkUploads.showStandardTemplates")}
              </label>
            </Col>
          </Row>
          {documentTypeOptions.length ? (
            <Row className="m-0 pt-2">
              <Col className="px-0">
                <Row className="mx-0 d-flex justify-content-between">
                  <Col className="px-0" style={{ maxWidth: "fit-content" }}>
                    <Form.Label htmlFor="wombat_id" className="form-label-bold">
                      {t("admin.pages.bulkUploads.fileType")}
                    </Form.Label>
                  </Col>
                  <Col className="px-0" style={{ maxWidth: "fit-content" }}>
                    <Link className="add-file-type" to={`/ap/wombatifier/add`}>
                      {t("admin.pages.bulkUploads.addFileType")}
                    </Link>
                  </Col>
                </Row>
                <Field
                  name="wombat_id"
                  id="wombat_id"
                  className="m-0"
                  component={RenderSelect}
                  options={documentTypeOptions}
                />
              </Col>
            </Row>
          ) : null}
          <Row className="m-0 pt-4">
            <FileUploaderNonModal
              onFilesUpdated={uploadAttachments}
              accept={[".xlsx", ".csv", ".xml", ".txt"]}
              maxFiles={1}
              containerClassName="w-100 m-0"
              className="dropzone icon-upload-attachment"
              /*closeModalConfig = {{ Uncomment when needed after product decision
                  title: "Warning",
                  body: "Closing this window will stop the upload.",
                  callBackData: null,
                  cancelCallBack: null,
                  confirmButtonLabel: "Close anyways"}
                }*/
            />
          </Row>
          <Row className="m-0">
            <Col className="px-0 mt-4" style={{ maxWidth: "fit-content" }}>
              <TemplateLink currentWombat={currentWombat} />
            </Col>
          </Row>
          <Row className="m-0 py-2 justify-content-end">
            {isLoading ? (
              <Loader className="w-100 h-100 d-flex justify-content-end">
                <>Uploading...</>
              </Loader>
            ) : (
              <button
                type="button"
                className="btn btn-primary upload-button"
                disabled={isLoading || !form?.uploadedFile}
                onClick={handleSubmit(onSubmit)}
              >
                {t("admin.pages.bulkUploads.submit")}
              </button>
            )}
          </Row>
        </Container>
      </Modal.Body>
    </Modal>
  );
};

const mapStateToProps = (state: any) => ({ initialValues: state.bulkUploadForm });

const BulkUploadFormModal = connect(mapStateToProps)(
  reduxForm<FormData, BulkUploadFilesModalPropsType>({
    form: FORM_NAME,
    keepDirtyOnReinitialize: false,
    enableReinitialize: true,
    destroyOnUnmount: true,
  })(BulkUploadFilesModal),
);

export const BulkUploadFileFormModable = (destinationType: WombatDestinationTypes | undefined = undefined) => {
  const modalActionsRef = useRef<ModalActionsType>({ open: undefined, close: undefined });
  const gridApiRef = useRef<GridApi>();

  const openUploader = useCallback(() => {
    modalActionsRef?.current?.open && modalActionsRef.current.open();
  }, []);

  const closeUploader = useCallback(() => {
    modalActionsRef?.current?.close && modalActionsRef.current.close();
  }, []);

  const Uploader = useMemo(
    () => () => {
      return (
        <BulkUploadFormModal
          destinationType={destinationType}
          modalActionsRef={modalActionsRef}
          gridApiRef={gridApiRef}
        />
      );
    },
    [],
  );
  return { openUploader, closeUploader, Uploader, gridApiRef };
};
