import { AgGridReact } from "ag-grid-react";
import React, { memo, useCallback, useEffect, useLayoutEffect, useMemo, useRef } from "react";
import {
  ColumnConfig,
  SideBarConfig,
  getContextMenuItems,
  getPreviousFilterStates,
  saveState,
} from "../../../../services/common/gridService";
import childCellRouting from "../../../datagrid/childCellRouting";
import ChildMessageRenderer from "../../../datagrid/childMessageRenderer";
// import CustomStatsToolPanel from "../../../datagrid/customStatsToolPanel";
import {
  ColDef,
  GetContextMenuItemsParams,
  GridOptions,
  GridReadyEvent,
  PaginationChangedEvent,
  SelectionChangedEvent,
  SizeColumnsToContentStrategy,
  SizeColumnsToFitGridStrategy,
  SizeColumnsToFitProvidedWidthStrategy,
} from "ag-grid-community";
import "ag-grid-community/styles/ag-grid.css";
import useAlphabeticallySortedToolPanel from "components/common/hooks/useAlphabeticallySortedColumnToolPanel";
import FloatingFilterComponent from "../../../datagrid/floatingFilterComponent";
import RandomCellChildRouting from "../../../datagrid/randomCellChildRouting";
import CustomSelectFilter from "../customSelectFilter";
import CustomStatToolPanel from "../customStatToolPanel";
import { CustomStatusPaginationNavigation, CustomStatusPanelRowsStatus } from "../customStatusPanel";
import DateFilter from "../gridDateFilter";

type serverSideDataGridPropsType = {
  columnDefs: any[] | ColDef[];
  rowSelection?: "single" | "multiple";
  defaultColDef?: ColDef;
  gridStorageName?: string;
  gridReady: (params: GridReadyEvent) => void;
  pagination?: boolean;
  paginationSize?: number;
  onSelectionChanged?: (event: SelectionChangedEvent<any>) => void;
  domLayout?: "normal" | "autoHeight" | "print" | undefined;
  masterDetail?: boolean;
  detailRowAutoHeight?: boolean;
  detailCellRenderer?: any;
  getRowId?: (params: any) => any;
  paginationOptions?: number[];
  defaultPaginationOptions?: number[];
  onCellClicked?: (data: any) => void;
  onFirstDataRendered?: (params: any) => void;
  floatingFiltersHeight?: number;
  headerHeight?: number;
  onPaginationChanged?(event: PaginationChangedEvent): void;
  suppressContext?: boolean;
  hideSideBar?: boolean;
  gridOptions?: GridOptions;
  autoSizeStrategy?:
    | SizeColumnsToFitGridStrategy
    | SizeColumnsToFitProvidedWidthStrategy
    | SizeColumnsToContentStrategy;
  skipContextMenuCols?: string[];
  customComponents?: {
    [p: string]: any;
  };
  disableGetPreviousState?: boolean;
};

/* ------------------gridReady(params)---------------------------
In gridready function in parent component add custom data source function to getRows (params.api.setServerSideDatasource({ getRows: getRows }))
here Everytime the grid needs new rows (such as first load or scrolling) or filter params changes getRows function will fire
  -------------------------------------------------------------*/

//  dataType is necessary so that it has unique saved state in local storage

const ServerSideDataGrid = ({
  columnDefs,
  rowSelection,
  gridReady,
  onSelectionChanged,
  defaultColDef = {},
  gridStorageName = "gridFilter",
  pagination = false,
  masterDetail,
  detailRowAutoHeight,
  detailCellRenderer,
  domLayout,
  paginationOptions,
  getRowId = (params: any) => params?.data?.id,
  defaultPaginationOptions = [10, 25, 50, 100],
  paginationSize = 25,
  onCellClicked,
  onFirstDataRendered,
  floatingFiltersHeight,
  headerHeight,
  onPaginationChanged,
  suppressContext = false,
  hideSideBar = false,
  gridOptions,
  autoSizeStrategy,
  skipContextMenuCols,
  customComponents,
  disableGetPreviousState,
}: serverSideDataGridPropsType) => {
  const gridElementRef = useRef<HTMLDivElement>(null);
  const gridRef = useRef<AgGridReact>(null);
  const initialDataRendered = useRef<boolean>(false);

  // Sort columns alphabetically by field in toolpanel
  useAlphabeticallySortedToolPanel(columnDefs, gridRef);

  useEffect(() => {
    window.addEventListener("beforeunload", () => {
      saveState({ gridRef, gridStorageName });
    });

    return () => {
      // Anything in here is fired on component unmount.
      window.removeEventListener("beforeunload", () => {
        saveState({ gridRef, gridStorageName });
      });
    };
  }, []);

  const handlePreviousGridState = useCallback(() => {
    if (!disableGetPreviousState || initialDataRendered.current) {
      getPreviousFilterStates({ gridRef, gridStorageName });
    }
  }, []);

  const onGridReady = useCallback(
    (params: GridReadyEvent) => {
      handlePreviousGridState();
      //set Grid Api and grid column api on grid ready
      gridReady(params);
    },
    [gridReady, gridStorageName],
  );

  // for every render grid colState is getting reset
  // so setting grid previous state for every render
  // expensive but required
  useEffect(() => {
    handlePreviousGridState();
  });

  // using use layout effect
  // as it will save the function call synchronously
  useLayoutEffect(() => {
    return () => {
      saveState({ gridRef, gridStorageName });
    };
  }, []);

  const resizeColumnsToFit = () => {
    gridRef?.current?.api?.sizeColumnsToFit();
  };

  // keeping useEffect separate for every fix, that keeping concern code separate
  // fixing: columns are not covering all space in table
  useEffect(() => {
    // Resize the columns to fit the container size when the component mounts
    resizeColumnsToFit();
    // Resize the columns to fit the container size when the window is resized
    window.addEventListener("resize", resizeColumnsToFit);
    return () => {
      window.removeEventListener("resize", resizeColumnsToFit);
    };
  }, []);

  // suppressed default pagination panel and used status bar for pagination component
  const statusBar = useMemo(() => {
    return {
      statusPanels: [
        {
          statusPanel: CustomStatusPaginationNavigation,
          statusPanelParams: { paginationOptions: paginationOptions ? paginationOptions : defaultPaginationOptions },
          align: "left",
        },
        {
          statusPanel: CustomStatusPanelRowsStatus,
          align: "right",
        },
      ],
    };
  }, []);

  const saveGridState = useCallback(() => {
    saveState({ gridRef, gridStorageName });
  }, [gridStorageName]);

  const resizeAndSaveState = useCallback(() => {
    saveGridState();
    resizeColumnsToFit();
  }, [saveGridState]);

  const firstDataRendered = (params: any) => {
    if (onFirstDataRendered) {
      onFirstDataRendered(params);
    }
    initialDataRendered.current = true;
  };
  const contextMenu = useCallback((params: GetContextMenuItemsParams) => {
    return getContextMenuItems(params, skipContextMenuCols);
  }, []);

  return (
    <div
      className="ag-theme-fresh animated fadeIn"
      ref={gridElementRef}
      style={{
        width: "100%",
        height: "auto",
      }}
    >
      <AgGridReact
        onFirstDataRendered={firstDataRendered}
        ref={gridRef}
        columnDefs={columnDefs}
        onCellClicked={onCellClicked}
        rowSelection={rowSelection}
        onSelectionChanged={onSelectionChanged}
        getContextMenuItems={contextMenu}
        suppressRowClickSelection={true}
        rowModelType="serverSide"
        onGridReady={onGridReady}
        defaultColDef={{
          resizable: true,
          suppressMenu: true,
          filterParams: {
            suppressAndOrCondition: true,
          },
          ...defaultColDef,
        }}
        {...(gridOptions ? gridOptions : {})}
        suppressServerSideInfiniteScroll={false}
        rowHeight={ColumnConfig.rowHeight}
        headerHeight={headerHeight ?? ColumnConfig.headerHeight}
        floatingFiltersHeight={floatingFiltersHeight ?? ColumnConfig.floatingFiltersHeight}
        masterDetail={masterDetail}
        detailCellRenderer={detailCellRenderer}
        detailRowAutoHeight={detailRowAutoHeight}
        suppressContextMenu={suppressContext}
        components={{
          childMessageRenderer: ChildMessageRenderer,
          childCellRouting: childCellRouting,
          randomChildCellRouting: RandomCellChildRouting,
          customStatsToolPanel: CustomStatToolPanel,
          floatingFilterComponent: FloatingFilterComponent,
          agCustomSelectFilter: CustomSelectFilter,
          agDateInput: DateFilter,
          ...customComponents,
        }}
        // pagination related props are collected here
        suppressPaginationPanel={true}
        {...(pagination
          ? {
              pagination: pagination,
              paginationPageSize: paginationSize,
              cacheBlockSize: paginationSize,
              maxBlocksInCache: 1,
              paginationPageSizeSelector: paginationOptions ? paginationOptions : defaultPaginationOptions,
              statusBar: statusBar,
            }
          : {})}
        onPaginationChanged={onPaginationChanged}
        sideBar={hideSideBar ? null : SideBarConfig}
        // now height of the grid table must be deside form parent Row for ServiceSideDataGrid component.
        domLayout={domLayout}
        // to Save grid when filter change or column moved
        onSortChanged={saveGridState}
        onFilterChanged={saveGridState}
        onColumnMoved={saveGridState}
        onColumnResized={saveGridState}
        onColumnValueChanged={saveGridState}
        onColumnPinned={resizeAndSaveState}
        onColumnVisible={resizeAndSaveState}
        onToolPanelVisibleChanged={resizeAndSaveState}
        getRowId={getRowId}
        autoSizeStrategy={autoSizeStrategy}
      />
    </div>
  );
};

export default memo(ServerSideDataGrid);
