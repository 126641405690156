import React, { memo } from "react";
import { EmployeePickerTypes } from "./employeePickerTypes";
import SingleEmployeePicker from "./singleEmployeePicker";

const EmployeePicker = (props: EmployeePickerTypes.TEmployeePickerProps) => {
  return (
    <>
      {props.isMulti ? (
        // TODO: Implement Multi Employee Picker
        <></>
      ) : (
        <SingleEmployeePicker {...props} />
      )}
    </>
  );
};

export default memo(EmployeePicker);
