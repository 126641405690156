import { restApiService } from "providers/restApi";
import React, { memo, useCallback, useMemo } from "react";
import AbstractListPicker from "../abstractListPicker/index2";
import { SponsorshipPickerTypes } from "./sponsorshipPickerType";

const SponsorshipPicker = ({
  name,
  label,
  instanceId,
  required = false,
  modelDataName,
  objectPropertyKey,
}: SponsorshipPickerTypes.TSponsorshipPickerProps) => {
  const mergeInactive = useCallback(async (currentId, sponerships) => {
    let inactiveOption = null;
    if (currentId) {
      const sponership = Array.isArray(sponerships)
        ? sponerships.filter((sponership) => sponership.id === currentId)
        : [];

      if (sponership.length === 0) {
        try {
          const response = await restApiService.get(`sponsorships/${currentId}`);
          inactiveOption = response.data;
        } catch (error) {
          console.error(error);
        }
      } else {
        inactiveOption = sponership[0];
      }
    }
    return { inactiveOption };
  }, []);

  const params = useMemo(() => ({ status: "ACTIVE" }), []);

  return (
    <AbstractListPicker<SponsorshipPickerTypes.TSponsorshipPickerOption>
      name={name}
      label={label}
      required={required}
      instanceId={instanceId}
      params={params}
      objectPropertyKey={modelDataName ? `${modelDataName}.sponsorship` : null}
      mergeInactive={mergeInactive}
      fetchUrl="sponsorships"
    />
  );
};

export default memo(SponsorshipPicker);
