import React, { memo } from "react";
import AbstractListPicker from "../abstractListPicker/";
import styles from "./contactPicker.module.css";
import { ContactTypeEnum } from "./contactPicker.types";

export type TContactOption = {
  id?: string;
  name?: string;
  email?: string;
};

type TContactPickerProps = {
  id?: string;
  name: string;
  objectName?: string;
  required?: boolean;
  multiple?: boolean;
  activeOnly?: boolean;
  contactType: ContactTypeEnum;
  label?: string;
  showEmail?: boolean;
  placeholder?: string;
  callBackFullObject?: (selected: TContactOption | TContactOption[] | null) => void;
};

const ContactPicker = ({
  id,
  name,
  objectName,
  label,
  placeholder,
  required = false,
  multiple = false,
  activeOnly = true,
  showEmail = false,
  contactType,
  callBackFullObject,
}: TContactPickerProps) => {
  const contactTypeAsString = ContactTypeEnum[contactType];
  const params = { active: activeOnly, contact_type: contactTypeAsString };

  const formatOptionLabel = React.useCallback(
    (option: TContactOption) => {
      return (
        <div className={styles.optionContainer} title={option.email}>
          {option.name && <span className={styles.contactName}>{option.name}</span>}
          {showEmail && option.email && <span className={styles.contactEmail}>{option.email}</span>}
        </div>
      );
    },
    [showEmail],
  );

  return (
    <AbstractListPicker<TContactOption>
      id={id}
      name={name}
      objectName={objectName}
      label={label}
      required={required}
      multiple={multiple}
      params={params}
      fetchUrl="contacts.lk"
      formatOptionLabel={formatOptionLabel}
      showInactiveStatus
      callBackFullObject={callBackFullObject}
      placeholder={placeholder}
    />
  );
};

export default memo(ContactPicker);
