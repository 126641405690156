import AttachmentCarousel from "components/common/attachmentCarousel/attachmentCarousel";
import useConfirmModal from "components/modals/confirmModal/useConfirmModalHook";
import _ from "lodash";
import React, { useEffect, useState, useMemo } from "react";
import { Col, Container, Row, Spinner, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { useTypedSelector } from "reducers";
import { selectAppCurrecyCode } from "reducers/common/appCommonStatesSlice";
import { selectCurrentUser } from "reducers/userReducers";
import { arrayPush, change } from "redux-form";
import expenseItemCommonSvc from "services/admin/expenses/expenseItems/expenseItemCommonSvc";
import { ExpenseReportTypes } from "services/admin/expenses/expenseReport/expenseReportType";
import ExpensesApis from "services/admin/expenses/expensesApis";
import { ExpensesTypes } from "services/admin/expenses/expensesType";
import expenseService from "services/admin/expenses/expenseSvc";
import ReceiptsApis from "services/admin/expenses/receipts/receiptsApis";
import PolicyApis from "services/admin/policy/policyApis";
import { Can } from "services/authorization/authorization";
import commonService from "services/common/commonSvc";
import { IAttachment, IDType } from "services/common/types/common.type";
import { IUser } from "services/common/user/userTypes";
import ExpenseAllocations from "./expenseAllocations";
import style from "./expenseItem.module.css";
import { ExpenseItemFormContext } from "./expenseItemFormSectionContext";
import { formExpenseItemFormData } from "./formExpenseItem";
import MileageForm from "./mileageForm";
import PerDiemForm from "./perDiemForm";
import PolicyAndItemTypeSelection from "./policyAndItemTypeSelection";
import SingleExpenseForm from "./singleExpenseForm";
import CustomModal from "components/modals/customModal";
import ExpenseReceiptList from "./expenseReceiptList";
import { ReceiptsProvider } from "./receiptListContext";

type AddExpenseItemFormSectionType = {
  form: string;
  sectionPrefix?: string;
  expenseReport: ExpenseReportTypes.Details | null;
  index: number;
};

const AddExpenseItemFormSection = ({ form, sectionPrefix, index, ...restProps }: AddExpenseItemFormSectionType) => {
  const currentExpenseItemFormLocationString = sectionPrefix ?? ""; //<- referring to current expenseItem
  // const expenseItem = useFormValue<ExpensesTypes.ExpenseItemFormDataType | null | undefined>(form, sectionPrefix);
  const expenseFormData = useTypedSelector(formExpenseItemFormData(form));
  const expenses = expenseFormData?.expenses;
  const expenseItem = expenses && expenses[index];
  const currentUser: IUser = useTypedSelector(selectCurrentUser);
  const dispatch = useDispatch();
  const appCurrencies = useTypedSelector(selectAppCurrecyCode);
  const { createConfirmModal } = useConfirmModal();
  const { t } = useTranslation();
  const translate = (key: string) => t(`admin.pages.expenseItemDetails.${key}`);
  const expenseReport = restProps.expenseReport;
  const location = useLocation();
  const history = useHistory();
  const searchParams = new URLSearchParams(location.search);
  const receiptID = searchParams.get("receiptID");
  const params: Record<string, any> = {};
  params.receiptID = searchParams.get("receiptID");
  params.poID = searchParams.get("poID");
  const [routeMapModalOpen, setRouteMapModalOpen] = useState<boolean>(false);
  const [showReceiptList, setShowReceiptList] = useState<boolean>(false);

  function onDeleteConfirm(selectedAttachment: IAttachment): void {
    const updatedAssetsAttributes =
      Array.isArray(expenseItem?.assets_attributes) &&
      expenseItem?.assets_attributes?.map((assetsAttributes) => {
        if (assetsAttributes.id && assetsAttributes.id === selectedAttachment.id) {
          // if files are already uploaded
          assetsAttributes._destroy = 1;
        } else if (assetsAttributes.url === selectedAttachment.url) {
          // if files are on local
          assetsAttributes._destroy = 1;
        }
        return assetsAttributes;
      });

    if (updatedAssetsAttributes) {
      dispatch(change(form, currentExpenseItemFormLocationString + ".assets_attributes", updatedAssetsAttributes));
    }
  }

  function onAttachmentUpload(files: File[]): void {
    if (Array.isArray(files) && files.length > 0) {
      const assetsAttributes = files.map((file) => {
        const assetFile = {
          asset_file: file,
          asset_file_file_name: file.name,
          url: URL.createObjectURL(file),
          asset_expiring_url: URL.createObjectURL(file),
        };
        dispatch(arrayPush(form, currentExpenseItemFormLocationString + ".assets_attributes", assetFile));
        return assetFile;
      });
    }
  }

  const getSelectedPolicy = async (
    policy_id: IDType,
    index: number,
    expensesCopy: ExpensesTypes.ExpenseItemFormDataType[],
  ) => {
    try {
      if (policy_id && expensesCopy) {
        const policy = await PolicyApis.getPolicy({ policyId: policy_id, cache: true });
        const expenseItem = await expenseItemCommonSvc.mergePolicyResponse(
          policy,
          index,
          expensesCopy,
          appCurrencies,
          currentUser,
          params,
        );
        if (expenseItem) {
          expensesCopy[index] = expenseItem;
        }
        // dispatch(change(form, currentExpenseItemFormLocationString, expenseItem));
      }
    } catch (error) {
      commonService.handleError(error);
    }
  };

  const setBankCardTransaction = (
    transaction: any,
    index: number,
    expensesCopy: ExpensesTypes.ExpenseItemFormDataType[],
  ) => {
    if (expensesCopy) {
      expensesCopy[index].vendor_name = transaction.vendor_name;
      expensesCopy[index].total = transaction.payment_gross_amount;
      expensesCopy[index].amount = transaction.payment_gross_amount;
      expensesCopy[index].date = transaction.transaction_date;

      //we need to manually set here as the backend cant make a decision to do this or not
      if (currentUser.company.auto_create_receipt_pdf) {
        expensesCopy[index].auto_create_pdf = true;
      }

      if (transaction.metadata && transaction.metadata.transaction_description) {
        expensesCopy[index].description = transaction.metadata.transaction_description;
      }

      //helpful so the controller knows what to do when its done
      expensesCopy[index].view_transaction = transaction;

      //so the attachment component isn't giant
      // $scope.drag_and_drop_height = 300;
    }
  };

  const inheritReceiptDetailsToExpense = async (expenseItem: ExpensesTypes.ExpenseItemFormDataType, index: number) => {
    if (receiptID) {
      const receipt = await ReceiptsApis.getReceipt(receiptID);

      expenseItem.date = receipt?.form_data?.date;
      expenseItem.vendor_name = receipt?.form_data?.merchant;
      expenseItem.description = receipt.note;
      expenseItem.assets_attributes = receipt.assets_attributes;

      if (expenseItem.policy && expenseItemCommonSvc.isAllowedConversion(expenseItem.policy)) {
        expenseItem.base_currency_total = Number(receipt?.form_data?.total);
        expenseItem.base_currency_amount = Number(receipt?.form_data?.sub_total);
        expenseItem.base_currency_tax = Number(receipt?.form_data?.tax);
        if (receipt?.form_data?.currency_code) {
          expenseItem.base_currency_code = receipt.form_data.currency_code;
          await expenseItemCommonSvc.getCurrencyRatePromise(expenseItem, appCurrencies, currentUser);
          expenseItemCommonSvc.calculateExchangeTotal(expenseItem, currentUser);
        }
      } else {
        expenseItem.total = Number(receipt.form_data?.total);
        expenseItem.amount = Number(receipt.form_data?.sub_total);
        expenseItem.tax = Number(receipt.form_data?.tax);
        expenseItem.currency_code = receipt.form_data?.currency_code;
      }
    }

    // dispatch(change(form, currentExpenseItemFormLocationString, expenseItem));
    return expenseItem;
  };

  const disableExistingExpenseReport = async (index: number, expensesCopy: ExpensesTypes.ExpenseItemFormDataType[]) => {
    if (expensesCopy) {
      // set expense item subsidiary to match expense report
      expensesCopy[index].subsidiary_id = expenseReport?.subsidiary_id;

      // set expense item policy to match expense report
      expensesCopy[index].policy_id = expenseReport?.policy_id;
      if (expenseReport?.policy) {
        await expenseItemCommonSvc.mergePolicyResponse(
          expenseReport?.policy,
          index,
          expensesCopy,
          appCurrencies,
          currentUser,
          params,
        );
      }

      // set expense item employee to match expense report
      if (expenseReport?.employee) {
        await expenseItemCommonSvc.employeePickedCallback(
          expenseReport.employee.id!,
          index,
          expensesCopy,
          appCurrencies,
          currentUser,
          params,
        );
      }

      // set expense item currency code to match expense report
      expensesCopy[index].currency_code = expenseReport?.currency_code;

      // set expense item reimbursable to match expense report
      if (_.isArray(expenseReport?.expense_items)) {
        expensesCopy[index].reimbursable = expenseReport?.expense_items[0].reimbursable;
      }

      // set expense item's expense_report_id as "link_to_expense_report" for use on backend
      expensesCopy[index].link_to_expense_report = expenseReport?.id;
    }
    // no dispatch is need as dispatch is performed in parent function
  };

  const setExpense = async (
    object: ExpensesTypes.ExpenseItemFormDataType | null | undefined,
    index: number,
    transaction = null,
  ) => {
    try {
      const result = await ExpensesApis.getNewExpenseItem();
      const expenseItem: ExpensesTypes.ExpenseItemFormDataType = result;
      expenseItem.total = null;

      if (_.isArray(expenses) && object) {
        const expensesCopy = _.cloneDeep(expenses);
        expensesCopy[index] = Object.assign(object, expenseItem);
        expensesCopy[index].selected_type = expenseService.expenseItemTypes[0];
        expensesCopy[index].item_type = expensesCopy[index]?.selected_type?.value;
        expensesCopy[index].project_id = expensesCopy[index]?.project?.id;
        expensesCopy[index].project_name = expensesCopy[index]?.project?.name;
        if (restProps.expenseReport) {
          await disableExistingExpenseReport(index, expensesCopy);
        }
        if (expensesCopy[index] && expensesCopy[index].policy_id) {
          await getSelectedPolicy(expensesCopy[index].policy_id!, index, expensesCopy);
        }

        if (
          expensesCopy[index].employee_id &&
          expensesCopy[index].employee_id === currentUser.contact.id &&
          !expenseReport
        ) {
          // if the expense item already has an employee, and the employee is the current user,
          // and there is not an existing 'expense_report' we are adding this expense item to,
          // automatically use the expense item's employee.
          expensesCopy[index].employee = currentUser.contact;
          if (expensesCopy[index]?.employee) {
            const expenseItem = await expenseItemCommonSvc.employeePickedCallback(
              expensesCopy[index]?.employee?.id!,
              index,
              expensesCopy,
              appCurrencies,
              currentUser,
              params,
            );
            if (expenseItem) {
              expensesCopy[index] = expenseItem;
            }
            // dispatch(change(form, currentExpenseItemFormLocationString, expenseItem));
          }
        }

        if (
          _.isPlainObject(expenseItem) &&
          !expenseItem.currency_code &&
          _.isPlainObject(currentUser.company.currency)
        ) {
          expensesCopy[index].currency_code = currentUser.company.currency.iso_code;
        }

        if (transaction) {
          setBankCardTransaction(transaction, index, expensesCopy);
        }

        if (receiptID) {
          expensesCopy[index] = await inheritReceiptDetailsToExpense(expensesCopy[index], index);
        }

        if (currentUser.company.expense_item?.enable_split_expenses) {
          // it will valid by default will update to false as user action on split and total
          expensesCopy[index].is_split_100_percent = true;
        }

        expensesCopy[index].is_loaded = true;
        dispatch(change(form, currentExpenseItemFormLocationString, expensesCopy[index]));
      }
    } catch (error) {
      commonService.handleError(error);
    }
  };

  const onDeleteAttachment = (attachment: IAttachment) => {
    createConfirmModal({
      title: translate("notification.deleteConfirmationDialog.title"),
      body: translate("notification.deleteConfirmationDialog.body"),
      confirmButtonLabel: translate("notification.deleteConfirmationDialog.deleteButton"),
      callBackData: null,
      cancelCallBack: null,
      saveCallBack: () => onDeleteConfirm(attachment),
    });
  };

  const showReceiptListDialog = () => {
    setShowReceiptList(true);
  };

  useEffect(() => {
    setExpense(expenseItem, index);
  }, []);

  const contextValue = useMemo(
    () => ({
      index,
      formName: form,
      sectionPrefix: currentExpenseItemFormLocationString,
      params,
      expenseReport,
    }),
    [index, form, currentExpenseItemFormLocationString, params, expenseReport],
  );

  if (!expenseItem?.is_loaded) {
    return (
      <Container fluid>
        <Row>
          <Col>
            <div className="w-100 h-100 d-flex justify-content-center align-items-center">
              <Spinner animation="border" variant="info" />
            </div>
          </Col>
        </Row>
      </Container>
    );
  }

  return (
    <ReceiptsProvider>
      {/** Memoized context value */}
      <ExpenseItemFormContext.Provider value={contextValue}>
        <Row>
          <Col md="12">
            <Row>
              <Can I={"editattachment"} a={"ExpenseItems"}>
                <Col md="4">
                  <div className={style.expenseAttachmentParent}>
                    <AttachmentCarousel
                      attachments={expenseItem?.assets_attributes || []}
                      onDeleteAttachmentCallback={onDeleteAttachment}
                      onUploadRequestedCallback={onAttachmentUpload}
                    />
                  </div>
                  <Button
                    className={style.attachFromReceiptQueueButton}
                    variant="secondary"
                    onClick={() => showReceiptListDialog()}
                  >
                    {translate("attach")}
                  </Button>
                </Col>
              </Can>
              <Col md="8">
                <PolicyAndItemTypeSelection />
                {expenseItem?.selected_type?.value === "SINGLE_EXPENSE" && <SingleExpenseForm />}
                {expenseItem?.selected_type?.value === "MILEAGE" && <MileageForm />}
                {expenseItem?.selected_type?.value === "PER_DIEM" && <PerDiemForm />}
              </Col>
            </Row>
            <ExpenseAllocations />
          </Col>
        </Row>
      </ExpenseItemFormContext.Provider>
      {expenseItem && (
        <CustomModal
          size="lg"
          show={showReceiptList}
          onHide={() => setShowReceiptList(false)}
          header={translate("attachReceiptDialogHeader")}
          body={<ExpenseReceiptList expenseItem={expenseItem} setShowReceiptList={setShowReceiptList} />}
        />
      )}
    </ReceiptsProvider>
  );
};

export default AddExpenseItemFormSection;
