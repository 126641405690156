import { AxiosResponse } from "axios";
import { restApiService } from "providers/restApi";
import React, { useCallback, useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import InputDropdownFilter from "./inputDropdown";

const AccountCodesFilter = ({
  name,
  code,
  options,
  isRequired,
  isMulti,
  customFilters,
}: {
  name: string;
  code: string;
  options: { [key: string]: any }[];
  isRequired?: boolean;
  isMulti?: boolean;
  customFilters?: URLSearchParams;
}) => {
  const [cardProgramOptions, setCardProgramOptions] = useState<{ label: string; value: string }[]>([]);

  const loadCardProgramOptions = useCallback(async () => {
    try {
      const response: AxiosResponse<any> = await restApiService.get(
        `card_programs${customFilters ? "?" + customFilters : ""}`,
      );
      const account_codes: { label: string; value: string }[] = [];
      if (!isMulti) {
        account_codes.push({ label: "ALL", value: "" });
      }
      response.data.forEach((card_program: { account_code: string }) => {
        if (
          card_program.account_code &&
          !account_codes.some((account_code) => account_code.value === card_program.account_code)
        ) {
          account_codes.push({ label: card_program.account_code, value: card_program.account_code });
        }
      });
      setCardProgramOptions(account_codes);
    } catch (error) {
      throw error;
    }
  }, [customFilters, isMulti]);

  useEffect(() => {
    loadCardProgramOptions();
  }, [loadCardProgramOptions]);

  const renderDropdown = () => {
    if (cardProgramOptions.length > 1) {
      return (
        <InputDropdownFilter
          name={name}
          code={code}
          options={cardProgramOptions}
          isRequired={isRequired}
          isMulti={isMulti}
        />
      );
    } else {
      return (
        <div>
          <h5>Loading Account Filter...</h5>
          <Spinner animation="border" variant="info"></Spinner>
        </div>
      );
    }
  };

  return <>{renderDropdown()}</>;
};

export default AccountCodesFilter;
