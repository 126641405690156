import React, { createContext, useContext, useState, useRef, useEffect, forwardRef, useImperativeHandle } from "react";
import { ReceiptsTypes } from "services/admin/expenses/receipts/receiptsType";
import { useTranslation } from "react-i18next";
import { formattedAmount } from "services/general/helpers";

interface ReceiptImage {
  id: string;
  imageUrl: string;
  fileName: string;
}

export interface Receipt {
  id: string;
  selected: boolean;
  image: ReceiptImage;
  date: Date;
  merchantName: string;
  amount: string | React.JSX.Element;
  note: string;
}

interface ReceiptsContextType {
  addRawReceipts: (rawReceipts: ReceiptsTypes.ListItem[]) => void;
  addReceipt: (receipt: Receipt) => void;
  flipCheckedStatus: (id: string) => void;
  receipts: Receipt[];
  removeAllReceipts: () => void;
  removeReceipt: (id: string) => void;
  restoreOriginalReceipts: () => void;
  setReceipts: React.Dispatch<React.SetStateAction<Receipt[]>>;
  unselectAllReceipts: () => void;
}

export const ReceiptsContext = createContext<ReceiptsContextType | undefined>(undefined);

export interface ReceiptsProviderHandle {
  getSelectedReceipts: () => Receipt[];
}

export const ReceiptsProvider = forwardRef<ReceiptsProviderHandle, { children: React.ReactNode }>(
  ({ children }, ref) => {
    const [receipts, setReceipts] = useState<Receipt[]>([]);
    const originalReceiptList = useRef<Receipt[]>([]);

    const { t } = useTranslation();
    const not_scanned = t(`components.admin.expenseReceiptList.notScanned`);

    const addReceipt = (receipt: Receipt) => setReceipts((prev) => [...prev, receipt]);
    const removeReceipt = (id: string) => setReceipts((prev) => prev.filter((r) => r.id !== id));
    const removeAllReceipts = () => setReceipts([]);

    const flipCheckedStatus = (id: string) => {
      setReceipts((prev) => prev.map((r) => (r.id === id ? { ...r, selected: !r.selected } : r)));
    };

    const addRawReceipts = (rawReceipts: ReceiptsTypes.ListItem[]) => {
      const updatedReceipts = [...receipts];

      rawReceipts.forEach((rawReceipt) => {
        const id = rawReceipt.id?.toString() || "";

        // Avoid duplicates
        if (updatedReceipts.find((r) => r.id === id)) {
          return;
        }

        const image: ReceiptImage =
          rawReceipt.assets_attributes && rawReceipt.assets_attributes?.length > 0
            ? {
                id: rawReceipt.assets_attributes[0].id?.toString() || "",
                imageUrl: rawReceipt.assets_attributes[0].asset_expiring_url || "",
                fileName: rawReceipt.assets_attributes[0].asset_file_file_name || "",
              }
            : {
                id: "",
                imageUrl: "",
                fileName: "",
              };

        const date =
          rawReceipt.form_data?.date?.substring(0, 10) || rawReceipt.created_at?.substring(0, 10) || not_scanned;

        const merchantName =
          rawReceipt.documentable?.vendor_name ||
          rawReceipt.documentable?.merchant ||
          rawReceipt.form_data?.merchant ||
          not_scanned;

        let amount: string | React.JSX.Element =
          rawReceipt.documentable?.amount?.toString() || rawReceipt.form_data?.total?.toString() || "";

        amount =
          amount.length === 0
            ? not_scanned
            : formattedAmount(amount.toString(), rawReceipt.documentable?.currency_code || "", 2, true);

        const note = rawReceipt.form_data?.note || "";

        const receipt: Receipt = {
          id: id,
          selected: false,
          image: image,
          date: new Date(date),
          merchantName: merchantName,
          amount: amount,
          note: note,
        };

        updatedReceipts.push(receipt);
      });

      // AC requirement: Receipts must be sorted by date in descending order
      updatedReceipts.sort((a, b) => b.date.getTime() - a.date.getTime());

      setReceipts(updatedReceipts);
      originalReceiptList.current = updatedReceipts;
    };

    const restoreOriginalReceipts = () => {
      setReceipts(originalReceiptList.current);
    };

    // Expose `getSelectedReceipts` via ref
    useImperativeHandle(ref, () => ({
      getSelectedReceipts: () => receipts.filter((receipt) => receipt.selected),
    }));

    const unselectAllReceipts = () => {
      setReceipts((prev) => prev.map((r) => ({ ...r, selected: false })));
    };

    return (
      <ReceiptsContext.Provider
        value={{
          addRawReceipts,
          addReceipt,
          flipCheckedStatus,
          receipts,
          removeAllReceipts,
          removeReceipt,
          restoreOriginalReceipts,
          setReceipts,
          unselectAllReceipts,
        }}
      >
        {children}
      </ReceiptsContext.Provider>
    );
  },
);

export const useReceipts = (): ReceiptsContextType => {
  const context = useContext(ReceiptsContext);
  if (!context) {
    throw new Error("useReceipts must be used within a ReceiptsProvider");
  }
  return context;
};
