import { compare } from "../services/general/helpers";

const international_payment_countries = [
  {
    name: "Canada",
    code: "CAN",
    code2: "CA",
    currency_code: "CAD",
    currency_name: "Canadian dollars",
    flag: "ca.svg",
    payment_types: ["wire"],
  },
  {
    name: "Canada",
    code: "CAN",
    code2: "CA",
    currency_code: "USD",
    currency_name: "US Dollar",
    flag: "ca.svg",
    currency_form: "causd",
  },
  {
    name: "Austria",
    code: "AUT",
    code2: "AT",
    currency_code: "EUR",
    currency_name: "Euro",
    flag: "at.svg",
    payment_types: ["sepa", "wire"],
  },
  {
    name: "Germany",
    code: "DEU",
    code2: "DE",
    currency_code: "EUR",
    currency_name: "Euro",
    flag: "de.svg",
    payment_types: ["sepa", "wire"],
  },
  {
    name: "Greece",
    code: "GRC",
    code2: "GR",
    currency_code: "EUR",
    currency_name: "Euro",
    flag: "gr.svg",
    payment_types: ["sepa", "wire"],
  },
  {
    name: "Greenland",
    code: "GRL",
    code2: "GL",
    currency_name: "Danish krone",
    currency_code: "DKK",
    flag: "gl.svg",
  },
  {
    name: "Cayman Islands",
    code: "CYM",
    code2: "KY",
    currency_name: "Cayman Islands dollar",
    currency_code: "KYD",
    flag: "ky.svg",
  },
  {
    name: "Italy",
    code: "ITA",
    code2: "IT",
    currency_code: "EUR",
    currency_name: "Euro",
    flag: "it.svg",
    payment_types: ["sepa", "wire"],
  },
  {
    name: "Ireland",
    code: "IRL",
    code2: "IE",
    currency_code: "EUR",
    currency_name: "Euro",
    flag: "ie.svg",
    payment_types: ["sepa", "wire"],
  },
  {
    name: "Netherlands",
    code: "NLD",
    code2: "NL",
    currency_code: "EUR",
    currency_name: "Euro",
    flag: "nl.svg",
    payment_types: ["sepa", "wire"],
  },
  {
    name: "Australia",
    code: "AUS",
    code2: "AU",
    currency_code: "AUD",
    currency_name: "Australian dollars",
    flag: "au.svg",
    payment_types: ["wire"],
  },
  {
    name: "Switzerland",
    code: "CHE",
    code2: "CH",
    currency_code: "CHF",
    currency_name: "Swiss Franc",
    flag: "ch.svg",
    payment_types: ["wire"],
  },
  {
    name: "United Kingdom",
    code: "GBR",
    code2: "GB",
    currency_code: "GBP",
    currency_name: "British pounds",
    flag: "gb.svg",
    payment_types: ["wire"],
  },
  {
    name: "India",
    code: "IND",
    code2: "IN",
    currency_code: "INR",
    currency_name: "Indian rupees",
    flag: "in.svg",
    payment_types: ["wire"],
  },
  {
    name: "Denmark",
    code: "DNK",
    code2: "DK",
    currency_code: "DKK",
    currency_name: "Danish krone",
    flag: "dk.svg",
    payment_types: ["wire"],
  },
  {
    name: "Denmark",
    code: "DNK",
    code2: "DK",
    currency_name: "Euro",
    currency_code: "EUR",
    flag: "dk.svg",
    payment_types: ["sepa"],
  },
  {
    name: "Sweden",
    code: "SWE",
    code2: "SE",
    currency_code: "SEK",
    currency_name: "Swedish krona",
    flag: "se.svg",
    payment_types: ["wire"],
  },
  {
    name: "Japan",
    code: "JPN",
    code2: "JP",
    currency_code: "JPY",
    currency_name: "Japanese Yen",
    flag: "jp.svg",
    payment_types: ["wire"],
  },
  {
    name: "China",
    code: "CHN",
    code2: "CN",
    currency_code: "CNY",
    currency_name: "Chinese Yuan",
    flag: "cn.svg",
    payment_types: [],
  },
  {
    name: "New Zealand",
    code: "NZL",
    code2: "NZ",
    currency_code: "NZD",
    currency_name: "New Zealand Dollar",
    flag: "nz.svg",
    payment_types: [],
  },
  {
    name: "Brazil",
    code: "BRA",
    code2: "BR",
    currency_name: "Brazilian Real",
    currency_code: "BRL",
    flag: "br.svg",
    payment_types: ["wire"],
  },
  {
    name: "Singapore",
    code: "SGP",
    code2: "SG",
    currency_name: "Singapore Dollar",
    currency_code: "SGD",
    flag: "sg.svg",
    payment_types: ["wire"],
  },
  {
    name: "Singapore",
    code: "SGP",
    code2: "SG",
    currency_code: "USD",
    currency_name: "US Dollar",
    flag: "sg.svg",
    payment_types: [],
  },
  {
    name: "Philippine",
    code: "PHL",
    code2: "PH",
    currency_name: "Philippines Peso",
    currency_code: "PHP",
    flag: "ph.svg",
    payment_types: [],
  },
  {
    name: "Norway",
    code: "NOR",
    code2: "NO",
    currency_name: "Norwegian krone",
    currency_code: "NOK",
    flag: "no.svg",
    payment_types: ["wire"],
  },
  {
    name: "Malaysia",
    code: "MYS",
    code2: "MY",
    currency_name: "Malaysian ringgit",
    currency_code: "MYR",
    flag: "my.svg",
    payment_types: [],
  },
  {
    name: "Belgium",
    code: "BEL",
    code2: "BE",
    currency_name: "Belgian Euro",
    currency_code: "EUR",
    flag: "be.svg",
    payment_types: ["sepa", "wire"],
  },
  {
    name: "Spain",
    code: "ESP",
    code2: "ES",
    currency_name: "Euro",
    currency_code: "EUR",
    flag: "es.svg",
    payment_types: ["sepa", "wire"],
  },
  {
    name: "France",
    code: "FRA",
    code2: "FR",
    currency_code: "EUR",
    currency_name: "Euro",
    flag: "fr.svg",
    payment_types: ["sepa", "wire"],
  },
  {
    name: "United States",
    code: "USA",
    code2: "US",
    currency_code: "USD",
    currency_name: "US Dollar",
    flag: "us.svg",
    payment_types: ["wire"],
  },
  {
    name: "Chile",
    code: "CHL",
    code2: "CL",
    currency_name: "Chilean Peso",
    currency_code: "CLP",
    flag: "cl.svg",
    payment_types: [],
  },
  {
    name: "Albania",
    code: "ALB",
    code2: "AL",
    currency_name: "Albanian lek",
    currency_code: "ALL",
    flag: "al.svg",
    payment_types: ["wire"],
  },
  {
    name: "South Africa",
    code: "ZAF",
    code2: "ZA",
    currency_name: "South African Rand",
    currency_code: "ZAR",
    flag: "za.svg",
    payment_types: [],
  },
  {
    name: "Turkey",
    code: "TUR",
    code2: "TR",
    currency_name: "Turkish lira",
    currency_code: "TRY",
    flag: "tr.svg",
    payment_types: ["wire"],
  },
  {
    name: "Thailand",
    code: "THA",
    code2: "TH",
    currency_name: "Thai Baht",
    currency_code: "THB",
    flag: "th.svg",
    payment_types: [],
  },
  {
    name: "Portugal",
    code: "PRT",
    code2: "PT",
    currency_name: "Euro",
    currency_code: "EUR",
    flag: "pt.svg",
    payment_types: ["sepa", "wire"],
  },
  {
    name: "Poland",
    code: "POL",
    code2: "PL",
    currency_name: "Polish Zloty",
    currency_code: "PLN",
    flag: "pl.svg",
  },
  {
    name: "Poland",
    code: "POL",
    code2: "PL",
    currency_name: "Euro",
    currency_code: "EUR",
    flag: "pl.svg",
    payment_types: ["sepa", "wire"],
  },
  {
    name: "Peru",
    code: "PER",
    code2: "PE",
    currency_name: "Peruvian Sol",
    currency_code: "PEN",
    flag: "pe.svg",
  },
  {
    name: "Peru",
    code: "PER",
    code2: "PE",
    currency_name: "US Dollar",
    currency_code: "USD",
    flag: "pe.svg",
    currency_form: "perusd",
    payment_types: ["wire"],
  },
  {
    name: "Mexico",
    code: "MEX",
    code2: "MX",
    currency_name: "Mexican Peso",
    currency_code: "MXN",
    flag: "mx.svg",
    payment_types: [],
  },
  {
    name: "United Arab Emirates",
    code: "ARE",
    code2: "AE",
    currency_name: "United Arab Emirates Dirham",
    currency_code: "AED",
    flag: "ae.svg",
    payment_types: ["wire"],
  },
  {
    name: "Colombia",
    code: "COL",
    code2: "CO",
    currency_name: "Colombian Peso",
    currency_code: "COP",
    flag: "co.svg",
  },
  {
    name: "Colombia",
    code: "COL",
    code2: "CO",
    currency_name: "Euro",
    currency_code: "EUR",
    flag: "co.svg",
    payment_types: ["wire"],
  },
  {
    name: "Argentina",
    code: "ARG",
    code2: "AR",
    currency_name: "Euro",
    currency_code: "EUR",
    flag: "ar.svg",
    payment_types: ["wire"],
  },
  {
    name: "Argentina",
    code: "ARG",
    code2: "AR",
    currency_name: "Argentine peso",
    currency_code: "ARS",
    flag: "ar.svg",
  },
  {
    name: "Andorra",
    code: "AND",
    code2: "AD",
    currency_name: "Euro",
    currency_code: "EUR",
    flag: "ad.svg",
    payment_types: ["sepa", "wire"],
  },
  {
    name: "Slovenia",
    code: "SVN",
    code2: "SI",
    currency_name: "Euro",
    currency_code: "EUR",
    flag: "si.svg",
    payment_types: ["sepa", "wire"],
  },
  {
    name: "Uruguay",
    code: "URY",
    code2: "UY",
    currency_name: "US Dollar",
    currency_code: "USD",
    flag: "uy.svg",
  },
  {
    name: "Iceland",
    code: "ISL",
    code2: "IS",
    currency_name: "US Dollar",
    currency_code: "USD",
    flag: "is.svg",
  },
  {
    name: "Ecuador",
    code: "ECU",
    code2: "EC",
    currency_name: "US Dollar",
    currency_code: "USD",
    flag: "ec.svg",
  },
  {
    name: "Czech Republic",
    code: "CZE",
    code2: "CZ",
    currency_name: "Euro",
    currency_code: "EUR",
    flag: "cz.svg",
    payment_types: ["sepa", "wire"],
  },
  {
    name: "Cambodia",
    code: "KHM",
    code2: "KH",
    currency_name: "US Dollar",
    currency_code: "USD",
    flag: "kh.svg",
    payment_types: ["wire"],
  },
  {
    name: "Hong Kong",
    code: "HKG",
    code2: "HK",
    currency_name: "Hong Kong Dollar",
    currency_code: "HKD",
    flag: "hk.svg",
    payment_types: [],
  },
  {
    name: "Kenya",
    code: "KEN",
    code2: "KE",
    currency_name: "Kenyan shilling",
    currency_code: "KES",
    flag: "ke.svg",
    payment_types: [],
  },
  {
    name: "Zambia",
    code: "ZMB",
    code2: "ZM",
    currency_name: "Zambian Kwacha",
    currency_code: "ZMW",
    flag: "zm.svg",
    payment_types: [],
  },
  {
    name: "Nigeria",
    code: "NGA",
    code2: "NG",
    currency_name: "Nigerian naira",
    currency_code: "NGN",
    flag: "ng.svg",
    payment_types: [],
  },
  {
    name: "Ghana",
    code: "GHA",
    code2: "GH",
    currency_name: "Ghanaian Cedi",
    currency_code: "GHS",
    flag: "gh.svg",
    payment_types: [],
  },
  {
    name: "Central African Republic",
    code: "CAF",
    code2: "CF",
    currency_name: "Central African CFA franc",
    currency_code: "XAF",
    flag: "cf.svg",
    payment_types: ["wire"],
  },
  {
    name: "Pakistan",
    code: "PAK",
    code2: "PK",
    currency_name: "Pakistani rupee",
    currency_code: "PKR",
    flag: "pk.svg",
    payment_types: ["wire"],
  },
  {
    name: "Indonesia",
    code: "IDN",
    code2: "ID",
    currency_name: "Indonesian Rupiah",
    currency_code: "IDR",
    flag: "id.svg",
    payment_types: [],
  },
  {
    name: "South Korea",
    code: "KOR",
    code2: "KR",
    currency_name: "South Korean Won",
    currency_code: "KRW",
    flag: "kr.svg",
    payment_types: [],
  },
  {
    name: "Sri Lanka",
    code: "LKA",
    code2: "LK",
    currency_name: "Sri Lankan Rupee",
    currency_code: "LKR",
    flag: "lk.svg",
    payment_types: [],
  },
  {
    name: "Cyprus",
    code: "CYP",
    code2: "CY",
    currency_name: "Euro",
    currency_code: "EUR",
    flag: "cy.svg",
    payment_types: ["sepa", "wire"],
  },
  {
    name: "Israel",
    code: "ISR",
    code2: "IL",
    currency_name: "Israeli Shekel",
    currency_code: "ILS",
    flag: "il.svg",
    payment_types: ["wire"],
  },
  {
    name: "Armenia",
    code: "ARM",
    code2: "AM",
    currency_name: "Armenian dram",
    currency_code: "AMD",
    flag: "am.svg",
    payment_types: ["wire"],
  },
  {
    name: "Bulgaria",
    code: "BGR",
    code2: "BG",
    currency_name: "Bulgarian lev",
    currency_code: "BGN",
    flag: "bg.svg",
    payment_types: ["wire"],
  },
  {
    name: "Bulgaria",
    code: "BGR",
    code2: "BG",
    currency_name: "Euro",
    currency_code: "EUR",
    flag: "bg.svg",
    payment_types: ["sepa"],
  },
  {
    name: "Romania",
    code: "ROU",
    code2: "RO",
    currency_name: "Romanian new leu",
    currency_code: "RON",
    flag: "ro.svg",
    payment_types: ["wire"],
  },
  {
    name: "Saudi Arabia",
    code: "SAU",
    code2: "SA",
    currency_name: "Saudi riyal",
    currency_code: "SAR",
    flag: "sa.svg",
    payment_types: ["wire"],
  },
  {
    name: "Russian Federation",
    code: "RUS",
    code2: "RU",
    currency_name: "Rouble",
    currency_code: "RUB",
    flag: "ru.svg",
    payment_types: ["wire"],
  },
  {
    name: "Azerbaijan",
    code: "AZE",
    code2: "AZ",
    currency_name: "Manat",
    currency_code: "AZN",
    flag: "az.svg",
    payment_types: ["wire"],
  },
  {
    name: "Belarus",
    code: "BLR",
    code2: "BY",
    currency_name: "Belarusian Ruble",
    currency_code: "BYN",
    flag: "by.svg",
    payment_types: ["wire"],
  },
  {
    name: "Croatia",
    code: "HRV",
    code2: "HR",
    currency_name: "Croatian kuna",
    currency_code: "HRK",
    flag: "hr.svg",
    payment_types: ["wire"],
  },
  {
    name: "Egypt",
    code: "EGY",
    code2: "EG",
    currency_name: "Egyptian pound",
    currency_code: "EGP",
    flag: "eg.svg",
    payment_types: ["wire"],
  },
  {
    name: "Estonia",
    code: "EST",
    code2: "EE",
    currency_name: "Euro",
    currency_code: "EUR",
    flag: "ee.svg",
    payment_types: ["sepa", "wire"],
  },
  {
    name: "Finland",
    code: "FIN",
    code2: "FI",
    currency_name: "Euro",
    currency_code: "EUR",
    flag: "fi.svg",
    payment_types: ["sepa", "wire"],
  },
  {
    name: "Latvia",
    code: "LVA",
    code2: "LV",
    currency_name: "Latvian lats",
    currency_code: "LVL",
    flag: "lv.svg",
    payment_types: ["wire"],
  },
  {
    name: "Latvia",
    code: "LVA",
    code2: "LV",
    currency_name: "Euro",
    currency_code: "EUR",
    flag: "lv.svg",
    payment_types: ["sepa"],
  },
  {
    name: "Luxembourg",
    code: "LUX",
    code2: "LU",
    currency_name: "Euro",
    currency_code: "EUR",
    flag: "lu.svg",
    payment_types: ["sepa", "wire"],
  },
  {
    name: "Montenegro",
    code: "MNE",
    code2: "ME",
    currency_name: "Euro",
    currency_code: "EUR",
    flag: "me.svg",
    payment_types: ["wire"],
  },
  {
    name: "Serbia",
    code: "SRB",
    code2: "RS",
    currency_name: "Serbian dinar",
    currency_code: "RSD",
    flag: "rs.svg",
    payment_types: ["wire"],
  },
  {
    name: "Ukraine",
    code: "UKR",
    code2: "UA",
    currency_name: "Ukrainian hryvnia",
    currency_code: "UAH",
    flag: "ua.svg",
    payment_types: ["wire"],
  },
  {
    name: "Viet Nam",
    code: "VNM",
    code2: "VN",
    currency_name: "Vietnamese đồng",
    currency_code: "VND",
    flag: "vn.svg",
    payment_types: ["wire"],
  },
  {
    name: "Malta",
    code: "MLT",
    code2: "MT",
    currency_name: "Euro",
    currency_code: "EUR",
    flag: "mt.svg",
    payment_types: ["wire", "sepa"],
  },
  {
    name: "Lithuania",
    code: "LTU",
    code2: "LT",
    currency_name: "Euro",
    currency_code: "EUR",
    flag: "lt.svg",
    payment_types: ["sepa"],
  },
  {
    name: "Slovakia",
    code: "SVK",
    code2: "SK",
    currency_name: "Euro",
    currency_code: "EUR",
    flag: "sk.svg",
    payment_types: ["sepa"],
  },
  {
    name: "San Marino",
    code: "SMR",
    code2: "SM",
    currency_name: "Euro",
    currency_code: "EUR",
    flag: "sm.svg",
    payment_types: ["sepa"],
  },
  {
    name: "Monaco",
    code: "MCO",
    code2: "MC",
    currency_name: "Euro",
    currency_code: "EUR",
    flag: "mc.svg",
    payment_types: ["sepa"],
  },
  {
    name: "Sweden",
    code: "SWE",
    code2: "SE",
    currency_name: "Euro",
    currency_code: "EUR",
    flag: "se.svg",
    payment_types: ["sepa"],
  },
  {
    name: "United Kingdom",
    code: "GBR",
    code2: "GB",
    currency_name: "Euro",
    currency_code: "EUR",
    flag: "gb.svg",
    payment_types: ["sepa"],
  },
  {
    name: "Switzerland",
    code: "CHE",
    code2: "CH",
    currency_name: "Euro",
    currency_code: "EUR",
    flag: "ch.svg",
    payment_types: ["sepa"],
  },
  {
    name: "Norway",
    code: "NOR",
    code2: "NO",
    currency_name: "Euro",
    currency_code: "EUR",
    flag: "no.svg",
    payment_types: ["sepa"],
  },
  {
    name: "Romania",
    code: "ROU",
    code2: "RO",
    currency_name: "Euro",
    currency_code: "EUR",
    flag: "ro.svg",
    payment_types: ["sepa"],
  },
  {
    name: "Jersey",
    code: "JEY",
    code2: "AF",
    currency_name: "Euro",
    currency_code: "EUR",
    flag: "af.svg",
    payment_types: ["sepa"],
  },
  {
    name: "Hungary",
    code: "HUN",
    code2: "HU",
    currency_name: "Euro",
    currency_code: "EUR",
    flag: "hu.svg",
    payment_types: ["sepa"],
  },
  {
    name: "Kyrgyzstan",
    code: "KGZ",
    code2: "KG",
    currency_name: "Euro",
    currency_code: "EUR",
    flag: "kg.svg",
    payment_types: ["sepa"],
  },
  {
    name: "Uganda",
    code: "UGA",
    code2: "UG",
    currency_name: "Ugandan shilling",
    currency_code: "UGX",
    flag: "ug.svg",
  },
  {
    name: "Botswana",
    code: "BWA",
    code2: "BW",
    currency_name: "Botswanan Pula",
    currency_code: "BWP",
    flag: "bw.svg",
  },
  {
    name: "Mauritius",
    code: "MUS",
    currency_name: "Mauritian Rupee",
    currency_code: "MUR",
    flag: "mu.svg",
  },
  {
    name: "Lesotho",
    code: "LSO",
    currency_name: "Lesotho Loti",
    currency_code: "LSL",
    flag: "ls.svg",
  },
  {
    name: "Panama",
    code: "PAN",
    code2: "PA",
    currency_name: "Panama Balboa",
    currency_code: "USD",
    flag: "pa.svg",
    payment_types: ["cambridge"],
  },
  {
    name: "Panama",
    code: "PAN",
    code2: "PA",
    currency_name: "Panama Balboa",
    currency_code: "PAB",
    flag: "pa.svg",
    payment_types: ["cambridge"],
  },
  {
    name: "Guatemala",
    code: "GTM",
    code2: "GT",
    currency_name: "Guatemala Quetzal",
    currency_code: "GTQ",
    flag: "gt.svg",
    payment_types: ["cambridge"],
  },
  /* {
          "name": "Afghanistan",
          "code": "AFG",
          "currency_name": "Afghanistan afghani",
          "currency_code": "AFA",
          "flag"         : "af.svg"
      },
      {
          "name": "Albania",
          "code": "ALB",
          "currency_name": "Albanian lek",
          "currency_code": "ALL",
          "flag"         : "al.svg"
      },
      {
          "name": "Algeria",
          "code": "DZA",
          "currency_name": "Algerian dinar",
          "currency_code": "DZD",
          "flag"         : "dz.svg"
      },
      {
          "name": "Myanmar",
          "code": "MMR",
          "currency_name": "Myanmar kyat",
          "currency_code": "MMK",
          "flag"         : "mm.svg"
      },
      {
          "name": "North Korea",
          "code": "PRK",
          "currency_name": "North Korean won",
          "currency_code": "KPW",
          "flag"         : "kp.svg"
      },

      {
          "name": "Swaziland",
          "code": "SWZ",
          "currency_name": "Swaziland lilangeni",
          "currency_code": "SZL",
          "flag"         : "sz.svg"
      },
      {
          "name": "Zimbabwe",
          "code": "ZWE",
          "currency_name": "Zimbabwe dollar",
          "currency_code": "ZWL",
          "flag"         : "zw.svg"
      },
      {
          "name": "Angola",
          "code": "AGO",
          "currency_name": "Angolan kwanza reajustado",
          "currency_code": "AOR",
          "flag"         : "ao.svg"
      },
      {
          "name": "Aruba",
          "code": "ABW",
          "currency_name": "Aruban florin",
          "currency_code": "AWG",
          "flag"         : "aw.svg"
      },
      {
          "name": "Bahamas",
          "code": "BHS",
          "currency_name": "Bahamian dollars",
          "currency_code": "BSD",
          "flag"         : "bs.svg"
      },
      {
          "name": "Bangladesh",
          "code": "BGD",
          "currency_name": "Bangladeshi taka",
          "currency_code": "BDT",
          "flag"         : "bd.svg"
      },
      {
          "name": "Bahrain",
          "code": "BHR",
          "currency_name": "Bahraini dinar",
          "currency_code": "BHD",
          "flag"         : "bh.svg"
      },
      {
          "name": "Bhutan",
          "code": "BTN",
          "currency_name": "Bhutan ngultrum",
          "currency_code": "BTN",
          "flag"         : "bt.svg"
      },
      {
          "name": "Bolivia",
          "code": "BOL",
          "currency_name": "Bolivian boliviano",
          "currency_code": "BOB",
          "flag"         : "bo.svg"
      },
      {
          "name": "Brunei",
          "code": "BRN",
          "currency_name": "Brunei dollar",
          "currency_code": "BND",
          "flag"         : "bn.svg"
      },
      {
          "name": "Georgia",
          "code": "GEO",
          "currency_name": "Georgian lari",
          "currency_code": "GEL",
          "flag"         : "ge.svg"
      },
      {
          "name": "Jordan",
          "code": "JOR",
          "currency_name": "Jordanian dinar",
          "currency_code": "JOD",
          "flag"         : "jo.svg"
      },
      {
          "name": "Lebanon",
          "code": "LBN",
          "currency_name": "Lebanese pound",
          "currency_code": "LBP",
          "flag"         : "lb.svg"
      },
      {
          "name": "Liberia",
          "code": "LBR",
          "currency_name": "Liberian Dollar",
          "currency_code": "LRD",
          "flag"         : "lr.svg"
      },
      {
          "name": "Mongolia",
          "code": "MNG",
          "currency_name": "Mongolian tugrik",
          "currency_code": "MNT",
          "flag"         : "mn.svg"
      },
      {
          "name": "Nepal",
          "code": "NPL",
          "currency_name": "Nepalese rupee",
          "currency_code": "NPR",
          "flag"         : "np.svg"
      },
      {
          "name": "Maldives",
          "code": "MDV",
          "currency_name": "Maldivian rufiyaa",
          "currency_code": "MVR",
          "flag"         : "mv.svg"
      }*/
].sort((a, b) => {
  var nameA = a.name.toUpperCase(); // ignore upper and lowercase
  var nameB = b.name.toUpperCase(); // ignore upper and lowercase
  if (nameA < nameB) {
    return -1;
  }
  if (nameA > nameB) {
    return 1;
  }
  // names must be equal
  return 0;
});

const incoterms = [
  { value: "EXW - Ex Works", label: "EXW - Ex Works" },
  { value: "FCA - Free Carrier", label: "FCA - Free Carrier" },
  { value: "CPT - Carriage Paid To", label: "CPT - Carriage Paid To" },
  { value: "CIP - Carriage and Insurance Paid to", label: "CIP - Carriage and Insurance Paid to" },
  { value: "DAT - Delivered At Terminal", label: "DAT - Delivered At Terminal" },
  { value: "DAP - Delivered At Place", label: "DAP - Delivered At Place" },
  { value: "DDP - Delivered Duty Paid", label: "DDP - Delivered Duty Paid" },
  { value: "FAS - Free Alongside Ship", label: "FAS - Free Alongside Ship" },
  { value: "FOB - Free on Board", label: "FOB - Free on Board" },
  { value: "CFR - Cost and Freight", label: "CFR - Cost and Freight" },
  { value: "CIF - Cost, Insurance & Freight", label: "CIF - Cost, Insurance & Freight" },
];

const international_wire_payment_countries = [
  {
    name: "Canada",
    code: "CAN",
    code2: "CA",
    currency_code: "CAD",
    currency_name: "Canadian dollars",
    flag: "ca.svg",
  },
  {
    name: "Austria",
    code: "AUT",
    code2: "AT",
    currency_code: "EUR",
    currency_name: "Euro",
    flag: "at.svg",
  },
  {
    name: "Germany",
    code: "DEU",
    code2: "DE",
    currency_code: "EUR",
    currency_name: "Euro",
    flag: "de.svg",
  },
  {
    name: "Greece",
    code: "GRC",
    code2: "GR",
    currency_code: "EUR",
    currency_name: "Euro",
    flag: "gr.svg",
  },
  {
    name: "Italy",
    code: "ITA",
    code2: "IT",
    currency_code: "EUR",
    currency_name: "Euro",
    flag: "it.svg",
  },
  {
    name: "Ireland",
    code: "IRL",
    code2: "IE",
    currency_code: "EUR",
    currency_name: "Euro",
    flag: "ie.svg",
  },
  {
    name: "Israel",
    code: "ISR",
    code2: "IL",
    currency_code: "ILS",
    currency_name: "Israeli Shekel",
    flag: "il.svg",
  },
  {
    name: "Netherlands",
    code: "NLD",
    code2: "NL",
    currency_code: "EUR",
    currency_name: "Euro",
    flag: "nl.svg",
  },
  {
    name: "Australia",
    code: "AUS",
    code2: "AU",
    currency_code: "AUD",
    currency_name: "Australian dollars",
    flag: "au.svg",
  },
  {
    name: "Switzerland",
    code: "CHE",
    code2: "CH",
    currency_code: "CHF",
    currency_name: "Swiss Franc",
    flag: "ch.svg",
  },
  {
    name: "United Kingdom",
    code: "GBR",
    code2: "GB",
    currency_code: "GBP",
    currency_name: "British pounds",
    flag: "gb.svg",
  },
  {
    name: "India",
    code: "IND",
    code2: "IN",
    currency_code: "INR",
    currency_name: "Indian rupees",
    flag: "in.svg",
  },
  {
    name: "Denmark",
    code: "DNK",
    code2: "DK",
    currency_code: "DKK",
    currency_name: "Danish krone",
    flag: "dk.svg",
  },
  {
    name: "Sweden",
    code: "SWE",
    code2: "SE",
    currency_code: "SEK",
    currency_name: "Swedish krona",
    flag: "se.svg",
  },
  {
    name: "Japan",
    code: "JPN",
    code2: "JP",
    currency_code: "JPY",
    currency_name: "Japanese Yen",
    flag: "jp.svg",
  },
  // {
  //   "name": "China",
  //   "code": "CHN",
  //   "code2" : "CN",
  //   "currency_code": "CNY",
  //   "currency_name": "Chinese Yuan",
  //   "flag": "cn.svg"
  // },
  {
    name: "New Zealand",
    code: "NZL",
    code2: "NZ",
    currency_code: "NZD",
    currency_name: "New Zealand Dollar",
    flag: "nz.svg",
  },
  {
    name: "Brazil",
    code: "BRA",
    code2: "BR",
    currency_name: "Brazilian Real",
    currency_code: "BRL",
    flag: "br.svg",
  },
  {
    name: "Singapore",
    code: "SGP",
    code2: "SG",
    currency_name: "Singapore Dollar",
    currency_code: "SGD",
    flag: "sg.svg",
  },
  // {
  //   "name": "Philippine",
  //   "code": "PHL",
  //   "code2" : "PH",
  //   "currency_name": "Philippines Peso",
  //   "currency_code": "PHP",
  //   "flag": "ph.svg"
  // },
  {
    name: "Norway",
    code: "NOR",
    code2: "NO",
    currency_name: "Norwegian krone",
    currency_code: "NOK",
    flag: "no.svg",
  },
  //  {
  //   "name": "Malaysia",
  //   "code": "MYS",
  //   "code2" : "MY",
  //   "currency_name": "Malaysian ringgit",
  //   "currency_code": "MYR",
  //   "flag": "my.svg"
  // },
  {
    name: "Belgium",
    code: "BEL",
    code2: "BE",
    currency_name: "Belgian Euro",
    currency_code: "EUR",
    flag: "be.svg",
  },
  {
    name: "Spain",
    code: "ESP",
    code2: "ES",
    currency_name: "Euro",
    currency_code: "EUR",
    flag: "es.svg",
  },
  {
    name: "France",
    code: "FRA",
    code2: "FR",
    currency_code: "EUR",
    currency_name: "Euro",
    flag: "fr.svg",
  },
  {
    name: "United States",
    code: "USA",
    code2: "US",
    currency_code: "USD",
    currency_name: "US Dollar",
    flag: "us.svg",
  },
  //  {
  //   "name": "Chile",
  //   "code": "CHL",
  //   "code2" : "CL",
  //   "currency_name": "Chilean Peso",
  //   "currency_code": "CLP",
  //   "flag": "cl.svg"
  // }
  {
    name: "Albania",
    code: "ALB",
    code2: "AL",
    currency_name: "Albanian lek",
    currency_code: "ALL",
    flag: "al.svg",
  },
  {
    name: "Turkey",
    code: "TUR",
    code2: "TR",
    currency_name: "Turkish lira",
    currency_code: "TRY",
    flag: "tr.svg",
  },
  //  {
  //   "name": "Thailand",
  //   "code": "THA",
  //   "code2" : "TH",
  //   "currency_name": "Thai Baht",
  //   "currency_code": "THB",
  //   "flag": "th.svg"
  // },
  {
    name: "Portugal",
    code: "PRT",
    code2: "PT",
    currency_name: "Euro",
    currency_code: "EUR",
    flag: "pt.svg",
  },
  {
    name: "Poland",
    code: "POL",
    code2: "PL",
    currency_name: "Euro",
    currency_code: "EUR",
    flag: "pl.svg",
  },
  // {
  //   "name": "Mexico",
  //   "code": "MEX",
  //   "code2" : "MX",
  //   "currency_name": "Mexican Peso",
  //   "currency_code": "MXN",
  //   "flag": "mx.svg"
  // },
  {
    name: "United Arab Emirates",
    code: "ARE",
    code2: "AE",
    currency_name: "United Arab Emirates Dirham",
    currency_code: "AED",
    flag: "ae.svg",
  },
  {
    name: "Colombia",
    code: "COL",
    code2: "CO",
    currency_name: "Euro",
    currency_code: "EUR",
    flag: "co.svg",
  },
  {
    name: "Argentina",
    code: "ARG",
    code2: "AR",
    currency_name: "Euro",
    currency_code: "EUR",
    flag: "ar.svg",
  },
  {
    name: "Andorra",
    code: "AND",
    code2: "AD",
    currency_name: "Euro",
    currency_code: "EUR",
    flag: "ad.svg",
  },
  {
    name: "Cambodia",
    code: "KHM",
    code2: "KH",
    currency_name: "US Dollar",
    currency_code: "USD",
    flag: "kh.svg",
  },
  // {
  //   "name": "Ecuador",
  //   "code": "ECU",
  //   "code2" : "EC",
  //   "currency_name": "US Dollar",
  //   "currency_code": "USD",
  //   "flag"         : "ec.svg"
  // }
  {
    name: "Iceland",
    code: "ISL",
    code2: "IS",
    currency_name: "US Dollar",
    currency_code: "USD",
    flag: "is.svg",
    payment_types: ["wire"],
  },
  {
    name: "Peru",
    code: "PER",
    code2: "PE",
    currency_name: "US Dollar",
    currency_code: "USD",
    flag: "pe.svg",
    currency_form: "perusd",
    payment_types: ["wire"],
  },
  {
    name: "Uruguay",
    code: "URY",
    code2: "UY",
    currency_name: "US Dollar",
    currency_code: "USD",
    flag: "uy.svg",
    payment_types: ["wire"],
  },
  // {
  //   "name" : 'Kenya',
  //   "code" : 'KEN',
  //   "code2" : "KE",
  //   "currency_name": "Kenyan shilling",
  //   "currency_code": "KES",
  //   "flag"         : "ke.svg"
  // },
  {
    name: "Malta",
    code: "MLT",
    code2: "MT",
    currency_name: "Euro",
    currency_code: "EUR",
    flag: "mt.svg",
  },
  //  {
  //   "name" : 'Zambia',
  //   "code" : 'ZMB',
  //   "code2" : "ZM",
  //   "currency_name": "Zambian Kwacha",
  //   "currency_code": "ZMW",
  //   "flag"         : "zm.svg"
  // },
  // {
  //   "name" : 'Nigeria',
  //   "code" : 'NGA',
  //   "code2" : "NG",
  //   "currency_name": "Nigerian naira",
  //   "currency_code": "NGN",
  //   "flag"         : "ng.svg"
  // },
  // {
  //   "name" : 'Ghana',
  //   "code" : 'GHA',
  //   "code2" : "GH",
  //   "currency_name": "Ghanaian Cedi",
  //   "currency_code": "GHS",
  //   "flag"         : "gh.svg"
  // },
  {
    name: "Central African Republic",
    code: "CAF",
    code2: "CF",
    currency_name: "Central African CFA franc",
    currency_code: "XAF",
    flag: "cf.svg",
  },
  {
    name: "Pakistan",
    code: "PAK",
    code2: "PK",
    currency_name: "Pakistani rupee",
    currency_code: "PKR",
    flag: "pk.svg",
  },
  {
    name: "Cyprus",
    code: "CYP",
    code2: "CY",
    currency_name: "Euro",
    currency_code: "EUR",
    flag: "cy.svg",
  },
  {
    name: "Czech Republic",
    code: "CZE",
    code2: "CZ",
    currency_name: "Euro",
    currency_code: "EUR",
    flag: "cz.svg",
  },
  {
    name: "Slovenia",
    code: "SVN",
    code2: "SI",
    currency_name: "Euro",
    currency_code: "EUR",
    flag: "si.svg",
  },
  {
    name: "Armenia",
    code: "ARM",
    code2: "AM",
    currency_name: "Armenian dram",
    currency_code: "AMD",
    flag: "am.svg",
  },
  {
    name: "Bulgaria",
    code: "BGR",
    code2: "BG",
    currency_name: "Bulgarian lev",
    currency_code: "BGN",
    flag: "bg.svg",
  },
  {
    name: "Romania",
    code: "ROU",
    code2: "RO",
    currency_name: "Romanian new leu",
    currency_code: "RON",
    flag: "ro.svg",
  },
  {
    name: "Saudi Arabia",
    code: "SAU",
    code2: "SA",
    currency_name: "Saudi riyal",
    currency_code: "SAR",
    flag: "sa.svg",
  },
  {
    name: "Russian Federation",
    code: "RUS",
    code2: "RU",
    currency_name: "Rouble",
    currency_code: "RUB",
    flag: "ru.svg",
  },
  {
    name: "Azerbaijan",
    code: "AZE",
    code2: "AZ",
    currency_name: "Manat",
    currency_code: "AZN",
    flag: "az.svg",
  },
  {
    name: "Belarus",
    code: "BLR",
    code2: "BY",
    currency_name: "Belarusian Ruble",
    currency_code: "BYN",
    flag: "by.svg",
  },
  {
    name: "Croatia",
    code: "HRV",
    code2: "HR",
    currency_name: "Croatian kuna",
    currency_code: "HRK",
    flag: "hr.svg",
  },
  {
    name: "Egypt",
    code: "EGY",
    code2: "EG",
    currency_name: "Egyptian pound",
    currency_code: "EGP",
    flag: "eg.svg",
  },
  {
    name: "Estonia",
    code: "EST",
    code2: "EE",
    currency_name: "Euro",
    currency_code: "EUR",
    flag: "ee.svg",
  },
  {
    name: "Finland",
    code: "FIN",
    code2: "FI",
    currency_name: "Euro",
    currency_code: "EUR",
    flag: "fi.svg",
  },
  {
    name: "Latvia",
    code: "LVA",
    code2: "LV",
    currency_name: "Latvian lats",
    currency_code: "LVL",
    flag: "lv.svg",
  },
  {
    name: "Luxembourg",
    code: "LUX",
    code2: "LU",
    currency_name: "Euro",
    currency_code: "EUR",
    flag: "lu.svg",
  },
  {
    name: "Montenegro",
    code: "MNE",
    code2: "ME",
    currency_name: "Euro",
    currency_code: "EUR",
    flag: "me.svg",
  },
  {
    name: "Serbia",
    code: "SRB",
    code2: "RS",
    currency_name: "Serbian dinar",
    currency_code: "RSD",
    flag: "rs.svg",
  },
  {
    name: "Ukraine",
    code: "UKR",
    code2: "UA",
    currency_name: "Ukrainian hryvnia",
    currency_code: "UAH",
    flag: "ua.svg",
  },
  {
    name: "Viet Nam",
    code: "VNM",
    code2: "VN",
    currency_name: "Vietnamese đồng",
    currency_code: "VND",
    flag: "vn.svg",
  },
].sort((a, b) => {
  var nameA = a.name.toUpperCase(); // ignore upper and lowercase
  var nameB = b.name.toUpperCase(); // ignore upper and lowercase
  if (nameA < nameB) {
    return -1;
  }
  if (nameA > nameB) {
    return 1;
  }
  // names must be equal
  return 0;
});

international_wire_payment_countries.forEach(function (obj) {
  obj.is_wire_type = true;
});

const currency_codes = [
  { value: "USD", label: "USD", name: "US Dollar", symbol: "$" },
  { value: "CAD", label: "CAD", name: "Canadian Dollar", symbol: "CA$" },
  { value: "EUR", label: "EUR", name: "Euro", symbol: "€" },
  { value: "AED", label: "AED", name: "United Arab Emirates Dirham", symbol: "د.إ." },
  { value: "ARS", label: "ARS", name: "Argentine Peso", symbol: "AR$" },
  { value: "AUD", label: "AUD", name: "Australian Dollar", symbol: "AU$" },
  { value: "BDT", label: "BDT", name: "Bangladeshi Taka", symbol: "৳" },
  { value: "BRL", label: "BRL", name: "Brazilian Real", symbol: "R$" },
  { value: "CDF", label: "CDF", name: "Congolese Franc", symbol: "CDF" },
  { value: "CNY", label: "CNY", name: "Chinese Yuan", symbol: "CN¥" },
  { value: "COP", label: "COP", name: "Colombian Peso", symbol: "CO$" },
  { value: "CZK", label: "CZK", name: "Czech Republic Koruna", symbol: "Kč" },
  { value: "DZD", label: "DZD", name: "Algerian Dinar", symbol: "د.ج." },
  { value: "EGP", label: "EGP", name: "Egyptian Pound", symbol: "ج.م." },
  { value: "GBP", label: "GBP", name: "British Pound Sterling", symbol: "£" },
  { value: "HKD", label: "HKD", name: "Hong Kong Dollar", symbol: "HK$" },
  { value: "IDR", label: "IDR", name: "Indonesian Rupiah", symbol: "Rp" },
  { value: "ILS", label: "ILS", name: "Israeli New Sheqel", symbol: "₪" },
  { value: "INR", label: "INR", name: "Indian Rupee", symbol: "₹" },
  { value: "IQD", label: "IQD", name: "Iraqi Dinar", symbol: "د.ع.‏" },
  { value: "IRR", label: "IRR", name: "Iranian Rial", symbol: "﷼" },
  { value: "JPY", label: "JPY", name: "Japanese Yen", symbol: "¥" },
  { value: "KRW", label: "KRW", name: "South Korean Won", symbol: "₩" },
  { value: "KWD", label: "KWD", name: "Kuwaiti Dinar", symbol: "د.ك." },
  { value: "LKR", label: "LKR", name: "Sri Lankan Rupee", symbol: "SLRs" },
  { value: "MXN", label: "MXN", name: "Mexican Peso", symbol: "MX$" },
  { value: "NZD", label: "NZD", name: "New Zealand Dollar", symbol: "NZ$" },
  { value: "RUB", label: "RUB", name: "Russian Ruble", symbol: "руб." },
  { value: "SGD", label: "SGD", name: "Singapore Dollar", symbol: "S$" },
  { value: "ZAR", label: "ZAR", name: "South African Rand", symbol: "R" },
  { value: "SEK", label: "SEK", name: "Swedish kron", symbol: "kr" },
  { value: "CHF", label: "CHF", name: "Swiss Franc", symbol: "CHF" },
  { value: "PHP", label: "PHP", name: "Philippines Peso", symbol: "₱" },
  { value: "DKK", label: "DKK", name: "Danish krone", symbol: "Kr." },
  { value: "NOK", label: "NOK", name: "Norwegian krone", symbol: "kr" },
  { value: "AFN", label: "AFN", name: "Afghanistan afghani", symbol: "Af" },
  { value: "ALL", label: "ALL", name: "Albanian lek", symbol: "L" },
  { value: "HUF", label: "HUF", name: "Hungarian forint", symbol: "Ft" },
  { value: "MYR", label: "MYR", name: "Malaysian ringgit", symbol: "RM" },
  { value: "MMK", label: "MMK", name: "Myanmar kyat", symbol: "K" },
  { value: "KPW", label: "KPW", name: "North Korean won", symbol: "₩" },
  { value: "PKR", label: "PKR", name: "Pakistani rupee", symbol: "₨" },
  { value: "SZL", label: "SZL", name: "Swaziland lilangeni", symbol: "L" },
  { value: "ZWL", label: "ZWL", name: "Zimbabwe dollar", symbol: "Z$" },
  { value: "TRY", label: "TRY", name: "Turkish lira", symbol: "₺" },
  { value: "SAR", label: "SAR", name: "Saudi riyal", symbol: "ر.س" },
  { value: "RON", label: "RON", name: "Romanian new leu", symbol: "lei" },
  { value: "AOR", label: "AOR", name: "Angolan kwanza reajustado", symbol: "AOR" },
  { value: "AMD", label: "AMD", name: "Armenian dram", symbol: "֏" },
  { value: "AWG", label: "AWG", name: "Aruban florin", symbol: "ƒ" },
  { value: "BSD", label: "BSD", name: "Bahamian dollar", symbol: "$" },
  { value: "BHD", label: "BHD", name: "Bahraini dinar", symbol: "ب.د" },
  { value: "BTN", label: "BTN", name: "Bhutan ngultrum", symbol: "Nu" },
  { value: "BOB", label: "BOB", name: "Bolivian boliviano", symbol: "$b" },
  { value: "BND", label: "BND", name: "Brunei dollar", symbol: "$" },
  { value: "BGN", label: "BGN", name: "Bulgarian lev", symbol: "лв" },
  { value: "KHR", label: "KHR", name: "Cambodian riel", symbol: "៛" },
  { value: "GEL", label: "GEL", name: "Georgian lari", symbol: "ლ" },
  { value: "JOD", label: "JOD", name: "Jordanian dinar", symbol: "د.ا" },
  { value: "LBP", label: "LBP", name: "Lebanese pound", symbol: "ل.ل." },
  { value: "LRD", label: "LRD", name: "Liberian Dollar", symbol: "$" },
  { value: "MNT", label: "MNT", name: "Mongolian tugrik", symbol: "₮" },
  { value: "NPR", label: "NPR", name: "Nepalese rupee", symbol: "₨" },
  { value: "MVR", label: "MVR", name: "Maldivian rufiyaa", symbol: "ރ." },
  { value: "BMD", label: "BMD", name: "Bermudian Dollar", symbol: "$" },
  { value: "CLP", label: "CLP", name: "Chilean Peso", symbol: "CLP$" },
  { value: "PEN", label: "PEN", name: "Peruvian Sol", symbol: "S/" },
  { value: "PLN", label: "PLN", name: "Polish Zloty", symbol: "zł" },
  { value: "MAD", label: "MAD", name: "Moroccan Dirham", symbol: "م.د." },
  { value: "THB", label: "THB", name: "Thai Baht", symbol: "฿" },
  { value: "CRC", label: "CRC", name: "Costa Rican Colon", symbol: "₡" },
  { value: "HRK", label: "HRK", name: "Croatian Kuna", symbol: "kn" },
  { value: "TWD", label: "TWD", name: "Taiwan dollar", symbol: "NT$" },
  { value: "RSD", label: "RSD", name: "Serbian dinar", symbol: "din" },
  { value: "KES", label: "KES", name: "Kenyan shilling", symbol: "Ksh" },
  { value: "ZMW", label: "ZMW", name: "Zambian Kwacha", symbol: "K" },
  { value: "NGN", label: "NGN", name: "Nigerian naira", symbol: "‎₦" },
  { value: "GHS", label: "GHS", name: "Ghanaian Cedi", symbol: "GH₵" },
  { value: "UGX", label: "UGX", name: "Uganda Shilling", symbol: "USh" },
  { value: "XAF", label: "XAF", name: "Central African CFA franc", symbol: "FCFA" },
  { value: "NAD", label: "NAD", name: "Namibian Dollar", symbol: "N$" },
  { value: "QAR", label: "QAR", name: "Qatari Riyal", symbol: "QR" },
  { value: "BWP", label: "BWP", name: "Botswanan Pula", symbol: "P" },
  { value: "KYD", label: "KYD", name: "Cayman Islands Dollar", symbol: "CI$" },
  { value: "MUR", label: "MUR", name: "Mauritian rupee", symbol: "Rs" },
  { value: "LSL", label: "LSL", name: "Lesotho Loti", symbol: "L" },
  { value: "PAB", label: "PAB", name: "Panamanian Balboa", symbol: "B/.", crypto: false },
  { value: "GTQ", label: "GTQ", name: "Guatemalan Quetzal", symbol: "Q", crypto: false },
];

const states = [
  {
    value: "USA",
    state_list: [
      { value: "AK", label: "Alaska" },
      { value: "AL", label: "Alabama" },
      { value: "AR", label: "Arkansas" },
      { value: "AS", label: "American Samoa" },
      { value: "AZ", label: "Arizona" },
      { value: "CA", label: "California" },
      { value: "CO", label: "Colorado" },
      { value: "CT", label: "Connecticut" },
      { value: "DC", label: "District of Columbia" },
      { value: "DE", label: "Delaware" },
      { value: "FL", label: "Florida" },
      { value: "GA", label: "Georgia" },
      { value: "GU", label: "Guam" },
      { value: "HI", label: "Hawaii" },
      { value: "IA", label: "Iowa" },
      { value: "ID", label: "Idaho" },
      { value: "IL", label: "Illinois" },
      { value: "IN", label: "Indiana" },
      { value: "KS", label: "Kansas" },
      { value: "KY", label: "Kentucky" },
      { value: "LA", label: "Louisiana" },
      { value: "MA", label: "Massachusetts" },
      { value: "MD", label: "Maryland" },
      { value: "ME", label: "Maine" },
      { value: "MI", label: "Michigan" },
      { value: "MN", label: "Minnesota" },
      { value: "MO", label: "Missouri" },
      { value: "MS", label: "Mississippi" },
      { value: "MT", label: "Montana" },
      { value: "NC", label: "North Carolina" },
      { value: "ND", label: "North Dakota" },
      { value: "NE", label: "Nebraska" },
      { value: "NH", label: "New Hampshire" },
      { value: "NJ", label: "New Jersey" },
      { value: "NM", label: "New Mexico" },
      { value: "NV", label: "Nevada" },
      { value: "NY", label: "New York" },
      { value: "OH", label: "Ohio" },
      { value: "OK", label: "Oklahoma" },
      { value: "OR", label: "Oregon" },
      { value: "PA", label: "Pennsylvania" },
      { value: "PR", label: "Puerto Rico" },
      { value: "RI", label: "Rhode Island" },
      { value: "SC", label: "South Carolina" },
      { value: "SD", label: "South Dakota" },
      { value: "TN", label: "Tennessee" },
      { value: "TX", label: "Texas" },
      { value: "UT", label: "Utah" },
      { value: "VA", label: "Virginia" },
      { value: "VI", label: "Virgin Islands" },
      { value: "VT", label: "Vermont" },
      { value: "WA", label: "Washington" },
      { value: "WI", label: "Wisconsin" },
      { value: "WV", label: "West Virginia" },
      { value: "WY", label: "Wyoming" },
    ],
  },
  {
    value: "CAN",
    state_list: [
      { value: "AB", label: "Alberta" },
      { value: "BC", label: "British Columbia" },
      { value: "MB", label: "Manitoba" },
      { value: "NB", label: "New Brunswick" },
      { value: "NL", label: "Newfoundland and Labrador" },
      { value: "NS", label: "Nova Scotia" },
      { value: "NU", label: "Nunavut" },
      { value: "NT", label: "Northwest Territories" },
      { value: "ON", label: "Ontario" },
      { value: "PE", label: "Prince Edward Island" },
      { value: "QC", label: "Quebec" },
      { value: "SK", label: "Saskatchewan" },
      { value: "YT", label: "Yukon" },
    ],
  },
  {
    value: "GBR",
    state_list: [
      { label: "Aberdeen City", value: "ABE" },
      { label: "Aberdeenshire", value: "ABD" },
      { label: "Angus", value: "ANS" },
      { label: "Antrim", value: "ANT" },
      { label: "Ards", value: "ARD" },
      { label: "Argyll and Bute", value: "AGB" },
      { label: "Armagh", value: "ARM" },
      { label: "Ballymena", value: "BLA" },
      { label: "Ballymoney", value: "BLY" },
      { label: "Banbridge", value: "BNB" },
      { label: "Barking and Dagenham", value: "BDG" },
      { label: "Barnet", value: "BNE" },
      { label: "Barnsley", value: "BNS" },
      { label: "Bath and North East Somerset", value: "BAS" },
      { label: "Bedfordshire", value: "BDF" },
      { label: "Belfast", value: "BFS" },
      { label: "Bexley", value: "BEX" },
      { label: "Birmingham", value: "BIR" },
      { label: "Blackburn with Darwen", value: "BBD" },
      { label: "Blackpool", value: "BPL" },
      { label: "Blaenau Gwent", value: "BGW" },
      { label: "Bolton", value: "BOL" },
      { label: "Bournemouth", value: "BMH" },
      { label: "Bracknell Forest", value: "BRC" },
      { label: "Bradford", value: "BRD" },
      { label: "Brent", value: "BEN" },
      { label: "Bridgend [Pen-y-bont ar Ogwr GB-POG]", value: "BGE" },
      { label: "Brighton and Hove", value: "BNH" },
      { label: "Bristol, City of", value: "BST" },
      { label: "Bromley", value: "BRY" },
      { label: "Buckinghamshire", value: "BKM" },
      { label: "Bury", value: "BUR" },
      { label: "Caerphilly [Caerffili GB-CAF]", value: "CAY" },
      { label: "Calderdale", value: "CLD" },
      { label: "Cambridgeshire", value: "CAM" },
      { label: "Camden", value: "CMD" },
      { label: "Cardiff [Caerdydd GB-CRD]", value: "CRF" },
      { label: "Carmarthenshire [Sir Gaerfyrddin GB-GFY]", value: "CMN" },
      { label: "Carrickfergus", value: "CKF" },
      { label: "Castlereagh", value: "CSR" },
      { label: "Ceredigion [Sir Ceredigion]", value: "CGN" },
      { label: "Cheshire", value: "CHS" },
      { label: "Clackmannanshire", value: "CLK" },
      { label: "Coleraine", value: "CLR" },
      { label: "Conwy", value: "CWY" },
      { label: "Cookstown", value: "CKT" },
      { label: "Cornwall", value: "CON" },
      { label: "Coventry", value: "COV" },
      { label: "Craigavon", value: "CGV" },
      { label: "Croydon", value: "CRY" },
      { label: "Cumbria", value: "CMA" },
      { label: "Darlington", value: "DAL" },
      { label: "Denbighshire [Sir Ddinbych GB-DDB]", value: "DEN" },
      { label: "Derby", value: "DER" },
      { label: "Derbyshire", value: "DBY" },
      { label: "Derry", value: "DRY" },
      { label: "Devon", value: "DEV" },
      { label: "Doncaster", value: "DNC" },
      { label: "Dorset", value: "DOR" },
      { label: "Down", value: "DOW" },
      { label: "Dudley", value: "DUD" },
      { label: "Dumfries and Galloway", value: "DGY" },
      { label: "Dundee City", value: "DND" },
      { label: "Dungannon", value: "DGN" },
      { label: "Durham", value: "DUR" },
      { label: "Ealing", value: "EAL" },
      { label: "East Ayrshire", value: "EAY" },
      { label: "East Dunbartonshire", value: "EDU" },
      { label: "East Lothian", value: "ELN" },
      { label: "East Renfrewshire", value: "ERW" },
      { label: "East Riding of Yorkshire", value: "ERY" },
      { label: "East Sussex", value: "ESX" },
      { label: "Edinburgh, City of", value: "EDH" },
      { label: "Eilean Siar", value: "ELS" },
      { label: "Enfield", value: "ENF" },
      { label: "Essex", value: "ESS" },
      { label: "Falkirk", value: "FAL" },
      { label: "Fermanagh", value: "FER" },
      { label: "Fife", value: "FIF" },
      { label: "Flintshire [Sir y Fflint GB-FFL]", value: "FLN" },
      { label: "Gateshead", value: "GAT" },
      { label: "Glasgow City", value: "GLG" },
      { label: "Gloucestershire", value: "GLS" },
      { label: "Greenwich", value: "GRE" },
      { label: "Gwynedd", value: "GWN" },
      { label: "Hackney", value: "HCK" },
      { label: "Halton", value: "HAL" },
      { label: "Hammersmith and Fulham", value: "HMF" },
      { label: "Hampshire", value: "HAM" },
      { label: "Haringey", value: "HRY" },
      { label: "Harrow", value: "HRW" },
      { label: "Hartlepool", value: "HPL" },
      { label: "Havering", value: "HAV" },
      { label: "Herefordshire, County of", value: "HEF" },
      { label: "Hertfordshire", value: "HRT" },
      { label: "Highland", value: "HLD" },
      { label: "Hillingdon", value: "HIL" },
      { label: "Hounslow", value: "HNS" },
      { label: "Inverclyde", value: "IVC" },
      { label: "Isle of Anglesey [Sir Ynys Môn GB-YNM]", value: "AGY" },
      { label: "Isle of Wight", value: "IOW" },
      { label: "Isles of Scilly", value: "IOS" },
      { label: "Islington", value: "ISL" },
      { label: "Kensington and Chelsea", value: "KEC" },
      { label: "Kent", value: "KEN" },
      { label: "Kingston upon Hull, City of", value: "KHL" },
      { label: "Kingston upon Thames", value: "KTT" },
      { label: "Kirklees", value: "KIR" },
      { label: "Knowsley", value: "KWL" },
      { label: "Lambeth", value: "LBH" },
      { label: "Lancashire", value: "LAN" },
      { label: "Larne", value: "LRN" },
      { label: "Leeds", value: "LDS" },
      { label: "Leicester", value: "LCE" },
      { label: "Leicestershire", value: "LEC" },
      { label: "Lewisham", value: "LEW" },
      { label: "Limavady", value: "LMV" },
      { label: "Lincolnshire", value: "LIN" },
      { label: "Lisburn", value: "LSB" },
      { label: "Liverpool", value: "LIV" },
      { label: "London, City of", value: "LND" },
      { label: "Luton", value: "LUT" },
      { label: "Magherafelt", value: "MFT" },
      { label: "Manchester", value: "MAN" },
      { label: "Medway", value: "MDW" },
      { label: "Merthyr Tydfil [Merthyr Tudful GB-MTU]", value: "MTY" },
      { label: "Merton", value: "MRT" },
      { label: "Middlesbrough", value: "MDB" },
      { label: "Midlothian", value: "MLN" },
      { label: "Milton Keynes", value: "MIK" },
      { label: "Monmouthshire [Sir Fynwy GB-FYN]", value: "MON" },
      { label: "Moray", value: "MRY" },
      { label: "Moyle", value: "MYL" },
      { label: "Neath Port Talbot [Castell-nedd Port Talbot GB-CTL]", value: "NTL" },
      { label: "Newcastle upon Tyne", value: "NET" },
      { label: "Newham", value: "NWM" },
      { label: "Newport [Casnewydd GB-CNW]", value: "NWP" },
      { label: "Newry and Mourne", value: "NYM" },
      { label: "Newtownabbey", value: "NTA" },
      { label: "Norfolk", value: "NFK" },
      { label: "North Ayrshire", value: "NAY" },
      { label: "North Down", value: "NDN" },
      { label: "North East Lincolnshire", value: "NEL" },
      { label: "North Lanarkshire", value: "NLK" },
      { label: "North Lincolnshire", value: "NLN" },
      { label: "North Somerset", value: "NSM" },
      { label: "North Tyneside", value: "NTY" },
      { label: "North Yorkshire", value: "NYK" },
      { label: "Northamptonshire", value: "NTH" },
      { label: "Northumberland", value: "NBL" },
      { label: "Nottingham", value: "NGM" },
      { label: "Nottinghamshire", value: "NTT" },
      { label: "Oldham", value: "OLD" },
      { label: "Omagh", value: "OMH" },
      { label: "Orkney Islands", value: "ORK" },
      { label: "Oxfordshire", value: "OXF" },
      { label: "Pembrokeshire [Sir Benfro GB-BNF]", value: "PEM" },
      { label: "Perth and Kinross", value: "PKN" },
      { label: "Peterborough", value: "PTE" },
      { label: "Plymouth", value: "PLY" },
      { label: "Poole", value: "POL" },
      { label: "Portsmouth", value: "POR" },
      { label: "Powys", value: "POW" },
      { label: "Reading", value: "RDG" },
      { label: "Redbridge", value: "RDB" },
      { label: "Redcar and Cleveland", value: "RCC" },
      { label: "Renfrewshire", value: "RFW" },
      { label: "Rhondda, Cynon, Taff [Rhondda, Cynon,Taf]", value: "RCT" },
      { label: "Richmond upon Thames", value: "RIC" },
      { label: "Rochdale", value: "RCH" },
      { label: "Rotherham", value: "ROT" },
      { label: "Rutland", value: "RUT" },
      { label: "Salford", value: "SLF" },
      { label: "Sandwell", value: "SAW" },
      { label: "Scottish Borders, The", value: "SCB" },
      { label: "Sefton", value: "SFT" },
      { label: "Sheffield", value: "SHF" },
      { label: "Shetland Islands", value: "ZET" },
      { label: "Shropshire", value: "SHR" },
      { label: "Slough", value: "SLG" },
      { label: "Solihull", value: "SOL" },
      { label: "Somerset", value: "SOM" },
      { label: "South Ayrshire", value: "SAY" },
      { label: "South Gloucestershire", value: "SGC" },
      { label: "South Lanarkshire", value: "SLK" },
      { label: "South Tyneside", value: "STY" },
      { label: "Southampton", value: "STH" },
      { label: "Southend-on-Sea", value: "SOS" },
      { label: "Southwark", value: "SWK" },
      { label: "St. Helens", value: "SHN" },
      { label: "Staffordshire", value: "STS" },
      { label: "Stirling", value: "STG" },
      { label: "Stockport", value: "SKP" },
      { label: "Stockton-on-Tees", value: "STT" },
      { label: "Stoke-on-Trent", value: "STE" },
      { label: "Strabane", value: "STB" },
      { label: "Suffolk", value: "SFK" },
      { label: "Sunderland", value: "SND" },
      { label: "Surrey", value: "SRY" },
      { label: "Sutton", value: "STN" },
      { label: "Swansea [Abertawe GB-ATA]", value: "SWA" },
      { label: "Swindon", value: "SWD" },
      { label: "Tameside", value: "TAM" },
      { label: "Telford and Wrekin", value: "TFW" },
      { label: "Thurrock", value: "THR" },
      { label: "Torbay", value: "TOB" },
      { label: "Torfaen [Tor-faen]", value: "TOF" },
      { label: "Tower Hamlets", value: "TWH" },
      { label: "Trafford", value: "TRF" },
      { label: "Vale of Glamorgan, The [Bro Morgannwg GB-BMG]", value: "VGL" },
      { label: "Wakefield", value: "WKF" },
      { label: "Walsall", value: "WLL" },
      { label: "Waltham Forest", value: "WFT" },
      { label: "Wandsworth", value: "WND" },
      { label: "Warrington", value: "WRT" },
      { label: "Warwickshire", value: "WAR" },
      { label: "West Berkshire", value: "WBK" },
      { label: "West Dunbartonshire", value: "WDU" },
      { label: "West Lothian", value: "WLN" },
      { label: "West Sussex", value: "WSX" },
      { label: "Westminster", value: "WSM" },
      { label: "Wigan", value: "WGN" },
      { label: "Wiltshire", value: "WIL" },
      { label: "Windsor and Maidenhead", value: "WNM" },
      { label: "Wirral", value: "WRL" },
      { label: "Wkingham", value: "WOK" },
      { label: "Wolverhampton", value: "WLV" },
      { label: "Worcestershire", value: "WOR" },
      { label: "Wrexham [Wrecsam GB-WRC]", value: "WRX" },
      { label: "York", value: "YOR" },
    ],
  },
  {
    value: "DEU",
    state_list: [
      { value: "BW", label: "Baden-Württemberg" },
      { value: "BY", label: "Bayern" },
      { value: "BE", label: "Berlin" },
      { value: "BB", label: "Brandenburg" },
      { value: "HB", label: "Bremen" },
      { value: "HH", label: "Hamburg" },
      { value: "HE", label: "Hessen" },
      { value: "MV", label: "Mecklenburg-Vorpommern" },
      { value: "NI", label: "Niedersachsen" },
      { value: "NW", label: "Nordrhein-Westfalen" },
      { value: "RP", label: "Rheinland-Pfalz" },
      { value: "SL", label: "Saarland" },
      { value: "SN", label: "Sachsen" },
      { value: "ST", label: "Sachsen-Anhalt" },
      { value: "SH", label: "Schleswig-Holstein" },
      { value: "TH", label: "Thüringen" },
    ],
  },
  {
    value: "NLD",
    state_list: [
      { value: "DR", label: "Drenthe" },
      { value: "FL", label: "Flevoland" },
      { value: "FR", label: "Friesland" },
      { value: "GD", label: "Gelderland" },
      { value: "GR", label: "Groningen" },
      { value: "LB", label: "Limburg" },
      { value: "NB", label: "Noord-Brabant" },
      { value: "NH", label: "Noord-Holland" },
      { value: "OV", label: "Overijssel" },
      { value: "UT", label: "Utrecht" },
      { value: "ZH", label: "Zuid-Holland" },
      { value: "ZL", label: "Zeeland" },
      { value: "AW", label: "Aruba" },
      { value: "BQ", label: "Bonaire" },
      { value: "BQ2", label: "Saba" },
      { value: "BQ3", label: "Sint Eustatius" },
      { value: "CW", label: "Curaçao" },
      { value: "SX", label: "Sint Maarten" },
    ],
  },
  {
    value: "IND",
    state_list: [
      { value: "AP", label: "Andhra Pradesh" },
      { value: "AR", label: "Arunachal Pradesh" },
      { value: "AS", label: "Assam" },
      { value: "BR", label: "Bihar" },
      { value: "CT", label: "Chhattisgarh" },
      { value: "GA", label: "Goa" },
      { value: "GJ", label: "Gujarat" },
      { value: "HR", label: "Haryana" },
      { value: "HP", label: "Himachal Pradesh" },
      { value: "JK", label: "Jammu and Kashmir" },
      { value: "JH", label: "Jharkhand" },
      { value: "KA", label: "Karnataka" },
      { value: "KL", label: "Kerala" },
      { value: "MP", label: "Madhya Pradesh" },
      { value: "MH", label: "Maharashtra" },
      { value: "MN", label: "Manipur" },
      { value: "ML", label: "Meghalaya" },
      { value: "MZ", label: "Mizoram" },
      { value: "NL", label: "Nagaland" },
      { value: "OR", label: "Odisha" },
      { value: "PB", label: "Punjab" },
      { value: "RJ", label: "Rajasthan" },
      { value: "SK", label: "Sikkim" },
      { value: "TN", label: "Tamil Nadu" },
      { value: "TG", label: "Telangana" },
      { value: "TR", label: "Tripura" },
      { value: "UP", label: "Uttar Pradesh" },
      { value: "UT", label: "Uttarakhand" },
      { value: "WB", label: "West Bengal" },
      { value: "AN", label: "Andaman and Nicobar Islands" },
      { value: "CH", label: "Chandigarh" },
      { value: "DN", label: "Dadra and Nagar Haveli" },
      { value: "DD", label: "Daman and Diu" },
      { value: "LD", label: "Lakshadweep" },
      { value: "DL", label: "National Capital Territory of Delhi" },
      { value: "PY", label: "Puducherry" },
    ],
  },
  {
    value: "BEL",
    state_list: [
      { value: "ANT", label: "Antwerpen" },
      { value: "HAI", label: "Henegouwen" },
      { value: "LIE", label: "Luik" },
      { value: "LIM", label: "Limburg" },
      { value: "LUX", label: "Luxemburg" },
      { value: "NAM", label: "Namen" },
      { value: "OVL", label: "Oost-Vlaanderen" },
      { value: "VBR", label: "Vlaams-Brabant" },
      { value: "WBR", label: "Waals-Brabant" },
      { value: "WVL", label: "West-Vlaanderen" },
    ],
  },
  {
    value: "IDN",
    state_list: [
      { value: "ID-AC", label: "Special Region of Aceh" },
      { value: "ID-BA", label: "Bali" },
      { value: "ID-BB", label: "Bangka–Belitung Islands" },
      { value: "ID-BT", label: "Banten" },
      { value: "ID-BE", label: "Bengkulu" },
      { value: "ID-JT", label: "Central Java" },
      { value: "ID-KT", label: "Central Kalimantan" },
      { value: "ID-ST", label: "Central Sulawesi" },
      { value: "ID-JI", label: "East Java" },
      { value: "ID-KI", label: "East Kalimantan" },
      { value: "ID-NT", label: "East Nusa Tenggara" },
      { value: "ID-GO", label: "Gorontalo" },
      { value: "ID-JK", label: "Jakarta Special Capital Region" },
      { value: "ID-JA", label: "Jambi" },
      { value: "ID-LA", label: "Lampung" },
      { value: "ID-MA", label: "Maluku" },
      { value: "ID-KU", label: "North Kalimantan" },
      { value: "ID-MU", label: "North Maluku" },
      { value: "ID-SA", label: "North Sulawesi" },
      { value: "ID-SU", label: "North Sumatra" },
      { value: "ID-PA", label: "Special Region of Papua" },
      { value: "ID-RI", label: "Riau" },
      { value: "ID-KR", label: "Riau Islands" },
      { value: "ID-SG", label: "Southeast Sulawesi" },
      { value: "ID-KS", label: "South Kalimantan" },
      { value: "ID-SN", label: "South Sulawesi" },
      { value: "ID-SS", label: "South Sumatra" },
      { value: "ID-JB", label: "West Java" },
      { value: "ID-KB", label: "West Kalimantan" },
      { value: "ID-NB", label: "West Nusa Tenggara" },
      { value: "ID-PB", label: "Special Region of West Papua" },
      { value: "ID-SR", label: "West Sulawesi" },
      { value: "ID-SB", label: "West Sumatra" },
      { value: "ID-YO", label: "Special Region of Yogyakarta" },
    ],
  },
  {
    value: "BRA",
    state_list: [
      { value: "AC", label: "Acre" },
      { value: "AL", label: "Alagoas" },
      { value: "AP", label: "Amapá" },
      { value: "AM", label: "Amazonas" },
      { value: "BA", label: "Bahia" },
      { value: "CE", label: "Ceará" },
      { value: "DF", label: "Distrito Federal" },
      { value: "ES", label: "Espírito Santo" },
      { value: "GO", label: "Goiás" },
      { value: "MA", label: "Maranhão" },
      { value: "MT", label: "Mato Grosso" },
      { value: "MS", label: "Mato Grosso do Sul" },
      { value: "MG", label: "Minas Gerais" },
      { value: "PA", label: "Pará" },
      { value: "PB", label: "Paraíba" },
      { value: "PR", label: "Paraná" },
      { value: "PE", label: "Pernambuco" },
      { value: "PI", label: "Piauí" },
      { value: "RJ", label: "Rio de Janeiro" },
      { value: "RN", label: "Rio Grande do Norte" },
      { value: "RS", label: "Rio Grande do Sul" },
      { value: "RO", label: "Rondônia" },
      { value: "RR", label: "Roraima" },
      { value: "SC", label: "Santa Catarina" },
      { value: "SP", label: "São Paulo" },
      { value: "SE", label: "Sergipe" },
      { value: "TO", label: "Tocantins" },
    ],
  },
  {
    value: "FRA",
    state_list: [
      { label: "Alsace", value: "AA" },
      { label: "Aquitaine", value: "AQ" },
      { label: "Auvergne", value: "AU" },
      { label: "Brittany", value: "BT" },
      { label: "Burgundy", value: "BG" },
      { label: "Centre", value: "CN" },
      { label: "Champagne-Ardenne", value: "CG" },
      { label: "Franche-Comté", value: "FC" },
      { label: "Île-de-France", value: "IF" },
      { label: "Languedoc-Roussillon", value: "LU" },
      { label: "Limousin", value: "LM" },
      { label: "Lorraine", value: "LE" },
      { label: "Lower Normandy", value: "BN" },
      { label: "Midi-Pyrénées", value: "MP" },
      { label: "Nord-Pas-de-Calais", value: "NP" },
      { label: "Pays de la Loire", value: "PL" },
      { label: "Picardy", value: "PI" },
      { label: "Poitou-Charentes", value: "PT" },
      { label: "Provence-Alpes-Côte d`Azur", value: "PR" },
      { label: "Rhône-Alpes", value: "RA" },
      { label: "Upper Normandy", value: "HT" },
      { label: "Corsica", value: "CE" },
    ],
  },
  {
    value: "UKR",
    state_list: [
      { value: "Avtonomna Respublika Krym", label: "Avtonomna Respublika Krym" },
      { value: "Cherkaska Oblast", label: "Cherkaska Oblast" },
      { value: "Chernivetska Oblast", label: "Chernivetska Oblast" },
      { value: "Chernihivska Oblast", label: "Chernihivska Oblast" },
      { value: "Dnipropetrovska Oblast", label: "Dnipropetrovska Oblast" },
      { value: "Donetska Oblast", label: "Donetska Oblast" },
      { value: "Ivano Frankivska Oblast", label: "Ivano Frankivska Oblast" },
      { value: "Kharkivska Oblast", label: "Kharkivska Oblast" },
      { value: "Khersonska Oblast", label: "Khersonska Oblast" },
      { value: "Khmelnytska Oblast", label: "Khmelnytska Oblast" },
      { value: "Kirovohradska Oblast", label: "Ternopilska Oblast" },
      { value: "Kyyivska Oblast", label: "Kyyivska Oblast" },
      { value: "Lvivska Oblast", label: "Lvivska Oblast" },
      { value: "Luhanska Oblast ", label: "Luhanska Oblast" },
      { value: "Misto Sevastopol", label: "Misto Sevastopol" },
      { value: "Misto Kyyiv", label: "Misto Kyyiv" },
      { value: "Mykolayivska Oblast", label: "Mykolayivska Oblast" },
      { value: "Odeska Oblast", label: "Odeska Oblast" },
      { value: "Poltavska Oblast", label: "Poltavska Oblast" },
      { value: "Rivnenska Oblast", label: "Rivnenska Oblast" },
      { value: "Sumska Oblast", label: "Sumska Oblast" },
      { value: "Ternopilska Oblast", label: "Ternopilska Oblast" },
      { value: "Vinnytska Oblast", label: "Vinnytska Oblast" },
      { value: "Volynska Oblast", label: "Volynska Oblast" },
      { value: "Zakarpatska Oblast", label: "Zakarpatska Oblast" },
      { value: "Zaporizka Oblast", label: "Zaporizka Oblast" },
      { value: "Zhytomyrska Oblast", label: "Zhytomyrska Oblast" },
    ],
  },
  {
    value: "AUS",
    state_list: [
      { value: "NSW", label: "New South Wales" },
      { value: "QLD", label: "Queensland" },
      { value: "SA", label: "South Australia" },
      { value: "TAS", label: "Tasmania" },
      { value: "VIC", label: "Victoria" },
      { value: "WA", label: "Western Australia" },
      { value: "ACT", label: "Australian Capital Territory" },
      { value: "NT", label: "Northern Territory" },
    ],
  },
  {
    value: "ITA",
    state_list: [
      { value: "AG", label: "Agrigento" },
      { value: "AL", label: "Alessandria" },
      { value: "AN", label: "Ancona" },
      { value: "AO", label: "Aosta" },
      { value: "AP", label: "Ascoli Piceno" },
      { value: "AQ", label: "LAquila" },
      { value: "AR", label: "Arezzo" },
      { value: "AT", label: "Asti" },
      { value: "AV", label: "Avellino" },
      { value: "BA", label: "Bari" },
      { value: "BG", label: "Bergamo" },
      { value: "BI", label: "Biella" },
      { value: "BL", label: "Belluno" },
      { value: "BN", label: "Benevento" },
      { value: "BO", label: "Bologna" },
      { value: "BR", label: "Brindisi" },
      { value: "BS", label: "Brescia" },
      { value: "BT", label: "Barletta-Andria-Trani" },
      { value: "BZ", label: "Bolzano" },
      { value: "CA", label: "Cagliari" },
      { value: "CB", label: "Campobasso" },
      { value: "CE", label: "Caserta" },
      { value: "CH", label: "Chieti" },
      { value: "CI", label: "Carbonia-Iglesias" },
      { value: "CL", label: "Caltanissetta" },
      { value: "CN", label: "Cuneo" },
      { value: "CO", label: "Como" },
      { value: "CR", label: "Cremona" },
      { value: "CS", label: "Cosenza" },
      { value: "CT", label: "Catania" },
      { value: "CZ", label: "Catanzaro" },
      { value: "EN", label: "Enna" },
      { value: "FC", label: "Forlì-Cesena" },
      { value: "FE", label: "Ferrara" },
      { value: "FG", label: "Foggia" },
      { value: "FI", label: "Florence" },
      { value: "FM", label: "Fermo" },
      { value: "FR", label: "Frosinone" },
      { value: "GE", label: "Genoa" },
      { value: "GO", label: "Gorizia" },
      { value: "GR", label: "Grosseto" },
      { value: "IM", label: "Imperia" },
      { value: "IS", label: "Isernia" },
      { value: "KR", label: "Crotone" },
      { value: "LC", label: "Lecco" },
      { value: "LE", label: "Lecce" },
      { value: "LI", label: "Livorno" },
      { value: "LO", label: "Lodi" },
      { value: "LT", label: "Latina" },
      { value: "LU", label: "Lucca" },
      { value: "MB", label: "Monza and Brianza" },
      { value: "MC", label: "Macerata" },
      { value: "ME", label: "Messina" },
      { value: "MI", label: "Milan" },
      { value: "MN", label: "Mantua" },
      { value: "MO", label: "Modena" },
      { value: "MS", label: "Massa and Carrara" },
      { value: "MT", label: "Matera" },
      { value: "NA", label: "Naples" },
      { value: "NO", label: "Novara" },
      { value: "NU", label: "Nuoro" },
      { value: "OG", label: "Ogliastra" },
      { value: "OR", label: "Oristano" },
      { value: "OT", label: "Olbia-Tempio" },
      { value: "PA", label: "Palermo" },
      { value: "PC", label: "Piacenza" },
      { value: "PD", label: "Padua" },
      { value: "PE", label: "Pescara" },
      { value: "PG", label: "Perugia" },
      { value: "PI", label: "Pisa" },
      { value: "PN", label: "Pordenone" },
      { value: "PO", label: "Prato" },
      { value: "PR", label: "Parma" },
      { value: "PT", label: "Pistoia" },
      { value: "PU", label: "Pesaro and Urbino" },
      { value: "PV", label: "Pavia" },
      { value: "PZ", label: "Potenza" },
      { value: "RA", label: "Ravenna" },
      { value: "RC", label: "Reggio Calabria" },
      { value: "RE", label: "Reggio Emilia" },
      { value: "RG", label: "Ragusa" },
      { value: "RI", label: "Rieti" },
      { value: "RM", label: "Rome" },
      { value: "RN", label: "Rimini" },
      { value: "RO", label: "Rovigo" },
      { value: "SA", label: "Salerno" },
      { value: "SI", label: "Siena" },
      { value: "SO", label: "Sondrio" },
      { value: "SP", label: "La Spezia" },
      { value: "SR", label: "Syracuse" },
      { value: "SS", label: "Sassari" },
      { value: "SV", label: "Savona" },
      { value: "TA", label: "Taranto" },
      { value: "TE", label: "Teramo" },
      { value: "TN", label: "Trento" },
      { value: "TO", label: "Turin" },
      { value: "TP", label: "Trapani" },
      { value: "TR", label: "Terni" },
      { value: "TS", label: "Trieste" },
      { value: "TV", label: "Treviso" },
      { value: "UD", label: "Udine" },
      { value: "VA", label: "Varese" },
      { value: "VB", label: "Verbano-Cusio-Ossola" },
      { value: "VC", label: "Vercelli" },
      { value: "VE", label: "Venice" },
      { value: "VI", label: "Vicenza" },
      { value: "VR", label: "Verona" },
      { value: "VS", label: "Medio Campidano" },
      { value: "VT", label: "Viterbo" },
      { value: "VV", label: "Vibo Valentia" },
    ],
  },
  {
    value: "IRL",
    state_list: [
      { value: "CE", label: "Clare" },
      { value: "CN", label: "Cavan" },
      { value: "CO", label: "Cork" },
      { value: "CW", label: "Carlow" },
      { value: "D", label: "Dublin" },
      { value: "DL", label: "Donegal" },
      { value: "G", label: "Galway" },
      { value: "KE", label: "Kildare" },
      { value: "KK", label: "Kilkenny" },
      { value: "KY", label: "Kerry" },
      { value: "LD", label: "Longford" },
      { value: "LH", label: "Louth" },
      { value: "LK", label: "Limerick" },
      { value: "LM", label: "Leitrim" },
      { value: "LS", label: "Laois" },
      { value: "MH", label: "Meath" },
      { value: "MN", label: "Monaghan" },
      { value: "MO", label: "Mayo" },
      { value: "OY", label: "Offaly" },
      { value: "RN", label: "Roscommon" },
      { value: "SO", label: "Sligo" },
      { value: "TA", label: "Tipperary" },
      { value: "WD", label: "Waterford" },
      { value: "WH", label: "Westmeath" },
      { value: "WW", label: "Wicklow" },
      { value: "WX", label: "Wexford" },
    ],
  },
  {
    value: "KOR",
    state_list: [
      { value: "Busan", label: "Busan" },
      { value: "Chungcheongbuk-do", label: "Chungcheongbuk-do" },
      { value: "Chungcheongnam-do", label: "Chungcheongnam-do" },
      { value: "Daegu", label: "Daegu" },
      { value: "Daejeon", label: "Daejeon" },
      { value: "Gangwon-do", label: "Gangwon-do" },
      { value: "Gwangju", label: "Gwangju" },
      { value: "Gyeonggi-do", label: "Gyeonggi-do" },
      { value: "Gyeongsangbuk-do", label: "Gyeongsangbuk-do" },
      { value: "Gyeongsangnam-do", label: "Gyeongsangnam-do" },
      { value: "Incheon", label: "Incheon" },
      { value: "Jeju-do", label: "Jeju-do" },
      { value: "Jeollabuk-do", label: "Jeollabuk-do" },
      { value: "Jeollanam-do", label: "Jeollanam-do" },
      { value: "Sejong", label: "Sejong" },
      { value: "Seoul", label: "Seoul" },
      { value: "Ulsan", label: "Ulsan" },
    ],
  },
  {
    value: "AUT",
    state_list: [
      { value: "BGL", label: "Burgenland" },
      { value: "KAE", label: "Carinthia" },
      { value: "NOE", label: "Lower Austria" },
      { value: "SBG", label: "Salzburg" },
      { value: "STM", label: "Styria" },
      { value: "TIR", label: "Tyrol" },
      { value: "OOE", label: "Upper Austria" },
      { value: "WIEN", label: "Vienna" },
      { value: "VBG", label: "Vorarlberg" },
    ],
  },
  {
    value: "CHN",
    state_list: [
      { value: "AH", label: "Anhui" },
      { value: "BJ", label: "Beijing" },
      { value: "CQ", label: "Chongqing" },
      { value: "FJ", label: "Fujian" },
      { value: "GS", label: "Gansu" },
      { value: "GD", label: "Guangdong" },
      { value: "GX", label: "Guangxi" },
      { value: "GZ", label: "Guizhou" },
      { value: "HI", label: "Hainan" },
      { value: "HE", label: "Hebei" },
      { value: "HL", label: "Heilongjiang" },
      { value: "HA", label: "Henan" },
      { value: "HK", label: "Hong Kong" },
      { value: "HB", label: "Hubei" },
      { value: "HN", label: "Hunan" },
      { value: "NM", label: "Inner Mongolia" },
      { value: "JS", label: "Jiangsu" },
      { value: "JX", label: "Jiangxi" },
      { value: "JL", label: "Jilin" },
      { value: "LN", label: "Liaoning" },
      { value: "MO", label: "Macau" },
      { value: "NX", label: "Ningxia" },
      { value: "QH", label: "Qinghai" },
      { value: "SN", label: "Shaanxi" },
      { value: "SD", label: "Shandong" },
      { value: "SH", label: "Shanghai" },
      { value: "SX", label: "Shanxi" },
      { value: "SC", label: "Sichuan" },
      { value: "TW", label: "Taiwan" },
      { value: "TJ", label: "Tianjin" },
      { value: "XZ", label: "Tibet" },
      { value: "XJ", label: "Xinjiang" },
      { value: "YN", label: "Yunnan" },
      { value: "ZJ", label: "Zhejiang" },
    ],
  },
  {
    value: "JPN",
    state_list: [
      { value: "Akita", label: "Akita" },
      { value: "Aomori", label: "Aomori" },
      { value: "Chiba", label: "Chiba" },
      { value: "Ehime", label: "Ehime" },
      { value: "Fukui", label: "Fukui" },
      { value: "Fukuoka", label: "Fukuoka" },
      { value: "Fukushima", label: "Fukushima" },
      { value: "Gifu", label: "Gifu" },
      { value: "Gunma", label: "Gunma" },
      { value: "Hiroshima", label: "Hiroshima" },
      { value: "Hokkaido", label: "Hokkaido" },
      { value: "Hyogo", label: "Hyogo" },
      { value: "Ibaragi", label: "Ibaragi" },
      { value: "Ibaraki", label: "Ibaraki" },
      { value: "Ishikawa", label: "Ishikawa" },
      { value: "Iwate", label: "Iwate" },
      { value: "Kagawa", label: "Kagawa" },
      { value: "Kagoshima", label: "Kagoshima" },
      { value: "Kanagawa", label: "Kanagawa" },
      { value: "Kochi", label: "Kochi" },
      { value: "Kumamoto", label: "Kumamoto" },
      { value: "Kyoto", label: "Kyoto" },
      { value: "Mie", label: "Mie" },
      { value: "Miyagi", label: "Miyagi" },
      { value: "Miyazaki", label: "Miyazaki" },
      { value: "Nagano", label: "Nagano" },
      { value: "Nagasaki", label: "Nagasaki" },
      { value: "Nara", label: "Nara" },
      { value: "Niigata", label: "Niigata" },
      { value: "Oita", label: "Oita" },
      { value: "Okinawa", label: "Okinawa" },
      { value: "Osaka", label: "Osaka" },
      { value: "Saga", label: "Saga" },
      { value: "Saitama", label: "Saitama" },
      { value: "Shiga", label: "Shiga" },
      { value: "Shimane", label: "Shimane" },
      { value: "Shizuoka", label: "Shizuoka" },
      { value: "Tochigi", label: "Tochigi" },
      { value: "Tokushima", label: "Tokushima" },
      { value: "Tokyo", label: "Tokyo" },
      { value: "Tottori", label: "Tottori" },
      { value: "Toyama", label: "Toyama" },
      { value: "Wakayama", label: "Wakayama" },
      { value: "Yamagata", label: "Yamagata" },
      { value: "Yamaguchi", label: "Yamaguchi" },
      { value: "Yamanashi", label: "Yamanashi" },
    ],
  },
  {
    value: "MYS",
    state_list: [
      { value: "Johor", label: "Johor" },
      { value: "Kedah", label: "Kedah" },
      { value: "Kelantan", label: "Kelantan" },
      { value: "KUALA LUMPUR", label: "KUALA LUMPUR" },
      { value: "Labuan", label: "Labuan" },
      { value: "Melaka", label: "Melaka" },
      { value: "Negeri Sembilan", label: "Negeri Sembilan" },
      { value: "Pahang", label: "Pahang" },
      { value: "Perak", label: "Perak" },
      { value: "Perlis", label: "Perlis" },
      { value: "Pulau Pinang", label: "Pulau Pinang" },
      { value: "Sabah", label: "Sabah" },
      { value: "Sarawak", label: "Sarawak" },
      { value: "Selangor", label: "Selangor" },
      { value: "Terengganu", label: "Terengganu" },
    ],
  },
  {
    value: "CHE",
    state_list: [
      { value: "AG", label: "Aargau" },
      { value: "AI", label: "Appenzell Inner Rhodes" },
      { value: "AR", label: "Appenzell Outer Rhodes" },
      { value: "BL", label: "Basel-Landschaft" },
      { value: "BS", label: "Basel-Stadt" },
      { value: "BE", label: "Bern" },
      { value: "FR", label: "Fribourg" },
      { value: "GE", label: "Geneva" },
      { value: "GL", label: "Glarus" },
      { value: "GR", label: "Graubünden" },
      { value: "JU", label: "Jura" },
      { value: "LU", label: "Lucerne" },
      { value: "NE", label: "Neuchâtel" },
      { value: "NW", label: "Nidwalden" },
      { value: "OW", label: "Obwalden" },
      { value: "SG", label: "Sankt Gallen" },
      { value: "SH", label: "Schaffhausen" },
      { value: "SZ", label: "Schwyz" },
      { value: "SO", label: "Solothurn" },
      { value: "TG", label: "Thurgau" },
      { value: "TI", label: "Ticino" },
      { value: "UR", label: "Uri" },
      { value: "VS", label: "Valais" },
      { value: "VD", label: "Vaud" },
      { value: "ZG", label: "Zug" },
      { value: "ZH", label: "Zurich" },
    ],
  },
  {
    value: "ISR",
    state_list: [
      { value: "HaDarom", label: "HaDarom" },
      { value: "Haifa", label: "Haifa" },
      { value: "HaMerkaz", label: "HaMerkaz" },
      { value: "HaZafon", label: "HaZafon" },
      { value: "Jerusalem", label: "Jerusalem" },
      { value: "Tel Aviv", label: "Tel Aviv" },
    ],
  },
  {
    value: "HKG",
    state_list: [
      { value: "Central and Western", label: "Central and Western" },
      { value: "Eastern", label: "Eastern" },
      { value: "Islands", label: "Islands" },
      { value: "Kowloon City", label: "Kowloon City" },
      { value: "Kwai Tsing", label: "Kwai Tsing" },
      { value: "Kwun Tong", label: "Kwun Tong" },
      { value: "North", label: "North" },
      { value: "Sai Kung", label: "Sai Kung" },
      { value: "Sham Shui Po", label: "Sham Shui Po" },
      { value: "Sha Tin", label: "Sha Tin" },
      { value: "Southern", label: "Southern" },
      { value: "Tai Po", label: "Tai Po" },
      { value: "Tsuen Wan", label: "Tsuen Wan" },
      { value: "Tuen Mun", label: "Tuen Mun" },
      { value: "Wan Chai", label: "Wan Chai" },
      { value: "Wong Tai Sin", label: "Wong Tai Sin" },
      { value: "Yau Tsim Mong", label: "Yau Tsim Mong" },
      { value: "Yuen Long", label: "Yuen Long" },
    ],
  },
  {
    value: "CZE",
    state_list: [
      { value: "Prague", label: "Prague" },
      { value: "Central Bohemian", label: "Central Bohemian" },
      { value: "South Bohemian", label: "South Bohemian" },
      { value: "Plzeň", label: "Plzeň" },
      { value: "Karlovy Vary", label: "Karlovy Vary" },
      { value: "Ústí nad Labem", label: "Ústí nad Labem" },
      { value: "Liberec", label: "Liberec" },
      { value: "Hradec Králové", label: "Hradec Králové" },
      { value: "Pardubice", label: "Pardubice" },
      { value: "Olomouc", label: "Olomouc" },
      { value: "Moravian-Silesian", label: "Moravian-Silesian" },
      { value: "South Moravian", label: "South Moravian" },
      { value: "Zlín", label: "Zlín" },
      { value: "Vysočina", label: "Vysočina" },
    ],
  },
  {
    value: "SWE",
    state_list: [
      { value: "BL", label: "Blechingia" },
      { value: "KO", label: "Dalecarlia" },
      { value: "GV", label: "Gevalia" },
      { value: "GT", label: "Gotlandia" },
      { value: "HA", label: "Hallandia" },
      { value: "JA", label: "Jemtia" },
      { value: "JO", label: "Junecopia " },
      { value: "KA", label: "Calmaria " },
      { value: "KR", label: "Kronoberg " },
      { value: "NB", label: "Norbothnia" },
      { value: "OR", label: "Orebrogia" },
      { value: "OG", label: "Ostrogothia" },
      { value: "SN", label: "Scania" },
      { value: "SD", label: "Sudermannia" },
      { value: "ST", label: "Stockholm" },
      { value: "UP", label: "Uppsala" },
      { value: "VR", label: "Wermlandia" },
      { value: "VM", label: "Westmannia" },
      { value: "VB", label: "Westrobothnia" },
      { value: "VG", label: "Westrogothia" },
    ],
  },
  {
    value: "DNK",
    state_list: [
      { value: "DK011", label: "Copenhagen City" },
      { value: "DK012", label: "Copenhagen surroundings" },
      { value: "DK013", label: "North Zealand" },
      { value: "DK014", label: "Bornholm" },
      { value: "DK021", label: "East Zealand" },
      { value: "DK022", label: "West & South Zealand" },
      { value: "DK031", label: "Funen" },
      { value: "DK032", label: "South Jutland" },
      { value: "DK041", label: "West Jutland" },
      { value: "DK042", label: "East Jutland" },
      { value: "DK051", label: "North Jutland" },
    ],
  },
  {
    value: "NZL",
    state_list: [
      { value: "Auckland", label: "Auckland" },
      { value: "Canterbury", label: "Canterbury" },
      { value: "Hawke's Bay", label: "Hawke's Bay" },
      { value: "Marlborough", label: "Marlborough" },
      { value: "Nelson", label: "Nelson" },
      { value: "New Leinster", label: "New Leinster" },
      { value: "New Munster", label: "New Munster" },
      { value: "New Ulster", label: "New Ulster" },
      { value: "Otago", label: "Otago" },
      { value: "Southland", label: "Southland" },
      { value: "Superintendent (New Zealand)", label: "Superintendent (New Zealand)" },
      { value: "Taranaki", label: "Taranaki" },
      { value: "Wellington", label: "Wellington" },
      { value: "Westland County", label: "Westland County" },
      { value: "Westland Province", label: "Westland Province" },
    ],
  },
  {
    value: "SGP",
    state_list: [
      { value: "Bishan", label: "Bishan" },
      { value: "Bukit Merah", label: "Bukit Merah" },
      { value: "Bukit Timah", label: "Bukit Timah" },
      { value: "Downtown Core", label: "Downtown Core" },
      { value: "Geylang", label: "Geylang" },
      { value: "Kallang", label: "Kallang" },
      { value: "Marina East", label: "Marina East" },
      { value: "Marina South", label: "Marina South" },
      { value: "Marine Parade", label: "Marine Parade" },
      { value: "Museum", label: "Museum" },
      { value: "Newton", label: "Newton" },
      { value: "Novena", label: "Novena" },
      { value: "Orchard", label: "Orchard" },
      { value: "Outram", label: "Outram" },
      { value: "Queenstown", label: "Queenstown" },
      { value: "River Valley", label: "River Valley" },
      { value: "Rochor", label: "Rochor" },
      { value: "Singapore River", label: "Singapore River" },
      { value: "Southern Islands", label: "Southern Islands" },
      { value: "Tanglin", label: "Tanglin" },
      { value: "Toa Payoh", label: "Toa Payoh" },
      { value: "Bedok", label: "Bedok" },
      { value: "Changi", label: "Changi" },
      { value: "Pasir Ris", label: "Pasir Ris" },
      { value: "Paya Lebar", label: "Paya Lebar" },
      { value: "Tampines", label: "Tampines" },
      { value: "Mandai", label: "Mandai" },
      { value: "Sembawang", label: "Sembawang" },
      { value: "Simpang", label: "Simpang" },
      { value: "Sungei Kadut", label: "Sungei Kadut" },
      { value: "Woodlands", label: "Woodlands" },
      { value: "Yishun", label: "Yishun" },
      { value: "Ang Mo Kio", label: "Ang Mo Kio" },
      { value: "Hougang", label: "Hougang" },
      { value: "Punggol", label: "Punggol" },
      { value: "Seletar", label: "Seletar" },
      { value: "Sengkang", label: "Sengkang" },
      { value: "Serangoon", label: "Serangoon" },
      { value: "Boon Lay", label: "Boon Lay" },
      { value: "Bukit Batok", label: "Bukit Batok" },
      { value: "Bukit Panjang", label: "Bukit Panjang" },
      { value: "Choa Chu Kang", label: "Choa Chu Kang" },
      { value: "Clementi", label: "Clementi" },
      { value: "Jurong East", label: "Jurong East" },
      { value: "Jurong West", label: "Jurong West" },
      { value: "Pioneer", label: "Pioneer" },
      { value: "Tuas", label: "Tuas" },
      { value: "Western Islands", label: "Western Islands" },
    ],
  },
  {
    value: "PHL",
    state_list: [
      { value: "Cavite", label: "Cavite" },
      { value: "Bulacan", label: "Bulacan" },
      { value: "Negros Occidental", label: "Negros Occidental" },
      { value: "Laguna", label: "Laguna" },
      { value: "Pangasinan", label: "Pangasinan" },
      { value: "Cebu", label: "Cebu" },
      { value: "Rizal", label: "Rizal" },
      { value: "Batangas", label: "Batangas" },
      { value: "Pampanga", label: "Pampanga" },
      { value: "Iloilo", label: "Iloilo" },
      { value: "Davao del Sur", label: "Davao del Sur" },
      { value: "Nueva Ecija", label: "Nueva Ecija" },
      { value: "Quezon", label: "Quezon" },
      { value: "Leyte", label: "Leyte" },
      { value: "Camarines Sur", label: "Camarines Sur" },
      { value: "Zamboanga del Sur", label: "Zamboanga del Sur" },
      { value: "Isabela", label: "Isabela" },
      { value: "Misamis Oriental", label: "Misamis Oriental" },
      { value: "South Cotabato", label: "South Cotabato" },
      { value: "Maguindanao", label: "Maguindanao" },
      { value: "Bukidnon", label: "Bukidnon" },
      { value: "Cotabato", label: "Cotabato" },
      { value: "Tarlac", label: "Tarlac" },
      { value: "Negros Oriental", label: "Negros Oriental" },
      { value: "Albay", label: "Albay" },
      { value: "Bohol", label: "Bohol" },
      { value: "Cagayan", label: "Cagayan" },
      { value: "Palawan", label: "Palawan" },
      { value: "Lanao del Sur", label: "Lanao del Sur" },
      { value: "Lanao del Norte", label: "Lanao del Norte" },
      { value: "Davao del Norte", label: "Davao del Norte" },
      { value: "Zamboanga del Norte", label: "Zamboanga del Norte" },
      { value: "Masbate", label: "Masbate" },
      { value: "Oriental Mindoro", label: "Oriental Mindoro" },
      { value: "Sulu", label: "Sulu" },
      { value: "Zambales", label: "Zambales" },
      { value: "Sultan Kudarat", label: "Sultan Kudarat" },
      { value: "Sorsogon", label: "Sorsogon" },
      { value: "Benguet", label: "Benguet" },
      { value: "La Union", label: "La Union" },
      { value: "Samar", label: "Samar" },
      { value: "Capiz", label: "Capiz" },
      { value: "Bataan", label: "Bataan" },
      { value: "Compostela Valley", label: "Compostela Valley" },
      { value: "Agusan del Sur", label: "Agusan del Sur" },
      { value: "Agusan del Norte", label: "Agusan del Norte" },
      { value: "Ilocos Sur", label: "Ilocos Sur" },
      { value: "Zamboanga Sibugay", label: "Zamboanga Sibugay" },
      { value: "Northern Samar", label: "Northern Samar" },
      { value: "Misamis Occidental", label: "Misamis Occidental" },
      { value: "Ilocos Norte", label: "Ilocos Norte" },
      { value: "Surigao del Sur", label: "Surigao del Sur" },
      { value: "Camarines Norte", label: "Camarines Norte" },
      { value: "Antique", label: "Antique" },
      { value: "Aklan", label: "Aklan" },
      { value: "Davao Oriental", label: "Davao Oriental" },
      { value: "Sarangani", label: "Sarangani" },
      { value: "Occidental Mindoro", label: "Occidental Mindoro" },
      { value: "Surigao del Norte", label: "Surigao del Norte" },
      { value: "Eastern Samar", label: "Eastern Samar" },
      { value: "Basilan", label: "Basilan" },
      { value: "Nueva Vizcaya", label: "Nueva Vizcaya" },
      { value: "Southern Leyte", label: "Southern Leyte" },
      { value: "Tawi-Tawi", label: "Tawi-Tawi" },
      { value: "Davao Occidental", label: "Davao Occidental" },
      { value: "Romblon", label: "Romblon" },
      { value: "Catanduanes", label: "Catanduanes" },
      { value: "Abra", label: "Abra" },
      { value: "Marinduque", label: "Marinduque" },
      { value: "Aurora", label: "Aurora" },
      { value: "Kalinga", label: "Kalinga" },
      { value: "Ifugao", label: "Ifugao" },
      { value: "Quirino", label: "Quirino" },
      { value: "Guimaras", label: "Guimaras" },
      { value: "Biliran", label: "Biliran" },
      { value: "Mountain Province", label: "Mountain Province" },
      { value: "Dinagat Islands", label: "Dinagat Islands" },
      { value: "Apayao", label: "Apayao" },
      { value: "Siquijor", label: "Siquijor" },
      { value: "Camiguin", label: "Camiguin" },
      { value: "Batanes", label: "Batanes" },
    ],
  },
  {
    value: "AFG",
    state_list: [
      { value: "Badakhshan", label: "Badakhshan" },
      { value: "Badghis", label: "Badghis" },
      { value: "Baghlan", label: "Baghlan" },
      { value: "Balkh", label: "Balkh" },
      { value: "Bamyan", label: "Bamyan" },
      { value: "Daykundi", label: "Daykundi" },
      { value: "Farah", label: "Farah" },
      { value: "Faryab", label: "Faryab" },
      { value: "Ghazni", label: "Ghazni" },
      { value: "Ghor", label: "Ghor" },
      { value: "Helmand", label: "Helmand" },
      { value: "Herat", label: "Herat" },
      { value: "Jowzjan", label: "Jowzjan" },
      { value: "Kabul", label: "Kabul" },
      { value: "Kandahar", label: "Kandahar" },
      { value: "Kapisa", label: "Kapisa" },
      { value: "Khost", label: "Khost" },
      { value: "Kunar", label: "Kunar" },
      { value: "Kunduz", label: "Kunduz" },
      { value: "Laghman", label: "Laghman" },
      { value: "Logar", label: "Logar" },
      { value: "Wardak", label: "Wardak" },
      { value: "Nangarhar", label: "Nangarhar" },
      { value: "Nimruz", label: "Nimruz" },
      { value: "Nuristan", label: "Nuristan" },
      { value: "Paktia", label: "Paktia" },
      { value: "Paktika", label: "Paktika" },
      { value: "Panjshir", label: "Panjshir" },
      { value: "Parwan", label: "Parwan" },
      { value: "Samangan", label: "Samangan" },
      { value: "Sar-e Pol", label: "Sar-e Pol" },
      { value: "Takhar", label: "Takhar" },
      { value: "Urozgan", label: "Urozgan" },
      { value: "Zabul", label: "Zabul" },
    ],
  },
  {
    value: "ALB",
    state_list: [
      { value: "Berat ", label: "Berat " },
      { value: "Diber ", label: "Diber " },
      { value: "Durres", label: "Durres" },
      { value: "Elbasan", label: "Elbasan" },
      { value: "Fier", label: "Fier" },
      { value: "Gjirokaster", label: "Gjirokaster" },
      { value: "Korce", label: "Korce" },
      { value: "Kukes", label: "Kukes" },
      { value: "Lezhe ", label: "Lezhe " },
      { value: "Shkoder ", label: "Shkoder " },
      { value: "Tirana", label: "Tirana" },
      { value: "Vlore ", label: "Vlore " },
    ],
  },
  {
    value: "DZA",
    state_list: [
      { value: "Adrar", label: "Adrar" },
      { value: "Chlef", label: "Chlef" },
      { value: "Laghouat", label: "Laghouat" },
      { value: "Oum el-Bouaghi", label: "Oum el-Bouaghi" },
      { value: "Batna", label: "Batna" },
      { value: "Béjaïa", label: "Béjaïa" },
      { value: "Biskra", label: "Biskra" },
      { value: "Béchar", label: "Béchar" },
      { value: "Blida", label: "Blida" },
      { value: "Bouïra", label: "Bouïra" },
      { value: "Tamanghasset", label: "Tamanghasset" },
      { value: "Tébessa", label: "Tébessa" },
      { value: "Tlemcen", label: "Tlemcen" },
      { value: "Tiaret", label: "Tiaret" },
      { value: "Tizi Ouzou", label: "Tizi Ouzou" },
      { value: "Algiers", label: "Algiers" },
      { value: "Djelfa", label: "Djelfa" },
      { value: "Jijel", label: "Jijel" },
      { value: "Sétif", label: "Sétif" },
      { value: "Saïda", label: "Saïda" },
      { value: "Skikda", label: "Skikda" },
      { value: "Sidi Bel Abbès", label: "Sidi Bel Abbès" },
      { value: "Annaba", label: "Annaba" },
      { value: "Guelma", label: "Guelma" },
      { value: "Constantine", label: "Constantine" },
      { value: "Médéa", label: "Médéa" },
      { value: "Mostaganem", label: "Mostaganem" },
      { value: "M'Sila", label: "M'Sila" },
      { value: "Mascara", label: "Mascara" },
      { value: "Ouargla", label: "Ouargla" },
      { value: "Oran", label: "Oran" },
      { value: "El Bayadh", label: "El Bayadh" },
      { value: "Illizi", label: "Illizi" },
      { value: "Bordj Bou Arréridj", label: "Bordj Bou Arréridj" },
      { value: "Boumerdès", label: "Boumerdès" },
      { value: "El Taref", label: "El Taref" },
      { value: "Tindouf", label: "Tindouf" },
      { value: "Tissemsilt", label: "Tissemsilt" },
      { value: "El Oued", label: "El Oued" },
      { value: "Khenchela", label: "Khenchela" },
      { value: "Souk Ahras", label: "Souk Ahras" },
      { value: "Tipaza", label: "Tipaza" },
      { value: "Mila", label: "Mila" },
      { value: "Aïn Defla", label: "Aïn Defla" },
      { value: "Naâma", label: "Naâma" },
      { value: "Aïn Témouchent", label: "Aïn Témouchent" },
      { value: "Ghardaïa", label: "Ghardaïa" },
      { value: "Relizane", label: "Relizane" },
    ],
  },
  {
    value: "HUN",
    state_list: [
      { value: "Bacs Kiskun ", label: "Bacs Kiskun " },
      { value: "Baranya", label: "Baranya" },
      { value: "Bekes", label: "Bekes" },
      { value: "Borsod Abauj Zemplen", label: "Borsod Abauj Zemplen" },
      { value: "Budapest", label: "Budapest" },
      { value: "Csongrad", label: "Csongrad" },
      { value: "Fejer", label: "Fejer" },
      { value: "Gyor Moson Sopron", label: "Gyor Moson Sopron" },
      { value: "Hajdu Bihar ", label: "Hajdu Bihar " },
      { value: "Heves", label: "Heves" },
      { value: "Jasz Nagykun Szolnok", label: "Jasz Nagykun Szolnok" },
      { value: "Komarom Esztergom", label: "Komarom Esztergom" },
      { value: "Nograd", label: "Nograd" },
      { value: "Pest ", label: "Pest " },
      { value: "Somogy", label: "Somogy" },
      { value: "Szabolcs Szatmar Bereg", label: "Szabolcs Szatmar Bereg" },
      { value: "Tolna", label: "Tolna" },
      { value: "Vas", label: "Vas" },
      { value: "Veszprem", label: "Veszprem" },
      { value: "Zala ", label: "Zala " },
    ],
  },
  {
    value: "MYS",
    state_list: [
      { value: "Perlis", label: "Perlis" },
      { value: "Kedah", label: "Kedah" },
      { value: "Terengganu", label: "Terengganu" },
      { value: "Pahang", label: "Pahang" },
      { value: "Perak", label: "Perak" },
      { value: "Kelantan", label: "Kelantan" },
      { value: "Penang", label: "Penang" },
      { value: "Selangor", label: "Selangor" },
      { value: "Negeri Sembilan", label: "Negeri Sembilan" },
      { value: "Johor", label: "Johor" },
      { value: "Malacca", label: "Malacca" },
      { value: "Sabah", label: "Sabah" },
      { value: "Sarawak", label: "Sarawak" },
    ],
  },
  {
    value: "ESP",
    state_list: [
      { value: "Cádiz", label: "Cádiz" },
      { value: "Cordoba", label: "Cordoba" },
      { value: "Granada", label: "Granada" },
      { value: "Malaga", label: "Malaga" },
      { value: "Seville", label: "Seville" },
      { value: "Huelva", label: "Huelva" },
      { value: "Jaén", label: "Jaén" },
      { value: "Almeria", label: "Almeria" },
      { value: "Zaragoza", label: "Zaragoza" },
      { value: "Huesca", label: "Huesca" },
      { value: "Teruel", label: "Teruel" },
      { value: "Asturias", label: "Asturias" },
      { value: "Balearic Islands", label: "Balearic Islands" },
      { value: "Vizcaya", label: "Vizcaya" },
      { value: "Álava", label: "Álava" },
      { value: "Guipúzcua", label: "Guipúzcua" },
      { value: "Las Palmas", label: "Las Palmas" },
      { value: "Santa Cruz de Tenerife", label: "Santa Cruz de Tenerife" },
      { value: "Cantabria", label: "Cantabria" },
      { value: "Guadalajara", label: "Guadalajara" },
      { value: "Toledo", label: "Toledo" },
      { value: "Cuenca", label: "Cuenca" },
      { value: "Ciudad Real", label: "Ciudad Real" },
      { value: "Albacete", label: "Albacete" },
      { value: "Léon", label: "Léon" },
      { value: "Burgos", label: "Burgos" },
      { value: "Zamora", label: "Zamora" },
      { value: "Valladolid", label: "Valladolid" },
      { value: "Segovia", label: "Segovia" },
      { value: "Soria", label: "Soria" },
      { value: "Salamanca", label: "Salamanca" },
      { value: "Avila", label: "Avila" },
      { value: "Barcelona", label: "Barcelona" },
      { value: "Girona", label: "Girona" },
      { value: "Lleida", label: "Lleida" },
      { value: "Tarragona", label: "Tarragona" },
      { value: "Caceres", label: "Caceres" },
      { value: "Badajoz", label: "Badajoz" },
      { value: "A Coruña", label: "A Coruña" },
      { value: "Pontevedra", label: "Pontevedra" },
      { value: "Lugo", label: "Lugo" },
      { value: "Orense", label: "Orense" },
      { value: "La Rioja", label: "La Rioja" },
      { value: "Madrid", label: "Madrid" },
      { value: "Murcia", label: "Murcia" },
      { value: "Navarra", label: "Navarra" },
      { value: "Valencia", label: "Valencia" },
      { value: "Castellon", label: "Castellon" },
      { value: "Alicante", label: "Alicante" },
      { value: "Navarra", label: "Navarra" },
    ],
  },
];

export const time_zones = [
  { value: "Australia/Perth", label: "Australia/Perth" },
  { value: "Australia/Eucla", label: "Australia/Eucla" },
  { value: "Australia/Darwin", label: "Australia/Darwin" },
  { value: "Australia/Brisbane", label: "Australia/Brisban" },
  { value: "Australia/Adelaide", label: "Australia/Adelaide" },
  { value: "Australia/Sydney", label: "Australia/Sydney" },
  { value: "US/Pacific", label: "US/Pacific" },
  { value: "US/Mountain", label: "US/Mountain" },
  { value: "US/Central", label: "US/Central" },
  { value: "US/Eastern", label: "US/Eastern" },
  { value: "Europe/Zurich", label: "Europe/Zurich" },
  { value: "Europe/London", label: "Europe/London" },
  { label: "Asia/Kolkata", value: "Asia/Kolkata" },
  { label: "Asia/Jakarta", value: "Asia/Jakarta" },
  { label: "Asia/Jayapura", value: "Asia/Jayapura" },
  { label: "Asia/Singapore", value: "Asia/Singapore" },
  { value: "PRC", label: "China/Beijing" },
];

export const file_types = [
  { value: ".png", label: "PNG" },
  { value: ".jpeg", label: "JPEG" },
  { value: ".jpg", label: "JPG" },
  { value: ".gif", label: "GIFJ" },
  { value: ".tiff", label: "TIFF" },
  { value: ".xls", label: "XLS" },
  { value: ".xlsx", label: "XLSX" },
  { value: ".txt", label: "TXT" },
  { value: ".doc", label: "DOC" },
  { value: ".docx", label: "DOCX" },
];

const getCurrencyCodeFromCountryCode = (countryCode) => {
  if (!countryCode) {
    return null;
  }
  let foundCountry = international_payment_countries.find((country) => country.code == countryCode);
  return foundCountry ? foundCountry.currency_code : null;
};

const vendorAcceptedPaymentMethodsKeyLabel = [
  {
    key: "ach",
    value: "ach",
    label: "ACH - Bank Account (USA)",
  },
  {
    key: "check",
    value: "check",
    label: "Check - Bank Account",
  },
  {
    key: "credit_card",
    value: "credit_card",
    label: "Credit Card",
  },
  {
    key: "fed_wire",
    value: "fed-wire",
    label: "Domestic/Fedwire - Wire",
  },
  {
    key: "can_eft",
    value: "can-eft",
    label: "EFT- Bank Account (CANADA)",
  },
  {
    key: "uk_eft",
    value: "uk-eft",
    label: "EFT- Bank Account (UK Domestic)",
  },
  {
    key: "aus_pay_net",
    value: "aus-pay-net",
    label: "EFT- Australian PayNet",
  },
  {
    key: "nz_eft",
    value: "nz-eft",
    label: "EFT- Bank Account (New Zealand)",
  },
  {
    key: "japan_eft",
    value: "japan-eft",
    label: "EFT- Japanese Local Payment",
  },
  {
    key: "transferwise",
    value: "international",
    label: "International Bank Account - TW",
  },
  {
    key: "payoneer",
    value: "payoneer",
    label: "International Bank Account - Pay",
  },
  {
    key: "wire",
    value: "wire",
    label: "International Cross-border - AI/Wire",
  },
  {
    key: "sepa",
    value: "sepa",
    label: "International - SEPA",
  },
  {
    key: "cambridge",
    value: "cambridge",
    label: "International - Cambridge",
  },
  {
    key: "swift_wire",
    value: "swift-wire",
    label: "Swift - Wire",
  },
  {
    key: "external",
    value: "external",
    label: "External Payment",
  },
].sort(compare);

const paymentTypes = [
  {
    value: "ach",
    label: "ACH - Bank Account",
    isAccountPayout: true,
  },
  {
    value: "can-eft",
    label: "EFT- Bank Account (CANADA)",
    isAccountPayout: true,
  },
  {
    value: "aus-pay-net",
    label: "Australia PayNet",
    isAccountPayout: true,
  },
  {
    value: "nz-eft",
    label: "New Zealand EFT",
    isAccountPayout: true,
  },
  {
    value: "check",
    label: "Check - Bank Account",
    isCheckPayout: true,
  },
  {
    value: "international",
    label: "International - Bank Account - TW",
    isInternationalPayout: true,
  },
  {
    value: "payoneer",
    label: "International - Bank Account - Pay",
    isInternationalPayout: true,
  },
  {
    value: "cambridge",
    label: "International - Bank Account - Cambridge",
    isInternationalPayout: true,
  },
  {
    value: "wire",
    label: "International - AI/Wire",
    isInternationalPayout: true,
  },
  {
    value: "fed-wire",
    label: "Domestic/Fedwire - Wire",
    isInternationalPayout: true,
  },
  {
    value: "sepa",
    label: "SEPA",
    isInternationalPayout: true,
  },
  {
    value: "uk-eft",
    label: "UK EFT",
    isInternationalPayout: true,
  },
  {
    value: "sg-eft",
    label: "Singapore EFT",
    isAccountPayout: true,
  },
  {
    value: "credit_card",
    label: "Virtual Card",
  },
].sort(compare);

const countries = [
  { value: "USA", label: "United States", currencies: ["USD"], flag: "US" },
  { value: "ABW", label: "Aruba", flag: "AW" },
  { value: "AFG", label: "Afghanistan", flag: "AF" },
  { value: "AGO", label: "Angola", flag: "AO" },
  { value: "AIA", label: "Anguilla", flag: "AI" },
  { value: "ALA", label: "Åland Islands", flag: "AX" },
  { value: "ALB", label: "Albania", flag: "AL" },
  { value: "AND", label: "Andorra", flag: "AD" },
  { value: "ARE", label: "United Arab Emirates", flag: "AE" },
  { value: "ARG", label: "Argentina", flag: "AR" },
  { value: "ARM", label: "Armenia", flag: "AM" },
  { value: "ASM", label: "American Samoa", flag: "AS" },
  { value: "ATA", label: "Antarctica", flag: "AQ" },
  { value: "ATF", label: "French Southern Territories", flag: "TF" },
  { value: "ATG", label: "Antigua and Barbuda", flag: "AG" },
  { value: "AUS", label: "Australia", flag: "AU" },
  { value: "AUT", label: "Austria", flag: "AT" },
  { value: "AZE", label: "Azerbaijan", flag: "AZ" },
  { value: "BDI", label: "Burundi", flag: "BI" },
  { value: "BEL", label: "Belgium", flag: "BE" },
  { value: "BEN", label: "Benin", flag: "BJ" },
  { value: "BES", label: "Bonaire, Sint Eustatius and Saba", flag: "BQ" },
  { value: "BFA", label: "Burkina Faso", flag: "BF" },
  { value: "BGD", label: "Bangladesh", flag: "BD" },
  { value: "BGR", label: "Bulgaria", flag: "BG" },
  { value: "BHR", label: "Bahrain", flag: "BH" },
  { value: "BHS", label: "Bahamas", flag: "BS" },
  { value: "BIH", label: "Bosnia and Herzegovina", flag: "BA" },
  { value: "BLM", label: "Saint Barthélemy", flag: "BL" },
  { value: "BLR", label: "Belarus", flag: "BY" },
  { value: "BLZ", label: "Belize", flag: "BZ" },
  { value: "BMU", label: "Bermuda", flag: "BM" },
  { value: "BOL", label: "Bolivia, Plurinational State of", flag: "BO" },
  { value: "BRA", label: "Brazil", flag: "BR" },
  { value: "BRB", label: "Barbados", flag: "BB" },
  { value: "BRN", label: "Brunei Darussalam", flag: "BN" },
  { value: "BTN", label: "Bhutan", flag: "BT" },
  { value: "BVT", label: "Bouvet Island", flag: "BV" },
  { value: "BWA", label: "Botswana", flag: "BW" },
  { value: "CAF", label: "Central African Republic", flag: "CF" },
  { value: "CAN", label: "Canada", currencies: ["USD"], flag: "CA" },
  { value: "CCK", label: "Cocos (Keeling) Islands", flag: "CC" },
  { value: "CHE", label: "Switzerland", flag: "CH" },
  { value: "CHL", label: "Chile", flag: "CL" },
  { value: "CHN", label: "China", flag: "CN" },
  { value: "CIV", label: "Côte dIvoire", flag: "CI" },
  { value: "CMR", label: "Cameroon", flag: "CM" },
  { value: "COD", label: "Congo, the Democratic Republic of the", flag: "CD" },
  { value: "COG", label: "Congo", flag: "CG" },
  { value: "COK", label: "Cook Islands", flag: "CK" },
  { value: "COL", label: "Colombia", flag: "CO" },
  { value: "COM", label: "Comoros", flag: "KM" },
  { value: "CPV", label: "Cabo Verde", flag: "CV" },
  { value: "CRI", label: "Costa Rica", flag: "CR" },
  { value: "CUB", label: "Cuba", flag: "CU" },
  { value: "CUW", label: "Curaçao", flag: "CW" },
  { value: "CXR", label: "Christmas Island", flag: "CX" },
  { value: "CYM", label: "Cayman Islands", flag: "KY" },
  { value: "CYP", label: "Cyprus", flag: "CY" },
  { value: "CZE", label: "Czech Republic", flag: "CZ" },
  { value: "DEU", label: "Germany", flag: "DE" },
  { value: "DJI", label: "Djibouti", flag: "DJ" },
  { value: "DMA", label: "Dominica", flag: "DM" },
  { value: "DNK", label: "Denmark", flag: "DK" },
  { value: "DOM", label: "Dominican Republic", flag: "DO" },
  { value: "DZA", label: "Algeria", flag: "DZ" },
  { value: "ECU", label: "Ecuador", currencies: ["USD"], flag: "EC" },
  { value: "EGY", label: "Egypt", flag: "EG" },
  { value: "ERI", label: "Eritrea", flag: "ER" },
  { value: "ESH", label: "Western Sahara", flag: "EH" },
  { value: "ESP", label: "Spain", flag: "ES" },
  { value: "EST", label: "Estonia", flag: "EE" },
  { value: "ETH", label: "Ethiopia", flag: "ET" },
  { value: "FIN", label: "Finland", flag: "FI" },
  { value: "FJI", label: "Fiji", flag: "FJ" },
  { value: "FLK", label: "Falkland Islands (Malvinas)", flag: "FK" },
  { value: "FRA", label: "France", flag: "FR" },
  { value: "FRO", label: "Faroe Islands", flag: "FO" },
  { value: "FSM", label: "Micronesia, Federated States of", flag: "FM" },
  { value: "GAB", label: "Gabon", flag: "GA" },
  { value: "GBR", label: "United Kingdom", flag: "GB" },
  { value: "GEO", label: "Georgia", flag: "GE" },
  { value: "GGY", label: "Guernsey", flag: "GG" },
  { value: "GHA", label: "Ghana", flag: "GH" },
  { value: "GIB", label: "Gibraltar", flag: "GI" },
  { value: "GIN", label: "Guinea", flag: "GN" },
  { value: "GLP", label: "Guadeloupe", flag: "GP" },
  { value: "GMB", label: "Gambia", flag: "GM" },
  { value: "GNB", label: "Guinea-Bissau", flag: "GW" },
  { value: "GNQ", label: "Equatorial Guinea", flag: "GQ" },
  { value: "GRC", label: "Greece", flag: "GR" },
  { value: "GRD", label: "Grenada", flag: "GD" },
  { value: "GRL", label: "Greenland", flag: "GL" },
  { value: "GTM", label: "Guatemala", flag: "GT" },
  { value: "GUF", label: "French Guiana", flag: "GF" },
  { value: "GUM", label: "Guam", flag: "GU" },
  { value: "GUY", label: "Guyana", flag: "GY" },
  { value: "HKG", label: "Hong Kong", flag: "HK" },
  { value: "HMD", label: "Heard Island and McDonald Islands", flag: "HM" },
  { value: "HND", label: "Honduras", flag: "HN" },
  { value: "HRV", label: "Croatia", flag: "HR" },
  { value: "HTI", label: "Haiti", flag: "HT" },
  { value: "HUN", label: "Hungary", flag: "HU" },
  { value: "IDN", label: "Indonesia", flag: "ID" },
  { value: "IMN", label: "Isle of Man", flag: "IM" },
  { value: "IND", label: "India", flag: "IN" },
  { value: "IOT", label: "British Indian Ocean Territory", flag: "IO" },
  { value: "IRL", label: "Ireland", flag: "IE" },
  { value: "IRN", label: "Iran, Islamic Republic of", flag: "IR" },
  { value: "IRQ", label: "Iraq", flag: "IQ" },
  { value: "ISL", label: "Iceland", currencies: ["USD"], flag: "IS" },
  { value: "ISR", label: "Israel", flag: "IL" },
  { value: "ITA", label: "Italy", flag: "IT" },
  { value: "JAM", label: "Jamaica", flag: "JM" },
  { value: "JEY", label: "Jersey", flag: "JE" },
  { value: "JOR", label: "Jordan", flag: "JO" },
  { value: "JPN", label: "Japan", flag: "JP" },
  { value: "KAZ", label: "Kazakhstan", flag: "KZ" },
  { value: "KEN", label: "Kenya", flag: "KE" },
  { value: "KGZ", label: "Kyrgyzstan", flag: "KG" },
  { value: "KHM", label: "Cambodia", currencies: ["USD"], flag: "KH" },
  { value: "KIR", label: "Kiribati", flag: "KI" },
  { value: "KNA", label: "Saint Kitts and Nevis", flag: "KN" },
  { value: "KOR", label: "South Korea (Republic of Korea)", flag: "KR" },
  { value: "KWT", label: "Kuwait", flag: "KW" },
  { value: "LAO", label: "Lao People's Democratic Republic", flag: "LA" },
  { value: "LBN", label: "Lebanon", flag: "LB" },
  { value: "LBR", label: "Liberia", flag: "LR" },
  { value: "LBY", label: "Libya", flag: "LY" },
  { value: "LCA", label: "Saint Lucia", flag: "LC" },
  { value: "LIE", label: "Liechtenstein", flag: "LI" },
  { value: "LKA", label: "Sri Lanka", flag: "LK" },
  { value: "LSO", label: "Lesotho", flag: "LS" },
  { value: "LTU", label: "Lithuania", flag: "LT" },
  { value: "LUX", label: "Luxembourg", flag: "LU" },
  { value: "LVA", label: "Latvia", flag: "LV" },
  { value: "MAF", label: "Saint Martin (French part)", flag: "MF" },
  { value: "MAR", label: "Morocco", flag: "MA" },
  { value: "MCO", label: "Monaco", flag: "MC" },
  { value: "MDA", label: "Moldova, Republic of", flag: "MD" },
  { value: "MDG", label: "Madagascar", flag: "MG" },
  { value: "MDV", label: "Maldives", flag: "MV" },
  { value: "MEX", label: "Mexico", flag: "MX" },
  { value: "MHL", label: "Marshall Islands", flag: "MH" },
  { value: "MKD", label: "Macedonia, the former Yugoslav Republic of", flag: "MK" },
  { value: "MLI", label: "Mali", flag: "ML" },
  { value: "MLT", label: "Malta", flag: "MT" },
  { value: "MMR", label: "Myanmar", flag: "MM" },
  { value: "MNE", label: "Montenegro", flag: "ME" },
  { value: "MNG", label: "Mongolia", flag: "MN" },
  { value: "MNP", label: "Northern Mariana Islands", flag: "MP" },
  { value: "MOZ", label: "Mozambique", flag: "MZ" },
  { value: "MRT", label: "Mauritania", flag: "MR" },
  { value: "MSR", label: "Montserrat", flag: "MS" },
  { value: "MTQ", label: "Martinique", flag: "MQ" },
  { value: "MUS", label: "Mauritius", flag: "MU" },
  { value: "MWI", label: "Malawi", flag: "MW" },
  { value: "MYS", label: "Malaysia", flag: "MY" },
  { value: "MYT", label: "Mayotte", flag: "YT" },
  { value: "NAM", label: "Namibia", flag: "NA" },
  { value: "NCL", label: "New Caledonia", flag: "NC" },
  { value: "NER", label: "Niger", flag: "NE" },
  { value: "NFK", label: "Norfolk Island", flag: "NF" },
  { value: "NGA", label: "Nigeria", flag: "NG" },
  { value: "NIC", label: "Nicaragua", flag: "NI" },
  { value: "NIU", label: "Niue", flag: "NU" },
  { value: "NLD", label: "Netherlands", flag: "NL" },
  { value: "NOR", label: "Norway", flag: "NO" },
  { value: "NPL", label: "Nepal", flag: "NP" },
  { value: "NRU", label: "Nauru", flag: "NR" },
  { value: "NZL", label: "New Zealand", flag: "NZ" },
  { value: "OMN", label: "Oman", flag: "OM" },
  { value: "PAK", label: "Pakistan", flag: "PK" },
  { value: "PAN", label: "Panama", flag: "PA" },
  { value: "PCN", label: "Pitcairn", flag: "PN" },
  { value: "PER", label: "Peru", currencies: ["USD"], flag: "PE" },
  { value: "PHL", label: "Philippines", flag: "PH" },
  { value: "PLW", label: "Palau", flag: "PW" },
  { value: "PNG", label: "Papua New Guinea", flag: "PG" },
  { value: "POL", label: "Poland", flag: "PL" },
  { value: "PRI", label: "Puerto Rico", flag: "PR" },
  { value: "PRK", label: "North Korea (Democratic People's Republic of Korea", flag: "KP" },
  { value: "PRT", label: "Portugal", flag: "PT" },
  { value: "PRY", label: "Paraguay", flag: "PY" },
  { value: "PSE", label: "Palestine, State of", flag: "PS" },
  { value: "PYF", label: "French Polynesia", flag: "PF" },
  { value: "QAT", label: "Qatar", flag: "QA" },
  { value: "REU", label: "Réunion", flag: "RE" },
  { value: "ROU", label: "Romania", flag: "RO" },
  { value: "RUS", label: "Russian Federation", flag: "RU" },
  { value: "RWA", label: "Rwanda", flag: "RW" },
  { value: "SAU", label: "Saudi Arabia", flag: "SA" },
  { value: "SDN", label: "Sudan", flag: "SD" },
  { value: "SEN", label: "Senegal", flag: "SN" },
  { value: "SGP", label: "Singapore", currencies: ["USD"], flag: "SG" },
  { value: "SGS", label: "South Georgia and the South Sandwich Islands", flag: "GS" },
  { value: "SHN", label: "Saint Helena, Ascension and Tristan da Cunha", flag: "SH" },
  { value: "SJM", label: "Svalbard and Jan Mayen", flag: "SJ" },
  { value: "SLB", label: "Solomon Islands", flag: "SB" },
  { value: "SLE", label: "Sierra Leone", flag: "SL" },
  { value: "SLV", label: "El Salvador", flag: "SV" },
  { value: "SMR", label: "San Marino", flag: "SM" },
  { value: "SOM", label: "Somalia", flag: "SO" },
  { value: "SPM", label: "Saint Pierre and Miquelon", flag: "PM" },
  { value: "SRB", label: "Serbia", flag: "RS" },
  { value: "SSD", label: "South Sudan", flag: "SS" },
  { value: "STP", label: "Sao Tome and Principe", flag: "TW" },
  { value: "SUR", label: "Suriname", flag: "SR" },
  { value: "SVK", label: "Slovakia", flag: "SK" },
  { value: "SVN", label: "Slovenia", flag: "SI" },
  { value: "SWE", label: "Sweden", flag: "SE" },
  { value: "SWZ", label: "Swaziland", flag: "SZ" },
  { value: "SXM", label: "Sint Maarten (Dutch part)", flag: "SX" },
  { value: "SYC", label: "Seychelles", flag: "SC" },
  { value: "SYR", label: "Syrian Arab Republic", flag: "SY" },
  { value: "TCA", label: "Turks and Caicos Islands", flag: "TC" },
  { value: "TCD", label: "Chad", flag: "TD" },
  { value: "TGO", label: "Togo", flag: "TG" },
  { value: "THA", label: "Thailand", flag: "TH" },
  { value: "TJK", label: "Tajikistan", flag: "TJ" },
  { value: "TKL", label: "Tokelau", flag: "TK" },
  { value: "TKM", label: "Turkmenistan", flag: "TM" },
  { value: "TLS", label: "Timor-Leste", flag: "TL" },
  { value: "TON", label: "Tonga", flag: "TO" },
  { value: "TTO", label: "Trinidad and Tobago", flag: "TT" },
  { value: "TUN", label: "Tunisia", flag: "TN" },
  { value: "TUR", label: "Turkey", flag: "TR" },
  { value: "TUV", label: "Tuvalu", flag: "TV" },
  { value: "TWN", label: "Taiwan, Province of China", flag: "TW" },
  { value: "TZA", label: "Tanzania, United Republic of", flag: "TZ" },
  { value: "UGA", label: "Uganda", flag: "UG" },
  { value: "UKR", label: "Ukraine", flag: "UA" },
  { value: "UMI", label: "United States Minor Outlying Islands", flag: "UM" },
  { value: "URY", label: "Uruguay", currencies: ["USD"], flag: "UY" },
  { value: "UZB", label: "Uzbekistan", flag: "UZ" },
  { value: "VAT", label: "Holy See (Vatican City State)", flag: "VA" },
  { value: "VCT", label: "Saint Vincent and the Grenadines", flag: "VC" },
  { value: "VEN", label: "Venezuela, Bolivarian Republic of", flag: "VE" },
  { value: "VGB", label: "Virgin Islands, British", flag: "VG" },
  { value: "VIR", label: "Virgin Islands, U.S.", flag: "VI" },
  { value: "VNM", label: "Viet Nam", flag: "VN" },
  { value: "VUT", label: "Vanuatu", flag: "VU" },
  { value: "WLF", label: "Wallis and Futuna", flag: "WF" },
  { value: "WSM", label: "Samoa", flag: "WS" },
  { value: "YEM", label: "Yemen", flag: "YE" },
  { value: "ZAF", label: "South Africa", flag: "ZA" },
  { value: "ZMB", label: "Zambia", flag: "ZM" },
  { value: "ZWE", label: "Zimbabwe", flag: "ZW" },
].sort(compare);

const postGridCheckCountries = [
  { value: "USA", label: "United States", currency_code: "USD" },
  { value: "CAN", label: "Canada", currency_code: "CAD" },
].sort(compare);

const taxFormTypes = [
  { value: "DIV", name: "DIVIDEND INCOME", prefix: "1099" },
  { value: "INT", name: "INTEREST INCOME", prefix: "1099" },
  { value: "MISC", name: "MISCELLANEOUS INFORMATION", prefix: "1099" },
  { value: "R", name: "DISTRIBUTION FROM PENSIONS,ANNUITES", prefix: "1099" },
  { value: "S", name: "PROCEEDS FROM REAL ESTATE TRANSACTIONS", prefix: "1099" },
  { value: "PATR", name: "TAXABLE DISTRIBUTIONS RECEIVED", prefix: "1099" },
  { value: "W-2G", name: "CERTAIN GAMBLING WINNINGS", prefix: "1099" },
  { value: "NEC", name: "NONEMPLOYEE COMPENSATION", prefix: "1099" },
  { value: "G", name: "GOVERNMENT PAYMENTS", prefix: "1099" },
];

//Not group up because all taxForms should show in list if taxFormTypes is not selected.
const taxForms = [
  { external_id: "1A", type: "DIV", name: "Total Ordinary dividends" },
  { external_id: "1B", type: "DIV", name: "Qualified dividends" },
  { external_id: "2A", type: "DIV", name: "Total capital gain distr" },
  { external_id: "2B", type: "DIV", name: "Unrecap. sec. 1250 gain" },
  { external_id: "2C", type: "DIV", name: "Section 1202 gain" },
  { external_id: "2D", type: "DIV", name: "Collectibles (28%) gain" },
  { external_id: "2E", type: "DIV", name: "Section 897 ordinary dividends" },
  { external_id: "2F", type: "DIV", name: "Section 897 capital gain" },
  { external_id: "3", type: "DIV", name: "Non dividend distributions" },
  { external_id: "4", type: "DIV", name: "Federal income tax withheld" },
  { external_id: "5", type: "DIV", name: "Section 199A dividends" },
  { external_id: "6", type: "DIV", name: "Investment expenses" },
  { external_id: "7", type: "DIV", name: "Foreign tax paid" },
  { external_id: "9", type: "DIV", name: "Cash liquidation distributions" },
  { external_id: "10", type: "DIV", name: "Non cash liquidation distributions" },
  { external_id: "11", type: "DIV", name: "Exempt-interest dividends" },
  { external_id: "12", type: "DIV", name: "Specified private activity bond interest dividends" },
  { external_id: "15", type: "DIV", name: "State tax withheld" },
  { external_id: "1", type: "INT", name: "Interest Income" },
  { external_id: "2", type: "INT", name: "Early withdrawal penalty" },
  { external_id: "3", type: "INT", name: "Interest on U.S. Savings Bonds and Treasury obligations" },
  { external_id: "4", type: "INT", name: "Federal income tax withheld" },
  { external_id: "5", type: "INT", name: "Investment expenses" },
  { external_id: "6", type: "INT", name: "Foreign tax paid" },
  { external_id: "8", type: "INT", name: "Tax-exempt interest" },
  { external_id: "9", type: "INT", name: "Specified private activity bond interest" },
  { external_id: "10", type: "INT", name: "Market Discount" },
  { external_id: "11", type: "INT", name: "Bond premium" },
  { external_id: "12", type: "INT", name: "Bond premium on Treasury obligations" },
  { external_id: "13", type: "INT", name: "Bond premium on tax-exempt bond" },
  { external_id: "17", type: "INT", name: "State tax withheld" },
  { external_id: "1", type: "MISC", name: "Rents" },
  { external_id: "2", type: "MISC", name: "Royalties" },
  { external_id: "3", type: "MISC", name: "Other Income" },
  { external_id: "4", type: "MISC", name: "Federal income tax withheld" },
  { external_id: "5", type: "MISC", name: "Fishing boat proceeds" },
  { external_id: "6", type: "MISC", name: "Medical and health care payments" },
  { external_id: "8", type: "MISC", name: "Substitute payments in lieu of dividends or interest" },
  { external_id: "9", type: "MISC", name: "Crop insurance proceeds" },
  { external_id: "10", type: "MISC", name: "Gross proceeds paid to an attorney" },
  { external_id: "11", type: "MISC", name: "Fish purchased for resale" },
  { external_id: "12", type: "MISC", name: "Section 409A deferrals" },
  { external_id: "13", type: "MISC", name: "Excess golden parachute payments" },
  { external_id: "14", type: "MISC", name: "Non qualified deferred compensation" },
  { external_id: "15", type: "MISC", name: "State tax withheld" },
  { external_id: "17", type: "MISC", name: "State income" },
  { external_id: "1", type: "R", name: "Gross distribution" },
  { external_id: "2A", type: "R", name: "Taxable amount" },
  { external_id: "3", type: "R", name: "Capital gain (included in box 2a)" },
  { external_id: "4", type: "R", name: "Federal income tax withheld" },
  { external_id: "5", type: "R", name: "Employee contributions/Designated Roth contributions or insurance premiums" },
  { external_id: "6", type: "R", name: "Net unrealized appreciation in employer's securities" },
  { external_id: "8", type: "R", name: "Other" },
  { external_id: "9B", type: "R", name: "Total employee contributions" },
  { external_id: "10", type: "R", name: "Amount allocable to IRR within 5 years" },
  { external_id: "14", type: "R", name: "State tax withheld" },
  { external_id: "16", type: "R", name: "State distribution" },
  { external_id: "17", type: "R", name: "Local tax withheld" },
  { external_id: "19", type: "R", name: "Local distribution" },
  { external_id: "2", type: "S", name: "Gross proceeds" },
  { external_id: "6", type: "S", name: "Buyer's part of real estate tax" },
  { external_id: "1", type: "PATR", name: "Patronage dividends" },
  { external_id: "2", type: "PATR", name: "Nonpatronage distributions" },
  { external_id: "3", type: "PATR", name: "Per-unit retain allocations" },
  { external_id: "4", type: "PATR", name: "Federal income tax withheld" },
  { external_id: "5", type: "PATR", name: "Redeemed nonqualified notices" },
  { external_id: "6", type: "PATR", name: "Section 199A(g) deduction" },
  { external_id: "7", type: "PATR", name: "Qualified payments (Section 199A(b)(7))" },
  { external_id: "8", type: "PATR", name: "Section 199A(a) qual. Items" },
  { external_id: "9", type: "PATR", name: "Section 199A(a) SSTB items" },
  { external_id: "10", type: "PATR", name: "Investment credit" },
  { external_id: "11", type: "PATR", name: "Work opportunity credit" },
  { external_id: "12", type: "PATR", name: "Other credits and deductions" },
  { external_id: "1", type: "G", name: "Unemployment compensation" },
  { external_id: "2", type: "G", name: "State or local income tax refunds, credits or offsets" },
  { external_id: "4", type: "G", name: "Federal income tax withheld" },
  { external_id: "5", type: "G", name: "RTAA payments" },
  { external_id: "6", type: "G", name: "Taxable grants" },
  { external_id: "7", type: "G", name: "Agriculture payments" },
  { external_id: "9", type: "G", name: "Market Gain" },
  { external_id: "11", type: "G", name: "State income tax withheld" },
  { external_id: "1", type: "W-2G", name: "Reportable Winnings" },
  { external_id: "4", type: "W-2G", name: "Federal income tax withheld" },
  { external_id: "7", type: "W-2G", name: "Winnings from identical wagers" },
  { external_id: "14", type: "W-2G", name: "State winnings" },
  { external_id: "15", type: "W-2G", name: "State income tax withheld" },
  { external_id: "16", type: "W-2G", name: "Local winnings" },
  { external_id: "17", type: "W-2G", name: "Local income tax withheld" },
  { external_id: "1", type: "NEC", name: "Nonemployee compensation" },
  { external_id: "4", type: "NEC", name: "Federal income tax withheld" },
  { external_id: "5", type: "NEC", name: "State tax withheld" },
  { external_id: "7", type: "NEC", name: "State income" },
];

const ibanCurrencies = [
  "CHF",
  "DKK",
  "NOK",
  "ALL",
  "TRY",
  "AED",
  "XAF",
  "PKR",
  "ILS",
  "BGN",
  "RON",
  "SAR",
  "AZN",
  "BYN",
  "HRK",
  "EGP",
  "LVL",
  "RSD",
  "PLN",
];

export {
  countries,
  currency_codes,
  getCurrencyCodeFromCountryCode,
  ibanCurrencies,
  incoterms,
  international_payment_countries,
  international_wire_payment_countries,
  paymentTypes,
  postGridCheckCountries,
  states,
  taxForms,
  taxFormTypes,
  vendorAcceptedPaymentMethodsKeyLabel,
};

export const invoiceStatus = [
  { value: "", label: "All" },
  { value: "NEW", label: "NEW" },
  { value: "PENDING", label: "PENDING" },
  { value: "OPEN", label: "OPEN" },
  { value: "CLOSED", label: "CLOSED" },
  { value: "REJECTED", label: "REJECTED" },
];

export const poTypes = [
  { value: "SINGLE VENDOR", label: "SINGLE VENDOR" },
  { value: "MULTIPLE VENDOR", label: "MULTIPLE VENDOR" },
  { value: "EXPENSE REIMBURSEMENT", label: "EXPENSE REIMBURSEMENT" },
  { value: "CORPORATE CARD", label: "CORPORATE CARD" },
];

export const poSubtypes = [
  { value: "", label: "" },
  { value: "Inventory", label: "Inventory" },
  { value: "Project", label: "Project" },
];

export const item_types = [
  { name: "Inventory", value: "Inventory" },
  { name: "Non Inventory", value: "Non-Inventory" },
  { name: "Other", value: "Others" },
  { name: "Service", value: "Service" },
].sort((a, b) => a.name.localeCompare(b.name));
