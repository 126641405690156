import React from "react";
import InputFilter from "../reportFilters/input";
import VendorSearchDropdown from "../reportFilters/vendorSearchDropdown";

const CatalogueItemListing = ({}) => {
  return (
    <div>
      <VendorSearchDropdown name={"Vendor"} code={"vendor_id"} />
      <InputFilter name={"Product Name"} code={"product_name"} options={[]} />
      <InputFilter name={"Subsidiary Name"} code={"subsidiary_name"} options={[]} />
      <InputFilter name={"COGS Account Name"} code={"cogs_account_name"} options={[]} />
      <InputFilter name={"Cost Category Name"} code={"cost_category_name"} options={[]} />
    </div>
  );
};

export default CatalogueItemListing;
