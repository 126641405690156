import NavTabs from "components/admin/vendors/nav";
import Panel from "components/common/panel/panel";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import gridStyles from "wombatifier/components/bulk_upload/bulkUpload.module.css";
import rowActionStyles from "wombatifier/components/bulk_upload/bulkUploadFilesRowAction.module.css";
import { BulkUploadsFilesGrid } from "./bulkUploadFilesGrid";
import "./bulkUploadFilesGrid.css";
import { BulkUploadFileFormModable } from "./bulkUploadFilesModal";

const BulkUploadsFilesGridVendor = () => {
  const { Uploader, openUploader, gridApiRef } = BulkUploadFileFormModable("vendor");
  return (
    <Container fluid={true} className="file-uploads">
      <Uploader />
      <Row>
        <Col md="12" className="removeHPseudoClass">
          <NavTabs activePageName="File Uploads" />
        </Col>
      </Row>

      <Panel
        header={
          <Row className="m-0 justify-content-between align-items-center">
            <Col className="p-0 grid-title" style={{ flex: 0, minWidth: "fit-content" }}>
              All File Uploads
            </Col>
            <Col className="p-0" style={{ flex: 0 }}>
              <button
                type="button"
                className={`btn btn-primary py-1 ${gridStyles.uploadFileButton}`}
                onClick={openUploader}
              >
                Upload File
              </button>
            </Col>
          </Row>
        }
        cardBodyClass={`py-2 ${rowActionStyles.allowRowOverflow}`}
      >
        <BulkUploadsFilesGrid gridApiRef={gridApiRef} sourceType="vendor" />
      </Panel>
    </Container>
  );
};

export default BulkUploadsFilesGridVendor;
