import useAdminCompanyCurrencyCode from "components/admin/hooks/useAdminCompanyCurrencyCode";
import DepartmentPicker from "components/admin/pickers/reduxFormPickers/departmentPicker";
import LocationPicker from "components/admin/pickers/reduxFormPickers/locationPicker";
import ProjectPicker, {
  ProjectOptionsType,
} from "components/admin/pickers/reduxFormPickers/projectPicker/projectPicker";
import SubtotalTemplatePicker, {
  SubtotalTemplateObjType,
} from "components/admin/pickers/reduxFormPickers/subtotalTemplatePicker";
import { RenderInputGroupField } from "components/forms/bootstrapFields";
import _ from "lodash";
import React, { Fragment, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useTypedSelector } from "reducers";
import { Field, FieldArrayFieldsProps, change } from "redux-form";
import adminCommonSvc from "services/admin/commonSvc";
import invoiceCommonSvc from "services/admin/invoices/invoiceCommonSvc";
import { InvoiceType } from "services/admin/invoices/invoiceType";
import { IUser } from "services/common/user/userTypes";
import { required } from "services/validations/reduxFormValidation";
import styles from "./invoices.module.css";

type ManageSubtotalChargesType = {
  is_subtotal_template?: boolean;
  subtotal_template_id?: number | string;
  is_department_required?: boolean;
  is_location_required?: boolean;
  is_project_required?: boolean;
  subtotal_template_name?: string;
  _destroy?: number;
};

type ManageSubtotalChargesPropsType = {
  fields: FieldArrayFieldsProps<ManageSubtotalChargesType>;
  invoice: InvoiceType.InvoiceDetailType;
  formName: string;
};

const ManageSubtotalCharges = ({ fields, invoice, formName }: ManageSubtotalChargesPropsType) => {
  const [hideEntry, setHideEntry] = useState<number[]>([]);
  const currentUser: IUser = useTypedSelector((state) => state.user);
  const { companyCurrencies } = useAdminCompanyCurrencyCode();
  const dispatch = useDispatch();

  const deleteSubtotalEntry = (item: any, index: number) => {
    item._destroy = 1;
    invoiceCommonSvc.updateSubtotalUsetax(invoice, currentUser, true);
    setHideEntry([...hideEntry, index]);
    dispatch(change("InvoiceForm", `debit_entries_attributes[${index}]`, item));
  };

  const updateSubtotalTemplateName = (subtotalEntry: any, subtotalTemplate: SubtotalTemplateObjType, index: number) => {
    subtotalEntry.subtotal_template_id = subtotalTemplate.id;
    subtotalEntry.subtotal_template_name = subtotalTemplate.name;
    subtotalEntry.account_id = subtotalTemplate.account_id;
    invoiceCommonSvc.mapAccountRequiredFields(subtotalTemplate.account, currentUser, subtotalEntry);
    dispatch(change("InvoiceForm", `debit_entries_attributes[${index}]`, subtotalEntry));
  };

  const updateSubtotalUsetax = (subtotalEntry: any, subtotalAmount: string, index: number) => {
    subtotalEntry.amount = parseFloat(subtotalAmount);
    invoiceCommonSvc.updateSubtotalUsetax(invoice, currentUser, true);
  };

  const changesubtotalEntryProject = (subtotalEntry: any, project: ProjectOptionsType, index: number) => {
    subtotalEntry.project_id = project.project_id;
    subtotalEntry.project_name = project.label;
    subtotalEntry.project = project;
    dispatch(change("InvoiceForm", `debit_entries_attributes[${index}].project_id`, subtotalEntry.project_id));
  };

  const isSubTotalTemplateExist = () => {
    let subtotalTemplateEntries = fields?.getAll();
    let subtotalArray;

    if (_.isArray(subtotalTemplateEntries) && subtotalTemplateEntries.length > 0) {
      subtotalArray = subtotalTemplateEntries?.filter((entry: any) => {
        return (entry.is_subtotal_template || entry.subtotal_template_id) && entry._destroy !== 1;
      });
    }

    return _.isUndefined(subtotalArray) ? [] : subtotalArray;
  };

  const addSubtotalCharges = (fields: FieldArrayFieldsProps<ManageSubtotalChargesType>) => {
    fields?.push({ is_subtotal_template: true });
  };

  return (
    <>
      {isSubTotalTemplateExist()?.length > 0 && <hr className={styles.separator} />}

      <Row className="invoiceItemLine">
        <Col>
          {fields &&
            fields.length > 0 &&
            fields.map((subtotalEntry: any, index) => {
              if (
                !hideEntry.includes(index) &&
                (fields.get(index).is_subtotal_template || fields.get(index).subtotal_template_id) &&
                fields.get(index)._destroy !== 1
              ) {
                return (
                  <Fragment key={index}>
                    <Row>
                      <Col sm={8}>
                        <Field
                          instanceId={`manage-subtotal-charge-${index}-subtotal-template`}
                          name={`${subtotalEntry}.subtotal_template_id`}
                          label="Description"
                          component={SubtotalTemplatePicker}
                          callBack={(selected: SubtotalTemplateObjType) =>
                            updateSubtotalTemplateName(fields.get(index), selected, index)
                          }
                          required={true}
                          validate={[required]}
                        />
                      </Col>
                      <Col sm={3} className="pr-0">
                        <Field
                          id={`manage-subtotal-charge-${index}-amount`}
                          name={`${subtotalEntry}.amount`}
                          type="number"
                          label="Amount"
                          inputGroupText={adminCommonSvc.getSymbolFromIsoCode(
                            invoice.currency?.iso_code,
                            companyCurrencies,
                          )}
                          component={RenderInputGroupField}
                          onChange={(e: any) => updateSubtotalUsetax(fields.get(index), e.target.value, index)}
                        />
                      </Col>
                      <Col sm={1} className="d-flex align-items-center justify-content-end">
                        <Button
                          onClick={() => deleteSubtotalEntry(fields.get(index), index)}
                          className="bg-transparent border-0 m-0 p-0 px-pl-15"
                        >
                          <i className="icon icon-delete m-0" />
                        </Button>
                      </Col>
                    </Row>
                    <Row className="px-mb-25">
                      {currentUser?.company?.has_departments && (
                        <Col sm={4}>
                          <Field
                            instanceId={`manage-subtotal-charge-${index}-department`}
                            name={`${subtotalEntry}.department_id`}
                            component={DepartmentPicker}
                            label="Department"
                            modelData={fields.get(index)}
                            parentObjData={invoice}
                            disabled={adminCommonSvc.isDepartmentDisabled(fields.get(index))}
                            required={fields.get(index).is_department_required}
                            validate={fields.get(index).is_department_required ? [required] : []}
                            menuPosition="fixed"
                          />
                        </Col>
                      )}
                      {currentUser?.company?.has_locations && (
                        <Col sm={4}>
                          <Field
                            instanceId={`manage-subtotal-charge-${index}-location`}
                            name={`${subtotalEntry}.location_id`}
                            component={LocationPicker}
                            label="Location"
                            modelData={fields.get(index)}
                            parentObjData={invoice}
                            disabled={adminCommonSvc.isLocationDisabled(fields.get(index))}
                            callBack={(location: any) =>
                              dispatch(change(formName, `${subtotalEntry}.location`, location))
                            }
                            required={fields.get(index).is_location_required}
                            validate={fields.get(index).is_location_required ? [required] : []}
                          />
                        </Col>
                      )}
                      {currentUser?.company?.has_projects && (
                        <Col sm={4}>
                          <Field
                            instanceId={`manage-subtotal-charge-${index}-project`}
                            name={`${subtotalEntry}.project`}
                            label="Project"
                            menuPosition="fixed"
                            component={ProjectPicker}
                            callBack={(selected: ProjectOptionsType) =>
                              changesubtotalEntryProject(fields.get(index), selected, index)
                            }
                            required={fields.get(index).is_project_required}
                            validate={fields.get(index).is_project_required ? [required] : []}
                          />
                        </Col>
                      )}
                    </Row>
                    {isSubTotalTemplateExist()?.length > 1 && index !== fields.length - 1 && <hr />}
                  </Fragment>
                );
              }
            })}
        </Col>
      </Row>
      {invoiceCommonSvc.isItemExist(invoice?.invoice_items_attributes).length > 0 && (
        <Row>
          <Col>
            <Button variant="btn btn-primary" onClick={() => addSubtotalCharges(fields)}>
              <i className="btn-icon icon-add-btn"></i> Add Subtotal Charges
            </Button>
          </Col>
        </Row>
      )}
      {isSubTotalTemplateExist()?.length > 0 && <hr className={styles.separator} />}
    </>
  );
};

export default ManageSubtotalCharges;
