import { AxiosResponse } from "axios";
import { restApiService } from "providers/restApi";
import React, { useCallback } from "react";
import InputDropdownFilter from "./inputSearchDropdown";

type Vendor = {
  id: number;
  name: string;
  status: string;
  vendor_id: string;
  external_id: string | null;
  templates: any[];
  default_address: object | null;
};

const VendorSearchDropdown = ({ name, code, isRequired }: { name: string; code: string; isRequired?: boolean }) => {
  const loadOptions = useCallback(
    async (inputValue: string, callback: (options: { label: string; value: string }[]) => void) => {
      if (!inputValue) {
        return [];
      }

      const getParams = {
        VENDOR_NAME: inputValue,
        VENDOR_LIMIT: 100,
      };

      try {
        const response: AxiosResponse<Vendor[]> = await restApiService.get("vendors.lk", getParams);

        const vendors = Array.isArray(response.data)
          ? response.data.map((vendor) => ({
              label: vendor.name,
              value: vendor.id.toString(),
            }))
          : [];

        callback(vendors);
      } catch (error) {
        callback([]);
      }
    },
    [],
  );

  return <InputDropdownFilter name={name} code={code} loadOptions={loadOptions} isRequired={isRequired} />;
};

export default VendorSearchDropdown;
