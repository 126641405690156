import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { FaSpinner } from "react-icons/fa6";
import * as XLSX from "xlsx";
import styles from "./documentViewer.module.css";
import { imageMimeType, mimeTypeMap } from "./mimeUtils";
import sstyles from "./scrollbar.module.css";

interface DocumentViewerProps {
  file: File;
}

const spinner = (
  <div className={styles.spinnerContainer}>
    <FaSpinner className={styles.spinner} />
  </div>
);

const DocumentViewer: React.FC<DocumentViewerProps> = ({ file }) => {
  const [content, setContent] = useState<string | null>(null);
  const [imageSrc, setImageSrc] = useState<string | null>(null);
  const [excelHeaders, setExcelHeaders] = useState<string[] | null>(null);
  const [excelRows, setExcelRows] = useState<any[][] | null>(null);

  useEffect(() => {
    setContent(null);
    setImageSrc(null);
    setExcelHeaders(null);
    setExcelRows(null);

    const reader = new FileReader();
    const fileType = file.type;

    if (fileType.startsWith(imageMimeType)) {
      const objectUrl = URL.createObjectURL(file);
      setImageSrc(objectUrl);

      return () => {
        URL.revokeObjectURL(objectUrl);
      };
    } else if (fileType === mimeTypeMap["txt"]) {
      reader.onload = () => {
        setContent(reader.result as string);
      };
      reader.readAsText(file);
    } else if (fileType === mimeTypeMap["xls"] || fileType === mimeTypeMap["xlsx"] || fileType === mimeTypeMap["csv"]) {
      reader.onload = () => {
        const data = new Uint8Array(reader.result as ArrayBuffer);
        const workbook = XLSX.read(data, { type: "array" });
        const firstSheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[firstSheetName];
        const jsonData = XLSX.utils.sheet_to_json<any[]>(worksheet, {
          header: 1,
          defval: "",
        });

        if (jsonData.length > 0) {
          const [headers, ...rows] = jsonData;
          setExcelHeaders(headers);
          setExcelRows(rows);
        } else {
          setExcelHeaders([]);
          setExcelRows([]);
        }
      };
      reader.readAsArrayBuffer(file);
    } else if (fileType !== mimeTypeMap["pdf"]) {
      setContent("Unsupported file type");
    }
  }, [file]);

  return (
    <div className={styles.container}>
      {imageSrc && <img src={imageSrc} alt="Preview" className={styles.image} />}
      {content && <pre className={styles.text}>{content}</pre>}
      {excelHeaders && excelRows && (
        <div className={`${styles.tableContainer} ${sstyles.scrollableContainer}`}>
          <Table className={styles.tableMaterial} size="sm">
            <thead>
              {excelHeaders.map((header, index) => (
                <th key={`header-${index}_${header}`}>{header}</th>
              ))}
            </thead>
            <tbody>
              {excelRows.map((row, rowIndex) => (
                <tr key={`row-${rowIndex}`}>
                  {row.map((cell, cellIndex) => (
                    <td key={`cell-${rowIndex}-${cellIndex}`}>{cell}</td>
                  ))}
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      )}
      {!imageSrc && !content && !excelHeaders && !excelRows && spinner}
    </div>
  );
};

export default DocumentViewer;
