import React, { useCallback, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import AsyncSelect from "react-select/async";
import { change } from "redux-form";
import { ReportSvc } from "services/admin/reports/reportSvc";

type InputOptionType = { value: string; label: string };

const InputDropdownFilter = ({
  name,
  code,
  isRequired,
  loadOptions,
  isMulti = false,
}: {
  name: string;
  code: string;
  loadOptions: (inputValue: string, callback: (options: InputOptionType[]) => void) => void;
  isRequired?: boolean;
  isMulti?: boolean;
}) => {
  const dispatch = useDispatch();
  const [optionChosen, setOptionChosen] = useState<InputOptionType | InputOptionType[] | null>(null);

  const onOptionChosen = useCallback(
    (inputvalue) => {
      dispatch(
        change(
          ReportSvc.formName,
          `properties.filters.${code}`,
          isMulti ? inputvalue?.map((v: InputOptionType) => v.value) : inputvalue?.value,
        ),
      );
      setOptionChosen(inputvalue);
    },
    [code, dispatch, isMulti],
  );

  return (
    <Row className="mx-0">
      <Col className="px-0">
        <label>Vendor</label>
        <AsyncSelect
          loadOptions={loadOptions}
          isClearable
          isMulti={isMulti}
          placeholder={isMulti ? "Select" : "Select one"}
          onChange={onOptionChosen}
          value={optionChosen}
          required={isRequired}
        />
      </Col>
    </Row>
  );
};

export default InputDropdownFilter;
