import PurchaseOrderPicker, {
  PurchaseOrderOptionType,
} from "components/admin/pickers/reduxFormPickers/purchaseOrderPicker";
import { RenderCheckBox, RenderInputGroupField } from "components/forms/bootstrapFields";
import _ from "lodash";
import { restApiService } from "providers/restApi";
import React, { Fragment, useEffect, useImperativeHandle, useRef, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useTypedSelector } from "reducers";
import { Field, FieldArray, FieldArrayFieldsProps, arrayPush, change } from "redux-form";
import { CommonApis } from "services/admin/commonApis";
import adminCommonSvc from "services/admin/commonSvc";
import { CommonType } from "services/admin/commonTypes";
import { CustomLabelSvc } from "services/admin/customLabels/customLabelsSvc";
import invoiceCommonSvc from "services/admin/invoices/invoiceCommonSvc";
import { InvoiceType } from "services/admin/invoices/invoiceType";
import PurchaseOrdersApis from "services/admin/purchaseOrders/purchaseOrderApi";
import { PurchaseOrderType } from "services/admin/purchaseOrders/purchaseOrderType";
import { IUser } from "services/common/user/userTypes";
import { CreateNotification, NotificationType } from "services/general/notifications";
import styles from "./invoices.module.css";

export type ManagePoPickerRefType = {
  updatePoByCurrencyCodeRef: () => void;
};

type ManagePoPickerPropsType = {
  formName: string;
  invoice: InvoiceType.InvoiceDetailType;
  isAddForm?: boolean;
  customLabels?: { [key: string]: string[] };
};

const PurchaseOrderLinks = ({
  fields,
  invoice,
}: {
  fields: FieldArrayFieldsProps<InvoiceType.PurchaseOrderLinksType>;
  invoice: InvoiceType.InvoiceDetailType;
}) => {
  return (
    <>
      {fields &&
        fields.length > 0 &&
        fields.map(
          (poLink, index) =>
            fields.get(index)?._destroy != 1 && (
              <Row key={fields.get(index).purchase_order_id} className="mb-1">
                <Col md={"3"}>
                  <div className={styles.poLink}>
                    <a
                      className="link"
                      href={restApiService.angularBaseURL() + "purchase_orders/" + fields.get(index).purchase_order.id}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {fields.get(index).purchase_order.number}
                    </a>
                  </div>
                </Col>
                <Col md={"8"}>
                  <Field
                    name={`${poLink}.amount`}
                    component={RenderInputGroupField}
                    type="number"
                    inputGroupText={invoice?.currency?.symbol}
                    placeholder="amount to deduct from PO..."
                  />
                </Col>
              </Row>
            ),
        )}
    </>
  );
};

const ManagePoPicker = React.forwardRef<ManagePoPickerRefType, ManagePoPickerPropsType>(
  ({ formName, invoice, isAddForm, customLabels }, ref) => {
    const [departments, setDepartments] = useState<CommonType.DepartmentType[]>([]);
    const [requestors, setRequestors] = useState<CommonType.ContactLkType[]>([]);
    const currentUser: IUser = useTypedSelector((state) => state.user);
    const dispatch = useDispatch();
    const oldVal = useRef<{ [key: string]: any }>({});
    const { t } = useTranslation();

    useImperativeHandle(ref, () => {
      return {
        updatePoByCurrencyCodeRef() {
          updatePoByCurrencyCode();
        },
      };
    });

    const preventInitialCall = (callback: () => void, field: string) => {
      if (!invoice || !invoice[field] || (!isAddForm && !oldVal.current[field])) {
        oldVal.current[field] = invoice ? invoice[field] : null;
        return;
      }

      //Update Pos by field if:
      //1. It is add form and user entered field
      //2. It is edit form user changed existing field
      if (
        ((isAddForm && invoice[field]) ||
          (!isAddForm && oldVal.current[field] && !_.isEqual(oldVal.current[field], invoice[field]))) &&
        callback
      ) {
        callback();
      }
    };

    const getDepartments = async () => {
      try {
        const response: CommonType.DepartmentType[] = await CommonApis.getDepartmentList({ status: "ACTIVE" });
        setDepartments(response);
      } catch (error) {
        console.log(error);
      }
    };

    const getRequestors = async () => {
      try {
        const response: CommonType.ContactLkType[] = await CommonApis.getContactsLk({
          by_active: true,
          contact_type: "COMPANY",
        });
        setRequestors(response);
      } catch (error) {
        console.log(error);
      }
    };

    const setDepartment = (departmentId: number | string) => {
      if (_.isArray(departments)) {
        let d: CommonType.DepartmentType[] = departments.filter((department) => departmentId == department.id);
        if (_.isArray(d)) {
          dispatch(change(formName, "department", d[0]));
        }
      }
    };

    useEffect(() => {
      getDepartments();
      getRequestors();
    }, []);

    //TODO-SANKET : MERGE INACTIVE IN TERM PICKER
    const assignPoTermToInvoice = (po: any) => {
      if (_.isPlainObject(po.term)) {
        invoice.term = po.term;
        invoice.term_id = po.term_id;
        dispatch(change(formName, "term_id", po.term_id));
        dispatch(change(formName, "term", po.term));
      }
    };

    const inheritPOHeaderToInvoiceHeader = (po: PurchaseOrderType.DetailType) => {
      assignPoTermToInvoice(po);
      invoiceCommonSvc.updateDueDate(invoice, dispatch, formName);
      invoiceCommonSvc.updateDiscountDate(invoice, dispatch, formName, currentUser);
      invoiceCommonSvc.updateDiscountAmount(invoice, dispatch, formName, currentUser);

      if (_.isPlainObject(po)) {
        if (po.department_id) {
          dispatch(change(formName, "department_id", po.department_id));
          setDepartment(po.department_id);
        }
        if (po.location_id) {
          dispatch(change(formName, "location_id", po.location_id));
        }
      }

      if (po.currency_code && isAddForm) {
        dispatch(change(formName, "currency_code", po.currency_code));
        dispatch(change(formName, "currency", po.currency));
      }

      if (po.subsidiary_id && isAddForm) {
        dispatch(change(formName, "subsidiary_id", po.subsidiary_id));
      }

      if (po.term_id && isAddForm) {
        dispatch(change(formName, "term_id", po.term_id));
      }

      if (po.custom_fields) {
        let obj = adminCommonSvc.inheritCustomField(invoice, po.custom_fields);
        dispatch(change(formName, "custom_fields", obj));
      }

      //TODO-SANKET : inital modeldata.metadata undefined
      if (po.metadata) {
        let obj = adminCommonSvc.inheritMetaData(invoice, po);
        if (obj) {
          dispatch(change(formName, "metadata", po.metadata));
          if (po.metadata_template_id) {
            dispatch(change(formName, "metadata_template_id", po.metadata_template_id));
          }
        }
      }
    };

    //if inherit_po_department_to_invoice_itemline is active then inherit po department to invoice items
    const inheritPODepartmentToInvoiceItems = (
      po: PurchaseOrderType.DetailType,
      item: PurchaseOrderType.PoItemType,
    ) => {
      if (currentUser.company.inherit_po_department_to_invoice_itemline && _.isPlainObject(po)) {
        if (po.department_id) {
          item.department_id = po.department_id;
        }
        if (po.location_id) {
          item.location_id = po.location_id;
        }
        if (po.project_id) {
          item.project_id = po.project_id;
          item.project = po.project;
        }
      }
    };

    const inheritPoItemQtyToInvoiceItemQty = (item: any) => {
      return currentUser.company.has_three_way_matching &&
        _.isPlainObject(currentUser.company.invoice) &&
        currentUser.company.invoice.inherit_only_received_lines
        ? item.receipt_item_qty - item.billed_item_qty
        : item.qty_balance;
    };

    const calculateBalance = (item: any, po: PurchaseOrderType.DetailType) => {
      item.qty = item.qty > 0 ? inheritPoItemQtyToInvoiceItemQty(item) : "";
      item.amount = adminCommonSvc.roundUpAmount(item.qty * item.unit_price, null, currentUser);
      if (item.qty && po && invoiceCommonSvc.isActiveHeaderTax(po, currentUser)) {
        item.tax = null;
        item.tax_id = null;
        item.tax_code = undefined;
      } else if (item.qty != item.qty_balance) {
        if (item.qty > 0 && item.tax > 0) {
          item.tax = (item.tax / item.qty) * inheritPoItemQtyToInvoiceItemQty(item);
        }
      }
    };

    //inherits po header level item
    const inheritHeaderlevelItem = (val: any, po: any) => {
      if (currentUser.company.show_product_item_po_header_level && po.product_item_id) {
        val.product_item_id = po.product_item_id;
        val.product_name = po.product_item.display_name;
        val.product_item = po.product_item;
        val.account_id = po.product_item.account_id;
      }
    };

    const assignFreeTextItem = (invItem: any) => {
      if (
        currentUser.company.enable_freetext_item_sync &&
        invoice.freetext_product_account &&
        _.isPlainObject(invoice.freetext_product_account)
      ) {
        invItem.account_id = invoice.freetext_product_account.account_id;
        invItem.product_item_id = invoice.freetext_product_account.account_linkable_id;
      }
    };

    const inheritPoLinesToInvoice = (response: PurchaseOrderType.DetailType, val: any) => {
      if (val.qty_balance && val.qty_balance > 0) {
        val.inherit_po_item_id = val.id;
        val.id = null;
        inheritPODepartmentToInvoiceItems(response, val);
        calculateBalance(val, response);
        inheritHeaderlevelItem(val, response);
        if (!val.product_item) {
          val.product_item = { display_name: val.product_name };
          val.name = val.product_name;
          assignFreeTextItem(val);
        }

        if (val.account_id) {
          dispatch(change(formName, "submitWithAccounts", true));
        }
        var opObj = _.cloneDeep(val);
        opObj.description = opObj.memo;

        if (
          currentUser.company.amortization_scheduled &&
          currentUser.company.po_line_service_date_inherite_to_inv_amortization
        ) {
          opObj.amortization_start_date = val.service_start_date;
          opObj.amortization_end_date = val.service_end_date;
        }

        if (opObj && opObj.metadata_links) {
          opObj.metadata_links = null;
        }

        if (opObj.product_item_id || (!opObj.product_item_id && !invoiceCommonSvc.disabledFreetextItem(currentUser))) {
          opObj.do_not_set_default_unit = true;
          adminCommonSvc.removeIdFromBudgetItemLinks(opObj);
          dispatch(arrayPush(formName, "invoice_items_attributes", opObj));
        }
      }
    };

    const assignPoItemsToInvoice = (response: PurchaseOrderType.DetailType) => {
      if (response.po_items) {
        response.po_items.forEach((val, index) => {
          if (
            currentUser?.company?.has_three_way_matching &&
            currentUser.company?.invoice?.inherit_only_received_lines &&
            val.receipt_item_qty
          ) {
            inheritPoLinesToInvoice(response, val);
          } else if (
            !currentUser.company.has_three_way_matching ||
            (currentUser.company.has_three_way_matching && !_.isPlainObject(currentUser.company.invoice)) ||
            (currentUser.company.has_three_way_matching &&
              _.isPlainObject(currentUser.company.invoice) &&
              !currentUser.company.invoice.inherit_only_received_lines)
          ) {
            inheritPoLinesToInvoice(response, val);
          }
        });
      }
    };

    const assignPoItemsToInvoiceExpenses = (po: PurchaseOrderType.DetailType) => {
      if (_.isPlainObject(po) && _.isArray(po.po_items)) {
        dispatch(change(formName, "submitWithAccounts", true));

        let total = 0;
        po.po_items.forEach((val: any) => {
          if (val.id && !val.is_closed) {
            val.id = null;

            if (currentUser.company.inherit_po_department_to_invoice_expense && po.department_id) {
              val.department_id = po.department_id;
            }

            //TODO-SANKET -enbale rebate
            if (invoiceCommonSvc.enabledExpenseTaxOrRebate(currentUser)) {
              val.sub_amount = val.amount;
              let tax = val.tax || 0;
              let rebate = val.rebate_amount || 0;
              val.amount = val.sub_amount + tax + rebate;
            }
            val.product_item_id = undefined;

            total += val.amount ? val.amount : 0;
            adminCommonSvc.removeIdFromBudgetItemLinks(val);
            dispatch(arrayPush(formName, "debit_entries_attributes", val));
          }
        });
        if (!(invoiceCommonSvc.isItemExist(invoice?.invoice_items_attributes).length > 0) && total > 0) {
          dispatch(change(formName, "amount", (invoice?.amount ? invoice.amount : 0) + total));
        }
      }
    };

    const assignToInvoiceDebitSection = (po: PurchaseOrderType.DetailType, val: any) => {
      val.account_link_id = val.id;
      val.id = null;

      if (currentUser.company.inherit_po_department_to_invoice_expense && po.department_id) {
        val.department_id = po.department_id;
      }

      if (val.tax && invoiceCommonSvc.isActiveHeaderTax(po, currentUser)) {
        val.tax = null;
        val.tax_id = null;
        val.tax_code = undefined;
        val.amount = val.sub_amount;
      }

      if (val && val.metadata_links) {
        val.metadata_links = null;
      }

      adminCommonSvc.removeIdFromBudgetItemLinks(val);
      dispatch(arrayPush(formName, "debit_entries_attributes", val));
    };

    const assignPoExpenseToInvoice = (po: PurchaseOrderType.DetailType) => {
      if (_.isPlainObject(po) && _.isArray(po.invoice_debit_accounts)) {
        dispatch(change(formName, "submitWithAccounts", true));

        let total = 0;
        po.invoice_debit_accounts.forEach((val: any) => {
          if (
            val.id &&
            !val.is_matched &&
            !val.is_closed &&
            (!currentUser.company.has_three_way_matching ||
              (currentUser.company.has_three_way_matching && !_.isPlainObject(currentUser.company.invoice)) ||
              (currentUser.company.has_three_way_matching &&
                _.isPlainObject(currentUser.company.invoice) &&
                !currentUser.company.invoice.inherit_only_received_lines))
          ) {
            assignToInvoiceDebitSection(po, val);
            total += val.amount;
          } else if (
            currentUser.company.has_three_way_matching &&
            _.isPlainObject(currentUser.company.invoice) &&
            currentUser.company.invoice.inherit_only_received_lines &&
            val.is_expense_received &&
            !_.isPlainObject(val.account_entry)
          ) {
            assignToInvoiceDebitSection(po, val);
            total += val.amount;
          }
        });
        if (!(invoiceCommonSvc.isItemExist(invoice?.invoice_items_attributes).length > 0) && total > 0) {
          dispatch(change(formName, "amount", (invoice?.amount ? invoice.amount : 0) + total));
        }
      }
    };

    const assignPoLineToInvoiceLine = async (po: PurchaseOrderOptionType) => {
      if (po) {
        try {
          const response = await PurchaseOrdersApis.getPurchaseOrder(po.id);
          if (!invoice.autoSubmit) {
            CreateNotification(
              "Warning",
              "The invoice amount may have changed. Please select Invoice Items tab to the unit price field to make any edits.",
              NotificationType.warning,
            );
          }
          const purchaseOrderLinks = invoiceCommonSvc.assignAbsoluteNetToPo(invoice, response);
          if (purchaseOrderLinks) {
            dispatch(change(formName, `invoice_purchase_order_links_attributes`, purchaseOrderLinks));
          }

          inheritPOHeaderToInvoiceHeader(response);
          if (currentUser.company.allow_invoice_items && currentUser.company.auto_pull_po_line_item) {
            assignPoItemsToInvoice(response);
          }

          if (currentUser.company.invoice && currentUser.company.invoice.auto_pull_po_items_to_expense_lines) {
            assignPoItemsToInvoiceExpenses(response); //to inherits po items to invoice expenses
          }

          if (currentUser.company?.invoice?.auto_pull_po_expenses_line) {
            assignPoExpenseToInvoice(response); //to inherits po expense to invoice
          }
          //TODO-SANKET - AUTO SUBMIT VENDOR
          // if ($scope.invoice.auto_submit) {
          //     $scope.load_vendor_data_before_auto_submit();
          // }
        } catch (error) {}
      }
    };
    const changePo = (po: PurchaseOrderOptionType) => {
      assignPoLineToInvoiceLine(po);
    };

    const inheritPoToInvoice = (po: any) => {
      if (po.id) {
        if (_.isPlainObject(po.subsidiary)) {
          // ensure state updates before calling dispatch to properly render on form
          setTimeout(() => {
            dispatch(change(formName, "subsidiary", po.subsidiary));
            dispatch(change(formName, "subsidiary_id", po.subsidiary.id));
          }, 0);
        }
        if (po.currency) {
          dispatch(change(formName, "currency_code", po.currency.iso_code));
          dispatch(change(formName, "currency", po.currency));
        }
      }
    };

    const callPosBySubsidiary = (subsidiaryId: number | null) => {
      if (subsidiaryId && subsidiaryId > 0) {
        dispatch(change(formName, `subsidiary_id`, subsidiaryId));
      } else {
        dispatch(change(formName, `subsidiary_id`, ""));
      }
    };

    const setRequestor = (requestorId: number) => {
      let obj = {};
      if (_.isArray(requestors)) {
        requestors.forEach((requestor) => {
          if (_.isPlainObject(requestor) && requestorId == requestor.id) {
            obj = requestor;
          }
        });
      }
      return obj;
    };

    const addPurchaseOrderLinks = (po: PurchaseOrderOptionType) => {
      let flag = invoiceCommonSvc.isPoExist(po.id, invoice.invoice_purchase_order_links_attributes);

      if (flag) {
        invoice.invoice_purchase_order_links_attributes = flag;
        dispatch(change(formName, `invoice_purchase_order_links_attributes`, flag));
      } else {
        let obj: any = {};
        obj.purchase_order_id = po.id;
        obj.invoice_id = invoice?.id;
        invoiceCommonSvc.getLinkedPoLineDetails(po.id).then((result) => {
          obj.linked_po_line_details = result;
        });
        obj.purchase_order = po;
        dispatch(arrayPush(formName, "invoice_purchase_order_links_attributes", obj));
        if (!_.isArray(invoice.invoice_purchase_order_links_attributes)) {
          invoice.invoice_purchase_order_links_attributes = [obj];
        } else {
          invoice.invoice_purchase_order_links_attributes.push(obj);
        }
        inheritPoToInvoice(po);
        changePo(po);
        callPosBySubsidiary(po.subsidiary_id);
        if (!invoice.requestor_id && po.requestor_id) {
          dispatch(change(formName, "requestor", setRequestor(po.requestor_id)));
          dispatch(change(formName, "requestor_id", po.requestor_id));
        }
      }
    };

    const removeInvoiceDebitAccount = (obj: any, type: string) => {
      if (invoice?.debit_entries_attributes && _.isArray(invoice.debit_entries_attributes)) {
        let isChanged = false;
        let changedDebitEntries = invoice.debit_entries_attributes.map((debit_entry) => {
          if (type == "account" && debit_entry.account_link_id && debit_entry.account_link_id == obj.id) {
            debit_entry._destroy = 1;
            isChanged = true;
          } else if (type == "item" && debit_entry.item_line_id && debit_entry.item_line_id == obj.id) {
            debit_entry._destroy = 1;
            isChanged = true;
          }
          return debit_entry;
        });
        if (isChanged) {
          dispatch(change(formName, "debit_entries_attributes", changedDebitEntries));
        }
      }
    };

    const removeInvoiceItem = (poItem: PurchaseOrderType.PoItemType) => {
      if (_.isArray(invoice?.invoice_items_attributes)) {
        let isChanged = false;

        let changedInvoiceItem = invoice.invoice_items_attributes.map((invoiceItem) => {
          if (invoiceItem.inherit_po_item_id == poItem.id) {
            isChanged = true;
            invoiceItem._destroy = 1;
            removeInvoiceDebitAccount(invoiceItem, "item");
          }
          return invoiceItem;
        });
        if (isChanged) {
          dispatch(change(formName, "invoice_items_attributes", changedInvoiceItem));
        }
      }
    };

    const removePoInheritedLine = async (po: PurchaseOrderOptionType) => {
      if (po) {
        try {
          const response = await PurchaseOrdersApis.getPurchaseOrder(po.id);
          if (
            _.isPlainObject(response) &&
            _.isArray(response.po_items) &&
            response.po_items.length > 0 &&
            _.isPlainObject(invoice) &&
            _.isArray(invoice.invoice_items_attributes) &&
            invoice.invoice_items_attributes.length > 0
          ) {
            response.po_items.forEach((poItem) => {
              removeInvoiceItem(poItem);
            });
          }
          if (
            _.isPlainObject(response) &&
            _.isArray(response.invoice_debit_accounts) &&
            response.invoice_debit_accounts.length > 0
          ) {
            response.invoice_debit_accounts.forEach((invoice_debit_account) => {
              removeInvoiceDebitAccount(invoice_debit_account, "account");
            });
          }
        } catch (error) {
          console.log(error);
        }
      }
    };

    const removePurchaseOrderLinks = (po: PurchaseOrderOptionType) => {
      if (invoice?.invoice_purchase_order_links_attributes) {
        const removedPoLinks = invoice.invoice_purchase_order_links_attributes.map((val, index) => {
          if (val.purchase_order_id == po.id) {
            val._destroy = 1;
            removePoInheritedLine(po);
          }
          return val;
        });
        dispatch(change(formName, `invoice_purchase_order_links_attributes`, removedPoLinks));
      }
    };

    //to get variance percent
    const getVariancePercent = () => {
      let variance = currentUser.company.matching_variance;
      switch (variance) {
        case "zero_percent":
          return 0;
        case "five_percent":
          return 5;
        case "ten_percent":
          return 10;
        case "fifteen_percent":
          return 15;
        case "twenty_percent":
          return 20;
        case "twenty_five_percent":
          return 25;
        default:
          return 0;
      }
    };

    //calculate absolute amount with considering matching variance
    const absoluteAmountWithVariance = (absolute_net: number) => {
      let amount = absolute_net;
      let variancePercent = getVariancePercent();
      if (variancePercent > 0) {
        amount = amount + (amount / 100) * variancePercent;
      }
      return amount;
    };

    const getDifferenceLinkAmount = (poLink: InvoiceType.PurchaseOrderLinksType) => {
      let difference_amount = 0;
      //since we are not changing purchase_order_links, we can use it as original value
      if (_.isArray(invoice?.purchase_order_links)) {
        let originalPoLink = invoice?.purchase_order_links.find((link) => link.id === poLink.id);
        if (originalPoLink) {
          difference_amount = originalPoLink.amount - poLink.amount;
        }
      }
      return difference_amount;
    };

    //to calculate modified absolute_net amount
    const calculateUpdatedAbsoluteNetAmount = (poLink: InvoiceType.PurchaseOrderLinksType) => {
      let amount = getDifferenceLinkAmount(poLink);
      return poLink.purchase_order.absolute_net + amount;
    };
    const calculatePoAmount = (linkedPoLineDetails: InvoiceType.linkedPoLineDetailsType) => {
      let { po_items_id = [], po_expenses_id = [] } = linkedPoLineDetails || {};
      let invoiceItems = invoice.invoice_items_attributes || [];
      let debitEntries = invoice.debit_entries_attributes || [];
      let invoiceAmount = 0;

      if (Array.isArray(po_items_id) && Array.isArray(invoiceItems)) {
        po_items_id.forEach((id) => {
          const item = invoiceItems.find((item) => id === item?.inherit_po_item_id);
          if (item?._destroy !== 1 && !_.isNil(item?.total) && item?.total !== undefined) {
            invoiceAmount += item.total;
          }
        });
      }

      if (Array.isArray(po_expenses_id) && Array.isArray(debitEntries)) {
        po_expenses_id.forEach((id) => {
          const entry = debitEntries?.find(
            (item: InvoiceType.TInvoiceExpenseLineEntry) => id === item?.account_link_id,
          );
          if (entry?._destroy !== 1 && !_.isNil(entry?.amount)) {
            invoiceAmount += entry.amount;
          }
        });
      }
      return invoiceAmount;
    };
    const showWarningMsg = (poLink: InvoiceType.PurchaseOrderLinksType) => {
      //return false if there is no po link or absolute_net amount is not available
      if (!poLink.purchase_order || !poLink.purchase_order.absolute_net) {
        return false;
      }
      getDifferenceLinkAmount(poLink);

      let linkAmount = poLink.amount ? poLink.amount : calculatePoAmount(poLink?.linked_po_line_details);
      let absoluteNet = poLink.id ? calculateUpdatedAbsoluteNetAmount(poLink) : poLink.purchase_order.absolute_net;
      let absoluteNetAmount = absoluteAmountWithVariance(absoluteNet);

      if (poLink._destroy == 1) {
        return false;
      } else if (!poLink.id && poLink.purchase_order && absoluteNetAmount - linkAmount < 0) {
        return true;
      } else if (poLink.id && poLink.purchase_order && absoluteNetAmount < 0) {
        return true;
      }

      return false;
    };

    const updatePoByVendor = () => {
      if (
        _.isArray(invoice?.invoice_purchase_order_links_attributes) &&
        invoice?.invoice_purchase_order_links_attributes.length > 0
      ) {
        let isUpdated = false;
        let updatedPoLinks = invoice.invoice_purchase_order_links_attributes.map((val) => {
          if (val.purchase_order.vendor_id !== invoice.vendor_id) {
            val._destroy = 1;
            removePoInheritedLine(val.purchase_order);
            isUpdated = true;
          }
          return val;
        });
        if (isUpdated) {
          dispatch(change(formName, `invoice_purchase_order_links_attributes`, updatedPoLinks));
        }
      }
    };

    const updatePoByCurrencyCode = () => {
      if (
        _.isArray(invoice?.invoice_purchase_order_links_attributes) &&
        invoice?.invoice_purchase_order_links_attributes.length > 0
      ) {
        let isUpdated = false;
        let updatedPoLinks = invoice.invoice_purchase_order_links_attributes.map((val) => {
          if (val.purchase_order.currency_code !== invoice.currency_code) {
            val._destroy = 1;
            removePoInheritedLine(val.purchase_order);
            isUpdated = true;
          }
          return val;
        });
        if (isUpdated) {
          dispatch(change(formName, `invoice_purchase_order_links_attributes`, updatedPoLinks));
        }
      }
    };

    useEffect(() => {
      preventInitialCall(updatePoByVendor, "vendor_id");
    }, [invoice?.vendor_id]);

    return (
      <Fragment>
        <Field
          id="is_not_single_vendor_po"
          name="is_not_single_vendor_po"
          component={RenderCheckBox}
          type="checkbox"
          className="mb-0"
          label="Multiple Vendor, Expense & Corp Card"
        />
        <Field
          id="po_links"
          name="po_links"
          modelData={invoice}
          isMulti={true}
          callBack={addPurchaseOrderLinks}
          deleteCallBack={removePurchaseOrderLinks}
          label={CustomLabelSvc.setCustomLabel(customLabels, t("admin.pages.invoice.purchaseOrder"))}
          placeholder="search/select po"
          instanceId="invoice-po-links"
          component={PurchaseOrderPicker}
        />
        {currentUser.company.has_advance_two_way_matching && (
          <Row className="mb-3">
            {invoice?.invoice_purchase_order_links_attributes &&
              invoice.invoice_purchase_order_links_attributes.map((purchaseOrderLink: any) => (
                <Fragment key={purchaseOrderLink.purchase_order_id}>
                  {purchaseOrderLink._destroy != 1 ? (
                    <Col sm="4" className="mb-1">
                      <div className={styles.poLink}>
                        <a
                          className="link"
                          href={
                            restApiService.angularBaseURL() + "purchase_orders/" + purchaseOrderLink.purchase_order.id
                          }
                          target="_blank"
                          rel="noreferrer"
                        >
                          {purchaseOrderLink.purchase_order.number}
                        </a>
                      </div>
                    </Col>
                  ) : null}
                </Fragment>
              ))}
          </Row>
        )}
        {!currentUser.company.has_advance_two_way_matching && invoice?.invoice_purchase_order_links_attributes && (
          <FieldArray
            key={"invoicePurchaseOrderMatching"}
            name="invoice_purchase_order_links_attributes"
            component={PurchaseOrderLinks}
            invoice={invoice}
          />
        )}
        {_.isArray(invoice?.invoice_purchase_order_links_attributes) &&
          invoice?.invoice_purchase_order_links_attributes?.length > 0 && (
            <Row className="mb-2">
              <Col md="12">
                {invoice.invoice_purchase_order_links_attributes.map((poLink) => (
                  <Fragment key={poLink.purchase_order_id}>
                    {showWarningMsg(poLink) && (
                      <span className={styles.poWarning + " separatedPoNumber"}>{poLink.purchase_order.number}</span>
                    )}
                  </Fragment>
                ))}
              </Col>
            </Row>
          )}
      </Fragment>
    );
  },
);

export default ManagePoPicker;
