import React, { memo, useMemo } from "react";
import AbstractListPicker from "../abstractListPicker/index2";
import { AmortizationSchedulePickerTypes } from "./amortizationSchedulePickerTypes";

const AmortizationSchedulePicker = ({
  name,
  label,
  instanceId,
  required = false,
  modelDataName,
  objectPropertyKey,
}: AmortizationSchedulePickerTypes.TAmortizationSchedulePickerProps) => {
  const params = useMemo(() => ({ ACTIVE: true }), []);

  return (
    <AbstractListPicker<AmortizationSchedulePickerTypes.TAmortizationSchedulePickerOption>
      name={name}
      label={label}
      required={required}
      instanceId={instanceId}
      params={params}
      objectPropertyKey={objectPropertyKey ?? (modelDataName && `${modelDataName}.amortization`)}
      fetchUrl="amortizations"
    />
  );
};

export default memo(AmortizationSchedulePicker);
