import React from "react";

const AccessDeniedPanel = () => {
  return (
    <>
      <h1>Access denied</h1>
      <p>You do not have permissions to view this page. Contact your administrator for assistance.</p>
    </>
  );
};

export default AccessDeniedPanel;
